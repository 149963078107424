import { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import { customStyles } from "../../../pages/customStyles";
import { AxiosInstance } from "../../../axios";
import { ImageUrlPrefix } from "../../../labels";

export const TechModelExam = (props) => {
  const [classOption, setClassOption] = useState([]);
  const [thqOption, setThqOption] = useState([]);
  const [thqOptionCoa, setThqOptionCoa] = useState([]);
  const txtadTech = useRef(null);

  useEffect(() => {
    AxiosInstance.get("api/codevalues?codetype=CLASS_THQ")
      .then((res) => {
        const classOptions = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setClassOption(classOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=THQ_K")
      .then((res) => {
        const classOption_thqsC = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setThqOption(classOption_thqsC);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=THQ_COA")
      .then((res) => {
        const classOption_thqsC = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setThqOptionCoa(classOption_thqsC);
      })
      .catch((error) => {
        return error;
      });
  }, []);
  return (
    <div className="row box">
      <div className="col-md-12">
        <p className="StepTitle">
          Technical Qualification {props.record.id > 1 ? "-" : ""}{" "}
          {props.record.id > 1 ? props.record.id : ""}
        </p>
        <div className="row">
          {props.record.id === 1 && (
            <div className="col-md-12">
              <div className="forms row  ">
                <label htmlFor="TQ">
                  Do you possess any technical qualification?
                </label>
                <div className="d-flex  col-md-12" id="radiobuttons">
                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      name={`TQ${props.index}`}
                      id={`TQ${props.index}`}
                      onClick={(e) =>
                        props.TechhandleChange(e, props.record.id, "TQ")
                      }
                      checked={props.record.TQ === "0" ? true : false}
                      value="0"
                    />
                    <label className="checkbox-label" htmlFor="TQ">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      name={`TQ${props.index}`}
                      id={`TQ${props.index}`}
                      onClick={(e) =>
                        props.TechhandleChange(e, props.record.id, "TQ")
                      }
                      checked={props.record.TQ === "1" ? true : false}
                      value="1"
                    />
                    <label className="checkbox-label" htmlFor="TQ2">
                      No
                    </label>
                  </div>
                </div>
              </div>
              {props.record.error_tq && (
                <small className="errors ">{props.record.error_tq}</small>
              )}
            </div>
          )}

          {props.record.TQ === "0" && (
            <div className="col-md-6">
              <div className="forms ">
                <label htmlFor={`name`}>Technical Course</label>
                <Select
                  id={`name`}
                  name={`name`}
                  value={
                    props.record.name !== null && props.record.name !== ""
                      ? { value: props.record.name, label: props.record.name }
                      : null
                  }
                  onChange={(option) =>
                    props.TechhandleChanges(option, props.record.id)
                  }
                  options={props.technicalOption
                    .filter(
                      (item) =>
                        item &&
                        item.label &&
                        !["COA", "Knowledge in computer", "Nil"].includes(
                          item.label
                        )
                    ) // Filter out null and undefined elements and COA, Knowledge in computer options
                    .sort((a, b) => a.label.localeCompare(b.label))} // Sort options alphabetically by label
                  isSearchable={true} // Enables search functionality
                  placeholder="Select an option"
                  maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                  filterOption={(option, searchText) =>
                    option.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  } // Filter options based on the search text
                  styles={customStyles} // Apply custom styles her
                />
                {props.record.error_name && (
                  <small className="errors ">{props.record.error_name}</small>
                )}
              </div>
            </div>
          )}

          {props.record.TQ === "0" && props.record.name === "Others" && (
            <div className="col-md-6">
              <div className="forms ">
                <label htmlFor={`level`}>Technical course name</label>
                <input
                  id={`level`}
                  name={`level`}
                  type="text"
                  className="Inputs"
                  maxLength={100}
                  value={props.record.level}
                  placeholder="please fill the details"
                  onChange={(e) =>
                    props.TechhandleChange(e, props.record.id, "level")
                  }
                  required
                />
                {props.record.error_level && (
                  <small className="errors ">{props.record.error_level}</small>
                )}
              </div>
            </div>
          )}

          {props.record.TQ === "0" && props.record.name !== "Others" && (
            <div className="col-md-6">
              <div className="forms ">
                <label htmlFor={`level`}>
                  {props.record.name === "Steno"
                    ? "Stenographer Level"
                    : "Typewriting Level"}
                </label>
                {props.record.name === "COA" && (
                  <select
                    id={`level`}
                    name={`level`}
                    className="Inputs"
                    value={props.record.level}
                    onChange={(e) =>
                      props.TechhandleChange(e, props.record.id, "level")
                    }
                    required
                  >
                    <option value="" disabled>
                      Please select
                    </option>
                    {thqOptionCoa}
                  </select>
                )}

                {props.record.name !== "COA" && (
                  <select
                    id={`level`}
                    name={`level`}
                    className="Inputs"
                    value={props.record.level}
                    onChange={(e) =>
                      props.TechhandleChange(e, props.record.id, "level")
                    }
                    required
                  >
                    <option value="" disabled>
                      Please select
                    </option>
                    <option value="Junior">Junior</option>
                    <option value="Senior">Senior</option>
                    <option value="HighSpeed">High Speed</option>
                  </select>
                )}

                {props.record.error_level && (
                  <small className="errors ">{props.record.error_level}</small>
                )}
              </div>
            </div>
          )}

          {props.record.TQ === "0" && props.record.name !== "Others" && (
            <div className="col-md-6">
              <div className="forms ">
                <label htmlFor={`lang`}>Language</label>
                <select
                  id={`lang`}
                  name={`lang`}
                  className="Inputs"
                  value={props.record.lang}
                  onChange={(e) =>
                    props.TechhandleChange(e, props.record.id, "lang")
                  }
                  required
                >
                  <option value="" disabled selected>
                    Please select
                  </option>
                  <option value="English">English</option>
                  <option value="Tamil">Tamil</option>
                  <option value="Hindi">Hindi</option>
                  {/* <option value="malayalam">malayalam</option> */}
                </select>

                {props.record.error_lang && (
                  <small className="errors ">{props.record.error_lang}</small>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="row mb-4">
          <div className="col-md-12 d-flex justify-content-end">
            {props.record.id != 1 && props.record.id === props.length && (
              <a
                className="AddMore me-3"
                onClick={() =>
                  props.DeleteQual(props.record.id, props.record.name)
                }
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete Technical Qualification
              </a>
            )}

            {props.record.id === props.length ? (
              <a
                className="AddMore"
                disabled={props.record.TQ === "0" ? true : false}
                onClick={
                  props.record.TQ === "0"
                    ? () => props.AddQualification()
                    : () => {}
                }
              >
                <span className="material-icons download_icon align-middle">
                  add_circle
                </span>
                Add More Technical Qualification
              </a>
            ) : (
              <a
                className="AddMore me-3"
                onClick={() =>
                  props.DeleteQual(props.record.id, props.record.name)
                }
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete Technical Qualification
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
