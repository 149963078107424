import React, { useState } from 'react';
import { Range } from 'react-range';

const MultiRangeSlider = (props) => {

  return (
    <div>  
      <form >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Range
            values={props.ranges}
            step={1}
            name='range_filter'
            min={0}
            max={100}
            onChange={props.handleChangeFilter}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '6px',
                  width: '300px',
                  backgroundColor: '#ddd',
                  borderRadius: '3px',
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '16px',
                  width: '16px',
                  backgroundColor: '#1ABC9C',
                  borderRadius: '50%',
                  outline: 'none',
                }}
              />
            )}
          />
        </div>
      </form>
    </div>
  );
};

export default MultiRangeSlider;
