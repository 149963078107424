import React from "react";
import styled from "styled-components";
import { MEDIA_SIZE_MD } from "../utils/helper";

const StyledButton = styled.button`
  border: 2px solid var(--color-brand-800);
  background-color: var(--color-brand-800);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 6px 12px;
  min-width: 300px;
  border-radius: var(--border-radius-sm);
  letter-spacing: 0.8px;
  cursor: pointer;
  transition-duration: 0.2s;
  @media (min-width: ${MEDIA_SIZE_MD}) {
    min-width: 200px;
  }

  &:hover {
    background-color: var(--color-blue-600);
    border-color: var(--color-blue-600);
    transition-duration: 0.2s;
  }
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background-color: var(--color-brand-800);
    opacity: 0.5;
    justify-content: center;
    border-color: var(--color-brand-800);
    cursor: not-allowed;
  }
`;

export default function PrimaryButton({
  children,
  className = "",
  disabled,
  style = {},
  onClick,
}) {
  return (
    <StyledButton style={{ ...style }} onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
}
