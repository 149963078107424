import "./Footer.css";

import { Facebook } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { LinkedIn } from "@mui/icons-material";
import React from "react";
import { Twitter } from "@mui/icons-material";
import { WhatsApp } from "@mui/icons-material";
import { YouTube } from "@mui/icons-material";
import logo from "../../../img/AIASA.png";
import iconLogo from "../../../img/IconLogo.png"
import FB from '../../../img/socialicons/facebook.png';
import INS  from '../../../img/socialicons/insta.png';
import WHATS  from '../../../img/socialicons/whastapp.png';
import TWITT  from '../../../img/socialicons/twitter.png';
import LINKD  from '../../../img/socialicons/linkedin.png';
import YOUTUBE  from '../../../img/socialicons/youtube.png';
import { Link, useNavigate } from "react-router-dom";

function Footer() {

  const navigate = useNavigate();

  const handleLogo = () => {

    navigate('/')

  }

  const handleSocialIcons = (nam) => {
    if (nam === "Fac") {
      window.open('https://www.facebook.com/annatnpsccenter?mibextid=ZbWKwL', '_blank');
    }
    else if (nam === "You") {
      window.open('https://youtube.com/@annaiasacademy2010?si=ttrQQPx9QGmBUzxB', '_blank');
    }
    else if (nam === "Ins") {
      window.open('https://instagram.com/annaiasacademykanchipuram?igshid=NzZlODBkYWE4Ng==', '_blank');
    }
    else if (nam === "Wha") {
      window.open('https://whatsapp.com/channel/0029VaBYURMIXnlmlHyKao1o', '_blank');
    }
    else if (nam === "Lin") {
      window.open('https://www.linkedin.com/in/anna-ias-academy-8b9b662a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app', '_blank');
    }
    else if (nam === "Twitt") {
      window.open('https://x.com/academy_an52468?t=PUh_SyGgAGYp4SjwZZ22xw&s=09', '_blank');
    }
  }



  return (
    <footer className="text-center text-lg-start text-white">
      <div className="row ">
        <div className="col-lg-7 col-md-12">
          <h4 className="footer_head_title ms-2">Sign up to our newsletter</h4>
          <div className="footer_sub_heading ms-2 text-md-center text-lg-start">
            Stay up to date with the latest news, announcements and articles
          </div>
        </div>
        {/* <div className="col-lg-3 col-md-6">
          <input
            type="email"
            id="form5Example21"
            className="form-control EMailForm"
            placeholder="Enter your email"
          />
        </div>
        <div className="col-lg-2 col-md-6">
          <button
            type="submit"
            className="btn btn-outline-light footer_subscribe"
          >
            Subscribe Now
          </button>
        </div> */}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div id="Border_Footer" className=""></div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-2 col-lg-12 text-center" id="LogoCont">
          <div className="rounded-circle shadow-1-strong d-flex align-items-center justify-content-center mb-4 mx-auto ">
            <img
              src={iconLogo}
              onClick={handleLogo}
              alt="anna_ias_logo"
              className="footer_logo"
              loading="anna_ias_logo"
            />
          </div>
        </div>
        <div className="col-lg-12 MobileBorder">
          <div id="Border_Footer" className="mt-0"></div>
        </div>
        <div className="col-xl-2 col-lg-4  col-md-4 col-sm-12 footer_category_list">
          <h6 className="fw-bold mb-4 footer_link_category">Our Institute</h6>
          <p className="footer_list">
            {" "}
            <a href="#!" className="text-reset footer_link">
              Who We Are
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
              Why Choose Us
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
              Our Dream Faculty
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
              Gallery
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
              Careers
            </a>
          </p>
        </div>
        <div className="col-xl-2 col-lg-4  col-md-4 col-sm-12 footer_category_list">
          <h6 className="fw-bold mb-4 footer_link_category">Accomplishments</h6>
          <p className="footer_list">
            {" "}
            <a href="#!" className="text-reset footer_link">
              Our Champions
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
              Our Achievements
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
              Testimonials
            </a>
          </p>
        </div>
        <div className="col-xl-2 col-lg-4  col-md-4 col-sm-12 footer_category_list">
          <h6 className="fw-bold mb-4 footer_link_category">Test Batches</h6>
          <p className="footer_list">
            {" "}
            <a href="#!" className="text-reset footer_link">
              Online Test Batches
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
              Offline Test Batches
            </a>
          </p>
        </div>
        <div className="col-xl-2 col-lg-4  col-md-4 col-sm-12 footer_category_list">
          <h6 className="fw-bold mb-4 footer_link_category">Courses</h6>
          <p className="footer_list">
            {" "}
            <a href="#!" className="text-reset footer_link">
            Online Courses
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
            Offline Courses
            </a>
          </p>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-12 footer_category_list">
          <h6 className="fw-bold mb-4 footer_link_category">Resources</h6>
          <p className="footer_list">
            {" "}
            <a href="#!" className="text-reset footer_link">
            Study Materials
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
            Current Affairs
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
            Articles
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
            Announcements
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
            Micro Learning
            </a>
          </p>
        </div>
        <div className=" footer_category_list">
          <h6 className="fw-bold mb-4 footer_link_category">Help Center</h6>
          <p className="footer_list">
            {" "}
            <a href="#!" className="text-reset footer_link">
            FAQs
            </a>
          </p>
          <p className="footer_list">
            <a href="#!" className="text-reset footer_link">
            Contact Us
            </a>
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div id="Border_Footer" className=""></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ul className="list-group list-group-horizontal footer_bottom_ul">
            <h5 className="footer_link_category">Featured Services</h5>
            <li className="footer_bottom_link ">
              <a href="#">Online Test Batches</a>
            </li>
            <li className="footer_bottom_link ">
              <a href="#">Offline Test Batches</a>
            </li>
            <li className="footer_bottom_link ">
              <a href="#">Online Courses</a>
            </li>
            <li className="footer_bottom_link ">
              <a href="#">Classroom Courses</a>
            </li>
            <li className="footer_bottom_link ">
              <a href="#">Admission</a>
            </li>
            <li className="footer_bottom_link ">
              <a href="#">Study Materials</a>
            </li>
            <li className="footer_bottom_link ">
              <a href="#">Current Affairs</a>
            </li>
            <li className="footer_bottom_link ">
              <a href="#">Articles</a>
            </li>
            <li className="footer_bottom_link">
              <a href="#">Announcements</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div id="Border_Footer" className=""></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5 col-md-6">
          <div class="MobileIocns">
            <a href="" className="me-3 link-secondary">
              <img src={FB} alt="facebook" id="socialIcons" onClick={() => handleSocialIcons('Fac')}/>
            </a>
            <a href="" className="me-3 link-secondary">
            <img src={INS} alt="insta" id="socialIcons" onClick={() => handleSocialIcons('Ins')}/>
            </a>
            <a href="" className="me-3 link-secondary">
            <img src={WHATS} alt="whastapp" id="socialIcons" onClick={() => handleSocialIcons('Wha')}/>
            </a>
            <a href="" className="me-3 link-secondary">
            <img src={TWITT} alt="Twitter" id="socialIcons" onClick={() => handleSocialIcons('Twitt')}/>
            </a>
            <a href="" className="me-3 link-secondary">
            <img src={LINKD} alt="Linkedin" id="socialIcons" onClick={() => handleSocialIcons('Lin')}/>
            </a>
            <a href="" className="me-3 link-secondary">
            <img src={YOUTUBE} alt="Youtube" id="socialIcons"  onClick={() => handleSocialIcons('You')}/>
            </a>
          </div>
        </div>
        <div className="col-lg-7 col-md-6">
          <div className="row d-lg-none d-xl-none d-md-none">
            <div className="col-lg-12">
              <div id="Border_Footer" className=""></div>
            </div>
          </div>
          <div className="footer_copy  mb-4  text-center">
            <span>
              Copyright © {new Date().getFullYear()} Anna IAS Academy.
              <span className=""> All rights reserved.</span>{" "}
              <a href={require('../../../PrivacyPolicyTermsCondition.pdf')} target="_blank" className="footer_rights">
                / Privacy /
              </a>
              <a href={require('../../../PrivacyPolicyTermsCondition.pdf')} target="_blank" className="footer_rights">
                Terms of Use
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
