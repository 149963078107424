import React from "react";
import getImageLink from "./getIamgeLink";

export default function ImageDisplay(props) {
  return (
    <img
      src={getImageLink(props.ImgUrl)}
      alt={props.ImgUrl}
      className={props.className}
    />
  );
}
