import { useEffect } from "react";

const LogoutListener = () => {
  useEffect(() => {
    const handleStorageChange = (event) => {
      //   if (event.key === 'accessToken' && !event.newValue) {
      if (event.key === "userDetails" && !event.newValue) {
        // Redirect to login page if accessToken is removed
        window.location.href = "/login";
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default LogoutListener;
