import React from "react";
import { Controller, useController } from "react-hook-form";
import styled from "styled-components";

const StyledInput = styled.input`
  border: 1px solid var(--color-text-primary);
  border-radius: var(--border-radius-sm);
  font-size: 14px;
  padding: 10px;
  margin: 0px;
  width: 100%;
  outline: none;
  &:hover {
    border: 1px solid var(--color-brand-800);
  }
`;
const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
`;
const StyledErrorMessage = styled.span`
  font-size: 12px;
  color: var(--color-brand-900);
  margin: 10px 0px;
`;

export default function TextInput({
  name,
  label,
  placeholder,
  readOnly,
  control,
  rules,
  type = "text",
}) {
  const {
    field: { ref }, //...inputProps
    fieldState: { error }, //invalid
  } = useController({
    name,
    control,
    rules,
  });
  // console.log("laksh-ref", ref, error);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <>
          <StyledLabel>{label}</StyledLabel>
          <StyledInput
            name={name}
            type={type}
            value={""}
            disabled={readOnly}
            placeholder={placeholder}
            ref={ref}
            {...field}
          />
          {!readOnly && (
            <StyledErrorMessage>{error?.message}</StyledErrorMessage>
          )}
        </>
      )}
    />
  );
}
