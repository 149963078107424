import React from "react";
import styled from "styled-components";
import { MEDIA_SIZE_LG } from "../utils/helper";
import { DialogActions } from "@mui/material";

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 10px;
    @media (min-width: ${MEDIA_SIZE_LG}) {
      padding: 20px;
    }
  }
`;

export default function ModalDialogActions({ children }) {
  return <StyledDialogActions>{children}</StyledDialogActions>;
}
