import React from "react";
import "./Question.css";
import parse from "html-react-parser";
import ImageDisplay from "../StudentTestWorkflow/ImageDisplay";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

//display type 8 means -  image  mcq design
// sample design

function Qtypenine(props) {
  // console.log(props,"props");
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  const optionType = props.list.options_type;

  const generateEnglish = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];
    for (let i = 0; i < optionType; i++) {
      const valueKey = `options_en_${options[i]}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === options[i]
      );
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_en`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  options[i],
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label"
              onClick={(e) => {
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  options[i],
                  props.list.subject_type
                );
              }}
            >
              ({options[i]})
            </span>
            {/* {props.list[valueKey].includes("image=") ? (
             
            ) : (
              <span className="answer_label">{props.list[valueKey]}</span>
            )} */}
            <ImageDisplay
              className="imgtnss_op"
              ImgUrl={`${props.list[valueKey]}`}
            />
            {/* <img className="imgtnss_op" src={`${props.list[valueKey]}`} /> */}
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateTamil = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];

    const elements = [];
    for (let i = 0; i < optionType; i++) {
      const valueKey = `options_tm_${options[i]}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === options[i]
      );

      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_tm`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  options[i],
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label"
              onClick={(e) => {
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  options[i],
                  props.list.subject_type
                );
              }}
            >
              ({options[i]})
            </span>
            {/* {props.list[valueKey].includes("image=") ? (
           
            ) : (
              <span className="answer_label">{props.list[valueKey]}</span>
            )} */}
            <ImageDisplay
              className="imgtnss_op"
              ImgUrl={`${props.list[valueKey]}`}
            />
            {/* <img className="imgtnss_op" src={`${props.list[valueKey]}`} /> */}
          </div>
        </div>
      );
    }

    return elements;
  };

  return (
    <div className=" Question_temp">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 1 || props.Lang === 2))) && (
          <div className="row tamilQues">
            {/* <div className="col-md-12">
              <span className="question_labels_english">
                {EsplitLines.length > 0 ? parse(EsplitLines[0]) : ""}
              </span>
            </div> */}
            {EsplitLines.map((line) => {
              if (line.includes("image=")) {
                return (
                  <div className="col-md-12" key={line}>
                    <ImageDisplay
                      className="imgtnss"
                      ImgUrl={`${line.substring(8)}`}
                    />
                  </div>
                );
              } else {
                return (
                  // Add return statement here
                  <span className="question_labels_english">
                    <LatexProcessorComponent originalString={line} />
                  </span>
                );
              }
            })}
            <div className="col-md-7  mt-4">
              <div className="row">
                {generateEnglish(optionType, props.index)}
              </div>
            </div>
          </div>
        )}
      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 0 || props.Lang === 2))) && (
          <div className="row tamilQues">
            {/* <div className="col-md-12">
              <span className="question_labels_tamil">
                {splitLines.length > 0 ? parse(splitLines[0]) : ""}
              </span>
            </div> */}
            {splitLines.map((line) => {
              if (line.includes("image=")) {
                return (
                  <div className="col-md-12" key={line}>
                    <ImageDisplay
                      className="imgtnss"
                      ImgUrl={`${line.substring(8)}`}
                    />
                  </div>
                );
              } else {
                return (
                  // Add return statement here
                  <span className="question_labels_tamil">
                    <LatexProcessorComponent originalString={line} />
                  </span>
                );
              }
            })}
            <div className="col-md-7 mt-4">
              <div className="row">
                {generateTamil(optionType, props.index)}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default Qtypenine;
