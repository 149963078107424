import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./CenterSupervisor.css";
import { CSVLink } from "react-csv";
import { R_PER_PAGE } from "../../../../labels";
import {
  apiGetCentreSupervisorList,
  apiGetCentreSupervisorListDropdown,
} from "../../../../services/services";
import { useDispatch, useSelector } from "react-redux";
import {
  createCentreSupDropdownList,
  createCentreSupervisorList,
} from "../../../../redux/CentreSupervisorSlice";
import Pagination from "../../../ReusuableComponent/Pagination/Pagination";
import CustomDropdown from "../../../ReusuableComponent/Dropdown/CustomDropdown";
import CustomDownloadButton from "../../../ReusuableComponent/CustomDownloadButton/CustomDownloadButton";
import Loader from "../../Loader";

function CentreSupervisor() {
  const { batch_id } = useParams();
  let page = R_PER_PAGE;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [sortOrder, setSortOrder] = useState("");
  const [csvListData, setCsvListData] = useState([]);
  console.log(csvListData, "csvListDatacsvListDatacsvListData");
  const [isLoading, setIsLoading] = useState(false);
  const [listErrors, setListErrors] = useState({});
  const [centreSupData, setCentreSupData] = useState([]);

  const [centreSupDropdownData, setCentreSupDropdownData] = useState({
    batchID: null,
  });
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDetails, setSelectedDetails] = useState({
    id: null,
    paperType: null,
  });
  console.log(selectedValue, "selectedValueselectedValue");

  const dispatch = useDispatch;
  const navigate = useNavigate();
  const handleBackFilter = (jk) => {
    navigate(jk, {});
  };
  // Handle change event
  const handleChange = (event) => {
    const [id, paperType] = event.target.value.split(",");
    setSelectedValue(event.target.value);
    setSelectedDetails({ id: parseInt(id), paperType: parseInt(paperType) });
  };
  useEffect(() => {
    // Example: Set default value after data is fetched
    if (centreSupDropdownData?.data?.length > 0) {
      const defaultItem = centreSupDropdownData.data[0]; // Adjust logic as needed
      setSelectedValue(`${defaultItem.id},${defaultItem.paper_type}`);
      setSelectedDetails({
        id: defaultItem.id,
        paperType: defaultItem.paper_type,
      });
    }
  }, [centreSupDropdownData]);

  // total table data  with pages
  const totalPages = Math.ceil(centreSupData?.length / rowsPerPage);

  const handlePrev = (newPage) => {
    if (newPage >= 1) {
      setCurrentPage(newPage);
    }
  };
  useEffect(() => {
    const CentreSupListApiDropdownFormData = {
      batch_id: batch_id,
    };
    setIsLoading(true);
    apiGetCentreSupervisorListDropdown(CentreSupListApiDropdownFormData)
      .then((data) => {
        setIsLoading(false);
        setCentreSupDropdownData(data);
        // console.log(data, "API Data Dropdown");
        dispatch(createCentreSupDropdownList(data));
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      })
      .finally(() => {
        // setCentreSupData((prevData) => ({ ...prevData, isLoading: false }));
      });
  }, []);
  useEffect(() => {
    if (selectedDetails.id !== null && selectedDetails.paperType !== null) {
      const CentreSupListApiFormData = {
        batch_id: batch_id,
        exam_detail_id: selectedDetails.id,
        paper_type: selectedDetails.paperType,
      };
      setIsLoading(true);
      apiGetCentreSupervisorList(CentreSupListApiFormData)
        .then((data) => {
          const fiata = data.data;
          const ResponseData = fiata.map((data) => ({
            ...data,
          }));

          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));

          setCentreSupData(serialNumberAddedData);
          setCsvListData(ResponseData);
          console.log(ResponseData, "ResponseData");

          // console.log(data.data, "API Data");
          dispatch(createCentreSupervisorList(data));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
        })
        .finally(() => {
          // setIsLoading(false);
          // setCentreSupData((prevData) => ({ ...prevData, isLoading: false }));
        });
    }
  }, [selectedDetails]);

  const handleNext = (newPage) => {
    if (newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const handlePageCount = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  useEffect(() => {}, [rowsPerPage, currentPage]);

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);

    let sortedList = [...csvListData].sort((a, b) => {
      // Convert both strings to lowercase to ensure case-insensitive comparison
      let aValue = a[sortKey]?.toString().toLowerCase();
      let bValue = b[sortKey]?.toString().toLowerCase();

      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });

    console.log(sortedList, "sortedList");

    setCsvListData(sortedList);
  };

  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = csvListData.slice(start, end);
  console.log(currentData, "currentDatacurrentData");
  // mobile tooltip
  let pressTimer;

  document.querySelectorAll(".tooltip-container").forEach((el) => {
    el.addEventListener("touchstart", function () {
      pressTimer = setTimeout(() => {
        this.classList.add("active");
      }, 500); // Long press duration
    });

    el.addEventListener("touchend", function () {
      clearTimeout(pressTimer);
      this.classList.remove("active");
    });
  });
  const dropdownOptions = centreSupDropdownData?.data?.map((item) => ({
    value: `${item.id},${item.paper_type}`,
    label: item.exam_title,
  }));
  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <>
      <div className="container-fluid  BathDetCentreSup">
        <div className="row">
          <div className=" col-md-6 col-12 ">
            <h2 className="TitleSupervisor "> Centre Supervisors</h2>
          </div>

          <div className="col-md-6 col-12 centreSupListBtn">
            <ul class="CentresupListButton">
              <li class="listButton">
                <Link
                  // onClick={() =>
                  //   handleBackFilter(`/DetailViewBatch/${batch_id}/${4}`)
                  // }
                  // to="javascript:void(0)"
                  to={`/DetailViewBatch/${batch_id}/${4}`}
                  className="secondaryButton "
                >
                  Back to View Model Exam Batch
                </Link>
              </li>

              <li class="centreSupListBtn">
                <CustomDownloadButton
                  csvListData={csvListData}
                  filename={"CourseBatch.csv"}
                  csvName="  Download as CSV"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className=" offset-md-8 col-md-4 examListDropdown">
            <CustomDropdown
              id="modelExamList"
              name="modelExamList"
              className="InputsSupervisor"
              onChange={handleChange}
              selectedValue={selectedValue}
              select="Please select"
              options={dropdownOptions}
            />

            {listErrors.TeachMedium && (
              <p className="errors">{listErrors.TeachMedium}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 tableFormat table-responsive">
            <table className="table" id="centreSupList">
              <thead>
                <tr>
                  <th id="th" style={{ width: "5% !important" }}>
                    <span className="text_th">S.No</span>
                    <span
                      className="material-icons align-middle"
                      onClick={(e) => handleSort(e, "serialNumber")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span className="text_th">Supervisor Name</span>
                    <span
                      className="material-icons align-middle"
                      onClick={(e) => handleSort(e, "name")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span className="text_th">Phone Number</span>
                    <span
                      className="material-icons align-middle"
                      onClick={(e) => handleSort(e, "mobile_no")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span className="text_th "> Email ID</span>
                    <span
                      className="material-icons align-middle"
                      onClick={(e) => handleSort(e, "email")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span className="text_th">District</span>
                    <span
                      className="material-icons align-middle"
                      onClick={(e) => handleSort(e, "exam_center_district")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span className="text_th"> Venue of Examination</span>
                    <span
                      className="material-icons align-middle"
                      onClick={(e) => handleSort(e, "exam_center_name")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span className="text_th"> Exam Date</span>
                    <span
                      className="material-icons align-middle"
                      onClick={(e) => handleSort(e, "started_dt")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span className="text_th">Time</span>
                    <span
                      className="material-icons align-middle"
                      onClick={(e) => handleSort(e, "time")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span className="text_th">Marksheet Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentData && currentData?.length === 0 ? (
                  <tr>
                    <td
                      colSpan="15"
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      No record to display
                    </td>
                  </tr>
                ) : (
                  currentData.map((course, index) => (
                    <tr key={index}>
                      <td id="td">{course.serialNumber || index + 1}</td>
                      <td id="td">{course.name}</td>
                      <td id="td">{course.mobile_no}</td>
                      <td className="ellipsis" title={course.email} id="td">
                        {course.email}
                      </td>
                      <td id="td">{course.exam_center_district}</td>
                      <td
                        className="ellipsis"
                        title={course.exam_center_name}
                        id="td"
                      >
                        {course.exam_center_name}
                      </td>

                      <td id="td">{course.started_dt}</td>
                      <td id="td">{course.time}</td>
                      {course.marksheet_upload === 0 && (
                        <td id="td">
                          <span className="PinkText">Not Yet Uploaded</span>
                        </td>
                      )}
                      {course.marksheet_upload === 1 && (
                        <td id="td">
                          <span className="GreenTexts">Uploaded</span>
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row mt-2">
          <Pagination
            rowsPerPage={rowsPerPage}
            handlePageCount={handlePageCount}
            startPage={
              centreSupData && centreSupData?.length > 0
                ? currentPage * rowsPerPage + 1 - rowsPerPage
                : 0
            }
            endPage={
              centreSupData && centreSupData?.length > 0
                ? centreSupData?.length < currentPage * rowsPerPage
                  ? centreSupData?.length
                  : currentPage * rowsPerPage
                : 0
            }
            totalRecords={
              centreSupData && centreSupData?.length > 0
                ? centreSupData?.length
                : 0
            }
            handlePrev={(e) => handlePrev(currentPage - 1)}
            currentPrevPage={currentPage === 1}
            prevButton="prev_buttonCentreSup  me-2"
            handleNext={() => handleNext(currentPage + 1)}
            currentNextPage={currentPage === totalPages}
          />
        </div>
      </div>
    </>
  );
}
export default CentreSupervisor;
