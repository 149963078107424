import React, { useEffect, useState } from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
export default function WarningPopUp(props) {
  const { event } = props;
  const [showModal, setShowModal] = useState(true);
  // New added
  const [count, setCount] = useState(60);

  useEffect(() => {
    const countdown = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === 0) {
          clearInterval(countdown);
          props.performApiBinding();
          setShowModal(false);
          return 0;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header className="warning-header" closeButton>
        <Modal.Title className="TitlemarginAuto">
          <h5 class="warning-title" id="exampleModalLabel">
            Warning!
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12  mb-3">
              <div>
                <p className="warning-name">
                  Hi {event.user_name ? event.user_name : ""}
                </p>
                {/* ------------------- */}
                <p className="warning-paragraph">
                  It looks like the screen has been idle for more than 2
                  minutes.
                </p>
                <p className="warning-paragraph">
                  To ensure the security and continuity of your test, please
                  return to the test now.
                </p>
                <p className="warning-paragraph">
                  Your session will be automatically terminated in
                </p>
                <p className="warning-count">{count} Seconds</p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="warning-footer">
        <button type="button" className="DashboardButton" onClick={handleClose}>
          <a href="javascript:void(0)">Go to Test</a>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
