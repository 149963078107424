import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const MAX_CHARACTERS = 12;

ChartJS.register(ArcElement, Tooltip, Legend);

function putTextCenterData(label) {
  const str = String(label);
  let convertedStr = "";
  const arr = Array.from({ length: MAX_CHARACTERS }, (_, i) => i);
  arr.map((ele) =>
    // (str[ele] ? `${convertedStr}${str[ele]}` : convertedStr));
    {
      if (str[ele]) {
        return (convertedStr = `${convertedStr}${str[ele]}`);
      }
    }
  );
  return str.length > MAX_CHARACTERS ? convertedStr + "..." : convertedStr;
}
const textCenter = {
  id: "textCenter",
  beforeDatasetsDraw(chart) {
    // args, pluginOptions
    const { ctx, data } = chart;
    ctx.save();
    ctx.font = "bolder 30px sans-serif";
    ctx.fillStyle = "#9E9E9E";
    ctx.textAlign = "center";
    ctx.Baseline = "middle";
    ctx.fillText(
      `${data.datasets[0].data[0]}`,
      chart.getDatasetMeta(0).data[0].x,
      chart.getDatasetMeta(0).data[0].y
    );
    ctx.font = "bold 15px sans-serif";
    ctx.fillText(
      `(Out of ${data.datasets[0].data[0] + data.datasets[0].data[1]})`,
      chart.getDatasetMeta(0).data[0].x,
      chart.getDatasetMeta(0).data[0].y + 15
    );
    ctx.font = "12px sans-serif";
    ctx.fillText(
      putTextCenterData(data.datasets[0].label),
      chart.getDatasetMeta(0).data[0].x,
      chart.getDatasetMeta(0).data[0].y + 30
    );
  },
};

export default function DonutChart({
  scoreData,
  totalScore,
  label,
  bgColor,
  bgcolor1,
}) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "proficiency analysis",
      },
      legend: {
        display: false,
        position: "top",
        reverse: true,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (funcData) {
            let title = "";
            title = funcData[0]?.dataset?.label;

            return title;
          },
          label: function (context) {
            let label = context?.label;
            if (label === "") {
              label = ` Total Ques : ${totalScore}`;
            } else {
              label = ` Correct Ques : ${
                context.dataset.data[context.dataIndex]
              } / ${totalScore}`;
            }
            return label;
          },
        },
      },
    },
  };
  const data = {
    labels: [label],
    datasets: [
      {
        label: label,
        data: [scoreData, totalScore - scoreData],
        backgroundColor: [bgColor, "rgba(231,231,231,1)"],
        borderColor: ["rgba(231, 231, 231, 1)", "rgba(231, 231, 231, 1)"],
        hoverBackgroundColor: [bgColor, "rgba(231,231,231,1)"],
        hoverBorderColor: [bgColor, "rgba(231,231,231,1)"],
        borderWidth: 3,
        borderRadius: 10,
        cutout: "80%",
        borderJoinStyle: "round",
        borderAlign: "inner",
        rotation: 0,
      },
    ],
  };

  return (
    <Doughnut
      options={options}
      data={data}
      width={150}
      height={300}
      plugins={[textCenter]}
    />
  );
}
