import React from "react";

export default function ExamFees(props) {
  return (
    <div className="col-md-6">
      <div className="forms ">
        <label for="fees">
          Fee for {props.record.id > 0 ? props.record.id : ""} Model{" "}
          {props.record.id > 1 ? "Exams" : "Exam"}{" "}
        </label>
        <input
          ref={props.txtBatchEFees}
          type="text"
          class="Inputs"
          id="fees"
          name="fees"
          placeholder="fees"
          value={props.record.fees < 0 ? 0 : props.record.fees}
          onChange={(e) => props.examdetailschange(e, props.record.id)}
        />
        {props.record.error_7 && (
          <small className="error">{props.record.error_7}</small>
        )}
      </div>
    </div>
  );
}
