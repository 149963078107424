import React, { useEffect, useRef, useState } from "react";
import NavigationButton from "../../../../../ui/NavigationButton";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function StartTest({
  startTime = new Date(),
  calculatedEndTime = new Date(),
  onClick,
  children,
  batch_id,
  event_id,
  endTime,
  attempt_no,
}) {
  const navigate = useNavigate();
  // const userTime = useRef(new Date());
  const [isAllowed, setIsAllowed] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAllowed(new Date() >= startTime && new Date() <= calculatedEndTime);
      if (new Date().getTime() === new Date(startTime).getTime()) {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [calculatedEndTime, startTime]);
  return (
    <NavigationButton className="ms-1" onClick={onClick} disabled={!isAllowed}>
      <Stack direction="row" alignItems="center" columnGap={1}>
        {children}
      </Stack>
    </NavigationButton>
  );
}
