import moment from "moment";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import QtypeOne from "../question/QtypeOne";
import QtypeTwo from "../question/QtypeTwo";
import QtypeThree from "../question/QtypeThree";
import QtypeFour from "../question/QtypeFour";
import QtypeFive from "../question/QtypeFive";
import Qtypesix from "../question/Qtypesix";
import Qtypeseven from "../question/Qtypeseven";
import Qtypeeight from "../question/Qtypeeight";
import Qtypenine from "../question/Qtypenine";
import QtypeTen from "../question/QtypeTen";
import QparaType from "../question/QparaType";
import Qquesparatype from "../question/Qquesparatype";
import styled from "styled-components";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import Select, { components } from "react-select";
import CloseIcon from "../../../../img/close.png";
import capRedFlag from "../../../../img/flagmark.png";
import capFlag from "../../../../img/flagclose.png";
import Swal from "sweetalert2";
import ModelComForP from "../../../../pages/ModelComForP";
import RedLogo from "../../../../img/Redlogo.png";
import FlagGreen from "../../../../img/flag_green.png";
import FlagRed from "../../../../img/flag_red.png";
import FlagWhite from "../../../../img/flg_white.png";
import { customStyles } from "../../../../pages/customStyles";
import PaymentSuccess from "./PaymentSuccess";
import WarningPopUp from "./WarningPopUp";
import Popup from "./pages/Popup";
import "./pages/ScreenShare.css";
import ScreenShare from "./pages/ScreenShare";
import AlertPopup from "./pages/AlertPopup";
import { X } from "@mui/icons-material";
import toast from "react-hot-toast";
import axios from "axios";
import { Dialog, DialogContent } from "@mui/material";
const MILLISECONDS_TO_SECONDS = 0.001;

const TestDetailsBox_submit = styled.div`
  border: none;
  padding: 14px 7px;
  margin: 7px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #ff6384 0%, #803242 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: "768px") {
    min-height: 140px;
  }
  @media (min-width: "991px") {
    min-height: 185px;
  }
`;

const TestDeatilsScore_submit = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-family: "Roboto";
  font-weight: var(--font-weight-medium);
  @media (min-width: "991px") {
    font-size: 36px;
  }
`;

const TestDetailsLabel_submit = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-family: "Roboto";
  @media (min-width: "991px") {
    font-size: 24px;
  }
`;

export default function JoinTestScreen() {
  const modalCloseRefTest = useRef(null);
  const [step, setStep] = useState(1);
  const [step_answer, setStep_answer] = useState(1);
  const [step_answers, setStep_answers] = useState(1);
  // const [isVisible, setIsVisible] = useState(!document.hidden);
  const [effStep, setEffStep] = useState(0);
  const [fillQuesSet, setFillQuesSet] = useState({});
  const [quesStep, setQuesStep] = useState(1);
  const buttonRef = useRef(null);
  const buttonRefs = useRef(null);
  const buttonRefAutomatic = useRef(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  // ----------Start----------------
  const [showPopup, setShowPopup] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [count, setCount] = useState(0);

  const handleContinue = () => {
    setShowPopup(false);
    setIsAlert(false);
    enterFullscreen();
    const newCount = count + 1;
    setCount(newCount);
    sessionStorage.setItem("buttonClickCount", newCount);
    localStorage.setItem("buttonClickCount", newCount);
    sessionStorage.removeItem("reloadAlert");
  };

  const enterFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      // IE/Edge
      elem.msRequestFullscreen();
    }
  };

  const displayAlertMessage = (message) => {
    setAlertMessage(message);
    setIsAlert(true);
    setShowPopup(true);
    sessionStorage.setItem("reloadAlert", message);
    localStorage.setItem("reloadAlert", message);
  };

  const preventRefresh = (event) => {
    event.preventDefault();
    // console.log("sessionStorage");
    sessionStorage.setItem("buttonClickCount", 0);
    localStorage.setItem("buttonClickCount", 0);

    event.returnValue = "";
    displayAlertMessage(
      "Please do not refresh the browser. Please remain on this page to attend the exam."
    );
    performApiBinding();
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      // displayAlertMessage(
      //   "Please do not navigate away. Please remain on this page to attend the exam."
      // );
      performApiBinding();
    }
  };
  const exitFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const handleFullscreenChange = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      setShowPopup(true);
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Escape" ||
      event.key === "Control" ||
      event.key === "Alt" ||
      event.key === "F5" ||
      event.key === "F11" ||
      event.key === "F1" ||
      event.key === "Shift"
    ) {
      // setShowPopup(true);
      event.preventDefault(); //new
      performApiBinding();
      exitFullscreen();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    window.addEventListener("beforeunload", preventRefresh);

    const reloadAlert = sessionStorage.getItem("reloadAlert");
    if (reloadAlert) {
      displayAlertMessage(reloadAlert);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("beforeunload", preventRefresh);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // --------------Start--(Mouse-Right click button disable)---
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Add the event listener when the component mounts
    document.addEventListener("contextmenu", handleContextMenu);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     setIsVisible(!document.hidden);
  //   };

  //   const updateFocusStatus = () => {
  //     if (document.hasFocus()) {
  //       // activeusercheck();
  //       // console.log("The window is focused");
  //       // statusElement.textContent = "The window is focused";
  //     } else {
  //       const ubb = JSON.parse(sessionStorage.getItem("userDetails"));
  //       if (ubb) {
  //         console.log(ubb.user_id, ubb);
  //       }
  //       const ubbs = JSON.parse(sessionStorage.getItem("active_user_count"));
  //       if (ubbs) {
  //         console.log(ubbs.user_id_active_user, ubbs);
  //       }
  //       // console.log("The window is not focused");
  //       // statusElement.textContent = "The window is not focused";
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log(`The tab is now ${isVisible ? "active" : "inactive"}`);
  //   if (isVisible) {
  //     console.log("Your Test Will be submitted ");
  //     performApiBinding();
  //   }
  // }, [isVisible]);

  // ----------end--------
  const [langSet, setLangSet] = useState([
    {
      value: 1,
      label: "English",
    },
    {
      value: 0,
      label: "Tamil",
    },
    {
      value: 2,
      label: "All",
      defaultSelected: true, // This indicates it's the default selection
    },
  ]);

  const [isSubmit, setIsSubmit] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [totalStep, setTotalStep] = useState();

  const [openModel, setOpenModel] = useState(false);

  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  const [totalStep_answer, setTotalStep_answer] = useState();
  let step_size = 50;
  let step_size_answer = 1;

  const navigate = useNavigate();
  const location = useLocation();
  const [parentPk, setParentPk] = useState([]);
  const [userInput, setUserInput] = useState([]);
  const [userInput_set, setUserInput_set] = useState([]);
  const [selectedLa, setSelectedLa] = useState(null);
  const [timefinish, setTimeFinish] = useState(false);
  const { batch_id, event_id, startTime, endTime, attempt_no } = location.state; //old code
  // const { batch_id, event_id, startTime, endTime, attempt_no } = location.state || {}; //New code

  const [quesSet, setQuesSet] = useState([]);

  const [prevPk, setPrevPk] = useState([]);

  const [quesTiming, setQuesTiming] = useState(null);

  const [sectionTab, setSectionTab] = useState(false);
  const [sectionTabLng, setSectionTabLng] = useState(false);
  const [sectionTabSub, setSectionTabSub] = useState([]);
  const [formData, setFormData] = useState({
    Lang: 2,
    LangText: "All",
  });
  const [stepSeven, setStepSeven] = useState(false);
  const [sub, setSub] = useState(false);

  const [timingHours, setTimingHours] = useState(null);
  const [eventName, setEventName] = useState({
    event_name: "",
    batch_name: "",
    user_profile_path: "",
    user_name: "",
    startDate: "",
  });
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [setStep_answer]);

  useEffect(() => {
    const effectiveStep = Math.ceil(quesSet.length / step_size);
    setEffStep(effectiveStep);
  }, [quesSet]);

  const navigateToPreviousScreen = () => {
    navigate(-2); // Navigate back by one step in the history
  };

  let pageSize = 50;
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }
  const [time, setTime] = useState(moment.duration(0));
  const [listData, setListData] = useState([]);

  const [timeDifference, setTimeDifference] = useState(
    moment.duration(0, "hours")
  );
  const [remainingTime, setRemainingTime] = useState(moment.duration(0));
  const refStartTime = useRef(new Date());
  const refEndTime = useRef(new Date());
  // 1.initial set up
  // 2.calculate time taken
  // 3.reset the time
  // DERIVED STATE
  const OPTIMUM_TIME_EACH_QUESTION = listData
    ? listData.length / ((new Date(endTime) - new Date(startTime)) * 0.001)
    : 0; //conver to seconds
  const TEST_DURATION = new Date(endTime) - new Date(startTime);
  const RetakeEndMoment = moment(new Date(Date.now() + TEST_DURATION));

  useEffect(() => {
    const startMoment = moment(startTime, "HH:mm:ss");
    const endMoment = moment(endTime, "HH:mm:ss");
    const durationMs = endMoment.diff(startMoment);
    const durationFormatted = moment.utc(durationMs).format("HH:mm:ss");

    setTimingHours(durationFormatted);

    const timer = setInterval(() => {
      const now = moment(data);

      // const timeDifference = moment.duration(endMoment.diff(now));
      const totalTime = moment.duration(endMoment.diff(startMoment));
      // const RetakeEndMoment=moment.add(totalTime,'milliseconds');
      // const RetakeEndMoment = now.clone().add(totalTime);
      let timeDifference = moment.duration(RetakeEndMoment.diff(now));
      if (attempt_no === 1) {
        timeDifference = moment.duration(endMoment.diff(now));
      } else {
        timeDifference = timeDifference.subtract(1, "seconds");
      }
      if (timeDifference.asMilliseconds() > 0) {
        setTimeDifference(timeDifference);
        setTime(timeDifference); // Update the time state here
      } else {
        clearInterval(timer);
      }
    }, 1000);
    const remainingTimer = setInterval(() => {
      const now = moment(data);
      const remainingTime = moment.duration(now.diff(startMoment));
      if (remainingTime.asMilliseconds() >= durationMs) {
        if (attempt_no === 1) {
          performApiBinding(); // Call the API here when the timer reaches 00:00:00
        }
        // else {
        //   toast.success("Please Submit Your Exam.", {
        //     autoClose: 6000, // 3 seconds (3000ms) + default 3 seconds (3000ms)
        //   });
        // }
        clearInterval(remainingTimer);
        setTime(moment.duration(0));
      }
    }, 1000);
    return () => {
      clearInterval(timer);
      clearInterval(remainingTimer);
      setTime(moment.duration(0));
    };
  }, []);
  const ref = useRef;
  const [data, setData] = useState([]);
  const [formattedTime, setFormattedTime] = useState("");
  // console.log(data, "data");

  const [serverTime, setServerTime] = useState("");
  // const server_time = serverTime.split(" ")[1];
  // console.log(serverTime, "serverTime123");

  // Split the time into hours, minutes, and seconds
  // const [hours24, minutes, seconds] = server_time.split(":");

  // Convert hours to 12-hour format
  // let hours12 = parseInt(hours24, 10);
  // const suffix = hours12 >= 12 ? "PM" : "AM";
  // hours12 = hours12 % 12 || 12; // Handle midnight (0 hours)

  // Format the time in 12-hour format
  // const time12 = `${hours12}:${minutes}:${seconds} `;

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchServerTime();
  //   }, 10000); // Update every second

  //   return () => clearInterval(interval); // Clean up on unmount
  // }, []);

  // const fetchServerTime = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://aiasadev.annaiasacademy.com/api/api/server_time"
  //     ); // Replace with your API endpoint
  //     const data = await response.data;
  //     setServerTime(data.time); // Update the server time in state
  //   } catch (error) {
  //     console.error("Error fetching server time:", error);
  //   }
  // };

  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // useEffect(() => {
  //   // Define the async function inside useEffect
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://aiasadev.annaiasacademy.com/api/api/server_time"
  //       );
  //       setData(response.data.time);
  //     } catch (err) {
  //       setError(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);
  // useEffect(() => {
  //   if (data.time) {
  //     const timeOnly = moment(data.time).format("HH:mm:ss");
  //     setFormattedTime(timeOnly);
  //   }
  // }, [data]);

  const apiUrl = "https://aiasadev.annaiasacademy.com/api/api/server_time";

  // const getServerAdjustedTime = async () => {
  //   try {
  //     const { data: serverTime } = await apiUrl.getServerTime();

  //     const computedServerMillisecondsOffset = moment(serverTime).diff(
  //       moment()
  //     );

  //     const updateTime = () => {
  //       const currentDateTimeMoment = moment().add(
  //         computedServerMillisecondsOffset,
  //         "milliseconds"
  //       );
  //       // You can also update the time on the UI if needed
  //       document.getElementById("time-display").innerText =
  //         currentDateTimeMoment.format("YYYY-MM-DDTHH:mm:ss");
  //     };

  //     updateTime(); // Initial call to display the time immediately
  //     setInterval(updateTime, 1000); // Update the time every second
  //   } catch (error) {
  //     console.error("Error fetching server time:", error);
  //   }
  // };

  // Call the function to start the time updates
  // getServerAdjustedTime();
  // console.log(getServerAdjustedTime, "getServerAdjustedTime");

  // Usage
  // getServerAdjustedTime().then((currentDateTimeMoment) => {
  //   console.log("Current adjusted time:", currentDateTimeMoment.format());
  // });

  useEffect(() => {
    // Calculate the effective step based on the length of listData
    const effectiveStepsss = Math.ceil(listData.length / 1);
    setQuesStep(effectiveStepsss);
  }, [listData]);

  // question list api load

  useEffect(() => {
    questionList(batch_id, event_id, userid);
  }, []);

  const questionList = (bid, eventid, usid) => {
    AxiosInstance.post("api/test/questionlist", {
      batch_id: parseInt(bid),
      event_id: parseInt(eventid),
      user_id: usid,
    })
      .then((res) => {
        if (res.data.status === true) {
          let ut = res.data.data.que_list;
          setListData(ut);
          let eventNamess = res.data.data.info;
          setEventName({
            event_name: eventNamess.event_type_title,
            startDate: eventNamess.start_date,
            batch_name: eventNamess.batch_name,
            user_profile_path: eventNamess.profile_path,
            user_name: `${eventNamess.first_name} ${eventNamess.last_name}`,
          });

          let data_length = ut.length;
          const number_of_steps = Math.ceil(data_length / step_size);
          setTotalStep(number_of_steps);
          const number_of_steps_answer = Math.ceil(
            data_length / step_size_answer
          );
          setTotalStep_answer(number_of_steps_answer);
          const updatedData = ut.map((item, index) => ({
            pk: item.pk,
            subject_type: item.subject_type,
            lang_type: item.lang_type,
            is_flag: false,
            is_fill: false,
            is_not_visited: index === 0 ? false : true,
            is_un_fill: true,
          }));
          setQuesSet(updatedData);
          const userInput = ut
            .filter((item, index) => index === 0 && item.ques_no === 1)
            .map((item) => ({
              pk: item.pk,
              subject_type: item.subject_type,
              lang: item.lang_type,
              userOption: null,
              UserOptionSec: null,
            }));
          // setUserInput(userInput);
          setUserInput_set(userInput);
          setPrevPk(
            ut.filter(
              (item, index) => index === 0 && item.ques_no === 1 && item.pk
            )
          );
          const sectionTabEn = ut.every((item) => item.section_tab === 1);
          setSectionTab(sectionTabEn);
          const sectionTabEnLnag = ut.every((item) => item.lang_type === 2);
          setSectionTabLng(sectionTabEnLnag);
          const sectionTabEnSub = [];
          const subjectTypeSet = new Set();

          ut.forEach((item) => {
            if (
              item.section_tab === 1 &&
              !subjectTypeSet.has(item.subject_overall)
            ) {
              subjectTypeSet.add(item.subject_overall);
              sectionTabEnSub.push({
                subject: item.subject_overall,
                pk: item.pk,
              });
            }
          });

          setSectionTabSub(sectionTabEnSub);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  // get seconds

  function getSeconds(timeString) {
    // Parse the time string as a moment object
    const time = moment(timeString, "HH:mm:ss");

    // Get the total seconds
    const totalSeconds =
      time.hours() * 3600 + time.minutes() * 60 + time.seconds();

    return totalSeconds;
  }

  function calculateTimePerQuestion(totalSeconds, numberOfQuestions) {
    // Calculate the time per question
    const timePerQuestion = totalSeconds / numberOfQuestions;
    return timePerQuestion;
  }

  function getTimeDifferenceInSeconds(startTimeString, endTimeString) {
    // Parse the time strings as moment objects
    const startTime = moment(startTimeString, "HH:mm:ss");
    const endTime = moment(endTimeString, "HH:mm:ss");
    // Calculate the difference in seconds
    const differenceInSeconds = endTime.diff(startTime, "seconds");

    return differenceInSeconds;
  }

  const handleUser_unselect = (pk) => {
    var unanswerData = userInput.filter((obj) => obj.pk === pk);
    setUserInput((prevData) => prevData.filter((obj) => obj.pk !== pk));
    // setUserInput_set((prevData) => prevData.filter((obj) => obj.pk !== pk));
    setUserInput_set((prevData) => {
      return prevData.map((item) => {
        if (item.pk === pk) {
          return {
            ...item,
            UserOptionSec: null,
          };
        }
        return item;
      });
    });
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === pk) {
          return {
            ...item,
            is_fill: false,
            is_flag: item.is_flag,
            is_un_fill: !item.is_un_fill,
          };
        }
        return item;
      });
    });
    // const timeTaken =
    //   parseInt(new Date() - refStartTime.current) * MILLISECONDS_TO_SECONDS;

    // let dat = {
    //   batch_id: batch_id,
    //   event_id: event_id,
    //   question_pk: unanswerData[0].pk,
    //   user_answer: null,
    //   language: unanswerData[0].lang,
    //   subject_name: unanswerData[0].subject_type,
    //   time_taken: timeTaken,
    //   attempt_no: attempt_no ? attempt_no : 1,
    //   optimum_time: OPTIMUM_TIME_EACH_QUESTION,
    //   user_id: userid,
    //   created_by: userid,
    //   updated_by: userid,
    // };

    // AxiosInstance.post("api/test/answer", dat)
    //   .then((res) => {
    //     if (res.data.status === true) {
    //       refStartTime.current = new Date();
    //     }
    //   })
    //   .catch((error) => {
    //     return error;
    //   });
    // update time taken by the user
  };

  const handleBook = (pk) => {
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === pk) {
          // console.log(item, "item", item.pk);
          return {
            ...item,
            is_flag: !item.is_flag,
          };
        }

        return item;
      });
    });
    setListData((prevData) => {
      return prevData.map((item) => {
        if (item.pk === pk) {
          return {
            ...item,
            // Toggle the value of is_flag
            is_bookmark: !item.is_bookmark,
          };
        }
        // else if (item.data && item.data.some((nestedItem) => nestedItem.pk === pk)) {
        //   return {
        //     ...item,
        //     // Use map to update the is_bookmark for each nested item in data
        //     data: item.data.map((nestedItem) =>
        //       nestedItem.pk === pk
        //         ? { ...nestedItem, is_bookmark: !nestedItem.is_bookmark }
        //         : nestedItem
        //     ),
        //   };
        // }
        return item;
      });
    });
  };

  // handle change function for answer option

  const handleOptionChange = (
    event,
    pk,
    batch_id,
    event_pk,
    user_id,
    lang,
    option,
    subject_type
  ) => {
    const { value, name, files } = event.target;

    setUserInput((prevState) => {
      const existingIndex = prevState.findIndex((item) => item.pk === pk);
      if (existingIndex !== -1) {
        // Update existing entry in the state
        const updatedItem = {
          ...prevState[existingIndex],
          userOption: value,
          UserOptionSec: option,
          lang: lang,
          subject_type: subject_type,
        };
        const updatedState = [...prevState];
        updatedState[existingIndex] = updatedItem;
        return updatedState;
      } else {
        // Add new entry to the state
        return [
          ...prevState,
          {
            pk,
            userOption: value,
            UserOptionSec: option,
            lang: lang,
            subject_type: subject_type,
          },
        ];
      }
    });
    setUserInput_set((prevState) => {
      const existingIndex = prevState.findIndex((item) => item.pk === pk);
      if (existingIndex !== -1) {
        // Update existing entry in the state
        const updatedItem = {
          ...prevState[existingIndex],
          userOption: value,
          UserOptionSec: option,
          lang: lang,
          subject_type: subject_type,
        };
        const updatedState = [...prevState];
        updatedState[existingIndex] = updatedItem;
        return updatedState;
      } else {
        // Add new entry to the state
        return [
          ...prevState,
          {
            pk,
            userOption: value,
            UserOptionSec: option,
            lang: lang,
            subject_type: subject_type,
          },
        ];
      }
    });
    setSelectedLa(lang);
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === pk) {
          return {
            ...item,
            is_fill: true,
            is_un_fill: false,
            is_not_visited: false,
          };
        }
        return item;
      });
    });
  };

  //question set click means need to update stepper

  const handleStepClick = (stepnum, elements) => {
    const lastActiveQuestion = step_answer;
    const timeTaken =
      parseInt(new Date() - refStartTime.current) * MILLISECONDS_TO_SECONDS;
    setStep_answer(stepnum);
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === elements.pk) {
          return {
            ...item,
            is_not_visited: false,
          };
        }
        return item;
      });
    });

    const currentIndex = quesSet.findIndex((op) => op.pk === elements.pk);
    const prevEl = currentIndex ? quesSet[currentIndex - 1] : { pk: 0 };

    const njk =
      prevEl && prevPk.length > 0
        ? userInput_set.filter((kl) => kl.pk === prevPk[0].pk).length > 0
          ? userInput_set.filter((kl) => kl.pk === prevPk[0].pk)
          : listData
              .filter((io) => io.pk === prevPk[0].pk)
              .map((kl) => ({
                pk: kl.pk,
                UserOptionSec: null,
                lang: kl.lang_type,
                subject_type: kl.subject_type,
              }))
        : 0 === prevEl.pk
        ? userInput_set.filter((kl) => kl.pk === prevPk[0].pk).length > 0
          ? userInput_set.filter((kl) => kl.pk === prevPk[0].pk)
          : listData
              .filter((io) => io.pk === prevPk[0].pk)
              .map((kl) => ({
                pk: kl.pk,
                UserOptionSec: null,
                lang: kl.lang_type,
                subject_type: kl.subject_type,
              }))
        : userInput_set.filter((kl) =>
            kl.pk === prevPk.length > 0 ? prevPk[0].pk : 0
          ).length > 0
        ? userInput_set.filter((kl) =>
            kl.pk === prevPk.length > 0 ? prevPk[0].pk : 0
          )
        : listData
            .filter((io) => (io.pk === prevPk.length > 0 ? prevPk[0].pk : 0))
            .map((kl) => ({
              pk: kl.pk,
              UserOptionSec: null,
              lang: kl.lang_type,
              subject_type: kl.subject_type,
            }));

    setPrevPk(listData.filter((io) => io.pk === elements.pk));
    let dat = {};
    dat = {
      batch_id: batch_id,
      event_id: event_id,
      question_pk: njk.length > 0 ? njk[0].pk : "",
      user_answer: njk.length > 0 ? njk[0].UserOptionSec : "",
      language: njk.length > 0 ? njk[0].lang : "",
      subject_name: njk.length > 0 ? njk[0].subject_type : "",
      time_taken: timeTaken,
      attempt_no: attempt_no ? attempt_no : 1,
      optimum_time: OPTIMUM_TIME_EACH_QUESTION,
      user_id: userid,
      created_by: userid,
      updated_by: userid,
    };
    setIsPaymentLoading(true);
    AxiosInstance.post("api/test/answer", dat)
      .then((res) => {
        if (res.data.status === true) {
          setIsPaymentLoading(false);
          refStartTime.current = new Date();
        } else {
          setIsPaymentLoading(false);
        }
      })
      .catch((error) => {
        setIsPaymentLoading(false);
        return error;
      });
    if (window.innerWidth <= 768) {
      // Mobile view (example threshold: 768px)
      window.scrollTo({
        top: 570, // Scroll position for mobile
        behavior: "smooth",
      });
    } else {
      // Desktop view
      window.scrollTo({
        top: 0, // Scroll position for desktop
        behavior: "smooth",
      });
    }
  };

  const handleSubmit = async (
    time,
    to_qu,
    ans_ques,
    ans_ques_mark,
    qu_not_v,
    unans_ques,
    unans_mark
  ) => {
    // // If userInput_set has only one item, call updateanswer
    if (prevPk.length === 1) {
      try {
        const bhb =
          userInput_set.filter((kl) => kl.pk === prevPk[0].pk).length > 0
            ? userInput_set.filter((kl) => kl.pk === prevPk[0].pk)
            : listData
                .filter((io) => io.pk === prevPk[0].pk)
                .map((kl) => ({
                  pk: kl.pk,
                  UserOptionSec: null,
                  lang: kl.lang_type,
                  subject_type: kl.subject_type,
                }));
        // console.log(bhb, "bhb");
        let updateResponse = await updateanswer(
          time,
          to_qu,
          ans_ques,
          ans_ques_mark,
          qu_not_v,
          unans_ques,
          unans_mark,
          bhb
        );
      } catch (error) {
        console.error("Error during first try of updateanswer:", error);
        return; // Stop execution if an error occurs
      }
    } else {
      // Calculate the duration completed
      const startMoment = moment(startTime, "HH:mm:ss");
      const now = moment(data);
      const remainingTime = moment.duration(now.diff(startMoment));
      const differCal = moment
        .utc(remainingTime.asMilliseconds())
        .format("HH:mm:ss");

      // Prepare the data object to send in the POST request
      let datb = {
        batch_id: batch_id,
        event_id: event_id,
        user_id: userid,
        duration_completed: differCal,
        created_by: userid,
        ans_que: ans_ques,
        ans_que_mr: ans_ques_mark,
        unans_que: unans_ques,
        unans_que_mr: unans_mark,
        que_nyv: qu_not_v,
        attempt_no: attempt_no ? attempt_no : 1,
      };
      setIsPaymentLoading(true);
      // Make the POST request
      AxiosInstance.post("api/test/report/add", datb)
        .then((res) => {
          if (res.data.status === true) {
            setIsPaymentLoading(false);
            setIsSubmit(true);
            setStepSeven(true);
            exitFullscreen();
            // setShowScreenShare(false);

            // Close the modal if it's open
            const modal = document.querySelector("#InstrModel"); // Adjust the selector as needed
            const modal_close = modalCloseRefTest.current;
            if (modal && modal_close) {
              modal_close.click();
            }
            // console.log(eventName, "eventName");
            // Navigate to PaymentSuccess page
            navigate("/ViewSchedule/ViewTest/JoinTest", {
              state: {
                batchID: batch_id,
                eventID: event_id,
                userID: userid,
                finishTime: time,
                eventName: eventName.event_name ? eventName.event_name : "",
                user_name: eventName.user_name ? eventName.user_name : "",
                startDate: eventName.startDate ? eventName.startDate : "",
              },
            });
          } else {
            setIsPaymentLoading(false);
            setIsSubmit(true);
            exitFullscreen();
            const modal = document.querySelector("#InstrModel"); // Adjust the selector as needed
            const modal_close = modalCloseRefTest.current;
            if (modal && modal_close) {
              modal_close.click();
            }
            // navigateToPreviousScreen();
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  function addapi(
    time,
    to_qu,
    ans_ques,
    ans_ques_mark,
    qu_not_v,
    unans_ques,
    unans_mark
  ) {
    // Calculate the duration completed
    const startMoment = moment(startTime, "HH:mm:ss");
    const now = moment(data);
    const remainingTime = moment.duration(now.diff(startMoment));
    const differCal = moment
      .utc(remainingTime.asMilliseconds())
      .format("HH:mm:ss");

    // Prepare the data object to send in the POST request
    let datb = {
      batch_id: batch_id,
      event_id: event_id,
      user_id: userid,
      duration_completed: differCal,
      created_by: userid,
      ans_que: ans_ques,
      ans_que_mr: ans_ques_mark,
      unans_que: unans_ques,
      unans_que_mr: unans_mark,
      que_nyv: qu_not_v,
      attempt_no: attempt_no ? attempt_no : 1,
    };
    setIsPaymentLoading(true);
    // Make the POST request
    AxiosInstance.post("api/test/report/add", datb)
      .then((res) => {
        if (res.data.status === true) {
          setIsPaymentLoading(false);
          setIsSubmit(true);
          setStepSeven(true);
          exitFullscreen();
          // setShowScreenShare(false);

          // Close the modal if it's open
          const modal = document.querySelector("#InstrModel"); // Adjust the selector as needed
          const modal_close = modalCloseRefTest.current;
          if (modal && modal_close) {
            modal_close.click();
          }
          // Navigate to PaymentSuccess page
          navigate("/ViewSchedule/ViewTest/JoinTest", {
            state: {
              batchID: batch_id,
              eventID: event_id,
              userID: userid,
              finishTime: time,
              eventName: eventName.event_name ? eventName.event_name : "",
              user_name: eventName.user_name ? eventName.user_name : "",
              startDate: eventName.startDate ? eventName.startDate : "",
            },
          });
        } else {
          setIsPaymentLoading(false);
          setIsSubmit(true);
          // setStepSeven(true);
          exitFullscreen();
          const modal = document.querySelector("#InstrModel"); // Adjust the selector as needed
          const modal_close = modalCloseRefTest.current;
          if (modal && modal_close) {
            modal_close.click();
          }
          // navigateToPreviousScreen();
        }
      })
      .catch((error) => {
        return error;
      });
  }

  const handleNext = () => {
    if (step < totalStep) {
      setStep(step + 1);
    } else {
      setStep(1); // Reset to the first step when reaching the end
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      setStep(totalStep); // Go back to the last step when at the first step
    }
  };

  const handlePrevious_answer = (elements, index) => {
    const timeTaken =
      (new Date() - refStartTime.current) * MILLISECONDS_TO_SECONDS;

    setQuesSet((prevData) => {
      return prevData.map((item, kl) => {
        if (kl === index - 1) {
          return {
            ...item,
            is_not_visited: false,
          };
        }
        return item;
      });
    });

    const PrevcurrentIndex = listData.findIndex((op) => op.pk === elements.pk);
    const prevElPPPP = PrevcurrentIndex ? listData[PrevcurrentIndex - 1] : null;
    setPrevPk([prevElPPPP]);

    let dat = {
      batch_id: batch_id,
      event_id: event_id,
      question_pk:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === elements.pk).length > 0
          ? userInput_set.filter((op) => op.pk === elements.pk)[0].pk
          : elements.pk,
      user_answer:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === elements.pk).length > 0
          ? userInput_set.filter((op) => op.pk === elements.pk)[0].UserOptionSec
          : null,
      language:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === elements.pk).length > 0
          ? userInput_set.filter((op) => op.pk === elements.pk)[0].lang
          : elements.lang_type,
      subject_name:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === elements.pk).length > 0
          ? userInput_set.filter((op) => op.pk === elements.pk)[0].subject_type
          : elements.subject_type,
      time_taken: timeTaken,
      attempt_no: attempt_no ? attempt_no : 1,
      optimum_time: OPTIMUM_TIME_EACH_QUESTION,
      user_id: userid,
      created_by: userid,
      updated_by: userid,
    };
    setIsPaymentLoading(true);
    AxiosInstance.post("api/test/answer", dat)
      .then((res) => {
        if (res.data.status === true) {
          setIsPaymentLoading(false);
          refStartTime.current = new Date();
        } else {
          setIsPaymentLoading(false);
        }
      })
      .catch((error) => {
        setIsPaymentLoading(false);
        console.error("Error in API call:", error);
      });

    if (step_answer > 1) {
      setStep_answer(step_answer - 1); // Corrected variable name
    } else {
      setStep_answer(totalStep_answer); // Corrected variable name
    }

    if ((step_answer - 1) % 50 === 0 && step_answer > 1) {
      handlePrevious(); // Assuming handlePrevious is defined and handles navigation
    }
    if (window.innerWidth <= 768) {
      // Mobile view (example threshold: 768px)
      window.scrollTo({
        top: 570, // Scroll position for mobile
        behavior: "smooth",
      });
    } else {
      // Desktop view
      window.scrollTo({
        top: 0, // Scroll position for desktop
        behavior: "smooth",
      });
    }
  };

  const handleNext_answer = (elements, index) => {
    const timeTaken =
      (new Date() - refStartTime.current) * MILLISECONDS_TO_SECONDS;
    setQuesSet((prevData) => {
      return prevData.map((item, ik) => {
        if (ik === index + 1) {
          return {
            ...item,
            is_not_visited: false,
          };
        }
        return item;
      });
    });
    const clickedTiming = moment.utc(time.asMilliseconds()).format("HH:mm:ss");
    setQuesTiming(clickedTiming);

    const PrevcurrentIndex = listData.findIndex((op) => op.pk === elements.pk);
    const prevElPPPP = PrevcurrentIndex ? listData[PrevcurrentIndex + 1] : null;
    setPrevPk([prevElPPPP]);

    let dat = {
      batch_id: batch_id,
      event_id: event_id,
      question_pk:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === elements.pk).length > 0
          ? userInput_set.filter((op) => op.pk === elements.pk)[0].pk
          : elements.pk,
      user_answer:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === elements.pk).length > 0
          ? userInput_set.filter((op) => op.pk === elements.pk)[0].UserOptionSec
          : null,
      language:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === elements.pk).length > 0
          ? userInput_set.filter((op) => op.pk === elements.pk)[0].lang
          : elements.lang_type,
      subject_name:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === elements.pk).length > 0
          ? userInput_set.filter((op) => op.pk === elements.pk)[0].subject_type
          : elements.subject_type,
      time_taken: timeTaken,
      attempt_no: attempt_no ? attempt_no : 1,
      optimum_time: OPTIMUM_TIME_EACH_QUESTION,
      user_id: userid,
      created_by: userid,
      updated_by: userid,
    };
    setIsPaymentLoading(true);
    AxiosInstance.post("api/test/answer", dat)
      .then((res) => {
        if (res.data.status === true) {
          setIsPaymentLoading(false);
          refStartTime.current = new Date();
          if (step_answer < totalStep_answer) {
            setStep_answer(step_answer + 1);
          } else {
            setStep_answer(1);
          }
        } else {
          setIsPaymentLoading(false);
        }
      })
      .catch((error) => {
        setIsPaymentLoading(false);
        return error;
      });
    if (step_answer % 50 === 0) {
      handleNext();
    }

    if (window.innerWidth <= 768) {
      // Mobile view (example threshold: 768px)
      window.scrollTo({
        top: 570, // Scroll position for mobile
        behavior: "smooth",
      });
    }
    // else if (window.innerWidth <= 960) {
    //   // Mobile view (example threshold: 768px)
    //   window.scrollTo({
    //     top: 900, // Scroll position for mobile
    //     behavior: "smooth",
    //   });
    // }
    else {
      // Desktop view
      window.scrollTo({
        top: 0, // Scroll position for desktop
        behavior: "smooth",
      });
    }
  };

  const handleCheckUserInput = (element) => {
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === element.pk) {
          return {
            ...item,
            is_not_visited: false,
          };
        }
        return item;
      });
    });

    // console.log(userInput_set, "user submit button", userInput);

    const startMoment = moment(startTime, "HH:mm:ss");
    const endMoment = moment(endTime, "HH:mm:ss");
    const durationMs = endMoment.diff(startMoment);
    const hours = String(timeDifference.hours()).padStart(2, "0");
    const minutes = String(timeDifference.minutes()).padStart(2, "0");
    const seconds = String(timeDifference.seconds()).padStart(2, "0");
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    const totalQuestion = quesSet.length;
    const TimeTakenSeconds = durationMs - totalSeconds;
    const timeTaken =
      (new Date() - refStartTime.current) * MILLISECONDS_TO_SECONDS;
    let dat = {
      batch_id: batch_id,
      event_id: event_id,
      question_pk:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === element.pk).length > 0
          ? userInput_set.filter((op) => op.pk === element.pk)[0].pk
          : element.pk,
      user_answer:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === element.pk).length > 0
          ? userInput_set.filter((op) => op.pk === element.pk)[0].UserOptionSec
          : null,
      language:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === element.pk).length > 0
          ? userInput_set.filter((op) => op.pk === element.pk)[0].lang
          : element.lang_type,
      subject_name:
        userInput_set.length > 0 &&
        userInput_set.filter((op) => op.pk === element.pk).length > 0
          ? userInput_set.filter((op) => op.pk === element.pk)[0].subject_type
          : element.subject_type,
      // time_taken: TimeTakenSeconds,
      time_taken: timeTaken,
      attempt_no: attempt_no ? attempt_no : 1,
      optimum_time: durationMs / totalQuestion,
      user_id: userid,
      created_by: userid,
      updated_by: userid,
    };
    setIsPaymentLoading(true);
    AxiosInstance.post("api/test/answer", dat)
      .then((res) => {
        if (res.data.status === true) {
          setIsPaymentLoading(false);
        } else {
          setIsPaymentLoading(false);
        }
      })
      .catch((error) => {
        setIsPaymentLoading(false);
        return error;
      });
    window.scrollTo({
      top: 570, // Replace 500 with the desired scroll position in pixels
      behavior: "smooth",
    });
  };

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleSelectCH = (option, dname) => {
    if (dname === "Lang") {
      setFormData((prevState) => ({
        ...prevState,
        Lang: option.value,
        LangText: option.label,
      }));
    }
  };

  const handleSectionactive = (ed) => {
    return listData.some((el, index) => {
      return (
        index + 1 === step_answer &&
        // el.pk === ed.pk &&
        el.subject_type === ed.subject
      );
    });
  };
  // console.log("swastic", eventName);
  // -------------New Added------------
  const [showAlert, setShowAlert] = useState(false);
  const [idleTime, setIdleTime] = useState(0);
  const [popupClosed, setPopupClosed] = useState(true); // Track if the pop-up is closed

  // Function to reset idle time
  const resetIdleTime = () => {
    setIdleTime(0);
  };

  // Event listener to reset idle time when user interacts with the page
  useEffect(() => {
    const resetIdle = () => {
      resetIdleTime();
    };

    // Attach event listeners conditionally based on the pop-up state
    if (popupClosed) {
      document.addEventListener("mousemove", resetIdle);
      document.addEventListener("keydown", resetIdle);
    } else {
      document.removeEventListener("mousemove", resetIdle);
      document.removeEventListener("keydown", resetIdle);
    }

    return () => {
      document.removeEventListener("mousemove", resetIdle);
      document.removeEventListener("keydown", resetIdle);
    };
  }, [popupClosed]); // Re-run effect when popupClosed changes

  // Function to start idle timer
  useEffect(() => {
    const idleTimer = setInterval(() => {
      setIdleTime((prevIdleTime) => prevIdleTime + 1);
    }, 1000);

    return () => clearInterval(idleTimer);
  }, []);

  // Check if no activity for 20 seconds and show alert
  useEffect(() => {
    if (idleTime >= 120 && !showAlert) {
      setShowAlert(true);
    } else if (idleTime < 120 && showAlert) {
      setShowAlert(false);
    }
  }, [idleTime, showAlert]);

  // Function to handle pop-up close
  const handlePopupClose = () => {
    setShowAlert(false);
    setPopupClosed(true); // Update pop-up state when closed
  };
  const performApiBinding = () => {
    // if (attempt_no === 1) {
    if (buttonRef.current) {
      buttonRef.current.click();
    } else {
      handleSubmit(
        moment.utc(time.asMilliseconds()).format("HH:mm:ss"),
        quesSet.length > 0 ? quesSet.length : 0,
        quesSet.length > 0
          ? quesSet.filter((el) => el.is_fill === true).length
          : 0,
        quesSet.length > 0
          ? quesSet.filter((el) => el.is_flag === true && el.is_fill === true)
              .length
          : 0,
        quesSet.length > 0
          ? quesSet.filter((el) => el.is_not_visited).length
          : 0,
        quesSet.length > 0 ? quesSet.filter((el) => el.is_un_fill).length : 0,
        quesSet.length > 0
          ? quesSet.filter(
              (el) => el.is_flag === true && el.is_un_fill === true
            ).length
          : 0
      );
    }
    // }
  };

  function updateanswer(
    time,
    to_qu,
    ans_ques,
    ans_ques_mark,
    qu_not_v,
    unans_ques,
    unans_mark,
    ele
  ) {
    const startMoment = moment(startTime, "HH:mm:ss");
    const endMoment = moment(endTime, "HH:mm:ss");
    const durationMs = endMoment.diff(startMoment);
    const hours = String(timeDifference.hours()).padStart(2, "0");
    const minutes = String(timeDifference.minutes()).padStart(2, "0");
    const seconds = String(timeDifference.seconds()).padStart(2, "0");
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    const totalQuestion = quesSet.length;
    const TimeTakenSeconds = durationMs - totalSeconds;
    const timeTaken =
      (new Date() - refStartTime.current) * MILLISECONDS_TO_SECONDS;
    setQuesSet((prevData) => {
      return prevData.map((item) => {
        if (item.pk === userInput_set.filter((op) => op.pk === ele[0].pk)) {
          return {
            ...item,
            is_not_visited: false,
          };
        }
        return item;
      });
    });
    let dat = {
      batch_id: batch_id,
      event_id: event_id,
      question_pk: ele[0].pk,
      user_answer: ele[0].UserOptionSec,
      language: ele[0].lang,
      subject_name: ele[0].subject_type,
      // time_taken: TimeTakenSeconds,
      time_taken: timeTaken,
      attempt_no: attempt_no ? attempt_no : 1,
      optimum_time: durationMs / totalQuestion,
      user_id: userid,
      created_by: userid,
      updated_by: userid,
    };
    setIsPaymentLoading(true);
    AxiosInstance.post("api/test/answer", dat)
      .then((res) => {
        if (res.data.status === true) {
          setIsPaymentLoading(false);
          const startMoment = moment(startTime, "HH:mm:ss");
          const now = moment(data);
          const remainingTime = moment.duration(now.diff(startMoment));
          const differCal = moment
            .utc(remainingTime.asMilliseconds())
            .format("HH:mm:ss");
          // Prepare the data object to send in the POST request
          let datb = {
            batch_id: batch_id,
            event_id: event_id,
            user_id: userid,
            duration_completed: differCal,
            created_by: userid,
            ans_que: ans_ques,
            ans_que_mr: ans_ques_mark,
            unans_que: unans_ques,
            unans_que_mr: unans_mark,
            que_nyv: qu_not_v,
            attempt_no: attempt_no ? attempt_no : 1,
          };
          setIsPaymentLoading(true);
          // Make the POST request
          AxiosInstance.post("api/test/report/add", datb)
            .then((res) => {
              if (res.data.status === true) {
                setIsSubmit(true);
                setStepSeven(true);
                exitFullscreen();
                setIsPaymentLoading(false);
                // setShowScreenShare(false);

                // Close the modal if it's open
                const modal = document.querySelector("#InstrModel"); // Adjust the selector as needed
                const modal_close = modalCloseRefTest.current;
                if (modal && modal_close) {
                  modal_close.click();
                }
                // Navigate to PaymentSuccess page
                navigate("/ViewSchedule/ViewTest/JoinTest", {
                  state: {
                    batchID: batch_id,
                    eventID: event_id,
                    userID: userid,
                    finishTime: time,
                    eventName: eventName.event_name ? eventName.event_name : "",
                    user_name: eventName.user_name ? eventName.user_name : "",
                    startDate: eventName.startDate ? eventName.startDate : "",
                  },
                });
              } else {
                setIsSubmit(true);
                setIsPaymentLoading(false);
                exitFullscreen();
                const modal = document.querySelector("#InstrModel"); // Adjust the selector as needed
                const modal_close = modalCloseRefTest.current;
                if (modal && modal_close) {
                  modal_close.click();
                }
                // navigateToPreviousScreen();
              }
            })
            .catch((error) => {
              return error;
            });
          // return true;
        } else {
          setIsPaymentLoading(false);
        }
      })
      .catch((error) => {
        setIsPaymentLoading(false);
        return error;
      });
  }

  return (
    <>
      {/* ------------start------------- */}
      {showPopup && (
        <Popup
          onContinue={handleContinue}
          message={alertMessage}
          performApiBinding={performApiBinding}
          isAlert={isAlert}
        />
      )}
      {!showPopup && (
        // ---------------end-------------------

        <div className="container-fluid col-md-12 col-sm-12">
          <img src="/favicon.png" className="bg-logo" />
          <div className="BathDet">
            <div className="row">
              {stepSeven === false && (
                <div className="col-md-12">
                  {swalMsg.show === true && (
                    <ModelComForP
                      show={swalMsg.show}
                      message={swalMsg.message}
                      status={swalMsg.status}
                      title={swalMsg.title}
                      BottomOkBtn={swalMsg.BottomOkBtn}
                      BottomCancelBtn={swalMsg.BottomCancelBtn}
                      handleCloseModels={handleCloseModel}
                      // handleClose_yes={handleSPayfee}
                      BottomOkClose={swalMsg.BottomOkClose}
                      handleCloseModels_Succ={handleCloseModels_Succ}
                    />
                  )}
                  {/* TITLE */}
                  <div className="row">
                    <section className="StepOne">
                      <div
                        className="heading heading_column  mt-3 d-flex  "
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="col-md-3">
                          <img
                            src={RedLogo}
                            className="TestLogo"
                            alt="ImageLogo"
                          />
                        </div>
                        <div className="col-md-4">
                          <h6
                            className={
                              stepSeven === false
                                ? "Tit_witTest"
                                : "Tit_wittTest"
                            }
                          >
                            {eventName !== null
                              ? `${eventName.batch_name} - ${eventName.event_name}`
                              : ""}
                          </h6>
                        </div>
                        <div className="col-md-5 eventProdAndName">
                          <div className="col-md-8 eventAndImage">
                            <h2 className="testUsernae tabEvent">
                              <img
                                src={`${ImageUrlPrefix}/${eventName.user_profile_path}`}
                                // src="https://tse4.mm.bing.net/th?id=OIP.WpnGIPj1DKAGo-CP64znTwHaHa&pid=Api&P=0&h=220"
                                className="testProfilepath"
                                alt="ImageLogo"
                              />{" "}
                              {eventName.user_name}
                            </h2>
                            {/* <div
                              className=" tabEvent"
                              style={{ margin: "18px" }}
                            >
                              <span className="testUsernae ">
                                {" "}
                                {eventName.user_name}
                              </span>
                            </div> */}
                          </div>
                          {stepSeven === false && (
                            <div className="col-4 Timing_Sec">
                              <div className="col-md-12 vvv_time">
                                <span className="Time">
                                  {" "}
                                  {String(timeDifference.hours()).padStart(
                                    2,
                                    "0"
                                  )}
                                </span>
                                <span className="time_span "> h</span>
                                <span className="Time ms-1">
                                  {" "}
                                  {String(timeDifference.minutes()).padStart(
                                    2,
                                    "0"
                                  )}{" "}
                                </span>
                                <span className="time_span "> m </span>
                                <span className="Time ms-1">
                                  {" "}
                                  {String(timeDifference.seconds()).padStart(
                                    2,
                                    "0"
                                  )}{" "}
                                  <span className="time_span "> s </span>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* <div className=" col-md-6 eventProdAndName">
                          <div className="eventAndImage">
                            <div className="">
                              <img
                                src={`${ImageUrlPrefix}/${eventName.user_profile_path}`}
                                // src="https://tse4.mm.bing.net/th?id=OIP.WpnGIPj1DKAGo-CP64znTwHaHa&pid=Api&P=0&h=220"
                                className="testProfilepath"
                                alt="ImageLogo"
                              />
                            </div>
                            <div
                              className=" tabEvent"
                              style={{ marginTop: "18px" }}
                            >
                              <span className="testUsernae">
                                {" "}
                                {eventName.user_name}
                              </span>
                            </div>
                          </div>
                          {stepSeven === false && (
                            <div className="Timing_Sec">
                              <div className="row">
                                <div className="col-md-12 vvv_time">
                                  <span className="Time">
                                    {" "}
                                    {String(timeDifference.hours()).padStart(
                                      2,
                                      "0"
                                    )}
                                  </span>
                                  <span className="time_span "> h</span>
                                  <span className="Time ms-1">
                                    {" "}
                                    {String(timeDifference.minutes()).padStart(
                                      2,
                                      "0"
                                    )}{" "}
                                  </span>
                                  <span className="time_span "> m </span>
                                  <span className="Time ms-1">
                                    {" "}
                                    {String(timeDifference.seconds()).padStart(
                                      2,
                                      "0"
                                    )}{" "}
                                    <span className="time_span "> s </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div> */}

                        {/* <div className="eventAndImagesss">
                        <div className="col-md-4 col-3">
                          <img
                            src={`${ImageUrlPrefix}/${eventName.user_profile_path}`}
                            // src="https://tse4.mm.bing.net/th?id=OIP.WpnGIPj1DKAGo-CP64znTwHaHa&pid=Api&P=0&h=220"
                            className="testProfilepath"
                            alt="ImageLogo"
                          />
                        </div>
                        <div
                          className="col-md-8 col-9"
                          style={{ marginTop: "15px" }}
                        >
                          <span className="testUsernae">
                            {" "}
                            {eventName.user_name}
                          </span>
                        </div>
                      </div> */}
                      </div>
                    </section>
                  </div>

                  {/* ANSWER STATUS LIST */}
                  <section className="StepOne">
                    {showAlert && (
                      <WarningPopUp
                        event={eventName}
                        performApiBinding={performApiBinding}
                        onClose={handlePopupClose}
                      />
                    )}
                    <div className="row box_reviews">
                      <div className="col-md-12">
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <div className="forms d-flex flex-row">
                              <div className="questionSet_with">
                                {quesSet.length > 0
                                  ? quesSet.filter((el) => el.is_not_visited)
                                      .length
                                  : 0}
                              </div>
                              <span
                                htmlFor="inputName"
                                className="QuesLabel_test "
                              >
                                Question Not Yet Visited
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="forms d-flex flex-row">
                              <div className="questionSet_with_green">
                                {quesSet.length > 0
                                  ? quesSet.filter((el) => el.is_fill === true)
                                      .length
                                  : 0}
                              </div>
                              <span
                                htmlFor="inputName"
                                className="QuesLabel_test"
                              >
                                Answered Question
                              </span>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="forms d-flex flex-row">
                              <div className="questionSet_with_red">
                                {quesSet.length > 0
                                  ? quesSet.filter((el) => el.is_un_fill).length
                                  : 0}
                              </div>
                              <span
                                htmlFor="inputName"
                                className="QuesLabel_test"
                              >
                                Unanswered Question
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="forms d-flex flex-row">
                              <div className="questionSet_with_blue">
                                {" "}
                                <span>
                                  {quesSet.length > 0
                                    ? quesSet.filter(
                                        (el) =>
                                          el.is_flag === true &&
                                          el.is_fill === true
                                      ).length
                                    : 0}
                                </span>{" "}
                                <img
                                  src={FlagGreen}
                                  className="flaggreen"
                                  alt="flag_green"
                                />{" "}
                              </div>
                              <span
                                htmlFor="inputName"
                                className="QuesLabel_test"
                              >
                                Answered Question Marked for Review
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="forms d-flex flex-row">
                              <div className="questionSet_with_blue">
                                <span>
                                  {quesSet.length > 0
                                    ? quesSet.filter((el) => {
                                        // console.log(el.is_un_fill, "is_un_fill");
                                        return (
                                          el.is_flag === true &&
                                          el.is_un_fill === true
                                        );
                                      }).length
                                    : 0}
                                </span>
                                <img
                                  src={FlagRed}
                                  className="flagred"
                                  alt="flag_green"
                                />
                              </div>
                              <span
                                htmlFor="inputName"
                                className="QuesLabel_test"
                              >
                                Unanswered Question Marked for Review
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}
              {/* {stepSeven === true && (
            <div className="col-md-12 ">
              <span className="Submit_welcome">
                <PaymentSuccess
                  batchID={batch_id}
                  eventID={event_id}
                  userID={userid}
                  startTime={startTime}
                  endTime={endTime}
                  finishTime={moment
                    .utc(time.asMilliseconds())
                    .format("HH:mm:ss")}
                  event={eventName}
                />
              </span>
            </div>
          )} */}
              {/* QUESTIONS */}
              {stepSeven === false && (
                <div className="col-md-9 col-sm-12 col-xs-12">
                  <fieldset>
                    {sectionTab === true && (
                      <section className="StepOne">
                        <div className="row box_reviews">
                          <div className="col-md-12">
                            <p className="StepTitle margin-left_25">Sections</p>
                            <div className="row mb-4 mt-2">
                              <div className="col-md-12 tabSection">
                                <ul
                                  className="nav  nav-fill mb-2 mt-2"
                                  id="listViewTabs_sec"
                                  role="tablist"
                                >
                                  {sectionTabSub.length > 0 ? (
                                    sectionTabSub.map((ed, index) => (
                                      <li
                                        className="nav-item"
                                        role="presentation"
                                        key={index}
                                      >
                                        <a
                                          className={
                                            handleSectionactive(ed)
                                              ? "nav-link active"
                                              : "nav-link"
                                          }
                                          data-mdb-toggle="tab"
                                          href="javascript:void(0)"
                                          role="tab"
                                          aria-controls={`ex2-tabs-${
                                            index + 1
                                          }`}
                                        >
                                          {ed.subject}
                                        </a>
                                      </li>
                                    ))
                                  ) : (
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <a
                                        // className={activeTab === index + 1 ? "nav-link active" : "nav-link"}
                                        className={"nav-link"}
                                        data-mdb-toggle="tab"
                                        href="javascript:void(0)"
                                        role="tab"
                                        aria-controls="ex2-tabs-1"
                                        // aria-selected={activeTab === index + 1 }
                                        // onClick={() => handleTabClick(index + 1, el.batch_id)}
                                      >
                                        Subject
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}

                    <section className="StepOne">
                      {listData.length > 0 &&
                        listData.map((el, index) => {
                          // Calculate the effective step based on the loop count
                          const Thestepper = Math.ceil((index + 1) / 1);
                          if (step_answer === Thestepper) {
                            if (el.display_type === 0) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle margin-left_30  mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>

                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4  usSelectImage d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture_sta"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row QuestionRow">
                                        <QtypeOne
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          Lang={formData.Lang}
                                          list={el}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-4  display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          className="submit_bun width_100"
                                          ref={buttonRefAutomatic}
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center  p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 1) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>
                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture_sta"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row QuestionRow">
                                        <QtypeTwo
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          Lang={formData.Lang}
                                          list={el}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 2) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>

                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture_sta"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row QuestionRow">
                                        <QtypeThree
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          Lang={formData.Lang}
                                          list={el}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 3) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>
                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture_sta"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="row QuestionRow">
                                        <QtypeFour
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          Lang={formData.Lang}
                                          list={el}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 4) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>
                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture_sta"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row QuestionRow">
                                        <QtypeFive
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          Lang={formData.Lang}
                                          list={el}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 5) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>
                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture_sta"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <Qtypesix
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          Lang={formData.Lang}
                                          list={el}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4  display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 6) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>
                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture_sta"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <Qtypeseven
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          Lang={formData.Lang}
                                          list={el}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 7) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>
                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture_sta"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <Qtypeeight
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          list={el}
                                          Lang={formData.Lang}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 8) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>
                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture_sta"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <Qtypenine
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          Lang={formData.Lang}
                                          list={el}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 9) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>
                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <QtypeTen
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          Lang={formData.Lang}
                                          list={el}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            } else if (el.display_type === 10) {
                              return (
                                <>
                                  <div className="row box_review">
                                    <div className="col-md-12">
                                      <p className="StepTitle mb-1">
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                      </p>
                                      <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 d-flex justify-content-end">
                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <img
                                              src={capFlag}
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className={`${
                                                el.is_bookmark === false
                                                  ? "close_capture"
                                                  : "close_capture"
                                              }`}
                                              alt="capture_flag"
                                            />
                                          )}

                                          {userInput.some(
                                            (item) => item.pk === el.pk
                                          ) && (
                                            <span
                                              onClick={() =>
                                                handleUser_unselect(el.pk)
                                              }
                                              className="close_capture_text"
                                            >
                                              Unselect My Answer
                                            </span>
                                          )}
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-sm-4 display_sm_end">
                                          <img
                                            src={capRedFlag}
                                            onClick={() => handleBook(el.pk)}
                                            className={`${
                                              el.is_bookmark === false
                                                ? "flag_capture"
                                                : "flag_capture_sta"
                                            }`}
                                            alt="capture_flag"
                                          />
                                          <span
                                            onClick={() => handleBook(el.pk)}
                                            className="flag_question"
                                          >
                                            {el.is_bookmark === false
                                              ? "Mark Question for Review"
                                              : "UnMark Question for Review"}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="row">
                                        {el.para_text_en &&
                                          (el.lang_type === 1 ||
                                            formData.Lang === 1) &&
                                          (el.lang_type === 2 ||
                                            formData.Lang === 2) && (
                                            <QparaType
                                              classET={
                                                "question_labels_english"
                                              }
                                              index={index}
                                              list={el.para_text_en}
                                            />
                                          )}
                                        {el.para_text_tm &&
                                          (el.lang_type === 0 ||
                                            formData.Lang === 0) &&
                                          (el.lang_type === 2 ||
                                            formData.Lang === 2) && (
                                            <QparaType
                                              classET={"question_labels_tamil"}
                                              index={index}
                                              list={el.para_text_tm}
                                            />
                                          )}
                                      </div>
                                      <div className="row">
                                        <Qquesparatype
                                          userInput={userInput}
                                          handleOptionChange={
                                            handleOptionChange
                                          }
                                          index={index}
                                          list={el}
                                          Lang={formData.Lang}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer <= totalStep_answer - 2 ? (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nNButton width_100"
                                          onClick={() =>
                                            handlePrevious_answer(el, index)
                                          }
                                          disabled={
                                            step_answer === 1 ? true : false
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Previous
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4 display_sm_center p-0">
                                      {step_answer === totalStep_answer && (
                                        <button
                                          type="button"
                                          ref={buttonRefAutomatic}
                                          className="submit_bun width_100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#InstrModel"
                                          onClick={() =>
                                            handleCheckUserInput(el)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Submit Answer
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-4  display_sm_center p-0 d-flex justify-content-end">
                                      {step_answer === totalStep_answer ? (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                          disabled
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="nPButton width_100"
                                          onClick={() =>
                                            handleNext_answer(el, index)
                                          }
                                        >
                                          <a href="javascript:void(0)">
                                            Save and Next
                                          </a>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          }
                          return null;
                        })}
                    </section>
                  </fieldset>
                </div>
              )}
              {/* SIDE COMPONENT */}
              {stepSeven === false && (
                <div className="col-md-3 col-sm-12 col-xs-12 viewQuestionList">
                  {sectionTabLng === true && (
                    <section className="StepOne">
                      <div className="row box_reviews">
                        <div className="col-md-12">
                          <p className="StepTitle mb-1">View In</p>
                          <div className="forms  ">
                            <Select
                              id="Lang"
                              name="Lang"
                              value={
                                formData.Lang !== null && formData.Lang !== ""
                                  ? {
                                      value: formData.Lang,
                                      label: formData.LangText,
                                    }
                                  : {
                                      value: 2,
                                      label: "All",
                                    }
                              }
                              onChange={(option) =>
                                handleSelectCH(option, "Lang")
                              }
                              options={
                                langSet
                                  .filter((item) => item && item.label) // Filter out null and undefined elements
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  ) // Sort options alphabetically by label
                              }
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              styles={customStyles} // Apply custom styles her
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  <section className="StepOne">
                    <div className=" row box">
                      <div className="col-md-12">
                        <p className="StepTitle mb-1">
                          Question Set -{" "}
                          {`${(step - 1) * pageSize + 1} to ${Math.min(
                            step * pageSize,
                            quesSet.length
                          )} of ${quesSet.length > 0 ? quesSet.length : "0"}`}
                        </p>
                        <div className="row mt-2">
                          {quesSet.length > 0 &&
                            quesSet
                              .slice((step - 1) * step_size, step * step_size)
                              .map((el, index) => {
                                const questionNumber =
                                  index + 1 + (step - 1) * step_size;

                                if (effStep === step) {
                                  return (
                                    <div
                                      onClick={() =>
                                        handleStepClick(questionNumber, el)
                                      }
                                      className={`col-md-2 d-flex flex-row ${
                                        el.is_fill === true
                                          ? el.is_flag === true
                                            ? "questionSet_with_blue_right"
                                            : "questionSet_with_green_set"
                                          : el.is_flag === true
                                          ? "questionSet_with_blue_right"
                                          : el.is_not_visited === false
                                          ? "questionSet_with_red_sets"
                                          : "questionSet_with_right"
                                      }`}
                                      title={el.pk}
                                      key={index}
                                    >
                                      {" "}
                                      <span
                                        className={`${
                                          el.is_fill === true
                                            ? el.is_flag === true
                                              ? "mark_number"
                                              : "mark_number"
                                            : el.is_flag === true
                                            ? "mark_number"
                                            : el.is_fill === true
                                            ? "mark_number"
                                            : el.is_not_visited === false
                                            ? "mark_number"
                                            : "mark_numbers"
                                        }`}
                                      >
                                        {questionNumber}
                                      </span>
                                      {el.is_flag === true && (
                                        <img
                                          src={
                                            el.is_flag === true &&
                                            el.is_fill === true
                                              ? FlagGreen
                                              : el.is_flag === true
                                              ? FlagRed
                                              : FlagWhite
                                          }
                                          className="flgQuestionSetImg"
                                          alt="flag_green"
                                        />
                                      )}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      onClick={() =>
                                        handleStepClick(questionNumber, el)
                                      }
                                      className={`col-md-2 d-flex flex-row ${
                                        el.is_fill === true
                                          ? el.is_flag === true
                                            ? "questionSet_with_blue_right"
                                            : "questionSet_with_green_set"
                                          : el.is_flag === true
                                          ? "questionSet_with_blue_right"
                                          : el.is_not_visited === false
                                          ? "questionSet_with_red_sets"
                                          : "questionSet_with_right"
                                      }`}
                                      title={el.pk}
                                      key={index}
                                    >
                                      <span
                                        className={`${
                                          el.is_fill === true
                                            ? el.is_flag === true
                                              ? "mark_number"
                                              : "mark_number"
                                            : el.is_flag === true
                                            ? "mark_number"
                                            : el.is_fill === true
                                            ? "mark_number"
                                            : el.is_not_visited === false
                                            ? "mark_number"
                                            : "mark_numbers"
                                        }`}
                                      >
                                        {questionNumber}
                                      </span>
                                      {el.is_flag === true && (
                                        <img
                                          src={
                                            el.is_flag === true &&
                                            el.is_fill === true
                                              ? FlagGreen
                                              : el.is_flag === true
                                              ? FlagRed
                                              : FlagWhite
                                          }
                                          className="flgQuestionSetImg"
                                          alt="flag_green"
                                        />
                                      )}
                                    </div>
                                  );
                                }
                              })}
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 col-lg-12 mb-3 mt-4 p-0 backAndNext">
                        <button
                          type="button"
                          className="PrevIcons_ques"
                          onClick={handlePrevious}
                          disabled={step === 1 ? true : false}
                        >
                          <span className="material-icons">
                            keyboard_backspace
                          </span>
                        </button>

                        <button
                          type="button"
                          className="PrevIcons_ques"
                          onClick={handleNext}
                          disabled={step >= totalStep ? true : false}
                        >
                          <span className="material-icons">east</span>
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              )}
            </div>

            <div
              class="modal"
              id="InstrModel"
              tabindex="-1"
              aria-labelledby="InstrModel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg ">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="InstrModelLabel">
                      Submit Your Answers
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      ref={modalCloseRefTest}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="model_inst_in">
                          Are you sure you want to submit your answers?
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 p-0">
                        <TestDetailsBox_submit
                          style={{
                            background:
                              "transparent linear-gradient(180deg, #FF6384 0%, #803242 100%) 0% 0% no-repeat padding-box",
                            boxShadow:
                              " inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                          }}
                        >
                          <div>
                            <TestDetailsLabel_submit>
                              {"Total Number of Questions"}
                            </TestDetailsLabel_submit>
                            <TestDeatilsScore_submit>
                              {quesSet.length > 0 ? quesSet.length : 0}
                            </TestDeatilsScore_submit>
                          </div>
                        </TestDetailsBox_submit>
                      </div>
                      <div className="col-md-4 p-0">
                        <TestDetailsBox_submit
                          style={{
                            background:
                              "transparent linear-gradient(180deg, #4BC0C0 0%, #266060 100%) 0% 0% no-repeat padding-box",
                            boxShadow:
                              "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                          }}
                        >
                          <div>
                            <TestDetailsLabel_submit>
                              {"Answered Questions"}
                            </TestDetailsLabel_submit>
                            <TestDeatilsScore_submit>
                              {" "}
                              {quesSet.length > 0
                                ? quesSet.filter((el) => el.is_fill === true)
                                    .length
                                : 0}
                            </TestDeatilsScore_submit>
                          </div>
                        </TestDetailsBox_submit>
                      </div>
                      <div className="col-md-4 p-0">
                        <TestDetailsBox_submit
                          style={{
                            background:
                              "transparent linear-gradient(180deg, #FF9F40  0%, #805020  100%) 0% 0% no-repeat padding-box",
                            boxShadow:
                              "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                          }}
                        >
                          <div>
                            <TestDetailsLabel_submit>
                              {"Answered Questions Marked for Review"}
                            </TestDetailsLabel_submit>
                            <TestDeatilsScore_submit>
                              {quesSet.length > 0
                                ? quesSet.filter(
                                    (el) =>
                                      el.is_flag === true && el.is_fill === true
                                  ).length
                                : 0}
                            </TestDeatilsScore_submit>
                          </div>
                        </TestDetailsBox_submit>
                      </div>
                      <div className="col-md-4 p-0">
                        <TestDetailsBox_submit
                          style={{
                            background:
                              "transparent linear-gradient(180deg, #FFCD56   0%, #80672B   100%) 0% 0% no-repeat padding-box",
                            boxShadow:
                              "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                          }}
                        >
                          <div>
                            <TestDetailsLabel_submit>
                              {"Questions Not Yet Visited"}
                            </TestDetailsLabel_submit>
                            <TestDeatilsScore_submit>
                              {" "}
                              {quesSet.length > 0
                                ? quesSet.filter((el) => el.is_not_visited)
                                    .length
                                : 0}
                            </TestDeatilsScore_submit>
                          </div>
                        </TestDetailsBox_submit>
                      </div>

                      <div className="col-md-4 p-0">
                        <TestDetailsBox_submit
                          style={{
                            background:
                              "transparent linear-gradient(180deg, #36A2EB  0%, #1B5176    100%) 0% 0% no-repeat padding-box",
                            boxShadow:
                              "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                          }}
                        >
                          <div>
                            <TestDetailsLabel_submit>
                              {"Unanswered Questions"}
                            </TestDetailsLabel_submit>
                            <TestDeatilsScore_submit>
                              {" "}
                              {quesSet.length > 0
                                ? quesSet.filter((el) => el.is_un_fill).length
                                : 0}
                            </TestDeatilsScore_submit>
                          </div>
                        </TestDetailsBox_submit>
                      </div>

                      <div className="col-md-4 p-0">
                        <TestDetailsBox_submit
                          style={{
                            background:
                              "transparent linear-gradient(180deg, #9966FF   0%, #4D3380     100%) 0% 0% no-repeat padding-box",
                            boxShadow:
                              "inset -6px -6px 6px #00000029, 3px 3px 6px #00000029",
                          }}
                        >
                          <div>
                            <TestDetailsLabel_submit>
                              {"Unanswered Questions Marked for Review"}
                            </TestDetailsLabel_submit>
                            <TestDeatilsScore_submit>
                              {quesSet.length > 0
                                ? quesSet.filter(
                                    (el) =>
                                      el.is_flag === true &&
                                      el.is_un_fill === true
                                  ).length
                                : 0}
                            </TestDeatilsScore_submit>
                          </div>
                        </TestDetailsBox_submit>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <Link data-bs-dismiss="modal" className="CancelButton mt-1">
                      Don't Submit
                    </Link>
                    <button
                      type="button"
                      ref={buttonRef}
                      className="SaveButton"
                      onClick={() =>
                        handleSubmit(
                          moment.utc(time.asMilliseconds()).format("HH:mm:ss"),
                          quesSet.length > 0 ? quesSet.length : 0,
                          quesSet.length > 0
                            ? quesSet.filter((el) => el.is_fill === true).length
                            : 0,
                          quesSet.length > 0
                            ? quesSet.filter(
                                (el) =>
                                  el.is_flag === true && el.is_fill === true
                              ).length
                            : 0,
                          quesSet.length > 0
                            ? quesSet.filter((el) => el.is_not_visited).length
                            : 0,
                          quesSet.length > 0
                            ? quesSet.filter((el) => el.is_un_fill).length
                            : 0,
                          quesSet.length > 0
                            ? quesSet.filter(
                                (el) =>
                                  el.is_flag === true && el.is_un_fill === true
                              ).length
                            : 0
                        )
                      }
                    >
                      <Link to="javascript:void(0)">Yes, Submit Now</Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* loader */}
      {isPaymentLoading && (
        <Dialog open={isPaymentLoading}>
          <DialogContent>
            <div style={{ margin: "15px" }}>
              <p>Please wait...</p>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
