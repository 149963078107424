  
  export const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#2378E9' : provided.borderColor,
      color: state.isFocused || state.isHovered ? 'white' : provided.color, // Set text color to white when focused
      '& svg': {
        fill: '#2378E9', // Change the color to your desired color
        // width: '12px', // Set the width to reduce the size
        // height: '12px', // Set the height to reduce the size
      },
      // Add additional styles for the active state here if needed
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#2378E9' : provided.backgroundColor,
      color: state.isFocused || state.isHovered ? 'white' : provided.color, // Set text color to white when focused
    
      // Add additional styles for the active state here if needed
    }),
  
    singleValue: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#2378E9' : provided.backgroundColor, // Background color for the selected value
        color: state.isFocused || state.isHovered ? 'white' : provided.color, // Text color for the selected value
        // Add additional styles for the selected state here if needed
      }),
      checkboxChecked: {
        // Define styles for the custom checkbox when it's checked
        backgroundColor: '#ffff', // Background color when checked
        borderColor: '#ffff', // Border color when checked
      },
    menu: (provided, state) => ({
      ...provided,
      // Add additional styles for the menu here if needed
    }),
  };


