// ModalComponent.js
import React from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CustomCOptionModelExam } from "../../../../labels/Lableoption";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import Select, { components, useStateManager } from "react-select";
import DatePickerSample from "../../DatePicker";
import { customStyles } from "../../../../pages/customStyles";
import moment from "moment";
import CustomDatePicker from "../../CustomDatePicker";
function ExamAdvancedFilterAdmin({
  show,
  modelExamOption,
  handleFilterClose,
  handleExamChange,
  ModelExamValue,
  ClearAllFilterBut,
  clearFilterDisabled,
  centerDistrict,
  handleExamRecordsFilter,
  district,
  district_alll_data,
  genderOption,
  optionNEEnrolled,
  handleExamDistrict,
  selectedDistrict,
  examCenterOption,
  selectedExamCenter,
  handleClearSection,
  handleApplyFilterAll,
  handleChangeDateFilter,
  handleAllClearFilter,
  error,
  txtFilterAPayments,
  txtFilterSDAPayments,
}) {
  const today = moment().format("YYYY-MM-DD");
  const idsToFilter = new Set(ModelExamValue.map((value) => value.id));
  return (
    <Modal show={show} onHide={handleFilterClose} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 class="modal-title modelHallTicketTitle" id="exampleModalLabel">
            Advanced Filters
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className="StepOne_modelFilter">
          <div className="row box">
            <div className="col-md-12">
              <p className="StepTitle">Filter by Exam</p>
              <div className="col-md-12">
                <div className="forms ">
                  <label htmlFor="model_exam_name">Exam</label>
                  <div className="d-flex  col-md-12" id="radiobuttons">
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="radio"
                        name="modelExamNo"
                        onChange={(e) =>
                          handleExamRecordsFilter(e, "modelExamNo")
                        }
                        checked={
                          centerDistrict.modelExamNo === "0" ? true : false
                        }
                        id="by_model_exam_name"
                        value="0"
                      />
                      <label
                        className="checkbox-label mt-1"
                        htmlFor="by_model_exam_name"
                      >
                        By Model Exam Name
                      </label>
                    </div>
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="radio"
                        name="modelExamNo"
                        id="by_number_of_exams_enrolled"
                        onChange={(e) =>
                          handleExamRecordsFilter(e, "modelExamNo")
                        }
                        checked={
                          centerDistrict.modelExamNo === "1" ? true : false
                        }
                        value="1"
                      />
                      <label
                        className="checkbox-label mt-1"
                        htmlFor="by_number_of_exams_enrolled"
                      >
                        By Number of Exams Enrolled
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {centerDistrict.modelExamNo !== null &&
                  centerDistrict.modelExamNo === "0" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label className="mt-1" htmlFor="model_exam_name">
                          Model Exam Name
                        </label>
                        <Select
                          id="model_exam_name"
                          isMulti
                          name="model_exam_name"
                          options={modelExamOption}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          isClearable={true}
                          onChange={(e) =>
                            handleExamChange(e, "model_exam_name")
                          }
                          value={ModelExamValue.map((value) => ({
                            value: value.value,
                            label: value.label,
                            id: value.id,
                            name: value.name,
                          }))}
                          components={{
                            Option: CustomCOptionModelExam, // Correct the syntax here
                          }}
                          styles={multiCusutomStyle} // Apply custom styles her
                        />
                      </div>
                    </div>
                  )}
                {centerDistrict.modelExamNo !== null &&
                  centerDistrict.modelExamNo === "1" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label className="mt-1" htmlFor="no_exam_enrolled">
                          Number of Exams Enrolled
                        </label>
                        <Select
                          id="no_exam_enrolled"
                          name="no_exam_enrolled"
                          options={optionNEEnrolled}
                          isClearable={true}
                          closeMenuOnSelect={true}
                          placeholder="Please choose exams enrolled"
                          hideSelectedOptions={false}
                          onChange={(e) =>
                            handleExamChange(e, "no_exam_enrolled")
                          }
                          value={
                            centerDistrict.no_exam_enrolled !== null &&
                            centerDistrict.no_exam_enrolled !== "" &&
                            centerDistrict.no_exam_enrolled !== undefined
                              ? {
                                  value: centerDistrict.no_exam_enrolled,
                                  label: centerDistrict.no_exam_enrolled,
                                }
                              : null
                          }
                          styles={customStyles} // Apply custom styles her
                        />
                      </div>
                    </div>
                  )}
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <span
                  disabled={
                    clearFilterDisabled.Modelexamsec === false ? true : false
                  }
                  onClick={
                    clearFilterDisabled.Modelexamsec === true
                      ? () => handleClearSection("model_exam")
                      : () => {}
                  }
                  className={`ClearFilterModel ${
                    clearFilterDisabled.Modelexamsec === false &&
                    "DisabledFilter"
                  }`}
                >
                  Clear Filter
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="StepOne_modelFilter">
          <div className="row box">
            <div className="col-md-12">
              <p className="StepTitle">Filter by Location</p>
              <div className="col-md-12">
                <div className="forms ">
                  <label htmlFor="model_exam_name">Location</label>
                  <div className="d-flex  col-md-12" id="radiobuttons">
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="radio"
                        name="centerD"
                        onChange={(e) => handleExamRecordsFilter(e, "centerD")}
                        checked={centerDistrict.centerD === "0" ? true : false}
                        id="district_name"
                        value="0"
                      />
                      <label
                        className="checkbox-label mt-1"
                        htmlFor="district_name"
                      >
                        District
                      </label>
                    </div>
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className={`checkbox_radio ${
                          (ModelExamValue.length > 1 ||
                            ModelExamValue.length === 0) &&
                          "DisabledFilter"
                        }`}
                        type="radio"
                        name="centerD"
                        disabled={
                          ModelExamValue.length > 1 ||
                          ModelExamValue.length === 0
                            ? true
                            : false
                        }
                        id="exam_centers"
                        onChange={(e) => handleExamRecordsFilter(e, "centerD")}
                        checked={centerDistrict.centerD === "1" ? true : false}
                        value="1"
                      />
                      <label
                        className={`checkbox-label mt-1 ${
                          (ModelExamValue.length > 1 ||
                            ModelExamValue.length === 0) &&
                          "DisabledFilter"
                        }`}
                        htmlFor="exam_centers"
                      >
                        Exam Centre (This filter will be available only for a
                        single model exam)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {centerDistrict.centerD !== "" &&
                  centerDistrict.centerD !== null &&
                  centerDistrict.centerD === "0" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label className="mt-1" htmlFor="district_name">
                          District Name
                        </label>
                        <Select
                          id="district_name"
                          name="district_name"
                          options={district}
                          // options={
                          //   ModelExamValue.length > 0
                          //     ? district
                          //     : district_alll_data
                          // }
                          isSearchable={true} // Enables search functionality
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          isClearable={true}
                          placeholder="Please select district "
                          onChange={(option) =>
                            handleExamDistrict(option, "district_name")
                          }
                          value={
                            Object.keys(selectedDistrict).length === 0
                              ? null
                              : selectedDistrict
                          }
                          styles={customStyles} // Apply custom styles her
                        />
                      </div>
                    </div>
                  )}
                {/* {console.log(ModelExamValue, "ModelExamValue")} */}
                {centerDistrict.centerD !== "" &&
                  centerDistrict.centerD !== null &&
                  centerDistrict.centerD === "1" && (
                    <>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label className="mt-1" htmlFor="district_names">
                            District Name
                          </label>
                          <Select
                            id="district_names"
                            name="district_name"
                            // options={
                            //   ModelExamValue.length > 0
                            //     ? district
                            //     : district_alll_data
                            // }
                            options={district}
                            isSearchable={true} // Enables search functionality
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            isClearable={true}
                            placeholder="Please select district "
                            onChange={(option) =>
                              handleExamDistrict(option, "district_name")
                            }
                            value={
                              Object.keys(selectedDistrict).length === 0
                                ? null
                                : selectedDistrict
                            }
                            styles={customStyles} // Apply custom styles her
                          />
                          {error.district_name && (
                            <small className="error_filter">
                              {error.district_name}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="forms ">
                          <label className="mt-1" htmlFor="exam_center_name">
                            Exam Centre Name
                          </label>
                          <Select
                            id="exam_center_name"
                            name="exam_center_name"
                            options={
                              Array.isArray(examCenterOption)
                                ? examCenterOption.filter((option) =>
                                    idsToFilter.has(option.exam_detail_id)
                                  )
                                : []
                            }
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            placeholder="Please select exam center"
                            onChange={(e) =>
                              handleExamDistrict(e, "exam_center_name")
                            }
                            value={
                              Object.keys(selectedExamCenter).length === 0
                                ? null
                                : selectedExamCenter
                            }
                            styles={customStyles} // Apply custom styles her
                          />
                          {error.exam_center_name && (
                            <small className="error_filter">
                              {error.exam_center_name}
                            </small>
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <span
                  onClick={
                    clearFilterDisabled.districtsec === true
                      ? () => handleClearSection("centerD")
                      : () => {}
                  }
                  // onClick={() => handleClearSection("centerD")}
                  disabled={
                    clearFilterDisabled.districtsec === false ? true : false
                  }
                  className={`ClearFilterModel ${
                    clearFilterDisabled.districtsec === false &&
                    "DisabledFilter"
                  }`}
                >
                  Clear Filter
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="StepOne_modelFilter">
          <div className="row box">
            <div className="col-md-12">
              <p className="StepTitle">Filter by Gender</p>
              <div className="col-md-12">
                <div className="forms ">
                  <label htmlFor="model_exam_name">Gender</label>
                  <div className="d-flex  col-md-12" id="radiobuttons">
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="checkbox"
                        name="gender"
                        onChange={(e) => handleExamRecordsFilter(e, "gender")}
                        checked={genderOption.includes("Male") ? true : false}
                        id="Male"
                        value="Male"
                      />
                      <label className="checkbox-label mt-1" htmlFor="Male">
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="checkbox"
                        name="gender"
                        id="Female"
                        onChange={(e) => handleExamRecordsFilter(e, "gender")}
                        checked={genderOption.includes("Female") ? true : false}
                        value="Female"
                      />
                      <label className="checkbox-label mt-1" htmlFor="Female">
                        Female
                      </label>
                    </div>
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="checkbox"
                        name="gender"
                        id="Transgender"
                        onChange={(e) => handleExamRecordsFilter(e, "gender")}
                        checked={
                          genderOption.includes("Transgender") ? true : false
                        }
                        value="Transgender"
                      />
                      <label
                        className="checkbox-label mt-1"
                        htmlFor="Transgender"
                      >
                        Transgender
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <span
                  onClick={() => handleClearSection("gender")}
                  disabled={
                    clearFilterDisabled.Modelexamsec === true ? true : false
                  }
                  className={`ClearFilterModel ${
                    clearFilterDisabled.gendersec === false && "DisabledFilter"
                  }`}
                >
                  Clear Filter
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="StepOne_modelAdmin StepOne_modelFilter">
          <div className="row box">
            <div className="col-md-12">
              <p className="StepTitle">Filter by Enrollment Date</p>

              <div className="row ">
                <div className="col-md-6">
                  <div className="forms ">
                    <label htmlFor="startDate">Start Date</label>

                    <CustomDatePicker
                      txtAdMDob={txtFilterSDAPayments}
                      maxDate={moment().format("YYYY-MM-DD")}
                      name={"startDateFilter"}
                      handleChange={handleChangeDateFilter}
                      fieldInput={
                        centerDistrict.startDateFilter
                          ? moment(centerDistrict.startDateFilter).format(
                              "DD/MM/YYYY"
                            )
                          : ""
                      }
                    />
                    {error.startDateFilter && (
                      <small className="error">{error.startDateFilter}</small>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="forms">
                    <label
                      for="endDateFilter"
                      className={`${
                        centerDistrict.startDateFilter !== null &&
                        centerDistrict.startDateFilter === today
                          ? "DisabledFilter"
                          : ""
                      } `}
                    >
                      End Date
                    </label>

                    <CustomDatePicker
                      maxDate={moment().format("YYYY-MM-DD")}
                      classDisabled={
                        centerDistrict.startDateFilter !== null &&
                        centerDistrict.startDateFilter === today
                          ? "DisabledFilter"
                          : ""
                      }
                      IconInput={
                        centerDistrict.startDateFilter !== null &&
                        centerDistrict.startDateFilter === today
                          ? "DisabledFilter"
                          : ""
                      }
                      minDate={
                        centerDistrict.startDateFilter !== null
                          ? moment(centerDistrict.startDateFilter)
                              .add(1, "days")
                              .format("YYYY-MM-DD")
                          : ""
                      }
                      disabledProperty={
                        centerDistrict.startDateFilter !== null &&
                        centerDistrict.startDateFilter === today
                          ? true
                          : false
                      }
                      txtAdMDob={txtFilterAPayments}
                      name={"endDateFilter"}
                      handleChange={handleChangeDateFilter}
                      fieldInput={
                        centerDistrict.endDateFilter
                          ? moment(centerDistrict.endDateFilter).format(
                              "DD/MM/YYYY"
                            )
                          : ""
                      }
                    />
                    {error.endDateFilter && (
                      <small className="error_filter">
                        {error.endDateFilter}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <span
                  onClick={() => handleClearSection("dateFilter")}
                  disabled={
                    clearFilterDisabled.Modelexamsec === true ? true : false
                  }
                  className={`ClearFilterModel ${
                    clearFilterDisabled.datefiltersec === false &&
                    "DisabledFilter"
                  }`}
                >
                  Clear Filter
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="StepOne_modelFilter">
          <div className="row box">
            <div className="col-md-12">
              <p className="StepTitle">Filter by Payment Mode</p>
              <div className="col-md-12">
                <div className="forms ">
                  <label htmlFor="payment_mode">Payment Mode</label>
                  <div className="d-flex  col-md-12" id="radiobuttons">
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="radio"
                        name="payment_mode"
                        onChange={(e) =>
                          handleExamRecordsFilter(e, "payment_mode")
                        }
                        checked={
                          centerDistrict.payment_mode === "online"
                            ? true
                            : false
                        }
                        id="online"
                        value="online"
                      />
                      <label className="checkbox-label mt-1" htmlFor="online">
                        Online
                      </label>
                    </div>
                    <div className="form-check form-check-inline Mobileform-check-inline p-0 ">
                      <input
                        className="checkbox_radio"
                        type="radio"
                        name="payment_mode"
                        id="cash"
                        onChange={(e) =>
                          handleExamRecordsFilter(e, "payment_mode")
                        }
                        checked={
                          centerDistrict.payment_mode === "cash" ? true : false
                        }
                        value="cash"
                      />
                      <label className="checkbox-label mt-1" htmlFor="cash">
                        Cash
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <span
                  onClick={() => handleClearSection("payment_mode")}
                  disabled={
                    clearFilterDisabled.Modelexamsec === true ? true : false
                  }
                  className={`ClearFilterModel ${
                    clearFilterDisabled.paymentsec === false && "DisabledFilter"
                  }`}
                >
                  Clear Filter
                </span>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Link
          data-bs-dismiss="modal"
          onClick={handleAllClearFilter}
          disabled={
            Object.values(clearFilterDisabled).some((value) => value === true)
              ? false
              : true
          }
          className={`DashboardCancelButton ${
            Object.values(clearFilterDisabled).some((value) => value === true)
              ? ""
              : "DisabledFilter"
          }`}
        >
          Clear All Filters
        </Link>
        <button
          type="button"
          onClick={handleApplyFilterAll}
          disabled={
            Object.values(clearFilterDisabled).some(
              (value) => value === true
            ) || ClearAllFilterBut === true
              ? false
              : true
          }
          className={`DashboardButton ${
            Object.values(clearFilterDisabled).some(
              (value) => value === true
            ) || ClearAllFilterBut === true
              ? ""
              : "DisabledFilter"
          }`}
        >
          Apply Filters
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExamAdvancedFilterAdmin;
