import { Home } from '@mui/icons-material'
import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../../features/Core/Footer/Footer'
import HeaderHome from '../../features/Core/Header/HomeHeader'


 function Dashboard() {

  // dashboard for layout 

  return (
    <div className='container-fluid p-0'>
      <HeaderHome/>
      <Outlet/>
      <Footer/>
    </div>
  )
}

export default Dashboard