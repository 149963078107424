export const validateTeachingProgress = (data) => {

    const errors = {};
// console.log(data);
    if (!data.topic_covered ) {
      errors.topic_covered = "Please enter Topic covered.";
    }
     if (!data.topics_pending) {
      errors.topics_pending = "Please enter Topic Pending.";
    }
     if (!data.percentage_completion ) {
      errors.percentage_completion = "Please enter Percentage.";
    }
     if (!data.comments) {
      errors.comments = "Please enter Comments";
    }
    
    return errors;

}

export const validateMainHall = (data) => {

  const errors = {};
// console.log(data);
  if (data.examNumber === "")  {
    errors.examNumber = "Please enter Register number.";
  }
   if (!data.hallTicket) {
    errors.hallTicket = "Please upload hallTicket.";
  }

  return errors;

}
export const validateUploadMark = (data) => {

  const errors = {};
console.log(data);
 
   if (!data.marksheet) {
    errors.marksheet = "Please upload marksheet.";
  }

  return errors;

}
export const validateComment = (data) => {
    const errors = {};
    if (!data.comment ) {
      errors.comment = "Please enter Comment.";
    }
    return errors;
}