import { FormValidationFocus } from "./FormvalidFocus";

export const FormValidation = (data,focus_var,specialPre, setSpecialPre) => {
  const errors = {};
  if (!data.FirstName) {
    errors.FirstName = "Please enter your first name.";
  }  if (!data.LastName) {
    errors.LastName = "Please enter your LastName.";
  }  if (!data.Fathername) {
    errors.Fathername = "Please enter your Fathername.";
  }  if (!data.MotherName) {
    errors.MotherName = "Please enter your MotherName.";
  }  if (!data.DOB) {
    errors.DOB = "Please enter your DOB.";
  }  if (!data.age) {
    errors.age = "Please enter your age.";
  }  if (isNaN(data.age)) {
    errors.age = "Please enter a valid age ";
  }  if (!data.gender) {
    errors.gender = "Please choose gender.";
  }  if (!data.MaritalStatus) {
    errors.MaritalStatus = "Please choose Marital Status.";
  }  if (!data.Profilephoto) {
    errors.Profilephoto = "Please upload Profilephoto.";
  }
  //   if (data.Profilephoto.size > 2 * 1024 * 1024) {
  //   errors.Profilephoto = "File size must be less than 2MB.";
  // }
    if (!data.aadharNo) {
    errors.aadharNo = "Please enter Aadhar No.";
  }
  //     if (!/^[2-9]\d{3}\s\d{4}\s\d{4}$/.test(data.aadharNo)) {
  //     errors.aadharNo = "Please enter a valid Aadhar No.";
  //   }
   if (!data.aadharCardphoto) {
    errors.aadharCardphoto = "Please choose AadharCardphoto.";
  }
  //   if (data.aadharCardphoto.size > 2 * 1024 * 1024) {
  //   errors.aadharCardphoto = "File size must be less than 2MB.";
  // }
    if (!data.Cast) {
    errors.Cast = "Please choose category.";
  }
  //   if (!data.CastFile || data.CastFile.length === 0) {
  //   errors.CastFile = "Please choose CastFile.";
  // }
  if (!data.SPCcategory) {
    errors.SPCcategory = "Please choose at least one category.";
  }
  if (specialPre.length > 0 ) {
    const updateSpc = specialPre.map((OB) => {
      let updatedOB = { ...OB };
  
      if (OB.name === "") {
        updatedOB.error_name = "Name is required";
      } else {
        updatedOB.error_name = "";
      }
      return updatedOB;
    });
  
    setSpecialPre(updateSpc);
  }
  //   if (data.CastFile.size > 2 * 1024 * 1024) {
  //   errors.CastFile = "File size must be less than 2MB.";
  // } 
   if (!data.Mobile) {
    errors.Mobile = "Please enter Mobile number.";
  }  if (data.Mobile && data.Mobile.length < 10) {
    errors.Mobile = "Please enter 10 digit Mobile number.";
  }  if (!data.Mobile  &&  !/^\d+$/.test(data.Mobile)) {
    errors.Mobile = "Please enter a valid Mobile number.";
  }  if (!data.WhatsAppNumber && data.ContactNumber === false) {
    errors.WhatsAppNumber = "Please enter WhatsApp Number.";
  }   if (data.WhatsAppNumber && data.WhatsAppNumber.length < 10 && data.ContactNumber === false) {
    errors.WhatsAppNumber = "Please enter 10 digit WhatsApp Number.";
  }  if (!/^\d+$/.test(data.WhatsAppNumber) && data.ContactNumber === false) {
    errors.WhatsAppNumber = "Please enter a valid WhatsApp number.";
  }   if (!data.ParentMobileNo) {
    errors.ParentMobileNo = "Please enter Parent Mobile number.";
  }  if (data.ParentMobileNo && data.ParentMobileNo.length < 10) {
    errors.ParentMobileNo = "Please enter 10 digit Parent MobileNo.";
  }  if (!data.ParentMobileNo  &&  !/^\d+$/.test(data.ParentMobileNo)) {
    errors.ParentMobileNo = "Please enter a valid Mobile number.";
  }  if (!data.emailaddress) {
    errors.emailaddress = "Please enter email address.";
  }  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailaddress)) {
    errors.emailaddress = "Please enter a valid email address.";
  }
   if (!data.BuildingNumberPresent) {
        errors.BuildingNumberPresent = "Please enter Building Number .";
      }  if (!data.StreetNamePresent) {
        errors.StreetNamePresent = "Please enter Street Name.";
      }
      if (!data.TalukPresent) {
        errors.TalukPresent = "Please  enter Taluk.";
      } 
      if (!data.PostPresent) {
        errors.PostPresent = "Please  enter Post.";
      } 
        if (!data.AreaPresent) {
        errors.AreaPresent = "Please  enter Area.";
      }
       if (!data.LandmarkPresent) {
        errors.LandmarkPresent = "Please  enter Landmark.";
      } 
       if (!data.CityPresent) {
        errors.CityPresent = "Please  enter City.";
      }
       if (!data.districtPresent) {
        errors.districtPresent = "Please choose district";
      }   if (!data.statePresent) {
        errors.statePresent = "Please  enter state.";
      }  if (!data.PincodePresent) {
        errors.PincodePresent = "Please  enter Pincode.";
      }
       if (!/^[1-9][0-9]{5}$/.test(data.PincodePresent)) {
        errors.PincodePresent = "Please enter a valid Pincode.";
      }
       if (!data.BuildingNumberPermanent  && data.SamePresentAddress === false ) {
        errors.BuildingNumberPermanent = "Please enter Building Number .";
      }  if (!data.StreetNamePermanent  && data.SamePresentAddress === false ) {
        errors.StreetNamePermanent = "Please enter Street Name.";
      } 
       if (!data.AreaPermanent  && data.SamePresentAddress === false ) {
        errors.AreaPermanent = "Please  enter Area.";
      }
  if (
    !data.TalukPermanent &&
    data.SamePresentAddress === false) {

    errors.TalukPermanent = "Please  enter Taluk.";

  }
  if (!data.PostPermanent && data.SamePresentAddress === false) {
    errors.PostPermanent = "Please  enter Post.";
  }
       if ( !data.LandmarkPermanent  && data.SamePresentAddress === false) {
        errors.LandmarkPermanent = "Please  enter Landmark.";
      } 
       if (!data.CityPermanent  && data.SamePresentAddress === false ) {
        errors.CityPermanent = "Please  enter City.";
      } 
       if ( !data.districtPermanent  && data.SamePresentAddress === false) {
        errors.districtPermanent = "Please  choose  district.";
      } 
       if (!data.statePermanent  && data.SamePresentAddress === false ) {
        errors.statePermanent = "Please  enter state.";
      }  if (!data.PincodePermanent  && data.SamePresentAddress === false ) {
        errors.PincodePermanent = "Please  enter Pincode.";
      }
        if (!/^[1-9][0-9]{5}$/.test(data.PincodePermanent) && data.SamePresentAddress === false ) {
        errors.PincodePermanent = "Please enter a valid Pincode.";
      }
      FormValidationFocus(data,focus_var);
  return errors;
};
