import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  perfomanceAnalysis: {
    studentScore: [],
    topperScore: [],
    perfomanceLabels: [],
  },
  overallAnalysis: {
    studentScore: [],
  },
  proficiencySubjectAnalysis: {
    studentScore: [],
  },
  timeTakenAnalysis: {
    timeTakenArray: [],
  },
  queDifficultyAnalysis: {
    overall: {
      totalCount: [],
      studentCount: [],
    },
    eventDetails: {
      totalCount: [],
      studentCount: [],
    },
  },
};

const ChartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    // perfomanceAnalysis
    createPerfomanceStudentScore(state, action) {
      state.perfomanceAnalysis.studentScore = action.payload;
    },
    createPerfomanceTopperScore(state, action) {
      state.perfomanceAnalysis.topperScore = action.payload;
    },
    createPerfomanceLabels(state, action) {
      state.perfomanceAnalysis.perfomanceLabels = action.payload;
    },
    // overall analysis
    createOverallAnalysisScore(state, action) {
      state.overallAnalysis.studentScore = action.payload;
    },
    // prficiency analysis
    createProficiencySubjectAnalysisScore(state, action) {
      state.proficiencySubjectAnalysis.studentScore = action.payload;
    },
    // time taken
    createSubjectWiseTimeTaken(state, action) {
      state.timeTakenAnalysis.timeTakenArray = action.payload;
    },
    // difficulty type
    createOverallDifficultyTypeData: {
      prepare(stCount, tCount) {
        return {
          payload: { stCount, tCount },
        };
      },
      reducer(state, action) {
        state.queDifficultyAnalysis.overall.studentCount =
          action.payload.stCount;
        state.queDifficultyAnalysis.overall.totalCount = action.payload.tCount;
      },
    },
    createEventDifficultyTypeData: {
      prepare(stCount, tCount) {
        return {
          payload: {
            stCount,
            tCount,
          },
        };
      },
      reducer(state, action) {
        state.queDifficultyAnalysis.eventDetails.studentCount =
          action.payload.stCount;
        state.queDifficultyAnalysis.eventDetails.totalCount =
          action.payload.tCount;
      },
    },
  },
});

export const {
  createPerfomanceStudentScore,
  createPerfomanceTopperScore,
  createPerfomanceLabels,
  createOverallAnalysisScore,
  createProficiencySubjectAnalysisScore,
  createSubjectWiseTimeTaken,
  createOverallDifficultyTypeData,
  createEventDifficultyTypeData,
} = ChartSlice.actions;
export default ChartSlice.reducer;
