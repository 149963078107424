import React from "react";
import { ImageUrlPrefix } from "../../../../../labels";
import moment from "moment/moment";

export default function QuerySection(props) {
  const replyTime = props.query_rise.created_dt;
  function convertDateTime(dateTimeStr) {
    let [datePart, timePart, period] = dateTimeStr.split(" ");

    let [day, month, year] = datePart.split("-");

    return `${day}/${month}/${year} ${timePart} ${period}`;
  }

  let formattedDateTime = convertDateTime(replyTime);
  return (
    <div className="col-md-12">
      <p className="titleQuery mb-1">
        Query by {`${props.query_rise.full_name}`} ( {formattedDateTime} )
        {/* (
        {moment(props.query_rise.created_dt).format("DD/MM/YYYY hh:mm A")}) */}
      </p>
      <p className="explain_ques mt-0 mb-1">{props.query_rise.query}</p>
      <div className="d-flex ">
        <p className="titleQuery_attch mb-1 ">Attachments</p>
        <a
          download
          target="_blank"
          className="attachmentQuery"
          href={`${ImageUrlPrefix}/${props.query_rise.query_proof_filepath}`}
        >
          {" "}
          <span className="material-icons iconQuery">download</span>
        </a>
        <a
          target="_blank"
          className="attachmentQuery"
          href={`${ImageUrlPrefix}/${props.query_rise.query_proof_filepath}`}
        >
          {" "}
          <span className="material-icons iconQuery">visibility</span>
        </a>
      </div>
      <p className="explain_ques  mt-0">
        {props.query_rise.original_query_filepath}
      </p>
    </div>
  );
}
