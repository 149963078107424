import { AxiosInstance } from "../axios";

// VIEW-TEST-PERFOMANCE
export async function perfomanceBasicInfo(formData) {
  const { data, error } = await AxiosInstance.post(
    "/api/test/view/performance",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("performanceBasicInfo could not be loaded");
  }
  return data;
}

export async function studentPerfomanceChart(formData) {
  const { data, error } = await AxiosInstance.post(
    "api/test/chart/view",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("studentPerformanceChart could not be loaded");
  }
  return data;
}

export async function getTestTypes(formData) {
  const { data, error } = await AxiosInstance.post(
    "api/all/getTestTypes",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("studentPerformanceChart could not be loaded");
  }
  return data;
}

// ADMIN - MODEL EXAM CENTRE
export async function apiUpdateModelExamCentre(formData) {
  const { data, error } = await AxiosInstance.post(
    "api/batch/examCenter/update",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("createModelExam could not be updated");
  }
  return data;
}
// FACULTY - GET ALL THE STAFF LIST
export async function apiGetAllSupervisors(formData) {
  const { data, error } = await AxiosInstance.post(
    "api/staff/allSupervisor",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("all-supervisor list could not be loaded");
  }
  return data;
}

// ADMIN - MODEL-EXAM-ANALYTICS
export async function apiViewModelExamAnalytics(formData) {
  const { data, error } = await AxiosInstance.post(
    "api/modelexam/view/analytics",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("apiViewModelExamAnalytics could not be loaded");
  }
  return data;
}
export async function apiViewModelExamAnalyticsDistrictAndCentreList(formData) {
  const { data, error } = await AxiosInstance.post(
    "api/modelexam/district_center",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("apiViewModelExamAnalytics could not be loaded");
  }
  return data;
}

export async function apiStudentDetailsByCentre(formData) {
  const { data, error } = await AxiosInstance.post(
    "api/modelexam/center_list",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("apiStudentDetailsByCentre could not be loaded");
  } else {
    return data;
  }
}
export async function apiSubjectListforTimeTakenChart(formData) {
  const { data, error } = await AxiosInstance.post(
    "api/test/performance/subList/byEvent",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("apiStudentDetailsByCentre could not be loaded");
  } else {
    return data;
  }
}
export async function apiGetCentreSupervisorList(formData) {
  const { data, error } = await AxiosInstance.post(
    "api/modelexam/centre_supervisors_list",
    formData
  );
  if (error) {
    console.error(error);
    throw new Error("all-supervisor list could not be loaded");
  }

  return data;
}
export async function apiGetCentreSupervisorListDropdown(formData) {
  const { data, error } = await AxiosInstance.post("api/modelexam/", formData);
  if (error) {
    console.error(error);
    throw new Error("all-supervisor list could not be loaded");
  }

  return data;
}
