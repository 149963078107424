import React from "react";
import styled from "styled-components";
import { MEDIA_SIZE_XL } from "../utils/helper";
const StyledScrollableContainerBox = styled.div`
  overflow: auto;
  border: 1px dashed #ccc;
  @media (min-width: ${MEDIA_SIZE_XL}) {
    border: none;
    overflow: auto;
  }
`;
export default function StyledScrollableContainer({ children }) {
  return (
    <StyledScrollableContainerBox>{children}</StyledScrollableContainerBox>
  );
}
