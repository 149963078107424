import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import moment from "moment";
import Swal from "sweetalert2";

export default function ReadViewSalary() {
  const { userId, id, staff_id, staff_type } = useParams();
  const navigate = useNavigate();

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  let role_id = null;
  let user_type = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      role_id = user.role_id;
      user_type = user.user_type;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const [formFields, setFormFields] = useState({
    Date: "",
    MonthOf: "",
    TSalary: "",
    deduction: "",
    THSalary: "",
    eDate: "",
    no_class: "",
    sDate: "",
  });

  useEffect(() => {
    AxiosInstance.post("/api/staff/salary/findById", { id: id })
      .then((res) => {
        // console.log(res, "findbyid");
        if (res.data.status === true) {
          let el = res.data.data[0];
          setFormFields({
            Date: el.processed_date,
            MonthOf: el.month_of,
            TSalary: el.tot_salary,
            deduction: el.deduction,
            THSalary: el.takehome_salary,
            sDate: el.start_date,
            eDate: el.end_date,
            no_class: el.no_of_class,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [staff_id]);

  // console.log(formFields);
  return (
    <div className="Faculty">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading  dflexbetww ">
              <div className=" mt-4 dbetw">
                <h6 className="Title">View Salary</h6>
                <Link
                  to={`${user_type === 3 ? "/" : "/Faculties"}`}
                  className="CancelButtons MobileShow mt-1"
                >
                  {user_type === 3 ? "Back to Home" : "Back to Faculties"}
                </Link>
              </div>
              <div className="d-flex smButton mobiledown  mt-4">
                <Link
                  to={`${user_type === 3 ? "/" : "/Faculties"}`}
                  className="CancelButtons MobileHide"
                >
                  {user_type === 3 ? "Back to Home" : "Back to Faculties"}
                </Link>
                {user_type === 1 && (
                  <button type="button" className="ListViewBtn_event ">
                    <Link
                      to={`/Faculties/AddSalary/${userId}/${staff_id}/${staff_type}/0/${id}`}
                    >
                      <span className="material-icons me-1 fs-5 align-middle text-white">
                        border_color
                      </span>
                    </Link>
                    <Link
                      to={`/Faculties/AddSalary/${userId}/${staff_id}/${staff_type}/0/${id}`}
                    >
                      Edit Salary
                    </Link>
                  </button>
                )}
              </div>
            </div>

            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Salary Details</p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-4">
                      <div className="col-md-6 col-lg-3">
                        <div className="forms">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Processed Date
                          </label>
                          <span className="ReviewLabel">
                            {moment(formFields.Date).format("YYYY-MM-DD") ||
                              "_"}
                          </span>
                        </div>
                      </div>

                      {staff_type === "1" && (
                        <div className="col-md-6 col-lg-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              For the Month of
                            </label>
                            <span className="ReviewLabel">
                              {moment(formFields.MonthOf, "YYYY-MM").format(
                                "MMMM YYYY"
                              ) || "_"}
                            </span>
                          </div>
                        </div>
                      )}

                      {staff_type != "1" && (
                        <div className="col-md-6 col-lg-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Start Date
                            </label>
                            <span className="ReviewLabel">
                              {formFields.sDate !== null
                                ? moment(formFields.sDate).format("YYYY-MM-DD")
                                : "_"}
                            </span>
                          </div>
                        </div>
                      )}

                      {staff_type != "1" && (
                        <div className="col-md-6 col-lg-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              End Date
                            </label>
                            <span className="ReviewLabel">
                              {formFields.eDate !== null
                                ? moment(formFields.eDate).format("YYYY-MM-DD")
                                : "_"}
                            </span>
                          </div>
                        </div>
                      )}

                      {staff_type != "1" && (
                        <div className="col-md-6 col-lg-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Number of Classes
                            </label>
                            <span className="ReviewLabel">
                              {formFields.no_class || "_"}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Total Salary
                          </label>
                          <span className="ReviewLabel">
                            {formFields.TSalary || "_"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Deduction
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {formFields.deduction || "_"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Take Home Salary
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {formFields.THSalary || "_"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
