import { multiCusutomStyle } from "../pages/Multicustomstyles";
import Select, { components } from "react-select";
import { customStyles } from "../pages/customStyles";

export const CAOption = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{
          ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
          width: "18px", // Change the width of the checkbox
          height: "18px", // Change the height of the checkbox
        }}
      />
      <label style={multiCusutomStyle.optionLabel}>{props.label}</label>
    </components.Option>
  );
};

export const OptionBatch = (props) => {
  //  console.log(props, " items  ");
  return (
    <div style={{ textAlign: "left" }}>
      <components.Option {...props}>
        {props.data.isArr === true ? (
          <label style={{ color: "red" }}>{props.label}</label>
        ) : (
          // <label style={{ color: "black", display: props.isDisabled === false ? "none":"block" }}>
          <label
            style={{
              color: "black",
              paddingLeft: "30px",
            }}
          >
            {props.label}
          </label>
        )}
      </components.Option>
    </div>
  );
};

export const OptionBB = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{
          ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
          width: "18px", // Change the width of the checkbox
          height: "18px", // Change the height of the checkbox
        }}
      />
      <label style={multiCusutomStyle.optionLabel}>{props.label}</label>
    </components.Option>
  );
};

export const CommonOption = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{
          ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
          width: "18px", // Change the width of the checkbox
          height: "18px", // Change the height of the checkbox
        }}
      />
      <label style={multiCusutomStyle.optionLabel}>{props.label}</label>
    </components.Option>
  );
};

export const CustomCOption = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{
          ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
          width: "18px", // Change the width of the checkbox
          height: "18px", // Change the height of the checkbox
        }}
      />
      <label style={multiCusutomStyle.optionLabel}>{props.label}</label>
    </components.Option>
  );
};

export const CustomCOption_report = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{
          ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
          width: "18px", // Change the width of the checkbox
          height: "18px", // Change the height of the checkbox
        }}
      />
      <label style={multiCusutomStyle.optionLabel_genr}>{props.label}</label>
    </components.Option>
  );
};

export const CustomCOptionModelExam = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{
          ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
          width: "18px", // Change the width of the checkbox
          height: "18px", // Change the height of the checkbox
          verticalAlign: "center", // Aligns the element vertically to the Center
        }}
      />
      <label style={multiCusutomStyle.optionLabelModelE}>{props.label}</label>
    </components.Option>
  );
};

export const CSOption = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        style={{
          ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
          width: "18px", // Change the width of the checkbox
          height: "18px", // Change the height of the checkbox
        }}
      />
      <label style={multiCusutomStyle.optionLabel}>{props.label}</label>
    </components.Option>
  );
};
