// ModalComponent.js
import React from "react";
import "./dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

function ModelComForP(props) {
  return (
    <Modal show={props.show} size="lg" onHide={props.handleCloseModels}>
      <Modal.Header closeButton>
        <Modal.Title
          className="modal-title modelPaymentDue"
          id="exampleModalLabel"
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ModelBodyPO">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <span className="modelSwalText">{props.message}</span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props.BottomCancelBtn && (
          <Link
            to="javascript:void(0)"
            className="DashboardCancelButton"
            onClick={props.handleCloseModels}
          >
            {props.BottomCancelBtn}
          </Link>
        )}
        {props.BottomOkClose && (
          <Button className="DueButton_swal" onClick={props.handleCloseModels}>
            <a href="javascript:void(0)">{props.BottomOkClose}</a>
          </Button>
        )}

        {props.BottomOkBtn && (
          <Button className="DueButton" onClick={props.handleClose_yes}>
            <a href="javascript:void(0)">{props.BottomOkBtn}</a>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModelComForP;
