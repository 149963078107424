
import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { AxiosInstance } from "../../../../axios";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import { CustomCOption, CSOption, CAOption } from "../../../../labels/Lableoption";
import DatePickerSample from "../../DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
export const AddCounsellingPanel = (props) => {

    const [panelMem, setPanelMem] = useState([]);
      useEffect(() => {
        if(props.record.department.length > 0 ){
            AxiosInstance.post("api/staff/byDepartment",{
                "department":props.record.department.map(item => item.value),
                "staff_type":3 // for panel members its type 3 
            }).then((res) => {
              const panelMmebers = res.data.data
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort array of objects by name
                .map((item) => ({
                    staff_id : item.staff_id,
                    type : item.staff_type,
                    value: item.full_name,
                    label: item.full_name,
                    name: item.full_name,
                    email: item.email,
                    department:item.department !==null ? item.department.split(',') : "",
                    // department:["Tamil"],
                }
                ));
             setPanelMem(panelMmebers);
            })
            .catch((error) => {
              return error;
            });
        }
      }, [props.record.department]);
      
  return (
    <div className="row box">
      <div className="col-md-12">
        <p className="StepTitle">
          Panel Details {props.record.id > 1 ? "-" : ""}{" "}
          {props.record.id > 1 ? props.record.id : ""}{" "}
        </p>
        <div className="row">
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor={`panel_name`}>Panel Name</label>
              <input
              ref={props.txtBatchCPanelName}
                id="panel_name"
                type="text"
                name="panel_name"
                className="Inputs"
                placeholder="Please Enter Panel Name"
                   onChange={e => props.handleChangeCPanel(e, props.record.id)}
                   value={props.record.panel_name}
                autocomplete="Please Enter Panel Name"
                required
              />
                  {props.record.error_1 && (
           <small className="error">{props.record.error_1}</small>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor={`date`}>Date</label>
              {/* <input
                 ref={props.txtBatchCCDate}
                id="date"
                type="date"
                name="date"
                className="Inputs"
                placeholder="Please Enter Date"
                autocomplete="Please Enter Date"
                required
                onChange={e => props.handleChangeCPanel_date(e, props.record.id)}
                value={props.record.date}
              /> */}
              <DatePickerSample    txtAdMDob={props.txtBatchCCDate}  name={"date"}  handleIdChange={props.handleChangeCPanel_date}  fieldsId ={props.record.id} fieldInput={props.record.date}/>

                  {props.record.error_2 && (
           <small className="error">{props.record.error_2}</small>)}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor={`StartTime`}>Start Time</label>

              {/* <input
                 ref={props.txtBatchCStartTime}
               onChange={e => props.handleChangeCPanel(e, props.record.id)}
               value={props.record.StartTime}
                id="StartTime"
                type="time"
                step="1"
                name="StartTime"
                className="Inputs"
                placeholder="Please Enter Time"
                autocomplete="Please Enter Time"
                required
              /> */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  ref={props.txtBatchCStartTime}
                  id="StartTime"
                  step="1"
                  name="StartTime"
                  placeholder="Enter StartTime"
                  title={props.record.StartTime}
                  value={dayjs(props.record.StartTime)}
                  format="hh:mm:ss A"
                  onChange={e => props.handleChangeCPanelTime(e, props.record.id, "StartTime")}
                />
              </LocalizationProvider>
                  {props.record.error_3 && (
           <small className="error">{props.record.error_3}</small>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor={`EndTime`}>End Time</label>
              {/* <input
                 ref={props.txtBatchCEndTime}
                 onChange={e => props.handleChangeCPanel(e, props.record.id)}
                value={props.record.EndTime}
                id="EndTime"
                type="time"
                step="1"
                name="EndTime"
                className="Inputs"
                placeholder="Please Enter Time"
                autocomplete="Please Enter Time"
                required
                min={props.record.StartTime ? props.record.StartTime : "00:00:00"} // Ensure the correct format for min value
              /> */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  ref={props.txtBatchCEndTime}
                  id="EndTime"
                  step="1"
                  name="EndTime"
                  placeholder="Enter EndTime"
                  title={props.record.EndTime}
                  value={dayjs(props.record.EndTime)}
                  format="hh:mm:ss A"
                  // minTime={props.record.StartTime !==null &&  props.record.StartTime !=="" ? dayjs(props.record.StartTime, "HH:mm:ss") : ""}
                  // minTime={dayjs(props.record.StartTime, "HH:mm:ss")}
                  onChange={e => props.handleChangeCPanelTime(e, props.record.id, "EndTime")}
                />
                </LocalizationProvider>
                  {props.record.error_4 && (
           <small className="error">{props.record.error_4}</small>)}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor={`department`}>Department</label>
               <Select
                 ref={props.txtBatchCdept}
                  id="department"
                  name="department"
                  options={props.dPart}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  isClearable={true}
                  onChange={(e) =>
                    props.handleChangeDepartmentCPanel(e, props.record.id)
                  }
                  value={props.record.department.map((value) => ({
                    value: value.label,
                    label: value.value,
                  }))}
                  components={{
                    Option: CustomCOption, // Use the custom Option component
                  }}
                  styles={multiCusutomStyle} // Apply custom styles her
                />
                    {props.record.error_5 && (
           <small className="error">{props.record.error_5}</small>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor={`CPaneMem`}>Panel Member</label>
              <Select
                  ref={props.txtBatchCPM}
                  id="CPaneMem"
                  name="CPaneMem"
                  options={panelMem}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(e) =>
                    props.handleChangePMCPanel(e, props.record.id)
                  }
                  isClearable={true}
                  value={props.record.CPaneMem.map((value) => ({
                    staff_id: value.staff_id,
                    type: value.type,
                    value: value.label,
                    label: value.label,
                    name: value.name,
                    email: value.email,
                    department:value.department
                  }))}
                  components={{
                    Option: CustomCOption, // Use the custom Option component
                  }}
                  styles={multiCusutomStyle} // Apply custom styles her
                />
             
                {props.record.error_6 && (
           <small className="error">{props.record.error_6}</small>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor={`CStaffM`}>Teaching Faculty</label>
              <Select
                 ref={props.txtBatchCSM}
                 id="CStaffM"
                 name="CStaffM"
                 options={props.cStaffMem}
                 isMulti
                 closeMenuOnSelect={false}
                 hideSelectedOptions={false}
                 isClearable={true}
                 onChange={(e) =>
                  props.handleChangeTMCPanel(e, props.record.id)
                }
                value={props.record.CStaffM.map((value) => ({
                  staff_id: value.staff_id,
                  type: value.type,
                  value: value.label,
                  label: value.label,
                  name:value.name,
                  email:value.email,
                  department:"",
                }))}
                components={{
                  Option: CustomCOption, // Use the custom Option component
                }}
                  styles={multiCusutomStyle} // Apply custom styles her
                />
                {props.record.error_7 && (
           <small className="error">{props.record.error_7}</small>)}
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12 dflexendcolu">
            {props.record.id === 1 ? (
              ""
            ) : (
              <a
                className="AddMore me-3"
                onClick={() =>
                    props.DeleteCPanel(props.record.id, props.record.name) }  >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete Panel Details
              </a>
            )}
            {props.length > 1 && props.record.id === 1 ? (
              <a
                className="AddMore"
                onClick={() =>props.DeleteCPanel(props.record.id, props.record.name)
                }
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete Panel Details
              </a>
            ) : (
              <a
                className="AddMore"
                onClick={
                    props.AddCPanel
                }
              >
                <span className="material-icons download_icon align-middle">
                  add_circle
                </span>
                Add More Panel Details
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
