import React from "react";
import { Pie } from "react-chartjs-2";
import styled from "styled-components";
import { MEDIA_SIZE_LG } from "../../../../../utils/helper";

const BACKGROUNDCOLOR = ["#33FFFF", "#ffe066", "#77FF33", "#FF3333", "#AAAAAA"];
const ADDED_VALUE = -1;
// const ADDED_VALUE = 0;
const StyledLabelBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 5px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    width: 30px;
    margin-right: 10px;
  }
`;

const StyledLabel = styled.span`
  font-size: 12px;
  /* color: #ccc; */
  @media (min-width: ${MEDIA_SIZE_LG}) {
    margin-right: 30px;
  }
`;
const StyledLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 10px;
`;
const PieChart = ({ yourscore, totalscore }) => {
  // console.log(yourscore, "yourscore", totalscore, "totalscore");
  const labels = ["Very Hard", "Hard", "Medium", "Easy", "Very Easy"];
  // Sample data for 5 series
  // Generate legend items
  const legendItems = labels.toReversed().map((label, index) => {
    return (
      <div
        key={index}
        style={{ display: "flex", alignItems: "center", margin: "5px" }}
      >
        <StyledLabelBox
          style={{
            backgroundColor: BACKGROUNDCOLOR[index],
          }}
        ></StyledLabelBox>
        <StyledLabel>{label}</StyledLabel>
      </div>
    );
  });
  const data = {
    labels,
    datasets: [
      {
        label: "Very Hard",
        data: [
          yourscore[0] + ADDED_VALUE,
          totalscore[0] - yourscore[0] + ADDED_VALUE,
        ],
        backgroundColor: ["#AAAAAA", "#777777"],
        hoverOffset: 4,
        weight: 1,
        borderWidth: 0.5,
      },
      {
        label: "Hard",
        data: [
          yourscore[1] + ADDED_VALUE,
          totalscore[1] - yourscore[1] + ADDED_VALUE,
        ],
        backgroundColor: ["#FF3333", "#B20000"],
        hoverOffset: 4,
        weight: 1,
        borderWidth: 0.5,
      },
      {
        label: "Medium",
        data: [
          yourscore[2] + ADDED_VALUE,
          totalscore[2] - yourscore[2] + ADDED_VALUE,
        ],
        // backgroundColor: ["#69db7c", "#40c057"],
        backgroundColor: ["#77FF33", "#3BB200"],
        hoverOffset: 4,
        weight: 1,
        borderWidth: 0.5,
      },
      {
        label: "Easy", //New Changes
        data: [
          yourscore[3] + ADDED_VALUE,
          totalscore[3] - yourscore[3] + ADDED_VALUE,
        ],
        backgroundColor: ["#ffe066", "#fcc419"],
        hoverOffset: 4,
        weight: 1,
        borderWidth: 0.5,
      },
      {
        label: "Very Easy", //New Changes
        data: [
          yourscore[4] + ADDED_VALUE,
          totalscore[4] - yourscore[4] + ADDED_VALUE,
        ],
        backgroundColor: ["#33FFFF", "#00B2B2"],
        hoverOffset: 4,
        weight: 1,
        borderWidth: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // interaction: {
    //   mode: "index",
    //   intersect: false,
    // },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "proficiency analysis - question type",
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          // padding: 40,
          padding: 30,
          border: 0,
        },
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (funcData) {
            const title = funcData[0].dataset.label;
            return title;
          },
          label: function (labelData) {
            const { dataIndex, datasetIndex } = labelData;

            return dataIndex === 0
              ? `You Answered : ${yourscore[datasetIndex] + ADDED_VALUE} / ${
                  totalscore[datasetIndex] + ADDED_VALUE
                }`
              : `Total count : ${totalscore[datasetIndex] + ADDED_VALUE}`;
            // return dataIndex === 0
            //   ? `You Answered : ${yourscore[datasetIndex]} / ${
            //       totalscore[datasetIndex] + ADDED_VALUE
            //     }`
            //   : `Total count : ${totalscore[datasetIndex] + ADDED_VALUE}`; //subracted add value here which added when its zero in data loading
          },
        },
      },
    },
  };
  // console.log(data, "datadatadatadata");
  return (
    <>
      <div
        style={{
          minHeight: "300px",
          maxHeight: "300px",
          padding: "10px",
          marginTop: "15px",
        }}
      >
        <Pie data={data} options={options} />
      </div>
      <StyledLegendContainer>{legendItems}</StyledLegendContainer>
    </>
  );
};

export default PieChart;
