import Select, { components } from "react-select";
import React from "react";
import MultiSelectDatePicker from "./MultiSelectDatePicker";
import { useState } from "react";
import DatePicker from "react-datepicker";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import styled from "styled-components";
import moment from "moment";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";

export default function AddpanelMemberMocksec(props) {
  // Custom Option component
  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
       <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{
            ...props.isSelected ? multiCusutomStyle.checkboxChecked : {},
            width: '18px', // Change the width of the checkbox
            height: '18px', // Change the height of the checkbox
          }}/>
      <label style={multiCusutomStyle.optionLabel}>{props.label}</label>
      </components.Option>
    );
  };

  const [selectedDates, setSelectedDates] = useState([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleDateChange = (date) => {
    // console.log(date, "date");
    const nnn = moment(date).format("YYYY-MM-DD");
    if (!selectedDates.includes(date)) {
      setSelectedDates([...selectedDates, nnn]);
    } else {
      setSelectedDates(
        selectedDates.filter((selectedDate) => selectedDate !== date)
      );
    }
  };
  const ListItems = styled.li`
    margin: 2px;
  `;

  return (
    <section className="StepOne ">
      <div className="row box ">
        <div className="col-md-12">
          <p className="StepTitle">
            Panel Details {props.record.id > 1 ? "-" : ""}{" "}
            {props.record.id > 1 ? props.record.id : ""}{" "}
          </p>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="forms ">
                <label for="panelName">Panel Name:</label>
                <input
                  ref={props.txtBatchMPanelName}
                  type="text"
                  class="Inputs"
                  id="panel_name"
                  name="panel_name"
                  placeholder="Enter panel name"
                  onChange={(e) =>
                    props.mockPanelHandlechange(e, props.record.id)
                  }
                  value={props.record.panel_name}
                />
                {props.record.error_1 && (
                  <small className="error">{props.record.error_1}</small>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="forms ">
                <label for="available_dates">Available Dates:</label>
                {/* available_dates mutiple selected */}

                {/* <MultiSelectDatePicker id={props.record.id} handleDeleteMock={props.handleDeleteMock} selectedDatesMock={props.selectedDatesMock} handleDateChange = {props.handleDateChangeMock}/> */}

                <div className="multi-select-datepicker">
                  <div className="selection-box ms-3 mt-2">
                    <div className="row">
                      <div className="col-md-1 col-sm-12">
                        <input ref={props.txtBatchMADate} type="text" className="hidden_fields"/>
                        <span
                        
                          class="material-icons fs-3"
                          onClick={toggleDatePicker}
                        >
                          calendar_month
                        </span>
                      </div>

                      {props.record.available_dates.length > 0 ? (
                        <div
                          className="col-md-11 col-sm-12 col-xs-12"
                          id="paperil"
                        >
                          <div className="chips-container">
                            {props.record.available_dates.map((date, index) => (
                              <div key={index} className="chip">
                                {date}
                                <span
                                  className="chip-close-icon"
                                  onClick={() =>
                                    props.handleDeleteMock(
                                      date,
                                      props.record.id
                                    )
                                  }
                                >
                                  &#10005;
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-11 col-sm-12 col-xs-12">
                          {" "}
                          <span className="mt-2">No dates available</span>{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  {isDatePickerOpen && (
                    <div className="date-picker-container">
                      <DatePicker
                        selected={null}
                        onChange={(date) =>
                          props.handleDateChangeMock(
                            date,
                            props.record.id !==null ? props.record.id : ""
                          )
                        }
                        inline
                        multiSelect
                        minDate={
                          props.startDate !==null
                            ? new Date(props.startDate)
                            : ""
                        } // Replace with your desired minimum date
                        maxDate={
                          props.endDate !==null ? new Date(props.endDate) : ""
                        } // Replace with your desired maximum date
                        // showYearDropdown
                        // scrollableYearDropdown
                      />
                    </div>
                  )}
                </div>

                {props.record.error_2 && (
                  <small className="error">{props.record.error_2}</small>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="forms ">
                <label for="panelMem">Panel Members:</label>
                {/* panelMem mutiple selected */}
                <Select
                 ref={props.txtBatchMPM}
                 id="panelMem"
                 name="panelMem"
                 options={props.panelMem}
                 isMulti
                 closeMenuOnSelect={false}
                 hideSelectedOptions={false}
                 onChange={(e) =>
                  props.handleChangeMutiplePanleMme(e, props.record.id)
                }
                 isClearable={true}
                 value={props.record.panelMem.map((value) => ({
                  staff_id: value.staff_id,
                  value: value.label,
                  type: value.type,
                  label: value.label,
                  email: value.email,
                  name: value.name,
                  department:"",
                }))}
                components={{
                  Option: CustomOption, // Use the custom Option component
                }}
                  styles={multiCusutomStyle} // Apply custom styles her
                />
                {/* <Select
                  ref={props.txtBatchMPM}
                  id="panelMem"
                  name="panelMem"
                  options={props.panelMem}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  isClearable={true}
                  onChange={(e) =>
                    props.handleChangeMutiplePanleMme(e, props.record.id)
                  }
                  value={props.record.panelMem.map((value) => ({
                    staff_id: value.staff_id,
                    value: value.label,
                    type: value.type,
                    label: value.label,
                    email: value.email,
                    name: value.name,
                    department:"",
                  }))}
                  components={{
                    Option: CustomOption, // Use the custom Option component
                  }}
                  style={multiCusutomStyle}
                /> */}
                {props.record.error_3 && (
                  <small className="error">{props.record.error_3}</small>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="forms ">
                <label for="staffMem">Teaching Faculty:</label>
                {/* panelMem mutiple selected */}

                <Select
                ref={props.txtBatchMSM}
                id="staffMem"
                name="staffMem"
                options={props.staffMem}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isClearable={true}
                onChange={(e) =>
                  props.handleChangeMutiplestaffMem(e, props.record.id)
                }
                value={props.record.staffMem.map((value) => ({
                  staff_id: value.staff_id,
                  type: value.type,
                  value: value.label,
                  label: value.label,
                  name:value.name,
                  email:value.email,
                  department:"",
                }))}
                components={{
                  Option: CustomOption, // Use the custom Option component
                }}
                  styles={multiCusutomStyle} // Apply custom styles her
              />
               
                {props.record.error_4 && (
                  <small className="error">{props.record.error_4}</small>
                )}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12 dflexendcolu">
              {props.record.id === 1 ? (
                ""
              ) : (
                <a
                  className="AddMore me-3"
                  onClick={() =>
                    props.DeleteMockPanel(props.record.id, props.record.name)
                  }
                >
                  <span className="material-icons download_icon align-middle">
                    remove_circle
                  </span>
                  Delete Panel Details
                </a>
              )}
              {props.length > 1 && props.record.id === 1 ? (
                <a
                  className="AddMore"
                  onClick={() =>
                    props.DeleteMockPanel(props.record.id, props.record.name)
                  }
                >
                  <span className="material-icons download_icon align-middle">
                    remove_circle
                  </span>
                  Delete Panel Details
                </a>
              ) : (
                <a className="AddMore" onClick={props.AddMockPanel}>
                  <span className="material-icons download_icon align-middle">
                    add_circle
                  </span>
                  Add More Panel Details
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
