import moment from "moment";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import Swal from "sweetalert2";
import QuerySection from "../Faculty/StudentsQuery/QuerySection";
import ExplanationQuery from "../Faculty/StudentsQuery/ExplanationQuery";
import RtypeOne from "../StudentTestWorkflow/RtypeOne";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import { CustomCOption } from "../../../../labels/Lableoption";
import Select, { components } from "react-select";
import { customStyles } from "../../../../pages/customStyles";
import PublicResult from "./PublicResult";
import RtypeTwo from "../StudentTestWorkflow/RtypeTwo";
import RtypeThree from "../StudentTestWorkflow/RtypeThree";
import RtypeFour from "../StudentTestWorkflow/RtypeFour";
import RtypeParaOption from "../StudentTestWorkflow/RtypeParaOption";
import Rtypepara from "../StudentTestWorkflow/Rtypepara";
import RtypeTen from "../StudentTestWorkflow/RtypeTen";
import RtypeNine from "../StudentTestWorkflow/RtypeNine";
import RtypeEight from "../StudentTestWorkflow/RtypeEight";
import RtypeSeven from "../StudentTestWorkflow/RtypeSeven";
import RtypeSix from "../StudentTestWorkflow/RtypeSix";
import RtypeFive from "../StudentTestWorkflow/RtypeFive";
import { Visibility } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import Loader from "../../Loader";
export default function DetailTestView() {
  const [showPublicResults, setShowPublicResults] = useState(false);
  const handlePublicResults = () => {
    setShowPublicResults(!showPublicResults);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let currentTime = moment();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  const { id, status, type } = useParams();
  const [flagReload, setFlagReload] = useState(false);
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditStaff, setIsEditStaff] = useState(false);
  const txtAssignTo = useRef(null);
  const [error, setError] = useState({});

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  let user_type = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      user_type = user.user_type;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }
  useEffect(() => {
    AxiosInstance.post("api/all/getTeachingStaffList")
      .then((res) => {
        const staffMmem = res.data.data.map((item, index) => ({
          value: item.user_id, // faculty user id
          label: item.full_name,
          staff_id: item.staff_id,
        }));
        setStaffMem(staffMmem);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    findById(id);
  }, []);

  useEffect(() => {
    findById(id);
  }, [flagReload]);

  const findById = (idss) => {
    setIsLoading(true);
    AxiosInstance.post("api/event/findById", {
      id: parseInt(idss),
      event_type: 1,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          setIsLoading(false);
          let el = res.data.data;
          setFdata((prevFdata) => ({
            ...prevFdata,
            title: el.name,
            sDate:
              el.start_date !== null
                ? moment(el.start_date).format("YYYY/MM/DD")
                : "",
            startTime: el.start_time,
            endTime: el.end_time,
            subject: el.subject,
            publish_results: el.publish_results,
            staff_name: el.staff_name,
            description: el.description,
            batchName: el.batch_name,
            category: el.category,
            course: el.category_class,
            method_of_test: el.method_of_test,
            que_status: el.que_status,
            classMode: el.class_mode,
            medium: el.medium,
            location: el.location,
            teams_url: el.teams_url,
            batch_id: el.batch_id,
            cutOff: el.cut_off,
            event_id: el.id,
            event_type: el.event_type,
            test_num: el.test_no,
            test_topic: el.test_topic,
            tol_mark: el.tot_marks,
            no_ques: el.no_of_questions,
            qpath: el.que_paper_filepath,
          }));

          const subTopics =
            el.subject_test_topics && el.subject_test_topics.length > 0
              ? el.subject_test_topics
              : [];

          setSubTopic(subTopics);
          const SubOtherApplic =
            el.oth_app_batch && el.oth_app_batch.length > 0
              ? el.oth_app_batch
              : [];
          setOtherApplic(SubOtherApplic);
          let que_spaperinfo =
            res.data.data.que_pap_info.length > 0
              ? res.data.data.que_pap_info
              : [];

          const transformedData = que_spaperinfo.map((item) => {
            const { subject, no_of_ques, difficulty_type } = item;
            const difficulty = difficulty_type[0];
            // console.log(difficulty, "difficulty");
            return {
              subject,
              no_ques: no_of_ques,
              very_easy_ques: difficulty.hasOwnProperty("1")
                ? difficulty["1"]
                : "_",
              easy_ques: difficulty.hasOwnProperty("0") ? difficulty["0"] : "_",
              med_ques: difficulty.hasOwnProperty("4") ? difficulty["4"] : "_",
              hard_ques: difficulty.hasOwnProperty("2") ? difficulty["2"] : "_",
              very_hard_ques: difficulty.hasOwnProperty("3")
                ? difficulty["3"]
                : "_",
              medium: subject,
            };
          });

          setQuesdata(transformedData);

          const query_section =
            el.query.length > 0
              ? el.query.map((query) => ({
                  ...query,
                  query_status:
                    query.assignee_info &&
                    query.assignee_info.query_status === 0
                      ? "0"
                      : "1",
                  query_path:
                    query.assignee_info &&
                    query.assignee_info.query_proof_filepath
                      ? query.assignee_info.query_proof_filepath
                      : null,
                  reply_query:
                    query.assignee_info && query.assignee_info.reply_by_staff
                      ? query.assignee_info.reply_by_staff
                      : "",
                  original_query_path:
                    query.assignee_info &&
                    query.assignee_info.original_query_filepath
                      ? query.assignee_info.original_query_filepath
                      : "",
                  query_Status_error: "",
                  query_path_error: null,
                  reply_status_error: "",
                  isEdit: false,
                  IsUpdate: false,
                  IsFacultyEdit: false,
                  IsfacultyUpdate:
                    query.assignee_info && query.assignee_info.reply_by_staff
                      ? query.assignee_info.reply_by_staff
                        ? true
                        : false
                      : "",
                  IsFaculty_id:
                    query.assignee_info && query.assignee_info.assigned_to
                      ? query.assignee_info.assigned_to
                      : null,
                  isFaculty_name:
                    query.assignee_info && query.assignee_info.assigned_to_name
                      ? query.assignee_info.assigned_to_name
                      : "",
                  isFaculty_error: "",
                  query_stage:
                    query.assignee_info && query.assignee_info.query_stage
                      ? query.assignee_info.query_stage
                      : 0,
                }))
              : [];
          setViewQueries(query_section);

          // const selectedStaffMem_findById = res.data.data.query.filter(option => option.assignee_info !== null).map(
          //   (option) => ( {
          //     staff_id:
          //       option.assignee_info !== null
          //         ? option.assignee_info.assigned_to
          //         : "",
          //     value:
          //       option.assignee_info !== null
          //         ? option.assignee_info.assigned_to
          //         : "",
          //     label:
          //       option.assignee_info !== null
          //         ? option.assignee_info.assigned_to_name
          //         : "",
          //     question_pk:
          //       option.assignee_info !== null
          //         ? option.assignee_info.question_pk
          //         : "",
          //   } )  );
          // setSelectedStaffMem(selectedStaffMem_findById);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };

  const [subTopic, setSubTopic] = useState([]);
  const [otherApplic, setOtherApplic] = useState([]);

  const [quesdata, setQuesdata] = useState({});
  const [staffMem, setStaffMem] = useState([]);

  const [selectedStaffMem, setSelectedStaffMem] = useState([]);
  const txtQueryStatus = useRef(null);
  const txtQueryPath = useRef(null);
  const txtQueryReply = useRef(null);

  const [viewQueries, setViewQueries] = useState([]);

  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    startTime: "",
    endTime: "",
    subject: "",
    staff_name: "",
    description: "",
    batchName: "",
    category: "",
    course: "",
    method_of_test: "",
    que_status: "",
    classMode: "",
    medium: "",
    location: "",
    teams_url: "",
    batch_id: "",
    event_id: "",
    test_num: "",
    test_topic: "",
    tol_mark: "",
    no_ques: "",
    qpath: null,
    assign_to: "",
    assign_to_name: "",
    event_type: "",
    cutOff: "",
    publish_results: null,
  });
  // console.log(fdata, "fdata");
  const Upload_Question = () => {
    navigate(`/UploadQuestionFile/${fdata.method_of_test === 0 ? 1 : 0}`, {
      state: {
        id,
        status,
      },
    });
  };

  const handleNavigate = (
    isSameDayss,
    isAfterStartTimesss,
    isAfterStartDatess
  ) => {
    const eventName = fdata.title;
    const bachId = fdata.batch_id;
    const publish_results = fdata.publish_results;
    const Isdisabled =
      (isSameDayss && isAfterStartTimesss) || isAfterStartDatess;

    navigate("/ViewQuestionPaper", {
      state: {
        id,
        status,
        eventName,
        bachId,
        publish_results,
        Isdisabled,
      },
    });
  };

  const handleflagReload = () => {
    setFlagReload(true);
  };
  const handleAssign = (option, dname, questionPk) => {
    // console.log(option, "option");
    if (option) {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === questionPk) {
            return {
              ...q,
              IsFaculty_id: option.value,
              isFaculty_name: option.label,
              isFaculty_error: "",
            };
          }
          return q;
        });
      });
    } else {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === questionPk) {
            return {
              ...q,
              IsFaculty_id: "",
              isFaculty_name: "",
              isFaculty_error: "",
            };
          }
          return q;
        });
      });
    }
  };
  const validateAssignTo = (queries, pk) => {
    const UpdateReplyss = queries.map((OB) => {
      // console.log(OB, "Adasdadasd");
      let updatedOB = { ...OB };
      if (!OB.IsFaculty_id && !OB.isFaculty_name && OB.question_pk === pk) {
        updatedOB.isFaculty_error = "Please choose faculty ";
      }
      return updatedOB;
    });

    setViewQueries(UpdateReplyss);
  };
  const handleAssignTo = (elements) => {
    validateAssignTo(viewQueries, elements.question_pk);
    if (
      viewQueries.length > 0 &&
      viewQueries.find(
        (el) =>
          el.isFaculty_error === "" &&
          el.IsFaculty_id !== "" &&
          el.isFaculty_name !== "" &&
          el.question_pk === elements.question_pk
      )
    ) {
      const df = {
        user_type: 0, // admin assign to deafult user_type  0→admin, 1→faculty
        event_type: fdata.event_type ? fdata.event_type : 1, // default as test so event type 1
        event_pk: fdata.event_id ? fdata.event_id : "",
        batch_pk: fdata.batch_id ? fdata.batch_id : "",
        question_pk: elements.question_pk,
        // rquery_pk:
        //   elements.stud_query && elements.stud_query.length > 0
        //     ? elements.stud_query.map((el) => el.pk)
        //     : null,
        remaind_again: 0,
        assigned_to: elements.IsFaculty_id ? elements.IsFaculty_id : "",
        assigned_by: userid, // admin_user id
        query_status: 0, // 0 valid 1 invalid
        query_proof_filepath: "",
        original_query_filepath: "",
        reply_by_staff: null,
        query_stage: 0, // 0 others 1 approved
        query_approved_dt: null,
        created_by: userid,
        updated_by: userid,
      };
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return {
              ...q,
              isEdit: false,
            };
          }
          return q;
        });
      });
      setFlagReload(false);
      if (df.assigned_to !== "") {
        RaiseAdminQuery(df);
      }
    }
  };

  const validateQueryReply = (queries) => {
    const UpdateReplyss = queries.map((OB) => {
      // console.log(OB, "sdsfsdf");
      let updatedOB = { ...OB };
      if (!OB.query_status) {
        updatedOB.query_Status_error = "Please Choose Query Status";
      }
      if (OB.query_path === null) {
        updatedOB.query_path_error = "Please Choose Query Path";
      }
      if (!OB.reply_query) {
        updatedOB.reply_status_error = "Please enter reply ";
      }

      if (!OB.isFaculty_error) {
        updatedOB.isFaculty_error = "Please choose faculty ";
      }

      return updatedOB;
    });

    setViewQueries(UpdateReplyss);
  };

  const handleUpdateApprove = (elements) => {
    validateQueryReply(viewQueries);
    if (
      viewQueries.length > 0 &&
      viewQueries.every(
        (el) =>
          el.query_Status_error === "" &&
          (el.query_path_error === "" || el.query_path_error === null) &&
          el.reply_status_error === ""
      )
    ) {
      const df = {
        user_type: 0, // admin assign to deafult user_type  0→admin, 1→faculty
        event_type: fdata.event_type ? fdata.event_type : 1, // default as test so event type 1
        event_pk: fdata.event_id ? fdata.event_id : "",
        batch_pk: fdata.batch_id ? fdata.batch_id : "",
        question_pk: elements.question_pk,
        remaind_again: 0,
        assigned_to: elements.IsFaculty_id ? elements.IsFaculty_id : "",
        assigned_by: userid, // admin_user id
        query_status: elements.query_status, // 0 valid 1 invalid
        query_proof_filepath: elements.query_path,
        original_query_filepath: elements.original_query_path,
        reply_by_staff: elements.reply_query,
        // rquery_pk:
        //   elements.stud_query && elements.stud_query.length > 0
        //     ? elements.stud_query.map((el) => el.pk)
        //     : null,
        query_stage: 1, // 0 others 1 approved
        query_approved_dt: moment().format("YYYY/MM/DD HH:mm:ss"),
        created_by: userid,
        updated_by: userid,
      };
      if (elements.reply_query !== "" && elements.query_status !== "") {
        RaiseAdminQuery(df);
      }
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return {
              ...q,
              isUpdate: false,
              isEdit: false,
              IsFacultyEdit: false,
              IsfacultyUpdate: true,
            };
          }
          return q;
        });
      });
    }
  };

  const handleRemindMeAgain = (elements) => {
    const df = {
      user_type: 0, // admin assign to deafult user_type  0→admin, 1→faculty
      event_type: fdata.event_type ? fdata.event_type : 1, // default as test so event type 1
      event_pk: fdata.event_id ? fdata.event_id : "",
      batch_pk: fdata.batch_id ? fdata.batch_id : "",
      question_pk: elements.question_pk,
      remaind_again: 1,
      assigned_to: elements.IsFaculty_id ? elements.IsFaculty_id : "",
      assigned_by: userid, // admin_user id
      query_status: 0, // 0 valid 1 invalid
      query_proof_filepath: "",
      original_query_filepath: "",
      reply_by_staff: null,
      // rquery_pk:
      //   elements.stud_query && elements.stud_query.length > 0
      //     ? elements.stud_query.map((el) => el.pk)
      //     : null,
      query_stage: 0, // 0 others 1 approved
      query_approved_dt: null,
      created_by: userid,
      updated_by: userid,
    };
    RaiseAdminQuery(df);
  };

  const handleApprove = (elements) => {
    const df = {
      user_type: 0, // admin assign to deafult user_type  0→admin, 1→faculty
      event_type: fdata.event_type ? fdata.event_type : 1, // default as test so event type 1
      event_pk: fdata.event_id ? fdata.event_id : "",
      batch_pk: fdata.batch_id ? fdata.batch_id : "",
      question_pk: elements.question_pk,
      remaind_again: 0,
      assigned_to: elements.IsFaculty_id ? elements.IsFaculty_id : "",
      assigned_by: userid, // admin_user id
      query_status: elements.query_status, // 0 valid 1 invalid
      query_proof_filepath: elements.query_path,
      original_query_filepath: elements.original_query_path,
      reply_by_staff: elements.reply_query,
      query_stage: 1, // 0 others 1 approved
      query_approved_dt: moment().format("YYYY/MM/DD HH:mm:ss"),
      created_by: userid,
      // rquery_pk:
      //   elements.stud_query && elements.stud_query.length > 0
      //     ? elements.stud_query.map((el) => el.pk)
      //     : null,
      updated_by: userid,
    };
    if (elements.reply_query !== "" && elements.query_status !== "") {
      RaiseAdminQuery(df);
    }
    // setViewQueries((prevState) => {
    //   return prevState.map((q) => {
    //     if (q.question_pk === elements.question_pk) {
    //       return {
    //         ...q,
    //         isUpdate: false,
    //         isEdit: false,
    //         IsFacultyEdit: false,
    //         IsfacultyUpdate: false,
    //       };
    //     }
    //     return q;
    //   });
    // });
  };

  const handleEditAssignTo = (el) => {
    setViewQueries((prevState) => {
      return prevState.map((q) => {
        if (q.question_pk === el.question_pk) {
          return {
            ...q,
            isEdit: true,
            IsfacultyUpdate: false,
            IsFacultyEdit: false,
          };
        }
        return q;
      });
    });
  };

  const handleEditFaculty = (el) => {
    setViewQueries((prevState) => {
      return prevState.map((q) => {
        if (q.question_pk === el.question_pk) {
          return {
            ...q,
            isUpdate: false,
            isEdit: false,
            IsFacultyEdit: true,
            IsfacultyUpdate: false,
          };
        }
        return q;
      });
    });
  };

  const RaiseAdminQuery = (dff) => {
    AxiosInstance.post("api/test/raise/query/byAdmin", dff)
      .then((response) => {
        if (response.data.status) {
          setFlagReload(true);
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          setFlagReload(false);
          Swal.fire({
            title: "Warning",
            text: response.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleChange = (event, elements) => {
    const { name, value, files, checked } = event.target;
    if (name === "query_path" && files && files.length > 0) {
      // console.log(value);
      const query_file = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("query_file", query_file);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setViewQueries((prevState) => {
                return prevState.map((q) => {
                  if (q.question_pk === elements.question_pk) {
                    return {
                      ...q,
                      query_path: res.data.path,
                      original_query_path: res.data.originalname,
                      query_path_error: "",
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setViewQueries((prevState) => {
            return prevState.map((q) => {
              if (q.question_pk === elements.question_pk) {
                return { ...q, query_path_error: err };
              }
              return q;
            });
          });
        }
      } else {
        let err = "pdf files only allowed";
        setViewQueries((prevState) => {
          return prevState.map((q) => {
            if (q.question_pk === elements.question_pk) {
              return { ...q, query_path_error: err };
            }
            return q;
          });
        });
      }
    } else if (name === "query_status") {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return { ...q, query_status: value, query_Status_error: "" };
          }
          return q;
        });
      });
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "reply_query") {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return { ...q, reply_query: value, reply_status_error: "" };
          }
          return q;
        });
      });
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const handleCancel = (el) => {
    setViewQueries((prevState) => {
      return prevState.map((q) => {
        if (q.question_pk === el.question_pk) {
          return {
            ...q,
            isUpdate: true,
            isEdit: false,
            IsfacultyUpdate: true,
            IsFacultyEdit: false,
          };
        }
        return q;
      });
    });
  };

  // Parse the start date and end time
  const startDate = moment(fdata.sDate, "YYYY-MM-DD");
  const endTime = moment(fdata.endTime, "HH:mm:ss");
  const startTime = moment(fdata.startTime, "HH:mm:ss");
  // Combine the current date with the end time to get the correct endDateTime
  const endDateTime = moment(
    `${currentTime.format("YYYY-MM-DD")} ${endTime.format("HH:mm:ss")}`,
    "YYYY-MM-DD HH:mm:ss"
  );

  const StartDateTime = moment(
    `${currentTime.format("YYYY-MM-DD")} ${startTime.format("HH:mm:ss")}`,
    "YYYY-MM-DD HH:mm:ss"
  );

  // console.log("startDate:", startDate.format("YYYY-MM-DD"));
  // console.log("endTime:", endTime.format("HH:mm:ss"));
  // console.log("endDateTime:", endDateTime.format("YYYY-MM-DD HH:mm:ss"));
  // console.log("currentTime:", currentTime.format("YYYY-MM-DD HH:mm:ss"));

  // Check if current time is on the same day as start date and after the end time
  const isSameDay = currentTime.isSame(startDate, "day");
  const isAfterEndTime = currentTime.isAfter(endDateTime);
  const isAfterStartTime = currentTime.isAfter(StartDateTime);
  const isAfterStartDate = currentTime.isAfter(startDate, "day");

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading dflexbetww ">
              <div class="mt-2 dbetw">
                <h6
                  className={
                    status === "1" && fdata.qpath !== null
                      ? "Tits_detail w-100"
                      : "Tits_details"
                  }
                >
                  {" "}
                  View Test
                </h6>
                <Link to="/TestList" className="CancelButton MobileShow ">
                  Back to Test
                </Link>
              </div>

              <div className="d-flex smButton mobiledown  ">
                <Link
                  to="/TestList"
                  className="CancelButtons_ques  MobileHide "
                >
                  Back to Test
                </Link>
                {type !== "4" && (
                  <button
                    type="button"
                    disabled={
                      (isSameDay && isAfterStartTime) || isAfterStartDate
                    }
                    className={`detailEventButton mt-sm-4 ms-2 ${
                      (isSameDay && isAfterStartTime) || isAfterStartDate
                        ? "DisabledListviS"
                        : ""
                    }`}
                    onClick={Upload_Question}
                  >
                    <span class="material-icons me-1">upload</span>
                    <a href="javascript:void(0)">Upload New Question Paper</a>
                  </button>
                )}

                {fdata.qpath !== null &&
                  fdata.qpath !== "" &&
                  status === "1" &&
                  type !== "4" && (
                    <button
                      type="button"
                      className="detailEventButtons mt-sm-4"
                      onClick={() =>
                        handleNavigate(
                          isSameDay,
                          isAfterStartTime,
                          isAfterStartDate
                        )
                      }
                    >
                      {" "}
                      <span class="material-icons me-2">visibility</span>
                      <a href="javascript:void(0)">View Question Paper</a>
                    </button>
                  )}
                {((isSameDay && isAfterEndTime) || isAfterStartDate) &&
                type !== "4" ? (
                  <button
                    type="button"
                    disabled={fdata.publish_results === 1}
                    className={`detailEventButtons mt-sm-4 ms-2 ${
                      fdata.publish_results === 1 ? "DisabledListviS" : ""
                    }`}
                    onClick={() => handlePublicResults()}
                  >
                    <a href="javascript:void(0)">Publish Results</a>
                    {showPublicResults && (
                      <PublicResult
                        batch_id={fdata.batch_id}
                        event_id={fdata.event_id}
                        user_id={userid}
                        handleflagReload={handleflagReload}
                      />
                    )}
                  </button>
                ) : (
                  ""
                )}
                {/* <button
                  type="button"
                  disabled={fdata.publish_results === 1 ? true : false}
                  className={`detailEventButtons mt-sm-4 ms-2 ${
                    fdata.publish_results === 1 ? "DisabledListviS" : ""
                  } `}
                  onClick={() => handlePublicResults()}
                >
                  <a href="javascript:void(0)">Publish Results </a>
                  {showPublicResults && (
                    <PublicResult
                      batch_id={fdata.batch_id}
                      event_id={fdata.event_id}
                      user_id={userid}
                      handleflagReload={handleflagReload}
                    />
                  )}
                </button> */}
              </div>
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Test Details</p>
                <div className="row mb-3">
                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Test Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.title !== null ? fdata.title : "⎯"}
                      </span>
                    </div>
                  </div>

                  {/* <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Test Number
                      </label>
                      <span className="ReviewLabel">
                        {fdata.test_num !== null ? fdata.test_num : "⎯"}
                      </span>
                    </div>
                  </div> */}

                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Date
                      </label>
                      <span className="ReviewLabel">
                        {fdata.sDate !== null ? fdata.sDate : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Start Time
                      </label>
                      <span className="ReviewLabel">
                        {fdata.startTime !== null
                          ? moment(fdata.startTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        End Time
                      </label>
                      <span className="ReviewLabel">
                        {fdata.endTime !== null
                          ? moment(fdata.endTime, "HH:mm:ss").format("h:mm A")
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Total Number of Questions
                      </label>
                      <span className="ReviewLabel">
                        {fdata.no_ques !== null ? fdata.no_ques : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Total Marks
                      </label>
                      <span className="ReviewLabel">
                        {fdata.tol_mark !== null ? fdata.tol_mark : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Batch Name
                      </label>
                      <span className="ReviewLabel">
                        {fdata.batchName !== null ? fdata.batchName : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Category
                      </label>
                      <span className="ReviewLabel">
                        {fdata.category !== null ? fdata.category : "⎯"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Course
                      </label>
                      <span className="ReviewLabel">
                        {fdata.course !== null ? fdata.course : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Method of Test
                      </label>
                      <span className="ReviewLabel">
                        {fdata.method_of_test === 0
                          ? "Objective"
                          : fdata.method_of_test === 1
                          ? "Descriptive"
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Status
                      </label>
                      <span className="ReviewLabel">
                        {fdata.publish_results === 0
                          ? "Results Not Published"
                          : fdata.publish_results === 1
                          ? "Results Published"
                          : "⎯"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row box">
              <div className="col-md-12 mb-3">
                <p className="StepTitle">Subjects and Topics</p>
                {subTopic.length > 0 ? (
                  subTopic.map((onk) => {
                    return (
                      <div className="row ">
                        <div className=" col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Subject
                            </label>
                            <span className="ReviewLabel">
                              {onk.subject_name}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Test Topics
                            </label>
                            <span className="ReviewLabel">
                              {onk.test_topics
                                .map((el) => el.test_topic)
                                .join(", ")}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="row ">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Subjects and Topics
                        </label>
                        <span className="ReviewLabel">{"-"}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row box">
              <div className="col-md-12 mb-3">
                <p className="StepTitle"> Other Applicable Batches</p>
                {otherApplic.length > 0 ? (
                  otherApplic.map((onk) => {
                    return (
                      <div className="row ">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Other Applicable Batches
                            </label>
                            <span className="ReviewLabel">{onk.name}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="row ">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Other Applicable Batches
                        </label>
                        <span className="ReviewLabel">{"-"}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Uploaded Question Paper</p>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Uploaded Question Paper
                        {fdata.qpath !== "" && fdata.qpath !== null && (
                          <a
                            href={`${ImageUrlPrefix}/${fdata.qpath}`}
                            target="_blank"
                            download
                          >
                            <span className="material-icons align-middle download_icon">
                              file_download
                            </span>
                          </a>
                        )}
                      </label>
                      <span className="ReviewLabel">
                        {fdata.qpath !== null ? (
                          <a
                            target="_blank"
                            className="questionpapera"
                            href={`${ImageUrlPrefix}/${fdata.qpath}`}
                          >
                            {fdata.qpath.split("/").pop()}
                          </a>
                        ) : (
                          "⎯"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Cut-Off
                      </label>
                      <span className="ReviewLabel">
                        {fdata.cutOff !== null ? fdata.cutOff : "⎯"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Question Paper Details</p>
                {quesdata.length > 0 ? (
                  quesdata.map((el, index) => (
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">Subject</h5>
                          <span className="ReviewLabel">
                            {el.subject !== null && el.subject !== ""
                              ? el.subject
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Total Number of Questions
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.no_ques !== null && el.no_ques !== ""
                              ? el.no_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Very Easy Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.very_easy_ques !== null &&
                            el.very_easy_ques !== ""
                              ? el.very_easy_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Easy Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.easy_ques !== null && el.easy_ques !== ""
                              ? el.easy_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Medium Questions
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.med_ques !== null && el.med_ques !== ""
                              ? el.med_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Hard Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.hard_ques !== null && el.hard_ques !== ""
                              ? el.hard_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="forms ">
                          <h5 className="Question_label">
                            Very Hard Questions{" "}
                            <span
                              className={el.medium.length > 12 ? "Sil" : ""}
                            >
                              ({el.medium})
                            </span>
                          </h5>
                          <span className="ReviewLabel">
                            {el.very_hard_ques !== null &&
                            el.very_hard_ques !== ""
                              ? el.very_hard_ques
                              : "⎯"}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="row mb-3 mt-4">
                    <div className="col-md-3">
                      <div className="forms ">
                        <span className="ReviewLabel">
                          No record to display{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
          {viewQueries.length > 0 &&
            viewQueries.map((el, index) => (
              <section className="StepOne">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">
                      Query - Question No:{el.ques_no}
                    </p>
                    <div className="row mt-3">
                      <div className="col-md-12 p-0">
                        {el.display_type === 0 && (
                          <RtypeOne type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 1 && (
                          <RtypeTwo type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 2 && (
                          <RtypeThree type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 3 && (
                          <RtypeFour type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 4 && (
                          <RtypeFive type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 5 && (
                          <RtypeSix type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 6 && (
                          <RtypeSeven type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 7 && (
                          <RtypeEight type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 8 && (
                          <RtypeNine type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 9 && (
                          <RtypeTen type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 10 && (
                          <>
                            {el.para_text_en !== "" && (
                              <Rtypepara
                                type={"2"}
                                classET={"question_labels_english"}
                                index={index}
                                list={el.para_text_en}
                              />
                            )}
                            {el.para_text_tm !== "" && (
                              <Rtypepara
                                type={"2"}
                                classET={"question_labels_tamil"}
                                index={index}
                                list={el.para_text_tm}
                              />
                            )}

                            <RtypeParaOption
                              type={"2"}
                              index={index}
                              list={el}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <ExplanationQuery
                      lang_type={el.lang_type}
                      margin_yes={0}
                      en_explan={el.en_explanation}
                      tm_expla={el.tm_explanation}
                    />
                    <div className="BorderQuery"></div>
                    {el.stud_query &&
                      el.stud_query.map((ojk, index) => {
                        return <QuerySection query_rise={ojk} index={index} />;
                      })}
                    <div className="BorderQuery"></div>
                    <div className="row mt-3 mb-4">
                      <div className="col-md-6 ">
                        {el.assignee_info === null ||
                        (el.assignee_info !== null && el.isEdit === true) ? (
                          <div className="forms ">
                            <label for="assign_to mt-0" id="assign_to_id">
                              Assign To
                            </label>
                            <Select
                              id="assign_to"
                              name="assign_to"
                              options={staffMem}
                              isSearchable={true}
                              isClearable={true}
                              onChange={(option) =>
                                handleAssign(
                                  option,
                                  "assign_to",
                                  el.question_pk
                                )
                              }
                              placeholder="Select an faculty"
                              value={
                                el.IsFaculty_id !== null &&
                                el.isFaculty_name !== "" && {
                                  staff_id: el.IsFaculty_id,
                                  value: el.isFaculty_name,
                                  label: el.isFaculty_name,
                                  questionPk: el.questionPk,
                                }
                              }
                              styles={customStyles}
                            />

                            {el.isFaculty_error && (
                              <p className="errors">{el.isFaculty_error}</p>
                            )}
                          </div>
                        ) : (
                          <div className="forms ">
                            <p
                              for="assign_to mt-0"
                              className="titleQuery mb-0"
                              id="assign_to_id"
                            >
                              Assign To
                            </p>
                            <span className="ReviewLabel mt-2">
                              {el.assignee_info !== null &&
                                el.assignee_info.assigned_to_name}
                            </span>
                          </div>
                        )}
                      </div>
                      {el.assignee_info !== null &&
                        (el.assignee_info.reply_by_staff !== null ||
                          el.assignee_info.reply_by_staff !== "") &&
                        el.IsfacultyUpdate === true && (
                          <>
                            <div className="col-md-6 ">
                              <div className="forms ">
                                <p
                                  for="assign_to mt-0"
                                  className="titleQuery mb-0"
                                  id="assign_to_id"
                                >
                                  Query Status
                                </p>
                                <span className="ReviewLabel mt-2">
                                  {el.assignee_info.query_status === 1
                                    ? "Valid"
                                    : "Invalid"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="forms ">
                                <p
                                  for="assign_to mt-0"
                                  className="titleQuery mt-2 mb-0"
                                  id="assign_to_id"
                                >
                                  Reply by {el.assignee_info.assigned_to_name} (
                                  {moment(el.assignee_info.reply_dt).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                  )
                                </p>
                                <span className="ReviewLabel mt-2">
                                  {el.assignee_info.reply_by_staff
                                    ? el.assignee_info.reply_by_staff
                                    : "_"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="forms ">
                                <div class="d-flex">
                                  {" "}
                                  <p className="titleQuery mb-1">Attachments</p>
                                  <a
                                    download
                                    target="_blank"
                                    className="attachmentQuery"
                                    href={`${ImageUrlPrefix}/${
                                      el.assignee_info &&
                                      el.assignee_info.query_proof_filepath
                                        ? el.assignee_info.query_proof_filepath
                                        : null
                                    }`}
                                  >
                                    <span class="material-icons iconQuery">
                                      download
                                    </span>
                                  </a>
                                  <a
                                    className="attachmentQuery"
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${
                                      el.assignee_info &&
                                      el.assignee_info.query_proof_filepath
                                        ? el.assignee_info.query_proof_filepath
                                        : null
                                    }`}
                                  >
                                    <span class="material-icons iconQuery">
                                      visibility
                                    </span>
                                  </a>
                                </div>

                                <p className="explain_ques mt-0">
                                  {" "}
                                  {el.assignee_info !== null &&
                                    el.assignee_info.original_query_filepath}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      {/* {console.log(el, "el")} */}
                      {el.assignee_info !== null &&
                        (el.assignee_info.reply_by_staff !== null ||
                          el.assignee_info.reply_by_staff !== "") &&
                        el.IsFacultyEdit === true && (
                          <>
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label for="query_status">Query Status:</label>
                                <select
                                  ref={txtQueryStatus}
                                  id="query_status"
                                  name="query_status"
                                  className="Inputs"
                                  onChange={(e) => handleChange(e, el)}
                                  required
                                >
                                  <option value="" disabled>
                                    Please select
                                  </option>
                                  <option
                                    selected={
                                      el.query_status === "1" ? true : false
                                    }
                                    value="1"
                                  >
                                    Valid
                                  </option>
                                  <option
                                    selected={
                                      el.query_status === "0" ? true : false
                                    }
                                    value="0"
                                  >
                                    Invalid
                                  </option>
                                </select>
                                {el.query_Status_error && (
                                  <small className="error">
                                    {el.query_Status_error}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="forms ">
                                <label for="query_path">
                                  Upload Proof for Query Reference:
                                  {el.query_path !== "" &&
                                    el.query_path !== null && (
                                      <a
                                        href={`${ImageUrlPrefix}/${el.query_path}`}
                                        target="_blank"
                                        download
                                      >
                                        <span className="material-icons download_icon align-middle text-danger ">
                                          file_download
                                        </span>
                                      </a>
                                    )}
                                </label>
                                <div className="row">
                                  <div
                                    className={
                                      el.query_path !== "" &&
                                      el.query_path !== null
                                        ? "col-md-3 "
                                        : "col-md-12"
                                    }
                                  >
                                    <input
                                      ref={txtQueryPath}
                                      id="query_path"
                                      type="file"
                                      name="query_path"
                                      placeholder="Upload query proof"
                                      className={
                                        el.query_path !== "" &&
                                        el.query_path !== null
                                          ? "InputsFile nn_upload_width"
                                          : "InputsFile  vv_upload_width"
                                      }
                                      onChange={(e) => handleChange(e, el)}
                                      autoComplete="query_path"
                                      required
                                    />
                                  </div>
                                  {el.query_path !== "" &&
                                    el.query_path !== null && (
                                      <div
                                        className={
                                          "col-md-9 d-flex align-content-center p-0"
                                        }
                                      >
                                        <span className="nn_upload_file">
                                          {el.original_query_path}
                                        </span>
                                        <span className="material-icons align-middle text-danger verified_upload">
                                          verified
                                        </span>
                                      </div>
                                    )}
                                </div>
                                {el.query_path_error && (
                                  <small className="error">
                                    {el.query_path_error}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="forms ">
                                <label for="reply_query">Reply by You:</label>
                                <textarea
                                  ref={txtQueryReply}
                                  id="reply_query"
                                  className="textareaInput"
                                  name="reply_query"
                                  rows="4"
                                  cols="20"
                                  onChange={(e) => handleChange(e, el)}
                                  placeholder="Reply by You"
                                  value={el.reply_query}
                                ></textarea>
                                {el.reply_status_error && (
                                  <small className="error">
                                    {el.reply_status_error}
                                  </small>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                      {el.query_stage === 0 && (
                        <div className="heading mt-4 dflexCenter ">
                          {el.assignee_info === null ||
                          (el.assignee_info !== null && el.isEdit === true) ||
                          el.IsFacultyEdit === true ? (
                            <>
                              {el.isUpdate === false && (
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() => handleCancel(el)}
                                  className="CancelButton"
                                >
                                  Cancel
                                </Link>
                              )}
                              <button
                                type="button"
                                className="QueryButton"
                                onClick={
                                  el.IsFacultyEdit === true
                                    ? () => handleUpdateApprove(el)
                                    : () => handleAssignTo(el)
                                }
                              >
                                <Link to="javascript:void(0)">
                                  {el.assignee_info !== null &&
                                  el.isEdit === false
                                    ? el.IsfacultyUpdate === true
                                      ? "Update"
                                      : "Update and Approve"
                                    : el.isEdit === true
                                    ? "Update"
                                    : "Assign Now"}
                                </Link>
                              </button>
                            </>
                          ) : (
                            <>
                              {el.assignee_info !== null &&
                              (el.assignee_info.reply_by_staff === "" ||
                                el.assignee_info.reply_by_staff === null) ? (
                                <>
                                  <button
                                    onClick={() => handleEditAssignTo(el)}
                                    type="button"
                                    className="QueryEditButton"
                                  >
                                    <Link to="javascript:void(0)">
                                      <span class="material-icons me-2">
                                        border_color
                                      </span>
                                      Edit
                                    </Link>
                                  </button>
                                  <button
                                    onClick={() => handleRemindMeAgain(el)}
                                    type="button"
                                    className="QueryRemindButton ms-4"
                                  >
                                    <Link to="javascript:void(0)">
                                      Remind Again
                                    </Link>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    onClick={() => handleEditFaculty(el)}
                                    type="button"
                                    className="QueryEditButton"
                                  >
                                    <Link to="javascript:void(0)">
                                      <span class="material-icons me-2">
                                        border_color
                                      </span>
                                      Edit
                                    </Link>
                                  </button>

                                  <button
                                    type="button"
                                    onClick={() => handleApprove(el)}
                                    className="QueryRemindButton ms-4"
                                  >
                                    <Link to="javascript:void(0)">Approve</Link>
                                  </button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            ))}
        </div>
      </div>
    </div>
  );
}
