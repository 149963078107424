import React, { useState } from "react";
import styled from "styled-components";
import { MEDIA_SIZE_LG } from "../../../../../utils/helper";

const instructionEnglish = [
  "Kindly ensure proper internet connectivity.",
  "Attending the exam using a laptop or desktop is highly preferable.",
  "Your mobile or laptop should be fully charged. Make sure of the availability of a power bank, UPS, or inverter for uninterrupted power supply.",
  "Kindly ensure that you have sufficient internet data.",
  "Kindly log in to our exam portal 10 minutes ahead of the start of the exam.",
  "Before starting the exam, please make sure that all the browsers/tabs on your gadget are closed.",
  "Once the exam has started, do not switch to any other window or tab. If you do, it shall be considered malpractice and your exam may be terminated.",
  "Kindly avoid answering any phone calls while attending the exam on mobile. If you do, it shall be treated as switching to another window.",
  "Kindly make use of an ad blocker to avoid unwanted pop-ups appearing during the exam.",
  "Kindly ensure all temporary files, browser history, and cache memory on your mobile or laptop are deleted.",
  "Kindly wait patiently until the end of your exam.",
];

const instructionTamil = [
  "சரியான இணைய இணைப்பை உறுதி செய்யவும்.",
  "லேப்டாப் அல்லது டெஸ்க்டாப் பயன்படுத்தி தேர்வில் கலந்துகொள்வது மிகவும் விரும்பத்தக்கது.",
  "உங்கள் மொபைல் அல்லது லேப்டாப் முழுமையாக சார்ஜ் செய்யப்பட்டிருக்க வேண்டும். மின்சாரம் கிடைப்பதை உறுதி செய்து கொள்ளுங்கள். தடையில்லா மின்சாரம் வழங்க வங்கி அல்லது UPS அல்லது இன்வெர்ட்டர்.",
  "உங்களிடம் போதுமான இன்டர்நெட் டேட்டா இருப்பதை உறுதி செய்து கொள்ளவும்.",
  "தேர்வு தொடங்குவதற்கு 10 நிமிடங்களுக்கு முன்னதாக எங்கள் தேர்வு போர்ட்டலில் உள்நுழையவும்.",
  "தேர்வைத் தொடங்கும் முன், உங்களின் அனைத்து உலாவிகள்/தாவல்கள் மூடப்பட்டுள்ளனவா என்பதை உறுதிப்படுத்திக் கொள்ளவும்.",
  "தேர்வு தொடங்கியதும், வேறு எந்த சாளரத்திற்கும் அல்லது தாவலுக்கும் மாற வேண்டாம். அவ்வாறு செய்தால், அது முறைகேடாகக் கருதப்படும் மற்றும் உங்கள் தேர்வு நிறுத்தப்படலாம்.",
  "தேர்வில் கலந்துகொள்ளும் போது அலைபேசியில் எந்த தொலைபேசி அழைப்புகளுக்கும் பதிலளிப்பதைத் தவிர்க்கவும். அப்படிஎன்றால், நீங்கள் மற்ற சாளரத்திற்கு மாறும்போது அது கருதப்படும்.",
  "தேவையற்ற பாப்-அப்்கள் தோன்றுவதைத் தவிர்க்க, சேர் பிளாக்கரைப் பயன்படுத்தவும்.",
  "உங்களின் அனைத்து தற்காலிக கோப்புகள், உலாவி வரலாறு மற்றும் கேச் நினைவகம் ஆகியவற்றை தயவுசெய்து நீக்கவும்.",
  "உங்கள் தேர்வு முடியும் வரை பொறுமையாக காத்திருங்கள்.",
];

let temp1 = [
  "Arrange for stable Internet connectivity.",
  "Giving the examination on a laptop or desktop is highly recommended.",
  "Make sure your mobile or laptop is fully charged. Arrange for a power bank for your mobile or a UPS/inverter for your laptop/desktop to ensure uninterrupted power supply.",
  "Students should have sufficient data in their Fair Usage Policy (FUP) / Internet plan with a sufficient data pack from their internet service provider.",
  "Log in to the portal 10 minutes before the online examination start time.",
  "Close all browsers and tabs before starting the online examination.",
  "Once the exam starts, do not switch to any other window or tab. Doing so may be considered malpractice, and your exam may be terminated.",
  "Do not pick up or receive calls during the exam if you are using a mobile device. This will also be treated as switching windows.",
  "To avoid unwanted pop-ups, using an ad blocker is recommended.",
  "Clear the browser cache memory on your mobile and laptop. Also, clear browsing history and delete temporary files.",
];
let temp2 = [
  "நிலையான இணைய இணைப்புக்கு ஏற்பாடு செய்யுங்கள்.",
  "லேப்டாப்போ அல்லது டெஸ்க்டாப்போ பயன்படுத்தி தேர்வு எழுதுவது மிகவும் பரிந்துரைக்கப்படுகிறது.",
  "மொபைல் அல்லது லேப்டாப் முழுவதுமாக சார்ஜ் செய்யப்பட்டிருப்பதை உறுதிசெய்யுங்கள். தடையில்லா மின்சாரம் வழங்க மொபைலுக்கான பவர் பேங்க் அல்லது லேப்டாப்/டெஸ்க்டாப்பிற்கான யுபிஎஸ்/இன்வெர்ட்டர் ஏற்பாடு செய்யுங்கள்.",
  "மாணவர்களுக்கு நியாயமான பயன்பாட்டுக் கொள்கை (FUP) / இணையத் திட்டத்தில் போதுமான தரவுப் பொதியுடன் தேவையான தரவுகள் இருக்க வேண்டும்.",
  "ஆன்லைன் தேர்வு தொடங்கும் நேரத்திற்கு 10 நிமிடங்களுக்கு முன்பு போர்ட்டலில் உள்நுழையுங்கள்.",
  "ஆன்லைன் தேர்வைத் தொடங்கும் முன் அனைத்து உலாவிகள் மற்றும் தாவல்களை மூடுங்கள்.",
  "தேர்வு தொடங்கியதும், வேறு எந்த விண்டோவோ அல்லது தாவலோ மாற வேண்டாம். இது தவறான செயலாகக் கருதப்படும் மற்றும் உங்கள் தேர்வு நிறுத்தப்படலாம்.",
  "மொபைலில் தேர்வு எழுதும்போது, அழைப்புகளை எடுக்கவோ அல்லது பெறவோ வேண்டாம். இது சாளரத்தை மாற்றுவதாகக் கருதப்படும்.",
  "தேவையற்ற பாப்-அப்புகளைத் தவிர்க்க, விளம்பரத் தடுப்பான் பயன்படுத்த பரிந்துரைக்கப்படுகிறது.",
  "மொபைல் மற்றும் லேப்டாப் ஆகியவற்றில் உலாவி கேச் நினைவகத்தை, உலாவல் வரலாற்றை மற்றும் தற்காலிக கோப்புகளை அழிக்கவும்.",
  "தேர்வு தொடங்கியதும், வேறு எந்த சாளரத்திற்கும் அல்லது தாவலுக்கும் மாற வேண்டாம். இது முறைகேடாகக் கருதப்படும் மற்றும் உங்கள் தேர்வு நிறுத்தப்படலாம்.",
];

const OPTION_VALUE_ENGLISH = 1;
const OPTION_VALUE_TAMIL = 2;

const StyledList = styled.ul`
  font-size: 14px;
  padding-right: 8px;
`;
const StyledHeader = styled.h6`
  text-transform: uppercase;
  text-decoration: underline;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  text-align: center;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 20px;
  }
`;
const StyledSelect = styled.select`
  && {
    /* background: none !important;
    appearance: inherit; */
    font-size: 12px;
    padding: 5px 100px 5px 5px;
    color: var(--color-grey-500);
    max-width: 250px;
    border-radius: var(--border-radius-tiny);
    &::marker {
      color: var(--color-grey-500);
    }
    &:focus,
    :focus-visible {
      outline: none;
      border-color: var(--color-grey-300);
    }
  }
`;

const StyledOption = styled.option`
  && {
    padding: 5px;
    color: var(--color-grey-300);
    &:hover {
      background-color: var(--color-grey-200);
    }
    &:active {
      background-color: var(--color-grey-500);
    }
  }
`;

const options = [
  { label: "View in English", value: OPTION_VALUE_ENGLISH },
  { label: "View in Tamil", value: OPTION_VALUE_TAMIL },
];

export default function InstructionList({ list }) {
  const [selectedlanguage, setSelectedlanguage] =
    useState(OPTION_VALUE_ENGLISH);

  const StyledListItem = styled.li`
    text-align: justify;
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--color-text-primary);
    font-weight: var(--font-weight-normal);
    font-family: ${selectedlanguage === OPTION_VALUE_ENGLISH
      ? "inherit"
      : "Noto-Sans-Tamil-Regular"};
    &::marker {
      color: var(--color-brand-900);
      width: 12px;
      height: 12px;
    }
    @media (min-width: ${MEDIA_SIZE_LG}) {
      font-size: 18px;
    }
  `;
  return (
    <>
      <StyledHeader>Instructions to Candidates</StyledHeader>
      <div className="d-flex justify-content-end">
        <StyledSelect
          className="ml-auto"
          onChange={(e) => setSelectedlanguage(Number(e.target.value))}
          value={selectedlanguage}
        >
          {options.map(({ label, value }) => (
            <StyledOption value={value}>{label}</StyledOption>
          ))}
        </StyledSelect>
      </div>

      <StyledList>
        {selectedlanguage === OPTION_VALUE_ENGLISH
          ? instructionEnglish.map((inst, index) => (
              <StyledListItem key={index}>{inst}</StyledListItem>
            ))
          : instructionTamil.map((inst, index) => (
              <StyledListItem key={index}>{inst}</StyledListItem>
            ))}
      </StyledList>
    </>
  );
}
