import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../CourseBatch/CreateCourseBatch.css";
import moment from "moment";
import { current } from "@reduxjs/toolkit";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { Chip, ListItem, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Loader from "../../Loader";
function ModelExamAnalytics() {
  let page = R_PER_PAGE;
  const [sortOrder, setSortOrder] = useState("asc");
  const [listData, setListData] = useState([]); //Original Data
  const [updateListData, setUpdateListData] = useState(listData); //Duplicate data
  // const [currentData, setCurrentData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [updateChipData, setUpdateChipData] = useState([]);
  const [isOpen, setIsOpen] = useState({
    status: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState([]);
  const navigate = useNavigate();
  let filterStatus = [];
  useEffect(() => {
    setAnalyticsdata((prevState) => ({
      ...prevState,
      basicInfo: {
        batchImg: location?.state.batchImg,
        category: location?.state.category,
        course: location?.state.course,
        paperType: location?.state.paperType,
        examName: location?.state.examName,
        examMedium: location?.state.examMedium,
        examMode: location?.state.examMode,
        examDistricts: location?.state.examDistricts,
        numberofExams: location?.state.numberofExams,
        batchStatus: location?.state.batchStatus,
        numberOfEnrolledStudents: location?.state.numberOfEnrolledStudents,
        hallticketStatus: location?.state.hallticketStatus,
      },
    }));
  }, []);

  useEffect(() => {
    filterStatus = status;
  }, [status]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  // -----------------Swastic------
  const handleSort = (event, sortKey) => {
    event.preventDefault();
    // console.log("sort", event, sortKey);

    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    setSortColumn(sortKey);

    let sortedList = [...updateListData].sort((a, b) => {
      let aValue = a;
      let bValue = b;

      // Split the sortKey to handle nested properties
      sortKey.split(".").forEach((key) => {
        aValue = aValue ? aValue[key] : null;
        bValue = bValue ? bValue[key] : null;
      });
      // console.log("a", aValue);
      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });

    setUpdateListData(sortedList);
  };
  // ---------Filter
  function updateStateData() {
    filterStatus = status;
  }

  const handleFilter = (e, meanly) => {
    if (meanly === "status") {
      setIsOpen((prevState) => ({
        ...prevState,
        status: !prevState.status,
      }));
    }
  };
  const handleClear = (name) => {
    if (name === "status") {
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
    }
    loadChipdata();
  };

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    }
  };
  function loadChipdata() {
    // console.log(filterStatus, "filterStatusfilterStatus");
    let tempArr = [];
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });
    setUpdateChipData(tempArr);
  }

  function loadListdata() {
    let tempStatus = [];
    let tempstatusnum = [];
    status.map((obj) => {
      tempStatus.push(obj.id);
    });
    tempstatusnum = tempStatus.map(Number);

    const statusFilter = {
      batch_id: parseInt(BATCH_ID),
      status: status.length > 0 ? tempstatusnum : "",
      page_no: "",
      page_size: "",
    };
    setIsLoading(true);
    AxiosInstance.post("api/modelexam/analytics", statusFilter).then((res) => {
      if (res.data.status === true) {
        setIsLoading(false);
        const modelAnalytic = res.data.data;
        const serialNumberAddedData = modelAnalytic
          .sort((a, b) => {
            const dateA = moment(a.started_dt, "DD-MM-YYYY").toDate();
            const dateB = moment(b.started_dt, "DD-MM-YYYY").toDate();

            // If the dates are different, sort by date
            if (dateA - dateB !== 0) {
              return dateA - dateB;
            }

            // If the dates are the same, sort by time
            const timeA = moment(a.time, "hh:mm A").toDate();
            const timeB = moment(b.time, "hh:mm A").toDate();

            return timeA - timeB;
          })
          .map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
        setUpdateListData(serialNumberAddedData);
      } else {
        setIsLoading(false);
        setUpdateListData([]);
      }
    });
  }

  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    }
    loadChipdata();
  };
  function handleViewAnalytics(analytics, type) {
    // return console.log("laksh", analytics);
    const examData = {
      batch_id: parseInt(BATCH_ID),
      exam_id: analytics.exam_detail_id,
      paper_type: parseInt(location.state.paperType),
      type_handle: type,
    };
    if (parseInt(location.state.paperType) === 1) {
      examData.event_pk = analytics.event_pk;
    }
    return navigate("/viewmodelexamanalytics", { state: examData });
  }

  // const handleViewAnalytics_noRecord = () => {
  //   navigate(`/viewmodelexamanalytics/${parseInt(BATCH_ID)}`);
  // };

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    if (name === "Upcoming" || name === "Ongoing" || name === "Completed") {
      if (checked) {
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    }
  };

  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  //chips overall clear button
  const handleAllClear = () => {
    setStatus([]);
    loadChipdata();
    // setUpdateListData(listData);
  };

  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);
  // ---------------------Laks------------------
  const location = useLocation();
  // console.log("laksh-location", location);
  const [analyticsdata, setAnalyticsdata] = useState({
    basicInfo: {
      batchImg: null,
      category: "",
      course: "",
      paperType: "",
      examName: "",
      examMedium: "",
      examMode: "",
      examDistricts: [],
      numberofExams: "",
      batchStatus: "",
      numberOfEnrolledStudents: "",
      hallticketStatus: "",
    },
  });
  const BATCH_ID = location.state.batchID;

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading  dflexbetww ">
              <div className="mt-2 dbetw">
                <h6 className="Title ">Model Exam Analytics</h6>
                <Link
                  to={`/DetailViewBatch/${BATCH_ID}/${4}`}
                  className="CancelButtons MobileShow "
                >
                  Back to View Model Exam Batch
                </Link>
              </div>
              <div className="d-flex smButton mobiledown  mt-4">
                <Link
                  to={`/DetailViewBatch/${BATCH_ID}/${4}`}
                  className="CancelButtons MobileHide mt-3"
                >
                  Back to View Model Exam Batch
                </Link>
                {/* <button type="button" className="ListViewBtn  me-3">
                  <a href="/createCourseBatch/4/82">
                    <span className="material-icons me-1 fs-5 align-middle text-white">
                      assignment
                    </span>
                  </a>
                  <a href="/DetailViewBatch/82/4">Generate Report</a>
                </button> */}
              </div>
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Basic Info</p>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Category
                          </label>
                          <span className="ReviewLabel">
                            {/* {["lak", "sri", "laksh"].join(', ')} */}
                            {analyticsdata.basicInfo.category || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Course
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {analyticsdata.basicInfo.course || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Single Papers / Combined Papers?
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {/* Combined Papers */}
                            {analyticsdata.basicInfo.paperType === "0"
                              ? "Single Papers"
                              : "Combined Papers" || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Name
                          </label>
                          <span className="ReviewLabel">
                            {analyticsdata.basicInfo.examName || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Medium of Exam
                          </label>
                          <span className="ReviewLabel">
                            {analyticsdata.basicInfo.examMedium || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Mode of Exam
                          </label>
                          <span className="ReviewLabel">
                            {analyticsdata.basicInfo.examMode === "ClassRoom"
                              ? "Offline"
                              : "Online" || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Exam Districts
                          </label>
                          <span className="ReviewLabel">
                            {analyticsdata.basicInfo.examDistricts.length > 0
                              ? analyticsdata.basicInfo.examDistricts
                                  .map((el) => el.label)
                                  .join(", ")
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Number of Model Exams
                          </label>
                          <span className="ReviewLabel">
                            {analyticsdata.basicInfo.numberofExams
                              ? analyticsdata.basicInfo.numberofExams
                              : 0}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 mb-3">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Batch Status
                          </label>
                          <td id="td">
                            <span className=" ReviewLabel ">
                              {analyticsdata.basicInfo.batchStatus || "-"}
                              {/* {analyticsdata.basicInfo.batchStatus? (
                          
                            currentDate.isBetween(
                              formFields.StartDate,
                              formFields.endDate,
                              'day',
                              '[]' // Include the end date
                            ) && currentDate.isAfter(tenAM) ? (
                            
                                "OnGoing"
                            
                            ) : currentDate.isBefore(propformFields.StartDate, 'day') ||
                              currentDate.isSame(formFields.StartDate, 'day') && currentDate.isBefore(tenAM) ? (
                             
                                "Upcoming"
                            
                            ) : (
                            
                                "Completed"
                              
                            )
                          ) : (
                            <ReviewstatusCheck
                              startDate={formFields.StartDate}
                              duration={formFields.Duration}
                            />
                          )} */}
                            </span>
                          </td>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Number of Enrolled Students
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {analyticsdata.basicInfo.numberOfEnrolledStudents ||
                              "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 mb-3 col-md-6">
                        <div className="forms ">
                          <label
                            for="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Hall Ticket Status
                          </label>
                          <span className="ReviewLabel">
                            {analyticsdata.basicInfo.hallticketStatus || "-"}
                            {/* {`Not Yet Generated (0/${
                              analyticsdata.basicInfo.hallticketStatus
                                ? analyticsdata.basicInfo.hallticketStatus
                                : 0
                            })`} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-5">
                    <div className="forms mt-4 mb-2  ">
                      <div id="batchImage">
                        {/* <img src="https://aiasadev.annaiasacademy.com/api/uploads/batch_image/batch_image1714402677823.jpg" /> */}
                        {analyticsdata.basicInfo.batchImg && (
                          <img
                            src={`${ImageUrlPrefix}/${analyticsdata.basicInfo.batchImg}`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row box">
              <div class="col-md-12">
                <p class="StepTitle">Exam Details</p>

                {updateChipData.length > 0 ? (
                  <div className="row mt-3">
                    <div
                      className="col-md-12 col-sm-12 col-xs-12 "
                      id="paperil"
                    >
                      <Paper
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul"
                      >
                        {updateChipData.map((data, index) => {
                          // console.log(data, " - data - ", index);
                          let icon;
                          let key = "" + data.name;
                          return (
                            <ListItem key={data}>
                              <Chip
                                icon={icon}
                                label={key}
                                onDelete={handleDelete(data, index)}
                              />
                            </ListItem>
                          );
                        })}

                        <ListItem>
                          <a
                            className="allclear"
                            href="javascript:void(0)"
                            onClick={handleAllClear}
                          >
                            Clear All Filters
                          </a>
                        </ListItem>
                      </Paper>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="row mt-3 ms-2 me-1">
                  <div className="col-md-12 table-responsive p-0">
                    <table
                      className="table"
                      id="example"
                      // style={{ borderBottom: "5px solid white" }}
                    >
                      <thead>
                        <tr>
                          <th id="th" style={{ width: "5%" }}>
                            <span className="text_th">S.No</span>
                            <span
                              class="material-icons align-middle"
                              onClick={(e) => handleSort(e, "serialNumber")}
                            >
                              {" "}
                              import_export{" "}
                            </span>
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "exam_title")}
                            >
                              Exam / Paper Title
                            </span>
                            <span
                              className="material-icons align-middle"
                              onClick={(e) => handleSort(e, "exam_title")}
                            >
                              import_export
                            </span>
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "started_dt")}
                            >
                              Exam Date
                            </span>
                            <span
                              className="material-icons align-middle"
                              onClick={(e) => handleSort(e, "started_dt")}
                            >
                              import_export
                            </span>
                          </th>
                          <th id="th" style={{ width: "25%" }}>
                            <span
                              className="text_th "
                              onClick={(e) => handleSort(e, "time")}
                            >
                              Time
                            </span>
                            <span
                              className="material-icons align-middle"
                              onClick={(e) => handleSort(e, "time")}
                            >
                              import_export
                            </span>
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "stu_count")}
                            >
                              No. of Students Enrolled
                            </span>
                            <span
                              className="material-icons align-middle"
                              onClick={(e) => handleSort(e, "stu_count")}
                            >
                              import_export
                            </span>
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) =>
                                handleSort(e, "attendance.present")
                              }
                            >
                              No. of Students Present
                            </span>
                            <span
                              className="material-icons align-middle"
                              onClick={(e) =>
                                handleSort(e, "attendance.present")
                              }
                            >
                              import_export
                            </span>
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) =>
                                handleSort(e, "attendance.absent")
                              }
                            >
                              No. of Students Absent
                            </span>
                            <span
                              className="material-icons align-middle"
                              onClick={(e) =>
                                handleSort(e, "attendance.absent")
                              }
                            >
                              import_export
                            </span>
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "batch_status")}
                            >
                              Exam Status
                            </span>
                            <span
                              className="material-icons align-middle"
                              onClick={(e) => handleSort(e, "batch_status")}
                            >
                              import_export
                            </span>
                            <span
                              class={` align-middle material-icons  ${
                                status.length > 0 ? "active_icons" : ""
                              }`}
                              onClick={(e) => handleFilter(e, "status")}
                            >
                              filter_list
                            </span>
                            {isOpen.status === true && (
                              <div className="filterBoxssss p-1">
                                <div className="row mt-4">
                                  <div className="col-md-12">
                                    <div class="form-check ms-3">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="Upcoming"
                                        value="0"
                                        id="Upcoming"
                                        checked={status.some((element) => {
                                          if (element.id === "0") {
                                            return true;
                                          }
                                          return false;
                                        })}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, "Upcoming")
                                        }
                                      />
                                      <label
                                        class="form-check-label PinkText"
                                        for="defaultCheck1"
                                      >
                                        Upcoming
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-md-12">
                                    <div class="form-check ms-3">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="Ongoing"
                                        value="1"
                                        id="Ongoing"
                                        checked={status.some((element) => {
                                          if (element.id === "1") {
                                            return true;
                                          }
                                          return false;
                                        })}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, "Ongoing")
                                        }
                                      />
                                      <label
                                        class="form-check-label GreenText"
                                        for="defaultCheck1"
                                      >
                                        Ongoing
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-md-12">
                                    <div class="form-check ms-3">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="Completed"
                                        value="2"
                                        id="Completed"
                                        checked={status.some((element) => {
                                          if (element.id === "2") {
                                            return true;
                                          }
                                          return false;
                                        })}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, "Completed")
                                        }
                                      />
                                      <label
                                        class="form-check-label GreyText"
                                        for="defaultCheck1"
                                      >
                                        Completed
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div className="row mt-3">
                                  <div className="col-md-4  col-4 col-sm-4">
                                    <span
                                      className="clearFilter ms-2 "
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleClear("status")}
                                    >
                                      Clear
                                    </span>
                                  </div>
                                  <div className="col-md-8  col-8 col-sm-8">
                                    <button
                                      className="FilterButton"
                                      onClick={() =>
                                        handleApplyFilter("status")
                                      }
                                    >
                                      Apply Filter
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </th>
                          <th id="th">
                            <span className="text_th">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData?.length === 0 ? (
                          <tr>
                            <td colspan="10" style={{ textAlign: "center" }}>
                              No record to display
                            </td>
                          </tr>
                        ) : (
                          currentData.map((analytics, index) => (
                            <tr key={index} title={analytics.papertitle}>
                              <td id="td">{analytics.serialNumber}</td>
                              <td id="td">{analytics.exam_title}</td>
                              <td id="td">
                                {analytics.started_dt
                                  ? moment(
                                      analytics.started_dt,
                                      "DD-MM-YYYY"
                                    ).format("DD/MM/YYYY")
                                  : "_"}
                              </td>
                              <td id="td">{analytics.time}</td>
                              <td id="td">{analytics.stu_count}</td>
                              <td id="td">{analytics.attendance.present}</td>
                              <td id="td">{analytics.attendance.absent}</td>

                              {analytics.batch_status === 0 && (
                                <td id="id">
                                  <span className="PinkText">Upcoming</span>
                                </td>
                              )}
                              {analytics.batch_status === null && (
                                <td id="id">
                                  <span>-</span>
                                </td>
                              )}
                              {analytics.batch_status === 1 && (
                                <td id="id">
                                  <span className="GreenText">Ongoing</span>
                                </td>
                              )}
                              {analytics.batch_status === 2 && (
                                <td id="id">
                                  <span className="GreyText">Completed</span>
                                </td>
                              )}
                              <td id="td" style={{ width: "13%" }}>
                                <button
                                  className="button"
                                  onClick={() => {
                                    if (
                                      analytics.attendance.absent !== 0 ||
                                      analytics.attendance.present !== 0
                                    ) {
                                      handleViewAnalytics(analytics, 0);
                                    } else if (
                                      analytics.attendance.absent === 0 &&
                                      analytics.attendance.present === 0 &&
                                      analytics.batch_status === 2
                                    ) {
                                      handleViewAnalytics(analytics, 1);
                                    }
                                  }}
                                >
                                  {analytics.attendance.absent === 0 &&
                                  analytics.attendance.present === 0 &&
                                  analytics.batch_status !== 2 ? (
                                    <span className="material-icons greyIconEyes">
                                      visibility_off
                                    </span>
                                  ) : (
                                    <span className="material-icons">
                                      visibility
                                    </span>
                                  )}
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                        {/* <tr>
                          <td colSpan={10} style={{ textAlign: "center" }}>
                            No record to display
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-2 ms-1 me-1 mb-3">
                  <div className="col-md-12 p-0">
                    <div className="pag pagssss w-100 dflexcenter">
                      <span className="ipage">Items Per Page</span>
                      <select
                        name="pageCount"
                        id="pageCount"
                        defaultValue={rowsPerPage}
                        onChange={handlePageCount}
                        className="ms-2"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      <span className="pagenumber ms-2">
                        {" "}
                        {updateListData.length > 0
                          ? currentPage * rowsPerPage + 1 - rowsPerPage
                          : 0}{" "}
                        -
                        {updateListData.length < currentPage * rowsPerPage
                          ? updateListData.length
                          : currentPage * rowsPerPage}{" "}
                        of {updateListData.length}
                      </span>
                      <button
                        className="prev_button ms-2 me-2"
                        onClick={(e) => handlePrev(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <span class="material-icons">chevron_left</span>
                      </button>
                      <button
                        className="prev_button"
                        onClick={() => handleNext(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <span class="material-icons">chevron_right</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ModelExamAnalytics;
