import React from "react";
import styles from "./NavigationButton.module.css";
import ICON_RIGHT from "@mui/icons-material/ArrowRightAlt";

export default function NavigationButton({
  children,
  style = {},
  lgMinWidth = "220px",
  onClick,
  disabled,
  className = "",
}) {
  return (
    <button
      className={`${styles.button} ${className}`}
      style={{ ...style }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      <span className={styles.icon}>
        <ICON_RIGHT />
      </span>
    </button>
  );
}
