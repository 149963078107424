import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
import Loader from "../../Loader";
function NteachingListView(props) {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const txtBSearch = useRef(null); //search icon
  // List data load table

  // use state
  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [csvListData, setCsvListData] = useState([]);
  const [isOpen, setIsOpen] = useState({
    status: false,
    location: false,
    category: false,
    designation: false,
  });

  let NonTeachingFaculity = 1;

  const [updateChipData, setUpdateChipData] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [status, setStatus] = useState([]);

  let filterStatus = [];
  let filterSearch = [];
  let filterType = [];

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    filterStatus = status;
  }, [status]);
  useEffect(() => {
    filterType = typeOn;
  }, [typeOn]);
  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    if (props.activeTab === 2) {
      loadChipdata();
    }
  }, []);
  useEffect(() => {
    if (props.activeTab === 2) {
      loadListdata();
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (props.activeTab === 2) {
      loadListdata();
    }
  }, [updateChipData]);

  // useEffect(() => {
  //   console.log(" updateListData : ", updateListData);
  //   currentData = updateListData.slice(start, end);
  //   // total table data  with pages
  //   totalPages = Math.ceil(updateListData.length / rowsPerPage);
  // }, [updateListData]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    if (
      name === "Active" ||
      name === "InActive" ||
      name === "WaitingForApproval" ||
      name === "InviteSent"
    ) {
      if (checked) {
        // setStatus([...status, value]);
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (typename === "type") {
      if (checked) {
        setTypeOn([...typeOn, { name: value, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.name !== value));
      }
    }
  };

  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  // -----search icon function
  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        code: "Enter",
        keyCode: 13,
        which: 13,
        bubbles: true,
      });
      txtBSearch.current.dispatchEvent(event);
    }
  };
  //end
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setTypeOn([]);

    setUpdateChipData([]);
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        designation: false,
      });
    }
  };

  function loadListdata() {
    let tempstatus = [];
    let temptypeon = [];

    status.map((obj) => {
      if (obj.id === "2") {
        tempstatus.push("3", obj.id);
      } else {
        tempstatus.push(obj.id);
      }
    });
    typeOn.map((obj) => {
      temptypeon.push(obj.name);
    });

    let statusFilter = {
      category: "",
      subject: "",
      designation: typeOn.length > 0 ? temptypeon : "",
      status: status.length > 0 ? tempstatus : "",
      staff_type: "1",
      search: searchTerm.length > 0 ? searchTerm : "",
    };
    setIsLoading(true);
    AxiosInstance.post("api/staff/list", statusFilter)
      .then((res) => {
        // console.log("editadasd ", res);
        if (res.data.status === true) {
          setIsLoading(false);
          const updatedData = res.data.data.map((item) => {
            if (item.staff_type === 1) {
              // set staff type to 1 here if you want to update it
              return item;
            } else {
              return null;
            }
          });
          // filter out null items
          const filteredData = updatedData.filter((item) => item !== null);
          const serialNumberAddedData = filteredData.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          // setUpdateListData(fiata);
          setUpdateListData(serialNumberAddedData);
          // set the state with the filtered data
          // setUpdateListData(serialNumberAddedData);
          const csvData = filteredData.map((el) => {
            return {
              fullname: el.fullname,
              faculty_id: el.faculty_id,
              mobile_no: el.mobile_no,
              email: el.email,
              designation: el.designation,
              location: el.location,
            };
          });
          setCsvListData(csvData);
        } else if (res.data.status === false) {
          setUpdateListData([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setUpdateListData([]);
        return error;
      });
  }

  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });

    filterType.map((obj) => {
      tempArr.push(obj);
    });

    setUpdateChipData(tempArr);
  }

  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        type: false,
      });
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.name !== chipToDelete.name));
      filterType = typeOn.filter((item) => item.name !== chipToDelete.name);
    } else {
      // filterApplyed = [];
    }
    loadChipdata();
  };

  const handleFilter = (e, boxName) => {
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        location: false,
        category: false,
        designation: false,
        status: !prevState.status,
      }));
    } else if (boxName === "designation") {
      setIsOpen((prevState) => ({
        location: false,
        category: false,
        status: false,
        designation: !prevState.designation,
      }));
    }
  };

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Landscape orientation
      unit: "in", // Use inches as the unit of measurement
      format: [9.5, 11], // Set the dimensions to a standard letter size (9.5 x 11 inches)
    });

    doc.setFont("arabic"); // Set the font to 'arabic'
    doc.setFontSize(12); // Adjust the desired font size
    // Add a title to the PDF document
    doc.text("Course batch data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    const headers = [
      "fullname",
      "faculty_id",
      "mobile_no",
      "email",
      "designation",
      "location",
    ]; // Assuming updateListData is your state variable

    tableData.push(headers);

    updateListData.forEach((item) => {
      const rowData = [];
      headers.forEach((header) => {
        rowData.push(item[header]);
      });
      tableData.push(rowData);
    });

    // Define the column widths for the header columns (adjust as needed)
    // Calculate column widths based on the content of header and tbody cells
    const headerColumnStyles = {};
    const tbodyColumnStyles = {};

    headers.forEach((header, index) => {
      const maxHeaderWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 1 + 6; // Adjust the multiplier as needed
          })
      );
      const maxTbodyWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 2 + 6; // Adjust the multiplier as needed
          }) // Adjust the multiplier as needed
      );

      headerColumnStyles[index] = { columnWidth: maxHeaderWidth };
      tbodyColumnStyles[index] = { columnWidth: maxTbodyWidth };
    });

    // Add data rows to tableData array
    const tableOptions = {
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
      // columnStyles: { ...headerColumnStyles, ...tbodyColumnStyles }, // Specify dynamic column widths
    };

    // Add the table to the PDF document
    doc.autoTable(tableOptions);

    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    if (props.activeTab === 2) {
      loadListdata();
    }
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);
  const handleReviewPage = (userId) => {
    navigate(`/ReviewFacultyProfile/${userId}`);
  };
  const handleReadView = (userId) => {
    navigate(`/readfaculty/${userId}`);
  };

  const handleDetailView = (userId, staff_id) => {
    navigate(`/Faculties/NonTeachingDetailView/${userId}/${staff_id}`);
  };
  // const handleEditView = (userId) => {
  //   navigate(`/Faculties/addfaculty/${userId}`);
  // };
  // className="row tab-content mt-4"
  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div
      className={
        props.activeTab === 2
          ? "row tab-content mt-4"
          : "row tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      <div
        className={
          props.activeTab === 2
            ? "tab-pane fade show active col-md-12"
            : "tab-pane fade col-md-12 "
        }
      >
        <div className="row ">
          <div className="col-lg-3 col-md-6">
            <div class="input-group">
              <input
                ref={txtBSearch}
                type="text"
                class="Inputs"
                id="SearchInput"
                name="SearchInput"
                placeholder="Search by Name and Phone number"
                // onChange={handleSearch}
                onKeyDown={handleSearch}
              />
              <span
                class="input-group-text"
                id="SearchInput"
                onClick={handleSearchFocus}
              >
                <span className="material-icons" onClick={handleSearchFocus}>
                  search
                </span>
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-md-2"></div>
          <div className="col-lg-5  dflex col-md-12 justify-content-md-between">
            <button type="button" className="ListViewBtn me-3">
              <span className="material-icons me-1 align-middle text-white">
                file_download
              </span>
              <CSVLink data={csvListData} filename={"NonTeaching.csv"}>
                Download as CSV
              </CSVLink>
            </button>
            <button
              type="button"
              className="ListViewBtn me-3"
              onClick={generatePDF}
            >
              <span className="material-icons me-1 align-middle text-white">
                file_download
              </span>
              Download as PDF
            </button>
            <button type="button" className="ListViewBtn">
              <Link to="/Faculties/addfaculty/1">
                <span className="material-icons me-1 align-middle text-white">
                  add_circle_outline
                </span>
              </Link>
              <Link to="/Faculties/addfaculty/1"> Add New Staff</Link>
            </button>
          </div>
        </div>
        {updateChipData.length > 0 ? (
          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {updateChipData.map((data, index) => {
                  // console.log(data, " - data - ", index);
                  let icon;
                  let key = "" + data.name;
                  return (
                    <ListItem key={data}>
                      <Chip
                        icon={icon}
                        label={key}
                        onDelete={handleDelete(data, index)}
                      />
                    </ListItem>
                  );
                })}

                <ListItem>
                  {/* <Chip
                      label=" Clear All Filters"
                      onClick={handleAllClear}
                    /> */}
                  <a className="allclear" href="#" onClick={handleAllClear}>
                    Clear All Filters
                  </a>
                </ListItem>
              </Paper>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12 table-responsive p-0">
            <table className="table " id="example">
              <thead>
                <tr>
                  <th id="th" style={{ width: "5%" }}>
                    {" "}
                    <span className="text_th">S.No</span>{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "serialNumber")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "fullname")}
                    >
                      Faculty Name
                    </span>{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "fullname")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "faculty_id")}
                    >
                      {" "}
                      Faculty ID
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "faculty_id")}
                    >
                      {" "}
                      import_export{" "}
                    </span>{" "}
                  </th>

                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "mobile_no")}
                    >
                      Phone Number
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "mobile_no")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "email")}
                    >
                      Email ID
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "email")}
                    >
                      import_export
                    </span>{" "}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "designation")}
                    >
                      Designation
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "designation")}
                    >
                      import_export
                    </span>{" "}
                    <span
                      class={` align-middle material-icons  ${
                        typeOn.length > 0 ? "active_icons" : ""
                      }`}
                      onClick={(e) => handleFilter(e, "designation")}
                    >
                      filter_list
                    </span>
                    {isOpen.designation === true && (
                      <div className="filterBoxs p-1">
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Accountant"
                                value="Accountant"
                                id="Accountant"
                                checked={typeOn.some((element) => {
                                  if (element.name === "Accountant") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Accountant", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                Accountant
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Superindent"
                                value="Superindent"
                                id="Superindent"
                                checked={typeOn.some((element) => {
                                  if (element.name === "Superindent") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Superindent", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                Superindent
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Assistant"
                                value="Assistant"
                                id="Assistant"
                                checked={typeOn.some((element) => {
                                  if (element.name === "Assistant") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Assistant", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                Assistant
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Manager"
                                value="Manager"
                                id="Manager"
                                checked={typeOn.some((element) => {
                                  if (element.name === "Manager") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Manager", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                Manager
                              </label>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("type")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("type")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "location")}
                    >
                      Location
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "location")}
                    >
                      import_export
                    </span>{" "}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "status")}
                    >
                      Status
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "status")}
                    >
                      import_export
                    </span>
                    <span
                      class={` align-middle material-icons  ${
                        status.length > 0 ? "active_icons" : ""
                      }`}
                      onClick={(e) => handleFilter(e, "status")}
                    >
                      filter_list
                    </span>
                    {isOpen.status === true && (
                      <div className="filterBoxs p-1">
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="InviteSent"
                                value="0"
                                id="InviteSent"
                                checked={status.some((element) => {
                                  if (element.id === "0") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "InviteSent")
                                }
                              />
                              <label
                                class="form-check-label YellowText"
                                for="defaultCheck1"
                              >
                                Invite Sent
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="CancelInvite"
                                value="5"
                                id="CancelInvite"
                                checked={status.some((element) => {
                                  if (element.id === "5") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "CancelInvite")
                                }
                              />
                              <label
                                class="form-check-label YellowText"
                                for="defaultCheck1"
                              >
                                Cancel Invite
                              </label>
                            </div>
                          </div>
                        </div> */}
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="WaitingForApproval"
                                value="1"
                                id="WaitingForApproval"
                                checked={status.some((element) => {
                                  if (element.id === "1") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "WaitingForApproval")
                                }
                              />
                              <label
                                class="form-check-label GreenText"
                                for="defaultCheck1"
                              >
                                Waiting For Approval
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="InActive"
                                value="2"
                                id="InActive"
                                checked={status.some((element) => {
                                  if (element.id === "2") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "InActive")
                                }
                              />
                              <label
                                class="form-check-label PinkText"
                                for="defaultCheck1"
                              >
                                InActive
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Active"
                                value="4"
                                id="Active"
                                checked={status.some((element) => {
                                  if (element.id === "4") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Active")
                                }
                              />
                              <label
                                class="form-check-label GreenText"
                                for="defaultCheck1"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("status")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("status")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    {" "}
                    <span className="text_th">Action</span>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentData.length === 0 ? (
                  <tr>
                    <td colspan="10" style={{ textAlign: "center" }}>
                      No record to display
                    </td>
                  </tr>
                ) : (
                  currentData.map((course, index) => (
                    <tr key={index} title={course.faculty_id}>
                      <td id="td">{course.serialNumber}</td>
                      <td id="td">
                        <Link to={`/NonFacultyProfile/${course.user_id}`}>
                          {course.fullname}
                        </Link>
                      </td>
                      <td id="td">{course.faculty_id}</td>
                      <td id="td"> {course.mobile_no}</td>
                      <td id="td">{course.email}</td>
                      <td id="td">{course.designation}</td>
                      {/* <td id="td">{course.category.map((category) => ( 
                       <small>{category}</small>
                      ))}</td>
                      <td id="td">{course.subject.map((subject) => ( 
                       <small>{subject}</small>
                      ))}</td> */}
                      <td id="td">{course.location}</td>
                      {/* <td id="td">{course.Status}</td> */}
                      {/* <td><img src={`${ImageUrlPrefix}/${course.img_url}`} class="rounded-circle" id="img" alt="avatar1" /></td> */}
                      {course.status === 0 && (
                        <td id="td">
                          <span className="YellowText">Invite Sent</span>
                        </td>
                      )}
                      {course.status === 1 && (
                        <td id="td">
                          <span className="YellowText">
                            Waiting For Approval
                          </span>
                        </td>
                      )}
                      {course.status === 5 && (
                        <td id="td">
                          <span className="YellowText">Cancel Invite</span>
                        </td>
                      )}
                      {course.status === 4 && (
                        <td id="td">
                          <span className="GreenTexts">Active</span>
                        </td>
                      )}
                      {(course.status === 2 || course.status === 3) && (
                        <td id="td">
                          <span className="PinkText">Inactive</span>
                        </td>
                      )}
                      {(course.status === 0 || course.status === 5) && (
                        <td id="td" style={{ width: "13%" }}>
                          <button
                            className="button"
                            onClick={() => handleReadView(course.user_id)}
                          >
                            <span class="material-icons">visibility</span>
                          </button>
                          {/* <button
                          className="button me-2"
                          onClick={() => handleEditView(course.user_id)}
                        >
                          <span class="material-icons">edit</span>
                        </button>{" "} */}
                        </td>
                      )}

                      {(course.status === 2 || course.status === 3) && (
                        <td id="td">
                          <button
                            className="button"
                            onClick={() => handleReadView(course.user_id)}
                          >
                            <span class="material-icons">visibility</span>
                          </button>
                        </td>
                      )}

                      {course.status === 4 && (
                        <td id="td" style={{ width: "13%" }}>
                          <button
                            className="button"
                            onClick={() =>
                              handleDetailView(course.user_id, course.staff_id)
                            }
                          >
                            <span class="material-icons">visibility</span>
                          </button>
                          {/* <button
                          className="button me-2"
                          onClick={() => handleEditView(course.user_id)}
                        >
                          <span class="material-icons">edit</span>
                        </button>{" "} */}
                        </td>
                      )}
                      {course.status === 1 && (
                        <td id="td">
                          <button
                            className="button"
                            onClick={() => handleReviewPage(course.user_id)}
                          >
                            <span class="material-icons"> exit_to_app</span>
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3 ">
          <div className="col-md-12">
            <div className="pag pag_fff w-100 dflexcenter">
              <span className="ipage">Items Per Page</span>
              <select
                name="pageCount"
                id="pageCount"
                defaultValue={rowsPerPage}
                onChange={handlePageCount}
                className="ms-2"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pagenumber ms-2">
                {" "}
                {updateListData.length > 0
                  ? currentPage * rowsPerPage + 1 - rowsPerPage
                  : 0}{" "}
                -
                {updateListData.length < currentPage * rowsPerPage
                  ? updateListData.length
                  : currentPage * rowsPerPage}{" "}
                of {updateListData.length}
              </span>
              <button
                className="prev_button ms-2 me-2"
                onClick={(e) => handlePrev(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span class="material-icons">chevron_left</span>
              </button>
              <button
                className="prev_button"
                onClick={() => handleNext(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <span class="material-icons">chevron_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NteachingListView;
