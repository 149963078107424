import React from "react";
import Select, { components } from "react-select";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import DatePickerSample from "../../DatePicker";

const Option = (props) => {
  return (
    <div style={{ textAlign: "left" }}>
      <components.Option {...props}>
        {!props.isDisabled && (
          <input
            type="checkbox"
            disabled={props.isDisabled}
            checked={props.isSelected}
            onChange={() => null}
            style={{
              ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
              width: "18px", // Change the width of the checkbox
              height: "18px", // Change the height of the checkbox
            }}
          />
        )}
        {props.isDisabled ? (
          <label style={multiCusutomStyle.optionLabelss}>{props.label}</label>
        ) : (
          <label style={multiCusutomStyle.optionLabel}>{props.label}</label>
        )}
      </components.Option>
    </div>
  );
};
function OldStudentBatch(props) {
  return (
    <div className="row box ">
      <div className="col-md-12">
        <p className="StepTitle">
          Old Student Discount {props.record.id > 1 ? "-" : ""}{" "}
          {props.record.id > 1 ? props.record.id : ""}
        </p>

        <div className="row mb-4" style={{ paddingRight: "20px" }}>
          <div className="col-md-6">
            <div className="forms ">
              <label for="OldDPercentage">
                Old Student Discount (in rupees)
              </label>
              <input
                type="text"
                ref={props.txtBatchOStudentAMount}
                maxLength={4}
                class="Inputs"
                id="OldDPercentage"
                name="OldDPercentage"
                placeholder="Old Student Discount"
                value={
                  props.record.OldDPercentage < 0
                    ? 0
                    : props.record.OldDPercentage
                }
                onChange={(e) => props.batchChange(e, props.record.id)}
              />
              {props.record.error_1 && (
                <small className="error">{props.record.error_1}</small>
              )}
            </div>
          </div>

          {props.record.OldDPercentage > 0 && (
            <div className="col-md-6">
              <div className="forms ">
                <label for="BatchRFrom">Batch Range From</label>
                <DatePickerSample
                  txtAdMDob={props.txtBatchOBatchFrom}
                  name={"BatchRFrom"}
                  handleIdChange={props.batchChange_date}
                  fieldsId={props.record.id}
                  fieldInput={props.record.BatchRFrom}
                />
                {props.record.error_2 && (
                  <small className="error">{props.record.error_2}</small>
                )}
              </div>
            </div>
          )}

          {props.record.OldDPercentage > 0 && (
            <div className="col-md-6">
              <div className="forms ">
                <label for="BatchRTo">Batch Range TO</label>
                {/* <input
                type="date"
                class="Inputs"
                id="BatchRTo"
                name="BatchRTo"
                placeholder="Batch Range TO"
                value={props.record.BatchRTo}
                onChange={(e) => props.batchChange(e, props.record.id)}
              /> */}
                <DatePickerSample
                  txtAdMDob={props.txtBatchOBatchTo}
                  minDate={
                    props.record.BatchRFrom !== null &&
                    props.record.BatchRFrom !== ""
                      ? props.record.BatchRFrom
                      : ""
                  }
                  name={"BatchRTo"}
                  handleIdChange={props.batchChange_date}
                  fieldsId={props.record.id}
                  fieldInput={props.record.BatchRTo}
                />
                {props.record.error_3 && (
                  <small className="error">{props.record.error_3}</small>
                )}
              </div>
            </div>
          )}
          {props.record.OldDPercentage > 0 && (
            <div className="col-md-6">
              <div className="forms ">
                <label for="OldSBatch">Applicable Batches</label>

                {/* mutiple checkbox  */}

                <Select
                  id="OldSBatch"
                  name="OldSBatch"
                  ref={props.txtBatchOApplicableBatch}
                  options={props.record.ApplicableBatch}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  isClearable={true}
                  onChange={(e) =>
                    props.handleChangeMutiple(e, props.record.id)
                  }
                  // value={props.record.selectedOptions}
                  value={props.record.selectedOptions.map((value) => ({
                    value: value.value,
                    batch_id: value.id,
                    label: value.label,
                    cateOp: value.cateOp,
                  }))}
                  components={{ Option }}
                  styles={multiCusutomStyle}
                />
                {props.record.error_4 && (
                  <small className="error">{props.record.error_4}</small>
                )}
              </div>
            </div>
          )}
        </div>
        {/* {props.record.error && (
       <div className="row"> 
        <div className="col-md-12 text-center">
        <small className="error">{props.record.error}</small>
         </div>
                          </div>
                        )} */}
        <div className="row mb-4" style={{ paddingRight: "20px" }}>
          <div className="col-md-12 dflexendcolu">
            {props.record.id === 1 ? (
              ""
            ) : (
              <a
                className="AddMore me-3"
                onClick={() => props.deleteOldBatch(props.record.id)}
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Old Student Discount
              </a>
            )}
            {props.length > 1 && props.record.id === 1 ? (
              <a
                className="AddMore"
                onClick={() => props.deleteOldBatch(props.record.id)}
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Old Student Discount
              </a>
            ) : (
              <a className="AddMore" onClick={props.addoldbatch}>
                <span className="material-icons download_icon align-middle">
                  add_circle
                </span>
                Add More Old Student Discount
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default OldStudentBatch;
