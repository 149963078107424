import React, { useEffect } from "react";
import Select, { components } from "react-select";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import { CommonOption } from "../../../../labels/Lableoption";
import DropdownHeader from "./DropdownHeader";

const Option = (props) => {
  return (
    <div style={{ textAlign: "left" }}>
      <components.Option {...props}>
        {!props.isDisabled && (
          <input
            type="checkbox"
            disabled={props.isDisabled}
            checked={props.isSelected}
            onChange={() => null}
            style={{
              ...(props.isSelected ? multiCusutomStyle.checkboxChecked : {}),
              width: "18px", // Change the width of the checkbox
              height: "18px", // Change the height of the checkbox
            }}
          />
        )}
        {props.isDisabled ? (
          <label style={multiCusutomStyle.optionLabelss}>{props.label}</label>
        ) : (
          <label style={multiCusutomStyle.optionLabel}>{props.label}</label>
        )}
      </components.Option>
    </div>
  );
};

const GroupHeading = (props) => {
  return (
    //   <div style={{ textAlign: "left" ,  }}>
    <components.GroupHeading {...props}>
      <label style={{ color: "#C0272D" }}>{props.children}</label>
    </components.GroupHeading>
    //  </div>
  );
};

export default function ModelExamDiscount(props) {
  // console.log(props.ref,"propsref")
  return (
    <div>
      <div className="row box ">
        <div className="col-md-12">
          <p className="StepTitle">
            Special Offer Discount {props.record.id > 1 ? "-" : ""}{" "}
            {props.record.id > 1 ? props.record.id : ""}
          </p>

          <div className="row mb-4" style={{ paddingRight: "20px" }}>
            <div className="col-md-12">
              <div className="forms">
                <label for={`NoStudentBatch`}>Type of Discount</label>
                <div
                  className="d-flex   flex-lg-row flex-md-row flex-column  col-md-12"
                  id="radiobuttons"
                >
                  <div className="form-check form-check-inline">
                    <input
                      ref={props.record.txtMeT} // Assign the unique ref
                      className="checkbox_radio"
                      type="radio"
                      disabled={
                        props.record.NoStudentBatch !== "4" ? false : true
                      }
                      name={`NoStudentBatch${props.index}`}
                      id={`NoStudentBatch${props.index + 1}`}
                      value="1"
                      checked={props.record.NoStudentBatch === "1"}
                      onChange={(e) =>
                        props.ModelSFExam(e, props.record.id, "NoStudentBatch")
                      }
                      onClick={
                        props.record.NoStudentBatch === "1"
                          ? () =>
                              props.ModelSFExamClickFunction(
                                props.record.id,
                                "NoStudentBatch",
                                "1"
                              )
                          : () => {}
                      }
                    />
                    <label
                      onClick={
                        props.record.NoStudentBatch === "1"
                          ? () =>
                              props.ModelSFExamClickFunction(
                                props.record.id,
                                "NoStudentBatch",
                                "1"
                              )
                          : () => {}
                      }
                      className="checkbox-label"
                      // htmlFor={`NoStudentBatch${props.index + 1}`}
                    >
                      For all the students enrolled in this batch
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      name={`NoStudentBatch${props.index}`}
                      id={`NoStudentBatch${props.index + 2}`}
                      value="2"
                      disabled={
                        props.record.NoStudentBatch !== "4" ? false : true
                      }
                      checked={props.record.NoStudentBatch === "2"}
                      onChange={(e) =>
                        props.ModelSFExam(e, props.record.id, "NoStudentBatch")
                      }
                      onClick={
                        props.record.NoStudentBatch === "2"
                          ? () =>
                              props.ModelSFExamClickFunction(
                                props.record.id,
                                "NoStudentBatch",
                                "2"
                              )
                          : () => {}
                      }
                    />
                    <label
                      onClick={
                        props.record.NoStudentBatch === "2"
                          ? () =>
                              props.ModelSFExamClickFunction(
                                props.record.id,
                                "NoStudentBatch",
                                "2"
                              )
                          : () => {}
                      }
                      className="checkbox-label"
                      // htmlFor={`NoStudentBatch${props.index + 2}`}
                    >
                      For the ongoing batches
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="checkbox_radio"
                      type="radio"
                      name={`NoStudentBatch${props.index}`}
                      id={`NoStudentBatch${props.index + 3}`}
                      value="3"
                      disabled={
                        props.record.NoStudentBatch !== "4" ? false : true
                      }
                      checked={props.record.NoStudentBatch === "3"}
                      onChange={(e) =>
                        props.ModelSFExam(e, props.record.id, "NoStudentBatch")
                      }
                      onClick={
                        props.record.NoStudentBatch === "3"
                          ? () =>
                              props.ModelSFExamClickFunction(
                                props.record.id,
                                "NoStudentBatch",
                                "3"
                              )
                          : () => {}
                      }
                    />
                    <label
                      className="checkbox-label"
                      onClick={
                        props.record.NoStudentBatch === "3"
                          ? () =>
                              props.ModelSFExamClickFunction(
                                props.record.id,
                                "NoStudentBatch",
                                "3"
                              )
                          : () => {}
                      }
                      // htmlFor={`NoStudentBatch${props.index + 3}`}
                    >
                      For the first 'n' number of students
                    </label>
                  </div>
                  {((props.record.NoStudentBatch === "" && props.index === 1) ||
                    props.record.NoStudentBatch === "4") && (
                    <div className="form-check form-check-inline">
                      <input
                        className="checkbox_radio"
                        type="radio"
                        name={`NoStudentBatch${props.index}`}
                        id={`NoStudentBatch${props.index + 4}`}
                        value="4"
                        checked={props.record.NoStudentBatch === "4"}
                        onChange={(e) =>
                          props.ModelSFExam(
                            e,
                            props.record.id,
                            "NoStudentBatch"
                          )
                        }
                        onClick={
                          props.record.NoStudentBatch === "4"
                            ? () =>
                                props.ModelSFExamClickFunction(
                                  props.record.id,
                                  "NoStudentBatch",
                                  "4"
                                )
                            : () => {}
                        }
                      />
                      <label
                        onClick={
                          props.record.NoStudentBatch === "4"
                            ? () =>
                                props.ModelSFExamClickFunction(
                                  props.record.id,
                                  "NoStudentBatch",
                                  "4"
                                )
                            : () => {}
                        }
                        className="checkbox-label"
                        // htmlFor={`NoStudentBatch${props.index + 4}`}
                      >
                        None
                      </label>
                    </div>
                  )}
                </div>
                {props.record.error_1 && (
                  <small className="error">{props.record.error_1}</small>
                )}
              </div>
            </div>
            {props.record.NoStudentBatch !== "4" &&
              props.record.NoStudentBatch !== "" && (
                <div className="col-md-6">
                  <div className="forms ">
                    <label for="SOfferDis">
                      Special Offer Discount (in rupees)
                    </label>
                    <input
                      ref={props.record.txtBatchMESFNSF}
                      type="number"
                      class="Inputs"
                      id="SOfferDis"
                      name="SOfferDis"
                      placeholder="Special Offer Discount"
                      value={
                        props.record.SOfferDis > 0 ? props.record.SOfferDis : ""
                      }
                      onChange={(e) =>
                        props.ModelSFExam(e, props.record.id, "SOfferDis")
                      }
                    />

                    {props.record.error_2 && (
                      <small className="error">{props.record.error_2}</small>
                    )}
                  </div>
                </div>
              )}

            {props.record.NoStudentBatch !== "4" &&
              props.record.NoStudentBatch === "3" && (
                <div className="col-md-6">
                  <div className="forms ">
                    <label for="StudentBatchCount">Student Count</label>
                    <input
                      ref={props.record.txtBatchMESFNSC}
                      type="number"
                      class="Inputs"
                      id="StudentBatchCount"
                      name="StudentBatchCount"
                      placeholder="Student Count"
                      value={
                        props.record.StudentBatchCount > 0
                          ? props.record.StudentBatchCount
                          : ""
                      }
                      onChange={(e) =>
                        props.ModelSFExam(
                          e,
                          props.record.id,
                          "StudentBatchCount"
                        )
                      }
                    />

                    {props.record.error_3 && (
                      <small className="error">{props.record.error_3}</small>
                    )}
                  </div>
                </div>
              )}

            {props.record.NoStudentBatch !== "4" &&
              props.record.NoStudentBatch === "2" && (
                <div className="col-md-6">
                  <div className="forms ">
                    <label for="MEABatch">Applicable Batches</label>

                    <Select
                      ref={props.record.txtBatchMESFNAB}
                      id="MEABatch"
                      name="MEABatch"
                      options={props.oNGoingBatch}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      isClearable={true}
                      onChange={(e) =>
                        props.handleChangeMutipleMESF(e, props.record.id)
                      }
                      value={props.record.selectedOptions.map((value) => ({
                        label: value.label,
                        id: value.batch_id,
                        batch_id: value.batch_id,
                        value: value.value,
                      }))}
                      components={{ GroupHeading, Option }}
                      styles={multiCusutomStyle}
                    />

                    {props.record.error_4 && (
                      <small className="error">{props.record.error_4}</small>
                    )}
                  </div>
                </div>
              )}
          </div>

          <div className="row mb-4" style={{ paddingRight: "20px" }}>
            <div className="col-md-12 dflexendcolu">
              {props.record.id === 1 ? (
                ""
              ) : (
                <a
                  className="AddMore me-3"
                  onClick={
                    props.record.NoStudentBatch !== "4"
                      ? () => props.deleteMESF(props.record.id)
                      : () => {}
                  }
                >
                  <span className="material-icons download_icon align-middle">
                    remove_circle
                  </span>
                  Delete This Special Offer Discount
                </a>
              )}
              {props.length > 1 && props.record.id === 1 ? (
                <a
                  className="AddMore"
                  onClick={
                    props.record.NoStudentBatch !== "4"
                      ? () => props.deleteMESF(props.record.id)
                      : () => {}
                  }
                >
                  <span className="material-icons download_icon align-middle">
                    remove_circle
                  </span>
                  Delete This Special Offer Discount
                </a>
              ) : (
                <a
                  className="AddMore"
                  onClick={
                    props.record.NoStudentBatch !== "4"
                      ? props.record.NoStudentBatch !== ""
                        ? props.addMESF
                        : () => {}
                      : () => {}
                  }
                >
                  <span className="material-icons download_icon align-middle">
                    add_circle
                  </span>
                  Add More Special Offer Discount
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
