import React from "react";
import "../question/Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";
//display type 1 means - multiple option  mcq design
// sample design
// .பபாருத்ைமான இழணழயத் பைர்க .
// a)அணங்கு - பைய்வம்
// b)ஓவு - ஓவியம்
// c)நியமம் - மதில்
// d)மாகால் - கைல்
// பபாருத்ைமான இழணழயத் பைர்க
//  (A) (a) மற்றும் (b) மட்டும் ரி  //  (B) (b) மற்றும் (c) மட்டும் ரி
//  (C) (c )மற்றும் (d) மட்டும் ரி  //  (D) அழனத்தும் ரி

function RtypeTwo(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  const optionType = props.list.options_type;
  const generateOptionsEnglish = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            {props.type !== "1" && props.type !== "2" && props.type === "0" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={props.list[`options_en_${options[i]}`]}
                checked={props.list.user_answer === options[i]}
              />
            )}
            {props.type === "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={props.list[`options_en_${options[i]}`]}
                checked={props.list.answer_en === options[i]}
              />
            )}
            <span
              className={
                props.type !== "1"
                  ? "answer_label_viewQ_english"
                  : "answer_labela"
              }
            >
              ({options[i]})
            </span>
          </div>
          <div>
            <span
              className={
                props.type !== "1"
                  ? "answer_label_viewQ_english ms-1"
                  : "answer_labela"
              }
            >
              <LatexProcessorComponent
                originalString={props.list[`options_en_${options[i]}`]}
              />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            {props.type !== "1" && props.type !== "2" && props.type === "0" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={props.list[`options_tm_${options[i]}`]}
                checked={props.list.user_answer === options[i]}
              />
            )}
            {props.type === "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={props.list[`options_tm_${options[i]}`]}
                checked={props.list.answer_tm === options[i]}
              />
            )}
            <span
              className={
                props.type !== "1"
                  ? "answer_label_viewQ_tamil"
                  : "answer_labela"
              }
            >
              ({options[i]})
            </span>
          </div>
          <div>
            <span
              className={
                props.type !== "1"
                  ? "answer_label_viewQ_tamil ms-1"
                  : "answer_labela"
              }
            >
              <LatexProcessorComponent
                originalString={props.list[`options_tm_${options[i]}`]}
              />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  return (
    <div className="Question_temp_VQ">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            {EsplitLines.map((line, index) => {
              if (line.trim() !== "") {
                return (
                  <div className="col-md-12 mt-2" key={index}>
                    <span className="question_labels_english">
                      <LatexProcessorComponent originalString={line} />
                    </span>
                  </div>
                );
              } else {
                return null;
              }
            })}

            <div className="col-md-12 mt-2">
              <div className="row">{generateOptionsEnglish(optionType)}</div>
            </div>
          </div>
        )}

      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            {splitLines.map((line, index) => {
              if (line.trim() !== "") {
                return (
                  <div className="col-md-12 mt-2" key={index}>
                    <span className="question_labels_tamil">
                      <LatexProcessorComponent originalString={line} />{" "}
                    </span>
                  </div>
                );
              } else {
                return null;
              }
            })}
            <div className="col-md-12 mt-2">
              <div className="row">{generateOptionsTamil(optionType)}</div>
            </div>
          </div>
        )}
    </div>
  );
}

export default RtypeTwo;
