import React from "react";
import "../question/Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

//display type 4 means -   mcq design
// sample design
// Which one of the following best describes the concept of ‘Small Farmer Large Field’?
//  (A) Resettlement of a large number of people, uprooted from their countries due to war, by giving them a large cultivable, land which they cultivate collectively and share the produce
//  (B) Many marginal farmers in an area organize themselves into groups and synchronize and harmonize selected agricultural operations.
//  (C) Many marginal farmers in an area together make a contract with a corporate body and surrender their land to the corporate body for a fixed term for which the corporate body makes a payment of agreed amount to the farmers
//  (D) A company extends loans, technical knowledge and material inputs to a number of small farmers in an area so that they produce the agricultural commodity required by the company for its manufacturing process and commercial production

function RtypeFive(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const optionType = props.list.options_type;

  const generateOptionsEnglish = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const optionValue = props.list[`options_en_${options[i]}`];
      const parts = optionValue.split(" - ").map((part) => part.trim());
      const length = parts.length;
      // console.log(parts, "lalitha......", optionValue);

      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            {props.type !== "1" && props.type !== "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={optionValue}
                checked={props.list.user_answer === options[i]}
              />
            )}
            {props.type === "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_en`}
                value={optionValue}
                checked={props.list.answer_tm === options[i]}
              />
            )}
            <span
              className={
                props.type != "1"
                  ? "answer_label_viewQ_english"
                  : "answer_labela"
              }
            >
              ({options[i]})
            </span>
          </div>
          <div className="container m-0">
            <div className="row">
              {parts.map((item, index) => (
                <div className="col-md-6 col-6" key={index}>
                  <span
                    className={
                      props.type !== "1"
                        ? "answer_label_viewQ_english ms-1"
                        : "answer_labela"
                    }
                  >
                    {/* {parse(item)} */}
                    <LatexProcessorComponent originalString={item} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const optionValue = props.list[`options_tm_${options[i]}`];
      const parts = optionValue.split(" - ").map((part) => part.trim());
      const length = parts.length;

      let firstPart = optionValue;
      let secondPart = "";

      if (parts.length === 2) {
        firstPart = parts[0].trim();
        secondPart = parts[1].trim();
      }

      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            {props.type !== "1" && props.type !== "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={optionValue}
                checked={props.list.user_answer === options[i]}
              />
            )}
            {props.type === "2" && (
              <input
                type="radio"
                className="radioInput1"
                name={`q_answer_${props.index}_tm`}
                value={optionValue}
                checked={props.list.answer_tm === options[i]}
              />
            )}
            <span
              className={
                props.type != "1" ? "answer_label_viewQ_tamil" : "answer_labela"
              }
            >
              ({options[i]})
            </span>
          </div>
          <div className="container m-0">
            <div className="row">
              {parts.map((item, index) => (
                <div className="col-md-6 col-6" key={index}>
                  <span
                    className={
                      props.type !== "1"
                        ? "answer_label_viewQ_tamil ms-1"
                        : "answer_labela"
                    }
                  >
                    {/* {parse(item)} */}
                    <LatexProcessorComponent originalString={item} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return elements;
  };

  return (
    <div className=" Question_temp_VQ">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_english">
                {/* {parse(props.list.en_question)} */}
                <LatexProcessorComponent
                  originalString={props.list.en_question}
                />
              </span>
            </div>
            <div className="col-md-12">
              <div className="row">{generateOptionsEnglish(optionType)}</div>
            </div>
          </div>
        )}
      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 || props.list.lang_type === 2) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {/* {parse(props.list.tm_question)} */}
                <LatexProcessorComponent
                  originalString={props.list.tm_question}
                />
              </span>
            </div>
            <div className="col-md-12 mt-1">
              <div className="row">{generateOptionsTamil(optionType)}</div>
            </div>
          </div>
        )}
    </div>
  );
}

export default RtypeFive;
