import React from "react";
import ScheduleView from "./ScheduleView";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../../../../axios";
import moment from "moment";
import Loader from "../../Loader";

export default function Faculityschedule(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [eventFields, setEventFields] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    AxiosInstance.post("/api/staff/schedulelist", {
      staff_id: parseInt(props.staff_id),
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
    })
      .then((res) => {
        if (res.data.status === true) {
          setIsLoading(false);
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${
              event.start_time
            }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${
              event.end_time
            }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              start: startDate,
              end: endDate,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              startTime: event.start_time,
              syllabus_filepath: event.syllabus_filepath,
              endTime: event.end_time,
              description: event.description,
              more: true,
            };
          });
          setEventFields(parsedEvents);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  }, []);

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div
      className={
        props.activeTab === 1
          ? "row tab-content mt-4"
          : "row tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      <div
        className={
          props.activeTab === 1
            ? "tab-pane fade show active col-md-12"
            : "tab-pane fade col-md-12 "
        }
      >
        <section className=" StepOne">
          <div className="row box">
            <div className="col-md-12">
              <p className="StepTitle">Schedule</p>
              <div className="row mt-4 mb-5">
                <div className="col-md-12">
                  <ScheduleView
                    staff_id={props.staff_id}
                    eventFields={eventFields}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
