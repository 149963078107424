import React from "react";
import styled, { css } from "styled-components";
import { MEDIA_SIZE_LG } from "../utils/helper";

const StyledBoxContainer = styled.div`
  border: 1px solid #111;
  /* padding: 16px; */
  position: relative;
  border-radius: ${css`var(--border-radius-tiny)`};
  margin-top: 10px;
`;

const StyledHeader = styled.span`
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0px 5px;
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: var(--font-weight-medium);
  /* text-transform: capitalize; */
  color: ${css`var(--color-brand-900)`};
  background-color: ${css`var(--color-white)`};
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 16px;
    top: -15px;
  }
`;

export default function FloatedTitleContainer({
  children,
  title = "",
  className = "",
  headerClassName = "",
  headerStyles = {},
}) {
  return (
    <StyledBoxContainer className={className}>
      <StyledHeader style={{ ...headerStyles }} className={headerClassName}>
        {title}
      </StyledHeader>
      {children}
    </StyledBoxContainer>
  );
}
