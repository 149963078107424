import "../../../pages/AdmissionForm.css";
import "./Counselling.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "../payment/Payment.css";
import Razorpay from "react-razorpay";
import intlTelInput from "intl-tel-input";
import {
  Alarm,
  ConstructionOutlined,
  CurrencyRupee,
  Download,
  LaptopChromebook,
  Today,
} from "@mui/icons-material";
import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
/// import files

import { AxiosInstance } from "../../../axios/index";
import { validateFields } from "./Validation";
import { TechQualification } from "../../../pages/TechnicalQualification";
import {
  ImageUrlPrefix,
  LBL_BASIC_DETAILS,
  LBL_CONTACT_DETAILS,
  LBL_ID_PROOFS,
  PaymentSKey,
} from "../../../labels/index.js";
import Subnav from "../../../features/Core/Header/Subnav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SpecialPrefrence from "../../../pages/dashboard/SpecialPrefrence";
import MultiSelect from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { object } from "yup";
import SpecialPre from "../../../pages/dashboard/SpecialPre";
// import { PictureAsPdfIcon } from '@mui/icons-material'
import { toWords } from "number-to-words";
import { ToWords } from "to-words";
// function start
import facebook from "../../../img/socialicons/facebook.png";
import insta from "../../../img/socialicons/insta.png";
import linkedin from "../../../img/socialicons/linkedin.png";
import twitter from "../../../img/socialicons/twitter.png";
import whatsapp from "../../../img/socialicons/whastapp.png";
import youtube from "../../../img/socialicons/youtube.png";
import Select, { components } from "react-select";
import { customStyles } from "../../../pages/customStyles";
import { multiCusutomStyle } from "../../../pages/Multicustomstyles";
import { CustomCOption } from "../../../labels/Lableoption";
import DatePickerSample from "../DatePicker";
import MonthDatePicker from "../MonthDatePicker";
//multiple dropdown chip

function ReviewCounselling() {
  //useState for all dropdown , input fields

  //fields use current fields focus
  const navigate = useNavigate();
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const { edit, user_id, admission_id } = useParams();
  const txtAdReply_student = useRef(null);
  const txtAdFname = useRef(null);
  const txtAdLname = useRef(null);
  const txtAdAge = useRef(null);
  const txtAdMDob = useRef(null);
  const txtAdMStatus = useRef(null);
  const txtAdGender = useRef(null);
  const txtAdFath = useRef(null);
  const txtAdMoth = useRef(null);
  const txtAdPSize = useRef(null);
  const txtAdAno = useRef(null);
  const txtAdApath = useRef(null);
  const txtAdCno = useRef(null);
  const txtAdCpath = useRef(null);
  const txtAdSpc = useRef(null);
  const txtAdPno = useRef(null);
  const txtAdWno = useRef(null);
  const txtbatchDet = useRef(null);
  const txtAdFpo = useRef(null);
  const txtAdEmail = useRef(null);
  const txtAdPhno = useRef(null);
  const txtAdPsname = useRef(null);
  const txtAdParea = useRef(null);
  const txtAdPland = useRef(null);
  const txtAdPpost = useRef(null);
  const txtAdPtaluk = useRef(null);
  const txtAdPpincode = useRef(null);
  const txtAdPcity = useRef(null);
  const txtAdPstate = useRef(null);
  const txtAdPdistrict = useRef(null);
  const txtAdPerhno = useRef(null);
  const txtAdPersname = useRef(null);
  const txtAdPerarea = useRef(null);
  const txtAdPerland = useRef(null);
  const txtAdPererpost = useRef(null);
  const txtAdPertaluk = useRef(null);
  const txtAdPerpincode = useRef(null);
  const txtAdPercity = useRef(null);
  const txtAdPerstate = useRef(null);
  const txtAdPerdistrict = useRef(null);

  const txtAdEduc = useRef(null);
  const txtAdSslc = useRef(null);
  const txtAdSpass = useRef(null);
  const txtAdSmarks = useRef(null);
  const txtAdSboard = useRef(null);
  const txtAdSschool = useRef(null);
  const txtAdSins = useRef(null);
  const txtAdSfile = useRef(null);
  const txtAdHslc = useRef(null);
  const txtAdHpass = useRef(null);
  const txtAdHmarks = useRef(null);
  const txtAdHboard = useRef(null);
  const txtAdHschool = useRef(null);
  const txtAdHins = useRef(null);
  const txtAdHfile = useRef(null);
  const txtAdUCer = useRef(null);
  const txtAdUpass = useRef(null);
  const txtAdUmarks = useRef(null);
  const txtAdUdeg = useRef(null);
  const txtAdUMaj = useRef(null);
  const txtAdUMajOther = useRef(null);
  const txtAdUclass = useRef(null);
  const txtadVerifyHere = useRef(null);
  const txtAdUins = useRef(null);
  const txtAdUfile = useRef(null);
  const txtAdPCer = useRef(null);
  const txtAdPpass = useRef(null);
  const txtAdPmarks = useRef(null);
  const txtAdPdeg = useRef(null);
  const txtAdPMaj = useRef(null);
  const txtAdPMajOther = useRef(null);
  const txtAdIMajOther = useRef(null);
  const txtAdDMajOther = useRef(null);
  const txtAdCMajOther = useRef(null);
  const txtAdPclass = useRef(null);
  const txtAdPins = useRef(null);
  const txtAdPfile = useRef(null);
  const txtAdICer = useRef(null);
  const txtAdIpass = useRef(null);
  const txtAdImarks = useRef(null);
  const txtAdIdeg = useRef(null);
  const txtAdIMaj = useRef(null);
  const txtAdIclass = useRef(null);
  const txtAdIins = useRef(null);
  const txtAdIfile = useRef(null);
  const txtAdDCer = useRef(null);
  const txtAdDpass = useRef(null);
  const txtAdDmarks = useRef(null);
  const txtAdDdeg = useRef(null);
  const txtAdDMaj = useRef(null);
  const txtAdDclass = useRef(null);
  const txtAdDins = useRef(null);
  const txtAdDfile = useRef(null);
  const txtAdCCer = useRef(null);
  const txtAdCpass = useRef(null);
  const txtAdCmarks = useRef(null);
  const txtAdCdeg = useRef(null);
  const txtAdCMaj = useRef(null);
  const txtAdCclass = useRef(null);
  const txtAdCins = useRef(null);
  const txtAdCfile = useRef(null);
  const txtAdOCer = useRef(null);
  const txtAdOpass = useRef(null);
  const txtAdOmarks = useRef(null);
  const txtAdOdeg = useRef(null);
  const txtAdOMaj = useRef(null);
  const txtAdOclass = useRef(null);
  const txtAdOins = useRef(null);
  const txtAdOfile = useRef(null);
  const txtAdCate = useRef(null);

  const txtAdMarkpaper1 = useRef(null);
  const txtAdMarkpaper2 = useRef(null);
  const txtAdMarkpaper3 = useRef(null);
  const txtAdMarkpaper4 = useRef(null);
  const txtAdMarkpaper5 = useRef(null);
  const txtAdMarkpaper6 = useRef(null);
  const txtAdMarkpaper7 = useRef(null);
  const txtAdMarkpaper8 = useRef(null);
  const txtAdMarkpaper9 = useRef(null);
  const txtAdmarkUpdatedMains = useRef(null);
  const txtAdmarkUpdatedOral = useRef(null);
  const txtAdoverallrank = useRef(null);
  const txtAdMarksOne = useRef(null);
  const txtAdMarksTwo = useRef(null);
  const txtAdInterviewmarks = useRef(null);
  const txtAdStenoRank = useRef(null);
  const txtAdWindowRank = useRef(null);
  const txtAdcommunalrank = useRef(null);
  const txtAdtypistrank = useRef(null);
  const txtAdtamilrank = useRef(null);
  const txtAdPSTMRank = useRef(null);
  const txtAdDRank = useRef(null);
  const txtAdpreferredpost = useRef(null);
  const txtAdPreferredDept = useRef(null);
  const txtAdExamPassed = useRef(null);
  const txtAdExamRegno = useRef(null);
  const txtAdLoca = useRef(null);
  const txtAdcMode = useRef(null);
  const txtAdSub = useRef(null);
  const txtAdExam = useRef(null);
  const txtAdinsu = useRef(null);
  const txtAdEdetail = useRef(null);
  const txtAdEclear = useRef(null);
  const txtAdDesg = useRef(null);
  const txtAdDep = useRef(null);
  const txtAdDjoin = useRef(null);
  const txtAdayear = useRef(null);
  const txtAdacate = useRef(null);
  const txtAdacourse = useRef(null);
  const txtAdaIdnum = useRef(null);
  const txtAdafile = useRef(null);

  const [pgDegree, setPGDegree] = useState([]);
  const [ipgDegree, setIPGDegree] = useState([]);
  const [readbatch, setReadbatch] = useState([]);
  const [readSpecialpre, setReadSpecialpre] = useState([]);
  const [step, setStep] = useState(1);
  const [cOption, setCOption] = useState([]);
  const [catOption, setCatOption] = useState([]);
  const [teachMedium, setTeachMedium] = useState([]);
  const [department, setDepartment] = useState([]);
  const [examination, setExamination] = useState([]);
  const [previousexam, setPreviousexam] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [technicalOption, setTechnicalOption] = useState([]);
  const [pcDegree, setPCDegree] = useState([]);
  const [diplomaDegree, setDiplomaDegree] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [coursesCate, setCoursesCate] = useState([]);
  const [city, setCity] = useState([]);
  const [major, setMajor] = useState([]);
  const [selectedPrev, setSelectedPrev] = useState([]);
  const [classOption, setClassOption] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [dept, setDept] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [specialPrefrence, setSpecialPrefrence] = useState([]);
  const [ugDegree, setUGDegree] = useState([]);
  const [pgMajor, setPgMajor] = useState([]);
  const [ipMajor, setIpMajor] = useState([]);
  const [dmajor, setDmajor] = useState([]);
  const [pcMajor, setPcmajor] = useState([]);
  const [otMajor, setOTmajor] = useState([]);
  const [listErrors, setListErrors] = useState({});
  const [batchDetails, setBatchDetails] = useState([]);
  const [batchId, setBatchId] = useState(null);
  const [courseAias, setCourseAias] = useState([]);
  const [selectedPre, setSelectedPre] = useState([]);
  const [selectedPreDept, setSelectedPreDept] = useState([]);
  const [locations, setLocations] = useState([]);
  const [batchMessage, setBatchMessage] = useState(null);
  //admission id  after the step 4 api call
  const [admissionID, setAdmissionID] = useState({
    adId: 0,
    is_paid: null,
    batch_id: null,
  });
  let ActualFees = 0; // for exam fees selected panel maintain step 7
  const [dis, setDis] = useState(null);
  //get user_id from localhost and set global
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [step]);
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }
  const currentDate_Join = moment().format("YYYY-MM-DD"); // step 4 join govt date
  //coupon code applied usestate
  const [couponApplied, setCouponApplied] = useState({
    coupon_status: false,
    amount: "",
    coupon_code: "",
  });
  //education useState
  const [educationQualification, setEducationQualification] = useState([
    "SSLC",
  ]);
  const [uGisOther, setUGIsOther] = useState(false);
  const [pGisOther, setPGIsOther] = useState(false);
  const [iPGisOther, setIPGIsOther] = useState(false);
  const [dipisOther, setDipIsOther] = useState(false);
  const [pCisOther, setPCIsOther] = useState(false);

  const [ugOption, setUgOption] = useState({
    UG: null,
    PG: null,
    Integrated_PG: null,
    Diploma: null,
    Professional_Courses: null,
  });

  const [mOption, setMOption] = useState({
    UGMajor: null,
    UGClassName: null,
    PGMajor: null,
    PGClassName: null,
    IPMajor: null,
    IPClassName: null,
    DMajor: null,
    DClassName: null,
    PCMajor: null,
    PCClassName: null,
    OtClassName: null,
  });
  //education useState
  const [sslcCerficates, setSslcCerficates] = useState([
    {
      name: "sslc",
      SSLC_CNo: "",
      SSLC_Year: "",
      SSLC_Marks: "",
      SSLC_Eboard: "",
      SSLC_Scl: "",
      SSLC_MI: "",
      SSLC_Path: null,
      original_file_path: null,
    },
    {
      name: "hsc",
      HSC_CNo: "",
      HSC_Year: "",
      HSC_Marks: "",
      HSC_Ebaord: "",
      HSC_Scl: "",
      HSC_MI: "",
      HSC_Path: null,
      original_file_path: null,
    },
    {
      name: "UG",
      UGCertificateNumber: "",
      UGYearpassing: "",
      UGDegree: "",
      UGMajor: "",
      UGMajor_Other: "",
      UGMarksPercentage: "",
      UGClassName: "",
      UGNameInstitute: "",
      UG_Path: null,
      original_file_path: null,
    },
    {
      name: "PG",
      PGCertificateNumber: "",
      PGYearpassing: "",
      PGDegree: "",
      PGMajor: "",
      PGMajor_Other: "",
      PGMarksPercentage: "",
      PGClassName: "",
      PGNameInstitute: "",
      PG_Path: null,
      original_file_path: null,
    },
    {
      name: "Integrated PG",
      IPCertificateNumber: "",
      IPYearpassing: "",
      IPDegree: "",
      IPMajor: "",
      IPMajor_Other: "",
      IPMarksPercentage: "",
      IPClassName: "",
      IPNameInstitute: "",
      IP_Path: null,
      original_file_path: null,
    },
    {
      name: "Diploma",
      DCertificateNumber: "",
      DYearpassing: "",
      DDegree: "",
      DMajor: "",
      DMajor_other: "",
      DMarksPercentage: "",
      DClassName: "",
      DNameInstitute: "",
      D_Path: null,
      original_file_path: null,
    },
    {
      name: "Professional Courses",
      PCCertificateNumber: "",
      PCYearpassing: "",
      PCDegree: "",
      PCMajor: "",
      PCMajor_Other: "",
      PCMarksPercentage: "",
      PCClassName: "",
      PCNameInstitute: "",
      PC_Path: null,
      original_file_path: null,
    },
    {
      name: "Others",
      OtCertificateNumber: "",
      OtYearpassing: "",
      OtDegree: "",
      OtMajor: "",
      OtMarksPercentage: "",
      OtClassName: "",
      OtNameInstitute: "",
      Ot_Path: null,
      original_file_path: null,
    },
  ]);
  //specialPreference
  const [specialAdmissionPre, setSpecialAdmissionPre] = useState([
    // {
    //   id: 1,
    //   name: "",
    //   file: "",
    // },
  ]);
  //technical qualification
  const [techQualification, setTechQualification] = useState([
    {
      id: 1,
      name: "",
      level: "",
      lang: "",
      Cnum: "",
      grade: "",
      year: "",
      file: "",
      error_name: "",
      edu_medium: "",
      error_certificate_no: "",
      error_year_of_passing: "",
      error_level: "",
      error_grade: "",
      error_certificate_path: "",
    },
  ]);
  const [dropdown, setDropDown] = useState({
    Cast: null,
    ExamName: null,
  });
  //list of inputs
  const [list, setList] = useState({
    FirstName: "",
    LastName: "",
    Fathername: "",
    MotherName: "",
    Profilephoto: null,
    DOB: "",
    age: 0,
    gender: "Male",
    MaritalStatus: "Single/Unmarried",
    aadharNo: "",
    aadharCardphoto: null,
    original_aadhar_path: null,
    original_profile_path: null,
    original_caste_path: null,
    Mobile: "",
    MobileCode: "",
    WhatsAppNumberCode: "",
    ParentMobileNoCode: "",
    WhatsAppNumber: "",
    ParentMobileNo: "",
    ContactNumber: false,
    SamePresentAddress: false,
    emailaddress: "",
    BuildingNumberPresent: "",
    BuildingNumberPresent: "",
    StreetNamePresent: "",
    AreaPresent: "",
    LandmarkPresent: "",
    districtPresent: "",
    CityPresent: "",
    statePresent: "",
    PostPresent: "",
    TalukPresent: "",
    PincodePresent: 0,
    BuildingNumberPermanent: "",
    StreetNamePermanent: "",
    AreaPermanent: "",
    LandmarkPermanent: "",
    CityPermanent: "",
    districtPermanent: "",
    statePermanent: "",
    PincodePermanent: 0,
    PostPermanent: "",
    TalukPermanent: "",
    PresentAddress: false,
    Cast: "",
    CastFile: null,
    category: "",
    SPCcategory: "",
    CompetitiveExamination: "Yes",
    CompetitiveExamName: "",
    Institute: "",
    ExamName: "",
    ExamRegno: "",
    location: "2",
    markPaperone: "",
    markPaperTwo: "",
    markpaperThree: "",
    markPaperFour: "",
    markPaperFive: "",
    markPaperSix: "",
    markPaperSeven: "",
    markPaperEight: "",
    markPaperNine: "",
    markUpdatedMains: "",
    markUpdatedOral: "",
    MarksOne: "",
    MarksTwo: "",
    WindowRank: "",
    StenoRank: "",
    DRank: "",
    PSTMRank: "",
    writtenexammark: "",
    overallrank: "",
    Interviewmarks: "",
    typistrank: "",
    communalrank: "",
    tamilrank: "",
    preferredpost: "",
    workingRes: "Yes",
    Designation: "",
    Department: "",
    JoinGovt: "",
    AIASAStudent: "Yes",
    Year: "",
    CategoryAias: "",
    CourseAias: "",
    IDPhoto: null,
    original_aiasa_id_path: null,
    IDNumber: "",
    ClassType: "",
    CourseCate: "",
    CouresName: "",
    TeachMedium: "",
    Verify: false,
    admission_status: "",
    ExamName_passed: "",
    location_name: "",
  });
  const [examDetailsExam, setExamDetailsExam] = useState([]);
  const [commentSec, setCommentSec] = useState([
    // {
    //   user_type:"",
    //   updated_dt: null,
    //   comment:null,
    //   admission_id: "",
    // }
  ]);
  useEffect(() => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id; // Correct variable name
        AxiosInstance.post("api/course/admission/chat/comm/list", {
          user_id: user_id, // Use user_id
          admission_id: parseInt(admission_id),
          type: 2, // counselling
        })
          .then((res) => {
            // console.log(res);
            if (res.data.status === true) {
              const comSec = res.data.data.map((el) => ({
                user_type: el.user_type,
                admission_id: el.admission_id,
                comment: el.comment,
                updated_dt: el.updated_dt,
              }));
              setCommentSec(comSec);
            }
          })
          .catch((error) => {
            console.error("Error fetching comments:", error);
            // Handle the error or set an error state
          });
      }
    }
  }, [edit]); // If edit is necessary as a dependency
  //when course category select batch details fill with ui
  // admission form read api for all steps
  useEffect(() => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;

        AxiosInstance.post("api/purchase/view", {
          user_id: user_id,
          admission_id: parseInt(admission_id),
          type: 2, // counselling batch
        })
          .then((res) => {
            // console.log(res.data.data);
            const userinfo = res.data.data;
            const PersonDetails = {
              FirstName: userinfo.first_name,
              LastName: userinfo.last_name,
              Fathername: userinfo.father_name,
              MotherName: userinfo.mother_name,
              Profilephoto:
                userinfo.profile_path !== null ? userinfo.profile_path : null,
              original_profile_path:
                userinfo.original_profile_path !== null
                  ? userinfo.original_profile_path
                  : null,
              original_aadhar_path:
                userinfo.original_aadhar_path !== null
                  ? userinfo.original_aadhar_path
                  : null,
              original_caste_path:
                userinfo.original_caste_path !== null
                  ? userinfo.original_caste_path
                  : null,
              DOB: userinfo.dob != null ? dobCon(userinfo.dob) : "",
              //  DOB: "2000-07-29",
              age: userinfo.age,
              gender: userinfo.gender,
              MaritalStatus: userinfo.marital_status,
              aadharNo: userinfo.aadhar_no,
              aadharCardphoto:
                userinfo.aadhar_path !== null ? userinfo.aadhar_path : null,
              Cast: userinfo.community,
              CastFile:
                userinfo.community_path !== null
                  ? userinfo.community_path.split("/").pop()
                  : "",
              SPCcategory: userinfo.scp[0].category,
              Mobile: userinfo.phone_no,
              WhatsAppNumber: userinfo.whatsapp_no,
              ContactNumber: userinfo.contact_type === 0 ? false : true,
              ParentMobileNo: userinfo.parent_mob_no,
              MobileCode:
                userinfo.country_code !== null ? userinfo.country_code : "+91",
              WhatsAppNumberCode:
                userinfo.country_code !== null ? userinfo.country_code : "+91",
              ParentMobileNoCode:
                userinfo.country_code !== null ? userinfo.country_code : "+91",
              emailaddress: userinfo.email,
            };

            if (userinfo.community !== null) {
              setDropDown((prevState) => ({
                ...prevState,
                ["Cast"]: {
                  value: userinfo.community, // Set to null if userinfo.community is falsy
                  label: userinfo.community, // Set a default label if userinfo.community is falsy
                },
              }));
            }

            const SpecPrefrence = userinfo.scp;
            // console.log(SpecPrefrence, "SpecPrefrence");
            setSpecialAdmissionPre(SpecPrefrence);
            // setReadSpecialpre(SpecPrefrence);

            userinfo.address.map((obj) => {
              if (obj.address_type === 1) {
                PersonDetails.SamePresentAddress =
                  obj.same_address === 0 ? true : false;
                PersonDetails.BuildingNumberPresent = obj.address1;
                PersonDetails.StreetNamePresent = obj.address2;
                PersonDetails.AreaPresent = obj.address3;
                PersonDetails.LandmarkPresent = obj.landmark;
                PersonDetails.districtPresent = obj.district_name;
                PersonDetails.CityPresent = obj.city;
                PersonDetails.statePresent = obj.state_name;
                PersonDetails.PostPresent = obj.post_name;
                PersonDetails.TalukPresent = obj.taluk;
                PersonDetails.PincodePresent = obj.postal_code;
              } else {
                PersonDetails.SamePresentAddress =
                  obj.same_address === 0 ? true : false;
                PersonDetails.BuildingNumberPermanent = obj.address1;
                PersonDetails.StreetNamePermanent = obj.address2;
                PersonDetails.AreaPermanent = obj.address3;
                PersonDetails.LandmarkPermanent = obj.landmark;
                PersonDetails.districtPermanent = obj.district_name;
                PersonDetails.CityPermanent = obj.city;
                PersonDetails.statePermanent = obj.state_name;
                PersonDetails.PostPermanent = obj.post_name;
                PersonDetails.TalukPermanent = obj.taluk;
                PersonDetails.PincodePermanent = obj.postal_code;
              }
            });

            // firsttime load course when empty

            // console.log(userinfo,"koloo");

            // if(userinfo.Courses.length > 0){
            let el = userinfo.Courses[0]
              ? userinfo.Courses[0]
              : userinfo.Courses;

            PersonDetails.ExamPreviously =
              el.cleared_any_exam === 1 ? "Yes" : "NO";
            // PersonDetails.ExamDetailsRes =
            //   el.exam_title !==null && el.exam_title !==""
            //     ? el.exam_title.split(",")
            //     : "";
            PersonDetails.ExamWrite = el.exam_details;
            PersonDetails.workingRes = el.govt_emp === 1 ? "Yes" : "NO";
            PersonDetails.Designation = el.designation;
            PersonDetails.Department = el.department;
            PersonDetails.JoinGovt =
              el.govt_join_dt !== "" && el.govt_join_dt !== null
                ? dobCon(el.govt_join_dt)
                : "";
            PersonDetails.AIASAStudent = el.aiasa_student === 1 ? "Yes" : "NO";
            PersonDetails.Year = el.aiasa_year;
            PersonDetails.CategoryAias = el.aiasa_category;
            PersonDetails.CourseAias = el.aiasa_course;
            PersonDetails.IDNumber = el.aiasa_id_number;
            PersonDetails.IDPhoto = el.aiasa_id_path;
            PersonDetails.original_aiasa_id_path = el.original_aiasa_id_path;
            PersonDetails.admission_status = el.admission_status;
            PersonDetails.location =
              el.location_id && el.location_id !== null && el.location_id !== ""
                ? el.location_id
                : "2";
            PersonDetails.location_name =
              el.location && el.location !== null && el.location !== ""
                ? el.location
                : "Kanchipuram";
            PersonDetails.ExamName_passed = el.passed_exam_name;
            PersonDetails.ExamRegno = el.exam_reg_no;
            PersonDetails.writtenexammark = el.written_exam_mark;
            PersonDetails.Interviewmarks = el.interview_mark;
            PersonDetails.overallrank = el.overall_rank;
            PersonDetails.communalrank = el.communal_rank;
            PersonDetails.typistrank = el.rank_in_typist;
            PersonDetails.tamilrank = el.marks_in_tamil;
            PersonDetails.StenoRank = el.steno_rank;
            PersonDetails.PSTMRank = el.pstm_rank;
            PersonDetails.WindowRank = el.widow_rank;
            PersonDetails.DRank = el.disabled_rank;
            PersonDetails.markUpdatedMains = el.tot_marks_in_mains;
            PersonDetails.markUpdatedOral = el.tot_marks_in_oral_test;
            PersonDetails.MarksOne = el.marks_in_tier1;
            PersonDetails.MarksTwo = el.marks_in_tier2;
            PersonDetails.markPaperone = el.marks_in_paper1;
            PersonDetails.markPaperTwo = el.marks_in_paper2;
            PersonDetails.markpaperThree = el.marks_in_paper3;
            PersonDetails.markPaperFour = el.marks_in_paper4;
            PersonDetails.markPaperFive = el.marks_in_paper5;
            PersonDetails.markPaperSix = el.marks_in_paper6;
            PersonDetails.markPaperSeven = el.marks_in_paper7;
            PersonDetails.markPaperEight = el.marks_in_paper8;
            PersonDetails.markPaperNine = el.marks_in_paper9;

            PersonDetails.MarksTwo = el.marks_in_tier2;
            if (el.exam_title !== null && el.exam_title !== "") {
              var exam_title = el.exam_title.split(",");
              // Creating an array of objects in the desired format
              var result = exam_title.map(function (sub, index) {
                return {
                  id: index,
                  examvalue: sub,
                  error_name_exam: "",
                };
              });
              // setSelectedPrev(result);
              setExamDetailsExam(result);
            }
            if (
              el.preferred_post &&
              typeof el.preferred_post === "string" &&
              el.preferred_post.trim() !== ""
            ) {
              var prefre = el.preferred_post.split(",");
              var result = prefre.map(function (sub) {
                return {
                  label: sub.trim(),
                  value: sub.trim(),
                };
              });

              setSelectedPre(result);
            }
            if (
              el.preferred_depart &&
              typeof el.preferred_depart === "string" &&
              el.preferred_depart.trim() !== ""
            ) {
              var prefre = el.preferred_depart.split(",");
              var result = prefre.map(function (sub) {
                return {
                  label: sub.trim(),
                  value: sub.trim(),
                };
              });

              setSelectedPreDept(result);
            }
            if (userinfo.Courses.batch_details !== null) {
              PersonDetails.category = userinfo.Courses.batch_details.category;
              PersonDetails.CourseCate =
                userinfo.Courses.batch_details.course_id;
              PersonDetails.ExamName = userinfo.Courses.batch_details.course_id;
              PersonDetails.CouresName =
                userinfo.Courses.batch_details.course_name;
              PersonDetails.TeachMedium =
                userinfo.Courses.batch_details.batch_medium;
              setDropDown((prevState) => ({
                ...prevState,
                ["ExamName"]: {
                  value: userinfo.Courses.batch_details.course_name,
                  label: userinfo.Courses.batch_details.course_name,
                },
              }));
            } else {
              PersonDetails.category = userinfo.scp[0].category;
              PersonDetails.ExamName = "";
              PersonDetails.CourseCate = "";
              PersonDetails.CouresName = "";
              PersonDetails.TeachMedium = "";
            }

            const bath_id =
              userinfo.Courses.batch_details !== null
                ? {
                    batch_id: userinfo.Courses.batch_details.batch_id,
                  }
                : null;

            const admiId = userinfo.Courses[0]
              ? {
                  adId:
                    userinfo.Courses[0].batch_details !== null
                      ? userinfo.Courses[0].batch_details.ID
                      : null,
                  is_paid: userinfo.Courses[0].is_paid,
                  batch_id:
                    userinfo.Courses[0].batch_details !== null
                      ? userinfo.Courses[0].batch_details.batch_id
                      : null,
                }
              : {
                  adId:
                    userinfo.Courses.batch_details !== null
                      ? userinfo.Courses.batch_details.ID
                      : null,
                  is_paid: userinfo.Courses.is_paid,
                  batch_id:
                    userinfo.Courses.batch_details !== null
                      ? userinfo.Courses.batch_details.batch_id
                      : null,
                };

            setAdmissionID(admiId);

            // }

            setList(PersonDetails);
            // setReadSpecialpre([]);
            // console.log(PersonDetails, "PersonDetails");

            // setReadSpecialpre(SpecPrefrence);

            let edq = userinfo.edu
              .filter((item) => item.qualification_type === "EDQ")
              .map((obj) => {
                let name = "";
                if (obj.name === "sslc") {
                  name = "SSLC";
                } else if (obj.name === "hsc") {
                  name = "HSC";
                } else {
                  name = obj.name;
                }
                return name;
              });

            setEducationQualification(edq);
            let sslcCert = userinfo.edu
              .filter((item) => item.qualification_type === "EDQ")
              .map((el, index) => {
                if (el.name === "sslc") {
                  const matchingCertificate = {};
                  // if a matching certificate is found, update its properties
                  matchingCertificate.name = el.name;
                  matchingCertificate.SSLC_CNo = el.certificate_no;
                  matchingCertificate.SSLC_Year = el.year_of_passing;
                  matchingCertificate.SSLC_Marks = el.marks_percentage;
                  matchingCertificate.SSLC_Eboard = el.edu_board;
                  matchingCertificate.SSLC_Scl = el.institute;
                  matchingCertificate.SSLC_MI = el.edu_medium;
                  matchingCertificate.SSLC_Path = el.certificate_path;
                  matchingCertificate.original_file_path =
                    el.original_file_path;
                  setSslcCerficates((prevState) => {
                    return prevState.map((q) => {
                      if (q.name === el.name) {
                        // return { ...q, matchingCertificate };
                        return matchingCertificate;
                      }
                      return q;
                    });
                  });
                } else if (el.name === "hsc") {
                  const matchingCertificate = {};
                  // if a matching certificate is found, update its properties
                  matchingCertificate.name = el.name;
                  matchingCertificate.HSC_CNo = el.certificate_no;
                  matchingCertificate.HSC_Year = el.year_of_passing;
                  matchingCertificate.HSC_Marks = el.marks_percentage;
                  matchingCertificate.HSC_Ebaord = el.edu_board;
                  matchingCertificate.HSC_Scl = el.institute;
                  matchingCertificate.HSC_MI = el.edu_medium;
                  matchingCertificate.HSC_Path = el.certificate_path;
                  matchingCertificate.original_file_path =
                    el.original_file_path;
                  setSslcCerficates((prevState) => {
                    return prevState.map((q) => {
                      if (q.name === el.name) {
                        // return { ...q, matchingCertificate };
                        return matchingCertificate;
                      }
                      return q;
                    });
                  });
                } else if (el.name === "UG") {
                  const matchingCertificate = {};
                  if (el.type_of_degree === 1) {
                    setUGIsOther(true);
                    setMOption((prevState) => ({
                      ...prevState,
                      ["UGMajor"]: {
                        value: "Others",
                        label: "Others",
                      },
                    }));
                  } else {
                    setMOption((prevState) => ({
                      ...prevState,
                      ["UGMajor"]: {
                        value: el.major_subject,
                        label: el.major_subject,
                      },
                    }));
                  }
                  setUgOption((prevState) => ({
                    ...prevState,
                    ["UG"]: {
                      value: el.degree_name,
                      label: el.degree_name,
                    },
                  }));

                  // if a matching certificate is found, update its properties
                  matchingCertificate.name = el.name;
                  matchingCertificate.UGCertificateNumber = el.certificate_no;
                  matchingCertificate.UGYearpassing = el.year_of_passing;
                  matchingCertificate.UGDegree = el.degree_name;
                  matchingCertificate.UGMajor_Other = el.major_subject;
                  matchingCertificate.UGMajor =
                    el.type_of_degree === 1 ? "Others" : el.major_subject;
                  matchingCertificate.UGMarksPercentage = el.marks_percentage;
                  matchingCertificate.UGClassName = el.grade;
                  matchingCertificate.original_file_path =
                    el.original_file_path;
                  setMOption((prevState) => ({
                    ...prevState,
                    ["UGClassName"]: {
                      value: el.grade,
                      label: el.grade,
                    },
                  }));
                  matchingCertificate.UGNameInstitute = el.institute;
                  matchingCertificate.UG_Path = el.certificate_path;
                  setSslcCerficates((prevState) => {
                    return prevState.map((q) => {
                      if (q.name === el.name) {
                        // return { ...q, matchingCertificate };
                        return matchingCertificate;
                      }
                      return q;
                    });
                  });
                } else if (el.name === "PG") {
                  const matchingCertificate = {};
                  if (el.type_of_degree === 1) {
                    setPGIsOther(true);
                    setMOption((prevState) => ({
                      ...prevState,
                      ["PGMajor"]: {
                        value: "Others",
                        label: "Others",
                      },
                    }));
                  } else {
                    setMOption((prevState) => ({
                      ...prevState,
                      ["PGMajor"]: {
                        value: el.major_subject,
                        label: el.major_subject,
                      },
                    }));
                  }

                  setUgOption((prevState) => ({
                    ...prevState,
                    ["PG"]: {
                      value: el.degree_name,
                      label: el.degree_name,
                    },
                  }));

                  // if a matching certificate is found, update its properties
                  matchingCertificate.name = el.name;
                  matchingCertificate.PGCertificateNumber = el.certificate_no;
                  matchingCertificate.PGYearpassing = el.year_of_passing;
                  matchingCertificate.PGMarksPercentage = el.marks_percentage;
                  matchingCertificate.PGDegree = el.degree_name;
                  matchingCertificate.PGMajor_Other = el.major_subject;
                  matchingCertificate.PGMajor =
                    el.type_of_degree === 1 ? "Others" : el.major_subject;
                  matchingCertificate.PGClassName = el.grade;
                  matchingCertificate.original_file_path =
                    el.original_file_path;
                  setMOption((prevState) => ({
                    ...prevState,
                    ["PGClassName"]: {
                      value: el.grade,
                      label: el.grade,
                    },
                  }));
                  matchingCertificate.PGNameInstitute = el.institute;
                  matchingCertificate.PG_Path = el.certificate_path;
                  setSslcCerficates((prevState) => {
                    return prevState.map((q) => {
                      if (q.name === el.name) {
                        return matchingCertificate;
                      }
                      return q;
                    });
                  });
                } else if (el.name === "Integrated PG") {
                  const matchingCertificate = {};
                  if (el.type_of_degree === 1) {
                    setIPGIsOther(true);
                    setMOption((prevState) => ({
                      ...prevState,
                      ["IPMajor"]: {
                        value: "Others",
                        label: "Others",
                      },
                    }));
                  } else {
                    setMOption((prevState) => ({
                      ...prevState,
                      ["IPMajor"]: {
                        value: el.major_subject,
                        label: el.major_subject,
                      },
                    }));
                  }

                  setUgOption((prevState) => ({
                    ...prevState,
                    ["Integrated_PG"]: {
                      value: el.degree_name,
                      label: el.degree_name,
                    },
                  }));

                  // if a matching certificate is found, update its properties
                  matchingCertificate.name = el.name;
                  matchingCertificate.IPCertificateNumber = el.certificate_no;
                  matchingCertificate.IPYearpassing = el.year_of_passing;
                  matchingCertificate.IPDegree = el.degree_name;
                  matchingCertificate.IPMajor_Other = el.major_subject;
                  matchingCertificate.IPMajor =
                    el.type_of_degree === 1 ? "Others" : el.major_subject;
                  matchingCertificate.IPMarksPercentage = el.marks_percentage;
                  matchingCertificate.IPClassName = el.grade;
                  matchingCertificate.original_file_path =
                    el.original_file_path;
                  setMOption((prevState) => ({
                    ...prevState,
                    ["IPClassName"]: {
                      value: el.grade,
                      label: el.grade,
                    },
                  }));
                  matchingCertificate.IPNameInstitute = el.institute;
                  matchingCertificate.IP_Path = el.certificate_path;
                  setSslcCerficates((prevState) => {
                    return prevState.map((q) => {
                      if (q.name === el.name) {
                        // return { ...q, matchingCertificate };
                        return matchingCertificate;
                      }
                      return q;
                    });
                  });
                } else if (el.name === "Diploma") {
                  const matchingCertificate = {};
                  if (el.type_of_degree === 1) {
                    setDipIsOther(true);
                    setMOption((prevState) => ({
                      ...prevState,
                      ["DMajor"]: {
                        value: "Others",
                        label: "Others",
                      },
                    }));
                  } else {
                    setMOption((prevState) => ({
                      ...prevState,
                      ["DMajor"]: {
                        value: el.major_subject,
                        label: el.major_subject,
                      },
                    }));
                  }
                  setUgOption((prevState) => ({
                    ...prevState,
                    ["Diploma"]: {
                      value: el.degree_name,
                      label: el.degree_name,
                    },
                  }));

                  // if a matching certificate is found, update its properties
                  matchingCertificate.name = el.name;
                  matchingCertificate.DCertificateNumber = el.certificate_no;
                  matchingCertificate.DYearpassing = el.year_of_passing;
                  matchingCertificate.DDegree = el.degree_name;
                  matchingCertificate.DMajor_Other = el.major_subject;
                  matchingCertificate.DMajor =
                    el.type_of_degree === 1 ? "Others" : el.major_subject;
                  matchingCertificate.DMarksPercentage = el.marks_percentage;
                  matchingCertificate.DClassName = el.grade;
                  matchingCertificate.original_file_path =
                    el.original_file_path;
                  setMOption((prevState) => ({
                    ...prevState,
                    ["DClassName"]: {
                      value: el.grade,
                      label: el.grade,
                    },
                  }));
                  matchingCertificate.DNameInstitute = el.institute;
                  matchingCertificate.D_Path = el.certificate_path;
                  setSslcCerficates((prevState) => {
                    return prevState.map((q) => {
                      if (q.name === el.name) {
                        // return { ...q, matchingCertificate };
                        return matchingCertificate;
                      }
                      return q;
                    });
                  });
                } else if (el.name === "Professional Courses") {
                  const matchingCertificate = {};

                  if (el.type_of_degree === 1) {
                    setPCIsOther(true);
                    setMOption((prevState) => ({
                      ...prevState,
                      ["PCMajor"]: {
                        value: "Others",
                        label: "Others",
                      },
                    }));
                  } else {
                    setMOption((prevState) => ({
                      ...prevState,
                      ["PCMajor"]: {
                        value: el.major_subject,
                        label: el.major_subject,
                      },
                    }));
                  }

                  setUgOption((prevState) => ({
                    ...prevState,
                    ["Professional_Courses"]: {
                      value: el.degree_name,
                      label: el.degree_name,
                    },
                  }));

                  // if a matching certificate is found, update its properties
                  matchingCertificate.name = el.name;
                  matchingCertificate.PCCertificateNumber = el.certificate_no;
                  matchingCertificate.PCYearpassing = el.year_of_passing;
                  matchingCertificate.PCDegree = el.degree_name;
                  matchingCertificate.PCMajor_Other = el.major_subject;
                  matchingCertificate.PCMajor =
                    el.type_of_degree === 1 ? "Others" : el.major_subject;
                  matchingCertificate.PCMarksPercentage = el.marks_percentage;
                  matchingCertificate.PCClassName = el.grade;
                  matchingCertificate.original_file_path =
                    el.original_file_path;
                  setMOption((prevState) => ({
                    ...prevState,
                    ["PCClassName"]: {
                      value: el.grade,
                      label: el.grade,
                    },
                  }));
                  matchingCertificate.PCNameInstitute = el.institute;
                  matchingCertificate.PC_Path = el.certificate_path;
                  setSslcCerficates((prevState) => {
                    return prevState.map((q) => {
                      if (q.name === el.name) {
                        // return { ...q, matchingCertificate };
                        return matchingCertificate;
                      }
                      return q;
                    });
                  });
                } else if (el.name === "Others") {
                  const matchingCertificate = {};

                  // if a matching certificate is found, update its properties
                  matchingCertificate.name = el.name;
                  matchingCertificate.OtCertificateNumber = el.certificate_no;
                  matchingCertificate.OtYearpassing = el.year_of_passing;
                  matchingCertificate.OtDegree = el.degree_name;
                  matchingCertificate.OtMajor = el.major_subject;
                  matchingCertificate.OtMarksPercentage = el.marks_percentage;
                  matchingCertificate.OtClassName = el.grade;
                  matchingCertificate.original_file_path =
                    el.original_file_path;
                  setMOption((prevState) => ({
                    ...prevState,
                    ["OtClassName"]: {
                      value: el.grade,
                      label: el.grade,
                    },
                  }));
                  matchingCertificate.OtNameInstitute = el.institute;
                  matchingCertificate.Ot_Path = el.certificate_path;
                  setSslcCerficates((prevState) => {
                    return prevState.map((q) => {
                      if (q.name === el.name) {
                        // return { ...q, matchingCertificate };
                        return matchingCertificate;
                      }
                      return q;
                    });
                  });
                }
              });
            let teach = userinfo.edu
              .filter((item) => item.qualification_type === "THQ")
              .map((el, index) => {
                const Teachqu = {};

                Teachqu.id = index === 0 ? 1 : index + 1;
                Teachqu.name = el.name;
                Teachqu.level = el.level;
                Teachqu.lang = el.edu_medium;
                Teachqu.Cnum = el.certificate_no;
                Teachqu.grade = el.grade;
                Teachqu.year =
                  el.year_of_passing !== "" && el.year_of_passing !== null
                    ? el.year_of_passing
                    : "";
                Teachqu.file = el.certificate_path;
                Teachqu.error_name = "";
                Teachqu.error_certificate_no = "";
                Teachqu.error_year_of_passing = "";
                Teachqu.error_level = "";
                Teachqu.error_grade = "";
                Teachqu.error_certificate_path = "";
                Teachqu.original_file_path = el.original_file_path;
                return Teachqu;
              });
            setTechQualification(teach);
            setReadbatch([]);
            setBatchDetails([]);
            // console.log(userinfo.Courses, "userinfo.Courses");

            const batchDe =
              userinfo.Courses.batch_details !== null
                ? {
                    batch_id: userinfo.Courses.batch_details.batch_id,
                    id: userinfo.Courses.batch_details.batch_id,
                    type: 2,
                    img_url: userinfo.Courses.batch_details.img_url,
                    course_name: userinfo.Courses.batch_details.course_name,
                    course_id: userinfo.Courses.batch_details.course_id,
                    batch_details: userinfo.Courses.batch_details.batch_details,
                    class_mode: userinfo.Courses.batch_details.class_mode,
                    start_on: dobCon(userinfo.Courses.batch_details.start_on),
                    duration: userinfo.Courses.batch_details.duration,
                    act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                    offer_spl: userinfo.Courses.batch_details.offer_spl,
                    dis_amt: userinfo.Courses.batch_details.dis_amt,
                    medium: userinfo.Courses.batch_details.medium,
                    is_selected: userinfo.Courses.batch_details.is_selected,
                    panel_details:
                      Object.keys(userinfo.Courses.batch_details.panel_details)
                        .length > 0
                        ? userinfo.Courses.batch_details.panel_details
                            .panel_list
                        : {},
                    exam_fees:
                      Object.keys(userinfo.Courses.batch_details.panel_details)
                        .length > 0
                        ? userinfo.Courses.batch_details.panel_details.exam_fees
                        : {},
                    test_img_url: userinfo.Courses.batch_details.test_img_url,
                  }
                : null;
            let PaneldetailsObj = [];
            batchDe.panel_details.map((el) => {
              PaneldetailsObj.push({
                pk: el.panel_pk,
                batch_id: el.batch_id,
                batch_type: el.batch_type,
                panel_name: el.panel_name,
                available_dates: el.available_dates,
                event_time: el.event_time,
                is_selected_panel: el.is_selected_panel == 1 ? true : false,
              });
            });
            batchDe.panel_details = PaneldetailsObj;
            // console.log(batchDe, "batchDe");
            setReadbatch([batchDe]);
            setBatchDetails([batchDe]);
          })
          .catch((error) => {
            return error;
          });
      }
    }
  }, []);

  useEffect(() => {
    if (step === 2) {
      // Initialize intl-tel-input on component mount
      const iti = intlTelInput(txtAdPno.current, {
        // Add options as needed, refer to the intl-tel-input documentation
        initialCountry: "in",
        separateDialCode: true,
      });
      // You can listen for changes if needed
      txtAdPno.current.addEventListener("countrychange", () => {
        // console.log('Country changed:', iti.getSelectedCountryData());
        const selectedCountryData = iti.getSelectedCountryData();
        setList((prevFields) => ({
          ...prevFields,
          MobileCode: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
        }));
      });

      const iti_wha = intlTelInput(txtAdWno.current, {
        // Add options as needed, refer to the intl-tel-input documentation
        initialCountry: "in",
        separateDialCode: true,
      });
      // You can listen for changes if needed
      txtAdWno.current.addEventListener("countrychange", () => {
        // console.log('Country changed:', iti_wha.getSelectedCountryData());
        const selectedCountryData = iti_wha.getSelectedCountryData();
        setList((prevFields) => ({
          ...prevFields,
          WhatsAppNumberCode: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
        }));
      });

      const iti_whaParent = intlTelInput(txtAdFpo.current, {
        // Add options as needed, refer to the intl-tel-input documentation
        initialCountry: "in",
        separateDialCode: true,
      });
      // You can listen for changes if needed
      txtAdFpo.current.addEventListener("countrychange", () => {
        // console.log('Country changed:', iti_whaParent.getSelectedCountryData());
        const selectedCountryData = iti_whaParent.getSelectedCountryData();
        setList((prevFields) => ({
          ...prevFields,
          ParentMobileNoCode: `+${selectedCountryData.dialCode}`, // Update phone number with the new country code
        }));
      });

      // Clean up on component unmount
      return () => {
        iti.destroy();
        iti_wha.destroy();
        iti_whaParent.destroy();
      };
    }
  }, [step]);

  //date and time convert to normal data
  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getDisocount = (batchId) => {
    // alert(batchId);
    const df = {
      user_id: userid,
      // type: 2,
      batch_id: batchId,
    };
    AxiosInstance.post("api/all/getOldStudDisc", df)
      .then((res) => {
        // console.log(res, "discount batch");
        if (res.data.status === true) {
          let discount = res.data.old_stud_disc;
          // console.log(discount, "discount");
          setDis(discount);
        } else {
          setDis(null); // or set a default value for no discount
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    AxiosInstance.post("api/branch/list")
      .then((res) => {
        // console.log(res, "locations");
        if (res.data.data.length > 0) {
          let locations = res.data.data.map((item, index) => (
            <option key={index} id={item.name} value={item.id}>
              {item.name}
            </option>
          ));
          setLocations(locations);
        } else {
          let locations = <option value="select"> Please select </option>;
          setLocations(locations);
        }
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDesignation/2")
      .then((res) => {
        // const designation = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const designation = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        // console.log(designation, "sfsdg");
        setDesignation(designation);
      })
      .catch((error) => {
        return error;
      });
  }, []);
  // based on batch id i call prefreed department   AIASA-000114  - v1 issues update by lalitha
  useEffect(() => {
    AxiosInstance.post("api/all/getPreferredDept")
      .then((res) => {
        const dept = res.data.data.map((item, index) => ({
          value: item.department,
          label: item.department,
        }));
        setDept(dept);
      })
      .catch((error) => {
        return error;
      });
  }, [batchId]);

  // get course list
  useEffect(() => {
    if (list.SPCcategory !== "" && list.category !== "") {
      AxiosInstance.post("api/all/getPassedExamName", {
        category: list.SPCcategory,
        type: 2,
      })
        .then((res) => {
          // console.log(res.data.data,"zcxxzczxv");
          const coursesCate = res.data.data.map((item, index) => ({
            value: item.id,
            label: item.exam_name,
          }));
          setCoursesCate(coursesCate);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.SPCcategory, list.category]);
  // get batch details
  useEffect(() => {
    if (list.category !== "" && list.ExamName !== "") {
      AxiosInstance.post("api/all/getCourseBatchesList/2", {
        medium: "",
        class_mode: "",
        location: list.location !== "" ? list.location : "",
        // "location":"",
        category_course_id: "",
        passed_exam_name: parseInt(list.ExamName),
        batch_type: "",
        preferred_dept:
          selectedPreDept.length > 0
            ? selectedPreDept.map((el) => el.label)
            : "",
        subject: "",
      })
        .then((res) => {
          let batch = res.data.data;
          if (res.data.data.length > 0) {
            // console.log(readbatch, "readbatch");
            if (readbatch.length > 0 && readbatch[0] !== null) {
              setBatchDetails((batch) =>
                batch.map((batchObj) => {
                  const matchingReadBatch = readbatch.find(
                    (readBatchObj) => readBatchObj.id === batchObj.batch_id
                  );
                  if (matchingReadBatch) {
                    // Update is_selected for the batch
                    batchObj.is_selected = matchingReadBatch.is_selected;
                    // Update is_selected_panel for panel details based on PKs
                    batchObj.panel_details =
                      batchObj.panel_details.length === 0
                        ? batchObj.panel_details // Return the same panel_details if its length is 0
                        : batchObj.panel_details.map((panel) => {
                            const matchingPanelReadBatch =
                              matchingReadBatch.panel_details.find(
                                (readPanel) => readPanel.pk === panel.pk
                              );
                            if (matchingPanelReadBatch) {
                              panel.is_selected_panel =
                                matchingPanelReadBatch.is_selected_panel;
                            }
                            return panel;
                          });
                  }
                  return batchObj;
                })
              );
            } else {
              // console.log(batch,"sdhsmdj");
              setBatchDetails(batch);
            }
          } else {
            // console.log(batch,"sdsf");
            setBatchDetails(batch);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.CourseCate, list.ExamName, list.location, selectedPreDept]);
  //Aiasa student (yes | no ) coures
  useEffect(() => {
    if (list.CategoryAias) {
      AxiosInstance.get(`api/all/getCourses/${list.CategoryAias}/2`)
        .then((res) => {
          const courseAias = res.data.data.map((item, index) => ({
            value: item.name,
            label: item.name,
          }));
          setCourseAias(courseAias);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.CategoryAias]);
  //when pincode enter city dropdown , state , district  fill with ui
  useEffect(() => {
    if (list.PincodePresent !== "") {
      AxiosInstance.get(`api/all/getAddress/${list.PincodePresent}`)
        .then((res) => {
          let code = res.data.data;
          // const city = code.map((item, index) => (
          //   <option key={index} value={item.city}>
          //     {item.city}
          //   </option>
          // ));
          const city = code.map((item, index) => ({
            value: item.city,
            label: item.city,
          }));
          setCity(city);
          list.statePresent = code[0].state;
          list.districtPresent = code[0].district;
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.PincodePresent]);
  useEffect(() => {
    if (list.PincodePermanent !== "") {
      AxiosInstance.get(`api/all/getAddress/${list.PincodePermanent}`)
        .then((res) => {
          let code = res.data.data;
          const city = code.map((item, index) => ({
            value: item.city,
            label: item.city,
          }));
          setCity(city);
          list.statePermanent = code[0].state;
          list.districtPermanent = code[0].district;
        })
        .catch((error) => {
          return error;
        });
    }
  }, [list.PincodePermanent]);
  //when pincode enter city dropdown , state , district  fill with ui -- end

  useEffect(() => {
    if (sslcCerficates[2].UGDegree !== "") {
      const dat = {
        degree_name: sslcCerficates[2].UGDegree,
        type: "UG",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, dat)
        .then((res) => {
          if (res.data.data.length > 0) {
            // in response duplicate entry come means its showing extra data in next api call option
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const majorOptions = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const majorOptions = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            // const additionalOptions = [
            //   { value: "Others", label: "Others" },
            // ];
            // // Append the additional objects to the majorOptions array
            // const updatedMajorOptions = majorOptions.concat(additionalOptions);
            // // Set the state with the updated array
            setMajor(majorOptions);
          } else {
            setMajor(
              []
              //   [
              //   <option key="select" disabled value="select">
              //     Please select
              //   </option>,
              // ]
            );
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[2].UGDegree]);

  useEffect(() => {
    if (sslcCerficates[3].PGDegree !== "") {
      const dats = {
        degree_name: sslcCerficates[3].PGDegree,
        type: "PG",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, dats)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const pgMajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const pgMajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setPgMajor(pgMajor);
          } else {
            setPgMajor(
              []
              //   [
              //   <option key="select" disabled value="select">
              //     Please select
              //   </option>,
              // ]
            );
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[3].PGDegree]);

  useEffect(() => {
    if (sslcCerficates[4].IPDegree !== "") {
      const datss = {
        degree_name: sslcCerficates[4].IPDegree,
        type: "IPG",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, datss)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const IpMajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const IpMajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setIpMajor(IpMajor);
          } else {
            setIpMajor(
              []
              //   [
              //   <option key="select" disabled value="select">
              //     Please select
              //   </option>,
              // ]
            );
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[4].IPDegree]);

  useEffect(() => {
    if (sslcCerficates[5].DDegree !== "") {
      const datsss = {
        degree_name: sslcCerficates[5].DDegree,
        type: "diploma",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, datsss)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const dmajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const dmajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setDmajor(dmajor);
          } else {
            setDmajor(
              []
              //   [
              //   <option key="select" disabled value="select">
              //     Please select
              //   </option>,
              // ]
            );
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[5].DDegree]);

  useEffect(() => {
    if (sslcCerficates[6].PCDegree !== "") {
      const datssss = {
        degree_name: sslcCerficates[6].PCDegree,
        type: "PC",
      };
      AxiosInstance.post(`api/all/getDegreeMajor/`, datssss)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            // const pcMajor = uniqueMajorData.map((item) => (
            //   <option key={item.name} value={item.name}>
            //     {item.name}
            //   </option>
            // ));
            const pcMajor = uniqueMajorData.map((item, index) => ({
              value: item.name,
              label: item.name,
            }));
            setPcmajor(pcMajor);
          } else {
            setPcmajor(
              []
              //   [
              //   <option key="select" disabled value="select">
              //     Please select
              //   </option>,
              // ]
            );
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[6].PCDegree]);

  useEffect(() => {
    if (sslcCerficates[7].OtDegree !== "") {
      AxiosInstance.get(`api/all/getDegreeMajor/${sslcCerficates[7].OtDegree}`)
        .then((res) => {
          if (res.data.data.length > 0) {
            const uniqueMajorData = res.data.data.filter(
              (item, index, self) =>
                self.findIndex((i) => i.name === item.name) === index
            );
            const OtMajor = uniqueMajorData.map((item) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ));
            setOTmajor(OtMajor);
          } else {
            setOTmajor([
              <option key="select" disabled value="select">
                Please select
              </option>,
            ]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [sslcCerficates[7].OtDegree]);

  //major,designation , department , exam details , degree  dropdown
  useEffect(() => {
    AxiosInstance.get("api/all/getDesignation/2")
      .then((res) => {
        const designations = res.data.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item, index) => ({
            value: item.name,
            label: item.name,
          }));
        setDesignations(designations);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDepartment/2")
      .then((res) => {
        const department = res.data.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item, index) => ({
            value: item.name,
            label: item.name,
          }));
        setDepartment(department);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.post("api/all/getPreviousExam")
      .then((res) => {
        const previousexam = res.data.data.map((item, index) => (
          // { key: index, value: item.name }
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setPreviousexam(previousexam);

        const examination = res.data.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));

        setExamination(examination);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/UG")
      .then((res) => {
        // const ugDegree = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        // setUGDegree(ugDegree);

        const ugDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));

        // const ugDegree = res.data.data.map((item) => item.name);
        setUGDegree(ugDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/PG")
      .then((res) => {
        // const pgDegree = res.data.data.map((item, index) => (
        //   <option key={index} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const pgDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setPGDegree(pgDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/IPG")
      .then((res) => {
        // const ipgDegree = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const ipgDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setIPGDegree(ipgDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/diploma")
      .then((res) => {
        // const diplomaDegree = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const diplomaDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setDiplomaDegree(diplomaDegree);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/all/getDegreeNames/PC")
      .then((res) => {
        // const pcDegree = res.data.data.map((item) => (
        //   <option key={item.name} value={item.name}>
        //     {item.name}
        //   </option>
        // ));
        const pcDegree = res.data.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setPCDegree(pcDegree);
      })
      .catch((error) => {
        return error;
      });

    // console.log(readSpecialpre,"sjdhsjfh");
  }, []);

  const handleExamPrev = (e, index) => {
    const { name, value } = e.target;
    // console.log(selectedPrev , name);
    // const objectIndex = selectedPrev.findIndex(obj => obj.examname === name);
    // // Create a copy of the selectedPrev state
    // const updatedSelectedPrev = [...selectedPrev];
    // if (objectIndex !== -1) {
    //   // If an object with the same 'examname' exists, update it
    //   updatedSelectedPrev[objectIndex] = { examname: name, examvalue: value };
    // } else {
    //   // If no matching object exists, add a new object to the array
    //   updatedSelectedPrev.push({ examname: name, examvalue: value });
    // }
    // // Set the updated state
    // setSelectedPrev(updatedSelectedPrev);
    // setListErrors({
    //   ...listErrors,
    //   ExamDetailsRes_0: "",
    // });
    setExamDetailsExam((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = { examvalue: value, error_name_exam: "" }; // Update other properties if needed
      return updatedDetails;
    });
  };
  const specialAdmissionHandleChanges = (option, id, spename) => {
    // console.log(option,spename);
    setSpecialAdmissionPre((prevState) => {
      // Check if name already exists in prevState
      const isNameExists = prevState.some(
        (q) => q.id !== id && q.name === option.value
      );
      if (isNameExists) {
        alert("Name already exists");
        return prevState;
      }

      // Update the name if it doesn't already exist
      return prevState.map((q) => {
        if (q.id === id) {
          return { ...q, ["name"]: option.value };
        }
        return q;
      });
    });
  };
  //special preference handle change
  const specialAdmissionHandleChange = (e, id, spename) => {
    const { name, value, files, checked } = e.target;
    if (name === "file" && files && files.length > 0) {
      const pref_path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("pref_path", pref_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      file: res.data.path,
                      original_pref_path: res.data.originalname,
                    };
                  }

                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_sslc" && files && files.length > 0) {
      // console.log("sdsf");
      const scp_path_sslc = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_sslc", scp_path_sslc);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_sslc: res.data.path,
                      original_path_sslc: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_hsc" && files && files.length > 0) {
      const scp_path_hsc = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_hsc", scp_path_hsc);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_hsc: res.data.path,
                      original_path_hsc: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_ug" && files && files.length > 0) {
      const scp_path_ug = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_ug", scp_path_ug);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_ug: res.data.path,
                      original_path_ug: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "path_pg" && files && files.length > 0) {
      const scp_path_pg = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("scp_path_pg", scp_path_pg);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSpecialAdmissionPre((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      path_pg: res.data.path,
                      original_path_pg: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2 mb");
        }
      }
    } else if (name === "pstm_type") {
      // console.log(value, name , checked);
      if (checked) {
        const psctm = [];
        setSpecialAdmissionPre((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              // console.log(value, "0 type");
              if (value == 0) {
                psctm.push = value;
                // console.log("0 type", psctm);
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: [0, ...q.pstm_type],
                  error_path_sslc: "",
                };
              } else if (value == 1) {
                if (q.pstm_type.includes(0)) {
                  psctm.push = value;
                  return {
                    ...q,
                    [name]: psctm,
                    pstm_type: [1, ...q.pstm_type],
                    error_path_sslc: "",
                  };
                }
                return {
                  ...q,
                  [name]: psctm,
                  error_path_sslc: "Please select sslc",
                };
              } else if (value == 2) {
                if (q.pstm_type.includes(0, 1)) {
                  psctm.push = value;
                  return {
                    ...q,
                    [name]: psctm,
                    pstm_type: [2, ...q.pstm_type],
                    error_path_sslc: "",
                  };
                }
                return {
                  ...q,
                  [name]: psctm,
                  error_path_sslc: "Please select sslc , hsc",
                };
              } else if (value == 3) {
                // console.log(q.pstm_type,"q.pstm_type");
                const valueTocheck = [0, 1, 2];
                if (
                  valueTocheck.every((values) => q.pstm_type.includes(values))
                ) {
                  psctm.push = value;
                  return {
                    ...q,
                    [name]: psctm,
                    pstm_type: [3, ...q.pstm_type],
                    error_path_sslc: "",
                  };
                }
                return {
                  ...q,
                  [name]: psctm,
                  error_path_sslc: "Please select sslc , hsc , ug",
                };
              }
            }
            return q;
          });
        });
      } else {
        const psctm = [];
        psctm.push = value;
        setSpecialAdmissionPre((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              if (value == 0) {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: [],
                };
              } else if (value == 1) {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: q.pstm_type.filter(
                    (item) => item !== 2 && item !== 1 && item !== 3
                  ),
                };
              } else if (value == 2) {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: q.pstm_type.filter(
                    (item) => item !== 2 && item !== 3
                  ),
                };
              } else {
                return {
                  ...q,
                  [name]: psctm,
                  pstm_type: q.pstm_type.filter(
                    (item) => item !== parseInt(value)
                  ),
                };
              }
            }
            return q;
          });
        });
      }
    } else {
      setSpecialAdmissionPre((prevState) => {
        // Check if name already exists in prevState
        const isNameExists = prevState.some(
          (q) => q.id !== id && q.name === value
        );
        if (isNameExists) {
          alert("Name already exists");
          return prevState;
        }
        // Update the name if it doesn't already exist
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value };
          }
          return q;
        });
      });
      // setListErrors({
      //   ...listErrors,aaf
      //   ["spc"]:"",
      // });
    }
  };
  //special preference delete function
  const DeleteAdmissionspre = (id, name) => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        AxiosInstance.post("api/course/admission/delete/scp", {
          user_id: userid,
          name: name,
          id: id,
        })
          .then((res) => {
            if (res.status === 200) {
              setSpecialAdmissionPre((prevState) =>
                prevState
                  .filter((item) => item.id !== id)
                  .map((item, index) => ({ ...item, id: index + 1 }))
              );

              // Swal.fire({
              //   title: "success",
              //   text: "Deleted Successfully",
              //   icon: "success",
              //   confirmButtonText: "Ok",
              // }).then((result) => {
              //   if (result.isConfirmed) {

              //   }
              // });
            }
            // console.log(res);
          })
          .catch((error) => {
            return error;
          });
      }
    } else {
      console.log("User Id cannot avaliable in session storage");
    }
  };
  //special preference Add function
  const AddPrefrence = () => {
    if (specialPrefrence.length > specialAdmissionPre.length) {
      setSpecialAdmissionPre((prevState) => [
        ...prevState,
        {
          id: prevState.length > 0 ? prevState.length + 1 : 1,
          name: "",
          file: "",
          path_sslc: "",
          path_hsc: "",
          path_ug: "",
          path_pg: "",
          original_pref_path: null,
          original_path_sslc: null,
          original_path_hsc: null,
          original_path_ug: null,
          original_path_pg: null,
          error_name: "",
          error_path_sslc: "",
          error_path_hsc: "",
          error_path_ug: "",
          error_path_pg: "",
          pstm_type: [],
        },
      ]);
      setListErrors({
        ...listErrors,
        ["SPCcategory"]: "",
      });
    } else {
      if (list.SPCcategory) {
        Swal.fire({
          title: "Warning",
          text: "you reach more Special Preferences",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        setListErrors({
          ...listErrors,
          ["SPCcategory"]: "Please select at least one category",
        });
      }
    }
  };
  //dob convert to age function
  const DobCovertAge = (dob) => {
    var ageInYears = moment().diff(dob, "years", false);

    // const dobDate = new Date(dob);
    // const now = new Date();
    // const ageInMs = now - dobDate;
    // const ageInYears = Math.floor(ageInMs / 31536000000); // 1 year ≈ 31536000000 ms

    return ageInYears;
  };

  //handlechange event

  const handleChangeDate = (date, name) => {
    const dateDOB = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateDOB);
    const age = DobCovertAge(date);
    if (age >= 18 && age <= 60) {
      setList({
        ...list,
        [name]: dateDOB,
        ["age"]: age,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
        ["age"]: "",
      });
    } else {
      const err =
        "Please select a DOB for autofill with an age between 18 and 60 years";
      setList({
        ...list,
        age: "",
      });
      setListErrors({
        ...listErrors,
        DOB: err,
      });
    }
  };
  //batch details handle change and store state
  const handleBatchDetails = (id, panelId, e) => {
    // console.log(panelId,id,batchDetails);
    const { checked } = e.target;
    let showAlert = false; // Flag to track if the alert has been shown

    // Check if any batch is already selected
    const isAnyBatchSelected = batchDetails.some((batch) => batch.is_selected);

    setBatchDetails((prevState) => {
      const updatedBatchDetails = prevState.map((batch) => {
        if (batch.id == id) {
          // console.log("dfsf");
          const updatedExamDetails = batch.panel_details.map((pan) => {
            if (pan.pk == panelId) {
              // console.log(pan,"pan");
              return { ...pan, is_selected_panel: !pan.is_selected_panel };
            }
            // console.log(pan,"cxcxc");
            return pan;
          });
          const isBatchSelected = updatedExamDetails.some(
            (aa) => aa.is_selected_panel
          );
          return {
            ...batch,
            is_selected: isBatchSelected,
            panel_details: updatedExamDetails,
          };
        }
        return batch;
      });

      if (isAnyBatchSelected && checked) {
        showAlert = true;
      }

      if (showAlert) {
        // alert("Already batch selecte");
        // e.target.checked = false; // Uncheck the checkbox
        // e.target.disabled = true; // Disable the checkbox
        return prevState; // Return the previous state without any changes
      }
      return updatedBatchDetails;
    });

    setListErrors({
      ...listErrors,
      batchDetails: "",
    });
  };
  // handlesubmit function
  const handleSubmit = (
    batchId,
    oldstudentAmount,
    damount,
    specialoffer,
    AmountPay,
    cname,
    cId
  ) => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        const CouresAmount = AmountPay;
        const CouresName = cname;
        const gstrate = 18;
        const soffer = specialoffer;
        const discountAmount = damount;
        const coures_id = cId;
        const batch_id = batchId;
        const receipt = "Order_" + Math.floor(Math.random() * 900 + 100);
        //  console.log(CouresAmount,"CouresAmount");
        AxiosInstance.post("api/payemnt/create_order", {
          amount: Math.round(CouresAmount) * 100,
          currency: "INR",
          receipt: receipt,
        })
          .then((res) => {
            if (res) {
              const { data } = res;
              const options = {
                key: PaymentSKey,
                amount: data.amount,
                currency: "INR",
                name: CouresName,
                prefill: {
                  name:
                    list.FirstName != null && list.FirstName !== ""
                      ? list.FirstName
                      : "Anna iasa",
                  email:
                    list.email != null && list.email !== ""
                      ? list.email
                      : "sample@annaiasacademy.com",
                  contact:
                    list.Mobile != null && list.Mobile !== ""
                      ? list.Mobile
                      : "1234567890",
                },
                description: "For Coures Admission ",
                image: "B",
                order_id: data.id,
                handler: function (response) {
                  // console.log(response);
                  if (response) {
                    // console.log(admissionID);
                    const dat = {
                      step: 6,
                      type: 2,
                      category: list.SPCcategory
                        ? list.SPCcategory
                        : list.category
                        ? list.category
                        : "",
                      location_id: parseInt(list.location),
                      Isverify: list.Verify || false,
                      AdmissionID: admissionID.adId ? admissionID.adId : 0,
                      CouresName: CouresName,
                      discountAmount: discountAmount,
                      gst: gstrate,
                      SpecialOffer: soffer,
                      spc_offer_type: 0, // 0 means Nil
                      batch_id: batch_id,
                      coures_id: coures_id,
                      user_id: userid,
                      old_stud_disc_perc: dis,
                      old_stud_disc: oldstudentAmount,
                      iscoupon: couponApplied.status === true ? true : false,
                      couponAmount:
                        couponApplied.amount !== "" ? couponApplied.amount : 0,
                      couponcode: couponApplied.coupon_code,
                      Netamount: data.amount / 100,
                      status: data.status,
                      transaction_id: response.razorpay_payment_id,
                      razorpay_order_id: response.razorpay_order_id,
                      razorpay_signature: response.razorpay_signature,
                      created_at: moment
                        .unix(data.created_at)
                        .format("YYYY-MM-DD HH:mm:ss"),
                    };
                    // console.log(dat, "response");
                    AxiosInstance.post("api/course/admission/register", dat)
                      .then((res) => {
                        // console.log(res);
                        if (res.data.status === true) {
                          const message = res.data.data.batch_message;
                          setBatchMessage([message]);
                          const admId = res.data.data.id;
                          // console.log(res.data.data.transaction_id, "step6");
                          AxiosInstance.get(
                            `api/payments/${res.data.data.transaction_id}`
                          )
                            .then((res) => {
                              // console.log(res);
                              if (res.status === 200) {
                                const payInfo = res.data;
                                const paymentHistory = {
                                  id: payInfo.id,
                                  admission_history_id: admId,
                                  entity: payInfo.entity,
                                  amount: payInfo.amount / 100,
                                  currency: payInfo.currency,
                                  status: payInfo.status,
                                  order_id: payInfo.order_id,
                                  invoice_id: payInfo.invoice_id,
                                  international: payInfo.international,
                                  method: payInfo.method,
                                  amount_refunded: payInfo.amount_refunded,
                                  refund_status: payInfo.refund_status,
                                  captured: payInfo.captured,
                                  description: payInfo.description,
                                  card_id: payInfo.card_id,
                                  bank: payInfo.bank,
                                  wallet: payInfo.wallet,
                                  vpa: payInfo.vpa,
                                  email: payInfo.email,
                                  contact: payInfo.contact,
                                  notes:
                                    payInfo.notes.length > 0
                                      ? payInfo.notes.toString()
                                      : null,
                                  fee: payInfo.fee,
                                  tax: payInfo.tax,
                                  error_code: payInfo.error_code,
                                  error_description: payInfo.error_description,
                                  error_source: payInfo.error_source,
                                  error_step: payInfo.error_step,
                                  error_reason: payInfo.error_reason,
                                  acquirer_data: {
                                    rrn: payInfo.acquirer_data.rrn,
                                    upi_transaction_id:
                                      payInfo.acquirer_data.upi_transaction_id,
                                  },
                                  created_at: moment
                                    .unix(payInfo.created_at)
                                    .format("YYYY-MM-DD hh:mm:ss"),
                                  created_by: userid,
                                  payment_type: 1, //1 → online ,0 → offline // we use online now
                                };
                                // console.log(paymentHistory, "paymentHistory");
                                AxiosInstance.post(
                                  "api/course/admission/paid_info",
                                  paymentHistory
                                )
                                  .then((res) => {
                                    if (res.data.status === true) {
                                      const resuserid = userid;
                                      const email = list.emailaddress;

                                      const html_text = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <link rel="stylesheet" href=" https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
</head>
<style>
    @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

    body {
        background-color: rgb(197, 191, 191);
    }

    .contain {
        top: 10%;
        left: 17%;
        background-color: #FFFFFF !important;
        width: 700px;
        height: 1035px;
        position: absolute;
        /* text-align: center; */
    }

    .contain header {
        position: absolute;
        top: 29px;
        left: 36%;
        width: 200px;
        height: 87px;

        text-align: center;
        /* font: normal normal bold 28px/24px Roboto; */
        letter-spacing: 2.6px;
        color: #C1262E;
        opacity: 1;
        font-family: roboto;
        font-size: 26px;
        font-weight: bold;

    }

    .contain header .head {
        font-size: 65%;
        font-weight: bold;
        font-family: roboto;
        position: absolute;
        left: 7%;
        margin-left: 47px;


    }

    .alter {
        left: 119px;
        position: absolute;
        margin-left: -61%;
        width: 52px;
        height: 48px;
        top: 4px;
    }

    .bc {
        background-color: #ECF3FF !important;
        position: absolute;
        top: 88px;
        height: 735px;
        width: 660px;
        left: 18px;
        opacity: 0.3;
    }

    .first {
        position: relative;
        left: 39px;
        top: 114px;
        font-family: roboto;
    }

    .ul {

        border: 1px solid #C1262E;
        position: relative;
        top: 119px;
        opacity: 0.8;
        left: 41px;
        width: 606px;
    }

    .para1 {
        position: relative;
        top: 132px;
        left: 38px;
        width: 614px;
        font-size: 14px;
        font-family: roboto;
        font-weight: 500;
        color: black;
        text-align: justify;
    }

    .sec {
        position: relative;
        left: 40px;
        top: 130px;
        font-family: roboto;
    }

    .add {
        position: relative;
        top: 125px;
        left: 39px;
        color: black;
        letter-spacing: 0;
        opacity: 1;
        font-family: roboto;
        font-size: 13px;
        height: 10px;
    }

    .inside {
        position: relative;
        font-weight: 500;
        font-size: 12px;
    }

    .third {
        position: relative;
        left: 40px;
        top: 160px;
        font-family: roboto;
        font-size: 14px;
    }

    .four {
        position: relative;
        left: 40px;
        top: 155px;
        font-family: roboto;
        font-size: 14px;
    }

    .bg {
        background-color: #000B28;
        position: relative;
        top: 40px;
        width: 400px;
        left: 4px;
        height: 50px;
    }

    .bg {
        background-color: #000B28;
        position: relative;
        top: 203px;
        width: 700px;
        left: 0px;
        height: 270px;
    }

    .ig {
        position: relative;
        top: 25px;      
    }

    .ig i {
        margin-left: 5px;
        margin-right: 20px;
    }

    .b {
        border: 1px solid #FFFFFF;
        position: relative;
        top: 51px;
        width: 652px;
        opacity: 0.1;
        left: 25px;
    }

    .web {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        text-decoration: underline;
        opacity: 1;
        top: 43px;
        left: 220px;
        width: 118px;
        height: 7px;
    }

    .web1 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        text-decoration: underline;
        opacity: 1;
        top: -10px;
        left: 347px;
        list-style-type: none;
        /* display: flex; */
    }

    .web2 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        text-decoration: underline;
        opacity: 1;
        top: -10px;
        left: 356px;
        list-style-type: none;
        /* display: flex; */
    }

    .b1 {
        border: 1px solid #FFFFFF;
        position: relative;
        top: -6px;
        width: 652px;
        opacity: 0.1;
        left: 25px;
    }

    .last {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
        top: -9px;
        left: 140px;
        height: 14px;
    }

    .last1 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
        top: -9px;
        left: 186px;
        height: 16px;
    }

    .li1 {
        position: relative;
        color: #C1393D;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
    }

    .last2 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
        top: -9px;
        left: 64px;
        height: 16px;
    }

    .last3 {
        position: relative;
        color: #FFFFFF;
        font-size: 12px;
        font-family: roboto;
        font-weight: 500;
        opacity: 1;
        top: -9px;
        left: 203px;
        height: 16px;
    }

    .point {
        border: 1px solid white;
        position: relative;
        top: 24px;
        height: 4px;
        left: 345px;
        opacity: 0.1;
    }

    .do {
        display: flex;
        position: relative;
        left: -36px;
    }

    .point1 {
        border: 1px solid white;
        position: relative;       
        left: 351px;
        opacity: 0.1;
    }
    
    .flex3 {
        display: flex;
        justify-content: space-between;
        margin-left: 6em;
        flex-direction: row;
    }

    .flex-items {
        margin-left: 3em !important;
    }

    .privacy_policy {
        margin-top: 3.7em;
        text-align: center;
        margin-bottom: 1.3em;
    }

    .privacy_policy a {
        color: white !important;
        font-family: Roboto, Medium !important;
        font-size: 12px;
    }

    .vertical-line {
        color: #FFFFFF;
        margin-left: 0.5em;
        margin-right: 0.5em;

    }
</style>

<body>
    <div class="contain">
        <header>ANNA
            <div class="head">
                IAS <span>ACADAMY</span>
            </div>
            <img src='https://i1.wp.com/aiasabook.com/wp-content/uploads/2021/10/cropped-logo1.png?fit=512%2C512&ssl=1' alt="logo" class="alter">
        </header>
        <div class="bc"></div>
        <div>
            <h6 class="first">Hi ${
              res.data.data.fullname
            }, Welcome to ANNA IAS ACADAMY!</h6>
        </div>
        <div class="ul"></div>

        <div>
            <p class="para1">Congratulations on successfully completing the payment for your admission to Anna IAS
                Academy. We are thrilled to have you as part of our institute's community and look forward to an
                exciting learning journey together.</p>
        </div>
        <div>
            <p class="para1">To ensure a smooth start, we are providing you with important details regarding your
                admission and payment. Additionally, you can find the attached receipt for your payment details
                mentioned below.</p>
        </div>
        <div>
            <h4 class="sec">Admission Details:</h4>
        </div>
        <div>
            <p class="add"> Batch Name:<span class="inside">&nbsp;${CouresName}</span></p>
            <p class="add">Start Date: <span class="inside"> &nbsp;${
              res.data.data.started_dt
            }</span> </p>
            <p class="add"> Time:<span class="inside">&nbsp;${
              res.data.data.batch_time
            }</span> </p>
            <p class="add"> Duration:<span class="inside">&nbsp;${
              res.data.data.duration + " Months"
            }</span> </p>
            <p class="add">Class Mode: <span class="inside">&nbsp;${
              res.data.data.class_mode
            }</span></p>
            <p class="add"> Location: <span class="inside">&nbsp;${
              res.data.data.location
            }</span></p>
        </div>
        <div>
            <h4 class="sec">Payment Details:</h4>
        </div>
        <div>
            <p class="add">
                Total Amount Paid:<span class="inside">&nbsp;${
                  res.data.data.amount
                } </span></p>
            <p class="add"> Payment Method:<span class="inside">&nbsp;${
              res.data.data.method
            }</span></p>
            <p class="add"> Transaction ID:<span class="inside"> &nbsp;${
              res.data.data.id
            } </span></p>
            <p class="add"> Date and Time of Payment:<span class="inside">&nbsp;${
              res.data.data.created_at
            }</span>
            </p>
        </div>
        <div>
            <p class="para1">Our dedicated Student Support team is available to assist you throughout your academic
                journey. If you have any questions or require further information, please don't hesitate to reach out to
                them at support@annaiasacademy.com.</p>
        </div>
        <div>
            <p class="para1">Once again, congratulations on securing your admission, and we look forward to welcoming
                you to Anna IAS Academy.</p>
        </div>
        <div>
            <h5 class="third" style="margin-bottom:0em;">Thanks,</h5>
        </div>
        <div>
            <h4 class="four">Anna IAS Academy</h4>
        </div>
        <section>
               <footer>
                <div class="bg">
                    <div class="ig">

                        <div  style=" display: flex !important;
        justify-content: space-between !important;
        margin-left: 6em !important;
        flex-direction: row !important;">

                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${facebook}'
                                alt="facebook" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${twitter}'
                                alt="twitter" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${linkedin}'
                                alt="linkedin" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${insta}'
                                alt="insta" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${whatsapp}'
                                alt="whatsapp" width="20" height="20">
                            <img class="flex-items" style=" margin-left: 3em;"
                                src='${youtube}'
                                alt="youtube" width="20" height="20">
                        </div>

                    </div>
                    <div class="b"></div>
                    <div class="privacy_policy">
                        <a href="#">annaiasacademy.com</a>
                        <span class="vertical-line">|</span>

                        <a href="#">Privacy</a>
                        <span class="vertical-line">|</span>

                        <a href="#">Terms of Use</a>

                    </div>
                    <div class="b1"></div>
                    <div>
                        <p class="last">This email is from Anna IAS Academy - 48 , Vanigar Street, Kanchipuram - 631501.
                        </p>
                    </div>
                    <div class="b1"></div>
                    <div>
                        <p class="last1">
                            If you have any questions please contact us <span class="li1">@annaiasacademy.com</span>
                        </p>
                    </div>
                    <div class="b1"></div>
                    <div>
                        <p class="last2">To ensure delivery to inbox, add <span class="li1">
                                support@annaiasacademy.com</span> to your address book and make it whitelisted</p>
                    </div>
                    <div class="b1"></div>
                    <div>
                        <p class="last3">Copyright © ${new Date().getFullYear()} Anna IAS Academy. All rights reserved</p>
                    </div>
                </div>
            </footer>
        </section>
    </div>


</body>

</html>`;

                                      const data = {
                                        trans_id: res.data.data.id,
                                        type: 2,
                                      };
                                      // console.log(data, "transcation id");
                                      AxiosInstance.post(
                                        "api/purchase/payment/download",
                                        data
                                      )
                                        .then((res) => {
                                          // console.log(res);
                                          // const html_text = `<!DOCTYPE html>
                                          // <html>
                                          // <body>

                                          // <h1>The a download attribute</h1>

                                          // <p>Click on the image to download it:<p>
                                          // <a href="/images/myw3schoolsimage.jpg" download>
                                          //   <img src="/images/myw3schoolsimage.jpg" alt="W3Schools" width="104" height="142">
                                          // </a>

                                          // <p><b>Note:</b> The download attribute is not supported in IE or Edge (prior version 18), or in Safari (prior version 10.1).</p>

                                          // </body>
                                          // </html>`;
                                          const html_text_rec = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
   
      <title>Document</title>
  </head>
  <style>
  @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
  @font-face {
    font-family: "Roboto-Regular";  
    src: local("Roboto-Regular"), url(${`../../../../font/font/Roboto/Roboto-Regular.ttf`}) format("truetype");
  }
  @font-face {
    font-family: "Roboto-Medium";  
    src: local("Roboto-Medium"), url(${`../../../../font/font/Roboto/Roboto-Medium.ttf`}) format("truetype");
  }
  @font-face {
    font-family: "Roboto-Bold";  
    src: local("Roboto-Bold"), url(${`../../../../font/font/Roboto/Roboto-Bold.ttf`}) format("truetype");
  }
      .contain
      {
          
          background: #FFFFFF 0% 0% no-repeat padding-box;
          width: 2480px;
      height: 3508px;
          
          opacity: 1;
         
      }
      .contain header
      {
          
  
          width: 202px;
      height: 87px;
      text-align: center;
      /* font: normal normal bold 66px/57px Roboto; */
      letter-spacing: 6.6px;
      color: #C1262E;
      opacity: 1;
      margin-top: 100px;
      margin-left: 47%;
      font-size: 66px;
      font-family:Roboto-Bold ;
  
      }
      .contain header .head{
      
      text-align: center;
      /* font: normal normal bold 51px/55px Roboto; */
      letter-spacing: 0px;
      color: #C1262E;
      opacity: 1;
      height: 46px;
      width: 423px;
      margin-top: 18px;
      margin-left: -37px;
      font-family: Roboto-Bold;
      font-size: 51px;
      line-height: 55px;
  
  
      }
      
      .logo
      {
      margin-top: -163px;
      margin-left: -368px;
      width: 138px;
      height: 138px;
      opacity: 1;
      margin-bottom: 56px;
      }
      
  .topic
  {
      text-align: left;
      /* font: normal normal bold 32px/24px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 43px;
      width: 270px;
      margin-left: 100px;
      margin-top: 194px;
      font-family: Roboto-Bold;
      font-size: 32px;
  }
  .main
  {
      flex-direction: column;
      width: auto;
  }
  .mainone
  {
      text-align: left;
      /* font: normal normal medium 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      height: 52px;
      width: 582px;
      font-size: 32px;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      margin-left: 97px;
  }
  .style{
      text-align: left;
      /* font: normal normal bold 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      height: 43px;
      width: 392px;
         margin-left: -2px;
      margin-top: -39px;
      font-size: 32px;
      font-family: Roboto-Bold;
  }
  .topicone
  {
      text-align: left;
      /* font: normal normal bold 32px/24px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 43px;
      width: 244px;
      margin-left: 100px;
      margin-top: 112px;
      font-size: 32px;
      font-family: Roboto-Bold;
  }
  .maintwo
  {
      text-align: left;
      /* font: normal normal medium 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
       font-size: 32px;
      font-family: Roboto-Medium;
      font-weight: 500;
      height: 52px;
      /* width: 386px; */
      margin-left: 94px;
  }
  .styleone{
      text-align: left;
      /* font: normal normal bold 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      font-size: 32px;
      font-family: Roboto-Bold;
      /* margin-left: 60px; */
  }
  .mains
  {
      width: auto;
      flex-direction: column;
  }
  .mainTopicTwo{
      display: flex;
      justify-content: center;
  }
  .mainTopicThree
  {
      display: flex;
      justify-content: left;
  }
  .topictwo
  {
      text-align: left;
      /* font: normal normal bold 40px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 43px;
      width: 400px;
      /* margin-left: 1066px; */
     
      font-size: 40px;
      font-family: Roboto-Bold;
  }
  .paragraph
  {
      /* text-align: left;
  
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 93px;
      font-size: 32px;
      font-family: Roboto-Medium;
      
      margin-left: 100px;
         width: 2238px; */
         text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      height: 93px;
      font-size: 32px;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      margin-left: 100px;
      margin-right: 100px;
      width: 2280px;
      line-height: 50px;
  }
  .mainss
  {
      /* gap: 60px;
      display: flex;
      width: auto; */
      margin-top: 40px;
  }
  .mainthree
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      /* width: 284px; */
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree1
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      /* height: 43px;
      width: 246px; */
      margin-left: 246px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  
  }
  .mainly
  {
      margin-top: 30px;
      /* gap: 18px;
      display: flex;
      width: auto; */
  }
  .mainfour
  {
      text-align: left;
      /* font: normal normal medium 28px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family:Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 100px;
      margin-top: 25px;
  }
  .mainfour1
  {
      text-align: left;
      /* font: normal normal medium 28px/50px Roboto; */
      letter-spacing: 0px;
      color: #C1262E;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: bold; */
      font-size: 28px;
         margin-left: 89px;
      margin-top: 25px;
  }
  .mainfour7
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 127px;
      margin-top: 25px;
  }
  .mainfour2
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 362px;
      margin-top: 25px;
  }
  .mainfour3
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 217px;
      margin-top: 25px;
  }
  .mainfour4
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 355px;
      margin-top: 25px;
  }
  .mainfour5
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 142px;
      margin-top: 25px;
  }
  .mainfour6
  {
          text-align: left;
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      font-size: 28px;
      margin-left: 77px;
      margin-top: 25px;
  }
  .topicthree
  {
      text-align: left;
      /* font: normal normal bold 40px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      text-transform: uppercase;
      opacity: 1;
      height: 53px;
      width: 1314px;
      margin-left: 95px;
      margin-top: 90px;
      font-family: Roboto-Bold;
      font-size: 40px;
      /* font-weight: bold; */
  }
  .mainfive
  {
      text-align: left;
      /* font: normal normal medium 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-size: 32px;
      font-family: Roboto-Medium;
      /* font-weight: 500; */
      margin-left: 100px;
      width: 535px;
      height: 52px;
      /* margin-top: 100px; */
  }
  .mainfive1{
      text-align: left;
      /* font: normal normal medium 32px/50px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      font-size: 32px;
      font-family:Roboto-Medium;
      /* font-weight: 500; */
      margin-left: 100px;
      width: 535px;
      height: 52px;
      margin-top: 60px;
     
  }
  .final
  {
      opacity: 1;
      text-align: center;
      /* font: normal normal normal 32px/43px Roboto; */
      letter-spacing: 0.03px;
      color: #041134;
      margin-left: 100px;
      margin-top: 60px;
      font-size: 32px;
      font-family: Roboto-Regular;
      /* font-weight: normal; */
  }
  .final a{
      text-align: center;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0.03px;
      color: #C1262E;
      text-decoration: none;
      font-size: 32px;
      font-family: Roboto-Bold;
      /* font-weight: bold; */
  }
  .finalone
  {
      margin-top: 70px;
  }
  .finaltwo
  {
      text-align: center;
      /* font: normal normal bold 32px/40px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 1;
      /* margin-left: 890px; */
      font-size: 32px;
      font-family: Roboto-Bold;
      line-height: 40px;
      /* font-weight: bold; */
  }
  .finalthree
  {
    margin-top: 550px;
      display: flex;
      justify-content: center;
  }
  .mained
  {
          margin-top: 130px;
  }
  .mainthree2
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 329px;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree3
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 188px;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree4
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 304;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree5
  {
      text-align: right;
      /* font: normal normal bold 32px/43px Roboto; */
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 120px;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree6
  {
      text-align: right;
  
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 61px;
      margin-left: 97px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  .mainthree7
  {
      text-align: right;
     
      letter-spacing: 0px;
      color: #041134;
      opacity: 0.5;
      height: 43px;
      width: 71px;
      margin-left: 100px;
      font-size: 32px;
      font-family: Roboto-Bold;
      font-weight: bold;
  }
  </style>
  <body>
        <div class="contain">
          <header >ANNA 
              <div class="head">
                  IAS ACADAMY
              </div>
              <img src="https://i1.wp.com/aiasabook.com/wp-content/uploads/2021/10/cropped-logo1.png?fit=512%2C512&ssl=1" alt="logo" class="logo">
           </header>
           <div>
              <h1 class="topic">Admission Details:</h1>
           </div>
           <div class="main">
              <div class="mainone">Batch Name: <span class="style">${
                res.data.data[0].batch_name
              }</span></div>
              <div class="mainone">  Start Date:<span class="style">${
                res.data.data[0].batch_start_dt
              }</span></div>
              <div class="mainone">Time:<span class="style">${
                res.data.data[0].batch_timing
              }</span>  </div>
              <div class="mainone">Duration:<span class="style">${
                res.data.data[0].duration
              }</span></div>
              <div class="mainone">Class Mode:<span class="style">${
                res.data.data[0].class_mode
              }</span></div>
              <div class="mainone">Branch Location: <span class="style">${
                res.data.data[0].location
              }</span></div>
           </div>
           <div>
              <h1 class="topicone">Payment Details:</h1>
           </div>
           <div class="mains">
              <div class="maintwo">Total Amount Paid: <span class="styleone">${
                res.data.data[0].trans_amount
              }</span></div>
          
              <div class="maintwo">Payment Method:<span class="styleone"> ${
                res.data.data[0].method
              } </span></div>
              <div class="maintwo">Transaction ID:<span class="styleone"> ${
                res.data.data[0].trans_id
              } </span></div>
              <div class="maintwo">Date and Time of Payment:<span class="styleone"> ${
                res.data.data[0].transaction_date
              }</span></div>
              
           </div>
           <div class="mainTopicTwo">
              <h1 class="topictwo">PAYMENT RECEIPT</h1>
           </div>
           <div class="mainTopicThree">
           <p class="paragraph">Received Rupees ${res.data.data[0].trans_amount}
             (${toWords(
               res.data.data[0].trans_amount
             )}) through payment gateway over the internet from ${
                                            res.data.data[0].fullname
                                          } towards the following</p>
           </div>
           <div class="mainss">
          
              <span class="mainthree">Batch Name</span>
              <span class="mainthree1">Actual Fee</span>
              <span class="mainthree2">Coupon Code Discount</span>
              <span class="mainthree3">Special Offer</span>
              <span class="mainthree4">Old Student Discount</span>
              <span class="mainthree5">Subtotal</span>
              <span class="mainthree6">GST</span>
               <span class="mainthree7">Total</span>
           
          </div>
          <div class="mainly">
              <span class="mainfour">${res.data.data[0].batch_name}</span>
              <span class="mainfour7">${res.data.data[0].actual_amt}</span>
              <span class="mainfour2">${res.data.data[0].coupon_amount}</span>
              <span class="mainfour3">${res.data.data[0].special_offer}</span>
              <span class="mainfour4">${res.data.data[0].old_stud_disc}</span>
              <span class="mainfour5">${
                res.data.data[0].actual_amt -
                res.data.data[0].coupon_amount -
                res.data.data[0].special_offer -
                res.data.data.old_stud_disc
              }</span>
              <span class="mainfour6">${res.data.data[0].gst_percent}</span>
              <span class="mainfour1">${res.data.data[0].trans_amount}</span>
             
             </div>
             <div>
             <h1 class="topicthree">(${toWords(
               res.data.data[0].trans_amount
             )})</h1>
             </div>
             <div class="mained">
          <div class="mainfive">${res.data.data[0].fullname}</div>
          <div class="mainfive">${res.data.data[0].address1}${
                                            res.data.data[0].landmark
                                          }</div>
          <div class="mainfive">${res.data.data[0].state_name}${
                                            res.data.data[0].district_name
                                          }</div>
          <div class="mainfive"> ${res.data.data[0].location} - ${
                                            res.data.data[0].postal_code
                                          }</div>
          <div class="mainfive1"> 
              ${res.data.data[0].email} </div>
          <div class="mainfive"> ${res.data.data[0].phone_no}</div>
            </div>
        
        <div class="finalone">
          <span class="final">If you have any payment related queries, please contact us 
              <a href="#">support@annaiasacademy.com</a>
          </span>
        </div >
        <div class="finalthree">
          <span class="finaltwo">48 , Vanigar Street, Kanchipuram,Tamil Nadu - 631501.</span>
        </div>
        
   </div>
  </body>
  </html>`;

                                          // const actual_amt =
                                          //   res.data.data[0].actual_amt;
                                          // const coupon_amt =
                                          //   res.data.data[0].coupon_amt;
                                          // const special_offer =
                                          //   res.data.data[0].special_offer;
                                          // const old_stud_disc =
                                          //   res.data.data[0].old_stud_disc;
                                          // const gst_percent =
                                          //   res.data.data[0].gst_percent;

                                          // const subtotal =
                                          //   actual_amt -
                                          //   coupon_amt -
                                          //   special_offer -
                                          //   old_stud_disc;
                                          // const gst_amt =
                                          //   (subtotal * gst_percent) / 100;
                                          // const tot_amt = subtotal + gst_amt;
                                          // const email_data = {
                                          //   first_name: list.FirstName,
                                          //   last_name: list.LastName,
                                          //   email: res.data.data[0].email,
                                          //   batch_name:
                                          //     res.data.data[0].batch_name,
                                          //   start_date: moment(
                                          //     res.data.data[0].batch_start_dt
                                          //   ).format("DD-MMM-YYYY"),
                                          //   time: res.data.data[0].batch_timing,
                                          //   duration: res.data.data[0].duration,
                                          //   class_mode:
                                          //     res.data.data[0].class_mode,
                                          //   location: res.data.data[0].location,
                                          //   amount_paid:
                                          //     res.data.data[0].trans_amount,
                                          //   amount_paid_words: toWords(
                                          //     res.data.data[0].trans_amount
                                          //   ),
                                          //   payment_method:
                                          //     res.data.data[0].method,
                                          //   transaction_id:
                                          //     res.data.data[0].trans_id,
                                          //   payment_time:
                                          //     res.data.data[0].transaction_date,
                                          //   actual_amt: actual_amt,
                                          //   coupon_amt: coupon_amt,
                                          //   special_offer: special_offer,
                                          //   old_stud_disc: old_stud_disc,
                                          //   subtotal: subtotal,
                                          //   gst_amt: gst_amt,
                                          //   tot_amt: tot_amt,
                                          //   parentage:
                                          //     list.gender === "Female"
                                          //       ? "D/O"
                                          //       : "S/O",
                                          //   father_name:
                                          //     res.data.data[0].father_name,
                                          //   address1: res.data.data[0].address1,
                                          //   address2: res.data.data[0].address2,
                                          //   address3: res.data.data[0].address3,
                                          //   city: res.data.data[0].city,
                                          //   pincode:
                                          //     res.data.data[0].postal_code,
                                          //   mob_no: res.data.data[0].phone_no,
                                          //   district:
                                          //     res.data.data[0].district_name,
                                          //   state: res.data.data[0].state_name,
                                          // };

                                          AxiosInstance.post(
                                            "api/all/sentPaymentInvoice",
                                            {
                                              user_id: resuserid,
                                              email: email,
                                              subject: "Payment Invoice",
                                              subject_rec: "Payment Receipt",
                                              html_text: html_text,
                                              html_text_rec: html_text_rec,
                                            }
                                          )
                                            .then((res) => {
                                              // console.log(res, "invoice");
                                              if (res.data[0].status === true) {
                                                setStep(step + 1);
                                              }
                                            })
                                            .catch((error) => {
                                              return error;
                                            });
                                        })
                                        .catch((error) => {
                                          return error;
                                        });
                                    }
                                  })
                                  .catch((error) => {
                                    return error;
                                  });
                              }
                            })
                            .catch((error) => {
                              return error;
                            });
                        }
                      })
                      .catch((error) => {
                        return error;
                      });
                  }
                },
              };
              const rzp1 = new window.Razorpay(options);
              rzp1.open();
            }
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "Warning",
              text: error,
              icon: "warning",
              confirmButtonText: "Ok",
            });
            return error;
          });
      }
    } else {
      console.log("User Id cannot available in session storage");
    }
  };

  const handleStepPrevious = (count) => {
    setStep(count);
  };

  // store localstorge
  function appendToStorage(name, data) {
    sessionStorage.setItem(name, data);
  }
  //technical qualification add function
  const AddQualification = () => {
    if (technicalOption.length > techQualification.length) {
      setTechQualification((prevState) => [
        ...prevState,
        {
          id: prevState.length > 0 ? prevState.length + 1 : 1,
          name: "",
          level: "",
          lang: "",
          Cnum: "",
          grade: "",
          year: "",
          file: "",
          error_name: "",
          edu_medium: "",
          error_certificate_no: "",
          error_year_of_passing: "",
          error_level: "",
          error_grade: "",
          error_certificate_path: "",
        },
      ]);
    } else {
      Swal.fire({
        title: "Warning",
        text: "you reach more Technical Qualification",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };
  //technical qualification delete function
  const DeleteQual = (id, name) => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        AxiosInstance.post("api/course/admission/delete/qualifications", {
          user_id: userid,
          name: name,
          qualification_type: "THQ",
          id: id,
        })
          .then((res) => {
            if (res.status === 200) {
              const val = techQualification
                .filter((item) => item.id !== id)
                .map((item, index) => ({ ...item, id: index + 1 }));
              setTechQualification(val);

              // Swal.fire({
              //   title: 'Success',
              //   text: "Deleted successfully",
              //   icon: 'success',
              //   confirmButtonText: 'Ok'
              // }).then((result) => {
              //   if (result.isConfirmed) {

              //   }
              // });
            }
          })
          .catch((error) => {
            return error;
          });
      }
    } else {
      console.log("User Id cannot avaliable in session storage");
    }
  };
  const setMaxMonthYearTec = (value) => {
    // console.log(value);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

    // Format the current month and year as "yyyy-MM"
    const maxMonthYear = `${currentYear}-${currentMonth
      .toString()
      .padStart(2, "0")}`;
    // Parse the provided value into a JavaScript Date object
    const selectedDate = new Date(value);

    if (selectedDate > currentDate) {
      alert("Year of passing should be in past");
    } else {
      // Return the value because it's not in the future
      return value;
    }
  };
  const TechhandleChanges = (option, id) => {
    setTechQualification((prevState) => {
      return prevState.map((q) => {
        if (q.id === id) {
          return { ...q, ["name"]: option.value, ["error_name"]: "" };
        }
        return q;
      });
    });
  };
  const TechhandleChange_date = (date, id, name) => {
    const dateEYD = moment(date).format("YYYY-MM");
    if (setMaxMonthYearTec(dateEYD)) {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: dateEYD, ["error_year_of_passing"]: "" };
          }
          return q;
        });
      });
    }
  };
  //technical qualification handle change  function
  const TechhandleChange = (e, id) => {
    const { name, value, files } = e.target;
    if (name === "file" && files && files.length > 0) {
      const TQ_path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("TQ_path", TQ_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setTechQualification((prevState) => {
                return prevState.map((q) => {
                  if (q.id === id) {
                    return {
                      ...q,
                      file: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          alert("File must be less than 2MB");
        }
      }
    } else if (name === "year") {
      if (setMaxMonthYearTec(value)) {
        setTechQualification((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              return { ...q, [name]: value, ["error_year_of_passing"]: "" };
            }
            return q;
          });
        });
      }
    } else if (name === "Cnum") {
      if (!/^[A-Za-z0-9]*$/.test(value)) {
        alert("Certificate number can only contain letters and numbers");
      } else {
        setTechQualification((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              return { ...q, [name]: value, ["error_certificate_no"]: "" };
            }
            return q;
          });
        });
      }
    } else if (name === "grade") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, ["error_grade"]: "" };
          }
          return q;
        });
      });
    } else if (name === "level") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, ["error_level"]: "" };
          }
          return q;
        });
      });
    } else if (name === "lang") {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value, ["error_edu_medium"]: "" };
          }
          return q;
        });
      });
    } else {
      setTechQualification((prevState) => {
        return prevState.map((q) => {
          if (q.id === id) {
            return { ...q, [name]: value };
          }
          return q;
        });
      });
    }
  };
  //handlebar function
  const handleblur = (event) => {
    const { name, value } = event.target;

    setList({
      ...list,
      [name]: value,
    });
  };
  //educational checkbox handlechange
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;

    if (checked) {
      setEducationQualification([...educationQualification, value]);
      setListErrors({
        ...listErrors,
        EducationQualification: "",
      });
    } else {
      // alert("uncheck");
      const userJSON = sessionStorage.getItem("userDetails");
      if (userJSON) {
        const user = JSON.parse(userJSON);
        if (user && user.user_id) {
          const userid = user.user_id;
          AxiosInstance.post("api/course/admission/delete/qualifications", {
            user_id: userid,
            name: value,
            qualification_type: "EDQ",
          })
            .then((res) => {
              // console.log(res);
              if (res.status === 200) {
                setEducationQualification(
                  educationQualification.filter((item) => item !== value)
                );
                // Swal.fire({
                //   title: 'Success',
                //   text: "Deleted successfully",
                //   icon: 'success',
                //   confirmButtonText: 'Ok'
                // }).then((result) => {
                //   if (result.isConfirmed) {

                //   }
                // });
              }
            })
            .catch((error) => {
              return error;
            });
        }
      } else {
        console.log("User Id cannot avaliable in session storage");
      }
    }
  };
  const setMaxMonthYear = (value, names) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

    // Format the current month and year as "yyyy-MM"
    const maxMonthYear = `${currentYear}-${currentMonth
      .toString()
      .padStart(2, "0")}`;

    // Parse the provided value into a JavaScript Date object
    const selectedDate = new Date(value);

    if (selectedDate > currentDate) {
      setListErrors({
        ...listErrors,
        [names]: "Year of passing should be in past",
      });
    } else {
      // Return the value because it's not in the future
      return value;
    }
  };
  const handleEdus = (option, name, dname) => {
    if (name === "Integrated PG") {
      setUgOption((prevState) => ({
        ...prevState,
        ["Integrated_PG"]: option,
      }));
    } else if (name === "Professional Courses") {
      setUgOption((prevState) => ({
        ...prevState,
        ["Professional_Courses"]: option,
      }));
    } else {
      setUgOption((prevState) => ({
        ...prevState,
        [name]: option,
      }));
    }
    setSslcCerficates((prevState) => {
      return prevState.map((q) => {
        if (q.name === name) {
          return { ...q, [dname]: option.value };
        }
        return q;
      });
    });
    setListErrors({
      ...listErrors,
      [dname]: "",
    });
  };

  const handleEduMajor = (option, name, dname) => {
    if (option.label === "Others" && name === "UG" && dname === "UGMajor") {
      setUGIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "UG" &&
      dname === "UGMajor"
    ) {
      setUGIsOther(false);
    } else if (
      option.label === "Others" &&
      name === "PG" &&
      dname === "PGMajor"
    ) {
      setPGIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "PG" &&
      dname === "PGMajor"
    ) {
      setPGIsOther(false);
    } else if (
      option.label === "Others" &&
      name === "Integrated PG" &&
      dname === "IPMajor"
    ) {
      setIPGIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "Integrated PG" &&
      dname === "IPMajor"
    ) {
      setIPGIsOther(false);
    } else if (
      option.label === "Others" &&
      name === "Diploma" &&
      dname === "DMajor"
    ) {
      setDipIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "Diploma" &&
      dname === "DMajor"
    ) {
      setDipIsOther(false);
    } else if (
      option.label === "Others" &&
      name === "Professional Courses" &&
      dname === "PCMajor"
    ) {
      setPCIsOther(true);
    } else if (
      option.label !== "Others" &&
      name === "Professional Courses" &&
      dname === "PCMajor"
    ) {
      setPCIsOther(false);
    }
    setMOption((prevState) => ({
      ...prevState,
      [dname]: option,
    }));
    setSslcCerficates((prevState) => {
      return prevState.map((q) => {
        if (q.name === name) {
          return { ...q, [dname]: option.value };
        }
        return q;
      });
    });
    setListErrors({
      ...listErrors,
      [dname]: "",
    });
  };

  const handleSelectMulti = (option, name, dname) => {
    if (name === "CourseCate") {
      setList((prevState) => ({
        ...prevState,
        ["CourseCate"]: option.value,
        ["CouresName"]: option.course_name,
      }));
      setDropDown((prevState) => ({
        ...prevState,
        [dname]: option,
      }));
    } else {
      setList((prevState) => ({
        ...prevState,
        [dname]: option.value,
      }));
      setDropDown((prevState) => ({
        ...prevState,
        [dname]: option,
      }));
    }

    setListErrors({
      ...listErrors,
      [dname]: "",
    });
  };
  const handleSelectCH = (option, dname) => {
    // console.log(option,dname);
    setList((prevState) => ({
      ...prevState,
      [dname]: option.value,
    }));
    setListErrors({
      ...listErrors,
      [dname]: "",
    });
  };

  // all date format should handle function
  const handleChangeCuDate = (dates, name) => {
    const dateSam = moment(dates).format("YYYY-MM-DD");
    // console.log(name, "name", dateSam);
    if (name === "JoinGovt") {
      setList((prevState) => ({
        ...prevState,
        [name]: dateSam,
      }));
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    }
  };

  const handleEYDate = (dates, name, dname) => {
    const dateEYD = moment(dates).format("YYYY-MM");
    // console.log(name, "name", dname, dateEYD);
    if (setMaxMonthYear(dateEYD, name)) {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === dname) {
            return { ...q, [name]: dateEYD };
          }
          return q;
        });
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === dname) {
            return { ...q, [name]: "" };
          }
          return q;
        });
      });
    }
  };

  const handleYearChange_month = (year, dname) => {
    if (dname === "SSLC_Year") {
      if (
        sslcCerficates[0].SSLC_Year !== null &&
        sslcCerficates[0].SSLC_Year !== "" &&
        sslcCerficates[0].SSLC_Year !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[0].SSLC_Year);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "sslc") {
                return { ...q, ["SSLC_Year"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["SSLC_Year"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "sslc") {
                return { ...q, ["SSLC_Year"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "sslc") {
              return { ...q, ["SSLC_Year"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["SSLC_Year"]: "",
        });
      }
    } else if (dname === "HSC_Year") {
      if (
        sslcCerficates[1].HSC_Year !== null &&
        sslcCerficates[1].HSC_Year !== "" &&
        sslcCerficates[1].HSC_Year !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[1].HSC_Year);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "hsc") {
                return { ...q, ["HSC_Year"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["HSC_Year"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "hsc") {
                return { ...q, ["HSC_Year"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "hsc") {
              return { ...q, ["HSC_Year"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["HSC_Year"]: "",
        });
      }
    } else if (dname === "UGYearpassing") {
      if (
        sslcCerficates[2].UGYearpassing !== null &&
        sslcCerficates[2].UGYearpassing !== "" &&
        sslcCerficates[2].UGYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[2].UGYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "UG") {
                return { ...q, ["UGYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["UGYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "UG") {
                return { ...q, ["UGYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "UG") {
              return { ...q, ["UGYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["UGYearpassing"]: "",
        });
      }
    } else if (dname === "PGYearpassing") {
      if (
        sslcCerficates[3].PGYearpassing !== null &&
        sslcCerficates[3].PGYearpassing !== "" &&
        sslcCerficates[3].PGYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[3].PGYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "PG") {
                return { ...q, ["PGYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["PGYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "PG") {
                return { ...q, ["PGYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "PG") {
              return { ...q, ["PGYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["PGYearpassing"]: "",
        });
      }
    } else if (dname === "IPYearpassing") {
      if (
        sslcCerficates[4].IPYearpassing !== null &&
        sslcCerficates[4].IPYearpassing !== "" &&
        sslcCerficates[4].IPYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[4].IPYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Integrated PG") {
                return { ...q, ["IPYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["IPYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Integrated PG") {
                return { ...q, ["IPYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Integrated PG") {
              return { ...q, ["IPYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["IPYearpassing"]: "",
        });
      }
    } else if (dname === "DYearpassing") {
      if (
        sslcCerficates[5].DYearpassing !== null &&
        sslcCerficates[5].DYearpassing !== "" &&
        sslcCerficates[5].DYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[5].DYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Diploma") {
                return { ...q, ["DYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["DYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Diploma") {
                return { ...q, ["DYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Diploma") {
              return { ...q, ["DYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["DYearpassing"]: "",
        });
      }
    } else if (dname === "PCYearpassing") {
      if (
        sslcCerficates[6].PCYearpassing !== null &&
        sslcCerficates[6].PCYearpassing !== "" &&
        sslcCerficates[6].PCYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[6].PCYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Professional Courses") {
                return { ...q, ["PCYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["PCYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Professional Courses") {
                return { ...q, ["PCYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Professional Courses") {
              return { ...q, ["PCYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["PCYearpassing"]: "",
        });
      }
    } else if (dname === "OtYearpassing") {
      if (
        sslcCerficates[7].OtYearpassing !== null &&
        sslcCerficates[7].OtYearpassing !== "" &&
        sslcCerficates[7].OtYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[7].OtYearpassing);
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Others") {
                return { ...q, ["OtYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["OtYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Others") {
                return { ...q, ["OtYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        const newYear = year;
        currentDate.setFullYear(newYear);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Others") {
              return { ...q, ["OtYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["OtYearpassing"]: "",
        });
      }
    }
  };

  const handleMonthChange_month = (month, dname) => {
    if (dname === "SSLC_Year") {
      if (
        sslcCerficates[0].SSLC_Year !== null &&
        sslcCerficates[0].SSLC_Year !== "" &&
        sslcCerficates[0].SSLC_Year !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[0].SSLC_Year);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        // console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "sslc") {
                return { ...q, ["SSLC_Year"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["SSLC_Year"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "sslc") {
                return { ...q, ["SSLC_Year"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "sslc") {
              return { ...q, ["SSLC_Year"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["SSLC_Year"]: "",
        });
      }
    } else if (dname === "HSC_Year") {
      if (
        sslcCerficates[1].HSC_Year !== null &&
        sslcCerficates[1].HSC_Year !== "" &&
        sslcCerficates[1].HSC_Year !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[1].HSC_Year);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        // console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "hsc") {
                return { ...q, ["HSC_Year"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["HSC_Year"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "hsc") {
                return { ...q, ["HSC_Year"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "hsc") {
              return { ...q, ["HSC_Year"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["HSC_Year"]: "",
        });
      }
    } else if (dname === "UGYearpassing") {
      if (
        sslcCerficates[2].UGYearpassing !== null &&
        sslcCerficates[2].UGYearpassing !== "" &&
        sslcCerficates[2].UGYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[2].UGYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        // console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "UG") {
                return { ...q, ["UGYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["UGYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "UG") {
                return { ...q, ["UGYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "UG") {
              return { ...q, ["UGYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["UGYearpassing"]: "",
        });
      }
    } else if (dname === "PGYearpassing") {
      if (
        sslcCerficates[3].PGYearpassing !== null &&
        sslcCerficates[3].PGYearpassing !== "" &&
        sslcCerficates[3].PGYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[3].PGYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        // console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "PG") {
                return { ...q, ["PGYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["PGYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "PG") {
                return { ...q, ["PGYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "PG") {
              return { ...q, ["PGYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["PGYearpassing"]: "",
        });
      }
    } else if (dname === "IPYearpassing") {
      if (
        sslcCerficates[4].IPYearpassing !== null &&
        sslcCerficates[4].IPYearpassing !== "" &&
        sslcCerficates[4].IPYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[4].IPYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        // console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Integrated PG") {
                return { ...q, ["IPYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["IPYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Integrated PG") {
                return { ...q, ["IPYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Integrated PG") {
              return { ...q, ["IPYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["IPYearpassing"]: "",
        });
      }
    } else if (dname === "DYearpassing") {
      if (
        sslcCerficates[5].DYearpassing !== null &&
        sslcCerficates[5].DYearpassing !== "" &&
        sslcCerficates[5].DYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[5].DYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        // console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Diploma") {
                return { ...q, ["DYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["DYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Diploma") {
                return { ...q, ["DYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Diploma") {
              return { ...q, ["DYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["DYearpassing"]: "",
        });
      }
    } else if (dname === "PCYearpassing") {
      if (
        sslcCerficates[6].PCYearpassing !== null &&
        sslcCerficates[6].PCYearpassing !== "" &&
        sslcCerficates[6].PCYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[6].PCYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        // console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Professional Courses") {
                return { ...q, ["PCYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["PCYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Professional Courses") {
                return { ...q, ["PCYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Professional Courses") {
              return { ...q, ["PCYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["PCYearpassing"]: "",
        });
      }
    } else if (dname === "OtYearpassing") {
      if (
        sslcCerficates[7].OtYearpassing !== null &&
        sslcCerficates[7].OtYearpassing !== "" &&
        sslcCerficates[7].OtYearpassing !== undefined
      ) {
        const currentDate = new Date(sslcCerficates[7].OtYearpassing);
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        // console.log(dateEYDa,"dateEYDa");
        if (setMaxMonthYear(dateEYDa, dname)) {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Others") {
                return { ...q, ["OtYearpassing"]: dateEYDa };
              }
              return q;
            });
          });
          setListErrors({
            ...listErrors,
            ["OtYearpassing"]: "",
          });
        } else {
          setSslcCerficates((prevState) => {
            return prevState.map((q) => {
              if (q.name === "Others") {
                return { ...q, ["OtYearpassing"]: "" };
              }
              return q;
            });
          });
        }
      } else {
        const currentDate = new Date();
        let monthNumber = moment(month, "MMMM").month();
        currentDate.setMonth(monthNumber);
        const dateEYDa = moment(currentDate).format("YYYY-MM");
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === "Others") {
              return { ...q, ["OtYearpassing"]: dateEYDa };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          ["OtYearpassing"]: "",
        });
      }
    }
  };

  // education cerficate handle change
  const handleEdu = (eduname, event) => {
    const names = event.target.name;
    const value = event.target.value;
    const files = event.target.files;

    if (names === "SSLC_Path" && files && files.length > 0) {
      const SSLC_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("SSLC_Path", SSLC_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            SSLC_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            SSLC_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          SSLC_Path: err,
        });
      }
    } else if (names === "HSC_Path" && files && files.length > 0) {
      const HSC_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("HSC_Path", HSC_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            HSC_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            HSC_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          HSC_Path: err,
        });
      }
    } else if (names === "UG_Path" && files && files.length > 0) {
      const UG_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("UG_Path", UG_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            UG_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            UG_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          UG_Path: err,
        });
      }
    } else if (names === "PG_Path" && files && files.length > 0) {
      const PG_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("PG_Path", PG_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            PG_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            PG_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          PG_Path: err,
        });
      }
    } else if (names === "IP_Path" && files && files.length > 0) {
      const IP_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("IP_Path", IP_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            IP_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            IP_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          IP_Path: err,
        });
      }
    } else if (names === "PC_Path" && files && files.length > 0) {
      const PC_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("PC_Path", PC_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            PC_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            PC_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          PC_Path: err,
        });
      }
    } else if (names === "D_Path" && files && files.length > 0) {
      const D_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("D_Path", D_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            D_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            D_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          D_Path: err,
        });
      }
    } else if (names === "Ot_Path" && files && files.length > 0) {
      const Ot_Path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("Ot_Path", Ot_Path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setSslcCerficates((prevState) => {
                return prevState.map((q) => {
                  if (q.name === eduname) {
                    return {
                      ...q,
                      [names]: res.data.path,
                      original_file_path: res.data.originalname,
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
          setListErrors({
            ...listErrors,
            Ot_Path: "",
          });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            Ot_Path: err,
          });
        }
      } else {
        let err = "PDF files only allowed";
        setListErrors({
          ...listErrors,
          Ot_Path: err,
        });
      }
    } else if (names === "PG") {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === eduname) {
            return { ...q, [names]: value };
          }
          return q;
        });
      });
      setListErrors({
        ...listErrors,
        [names]: "",
      });
    } else if (names === "SSLC_Marks") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "HSC_Marks") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "PGMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "UGMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "IPMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "DMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "PCMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (names === "OtMarksPercentage") {
      if (value < 0 || value == 0) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: "" };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "Marks Percentage should be above 45%",
        });
      } else {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else if (
      names === "SSLC_Year" ||
      names === "HSC_Year" ||
      names === "UGYearpassing" ||
      names === "PGYearpassing" ||
      names === "IPYearpassing" ||
      names === "DYearpassing" ||
      names === "PCYearpassing" ||
      names === "OtYearpassing"
    ) {
      if (setMaxMonthYear(value, names)) {
        setSslcCerficates((prevState) => {
          return prevState.map((q) => {
            if (q.name === eduname) {
              return { ...q, [names]: value };
            }
            return q;
          });
        });
        setListErrors({
          ...listErrors,
          [names]: "",
        });
      }
    } else {
      setSslcCerficates((prevState) => {
        return prevState.map((q) => {
          if (q.name === eduname) {
            return { ...q, [names]: value };
          }
          return q;
        });
      });
      setListErrors({
        ...listErrors,
        [names]: "",
      });
    }
  };

  const handlePreferredpost = (selected) => {
    // console.log(selected, "selected");

    const selectedPre = selected.map((option) => ({
      label: option.label,
      value: option.value,
    }));
    setSelectedPre(selectedPre);
    setListErrors({
      ...listErrors,
      preferredpost: "",
    });
  };

  const handlePreferredDept = (selected) => {
    // console.log(selected, "selected");

    const selectedPreDept = selected.map((option) => ({
      label: option.label,
      value: option.value,
    }));
    setSelectedPreDept(selectedPreDept);
    setListErrors({
      ...listErrors,
      preferredDept: "",
    });
  };

  const handleStepOne = () => {
    setStep(1);
  };

  //handlechange function
  const handleChange = (event) => {
    // console.log(list.location);
    const { name, value, files, checked } = event.target;
    const numericValue = value.replace(/[^0-9]/g, "");
    if (name === "DOB") {
      DobCovertAge(value);
      setList({
        ...list,
        [name]: value,
      });
    } else if (name === "PincodePresent") {
      if (/^[1-9][0-9]{5}$/.test(value) || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          ["CityPresent"]: "",
        }));
        setListErrors({
          ...listErrors,
          ["PincodePresent"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          ["CityPresent"]: "",
        }));
        setListErrors({
          ...listErrors,
          ["PincodePresent"]: "Please Enter valid Pincode",
        });
        return;
      }
    } else if (name === "PincodePermanent") {
      if (/^[1-9][0-9]{5}$/.test(value) || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          ["CityPermanent"]: "",
        }));
        setListErrors({
          ...listErrors,
          ["PincodePermanent"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          ["CityPermanent"]: "",
        }));
        setListErrors({
          ...listErrors,
          ["PincodePermanent"]: "Please Enter valid Pincode",
        });
        return;
      }
    } else if (name === "Mobile") {
      if (value.length === 10 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["Mobile"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["Mobile"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name === "WhatsAppNumber") {
      if (value.length === 10 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name === "ParentMobileNo") {
      if (value.length === 10 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["ParentMobileNo"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
        }));
        setListErrors({
          ...listErrors,
          ["ParentMobileNo"]: "Please Enter valid 10 digit number",
        });
        return;
      }
    } else if (name == "aadharNo") {
      if (value.length === 12 || value.length === 0) {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          [name]: numericValue,
        }));
        setListErrors({
          ...listErrors,
          ["aadharNo"]: "",
        });
        // Proceed with other fields or actions if needed
      } else {
        setList((prevList) => ({
          ...prevList,
          [name]: value,
          [name]: numericValue,
        }));
        setListErrors({
          ...listErrors,
          ["aadharNo"]: "Please Enter valid 12 digit number",
        });
        return;
      }
    } else if (name == "ContactNumber") {
      if (checked) {
        setList((prevList) => ({
          ...prevList,
          [name]: checked,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "",
        });
      } else {
        setList((prevList) => ({
          ...prevList,
          ["WhatsAppNumber"]: "",
          [name]: checked,
        }));
        setListErrors({
          ...listErrors,
          ["WhatsAppNumber"]: "",
        });
      }
    } else if (name == "location") {
      setList((prevList) => ({
        ...prevList,
        [name]: value,
      }));
      setListErrors({
        ...listErrors,
        location: "",
      });
      const selectedId = event.target.options[event.target.selectedIndex].id;
      setList((prevList) => ({
        ...prevList,
        location_name: selectedId,
      }));
    } else if (name == "SamePresentAddress") {
      setList((prevList) => ({
        ...prevList,
        [name]: checked,
      }));
      setListErrors({
        ...listErrors,
        ["SamePresentAddress"]: "",
        ["BuildingNumberPermanent"]: "",
        ["StreetNamePermanent"]: "",
        ["AreaPermanent"]: "",
        ["LandmarkPermanent"]: "",
        ["districtPermanent"]: "",
        ["CityPermanent"]: "",
        ["statePermanent"]: "",
        ["PostPermanent"]: "",
        ["TalukPermanent"]: "",
        ["PincodePermanent"]: "",
      });
    } else if (name === "Profilephoto" && files && files.length > 0) {
      const profile_path = files[0];
      // console.log(profile_path);
      if (
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 40 * 1024) {
          const formData = new FormData();
          formData.append("profile_path", profile_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                Profilephoto: res.data.path,
                original_profile_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                Profilephoto: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 40KB.";
          setListErrors({
            ...listErrors,
            Profilephoto: err,
          });
        }
      } else {
        let err = "(Png , jpg ,jpeg )images only allowed";
        setListErrors({
          ...listErrors,
          Profilephoto: err,
        });
      }
    } else if (name === "aadharCardphoto" && files && files.length > 0) {
      const aadhar_path = files[0];
      if (files[0].type == "application/pdf") {
        const formData = new FormData();
        formData.append("aadhar_path", aadhar_path);
        AxiosInstance.post("api/upload/", formData)
          .then((res) => {
            setList({
              ...list,
              aadharCardphoto: res.data.path,
              original_aadhar_path: res.data.originalname,
            });
            setListErrors({
              ...listErrors,
              aadharCardphoto: "",
            });
          })
          .catch((error) => {
            return error;
          });
      } else if (
        files[0].type == "application/pdf" &&
        files[0].size > 2 * 1024 * 1024
      ) {
        let err = "File size must be less than 2MB.";
        setListErrors({
          ...listErrors,
          aadharCardphoto: err,
        });
      } else {
        let err = "pdf files only allowed";
        setListErrors({
          ...listErrors,
          aadharCardphoto: err,
        });
      }
    } else if (name === "CastFile" && files && files.length > 0) {
      const community_path = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("community_path", community_path);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setList({
                ...list,
                CastFile: res.data.path,
                original_caste_path: res.data.originalname,
              });
              setListErrors({
                ...listErrors,
                CastFile: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setListErrors({
            ...listErrors,
            CastFile: err,
          });
        }
      } else {
        let err = "pdf files only allowed";
        setListErrors({
          ...listErrors,
          CastFile: err,
        });
      }
    } else if (name === "category") {
      setList({
        ...list,
        [name]: value,
      });

      setListErrors({
        ...listErrors,
        category: "",
      });
    } else if (name === "SPCcategory") {
      setList({
        ...list,
        [name]: value,
        ["category"]: value,
        ["CourseCate"]: "",
        ["CouresName"]: "",
        ["CourseSubCate"]: "",
      });

      // setSubjectselect([]);
      // setSelectedSub([]);
      setSpecialAdmissionPre((prevState) => {
        return prevState.map((q) => {
          return { ...q, ["name"]: "" };
        });
      });
      setBatchDetails([]);
      setListErrors({
        ...listErrors,
        SPCcategory: "",
      });
    } else if (name === "ExamDetailsRes") {
      const selectedOptions = Array.from(
        event.target.selectedOptions,
        (option) => option.value
      );
      setList((prevList) => ({ ...prevList, ExamDetailsRes: selectedOptions }));
      setSpecialAdmissionPre((prevState) => {
        return prevState.map((q) => {
          return { ...q, ["name"]: "" };
        });
      });
      setListErrors({
        ...listErrors,
        ExamDetailsRes: "",
      });
    } else if (name === "IDPhoto" && files && files.length > 0) {
      const ID_photo_Path = files[0];
      if (files[0].type == "application/pdf") {
        const formData = new FormData();
        formData.append("ID_photo_Path", ID_photo_Path);
        AxiosInstance.post("api/upload/", formData)
          .then((res) => {
            setList({
              ...list,
              IDPhoto: res.data.path,
              original_aiasa_id_path: res.data.originalname,
            });
            setListErrors({
              ...listErrors,
              IDPhoto: "",
            });
          })
          .catch((error) => {
            return error;
          });
      } else if (
        files[0].type == "application/pdf" &&
        files[0].size > 2 * 1024 * 1024
      ) {
        let err = "File size must be less than 2MB.";
        setListErrors({
          ...listErrors,
          CastFile: err,
        });
      } else {
        let err = "pdf files only allowed";
        setListErrors({
          ...listErrors,
          IDPhoto: err,
        });
      }
    } else if (name == "Verify") {
      setList((prevList) => ({
        ...prevList,
        [name]: checked,
      }));
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name == "CouponCode") {
      setCouponApplied((prevState) => ({
        ...prevState,
        coupon_code: value,
      }));
      if (value == "") {
        // alert("90");
        setCouponApplied({
          status: false,
          amount: 0,
          coupon_code: "",
        });
      }
      // setListErrors({
      //   ...listErrors,
      //   [name]: "",
      // });
    } else if (name === "ExamWrite") {
      setList({
        ...list,
        [name]: value,
      });
      setExamDetailsExam([]);
      const examdetails_bb = Array.from({ length: value }, (_, index) => ({
        id: index,
        examvalue: "",
        error_name_exam: "", // Add other properties you need in the state
      }));
      setExamDetailsExam(examdetails_bb);
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    } else if (name === "ExamPreviously") {
      if (value === "NO") {
        setList({
          ...list,
          [name]: value,
          ExamWrite: "",
        });
        setExamDetailsExam([]);
        setListErrors({
          ...listErrors,
          [name]: "",
        });
      } else {
        setList({
          ...list,
          [name]: value,
        });
        setExamDetailsExam([]);
        setListErrors({
          ...listErrors,
          [name]: "",
        });
      }
    } else {
      setList({
        ...list,
        [name]: value,
      });
      setListErrors({
        ...listErrors,
        [name]: "",
      });
    }
  };
  //handle Coupon code
  const handleCouponCode = (e, dAmount, id) => {
    let code = couponApplied.coupon_code;
    // let discountAmount = dAmount;
    // let batch_id = id;

    // sample code = AIASA_1000$

    AxiosInstance.get(`api/all/getCoupon/${code}`)
      .then((res) => {
        if (res.data[0].status === true) {
          // let lessamount = res.data[0].data.dis_amt;
          // let amount = discountAmount - lessamount;

          // setBatchDetails((prevState) => {
          //   return prevState.map((q) => {
          //     if (q.id === batch_id) {
          //       return { ...q, dis_amt: amount };
          //     }
          //     return q;
          //   });
          // });
          setCouponApplied({
            status: true,
            amount: res.data[0].data.dis_amt,
            coupon_code: res.data[0].data.id,
          });
        } else {
          Swal.fire({
            title: "warning",
            text: "coupon code is not avaliable ",
            icon: "warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });

    // if (code) {
    //   discountedPrice -= 1000;
    //   const gstAmount = discountedPrice * gstRate;
    //   const finalPrice = discountedPrice + gstAmount;
    //   alert(finalPrice);
    // } else {
    //   alert("Please Enter Coupon Code");
    // }
  };
  //dropdown api call
  useEffect(() => {
    // get response from api to fill dropdowns

    AxiosInstance.get("api/codevalues?codetype=EDQ")
      .then((res) => {
        const educationOptions = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setEducationOptions(educationOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=CLASS_EDQ")
      .then((res) => {
        const classOptions = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setClassOption(classOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=THQ")
      .then((res) => {
        const technicalOption = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        // Sort the options alphabetically, ignoring "Nil"
        const sortedOptions = technicalOption
          .filter((item) => item.value !== "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        const NilOption = technicalOption
          .filter((item) => item.value === "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        // Set the sorted list to the state
        sortedOptions.push(...NilOption);
        setTechnicalOption(sortedOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=CTGY")
      .then((res) => {
        const categoryOption = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));

        // Sort the options alphabetically, ignoring "Nil"
        const sortedOptions = categoryOption
          .filter((item) => item.value !== "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        const NilOption = categoryOption
          .filter((item) => item.value === "Nil")
          .sort((a, b) => a.value.localeCompare(b.value));
        // Set the sorted list to the state
        sortedOptions.push(...NilOption);
        setCategoryOption(sortedOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=CURS")
      .then((res) => {
        const cOption = res.data.map((item, index) => ({
          value: item.name,
          label: item.name,
        }));
        setCOption(cOption);
        setCatOption(res.data);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=MDP")
      .then((res) => {
        const teachMedium = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setTeachMedium(teachMedium);
      })
      .catch((error) => {
        return error;
      });
    // AxiosInstance.get("api/all/district/31")
    //   .then((res) => {
    //     const district = res.data.map((item) => (
    //       <option key={item.name} value={item.name}>
    //         {item.name}
    //       </option>
    //     ));
    //     setDistrict(district);
    //   })
    //   .catch((error) => {
    //     return error;
    //   });
  }, []);

  useEffect(() => {
    if (list.SPCcategory !== "") {
      let ValueAsp = "";
      if (list.SPCcategory === "UPSC") {
        ValueAsp = "UPSC_ASP";
      } else if (list.SPCcategory === "TNPSC") {
        ValueAsp = "TNPSC_ASP";
      } else if (list.SPCcategory === "TRB") {
        ValueAsp = "TRB_ASP";
      } else if (list.SPCcategory === "TET") {
        ValueAsp = "TET_ASP";
      } else if (list.SPCcategory === "TNUSRB") {
        ValueAsp = "TNUSRB_ASP";
      } else if (list.SPCcategory === "SSC") {
        ValueAsp = "SSC_ASP";
      } else if (list.SPCcategory === "Banking") {
        ValueAsp = "Banking_ASP";
      } else if (list.SPCcategory === "Others") {
        ValueAsp = "OTHERS_ASP";
      } else if (list.SPCcategory === "RRB") {
        ValueAsp = "RRB_ASP";
      }

      AxiosInstance.get(`api/codevalues?codetype=${ValueAsp}`)
        .then((res) => {
          const specialPrefrence = res.data.map((item, index) => ({
            value: item.name,
            label: item.name,
          }));
          // Sort the options alphabetically, ignoring "Nil"
          const sortedOptions = specialPrefrence
            .filter((item) => item.value !== "Nil")
            .sort((a, b) => a.value.localeCompare(b.value));
          const NilOption = specialPrefrence
            .filter((item) => item.value === "Nil")
            .sort((a, b) => a.value.localeCompare(b.value));
          // Set the sorted list to the state
          sortedOptions.push(...NilOption);
          setSpecialPrefrence(sortedOptions);
        })
        .catch((error) => {
          return error;
        });
      // setList({
      //   ...list,
      //   ['category']: list.SPCcategory,
      // });
    }
  }, [list.SPCcategory]);

  // AIASA-000128 v1 - issues update code by lalitha
  const validateReplyComment = (data) => {
    let error = {};
    if (!data.reply_student) {
      error.reply_student = "Please enter any comment";
      txtAdReply_student.current.focus();
    }
    return error;
  };

  const handleNexts = () => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        const user_type = user.user_type;
        const role_id = user.role_id;
        const errors = validateReplyComment(list);
        // console.log(errors , batchDetails);
        if (Object.keys(errors).length === 0) {
          if (list.Verify === true) {
            // setStep(step + 1);
            let bb = {
              user_id: user_id,
              admission_id: admission_id,
              type: 2, //always
              admission_status: list.admission_status,
              batch_id: batchId,
              comment: list.reply_student,
              user_type: user_type === 1 ? 0 : 1,
              read_status: 1, // send always 1
              created_by: userid,
              updated_by: userid,
            };
            // console.log(bb);
            AxiosInstance.post("api/course/admission/chat/comm", bb)
              .then((res) => {
                if (res.data.status === true) {
                  Swal.fire({
                    title: "Success",
                    text: "Reply updated successfully",
                    icon: "success",
                    confirmButtonText: "Ok",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/TransactionHistory");
                    }
                  });
                } else {
                  Swal.fire({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          } else {
            let err = "Please look and verify the Terms and Conditions.";
            txtadVerifyHere.current.focus();
            setListErrors({
              ...listErrors,
              Verify: err,
            });
          }
        } else {
          setListErrors(errors);
        }
      }
    } else {
      console.log("User Id cannot available in session storage");
    }
  };
  //step handle prev button click
  const handlePrevious = () => {
    setStep(step - 1);
  };
  //step handle next button click
  const handleNext = () => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;

        const AdmissionRef_validation = {
          firstName: txtAdFname,
          lastName: txtAdLname,
          age: txtAdAge,
          mdob: txtAdMDob,
          mStatus: txtAdMStatus,
          gender: txtAdGender,
          father: txtAdFath,
          mother: txtAdMoth,
          pSize: txtAdPSize,
          ano: txtAdAno,
          apath: txtAdApath,
          cno: txtAdCno,
          cpath: txtAdCpath,
          pno: txtAdPno,
          wno: txtAdWno,
          fpo: txtAdFpo,
          email: txtAdEmail,
          SPCcategory: txtAdSpc,
          phno: txtAdPhno,
          psname: txtAdPsname,
          parea: txtAdParea,
          pland: txtAdPland,
          ppost: txtAdPpost,
          ptaluk: txtAdPtaluk,
          ppincode: txtAdPpincode,
          pcity: txtAdPcity,
          pstate: txtAdPstate,
          pdistrict: txtAdPdistrict,
          perhno: txtAdPerhno,
          persname: txtAdPersname,
          perarea: txtAdPerarea,
          perland: txtAdPerland,
          pererpost: txtAdPererpost,
          pertaluk: txtAdPertaluk,
          perpincode: txtAdPerpincode,
          percity: txtAdPercity,
          perstate: txtAdPerstate,
          perdistrict: txtAdPerdistrict,
          education: txtAdEduc,
          eduSCer: txtAdSslc,
          eduSpass: txtAdSpass,
          eduSmarks: txtAdSmarks,
          eduSboard: txtAdSboard,
          eduSschool: txtAdSschool,
          eduSins: txtAdSins,
          eduSfile: txtAdSfile,
          eduHCer: txtAdHslc,
          eduHpass: txtAdHpass,
          eduHmarks: txtAdHmarks,
          eduHboard: txtAdHboard,
          eduHschool: txtAdHschool,
          eduHins: txtAdHins,
          eduHfile: txtAdHfile,
          eduUCer: txtAdUCer,
          eduUpass: txtAdUpass,
          eduUmarks: txtAdUmarks,
          eduUdeg: txtAdUdeg,
          eduUMaj: txtAdUMaj,
          eduUMajOther: txtAdUMajOther,
          eduPMajOther: txtAdPMajOther,
          eduIMajOther: txtAdIMajOther,
          eduDMajOther: txtAdDMajOther,
          eduCMajOther: txtAdCMajOther,
          eduUclass: txtAdUclass,
          eduUins: txtAdUins,
          eduUfile: txtAdUfile,
          eduPCer: txtAdPCer,
          eduPpass: txtAdPpass,
          eduPmarks: txtAdPmarks,
          eduPdeg: txtAdPdeg,
          eduPMaj: txtAdPMaj,
          eduPclass: txtAdPclass,
          eduPins: txtAdPins,
          eduPfile: txtAdPfile,
          eduICer: txtAdICer,
          eduIpass: txtAdIpass,
          eduImarks: txtAdImarks,
          eduIdeg: txtAdIdeg,
          eduIMaj: txtAdIMaj,
          eduIclass: txtAdIclass,
          eduIins: txtAdIins,
          eduIfile: txtAdIfile,
          eduDCer: txtAdDCer,
          eduDpass: txtAdDpass,
          eduDmarks: txtAdDmarks,
          eduDdeg: txtAdDdeg,
          eduDMaj: txtAdDMaj,
          eduDclass: txtAdDclass,
          eduDins: txtAdDins,
          eduDfile: txtAdDfile,
          eduCCer: txtAdCCer,
          eduCpass: txtAdCpass,
          eduCmarks: txtAdCmarks,
          eduCdeg: txtAdCdeg,
          eduCMaj: txtAdCMaj,
          eduCclass: txtAdCclass,
          eduCins: txtAdCins,
          eduCfile: txtAdCfile,
          eduOCer: txtAdOCer,
          eduOpass: txtAdOpass,
          eduOmarks: txtAdOmarks,
          eduOdeg: txtAdOdeg,
          eduOMaj: txtAdOMaj,
          eduOclass: txtAdOclass,
          eduOins: txtAdOins,
          eduOfile: txtAdOfile,
          category: txtAdCate,
          examPassed: txtAdExamPassed,
          examRegno: txtAdExamRegno,
          location: txtAdLoca,
          batchDetails: txtbatchDet,
          Markpaper1: txtAdMarkpaper1,
          Markpaper2: txtAdMarkpaper2,
          Markpaper3: txtAdMarkpaper3,
          Markpaper4: txtAdMarkpaper4,
          Markpaper5: txtAdMarkpaper5,
          Markpaper6: txtAdMarkpaper6,
          Markpaper7: txtAdMarkpaper7,
          Markpaper8: txtAdMarkpaper8,
          Markpaper9: txtAdMarkpaper9,
          markUpdatedMains: txtAdmarkUpdatedMains,
          markUpdatedOral: txtAdmarkUpdatedOral,
          overallrank: txtAdoverallrank,
          MarksOne: txtAdMarksOne,
          MarksTwo: txtAdMarksTwo,
          Interviewmarks: txtAdInterviewmarks,
          StenoRank: txtAdStenoRank,
          WindowRank: txtAdWindowRank,
          communalrank: txtAdcommunalrank,
          typistrank: txtAdtypistrank,
          tamilrank: txtAdtamilrank,
          PSTMRank: txtAdPSTMRank,
          DRank: txtAdDRank,
          preferredpost: txtAdpreferredpost,
          preferredep: txtAdPreferredDept,
          examdetail: txtAdEdetail,
          examclear: txtAdEclear,
          designation: txtAdDesg,
          department: txtAdDep,
          departJoin: txtAdDjoin,
          aiasayear: txtAdayear,
          aiasacate: txtAdacate,
          aiasacourse: txtAdacourse,
          aiasaIdnum: txtAdaIdnum,
          aiasafile: txtAdafile,
        };

        const errors = validateFields(
          list,
          sslcCerficates,
          educationQualification,
          step,
          selectedPre,
          selectedPreDept,
          AdmissionRef_validation,
          batchDetails,
          selectedPrev,
          specialAdmissionPre,
          setSpecialAdmissionPre,
          techQualification,
          setTechQualification
        );
        // console.log(errors, "errr");
        if (
          Object.keys(errors).length === 0 &&
          specialAdmissionPre.length > 0 &&
          specialAdmissionPre.every(
            (el) =>
              el.name !== "" &&
              (el.name !== "Tamil Medium (PSTM)" || el.pstm_type.length > 0)
          )
        ) {
          if (step === 1) {
            let SP = specialAdmissionPre.map((item) => {
              return {
                id: item.id,
                name: item.name,
                category: list.SPCcategory,
                file:
                  item.file !== null && item.file !== "null" ? item.file : "",
                path_sslc:
                  item.path_sslc !== null && item.path_sslc !== "null"
                    ? item.path_sslc
                    : "",
                path_hsc:
                  item.path_hsc !== null && item.path_hsc !== "null"
                    ? item.path_hsc
                    : "",
                path_ug:
                  item.path_ug !== null && item.path_ug !== "null"
                    ? item.path_ug
                    : "",
                path_pg:
                  item.path_pg !== null && item.path_pg !== "null"
                    ? item.path_pg
                    : "",
                original_pref_path:
                  item.original_pref_path !== null &&
                  item.original_pref_path !== "null"
                    ? item.original_pref_path
                    : "",
                original_path_sslc:
                  item.original_path_sslc !== null &&
                  item.original_path_sslc !== "null"
                    ? item.original_path_sslc
                    : "",
                original_path_hsc:
                  item.original_path_hsc !== null &&
                  item.original_path_hsc !== "null"
                    ? item.original_path_hsc
                    : "",
                original_path_ug:
                  item.original_path_ug !== null &&
                  item.original_path_ug !== "null"
                    ? item.original_path_ug
                    : "",
                original_path_pg:
                  item.original_path_pg !== null &&
                  item.original_path_pg !== "null"
                    ? item.original_path_pg
                    : "",
                pstm_type: item.pstm_type,
                admission_id: admissionID.adId ? admissionID.adId : 0,
              };
            });
            const SteponeData = {
              user_id: userid,
              step: 1,
              id: admissionID.adId ? admissionID.adId : 0,
              type: 2,
              first_name: list.FirstName,
              last_name: list.LastName,
              father_name: list.Fathername,
              mother_name: list.MotherName,
              dob: list.DOB,
              age: list.age,
              gender: list.gender,
              marital_status: list.MaritalStatus,
              profile_path: list.Profilephoto,
              aadhar_no: list.aadharNo,
              aadhar_path: list.aadharCardphoto,
              community_name: list.Cast,
              community_path: list.CastFile,
              scp: SP,
            };
            // console.log(SteponeData);
            AxiosInstance.post("api/course/admission/register", SteponeData)
              .then((res) => {
                if (res.data.status === true) {
                  setStep(step + 1);
                } else {
                  Swal.fire({
                    title: "warning",
                    text: res.data.message,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          } else if (step === 2) {
            const address = [
              {
                address1: list.BuildingNumberPresent,
                address2: list.StreetNamePresent,
                address3: list.AreaPresent,
                landmark: list.LandmarkPresent,
                post_name: list.PostPresent,
                taluk: list.TalukPresent,
                postal_code: list.PincodePresent ? list.PincodePresent : 0,
                city: list.CityPresent,
                district_name: list.districtPresent,
                state_name: list.statePresent,
                same_address: list.SamePresentAddress == true ? "0" : "1",
                address_type: "1",
              },
              {
                address1:
                  list.SamePresentAddress == true
                    ? list.BuildingNumberPresent
                    : list.BuildingNumberPermanent,
                address2:
                  list.SamePresentAddress == true
                    ? list.StreetNamePresent
                    : list.StreetNamePermanent,
                address3:
                  list.SamePresentAddress == true
                    ? list.AreaPresent
                    : list.AreaPermanent,
                landmark:
                  list.SamePresentAddress == true
                    ? list.LandmarkPresent
                    : list.LandmarkPermanent,
                post_name:
                  list.SamePresentAddress == true
                    ? list.PostPresent
                    : list.PostPermanent,
                taluk:
                  list.SamePresentAddress == true
                    ? list.TalukPresent
                    : list.TalukPermanent,
                postal_code:
                  list.SamePresentAddress == true
                    ? list.PincodePresent
                      ? list.PincodePresent
                      : 0
                    : list.PincodePermanent
                    ? list.PincodePermanent
                    : 0,
                city:
                  list.SamePresentAddress == true
                    ? list.CityPresent
                    : list.CityPermanent,
                district_name:
                  list.SamePresentAddress == true
                    ? list.districtPresent
                    : list.districtPermanent,
                state_name:
                  list.SamePresentAddress == true
                    ? list.statePresent
                    : list.statePermanent,
                same_address: list.SamePresentAddress == true ? "0" : "1",
                address_type: "2",
              },
            ];

            const StepTwoData = {
              user_id: userid,
              step: 2,
              phone_no: list.Mobile,
              country_code: list.MobileCode,
              whatsapp_no:
                list.ContactNumber === true ? list.Mobile : list.WhatsAppNumber,
              contact_type: list.ContactNumber === true ? "1" : "0",
              parent_mob_no: list.ParentMobileNo,
              email: list.emailaddress,
              address: address,
            };

            // console.log(StepTwoData);
            AxiosInstance.post("api/course/admission/register", StepTwoData)
              .then((res) => {
                if (res.data.status === true) {
                  setStep(step + 1);
                } else {
                  Swal.fire({
                    title: "warning",
                    text: res.data.message,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                }
              })
              .catch((error) => {
                return error;
              });
          } else if (step === 3) {
            const errors_thq = validateThq(
              techQualification,
              setTechQualification
            );
            if (
              errors_thq.length > 0 &&
              errors_thq.every(
                (el) =>
                  el.error_name === "" &&
                  el.error_certificate_no === "" &&
                  el.error_year_of_passing === "" &&
                  el.error_level === "" &&
                  el.error_grade === ""
              )
            ) {
              const filteredDetails = sslcCerficates.filter((detail) => {
                let cert = "";
                if (detail.name === "sslc") {
                  cert = detail.name.toUpperCase();
                } else if (detail.name === "hsc") {
                  cert = detail.name.toUpperCase();
                } else {
                  cert = detail.name;
                }
                if (detail.name === "UG") {
                  detail.UGMajor =
                    uGisOther === true ? detail.UGMajor_Other : detail.UGMajor;
                  detail.type_of_degree = uGisOther === true ? 1 : 0;
                } else if (detail.name === "PG") {
                  detail.PGMajor =
                    pGisOther === true ? detail.PGMajor_Other : detail.PGMajor;
                  detail.type_of_degree = pGisOther === true ? 1 : 0;
                } else if (detail.name === "Integrated PG") {
                  detail.IPMajor =
                    iPGisOther === true ? detail.IPMajor_Other : detail.IPMajor;
                  detail.type_of_degree = iPGisOther === true ? 1 : 0;
                } else if (detail.name === "Diploma") {
                  detail.DMajor =
                    dipisOther === true ? detail.DMajor_Other : detail.DMajor;
                  detail.type_of_degree = dipisOther === true ? 1 : 0;
                } else if (detail.name === "Professional Courses") {
                  detail.PCMajor =
                    pCisOther === true ? detail.PCMajor_Other : detail.PCMajor;
                  detail.type_of_degree = pCisOther === true ? 1 : 0;
                } else if (detail.name === "Others") {
                  detail.type_of_degree = 1;
                }
                return educationQualification.includes(cert);
              });

              const TQ = techQualification.map((item) => ({
                id: item.id,
                name: item.name,
                level:
                  item.name !== "Knowledge in computer"
                    ? item.level
                    : item.level,
                lang: item.lang,
                Cnum: item.Cnum,
                kdg_in_com:
                  item.name === "Knowledge in computer" ? item.level : "",
                grade: item.grade,
                year: item.year,
                file: item.file,
                original_file_path:
                  item.original_file_path !== null &&
                  item.original_file_path !== "null"
                    ? item.original_file_path
                    : null,
                type_of_degree: 0,
              }));
              const StepThreeData = {
                user_id: userid,
                step: 3,
                edu_details: filteredDetails,
                tech_qualification: TQ,
              };
              // console.log(StepThreeData);
              AxiosInstance.post("api/course/admission/register", StepThreeData)
                .then((res) => {
                  if (res.data.status === true) {
                    setStep(step + 1);
                  } else {
                    Swal.fire({
                      title: "warning",
                      text: res.data.message,
                      icon: "warning",
                      confirmButtonText: "Ok",
                    });
                  }
                })
                .catch((error) => {
                  return error;
                });
            }
          }
          if (step === 4) {
            const BatchDetails = batchDetails.filter((itm) => {
              return itm.is_selected === true;
            });
            // console.log(batchDetails);
            const ExamDetailsArray = examDetailsExam.map(
              (item) => item.examvalue
            );
            const transformedData = BatchDetails.map((item) => ({
              id: item.id,
              type: item.type,
              img_url: item.img_url,
              course_name: item.course_name,
              batch_details: item.batch_details,
              class_mode: item.class_mode,
              start_on: item.start_on,
              duration: item.duration,
              act_c_fee: item.act_c_fee,
              offer_spl: item.offer_spl,
              dis_amt: item.dis_amt,
              medium: item.medium,
              is_seleted: item.is_selected, // Note: You have a typo here, it should be "is_selected" not "is_seleted"
              no_of_test: item.no_of_test,
              panel_details: item.panel_details.map((panel) => ({
                pk: panel.pk,
                is_selected_panel: panel.is_selected_panel === true ? 1 : 0,
              })),
            }));
            const prePost = selectedPre.map((item) => item.value);
            const preDept = selectedPreDept.map((item) => item.value);

            const errors_ExamDetails = validateExamDetails(
              examDetailsExam,
              setExamDetailsExam
            );
            if (
              (step === 4 &&
                list.ExamPreviously === "Yes" &&
                errors_ExamDetails.length > 0 &&
                errors_ExamDetails.every(
                  (el) => el.examvalue !== "" && el.error_name_exam === ""
                )) ||
              list.ExamPreviously === "NO"
            ) {
              const StepFourData = {
                user_id: userid,
                step: 4,
                type: 2,
                id: admissionID.adId ? admissionID.adId : 0,
                Category: list.category,
                Courses: list.ExamName,
                MediumExam: list.TeachMedium,
                BatchDetails:
                  transformedData.length > 0 ? transformedData[0] : null,
                location: list.location !== "" ? parseInt(list.location) : 2,
                passed_exam_name: parseInt(list.ExamName),
                exam_reg_no: list.ExamRegno,
                preferred_depart:
                  selectedPreDept.length > 0
                    ? selectedPreDept.map((el) => el.value)
                    : null,
                written_exam_mark:
                  list.writtenexammark !== ""
                    ? parseInt(list.writtenexammark)
                    : null,
                interview_mark:
                  list.Interviewmarks !== ""
                    ? parseInt(list.Interviewmarks)
                    : null,
                overall_rank:
                  list.overallrank !== "" ? parseInt(list.overallrank) : null,
                communal_rank:
                  list.communalrank !== "" ? parseInt(list.communalrank) : null,
                rank_in_typist:
                  list.typistrank !== "" ? parseInt(list.typistrank) : null,
                rank_in_tm:
                  list.tamilrank !== "" ? parseInt(list.tamilrank) : null,
                preferred_post: prePost,
                preferred_depart: preDept,
                marks_in_tamil:
                  list.tamilrank !== "" ? parseInt(list.tamilrank) : null,
                steno_rank:
                  list.StenoRank !== "" ? parseInt(list.StenoRank) : null,
                pstm_rank:
                  list.PSTMRank !== "" ? parseInt(list.PSTMRank) : null,
                widow_rank:
                  list.WindowRank !== "" ? parseInt(list.WindowRank) : null,
                marks_in_tier1:
                  list.MarksOne !== "" ? parseInt(list.MarksOne) : null,
                marks_in_tier2:
                  list.MarksTwo !== "" ? parseInt(list.MarksTwo) : null,
                disabled_rank: list.DRank !== "" ? parseInt(list.DRank) : null,
                tot_marks_in_mains:
                  list.markUpdatedMains !== ""
                    ? parseInt(list.markUpdatedMains)
                    : null,
                marks_in_paper1:
                  list.markPaperone !== "" ? parseInt(list.markPaperone) : null,
                marks_in_paper2:
                  list.markPaperTwo !== "" ? parseInt(list.markPaperTwo) : null,
                marks_in_paper3:
                  list.markPaperThree !== ""
                    ? parseInt(list.markpaperThree)
                    : null,
                marks_in_paper4:
                  list.markPaperFour !== ""
                    ? parseInt(list.markPaperFour)
                    : null,
                marks_in_paper5:
                  list.markPaperFive !== ""
                    ? parseInt(list.markPaperFive)
                    : null,
                marks_in_paper6:
                  list.markPaperSix !== "" ? parseInt(list.markPaperSix) : null,
                marks_in_paper7:
                  list.markPaperSeven !== ""
                    ? parseInt(list.markPaperSeven)
                    : null,
                marks_in_paper8:
                  list.markPaperEight !== ""
                    ? parseInt(list.markPaperEight)
                    : null,
                marks_in_paper9:
                  list.markPaperNine !== ""
                    ? parseInt(list.markPaperNine)
                    : null,
                tot_marks_in_oral_test:
                  list.markUpdatedOral !== ""
                    ? parseInt(list.markUpdatedOral)
                    : null,
                Exam: 0,
                PrevExam: list.ExamPreviously === "Yes" ? 1 : 0,
                ExamDetail:
                  list.ExamPreviously === "Yes"
                    ? ExamDetailsArray.length > 0
                      ? ExamDetailsArray
                      : null
                    : null,
                PastExam: list.ExamPreviously === "Yes" ? list.ExamWrite : null,
                GoveEmp: list.workingRes === "Yes" ? 1 : 0,
                Designation:
                  list.workingRes === "Yes" ? list.Designation : null,
                Department: list.workingRes === "Yes" ? list.Department : null,
                JoinGovt: list.workingRes === "Yes" ? list.JoinGovt : null,
                AIASAStudent: list.AIASAStudent === "Yes" ? 1 : 0,
                Year: list.AIASAStudent === "Yes" ? list.Year : null,
                Aiasa_category:
                  list.AIASAStudent === "Yes" ? list.CategoryAias : null,
                Aiasa_course:
                  list.AIASAStudent === "Yes" ? list.CourseAias : null,
                IDNumber: list.AIASAStudent === "Yes" ? list.IDNumber : null,
                original_aiasa_id_path:
                  list.AIASAStudent === "Yes"
                    ? list.original_aiasa_id_path
                    : null,
                IdCardPath: list.AIASAStudent === "Yes" ? list.IDPhoto : null,
                updated_by: userid,
              };

              // console.log(StepFourData);
              AxiosInstance.post("api/course/admission/register", StepFourData)
                .then((res) => {
                  // console.log(res);
                  if (res.data.status === true) {
                    // console.log(res.data.data,"stepfour admission id");
                    if (res.data.data) {
                      setAdmissionID({
                        adId: res.data.data,
                      });
                    }
                    setStep(step + 1);
                  } else {
                    Swal.fire({
                      title: "warning",
                      text: res.data.message,
                      icon: "warning",
                      confirmButtonText: "Ok",
                    });
                  }
                })
                .catch((error) => {
                  return error;
                });
            }
            // console.log(selectedPre);
          } else if (step === 5) {
            if (list.Verify === true) {
              setStep(step + 1);
            } else {
              let err = "Please look and verify the Terms and Conditions.";
              txtadVerifyHere.current.focus();
              setListErrors({
                ...listErrors,
                Verify: err,
              });
            }
          }
          // setStep(step + 1);
          // appendToStorage("PersonalDetails", JSON.stringify(list));
        } else {
          setListErrors(errors);
        }
      }
    } else {
      console.log("User Id cannot available in session storage");
    }
  };
  const validateThq = (techQualification, setTechQualification) => {
    if (techQualification && techQualification.length > 0) {
      const updateTHQ = techQualification.map((OB) => {
        let updatedOB = { ...OB };
        // console.log(techQualification,"techQualification");
        if (!OB.name) {
          updatedOB.error_name = "Name is required";
        }
        if (OB.name === "Nil") {
          updatedOB.error_name = "";
          updatedOB.error_level = "";
          updatedOB.error_edu_medium = "";
          updatedOB.error_certificate_no = "";
          updatedOB.error_year_of_passing = "";
          updatedOB.error_grade = "";
        }
        if (!OB.level && OB.name !== "Nil") {
          updatedOB.error_level = "Level is required";
        }

        if (!OB.lang && OB.name !== "Nil") {
          updatedOB.error_edu_medium = "Lang is required";
        }

        if (!OB.Cnum && OB.name !== "Nil") {
          updatedOB.error_certificate_no = "Certificate No is required";
        }

        if (!OB.year && OB.name !== "Nil") {
          updatedOB.error_year_of_passing = "Year of passing is required";
        }

        if (!OB.grade && OB.name !== "Nil") {
          updatedOB.error_grade = "Grade is required";
        }

        return updatedOB;
      });
      setTechQualification(updateTHQ);
      return updateTHQ;
    }
  };

  const validateExamDetails = (examDetailsExam, setExamDetailsExam) => {
    if (
      step === 4 &&
      examDetailsExam.length > 0 &&
      list.ExamPreviously === "Yes"
    ) {
      const updateExam = examDetailsExam.map((OB) => {
        let updatedOB = { ...OB };
        if (!OB.examvalue) {
          updatedOB.error_name_exam = "Exam Name is required";
          txtAdEdetail.current.focus();
        } else {
          updatedOB.error_name_exam = "";
        }
        return updatedOB;
      });
      setExamDetailsExam(updateExam);
      return updateExam;
    }
  };
  //  front end
  return (
    <div className="AdmissionForm_section AdmissionForm">
      <h4 className="Title">Counselling Awareness Program Admission</h4>
      <form>
        <div className="row progressTitle">
          <div className={`col-md-2 col-2 col-sm-12 col_progress `}>
            <div
              className={`${
                step === 1 ||
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6 ||
                step === 7
                  ? "Card"
                  : "  Cards"
              }`}
            >
              <p
                className="circle"
                onClick={
                  step === 2 ||
                  step === 3 ||
                  step === 4 ||
                  step === 5 ||
                  step === 6 ||
                  step === 7
                    ? () => handleStepPrevious(1) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6 ||
                step === 7
                  ? "✓"
                  : "1"}
              </p>
              <p className="cTitle fw-semibold">Personal Profile</p>
              <p className="cSubtitle">Define Your Identity</p>
              <span
                className={`${
                  step === 2 ||
                  step === 3 ||
                  step === 4 ||
                  step === 5 ||
                  step === 6 ||
                  step === 7
                    ? "Stepborders"
                    : "Stepborder"
                } display_none_Stepborder`}
              ></span>
            </div>
          </div>
          <div className={`col-md-2 col-2 col-sm-12 col_progress `}>
            <div
              className={`${
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6 ||
                step === 7
                  ? "Card"
                  : "  Cards"
              }`}
            >
              <p
                className="circle"
                onClick={
                  step === 3 ||
                  step === 4 ||
                  step === 5 ||
                  step === 6 ||
                  step === 7
                    ? () => handleStepPrevious(2) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6 ||
                step === 7
                  ? "✓"
                  : "2"}
              </p>
              <p className="cTitle ">Communication</p>
              <p className="cSubtitle">Stay Connected Always</p>
              <span
                className={`${
                  step === 3 ||
                  step === 4 ||
                  step === 5 ||
                  step === 6 ||
                  step === 7
                    ? "Stepborders"
                    : "Stepborder"
                } display_none_Stepborder`}
              ></span>
            </div>
          </div>
          <div className={`col-md-2 col-2 col-sm-12 `}>
            <div
              className={`${
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6 ||
                step === 7
                  ? "Card"
                  : "  Cards"
              }`}
            >
              <p
                className="circle"
                onClick={
                  step === 4 || step === 5 || step === 6 || step === 7
                    ? () => handleStepPrevious(3) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 4 || step === 5 || step === 6 || step === 7
                  ? "✓"
                  : "3"}
              </p>
              <p className="cTitle ">Academic Background</p>
              <p className="cSubtitle ">Your Learning Journey</p>
              <span
                className={`${
                  step === 4 || step === 5 || step === 6 || step === 7
                    ? "Stepborders"
                    : "Stepborder"
                } display_none_Stepborder`}
              ></span>
            </div>
          </div>
          <div className={`col-md-2 col-2 col-sm-12 `}>
            <div
              className={`${
                step === 4 || step === 5 || step === 6 || step === 7
                  ? "Card"
                  : "  Cards"
              }`}
            >
              <p
                className="circle"
                onClick={
                  step === 5 || step === 6 || step === 7
                    ? () => handleStepPrevious(4) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 5 || step === 6 || step === 7 ? "✓" : "4"}
              </p>
              <p className="cTitle ">Counselling Awareness </p>
              <p className="cSubtitle ">Choose Your Course</p>
              <span
                className={`${
                  step === 5 || step === 6 || step === 7
                    ? "Stepborders"
                    : "Stepborder"
                } display_none_Stepborder`}
              ></span>
            </div>
          </div>

          <div className={`col-md-2 col-2 col-sm-12 col_end_progress `}>
            <div
              className={`${
                step === 5 || step === 6 || step === 7 ? "Card" : "  Cards"
              }`}
            >
              <p
                className="circle"
                onClick={
                  step === 6 || step === 7
                    ? () => handleStepPrevious(5) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 6 || step === 7 ? "✓" : "5"}
              </p>
              <p className="cTitle ">Review and Confirm</p>
              <p className="cSubtitle ">Review Your Details</p>
              <span
                className={`${
                  step === 6 || step === 7 ? "Stepborders" : "Stepborder"
                } display_none_Stepborder`}
              ></span>
            </div>
          </div>
          <div className={`col-md-2 col-2 col-sm-12 col_end_progress `}>
            <div className={`${step === 6 || step === 7 ? "Card" : "  Cards"}`}>
              <p
                className="circle"
                onClick={
                  step === 7
                    ? () => handleStepPrevious(6) // Wrap the call in an arrow function
                    : handleNext
                }
              >
                {step === 7 ? "✓" : "6"}
              </p>
              <p className="cTitle ">Payment Info</p>
              <p className="cSubtitle ">Your Course, Your Cost</p>
            </div>
          </div>
        </div>
        {/* {step === 1} */}
        {step === 1 && (
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <section className=" StepOne ">
                  <div className="heading  mt-3 d-flex justify-content-end ">
                    {/* <Link to="/" className="CancelButton">
                          Back to Home
                        </Link> */}

                    <button
                      type="button"
                      className="SaveButton"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Communication
                      </a>
                    </button>
                  </div>
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">{LBL_BASIC_DETAILS}</p>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="FirstName">First Name</label>
                            <input
                              ref={txtAdFname}
                              id="FirstName"
                              type="text"
                              name="FirstName"
                              className="Inputs"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              placeholder="First Name"
                              value={list.FirstName}
                              autoComplete="FirstName"
                              minLength={5}
                              maxLength={15}
                              required
                            />
                            {listErrors.FirstName && (
                              <p className="errors">{listErrors.FirstName}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="LastName">Last Name</label>
                            <input
                              ref={txtAdLname}
                              id="LastName"
                              type="text"
                              name="LastName"
                              placeholder=" Last Name"
                              className="Inputs"
                              minLength={5}
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              maxLength={15}
                              onChange={handleChange}
                              value={list.LastName}
                              autoComplete="LastName"
                              required
                            />
                            {listErrors.LastName && (
                              <p className="errors">{listErrors.LastName}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Fathername">
                              Father's Name/Guardian's Name
                            </label>
                            <input
                              ref={txtAdFath}
                              id="Fathername"
                              type="text"
                              name="Fathername"
                              placeholder=" Fathers Name"
                              onChange={handleChange}
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={list.Fathername}
                              className="Inputs"
                              minLength={5}
                              maxLength={50}
                              autoComplete="Fathername"
                              required
                            />
                            {listErrors.Fathername && (
                              <p className="errors">{listErrors.Fathername}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="MotherName">Mother's Name</label>
                            <input
                              ref={txtAdMoth}
                              id="MotherName"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="MotherName"
                              onChange={handleChange}
                              value={list.MotherName}
                              placeholder=" Mother Name"
                              className="Inputs"
                              minLength={5}
                              maxLength={15}
                              autoComplete="MotherName"
                              required
                            />
                            {listErrors.MotherName && (
                              <small className="errors fw-semibold">
                                {listErrors.MotherName}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="DOB">Date of Birth</label>
                            {/* <input
                                  ref={txtAdMDob}
                                  id="DOB"
                                  type="date"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  name="DOB"
                                  onChange={handleChange}
                                  value={list.DOB}
                                  className="Inputs"
                                  autoComplete="DOB"
                                  required
                                /> */}
                            <DatePickerSample
                              disabledProperty={
                                list.admission_status === 1 ? true : false
                              }
                              ref={txtAdMDob}
                              name={"DOB"}
                              handleChange={handleChangeDate}
                              fieldInput={list.DOB}
                            />
                            {listErrors.DOB && (
                              <p className="errors">{listErrors.DOB}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="age">Age</label>
                            <input
                              ref={txtAdAge}
                              id="age"
                              type="text"
                              readOnly
                              name="age"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={list.age}
                              className="Inputs"
                              placeholder="Age"
                              autoComplete="age"
                              required
                            />
                            {listErrors.age && (
                              <p className="errors">{listErrors.age}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <div className="forms row  ">
                            <label htmlFor="Gender">Gender</label>
                            <div
                              className="d-flex  col-md-12"
                              id="radiobuttons"
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  ref={txtAdGender}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="gender"
                                  onChange={handleChange}
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={
                                    list.gender === "Male" ? true : false
                                  }
                                  id="Male"
                                  value="Male"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Male"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="gender"
                                  id="Female"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  onChange={handleChange}
                                  checked={
                                    list.gender === "Female" ? true : false
                                  }
                                  value="Female"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Female"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="gender"
                                  id="Transgender"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  onChange={handleChange}
                                  checked={
                                    list.gender === "Transgender" ? true : false
                                  }
                                  value="Transgender"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Transgender"
                                >
                                  Transgender
                                </label>
                              </div>
                            </div>
                          </div>
                          {listErrors.gender && (
                            <small className="errors fw-semibold">
                              {listErrors.gender}
                            </small>
                          )}
                        </div>

                        <div className="col-md-6">
                          <div className="forms row  ">
                            <label htmlFor="MaritalStatus">
                              Marital Status
                            </label>
                            <div
                              className="d-flex ms-4  col-md-12"
                              id="radiobuttons"
                            >
                              <div className="form-checks form-check-inline">
                                <input
                                  ref={txtAdMStatus}
                                  className="checkbox_radio"
                                  type="radio"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  name="MaritalStatus"
                                  onChange={handleChange}
                                  checked={
                                    list.MaritalStatus === "Single/Unmarried"
                                      ? true
                                      : false
                                  }
                                  id="Single/Unmarried"
                                  value="Single/Unmarried"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Single/Unmarried"
                                >
                                  Single/Unmarried
                                </label>
                              </div>
                              <div className="form-checks form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  name="MaritalStatus"
                                  id="Married"
                                  onChange={handleChange}
                                  checked={
                                    list.MaritalStatus === "Married"
                                      ? true
                                      : false
                                  }
                                  value="Married"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Married"
                                >
                                  Married
                                </label>
                              </div>

                              <div className="form-checks form-check-inline">
                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="MaritalStatus"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  id="Widowed"
                                  onChange={handleChange}
                                  checked={
                                    list.MaritalStatus === "Widowed"
                                      ? true
                                      : false
                                  }
                                  value="Widowed"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="Widowed"
                                >
                                  Widowed
                                </label>
                              </div>
                            </div>
                          </div>
                          {listErrors.MaritalStatus && (
                            <small className="errors fw-semibold">
                              {listErrors.MaritalStatus}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="sectionTwo ">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Passport Size Photograph</p>
                      <div className="row">
                        <div className="col-md-6 d-flex  justify-content-center">
                          <div className="forms">
                            <img
                              className="ProfilePhoto  bg-opacity-75"
                              src={`${ImageUrlPrefix}/${list.Profilephoto}`}
                              alt="profile"
                            />

                            {!list.Profilephoto && (
                              <span id="text">Profile Picture</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Profilephoto">
                              Upload Passport Size Photograph
                            </label>
                            <input
                              ref={txtAdPSize}
                              id="Profilephoto"
                              type="file"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="Profilephoto"
                              onChange={handleChange}
                              className="InputsFile"
                              autoComplete="Profilephoto"
                              required
                            />
                            {listErrors.Profilephoto && (
                              <small className="errors fw-semibold">
                                {listErrors.Profilephoto}
                              </small>
                            )}
                          </div>

                          <div className="forms mt-4 ms-md-3 mb-4">
                            <h6 className="profileText">Note:</h6>
                            <p className="warningOne my-2">
                              Image format: The recommended image format for
                              profile pictures on Anna IAS Academy is JPEG or
                              PNG.
                            </p>
                            <h6 className="warningTwo">
                              <span className="fw-semibold">Image size</span>:
                              Profile pictures should be a minimum of 400x400
                              pixels and a maximum of 1024x1024 pixels. The file
                              size should not exceed 40 KB.
                            </h6>
                            <h6 className="warningTwo">
                              <span className="fw-semibold">
                                Image quality and content
                              </span>
                              : Choose a clear and high-quality image for your
                              profile picture. Your profile picture should be
                              appropriate and professional.
                            </h6>
                            <h6 className="warningTwo">
                              <span className="fw-semibold">
                                Image orientation
                              </span>
                              :Profile pictures should be in a square format, as
                              they will be displayed as a circle on the
                              platform. Make sure your image is not stretched or
                              distorted.
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className=" sectionTwo">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">{LBL_ID_PROOFS}</p>

                      <div className="row mb-4">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="aadharNo">
                              AADHAR Number
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            <input
                              ref={txtAdAno}
                              id="aadharNo"
                              type="number"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="aadharNo"
                              onChange={handleChange}
                              value={list.aadharNo < 0 ? 0 : list.aadharNo}
                              className="Inputs"
                              placeholder="Aadhar Number"
                              autoComplete="aadharNo"
                              required
                              minLength={12}
                            />
                            {listErrors.aadharNo && (
                              <p className="errors">{listErrors.aadharNo}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="forms ">
                            <label htmlFor="aadharCardphoto">
                              Upload AADHAR Card (In pdf/Image format)
                              {list.aadharCardphoto !== "" &&
                                list.aadharCardphoto !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${list.aadharCardphoto}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  list.aadharCardphoto !== "" &&
                                  list.aadharCardphoto !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtAdApath}
                                  id="aadharCardphoto"
                                  type="file"
                                  name="aadharCardphoto"
                                  placeholder="Upload AADHAR Card"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className={
                                    list.aadharCardphoto !== "" &&
                                    list.aadharCardphoto !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  onChange={handleChange}
                                  autoComplete="aadharCardphoto"
                                  required
                                />
                              </div>
                              {list.aadharCardphoto !== "" &&
                                list.aadharCardphoto !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {list.aadharCardphoto !== "" &&
                                      list.aadharCardphoto !== null
                                        ? list.original_aadhar_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>
                            {listErrors.aadharCardphoto && (
                              <p className="errors">
                                {listErrors.aadharCardphoto}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="  sectionTwo">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Communal Reservation Category</p>

                      <div className="row mb-4">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Cast">Category</label>
                            <Select
                              ref={txtAdExamPassed}
                              id="ExamName"
                              name="ExamName"
                              isDisabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={dropdown.ExamName}
                              onChange={(option) =>
                                handleSelectMulti(
                                  option,
                                  "ExamName",
                                  "ExamName"
                                )
                              }
                              options={
                                coursesCate
                                  .filter((item) => item && item.label) // Filter out null and undefined elements
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  ) // Sort options alphabetically by label
                              }
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />

                            {listErrors.Cast && (
                              <small className="errors fw-semibold">
                                {listErrors.Cast}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="forms ">
                            <label htmlFor="CastFile">
                              Upload Community certificate (In pdf/Image format){" "}
                              <span className="optionFields">(Optional)</span>
                              {list.CastFile !== "" &&
                                list.CastFile !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${list.CastFile}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  list.CastFile !== "" && list.CastFile !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtAdCpath}
                                  id="CastFile"
                                  type="file"
                                  name="CastFile"
                                  onChange={handleChange}
                                  className={
                                    list.CastFile !== "" &&
                                    list.CastFile !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  placeholder="Upload Certificate"
                                  autoComplete="CastFile"
                                />
                              </div>
                              {list.CastFile !== "" &&
                                list.CastFile !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {list.CastFile !== "" &&
                                      list.CastFile !== null
                                        ? list.original_caste_path
                                        : ""}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>

                            {listErrors.CastFile && (
                              <small className="errors fw-semibold">
                                {listErrors.CastFile}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className=" sectionTwo">
                  {specialAdmissionPre.map((item, index) => (
                    <SpecialPre
                      key={index.toString()}
                      record={item}
                      admission_status={1}
                      length={specialAdmissionPre.length}
                      specialAdmissionHandleChanges={
                        specialAdmissionHandleChanges
                      }
                      specialHandleChange={specialAdmissionHandleChange}
                      sPrefrence={specialPrefrence}
                      Deletespre={DeleteAdmissionspre}
                      AddPrefrence={AddPrefrence}
                      txtAdSpc={txtAdSpc}
                      handleChange={handleChange}
                      list={list.SPCcategory}
                      listErrors={listErrors.SPCcategory}
                    />
                  ))}

                  <div className="heading mb-5  d-flex justify-content-end ">
                    {/* <Link to="/" className="CancelButton">
                          Back to Home
                        </Link> */}
                    <button
                      type="button"
                      className="SaveButton"
                      onClick={handleNext}
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Communication
                      </a>
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </fieldset>
        )}
        {/* {step === 2} */}
        {step === 2 && (
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <section className=" sectionThree">
                  <div className="heading  dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">Go to Personal Details</a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButtons"
                        onClick={handleNext}
                      >
                        <a href="javascript:void(0)">
                          Save and Continue to Academic Background
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">{LBL_CONTACT_DETAILS}</p>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="Mobile">Phone Number</label>
                            <input
                              ref={txtAdPno}
                              id="Mobile"
                              type="tel"
                              name="Mobile"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={list.Mobile}
                              className="Inputs_Phone"
                              placeholder="Phone Number"
                              autoComplete="Mobile"
                              required
                              minLength={10}
                              maxLength={10}
                              inputMode="tel"
                            />
                            {listErrors.Mobile && (
                              <p className="errors">{listErrors.Mobile}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="WhatsAppNumber ">
                                WhatsApp Number
                              </label>
                              <label>
                                <input
                                  className=" form-check-input me-2 align-items-center"
                                  type="checkbox"
                                  id="ContactNumber"
                                  name="ContactNumber"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  onChange={handleChange}
                                  value={list.ContactNumber}
                                  checked={
                                    list.ContactNumber === true ? true : false
                                  }
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="ContactNumber"
                                >
                                  Same as Contact Number
                                </span>
                              </label>
                            </div>
                            <input
                              ref={txtAdWno}
                              id="WhatsAppNumber"
                              type="tel"
                              name="WhatsAppNumber"
                              placeholder="WhatsApp Number"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              minLength={10}
                              maxLength={10}
                              className="Inputs_Phone"
                              onBlur={handleblur}
                              onChange={handleChange}
                              value={
                                list.ContactNumber !== true
                                  ? list.WhatsAppNumber
                                  : list.Mobile
                              }
                              autoComplete="WhatsAppNumber"
                              required
                              inputMode="tel"
                            />
                            {listErrors.WhatsAppNumber && (
                              <p className="errors">
                                {listErrors.WhatsAppNumber}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="ParentMobileNo">
                              Father/Guardian Phone Number
                            </label>
                            <input
                              ref={txtAdFpo}
                              id="ParentMobileNo"
                              type="text"
                              name="ParentMobileNo"
                              className="Inputs_Phone"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              inputMode="tel"
                              minLength={10}
                              maxLength={10}
                              onChange={handleChange}
                              value={list.ParentMobileNo}
                              placeholder="MobileNo"
                              autoComplete="ParentMobileNo"
                              required
                            />
                            {listErrors.ParentMobileNo && (
                              <p className="errors">
                                {listErrors.ParentMobileNo}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="emailaddress">Email ID</label>
                            <input
                              ref={txtAdEmail}
                              id="emailaddress"
                              type="email"
                              name="emailaddress"
                              readOnly
                              className="Inputs"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={list.emailaddress}
                              placeholder=" Email ID"
                              autoComplete="emailaddress"
                              required
                            />
                            {listErrors.emailaddress && (
                              <p className="errors">
                                {listErrors.emailaddress}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className=" sectionFour">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Present Address</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="BuildingNumberPresent">
                              House/Flat Number & Name
                            </label>
                            <input
                              ref={txtAdPhno}
                              id="BuildingNumberPresent"
                              type="text"
                              name="BuildingNumberPresent"
                              className="Inputs"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={list.BuildingNumberPresent}
                              placeholder=" House/Building Number"
                              autoComplete="BuildingNumberPresent"
                              required
                              // minLength={20}
                              // maxLength={20}
                            />
                            {listErrors.BuildingNumberPresent && (
                              <p className="errors">
                                {listErrors.BuildingNumberPresent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <div className="forms ">
                              <label htmlFor="StreetNamePresent">
                                Street Name
                              </label>
                              <input
                                ref={txtAdPsname}
                                id="StreetNamePresent"
                                type="text"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="StreetNamePresent"
                                className="Inputs"
                                onChange={handleChange}
                                value={list.StreetNamePresent}
                                placeholder="Street Name"
                                autoComplete="StreetNamePresent"
                                required
                              />
                              {listErrors.StreetNamePresent && (
                                <p className="errors">
                                  {listErrors.StreetNamePresent}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="AreaPresent">Locality/Area</label>
                            <input
                              ref={txtAdParea}
                              id="AreaPresent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="AreaPresent"
                              className="Inputs"
                              onChange={handleChange}
                              value={list.AreaPresent}
                              placeholder="Locality/Area"
                              autoComplete="AreaPresent"
                              required
                            />
                            {listErrors.AreaPresent && (
                              <p className="errors">{listErrors.AreaPresent}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="LandmarkPresent">Landmark</label>
                            <input
                              ref={txtAdPland}
                              id="LandmarkPresent"
                              type="text"
                              name="LandmarkPresent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={handleChange}
                              value={list.LandmarkPresent}
                              placeholder=" Landmark"
                              autoComplete="LandmarkPresent"
                              required
                            />
                            {listErrors.LandmarkPresent && (
                              <p className="errors">
                                {listErrors.LandmarkPresent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="PostPresent">Post</label>
                            <input
                              ref={txtAdPpost}
                              id="PostPresent"
                              type="text"
                              name="PostPresent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={handleChange}
                              value={list.PostPresent}
                              placeholder=" Post"
                              autoComplete="PostPresent"
                              required
                            />

                            {listErrors.PostPresent && (
                              <p className="errors">{listErrors.PostPresent}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="TalukPresent">Taluk</label>
                            <input
                              ref={txtAdPtaluk}
                              id="TalukPresent"
                              type="text"
                              name="TalukPresent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={handleChange}
                              value={list.TalukPresent}
                              placeholder=" Taluk"
                              autoComplete="TalukPresent"
                              required
                            />
                            {listErrors.TalukPresent && (
                              <p className="errors">
                                {listErrors.TalukPresent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="PincodePresent">
                              Pin/Postal Code
                            </label>
                            <input
                              ref={txtAdPpincode}
                              id="PincodePresent"
                              type="text"
                              onChange={handleChange}
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={list.PincodePresent}
                              name="PincodePresent"
                              className="Inputs"
                              placeholder="Pin/Postal Code"
                              autoComplete="PincodePresent"
                              required
                            />
                            {listErrors.PincodePresent && (
                              <p className="errors">
                                {listErrors.PincodePresent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="CityPresent">
                              City/Town/Village
                            </label>
                            <Select
                              ref={txtAdPcity}
                              id="CityPresent"
                              name="CityPresent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={
                                list.CityPresent !== null &&
                                list.CityPresent !== ""
                                  ? {
                                      value: list.CityPresent,
                                      label: list.CityPresent,
                                    }
                                  : null
                              }
                              onChange={(option) =>
                                handleSelectCH(option, "CityPresent")
                              }
                              options={
                                city
                                  .filter((item) => item && item.label) // Filter out null and undefined elements
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  ) // Sort options alphabetically by label
                              }
                              isSearchable={true}
                              placeholder="Select an option"
                              maxMenuHeight={200}
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              }
                              styles={customStyles}
                            />

                            {/* <select
                                  ref={txtAdPcity}
                                  id="CityPresent"
                                  name="CityPresent"
                                  className="Inputs"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  onChange={handleChange}
                                  defaultValue={list.CityPresent}
                                  required
                                >
                                  <option value="" disabled>
                                    Please select
                                  </option>
                                  {city}
                                </select> */}

                            {listErrors.CityPresent && (
                              <p className="errors">{listErrors.CityPresent}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row  mb-4">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="districtPresent" id="hio">
                              District{" "}
                            </label>
                            <input
                              ref={txtAdPdistrict}
                              id="districtPresent"
                              type="text"
                              value={list.districtPresent}
                              name="districtPresent"
                              className="Inputs"
                              placeholder="district"
                              disabled
                              required
                            />

                            {listErrors.districtPresent && (
                              <p className="errors">
                                {listErrors.districtPresent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="statePresent" id="hio">
                              State/Union Territory
                            </label>
                            <input
                              ref={txtAdPstate}
                              id="statePresent"
                              type="text"
                              value={list.statePresent}
                              name="statePresent"
                              className="Inputs"
                              placeholder=" State/Union Territory"
                              disabled
                              required
                            />
                            {/* <select
                          id="statePresent"
                          name="statePresent"
                          className="Inputs"
                          onChange={handleChange}
                          defaultValue={fieldInput.statePresent}
                          required
                        >
                          <option value="" disabled selected>
                            
                          </option>
                        </select> */}
                            {listErrors.statePresent && (
                              <p className="errors">
                                {listErrors.statePresent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className=" sectionFour ">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="final">Permanent Address</p>

                      <div className="row row mt-4 ms-2">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between">
                            <label>
                              <input
                                className="me-2 form-check-input align-items-center"
                                type="checkbox"
                                id="SamePresentAddress"
                                name="SamePresentAddress"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                onChange={handleChange}
                                value={list.SamePresentAddress}
                                checked={
                                  list.SamePresentAddress === true
                                    ? true
                                    : false
                                }
                              />
                              <span
                                className="form-check-label"
                                htmlFor="SamePresentAddress"
                              >
                                Same as Present Address
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="BuildingNumberPermanent">
                              House/Flat Number & Name
                            </label>
                            <input
                              ref={txtAdPerhno}
                              id="BuildingNumberPermanent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              type="text"
                              name="BuildingNumberPermanent"
                              className="Inputs"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.BuildingNumberPermanent
                                  : list.BuildingNumberPresent
                              }
                              placeholder=" House/Building Number"
                              autoComplete="BuildingNumberPermanent"
                              required
                            />
                            {listErrors.BuildingNumberPermanent && (
                              <p className="errors">
                                {listErrors.BuildingNumberPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <div className="forms ">
                              <label htmlFor="StreetNamePermanent">
                                Street Name
                              </label>
                              <input
                                ref={txtAdPersname}
                                id="StreetNamePermanent"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                type="text"
                                name="StreetNamePermanent"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.SamePresentAddress !== true
                                    ? list.StreetNamePermanent
                                    : list.StreetNamePresent
                                }
                                placeholder="Street Name"
                                autoComplete="StreetNamePermanent"
                                required
                              />
                              {listErrors.StreetNamePermanent && (
                                <p className="errors">
                                  {listErrors.StreetNamePermanent}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="AreaPermanent">Locality/Area</label>
                            <input
                              ref={txtAdPerarea}
                              id="AreaPermanent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="AreaPermanent"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.AreaPermanent
                                  : list.AreaPresent
                              }
                              className="Inputs"
                              placeholder="Locality/Area"
                              autoComplete="AreaPermanent"
                              required
                            />
                            {listErrors.AreaPermanent && (
                              <p className="errors">
                                {listErrors.AreaPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="LandmarkPermanent">Landmark</label>
                            <input
                              ref={txtAdPerland}
                              id="LandmarkPermanent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="LandmarkPermanent"
                              className="Inputs"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.LandmarkPermanent
                                  : list.LandmarkPresent
                              }
                              placeholder="Landmark"
                              autoComplete="LandmarkPermanent"
                              required
                            />
                            {listErrors.LandmarkPermanent && (
                              <p className="errors">
                                {listErrors.LandmarkPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="PostPermanent">Post</label>
                            <input
                              ref={txtAdPererpost}
                              id="PostPermanent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              name="PostPermanent"
                              className="Inputs"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.PostPermanent
                                  : list.PostPresent
                              }
                              placeholder=" Post"
                              autoComplete="PostPermanent"
                              required
                            />

                            {listErrors.PostPermanent && (
                              <p className="errors">
                                {listErrors.PostPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="TalukPermanent">Taluk</label>
                            <input
                              ref={txtAdPertaluk}
                              id="TalukPermanent"
                              type="text"
                              name="TalukPermanent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.TalukPermanent
                                  : list.TalukPresent
                              }
                              placeholder=" Taluk"
                              autoComplete="TalukPermanent"
                              required
                            />
                            {listErrors.TalukPermanent && (
                              <p className="errors">
                                {listErrors.TalukPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="PincodePermanent">
                              Pin/Postal Code
                            </label>
                            <input
                              ref={txtAdPerpincode}
                              id="PincodePermanent"
                              type="text"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.PincodePermanent
                                  : list.PincodePresent
                              }
                              name="PincodePermanent"
                              className="Inputs"
                              placeholder="Pin/Postal Code"
                              autoComplete="PincodePermanent"
                              required
                            />
                            {listErrors.PincodePermanent && (
                              <p className="errors">
                                {listErrors.PincodePermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="CityPermanent">
                              City/Town/Village
                            </label>

                            {list.SamePresentAddress !== true ? (
                              <Select
                                ref={txtAdPercity}
                                id="CityPermanent"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="CityPermanent"
                                value={
                                  list.CityPermanent !== null &&
                                  list.CityPermanent !== ""
                                    ? {
                                        value: list.CityPermanent,
                                        label: list.CityPermanent,
                                      }
                                    : null
                                }
                                onChange={(option) =>
                                  handleSelectCH(option, "CityPermanent")
                                }
                                options={
                                  city
                                    .filter((item) => item && item.label) // Filter out null and undefined elements
                                    .sort((a, b) =>
                                      a.label.localeCompare(b.label)
                                    ) // Sort options alphabetically by label
                                }
                                isSearchable={true}
                                placeholder="Select an option"
                                maxMenuHeight={200}
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                }
                                styles={customStyles}
                              />
                            ) : (
                              <input
                                ref={txtAdPercity}
                                id="CityPermanent"
                                type="text"
                                onChange={handleChange}
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={
                                  list.SamePresentAddress === true
                                    ? list.CityPresent
                                    : list.CityPermanent
                                }
                                name="CityPermanent"
                                className="Inputs"
                                placeholder="City"
                                autoComplete="CityPermanent"
                                required
                              />
                            )}

                            {listErrors.CityPermanent && (
                              <p className="errors">
                                {listErrors.CityPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row  mb-4">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="districtPermanent" id="hio">
                              District
                            </label>
                            <input
                              ref={txtAdPerdistrict}
                              id="districtPermanent"
                              type="text"
                              onChange={handleChange}
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              value={
                                list.SamePresentAddress !== true
                                  ? list.districtPermanent
                                  : list.districtPresent
                              }
                              name="districtPermanent"
                              className="Inputs"
                              placeholder="district"
                              autoComplete="districtPermanent"
                              required
                            />

                            {listErrors.districtPermanent && (
                              <p className="errors">
                                {listErrors.districtPermanent}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="statePermanent" id="hio">
                              State/Union Territory
                            </label>
                            <input
                              ref={txtAdPerstate}
                              id="statePermanent"
                              type="text"
                              onChange={handleChange}
                              value={
                                list.SamePresentAddress !== true
                                  ? list.statePermanent
                                  : list.statePresent
                              }
                              name="statePermanent"
                              disabled={
                                list.admission_status === 1 ? true : false
                              }
                              className="Inputs"
                              placeholder="State/Union Territory"
                              autoComplete="statePermanent"
                              required
                            />

                            {listErrors.statePermanent && (
                              <p className="errors">
                                {listErrors.statePermanent}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="heading  mb-4 dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">Go to Personal Details</a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButtons"
                        onClick={handleNext}
                      >
                        <a href="javascript:void(0)">
                          Save and Continue to Academic Background
                        </a>
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </fieldset>
        )}
        {/* {step === 3} */}
        {step === 3 && (
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <section className="  sectionFive">
                  <div className="heading  dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">Go to Communication</a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButtons"
                        onClick={handleNext}
                      >
                        <a href="javascript:void(0)">
                          Save and Continue to Course Selection Details
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Educational Qualification</p>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="forms row ">
                            <label htmlFor="EducationQualification">
                              Select All The Academic Certificates You Have
                            </label>
                            <div
                              className="d-flex mt-4 ms-3  col-md-12"
                              id="radiobuttons"
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  ref={txtAdEduc}
                                  className="form-check-input"
                                  type="checkbox"
                                  id="EducationQualification1"
                                  value="SSLC"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={
                                    educationQualification.includes("SSLC")
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="EducationQualification1"
                                >
                                  SSLC
                                </span>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="EducationQualification2"
                                  value="HSC"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={
                                    educationQualification.includes("HSC")
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="EducationQualification2"
                                >
                                  HSC
                                </span>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="EducationQualification3"
                                  value="UG"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={educationQualification.includes(
                                    "UG"
                                  )}
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="EducationQualification3"
                                >
                                  UG
                                </span>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="EducationQualification4"
                                  value="PG"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={educationQualification.includes(
                                    "PG"
                                  )}
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="EducationQualification4"
                                >
                                  PG
                                </span>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="EducationQualification5"
                                  value="Integrated PG"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={educationQualification.includes(
                                    "Integrated PG"
                                  )}
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="EducationQualification5"
                                >
                                  Integrated PG
                                </span>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="EducationQualification6"
                                  value="Diploma"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={educationQualification.includes(
                                    "Diploma"
                                  )}
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="EducationQualification6"
                                >
                                  Diploma
                                </span>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="EducationQualification7"
                                  value="Professional Courses"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={educationQualification.includes(
                                    "Professional Courses"
                                  )}
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="EducationQualification7"
                                >
                                  Professional Courses
                                </span>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="EducationQualification8"
                                  value="Others"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  checked={educationQualification.includes(
                                    "Others"
                                  )}
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className="form-check-label"
                                  htmlFor="EducationQualification8"
                                >
                                  Others
                                </span>
                              </div>
                            </div>

                            {listErrors.EducationQualification && (
                              <p className="errors">
                                {listErrors.EducationQualification}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* //educational section */}

                {educationQualification.includes("SSLC") && (
                  <section className="  sectionFive">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">SSLC</p>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="SSLC_CNo">
                                Certificate Number
                              </label>
                              <input
                                ref={txtAdSslc}
                                id="SSLC_CNo"
                                type="text"
                                name="SSLC_CNo"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("sslc", e)}
                                placeholder="Certificate Number"
                                value={sslcCerficates[0].SSLC_CNo}
                                autoComplete="Certificate Number"
                                minLength={10}
                                maxLength={10}
                                required
                              />
                              {listErrors.SSLC_CNo && (
                                <p className="errors">{listErrors.SSLC_CNo}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="SSLC_Year">Year of Passing</label>
                              {/* <input
                                    ref={txtAdSpass}
                                    id="SSLC_Year"
                                    type="month"
                                    name="SSLC_Year"
                                    className="Inputs"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    onChange={(e) => handleEdu("sslc", e)}
                                    placeholder="Year passing"
                                    value={sslcCerficates[0].SSLC_Year}
                                    autoComplete="SSLC_Year"
                                    required
                                  /> */}
                              <MonthDatePicker
                                handleYearChange={handleYearChange_month}
                                handleMonthChange={handleMonthChange_month}
                                disabledProperty={
                                  list.admission_status === 1 ? true : false
                                }
                                dname={"sslc"}
                                txtAdSpass={txtAdSpass}
                                name={"SSLC_Year"}
                                handleChange={handleEYDate}
                                fieldInput={sslcCerficates[0].SSLC_Year}
                              />
                              {listErrors.SSLC_Year && (
                                <p className="errors">{listErrors.SSLC_Year}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="SSLC_Marks">
                                Marks Percentage
                              </label>
                              <input
                                ref={txtAdSmarks}
                                id="SSLC_Marks"
                                type="number"
                                name="SSLC_Marks"
                                className="Inputs"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                onChange={(e) => handleEdu("sslc", e)}
                                placeholder="Marks Percentage"
                                value={sslcCerficates[0].SSLC_Marks}
                                autoComplete="SSLC_Marks"
                                required
                              />
                              {listErrors.SSLC_Marks && (
                                <p className="errors">
                                  {listErrors.SSLC_Marks}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="SSLC_Eboard">
                                Education Board
                              </label>
                              <select
                                ref={txtAdSboard}
                                id="SSLC_Eboard"
                                name="SSLC_Eboard"
                                className="Inputs"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                onChange={(e) => handleEdu("sslc", e)}
                                defaultValue={sslcCerficates[0].SSLC_Eboard}
                                required
                              >
                                <option value="" disabled selected>
                                  Please select
                                </option>
                                <option className="StateBoard">
                                  State board
                                </option>
                                <option className="matriculation">
                                  Matriculation
                                </option>
                                <option className="CBSE">CBSE</option>
                                <option className="ICSE">ICSE</option>
                                <option className="others">others</option>
                              </select>

                              {listErrors.SSLC_Eboard && (
                                <p className="errors">
                                  {listErrors.SSLC_Eboard}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4 ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="SSLC_MI">
                                Medium of Instruction
                              </label>
                              <select
                                ref={txtAdSins}
                                id="SSLC_MI"
                                name="SSLC_MI"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("sslc", e)}
                                defaultValue={sslcCerficates[0].SSLC_MI}
                                required
                              >
                                <option value="" disabled selected>
                                  Please select
                                </option>
                                <option className="TM">Tamil Medium</option>
                                <option className="EM">English Medium</option>
                              </select>
                              {/* <input
                                    id="SSLC_MI"
                                    type="text"
                                    name="SSLC_MI"
                                    className="Inputs"
                                    onChange={(e) => handleEdu("sslc", e)}
                                    placeholder="Medium Instruction"
                                    value={sslcCerficates[0].SSLC_MI}
                                    autoComplete="Medium Instruction"
                                    required
                                  /> */}
                              {listErrors.SSLC_MI && (
                                <p className="errors">{listErrors.SSLC_MI}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="SSLC_Scl">School Name</label>
                              <input
                                ref={txtAdSschool}
                                id="SSLC_Scl"
                                type="text"
                                name="SSLC_Scl"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("sslc", e)}
                                placeholder="School Name"
                                value={sslcCerficates[0].SSLC_Scl}
                                autoComplete="SchoolName"
                                required
                              />
                              {listErrors.SSLC_Scl && (
                                <p className="errors">{listErrors.SSLC_Scl}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="SSLC_Path">
                                Upload SSLC Certificate (In pdf/Image format){" "}
                                <span className="optionFields">(Optional)</span>
                                {sslcCerficates[0].SSLC_Path !== "" &&
                                  sslcCerficates[0].SSLC_Path !== null && (
                                    <a
                                      href={`${ImageUrlPrefix}/${sslcCerficates[0].SSLC_Path}`}
                                      target="_blank"
                                      download
                                    >
                                      <span className="material-icons download_icon align-middle text-danger">
                                        file_download
                                      </span>
                                    </a>
                                  )}
                              </label>
                              <div className="row">
                                <div
                                  className={
                                    sslcCerficates[0].SSLC_Path !== "" &&
                                    sslcCerficates[0].SSLC_Path !== null
                                      ? "col-md-3 "
                                      : "col-md-12"
                                  }
                                >
                                  <input
                                    ref={txtAdSfile}
                                    id="SSLC_Path"
                                    type="file"
                                    name="SSLC_Path"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className={
                                      sslcCerficates[0].SSLC_Path !== "" &&
                                      sslcCerficates[0].SSLC_Path !== null
                                        ? "InputsFile nn_upload_width"
                                        : "InputsFile  vv_upload_width"
                                    }
                                    onChange={(e) => handleEdu("sslc", e)}
                                    placeholder="SSLC_Path"
                                    autoComplete="SSLC_Path"
                                    required
                                  />
                                </div>
                                {sslcCerficates[0].SSLC_Path !== "" &&
                                  sslcCerficates[0].SSLC_Path !== null && (
                                    <div
                                      className={
                                        "col-md-9 d-flex align-content-center p-0"
                                      }
                                    >
                                      <span className="nn_upload_file">
                                        {sslcCerficates[0].SSLC_Path !== "" &&
                                        sslcCerficates[0].SSLC_Path !== null
                                          ? sslcCerficates[0].original_file_path
                                          : ""}
                                      </span>
                                      <span className="material-icons align-middle text-danger verified_upload">
                                        verified
                                      </span>
                                    </div>
                                  )}
                              </div>
                              {listErrors.SSLC_Path && (
                                <p className="errors">{listErrors.SSLC_Path}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
                {educationQualification.includes("HSC") && (
                  <section className="  sectionFive">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">HSC</p>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="HSC_CNo">
                                Certificate Number
                              </label>
                              <input
                                ref={txtAdHslc}
                                id="HSC_CNo"
                                type="text"
                                minLength={10}
                                maxLength={10}
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="HSC_CNo"
                                className="Inputs"
                                onChange={(e) => handleEdu("hsc", e)}
                                placeholder="Certificate Number"
                                value={sslcCerficates[1].HSC_CNo}
                                autoComplete="Certificate Number"
                                required
                              />
                              {listErrors.HSC_CNo && (
                                <p className="errors">{listErrors.HSC_CNo}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="HSC_Year">Year of Passing</label>
                              {/* <input
                                    ref={txtAdHpass}
                                    id="HSC_Year"
                                    type="month"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    name="HSC_Year"
                                    className="Inputs"
                                    onChange={(e) => handleEdu("hsc", e)}
                                    placeholder="Year passing"
                                    value={sslcCerficates[1].HSC_Year}
                                    autoComplete="Yearpassing"
                                    required
                                  /> */}
                              <MonthDatePicker
                                handleYearChange={handleYearChange_month}
                                handleMonthChange={handleMonthChange_month}
                                minDate={
                                  sslcCerficates[0].SSLC_Year !== "" &&
                                  sslcCerficates[0].SSLC_Year !== null
                                    ? sslcCerficates[0].SSLC_Year
                                    : ""
                                }
                                disabledProperty={
                                  list.admission_status === 1 ? true : false
                                }
                                dname={"hsc"}
                                txtAdSpass={txtAdHpass}
                                name={"HSC_Year"}
                                handleChange={handleEYDate}
                                fieldInput={sslcCerficates[1].HSC_Year}
                              />
                              {listErrors.HSC_Year && (
                                <p className="errors">{listErrors.HSC_Year}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="HSC_Marks">
                                Marks Percentage
                              </label>
                              <input
                                ref={txtAdHmarks}
                                id="HSC_Marks"
                                type="number"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="HSC_Marks"
                                className="Inputs"
                                onChange={(e) => handleEdu("hsc", e)}
                                placeholder="Marks Percentage"
                                value={sslcCerficates[1].HSC_Marks}
                                autoComplete="Marks Percentage"
                                required
                              />
                              {listErrors.HSC_Marks && (
                                <p className="errors">{listErrors.HSC_Marks}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="HSC_Ebaord">
                                Education Board
                              </label>
                              <select
                                ref={txtAdHboard}
                                id="HSC_Ebaord"
                                name="HSC_Ebaord"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("hsc", e)}
                                defaultValue={sslcCerficates[1].HSC_Ebaord}
                                required
                              >
                                <option value="" disabled selected>
                                  Please select
                                </option>
                                <option className="StateBoard">
                                  State board
                                </option>
                                <option className="matriculation">
                                  Matriculation
                                </option>
                                <option className="CBSE">CBSE</option>
                                <option className="ICSE">ICSE</option>
                                <option className="others">others</option>
                              </select>
                              {/* <input
                                    id="HSC_Ebaord"
                                    type="text"
                                    name="HSC_Ebaord"
                                    className="Inputs"
                                    onChange={(e) => handleEdu("hsc", e)}
                                    placeholder="Education Borad"
                                    value={sslcCerficates[1].HSC_Ebaord}
                                    autoComplete="HSC_Ebaord"
                                    required
                                  /> */}
                              {listErrors.HSC_Ebaord && (
                                <p className="errors">
                                  {listErrors.HSC_Ebaord}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4 ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="HSC_MI">
                                Medium of Instruction
                              </label>
                              <select
                                ref={txtAdHins}
                                id="HSC_MI"
                                name="HSC_MI"
                                className="Inputs"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                onChange={(e) => handleEdu("hsc", e)}
                                defaultValue={sslcCerficates[1].HSC_MI}
                                required
                              >
                                <option value="" disabled selected>
                                  Please select
                                </option>
                                <option className="TM">Tamil Medium</option>
                                <option className="EM">English Medium</option>
                              </select>
                              {/* <input
                                    id="HSC_MI"
                                    type="text"
                                    name="HSC_MI"
                                    className="Inputs"
                                    onChange={(e) => handleEdu("hsc", e)}
                                    placeholder="Medium Instruction"
                                    value={sslcCerficates[1].HSC_MI}
                                    autoComplete="Medium Instruction"
                                    required
                                  /> */}
                              {listErrors.HSC_MI && (
                                <p className="errors">{listErrors.HSC_MI}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="HSC_Scl">School Name</label>
                              <input
                                ref={txtAdHschool}
                                id="HSC_Scl"
                                type="text"
                                name="HSC_Scl"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("hsc", e)}
                                placeholder="School Name"
                                value={sslcCerficates[1].HSC_Scl}
                                autoComplete="SchoolName"
                                required
                              />
                              {listErrors.HSC_Scl && (
                                <p className="errors">{listErrors.HSC_Scl}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="HSC_Path">
                                Upload HSC Certificate (In pdf/Image format){" "}
                                <span className="optionFields">(Optional)</span>
                                {sslcCerficates[1].HSC_Path !== "" &&
                                  sslcCerficates[1].HSC_Path !== null && (
                                    <a
                                      href={`${ImageUrlPrefix}/${sslcCerficates[1].HSC_Path}`}
                                      target="_blank"
                                      download
                                    >
                                      <span className="material-icons download_icon align-middle text-danger">
                                        file_download
                                      </span>
                                    </a>
                                  )}
                              </label>
                              <div className="row">
                                <div
                                  className={
                                    sslcCerficates[1].HSC_Path !== "" &&
                                    sslcCerficates[1].HSC_Path !== null
                                      ? "col-md-3 "
                                      : "col-md-12"
                                  }
                                >
                                  <input
                                    ref={txtAdHfile}
                                    id="HSC_Path"
                                    type="file"
                                    name="HSC_Path"
                                    className={
                                      sslcCerficates[1].HSC_Path !== "" &&
                                      sslcCerficates[1].HSC_Path !== null
                                        ? "InputsFile nn_upload_width"
                                        : "InputsFile  vv_upload_width"
                                    }
                                    onChange={(e) => handleEdu("hsc", e)}
                                    placeholder="HSC_Path"
                                    autoComplete="HSC_Path"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    required
                                  />
                                </div>
                                {sslcCerficates[1].HSC_Path !== "" &&
                                  sslcCerficates[1].HSC_Path !== null && (
                                    <div
                                      className={
                                        "col-md-9 d-flex align-content-center p-0"
                                      }
                                    >
                                      <span className="nn_upload_file">
                                        {sslcCerficates[1].HSC_Path !== "" &&
                                        sslcCerficates[1].HSC_Path !== null
                                          ? sslcCerficates[1].original_file_path
                                          : ""}
                                      </span>
                                      <span className="material-icons align-middle text-danger verified_upload">
                                        verified
                                      </span>
                                    </div>
                                  )}
                              </div>
                              {listErrors.HSC_Path && (
                                <p className="errors">{listErrors.HSC_Path}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
                {educationQualification.includes("UG") && (
                  <section className="  sectionFive">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">UG</p>
                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms">
                              <label htmlFor="UGDegree">Degree</label>
                              <Select
                                ref={txtAdUdeg}
                                id="UGDegree"
                                name="UGDegree"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={ugOption.UG} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEdus(option, "UG", "UGDegree")
                                }
                                options={ugDegree.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {/* {!uGisOther ? (
                                    

                                  ) : (

                                    <input
                                      ref={txtAdUdeg}
                                      id="UGDegree"
                                      name="UGDegree"
                                      type="text"
                                      disabled={
                                        list.admission_status === 1
                                          ? true
                                          : false
                                      }
                                      className="Inputs"
                                      placeholder="Please enter degree"
                                      onChange={(e) => handleEdu("UG", e)}
                                      value={sslcCerficates[2].UGDegree}
                                      required
                                    />

                                  )} */}
                              {listErrors.UGDegree && (
                                <p className="errors">{listErrors.UGDegree}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="UGMajor">Major</label>
                              <Select
                                ref={txtAdUMaj}
                                id="UGMajor"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="UGMajor"
                                value={mOption.UGMajor}
                                onChange={(option) =>
                                  handleEduMajor(option, "UG", "UGMajor")
                                }
                                options={major.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />

                              {listErrors.UGMajor && (
                                <p className="errors">{listErrors.UGMajor}</p>
                              )}
                            </div>
                          </div>
                          {uGisOther && (
                            <div className="col-md-6 col-sm-12">
                              <div className="forms ">
                                <label htmlFor="UGMajor_Other">
                                  Major(Other)
                                </label>
                                <input
                                  ref={txtAdUMajOther}
                                  id="UGMajor_Other"
                                  type="text"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  name="UGMajor_Other"
                                  className="Inputs"
                                  onChange={(e) => handleEdu("UG", e)}
                                  placeholder="Year passing"
                                  value={sslcCerficates[2].UGMajor_Other}
                                  required
                                />
                                {listErrors.UGMajor_Other && (
                                  <p className="errors">
                                    {listErrors.UGMajor_Other}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="UGYearpassing">
                                Year of Passing
                              </label>

                              {/* <input
                                    ref={txtAdUpass}
                                    id="UGYearpassing"
                                    type="month"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    name="UGYearpassing"
                                    className="Inputs"
                                    onChange={(e) => handleEdu("UG", e)}
                                    placeholder="Year passing"
                                    value={sslcCerficates[2].UGYearpassing}
                                    autoComplete="Yearpassing"
                                    required
                                  /> */}
                              <MonthDatePicker
                                handleYearChange={handleYearChange_month}
                                handleMonthChange={handleMonthChange_month}
                                minDate={
                                  sslcCerficates[1].HSC_Year !== "" &&
                                  sslcCerficates[1].HSC_Year !== null
                                    ? sslcCerficates[1].HSC_Year
                                    : sslcCerficates[0].SSLC_Year !== "" &&
                                      sslcCerficates[0].SSLC_Year !== null
                                    ? sslcCerficates[0].SSLC_Year
                                    : ""
                                }
                                disabledProperty={
                                  list.admission_status === 1 ? true : false
                                }
                                dname={"UG"}
                                txtAdSpass={txtAdUpass}
                                name={"UGYearpassing"}
                                handleChange={handleEYDate}
                                fieldInput={sslcCerficates[2].UGYearpassing}
                              />
                              {listErrors.UGYearpassing && (
                                <p className="errors">
                                  {listErrors.UGYearpassing}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="UGCertificateNumber">
                                Certificate Number
                              </label>
                              <input
                                ref={txtAdUCer}
                                id="UGCertificateNumber"
                                type="text"
                                name="UGCertificateNumber"
                                className="Inputs"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                onChange={(e) => handleEdu("UG", e)}
                                placeholder="Certificate Number"
                                value={sslcCerficates[2].UGCertificateNumber}
                                minLength={10}
                                maxLength={10}
                                autoComplete="UGCertificate Number"
                                required
                              />
                              {listErrors.UGCertificateNumber && (
                                <p className="errors">
                                  {listErrors.UGCertificateNumber}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="UGMarksPercentage">
                                Marks Percentage
                              </label>
                              <input
                                ref={txtAdUmarks}
                                id="UGMarksPercentage"
                                type="number"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="UGMarksPercentage"
                                className="Inputs"
                                onChange={(e) => handleEdu("UG", e)}
                                placeholder="Marks Percentage"
                                value={sslcCerficates[2].UGMarksPercentage}
                                autoComplete="Marks Percentage"
                                required
                              />
                              {listErrors.UGMarksPercentage && (
                                <p className="errors">
                                  {listErrors.UGMarksPercentage}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="UGClassName">Class</label>
                              <Select
                                ref={txtAdUclass}
                                id="UGClassName"
                                type="text"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="UGClassName"
                                value={mOption.UGClassName}
                                onChange={(option) =>
                                  handleEduMajor(option, "UG", "UGClassName")
                                }
                                options={classOption.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {/* <select
                                     ref={txtAdUclass}
                                     id="UGClassName"
                                     type="text"
                                     disabled={
                                       list.admission_status === 1 ? true : false
                                     }
                                     name="UGClassName"
                                     className="Inputs"
                                     onChange={(e) => handleEdu("UG", e)}
                                     placeholder="Class"
                                     value={sslcCerficates[2].UGClassName}
                                     autoComplete="UGClassName"
                                     required
                                    >
                                      <option value="" disabled>
                                        Please select
                                      </option>
                                      {classOption}
                                    </select>
                                 */}
                              {listErrors.UGClassName && (
                                <p className="errors">
                                  {listErrors.UGClassName}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4 ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="UGNameInstitute">
                                Name of the college / university
                              </label>
                              <input
                                ref={txtAdUins}
                                id="UGNameInstitute"
                                type="text"
                                name="UGNameInstitute"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("UG", e)}
                                placeholder="Name of the college / university"
                                value={sslcCerficates[2].UGNameInstitute}
                                autoComplete="UGNameInstitute"
                                required
                              />
                              {listErrors.UGNameInstitute && (
                                <p className="errors">
                                  {listErrors.UGNameInstitute}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="UG_Path">
                                Upload Certificate (In pdf/Image format){" "}
                                <span className="optionFields">(Optional)</span>
                                {sslcCerficates[2].UG_Path !== "" &&
                                  sslcCerficates[2].UG_Path !== null && (
                                    <a
                                      href={`${ImageUrlPrefix}/${sslcCerficates[2].UG_Path}`}
                                      target="_blank"
                                      download
                                    >
                                      <span className="material-icons align-middle download_icon">
                                        file_download
                                      </span>
                                    </a>
                                  )}
                              </label>
                              <div className="row">
                                <div
                                  className={
                                    sslcCerficates[2].UG_Path !== "" &&
                                    sslcCerficates[2].UG_Path !== null
                                      ? "col-md-3 "
                                      : "col-md-12"
                                  }
                                >
                                  <input
                                    ref={txtAdUfile}
                                    id="UG_Path"
                                    type="file"
                                    name="UG_Path"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className={
                                      sslcCerficates[2].UG_Path !== "" &&
                                      sslcCerficates[2].UG_Path !== null
                                        ? "InputsFile nn_upload_width"
                                        : "InputsFile  vv_upload_width"
                                    }
                                    onChange={(e) => handleEdu("UG", e)}
                                    placeholder="UG_Path"
                                    autoComplete="UG_Path"
                                    required
                                  />
                                </div>
                                {sslcCerficates[2].UG_Path !== "" &&
                                  sslcCerficates[2].UG_Path !== null && (
                                    <div
                                      className={
                                        "col-md-9 d-flex align-content-center p-0"
                                      }
                                    >
                                      <span className="nn_upload_file">
                                        {sslcCerficates[2].UG_Path !== "" &&
                                        sslcCerficates[2].UG_Path !== null
                                          ? sslcCerficates[2].original_file_path
                                          : ""}
                                      </span>
                                      <span className="material-icons align-middle text-danger verified_upload">
                                        verified
                                      </span>
                                    </div>
                                  )}
                              </div>

                              {listErrors.UG_Path && (
                                <p className="errors">{listErrors.UG_Path}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("PG") && (
                  <section className=" sectionFive">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">PG</p>
                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PGDegree">Degree</label>
                              <Select
                                id="PGDegree"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                ref={txtAdPdeg}
                                name="PGDegree"
                                value={ugOption.PG} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEdus(option, "PG", "PGDegree")
                                }
                                options={pgDegree.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {/* {!pGisOther ? (
                                
                                  ) : (

                                    <input
                                    ref={txtAdPdeg}
                                    name="PGDegree"
                                      id="PGDegree"
                                      type="text"
                                      disabled={
                                        list.admission_status === 1
                                          ? true
                                          : false
                                      }
                                      className="Inputs"
                                      placeholder="Please enter degree"
                                      onChange={(e) => handleEdu("PG", e)}
                                      value={sslcCerficates[3].PGDegree}
                                      required
                                    />

                                  )} */}
                              {/* <select
                                    id="PGDegree"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    ref={txtAdPdeg}
                                    name="PGDegree"
                                    className="Inputs"
                                    onChange={(e) => handleEdu("PG", e)}
                                    defaultValue={sslcCerficates[3].PGDegree}
                                    required
                                  >
                                    <option value="" disabled selected>
                                      Please select
                                    </option>
                                    {pgDegree}
                                  </select> */}

                              {listErrors.PGDegree && (
                                <p className="errors">{listErrors.PGDegree}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PGMajor">Major</label>
                              <Select
                                id="PGMajor"
                                name="PGMajor"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                ref={txtAdPMaj}
                                value={mOption.PGMajor} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEduMajor(option, "PG", "PGMajor")
                                }
                                options={pgMajor.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {listErrors.PGMajor && (
                                <p className="errors">{listErrors.PGMajor}</p>
                              )}
                            </div>
                          </div>

                          {pGisOther && (
                            <div className="col-md-6 col-sm-12">
                              <div className="forms ">
                                <label htmlFor="PGMajor_Other">Major</label>
                                <input
                                  type="text"
                                  id="PGMajor_Other"
                                  name="PGMajor_Other"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  ref={txtAdPMajOther}
                                  className="Inputs"
                                  onChange={(e) => handleEdu("PG", e)}
                                  defaultValue={sslcCerficates[3].PGMajor_Other}
                                  required
                                  placeholder="Please enter major"
                                />

                                {listErrors.PGMajor_Other && (
                                  <p className="errors">
                                    {listErrors.PGMajor_Other}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PGYearpassing">
                                Year of Passing
                              </label>
                              {/* <input
                                    ref={txtAdPpass}
                                    id="PGYearpassing"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    type="month"
                                    name="PGYearpassing"
                                    className="Inputs"
                                    onChange={(e) => handleEdu("PG", e)}
                                    placeholder="Year passing"
                                    value={sslcCerficates[3].PGYearpassing}
                                    autoComplete="Yearpassing"
                                    required
                                  /> */}
                              <MonthDatePicker
                                handleYearChange={handleYearChange_month}
                                handleMonthChange={handleMonthChange_month}
                                minDate={
                                  sslcCerficates[1].HSC_Year !== "" &&
                                  sslcCerficates[1].HSC_Year !== null
                                    ? sslcCerficates[1].HSC_Year
                                    : sslcCerficates[0].SSLC_Year !== "" &&
                                      sslcCerficates[0].SSLC_Year !== null
                                    ? sslcCerficates[0].SSLC_Year
                                    : ""
                                }
                                disabledProperty={
                                  list.admission_status === 1 ? true : false
                                }
                                dname={"PG"}
                                txtAdSpass={txtAdPpass}
                                name={"PGYearpassing"}
                                handleChange={handleEYDate}
                                fieldInput={sslcCerficates[3].PGYearpassing}
                              />
                              {listErrors.PGYearpassing && (
                                <p className="errors">
                                  {listErrors.PGYearpassing}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PGCertificateNumber">
                                Certificate Number
                              </label>
                              <input
                                ref={txtAdPCer}
                                id="PGCertificateNumber"
                                type="text"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="PGCertificateNumber"
                                className="Inputs"
                                onChange={(e) => handleEdu("PG", e)}
                                placeholder="Certificate Number"
                                value={sslcCerficates[3].PGCertificateNumber}
                                autoComplete="Certificate Number"
                                required
                                minLength={10}
                                maxLength={10}
                              />
                              {listErrors.PGCertificateNumber && (
                                <p className="errors">
                                  {listErrors.PGCertificateNumber}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PGMarksPercentage">
                                Marks Percentage
                              </label>
                              <input
                                ref={txtAdPmarks}
                                id="PGMarksPercentage"
                                type="number"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="PGMarksPercentage"
                                className="Inputs"
                                onChange={(e) => handleEdu("PG", e)}
                                placeholder="Marks Percentage"
                                value={sslcCerficates[3].PGMarksPercentage}
                                autoComplete="Marks Percentage"
                                required
                              />
                              {listErrors.PGMarksPercentage && (
                                <p className="errors">
                                  {listErrors.PGMarksPercentage}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PGClassName">Class</label>
                              <Select
                                ref={txtAdPclass}
                                id="PGClassName"
                                type="text"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="PGClassName"
                                value={mOption.PGClassName}
                                onChange={(option) =>
                                  handleEduMajor(option, "PG", "PGClassName")
                                }
                                options={classOption.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {listErrors.PGClassName && (
                                <p className="errors">
                                  {listErrors.PGClassName}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4 ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PGNameInstitute">
                                Name of the college / university
                              </label>
                              <input
                                ref={txtAdPins}
                                id="PGNameInstitute"
                                type="text"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="PGNameInstitute"
                                className="Inputs"
                                onChange={(e) => handleEdu("PG", e)}
                                placeholder="Name of the college / university"
                                value={sslcCerficates[3].PGNameInstitute}
                                autoComplete="PGNameInstitute"
                                required
                              />
                              {listErrors.PGNameInstitute && (
                                <p className="errors">
                                  {listErrors.PGNameInstitute}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PG_Path">
                                Upload Certificate (In pdf/Image format){" "}
                                <span className="optionFields">(Optional)</span>
                                {sslcCerficates[3].PG_Path !== "" &&
                                  sslcCerficates[3].PG_Path !== null && (
                                    <a
                                      href={`${ImageUrlPrefix}/${sslcCerficates[3].PG_Path}`}
                                      target="_blank"
                                      download
                                    >
                                      <span className="material-icons align-middle download_icon">
                                        file_download
                                      </span>
                                    </a>
                                  )}
                              </label>
                              <div className="row">
                                <div
                                  className={
                                    sslcCerficates[3].PG_Path !== "" &&
                                    sslcCerficates[3].PG_Path !== null
                                      ? "col-md-3 "
                                      : "col-md-12"
                                  }
                                >
                                  <input
                                    ref={txtAdPfile}
                                    id="PG_Path"
                                    type="file"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    name="PG_Path"
                                    className={
                                      sslcCerficates[3].PG_Path !== "" &&
                                      sslcCerficates[3].PG_Path !== null
                                        ? "InputsFile nn_upload_width"
                                        : "InputsFile  vv_upload_width"
                                    }
                                    onChange={(e) => handleEdu("PG", e)}
                                    placeholder="Path"
                                    autoComplete="Path"
                                    required
                                  />
                                </div>
                                {sslcCerficates[3].PG_Path !== "" &&
                                  sslcCerficates[3].PG_Path !== null && (
                                    <div
                                      className={
                                        "col-md-9 d-flex align-content-center p-0"
                                      }
                                    >
                                      <span className="nn_upload_file">
                                        {sslcCerficates[3].PG_Path !== "" &&
                                        sslcCerficates[3].PG_Path !== null
                                          ? sslcCerficates[3].original_file_path
                                          : ""}
                                      </span>
                                      <span className="material-icons align-middle text-danger verified_upload">
                                        verified
                                      </span>
                                    </div>
                                  )}
                              </div>

                              {listErrors.PG_Path && (
                                <p className="errors">{listErrors.PG_Path}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("Integrated PG") && (
                  <section className="  sectionFive">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Integrated PG</p>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="IPDegree">Degree</label>
                              <Select
                                ref={txtAdIdeg}
                                id="IPDegree"
                                name="IPDegree"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={ugOption.Integrated_PG} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEdus(
                                    option,
                                    "Integrated PG",
                                    "IPDegree"
                                  )
                                }
                                options={ipgDegree.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {/* {!iPGisOther ? (
                                   
                                  ) : (

                                    <input
                                    ref={txtAdIdeg}
                                      id="IPDegree"
                                      name="IPDegree"
                                      type="text"
                                      disabled={
                                        list.admission_status === 1
                                          ? true
                                          : false
                                      }
                                      className="Inputs"
                                      placeholder="Please enter degree"
                                      onChange={(e) =>
                                        handleEdu("Integrated PG", e)
                                      }
                                      value={sslcCerficates[4].IPDegree}
                                      required
                                    />

                                  )} */}

                              {/* <select
                                    ref={txtAdIdeg}
                                    id="IPDegree"
                                    name="IPDegree"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className="Inputs"
                                    onChange={(e) =>
                                      handleEdu("Integrated PG", e)
                                    }
                                    value={sslcCerficates[4].IPDegree}
                                    required
                                  >
                                    <option value="" disabled selected>
                                      Please select
                                    </option>
                                    {ipgDegree}
                                  </select> */}

                              {listErrors.IPDegree && (
                                <p className="errors">{listErrors.IPDegree}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="IPMajor">Major</label>
                              <Select
                                ref={txtAdIMaj}
                                id="IPMajor"
                                name="IPMajor"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={mOption.IPMajor} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEduMajor(
                                    option,
                                    "Integrated PG",
                                    "IPMajor"
                                  )
                                }
                                options={ipMajor.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {listErrors.IPMajor && (
                                <p className="errors">{listErrors.IPMajor}</p>
                              )}
                            </div>
                          </div>

                          {iPGisOther && (
                            <div className="col-md-6 col-sm-12">
                              <div className="forms ">
                                <label htmlFor="IPMajor_Other">Major</label>
                                <input
                                  type="text"
                                  ref={txtAdIMajOther}
                                  id="IPMajor_Other"
                                  name="IPMajor_Other"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className="Inputs"
                                  onChange={(e) =>
                                    handleEdu("Integrated PG", e)
                                  }
                                  value={sslcCerficates[4].IPMajor_Other}
                                  required
                                  placeholder="Please enter major"
                                />
                                {listErrors.IPMajor_Other && (
                                  <p className="errors">
                                    {listErrors.IPMajor_Other}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="IPYearpassing">
                                Year of Passing
                              </label>

                              {/* <input
                                    ref={txtAdIpass}
                                    id="IPYearpassing"
                                    type="month"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    name="IPYearpassing"
                                    className="Inputs"
                                    onChange={(e) =>
                                      handleEdu("Integrated PG", e)
                                    }
                                    placeholder="Year passing"
                                    value={sslcCerficates[4].IPYearpassing}
                                    autoComplete="Yearpassing"
                                    required
                                  /> */}
                              <MonthDatePicker
                                handleYearChange={handleYearChange_month}
                                handleMonthChange={handleMonthChange_month}
                                minDate={
                                  sslcCerficates[1].HSC_Year !== "" &&
                                  sslcCerficates[1].HSC_Year !== null
                                    ? sslcCerficates[1].HSC_Year
                                    : sslcCerficates[0].SSLC_Year !== "" &&
                                      sslcCerficates[0].SSLC_Year !== null
                                    ? sslcCerficates[0].SSLC_Year
                                    : ""
                                }
                                disabledProperty={
                                  list.admission_status === 1 ? true : false
                                }
                                dname={"Integrated PG"}
                                txtAdSpass={txtAdIpass}
                                name={"IPYearpassing"}
                                handleChange={handleEYDate}
                                fieldInput={sslcCerficates[4].IPYearpassing}
                              />
                              {listErrors.IPYearpassing && (
                                <p className="errors">
                                  {listErrors.IPYearpassing}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="IPCertificateNumber">
                                Certificate Number
                              </label>
                              <input
                                ref={txtAdICer}
                                id="IPCertificateNumber"
                                type="text"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                name="IPCertificateNumber"
                                className="Inputs"
                                onChange={(e) => handleEdu("Integrated PG", e)}
                                placeholder="Certificate Number"
                                value={sslcCerficates[4].IPCertificateNumber}
                                autoComplete="Certificate Number"
                                required
                                minLength={10}
                                maxLength={10}
                              />
                              {listErrors.IPCertificateNumber && (
                                <p className="errors">
                                  {listErrors.IPCertificateNumber}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="IPMarksPercentage">
                                Marks Percentage
                              </label>
                              <input
                                ref={txtAdImarks}
                                id="IPMarksPercentage"
                                type="number"
                                name="IPMarksPercentage"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Integrated PG", e)}
                                placeholder="Marks Percentage"
                                value={sslcCerficates[4].IPMarksPercentage}
                                autoComplete="Marks Percentage"
                                required
                              />
                              {listErrors.IPMarksPercentage && (
                                <p className="errors">
                                  {listErrors.IPMarksPercentage}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="IPClassName">Class</label>
                              <Select
                                ref={txtAdIclass}
                                id="IPClassName"
                                type="text"
                                name="IPClassName"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={mOption.IPClassName}
                                onChange={(option) =>
                                  handleEduMajor(
                                    option,
                                    "Integrated PG",
                                    "IPClassName"
                                  )
                                }
                                options={classOption.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />

                              {/* <input
                                    ref={txtAdIclass}
                                    id="IPClassName"
                                    type="text"
                                    name="IPClassName"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className="Inputs"
                                    onChange={(e) =>
                                      handleEdu("Integrated PG", e)
                                    }
                                    placeholder="Class Name"
                                    value={sslcCerficates[4].IPClassName}
                                    autoComplete="SchoolName"
                                    required
                                  /> */}
                              {listErrors.IPClassName && (
                                <p className="errors">
                                  {listErrors.IPClassName}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4 ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="IPNameInstitute">
                                Name of the college / university
                              </label>
                              <input
                                ref={txtAdIins}
                                id="IPNameInstitute"
                                type="text"
                                name="IPNameInstitute"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Integrated PG", e)}
                                placeholder="Name of the college / university"
                                value={sslcCerficates[4].IPNameInstitute}
                                autoComplete="IPNameInstitute"
                                required
                              />
                              {listErrors.IPNameInstitute && (
                                <p className="errors">
                                  {listErrors.IPNameInstitute}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="IP_Path">
                                Upload Certificate (In pdf/Image format){" "}
                                <span className="optionFields">(Optional)</span>
                                {sslcCerficates[4].IP_Path !== "" &&
                                  sslcCerficates[4].IP_Path !== null && (
                                    <a
                                      href={`${ImageUrlPrefix}/${sslcCerficates[4].IP_Path}`}
                                      target="_blank"
                                      download
                                    >
                                      <span className="material-icons align-middle download_icon">
                                        file_download
                                      </span>
                                    </a>
                                  )}
                              </label>
                              <div className="row">
                                <div
                                  className={
                                    sslcCerficates[4].IP_Path !== "" &&
                                    sslcCerficates[4].IP_Path !== null
                                      ? "col-md-3 "
                                      : "col-md-12"
                                  }
                                >
                                  <input
                                    ref={txtAdIfile}
                                    id="IP_Path"
                                    type="file"
                                    name="IP_Path"
                                    className={
                                      sslcCerficates[4].IP_Path !== "" &&
                                      sslcCerficates[4].IP_Path !== null
                                        ? "InputsFile nn_upload_width"
                                        : "InputsFile  vv_upload_width"
                                    }
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    onChange={(e) =>
                                      handleEdu("Integrated PG", e)
                                    }
                                    placeholder="Path"
                                    autoComplete="Path"
                                    required
                                  />
                                </div>
                                {sslcCerficates[4].IP_Path !== "" &&
                                  sslcCerficates[4].IP_Path !== null && (
                                    <div
                                      className={
                                        "col-md-9 d-flex align-content-center p-0"
                                      }
                                    >
                                      <span className="nn_upload_file">
                                        {sslcCerficates[4].IP_Path !== "" &&
                                        sslcCerficates[4].IP_Path !== null
                                          ? sslcCerficates[4].original_file_path
                                          : ""}
                                      </span>
                                      <span className="material-icons align-middle text-danger verified_upload">
                                        verified
                                      </span>
                                    </div>
                                  )}
                              </div>

                              {listErrors.IP_Path && (
                                <p className="errors">{listErrors.IP_Path}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("Diploma") && (
                  <section className="  sectionFive">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Diploma</p>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="DDegree">Diploma</label>
                              <Select
                                ref={txtAdDdeg}
                                id="DDegree"
                                name="DDegree"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={ugOption.Diploma} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEdus(option, "Diploma", "DDegree")
                                }
                                options={diplomaDegree.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {/* {!dipisOther ? (
                                   
                                  ) : (

                                    <input
                                    ref={txtAdDdeg}
                                    id="DDegree"
                                    name="DDegree"
                                      type="text"
                                      disabled={
                                        list.admission_status === 1
                                          ? true
                                          : false
                                      }
                                      className="Inputs"
                                      placeholder="Please enter degree"
                                      onChange={(e) => handleEdu("Diploma", e)}
                                      value={sslcCerficates[5].DDegree}
                                      required
                                    />

                                  )} */}
                              {/* <select
                                    ref={txtAdDdeg}
                                    id="DDegree"
                                    name="DDegree"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className="Inputs"
                                    onChange={(e) => handleEdu("Diploma", e)}
                                    value={sslcCerficates[5].DDegree}
                                    required
                                  >
                                    <option value="" disabled selected>
                                      Please select
                                    </option>
                                    {diplomaDegree}
                                  </select> */}

                              {listErrors.DDegree && (
                                <p className="errors">{listErrors.DDegree}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="DMajor">Major</label>
                              <Select
                                ref={txtAdDMaj}
                                id="DMajor"
                                name="DMajor"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={mOption.DMajor} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEduMajor(option, "Diploma", "DMajor")
                                }
                                options={dmajor.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />

                              {listErrors.DMajor && (
                                <p className="errors">{listErrors.DMajor}</p>
                              )}
                            </div>
                          </div>

                          {dipisOther && (
                            <div className="col-md-6 col-sm-12">
                              <div className="forms ">
                                <label htmlFor="DMajor_other">Major</label>
                                <input
                                  type="text"
                                  placeholder="Please Enter Major"
                                  ref={txtAdDMajOther}
                                  id="DMajor_other"
                                  name="DMajor_other"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  className="Inputs"
                                  onChange={(e) => handleEdu("Diploma", e)}
                                  value={sslcCerficates[5].DMajor_other}
                                  required
                                />
                                {listErrors.DMajor_other && (
                                  <p className="errors">
                                    {listErrors.DMajor_other}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="DYearpassing">
                                Year of Passing
                              </label>

                              {/* <input
                                    ref={txtAdDpass}
                                    id="DYearpassing"
                                    type="month"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    name="DYearpassing"
                                    className="Inputs"
                                    onChange={(e) => handleEdu("Diploma", e)}
                                    placeholder="Year passing"
                                    value={sslcCerficates[5].DYearpassing}
                                    autoComplete="Yearpassing"
                                    required
                                  /> */}
                              <MonthDatePicker
                                handleYearChange={handleYearChange_month}
                                handleMonthChange={handleMonthChange_month}
                                minDate={
                                  sslcCerficates[1].HSC_Year !== "" &&
                                  sslcCerficates[1].HSC_Year !== null
                                    ? sslcCerficates[1].HSC_Year
                                    : sslcCerficates[0].SSLC_Year !== "" &&
                                      sslcCerficates[0].SSLC_Year !== null
                                    ? sslcCerficates[0].SSLC_Year
                                    : ""
                                }
                                disabledProperty={
                                  list.admission_status === 1 ? true : false
                                }
                                dname={"Diploma"}
                                txtAdSpass={txtAdDpass}
                                name={"DYearpassing"}
                                handleChange={handleEYDate}
                                fieldInput={sslcCerficates[5].DYearpassing}
                              />
                              {listErrors.DYearpassing && (
                                <p className="errors">
                                  {listErrors.DYearpassing}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="DCertificateNumber">
                                Certificate Number
                              </label>
                              <input
                                ref={txtAdDCer}
                                id="DCertificateNumber"
                                type="text"
                                name="DCertificateNumber"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Diploma", e)}
                                placeholder="Certificate Number"
                                value={sslcCerficates[5].DCertificateNumber}
                                autoComplete="Certificate Number"
                                required
                                minLength={10}
                                maxLength={10}
                              />
                              {listErrors.DCertificateNumber && (
                                <p className="errors">
                                  {listErrors.DCertificateNumber}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="DMarksPercentage">
                                Marks Percentage
                              </label>
                              <input
                                ref={txtAdDmarks}
                                id="DMarksPercentage"
                                type="number"
                                name="DMarksPercentage"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Diploma", e)}
                                placeholder="Marks Percentage"
                                value={sslcCerficates[5].DMarksPercentage}
                                autoComplete="Marks Percentage"
                                required
                              />
                              {listErrors.DMarksPercentage && (
                                <p className="errors">
                                  {listErrors.DMarksPercentage}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="DClassName">Class</label>
                              <Select
                                ref={txtAdDclass}
                                id="DClassName"
                                type="text"
                                name="DClassName"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={mOption.DClassName}
                                onChange={(option) =>
                                  handleEduMajor(
                                    option,
                                    "Diploma",
                                    "DClassName"
                                  )
                                }
                                options={classOption.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />

                              {/* <input
                                    ref={txtAdDclass}
                                    id="DClassName"
                                    type="text"
                                    name="DClassName"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className="Inputs"
                                    onChange={(e) => handleEdu("Diploma", e)}
                                    placeholder=" Class Name"
                                    value={sslcCerficates[5].DClassName}
                                    autoComplete="SchoolName"
                                    required
                                  /> */}
                              {listErrors.DClassName && (
                                <p className="errors">
                                  {listErrors.DClassName}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4 ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="DNameInstitute">
                                Name of the college / university
                              </label>
                              <input
                                ref={txtAdDins}
                                id="DNameInstitute"
                                type="text"
                                name="DNameInstitute"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Diploma", e)}
                                placeholder="Name of the college / university"
                                value={sslcCerficates[5].DNameInstitute}
                                autoComplete="DNameInstitute"
                                required
                              />
                              {listErrors.DNameInstitute && (
                                <p className="errors">
                                  {listErrors.DNameInstitute}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="D_Path">
                                Upload Certificate (In pdf/Image format){" "}
                                <span className="optionFields">(Optional)</span>
                                {sslcCerficates[5].D_Path !== "" &&
                                  sslcCerficates[5].D_Path !== null && (
                                    <a
                                      href={`${ImageUrlPrefix}/${sslcCerficates[5].D_Path}`}
                                      target="_blank"
                                      download
                                    >
                                      <span className="material-icons align-middle download_icon">
                                        file_download
                                      </span>
                                    </a>
                                  )}
                              </label>
                              <div className="row">
                                <div
                                  className={
                                    sslcCerficates[5].D_Path !== "" &&
                                    sslcCerficates[5].D_Path !== null
                                      ? "col-md-3 "
                                      : "col-md-12"
                                  }
                                >
                                  <input
                                    ref={txtAdDfile}
                                    id="D_Path"
                                    type="file"
                                    name="D_Path"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className={
                                      sslcCerficates[5].D_Path !== "" &&
                                      sslcCerficates[5].D_Path !== null
                                        ? "InputsFile nn_upload_width"
                                        : "InputsFile  vv_upload_width"
                                    }
                                    onChange={(e) => handleEdu("Diploma", e)}
                                    placeholder="Path"
                                    autoComplete="Path"
                                    required
                                  />
                                </div>
                                {sslcCerficates[5].D_Path !== "" &&
                                  sslcCerficates[5].D_Path !== null && (
                                    <div
                                      className={
                                        "col-md-9 d-flex align-content-center p-0"
                                      }
                                    >
                                      <span className="nn_upload_file">
                                        {sslcCerficates[5].D_Path !== "" &&
                                        sslcCerficates[5].D_Path !== null
                                          ? sslcCerficates[5].original_file_path
                                          : ""}
                                      </span>
                                      <span className="material-icons align-middle text-danger verified_upload">
                                        verified
                                      </span>
                                    </div>
                                  )}
                              </div>

                              {listErrors.D_Path && (
                                <p className="errors">{listErrors.D_Path}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("Professional Courses") && (
                  <section className=" sectionFive">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Professional Courses</p>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PCDegree">Degree</label>
                              <Select
                                ref={txtAdCdeg}
                                id="PCDegree"
                                name="PCDegree"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={ugOption.Professional_Courses} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEdus(
                                    option,
                                    "Professional Courses",
                                    "PCDegree"
                                  )
                                }
                                options={pcDegree.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {/* {!pCisOther ? (
                                   
                                  ) : (

                                    <input
                                    ref={txtAdCdeg}
                                    id="PCDegree"
                                    name="PCDegree"
                                      type="text"
                                      disabled={
                                        list.admission_status === 1
                                          ? true
                                          : false
                                      }
                                      className="Inputs"
                                      placeholder="Please enter degree"
                                      onChange={(e) =>
                                        handleEdu("Professional Courses", e)
                                      }
                                      value={sslcCerficates[6].PCDegree}
                                      required
                                    />

                                  )} */}
                              {/* <select
                                    ref={txtAdCdeg}
                                    id="PCDegree"
                                    name="PCDegree"
                                    className="Inputs"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    onChange={(e) =>
                                      handleEdu("Professional Courses", e)
                                    }
                                    value={sslcCerficates[6].PCDegree}
                                    required
                                  >
                                    <option value="" disabled selected>
                                      Please select
                                    </option>
                                    {pcDegree}
                                  </select> */}

                              {listErrors.PCDegree && (
                                <p className="errors">{listErrors.PCDegree}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PCMajor">Major</label>
                              <Select
                                ref={txtAdCMaj}
                                id="PCMajor"
                                name="PCMajor"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={mOption.PCMajor} // Use ugOption.ug as the value
                                onChange={(option) =>
                                  handleEduMajor(
                                    option,
                                    "Professional Courses",
                                    "PCMajor"
                                  )
                                }
                                options={pcMajor.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />
                              {listErrors.PCMajor && (
                                <p className="errors">{listErrors.PCMajor}</p>
                              )}
                            </div>
                          </div>
                          {pCisOther && (
                            <div className="col-md-6 col-sm-12">
                              <div className="forms ">
                                <label htmlFor="PCMajor_Other">Major</label>
                                <input
                                  type="text"
                                  placeholder="Please Enter Major"
                                  ref={txtAdCMajOther}
                                  id="PCMajor_Other"
                                  name="PCMajor_Other"
                                  className="Inputs"
                                  disabled={
                                    list.admission_status === 1 ? true : false
                                  }
                                  onChange={(e) =>
                                    handleEdu("Professional Courses", e)
                                  }
                                  defaultValue={sslcCerficates[6].PCMajor_Other}
                                  required
                                />

                                {listErrors.PCMajor_Other && (
                                  <p className="errors">
                                    {listErrors.PCMajor_Other}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PCYearpassing">
                                Year of Passing
                              </label>

                              {/* <input
                                    ref={txtAdCpass}
                                    id="PCYearpassing"
                                    type="month"
                                    name="PCYearpassing"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className="Inputs"
                                    onChange={(e) =>
                                      handleEdu("Professional Courses", e)
                                    }
                                    placeholder="Year passing"
                                    value={sslcCerficates[6].PCYearpassing}
                                    autoComplete="Yearpassing"
                                    required
                                  /> */}
                              <MonthDatePicker
                                handleYearChange={handleYearChange_month}
                                handleMonthChange={handleMonthChange_month}
                                minDate={
                                  sslcCerficates[1].HSC_Year !== "" &&
                                  sslcCerficates[1].HSC_Year !== null
                                    ? sslcCerficates[1].HSC_Year
                                    : sslcCerficates[0].SSLC_Year !== "" &&
                                      sslcCerficates[0].SSLC_Year !== null
                                    ? sslcCerficates[0].SSLC_Year
                                    : ""
                                }
                                disabledProperty={
                                  list.admission_status === 1 ? true : false
                                }
                                dname={"Professional Courses"}
                                txtAdSpass={txtAdCpass}
                                name={"PCYearpassing"}
                                handleChange={handleEYDate}
                                fieldInput={sslcCerficates[6].PCYearpassing}
                              />
                              {listErrors.PCYearpassing && (
                                <p className="errors">
                                  {listErrors.PCYearpassing}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PCCertificateNumber">
                                Certificate Number
                              </label>
                              <input
                                ref={txtAdCCer}
                                id="PCCertificateNumber"
                                type="text"
                                name="PCCertificateNumber"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) =>
                                  handleEdu("Professional Courses", e)
                                }
                                placeholder="Certificate Number"
                                value={sslcCerficates[6].PCCertificateNumber}
                                autoComplete="Certificate Number"
                                required
                                minLength={10}
                                maxLength={10}
                              />
                              {listErrors.PCCertificateNumber && (
                                <p className="errors">
                                  {listErrors.PCCertificateNumber}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PCMarksPercentage">
                                Marks Percentage
                              </label>
                              <input
                                ref={txtAdCmarks}
                                id="PCMarksPercentage"
                                type="number"
                                name="PCMarksPercentage"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) =>
                                  handleEdu("Professional Courses", e)
                                }
                                placeholder="Marks Percentage"
                                value={sslcCerficates[6].PCMarksPercentage}
                                autoComplete="Marks Percentage"
                                required
                              />
                              {listErrors.PCMarksPercentage && (
                                <p className="errors">
                                  {listErrors.PCMarksPercentage}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PCClassName">Class</label>
                              <Select
                                ref={txtAdCclass}
                                id="PCClassName"
                                type="text"
                                name="PCClassName"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={mOption.PCClassName}
                                onChange={(option) =>
                                  handleEduMajor(
                                    option,
                                    "Professional Courses",
                                    "PCClassName"
                                  )
                                }
                                options={classOption.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />

                              {/* <input
                                    ref={txtAdCclass}
                                    id="PCClassName"
                                    type="text"
                                    name="PCClassName"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className="Inputs"
                                    onChange={(e) =>
                                      handleEdu("Professional Courses", e)
                                    }
                                    placeholder="ClassName"
                                    value={sslcCerficates[6].PCClassName}
                                    autoComplete="PCClassName"
                                    required
                                  /> */}
                              {listErrors.PCClassName && (
                                <p className="errors">
                                  {listErrors.PCClassName}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4 ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PCNameInstitute">
                                Name of the college / university
                              </label>
                              <input
                                ref={txtAdCins}
                                id="PCNameInstitute"
                                type="text"
                                name="PCNameInstitute"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) =>
                                  handleEdu("Professional Courses", e)
                                }
                                placeholder="Name of the college / university"
                                value={sslcCerficates[6].PCNameInstitute}
                                autoComplete="PCNameInstitute"
                                required
                              />
                              {listErrors.PCNameInstitute && (
                                <p className="errors">
                                  {listErrors.PCNameInstitute}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="PC_Path">
                                Upload Certificate (In pdf/Image format){" "}
                                <span className="optionFields">(Optional)</span>
                                {sslcCerficates[6].PC_Path !== "" &&
                                  sslcCerficates[6].PC_Path !== null && (
                                    <a
                                      href={`${ImageUrlPrefix}/${sslcCerficates[6].PC_Path}`}
                                      target="_blank"
                                      download
                                    >
                                      <span className="material-icons align-middle download_icon">
                                        file_download
                                      </span>
                                    </a>
                                  )}
                              </label>
                              <div className="row">
                                <div
                                  className={
                                    sslcCerficates[6].PC_Path !== "" &&
                                    sslcCerficates[6].PC_Path !== null
                                      ? "col-md-3 "
                                      : "col-md-12"
                                  }
                                >
                                  <input
                                    ref={txtAdCfile}
                                    id="PC_Path"
                                    type="file"
                                    name="PC_Path"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className={
                                      sslcCerficates[6].PC_Path !== "" &&
                                      sslcCerficates[6].PC_Path !== null
                                        ? "InputsFile nn_upload_width"
                                        : "InputsFile  vv_upload_width"
                                    }
                                    onChange={(e) =>
                                      handleEdu("Professional Courses", e)
                                    }
                                    placeholder="Path"
                                    autoComplete="Path"
                                    required
                                  />
                                </div>
                                {sslcCerficates[6].PC_Path !== "" &&
                                  sslcCerficates[6].PC_Path !== null && (
                                    <div
                                      className={
                                        "col-md-9 d-flex align-content-center p-0"
                                      }
                                    >
                                      <span className="nn_upload_file">
                                        {sslcCerficates[6].PC_Path !== "" &&
                                        sslcCerficates[6].PC_Path !== null
                                          ? sslcCerficates[6].original_file_path
                                          : ""}
                                      </span>
                                      <span className="material-icons align-middle text-danger verified_upload">
                                        verified
                                      </span>
                                    </div>
                                  )}
                              </div>

                              {listErrors.PC_Path && (
                                <p className="errors">{listErrors.PC_Path}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("Others") && (
                  <section className="sectionFive">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Others</p>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="OtDegree">Degree</label>
                              {/* <select
                                    ref={txtAdOdeg}
                                    id="OtDegree"
                                    name="OtDegree"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className="Inputs"
                                    onChange={(e) => handleEdu("Others", e)}
                                    value={sslcCerficates[7].OtDegree}
                                    required
                                  >
                                    <option value="" disabled selected>
                                      Please select
                                    </option>
                                    {ugDegree}
                                  </select> */}

                              <input
                                ref={txtAdOdeg}
                                id="OtDegree"
                                name="OtDegree"
                                placeholder="Please Enter Degree name"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Others", e)}
                                value={sslcCerficates[7].OtDegree}
                                required
                              />

                              {listErrors.OtDegree && (
                                <p className="errors">{listErrors.OtDegree}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="OtMajor">Major</label>
                              {/* {sslcCerficates[7].OtDegree === "Others" ? (
                                  
                                  ) : (
                                    <select
                                      ref={txtAdOMaj}
                                      id="OtMajor"
                                      name="OtMajor"
                                      disabled={
                                        list.admission_status === 1
                                          ? true
                                          : false
                                      }
                                      className="Inputs"
                                      onChange={(e) => handleEdu("Others", e)}
                                      defaultValue={sslcCerficates[7].OtMajor}
                                      required
                                    >
                                      <option value="" disabled selected>
                                        Please select
                                      </option>
                                      {otMajor}
                                    </select>
                                  )} */}

                              <input
                                type="text"
                                placeholder="Please Enter Major"
                                ref={txtAdOMaj}
                                id="OtMajor"
                                name="OtMajor"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Others", e)}
                                defaultValue={sslcCerficates[7].OtMajor}
                                required
                              />

                              {listErrors.OtMajor && (
                                <p className="errors">{listErrors.OtMajor}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="OtYearpassing">
                                Year of Passing
                              </label>

                              {/* <input
                                    ref={txtAdOpass}
                                    id="OtYearpassing"
                                    type="month"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    name="OtYearpassing"
                                    className="Inputs"
                                    onChange={(e) => handleEdu("Others", e)}
                                    placeholder="Year passing"
                                    value={sslcCerficates[7].OtYearpassing}
                                    autoComplete="Yearpassing"
                                    required
                                  /> */}
                              <MonthDatePicker
                                handleYearChange={handleYearChange_month}
                                handleMonthChange={handleMonthChange_month}
                                minDate={
                                  sslcCerficates[1].HSC_Year !== "" &&
                                  sslcCerficates[1].HSC_Year !== null
                                    ? sslcCerficates[1].HSC_Year
                                    : sslcCerficates[0].SSLC_Year !== "" &&
                                      sslcCerficates[0].SSLC_Year !== null
                                    ? sslcCerficates[0].SSLC_Year
                                    : ""
                                }
                                disabledProperty={
                                  list.admission_status === 1 ? true : false
                                }
                                dname={"Others"}
                                txtAdSpass={txtAdOpass}
                                name={"OtYearpassing"}
                                handleChange={handleEYDate}
                                fieldInput={sslcCerficates[7].OtYearpassing}
                              />
                              {listErrors.OtYearpassing && (
                                <p className="errors">
                                  {listErrors.OtYearpassing}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="OtCertificateNumber">
                                Certificate Number
                              </label>
                              <input
                                ref={txtAdOCer}
                                id="OtCertificateNumber"
                                type="text"
                                name="OtCertificateNumber"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Others", e)}
                                placeholder="Certificate Number"
                                value={sslcCerficates[7].OtCertificateNumber}
                                autoComplete="Certificate Number"
                                required
                                minLength={10}
                                maxLength={10}
                              />
                              {listErrors.OtCertificateNumber && (
                                <p className="errors">
                                  {listErrors.OtCertificateNumber}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="OtMarksPercentage">
                                Marks Percentage
                              </label>
                              <input
                                ref={txtAdOmarks}
                                id="OtMarksPercentage"
                                type="number"
                                name="OtMarksPercentage"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Others", e)}
                                placeholder="Marks Percentage"
                                value={sslcCerficates[7].OtMarksPercentage}
                                autoComplete="Marks Percentage"
                                required
                              />
                              {listErrors.OtMarksPercentage && (
                                <p className="errors">
                                  {listErrors.OtMarksPercentage}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="OtClassName">Class</label>
                              <Select
                                ref={txtAdOclass}
                                id="OtClassName"
                                type="text"
                                name="OtClassName"
                                isDisabled={
                                  list.admission_status === 1 ? true : false
                                }
                                value={mOption.OtClassName}
                                onChange={(option) =>
                                  handleEduMajor(
                                    option,
                                    "Others",
                                    "OtClassName"
                                  )
                                }
                                options={classOption.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )} // Sort options alphabetically by label
                                isSearchable={true} // Enables search functionality
                                placeholder="Select an option"
                                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                filterOption={(option, searchText) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                } // Filter options based on the search text
                                styles={customStyles} // Apply custom styles her
                              />

                              {/* <input
                                    ref={txtAdOclass}
                                    id="OtClassName"
                                    type="text"
                                    name="OtClassName"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className="Inputs"
                                    onChange={(e) => handleEdu("Others", e)}
                                    placeholder="Class Name"
                                    value={sslcCerficates[7].OtClassName}
                                    autoComplete="ClassName"
                                    required
                                  /> */}
                              {listErrors.OtClassName && (
                                <p className="errors">
                                  {listErrors.OtClassName}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4 ">
                          <div className="col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="OtNameInstitute">
                                Name of the college / university
                              </label>
                              <input
                                ref={txtAdOins}
                                id="OtNameInstitute"
                                type="text"
                                name="OtNameInstitute"
                                disabled={
                                  list.admission_status === 1 ? true : false
                                }
                                className="Inputs"
                                onChange={(e) => handleEdu("Others", e)}
                                placeholder="Name of the college / university"
                                value={sslcCerficates[7].OtNameInstitute}
                                autoComplete="OtNameInstitute"
                                required
                              />
                              {listErrors.OtNameInstitute && (
                                <p className="errors">
                                  {listErrors.OtNameInstitute}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forms ">
                              <label htmlFor="Ot_Path">
                                Upload Certificate (In pdf/Image format){" "}
                                <span className="optionFields">(Optional)</span>
                                {sslcCerficates[7].Ot_Path !== "" &&
                                  sslcCerficates[7].Ot_Path !== null && (
                                    <a
                                      href={`${ImageUrlPrefix}/${sslcCerficates[7].Ot_Path}`}
                                      target="_blank"
                                      download
                                    >
                                      <span className="material-icons align-middle download_icon">
                                        file_download
                                      </span>
                                    </a>
                                  )}
                              </label>
                              <div className="row">
                                <div
                                  className={
                                    sslcCerficates[7].Ot_Path !== "" &&
                                    sslcCerficates[7].Ot_Path !== null
                                      ? "col-md-3 "
                                      : "col-md-12"
                                  }
                                >
                                  <input
                                    ref={txtAdOfile}
                                    id="Ot_Path"
                                    type="file"
                                    name="Ot_Path"
                                    disabled={
                                      list.admission_status === 1 ? true : false
                                    }
                                    className={
                                      sslcCerficates[7].Ot_Path !== "" &&
                                      sslcCerficates[7].Ot_Path !== null
                                        ? "InputsFile nn_upload_width"
                                        : "InputsFile  vv_upload_width"
                                    }
                                    onChange={(e) => handleEdu("Others", e)}
                                    placeholder="Path"
                                    autoComplete="Path"
                                    required
                                  />
                                </div>
                                {sslcCerficates[7].Ot_Path !== "" &&
                                  sslcCerficates[7].Ot_Path !== null && (
                                    <div
                                      className={
                                        "col-md-9 d-flex align-content-center p-0"
                                      }
                                    >
                                      <span className="nn_upload_file">
                                        {sslcCerficates[7].Ot_Path !== "" &&
                                        sslcCerficates[7].Ot_Path !== null
                                          ? sslcCerficates[7].original_file_path
                                          : ""}
                                      </span>
                                      <span className="material-icons align-middle text-danger verified_upload">
                                        verified
                                      </span>
                                    </div>
                                  )}
                              </div>

                              {listErrors.Ot_Path && (
                                <p className="errors">{listErrors.Ot_Path}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {/* //educational section  end */}

                <section className="  sectionFive">
                  {techQualification.map((item, index) => (
                    <TechQualification
                      key={item.id}
                      admission_status={list.admission_status === 1 ? 1 : 0}
                      length={techQualification.length}
                      AddQualification={AddQualification}
                      DeleteQual={DeleteQual}
                      technicalOption={technicalOption}
                      TechhandleChange_date={TechhandleChange_date}
                      TechhandleChange={TechhandleChange}
                      TechhandleChanges={TechhandleChanges}
                      record={item}
                    />
                  ))}

                  <div className="heading  mb-4 dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">Go to Communication</a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButtons"
                        onClick={handleNext}
                      >
                        <a href="javascript:void(0)">
                          Save and Continue to Course Selection Details
                        </a>
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </fieldset>
        )}
        {/* {step === 4} */}
        {step === 4 && (
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <section className="sectionSix">
                  <div className="heading  dflexbetww ">
                    <div className=" ">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">
                          Go to Academic Background
                        </a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButton Counselling"
                        onClick={handleNext}
                      >
                        <a href="javascript:void(0)">
                          Save and Continue to Review and Confirm
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Counselling Awareness Program Enrollment
                      </p>
                      <div className="row ">
                        <div className="col-lg-6 col-md-12">
                          <div className="forms ">
                            <label htmlFor="Category">Category</label>

                            <div
                              className="d-flex flex-lg-row flex-md-row flex-column"
                              id="radiobuttons"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip onClick={handleStepOne} id="tooltip">
                                    You choose default category as{" "}
                                    {list.SPCcategory} in stepper one
                                  </Tooltip>
                                }
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    ref={txtAdCate}
                                    className="checkbox_radio"
                                    type="radio"
                                    name="category"
                                    disabled
                                    id="UPSC"
                                    value="UPSC"
                                    onChange={handleChange}
                                    checked={
                                      list.category === "UPSC" ||
                                      list.SPCcategory === "UPSC"
                                    }
                                  />
                                  <label
                                    className="checkbox-label"
                                    htmlFor="UPSC"
                                  >
                                    UPSC
                                  </label>
                                </div>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip onClick={handleStepOne} id="tooltip">
                                    You choose default category as{" "}
                                    {list.SPCcategory} in stepper one
                                  </Tooltip>
                                }
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    className="checkbox_radio"
                                    type="radio"
                                    name="category"
                                    id="TNPSC"
                                    disabled
                                    value="TNPSC"
                                    checked={
                                      list.category === "TNPSC" ||
                                      list.SPCcategory === "TNPSC"
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="checkbox-label"
                                    htmlFor="TNPSC"
                                  >
                                    TNPSC
                                  </label>
                                </div>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip onClick={handleStepOne} id="tooltip">
                                    You choose default category as{" "}
                                    {list.SPCcategory} in stepper one
                                  </Tooltip>
                                }
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    className="checkbox_radio"
                                    type="radio"
                                    name="category"
                                    id="TRB"
                                    disabled
                                    value="TRB"
                                    checked={
                                      list.category === "TRB" ||
                                      list.SPCcategory === "TRB"
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="checkbox-label"
                                    htmlFor="TRB"
                                  >
                                    TRB
                                  </label>
                                </div>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip onClick={handleStepOne} id="tooltip">
                                    You choose default category as{" "}
                                    {list.SPCcategory} in stepper one
                                  </Tooltip>
                                }
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    className="checkbox_radio"
                                    type="radio"
                                    name="category"
                                    id="TET"
                                    disabled
                                    value="TET"
                                    checked={
                                      list.category === "TET" ||
                                      list.SPCcategory === "TET"
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="checkbox-label"
                                    htmlFor="TET"
                                  >
                                    TET
                                  </label>
                                </div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip onClick={handleStepOne} id="tooltip">
                                    You choose default category as{" "}
                                    {list.SPCcategory} in stepper one
                                  </Tooltip>
                                }
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    className="checkbox_radio"
                                    type="radio"
                                    name="category"
                                    id="TNUSRB"
                                    disabled
                                    value="TNUSRB"
                                    checked={
                                      list.category === "TNUSRB" ||
                                      list.SPCcategory === "TNUSRB"
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="checkbox-label"
                                    htmlFor="TNUSRB"
                                  >
                                    TNUSRB
                                  </label>
                                </div>
                              </OverlayTrigger>
                            </div>
                            <div
                              className="d-flex flex-lg-row flex-md-row flex-column"
                              id="radiobuttons"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip onClick={handleStepOne} id="tooltip">
                                    You choose default category as{" "}
                                    {list.SPCcategory} in stepper one
                                  </Tooltip>
                                }
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    className="checkbox_radio"
                                    type="radio"
                                    name="category"
                                    id="RRB"
                                    disabled
                                    value="RRB"
                                    checked={
                                      list.category === "RRB" ||
                                      list.SPCcategory === "RRB"
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="checkbox-label"
                                    htmlFor="RRB"
                                  >
                                    RRB
                                  </label>
                                </div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip onClick={handleStepOne} id="tooltip">
                                    You choose default category as{" "}
                                    {list.SPCcategory} in stepper one
                                  </Tooltip>
                                }
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    className="checkbox_radio"
                                    type="radio"
                                    name="category"
                                    id="SSC"
                                    disabled
                                    value="SSC"
                                    checked={
                                      list.category === "SSC" ||
                                      list.SPCcategory === "SSC"
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="checkbox-label"
                                    htmlFor="SSC"
                                  >
                                    SSC
                                  </label>
                                </div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip onClick={handleStepOne} id="tooltip">
                                    You choose default category as{" "}
                                    {list.SPCcategory} in stepper one
                                  </Tooltip>
                                }
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    className="checkbox_radio"
                                    type="radio"
                                    name="category"
                                    id="Banking"
                                    disabled
                                    value="Banking"
                                    checked={
                                      list.category === "Banking" ||
                                      list.SPCcategory === "Banking"
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="checkbox-label"
                                    htmlFor="Banking"
                                  >
                                    Banking
                                  </label>
                                </div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip onClick={handleStepOne} id="tooltip">
                                    You choose default category as{" "}
                                    {list.SPCcategory} in stepper one
                                  </Tooltip>
                                }
                              >
                                <div className="form-check form-check-inline">
                                  <input
                                    className="checkbox_radio"
                                    type="radio"
                                    name="category"
                                    id="Others"
                                    value="Others"
                                    disabled
                                    checked={
                                      list.category === "Others" ||
                                      list.SPCcategory === "Others"
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="checkbox-label"
                                    htmlFor="Others"
                                  >
                                    Others
                                  </label>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </div>
                          {listErrors.category && (
                            <small className="errors fw-semibold">
                              {listErrors.category}
                            </small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="ExamName">
                              Name of the Exam You Passed
                            </label>
                            <Select
                              ref={txtAdExamPassed}
                              id="ExamName"
                              name="ExamName"
                              disabled={edit ? true : false}
                              value={dropdown.ExamName}
                              onChange={(option) =>
                                handleSelectMulti(
                                  option,
                                  "ExamName",
                                  "ExamName"
                                )
                              }
                              options={
                                coursesCate
                                  .filter((item) => item && item.label) // Filter out null and undefined elements
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  ) // Sort options alphabetically by label
                              }
                              isSearchable={true} // Enables search functionality
                              placeholder="Select an option"
                              maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                              filterOption={(option, searchText) =>
                                option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              } // Filter options based on the search text
                              styles={customStyles} // Apply custom styles her
                            />
                            {listErrors.ExamName && (
                              <p className="errors">{listErrors.ExamName}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="ExamRegno">
                              Exam Registration Number
                            </label>
                            <input
                              ref={txtAdExamRegno}
                              id="ExamRegno"
                              type="number"
                              disabled={edit ? true : false}
                              name="ExamRegno"
                              className="Inputs"
                              onChange={handleChange}
                              value={list.ExamRegno < 0 ? 0 : list.ExamRegno}
                              placeholder="Exam Registration Number"
                              autoComplete="ExamRegno"
                              required
                            />
                            {listErrors.ExamRegno && (
                              <p className="errors">{listErrors.ExamRegno}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="location">
                              Preferred Branch Location
                            </label>
                            <select
                              ref={txtAdLoca}
                              disabled={edit ? true : false}
                              id="location"
                              name="location"
                              // disabled={list.classMode === "ClassRoom" ? false : true}
                              className="Inputs"
                              onChange={handleChange}
                              value={list.location}
                              required
                            >
                              <option value="" disabled selected>
                                Please select
                              </option>
                              {locations}
                            </select>
                            {listErrors.location && (
                              <p className="errors">{listErrors.location}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="forms">
                            {/* AIASA-000114 - v1 issues solved by lalitha */}
                            <label htmlFor="preferredDept">
                              Preferred Department
                            </label>
                            <Select
                              ref={txtAdPreferredDept}
                              class="Inputs"
                              id="preferredDept"
                              name="preferredDept"
                              options={dept}
                              isDisabled={edit ? true : false}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={true}
                              onChange={handlePreferredDept}
                              defaultValue={selectedPreDept.map((value) => ({
                                value: value.value,
                                label: value.value,
                              }))}
                              components={{
                                Option: CustomCOption, // Use the custom Option component
                              }}
                              styles={multiCusutomStyle}
                            />

                            {listErrors.preferredDept && (
                              <p className="errors">
                                {listErrors.preferredDept}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {Object.keys(batchDetails).length > 0 && (
                  <section className="  sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Panel Details</p>
                        {batchDetails.map((el, index) => (
                          <div className="row" key={index}>
                            <div className="col-md-12 mt-3 mb-3">
                              <div className="card  text-dark">
                                <div className="card-body BatchImage">
                                  <div className="row">
                                    <div className="col-lg-2 col-md-6 col-sm-12">
                                      <img
                                        src={`${ImageUrlPrefix}/${el.img_url}`}
                                        // src="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg"
                                        id={el.id}
                                        alt="Imge"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column ImageText  justify-content-center">
                                      <p className="title m-0 ">
                                        {el.course_name || ""} {"-"}{" "}
                                        {el.name || ""}
                                      </p>
                                    </div>
                                    <div className="col-lg-7 col-md-10 col-sm-12 col-xl-6 pt-5 mt-1">
                                      <p className="subtitle mb-1 ">
                                        Fee Details
                                      </p>
                                      <span
                                        class="material-icons"
                                        id="mockInterviewcurr"
                                      >
                                        currency_rupee
                                      </span>

                                      <div className="mockinter_fff">
                                        {el.exam_fees.reverse().map((elll) => (
                                          <span className="batch_fees_text">
                                            {elll.fees_count} Interviews -{" "}
                                            {elll.fees}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  {el.panel_details.map((obh) => (
                                    <div className="row">
                                      <div className="col-md-1 col-sm-12 col-lg-1 d-flex justify-content-lg-center mt-sm-4 align-items-center mt-3">
                                        <input
                                          ref={txtbatchDet}
                                          onChange={(e) =>
                                            handleBatchDetails(
                                              obh.batch_id,
                                              obh.pk,
                                              e
                                            )
                                          }
                                          defaultChecked={obh.is_selected_panel}
                                          disabled={edit ? true : false}
                                          type="checkbox"
                                          className="checkbox_batch"
                                          name={`is_selected_panel_${obh.pk}`}
                                          id={`is_selected_panel_${obh.pk}`}
                                          value={obh.is_selected_panel}
                                        />
                                      </div>
                                      <div className="col-md-11 col-sm-12 col-lg-11 d-flex">
                                        <div
                                          className="card mt-3 BatchImage"
                                          style={{ width: "100%" }}
                                        >
                                          <div className="row">
                                            <div className="col-md-3 col-sm-12">
                                              <li className="IconsImage mt-2 mb-2 ms-3">
                                                <span className="material-icons">
                                                  format_shapes
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="title ms-1">
                                                    Panel Name
                                                  </p>
                                                  <p className="subtitle ms-1">
                                                    {obh.panel_name}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                              <li className="IconsImage mt-2 mb-2 ms-3">
                                                <span className="material-icons">
                                                  event
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="title ms-1">
                                                    Date
                                                  </p>
                                                  <p className="subtitle ms-1">
                                                    {moment(obh.date).format(
                                                      "Do MMM YYYY"
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                              <li className="IconsImage mt-2 mb-2 ms-3">
                                                <span className="material-icons">
                                                  timer
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="title ms-1">
                                                    Time
                                                  </p>
                                                  <p className="subtitle ms-1">
                                                    {obh.event_time}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {listErrors.batchDetails && (
                          <p className="errors">{listErrors.batchDetails}</p>
                        )}
                      </div>
                    </div>
                  </section>
                )}
                {/* {(list.category === "UPSC" ||
                      list.category === "TNPSC" ||
                      list.category === "SSC" ||
                      list.category === "RRB") && (
                     
                    )} */}

                <section className="  sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Exam Result Info</p>
                      <div className="row">
                        {(list.category === "SSC" ||
                          list.category === "TNPSC" ||
                          list.category === "RRB" ||
                          list.category === "UPSC") && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="overallrank">Overall rank</label>
                              <input
                                ref={txtAdoverallrank}
                                id="overallrank"
                                type="number"
                                name="overallrank"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.overallrank < 0 ? 0 : list.overallrank
                                }
                                placeholder="Overall rank"
                                autoComplete="overallrank"
                                required
                              />
                              {listErrors.overallrank && (
                                <p className="errors">
                                  {listErrors.overallrank}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {(list.category === "SSC" ||
                          list.category === "TNPSC" ||
                          list.category === "RRB" ||
                          list.category === "UPSC") && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="Interviewmarks">
                                Communal Rank
                              </label>
                              <input
                                ref={txtAdcommunalrank}
                                id="communalrank"
                                type="number"
                                name="communalrank"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.communalrank < 0 ? 0 : list.communalrank
                                }
                                placeholder="Communal Rank"
                                autoComplete="communalrank"
                                required
                              />
                              {listErrors.communalrank && (
                                <p className="errors">
                                  {listErrors.communalrank}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "TNPSC" &&
                          (list.ExamName === "5" || list.ExamName === 5) && (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="typistrank">
                                    Rank in Typist Category
                                  </label>
                                  <input
                                    ref={txtAdtypistrank}
                                    id="typistrank"
                                    type="number"
                                    name="typistrank"
                                    className="Inputs"
                                    onChange={handleChange}
                                    value={
                                      list.typistrank < 0 ? 0 : list.typistrank
                                    }
                                    placeholder="Rank in Typist Category"
                                    autoComplete="typistrank"
                                    required
                                  />
                                  {listErrors.typistrank && (
                                    <p className="errors">
                                      {listErrors.typistrank}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="tamilrank">
                                    Rank in Tamil Medium Reservation
                                  </label>
                                  <input
                                    ref={txtAdtamilrank}
                                    id="tamilrank"
                                    type="number"
                                    name="tamilrank"
                                    className="Inputs"
                                    onChange={handleChange}
                                    value={
                                      list.tamilrank < 0 ? 0 : list.tamilrank
                                    }
                                    placeholder="Rank in Tamil Medium Reservation"
                                    autoComplete="tamilrank"
                                    required
                                  />
                                  {listErrors.tamilrank && (
                                    <p className="errors">
                                      {listErrors.tamilrank}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}

                        {list.category === "TNPSC" &&
                          (list.ExamName === "5" ||
                            list.ExamName === "4" ||
                            list.ExamName === 4) && (
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="StenoRank">Steno Rank</label>
                                <input
                                  ref={txtAdStenoRank}
                                  id="StenoRank"
                                  type="number"
                                  name="StenoRank"
                                  className="Inputs"
                                  onChange={handleChange}
                                  value={
                                    list.StenoRank < 0 ? 0 : list.StenoRank
                                  }
                                  placeholder="Steno Rank"
                                  autoComplete="StenoRank"
                                  required
                                />
                                {listErrors.StenoRank && (
                                  <p className="errors">
                                    {listErrors.StenoRank}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}

                        {list.category === "TNPSC" &&
                          (list.ExamName === "5" ||
                            list.ExamName === "4" ||
                            list.ExamName === 5 ||
                            list.ExamName === 4) && (
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="PSTMRank">PSTM Rank</label>
                                <input
                                  ref={txtAdPSTMRank}
                                  id="PSTMRank"
                                  type="number"
                                  name="PSTMRank"
                                  className="Inputs"
                                  onChange={handleChange}
                                  value={list.PSTMRank < 0 ? 0 : list.PSTMRank}
                                  placeholder="PSTM Rank"
                                  autoComplete="PSTMRank"
                                  required
                                />
                                {listErrors.PSTMRank && (
                                  <p className="errors">
                                    {listErrors.PSTMRank}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}

                        {list.category === "TNPSC" &&
                          (list.ExamName === "5" ||
                            list.ExamName === "4" ||
                            list.ExamName === 4) && (
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="WindowRank">Widow Rank</label>
                                <input
                                  ref={txtAdWindowRank}
                                  id="WindowRank"
                                  type="number"
                                  name="WindowRank"
                                  className="Inputs"
                                  onChange={handleChange}
                                  value={
                                    list.WindowRank < 0 ? 0 : list.WindowRank
                                  }
                                  placeholder="Widow Rank"
                                  autoComplete="WindowRank"
                                  required
                                />
                                {listErrors.WindowRank && (
                                  <p className="errors">
                                    {listErrors.WindowRank}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}

                        {list.category === "TNPSC" &&
                          (list.ExamName === "5" ||
                            list.ExamName === "4" ||
                            list.ExamName === 5 ||
                            list.ExamName === 4) && (
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="DRank">Disabled Rank</label>
                                <input
                                  ref={txtAdDRank}
                                  id="DRank"
                                  type="number"
                                  name="DRank"
                                  className="Inputs"
                                  onChange={handleChange}
                                  value={list.DRank < 0 ? 0 : list.DRank}
                                  placeholder="Disabled Rank"
                                  autoComplete="DRank"
                                  required
                                />
                                {listErrors.DRank && (
                                  <p className="errors">{listErrors.DRank}</p>
                                )}
                              </div>
                            </div>
                          )}

                        {list.category === "UPSC" && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markPaperone">
                                Mark uptained in Paper I
                              </label>
                              <input
                                ref={txtAdMarkpaper1}
                                id="markPaperone"
                                type="number"
                                name="markPaperone"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markPaperone < 0 ? 0 : list.markPaperone
                                }
                                placeholder="Mark uptained in Paper I"
                                autoComplete="markPaperone"
                                required
                              />
                              {listErrors.markPaperone && (
                                <p className="errors">
                                  {listErrors.markPaperone}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "UPSC" && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markPaperTwo">
                                Mark uptained in Paper II
                              </label>
                              <input
                                ref={txtAdMarkpaper2}
                                id="markPaperTwo"
                                type="number"
                                name="markPaperTwo"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markPaperTwo < 0 ? 0 : list.markPaperTwo
                                }
                                placeholder="Mark uptained in Paper II"
                                autoComplete="markPaperTwo"
                                required
                              />
                              {listErrors.markPaperTwo && (
                                <p className="errors">
                                  {listErrors.markPaperTwo}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "UPSC" && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markpaperThree">
                                Mark uptained in Paper III
                              </label>
                              <input
                                ref={txtAdMarkpaper3}
                                id="markpaperThree"
                                type="number"
                                name="markpaperThree"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markpaperThree < 0
                                    ? 0
                                    : list.markpaperThree
                                }
                                placeholder="Mark uptained in Paper III"
                                autoComplete="markpaperThree"
                                required
                              />
                              {listErrors.markpaperThree && (
                                <p className="errors">
                                  {listErrors.markpaperThree}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "UPSC" && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markPaperFour">
                                Mark uptained in Paper IV
                              </label>
                              <input
                                ref={txtAdMarkpaper4}
                                id="markPaperFour"
                                type="number"
                                name="markPaperFour"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markPaperFour < 0
                                    ? 0
                                    : list.markPaperFour
                                }
                                placeholder="Mark uptained in Paper IV"
                                autoComplete="markPaperFour"
                                required
                              />
                              {listErrors.markPaperFour && (
                                <p className="errors">
                                  {listErrors.markPaperFour}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "UPSC" && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markPaperFive">
                                Mark uptained in Paper V
                              </label>
                              <input
                                ref={txtAdMarkpaper5}
                                id="markPaperFive"
                                type="number"
                                name="markPaperFive"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markPaperFive < 0
                                    ? 0
                                    : list.markPaperFive
                                }
                                placeholder="Mark uptained in Paper V"
                                autoComplete="markPaperFive"
                                required
                              />
                              {listErrors.markPaperFive && (
                                <p className="errors">
                                  {listErrors.markPaperFive}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "UPSC" && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markPaperSix">
                                Mark uptained in Paper VI
                              </label>
                              <input
                                ref={txtAdMarkpaper6}
                                id="markPaperSix"
                                type="number"
                                name="markPaperSix"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markPaperSix < 0 ? 0 : list.markPaperSix
                                }
                                placeholder="Mark uptained in Paper VI"
                                autoComplete="markPaperSix"
                                required
                              />
                              {listErrors.markPaperSix && (
                                <p className="errors">
                                  {listErrors.markPaperSix}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "UPSC" && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markPaperSeven">
                                Mark uptained in Paper VII
                              </label>
                              <input
                                ref={txtAdMarkpaper7}
                                id="markPaperSeven"
                                type="number"
                                name="markPaperSeven"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markPaperSeven < 0
                                    ? 0
                                    : list.markPaperSeven
                                }
                                placeholder="Mark uptained in Paper VII"
                                autoComplete="markPaperSeven"
                                required
                              />
                              {listErrors.markPaperSeven && (
                                <p className="errors">
                                  {listErrors.markPaperSeven}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "UPSC" && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markPaperEight">
                                Mark uptained in Paper VIII
                              </label>
                              <input
                                ref={txtAdMarkpaper8}
                                id="markPaperEight"
                                type="number"
                                name="markPaperEight"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markPaperEight < 0
                                    ? 0
                                    : list.markPaperEight
                                }
                                placeholder="Mark uptained in Paper VIII"
                                autoComplete="markPaperEight"
                                required
                              />
                              {listErrors.markPaperEight && (
                                <p className="errors">
                                  {listErrors.markPaperEight}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "UPSC" && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markPaperNine">
                                Mark uptained in Paper IX
                              </label>
                              <input
                                ref={txtAdMarkpaper9}
                                id="markPaperNine"
                                type="number"
                                name="markPaperNine"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markPaperNine < 0
                                    ? 0
                                    : list.markPaperNine
                                }
                                placeholder="Mark uptained in Paper IX"
                                autoComplete="markPaperNine"
                                required
                              />
                              {listErrors.markPaperNine && (
                                <p className="errors">
                                  {listErrors.markPaperNine}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        {(list.category === "UPSC" ||
                          (list.category === "TNPSC" &&
                            (list.ExamName === "3" ||
                              list.ExamName === "34" ||
                              list.ExamName === 3 ||
                              list.ExamName === 34))) && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markUpdatedMains">
                                Total marks uptained in mains
                              </label>
                              <input
                                ref={txtAdmarkUpdatedMains}
                                id="markUpdatedMains"
                                type="number"
                                name="markUpdatedMains"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markUpdatedMains < 0
                                    ? 0
                                    : list.markUpdatedMains
                                }
                                placeholder="Total marks uptained in mains"
                                autoComplete="markUpdatedMains"
                                required
                              />
                              {listErrors.markUpdatedMains && (
                                <p className="errors">
                                  {listErrors.markUpdatedMains}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        {(list.category === "UPSC" ||
                          (list.category === "TNPSC" &&
                            (list.ExamName === "3" ||
                              list.ExamName === "34" ||
                              list.ExamName === 3 ||
                              list.ExamName === 34))) && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="markUpdatedOral">
                                {list.ExamName != "3" || list.ExamName != "34"
                                  ? "Total marks uptained marked uptained in oral test"
                                  : "Marks in Oral Test"}
                              </label>
                              <input
                                ref={txtAdmarkUpdatedOral}
                                id="markUpdatedOral"
                                type="number"
                                name="markUpdatedOral"
                                className="Inputs"
                                onChange={handleChange}
                                value={
                                  list.markUpdatedOral < 0
                                    ? 0
                                    : list.markUpdatedOral
                                }
                                placeholder="Total marks uptained marked uptained in oral test"
                                autoComplete="markUpdatedOral"
                                required
                              />
                              {listErrors.markUpdatedOral && (
                                <p className="errors">
                                  {listErrors.markUpdatedOral}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {(list.category === "SSC" ||
                          list.category === "RRB") && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="MarksOne">Marks in Tier I</label>
                              <input
                                ref={txtAdMarksOne}
                                id="MarksOne"
                                type="number"
                                name="MarksOne"
                                className="Inputs"
                                onChange={handleChange}
                                value={list.MarksOne < 0 ? 0 : list.MarksOne}
                                placeholder="Marks in Tier I"
                                autoComplete="MarksOne"
                                required
                              />
                              {listErrors.MarksOne && (
                                <p className="errors">{listErrors.MarksOne}</p>
                              )}
                            </div>
                          </div>
                        )}

                        {(list.category === "SSC" ||
                          list.category === "RRB") && (
                          <div className="col-md-6">
                            <div className="forms ">
                              <label htmlFor="MarksTwo">Marks in Tier II</label>
                              <input
                                ref={txtAdMarksTwo}
                                id="MarksTwo"
                                type="number"
                                name="MarksTwo"
                                className="Inputs"
                                onChange={handleChange}
                                value={list.MarksTwo < 0 ? 0 : list.MarksTwo}
                                placeholder="Marks in Tier II"
                                autoComplete="MarksTwo"
                                required
                              />
                              {listErrors.MarksTwo && (
                                <p className="errors">{listErrors.MarksTwo}</p>
                              )}
                            </div>
                          </div>
                        )}

                        {list.category === "TNPSC" &&
                          (list.ExamName === "4" || list.ExamName === 4) && (
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="Interviewmarks">
                                  Interview Mark
                                </label>
                                <input
                                  ref={txtAdInterviewmarks}
                                  id="Interviewmarks"
                                  type="number"
                                  name="Interviewmarks"
                                  className="Inputs"
                                  onChange={handleChange}
                                  value={
                                    list.Interviewmarks < 0
                                      ? 0
                                      : list.Interviewmarks
                                  }
                                  placeholder=" Interview Mark"
                                  autoComplete="Interviewmarks"
                                  required
                                />
                                {listErrors.Interviewmarks && (
                                  <p className="errors">
                                    {listErrors.Interviewmarks}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="preferredpost">
                              Preferred Post
                            </label>
                            {/* <select
                                  id="preferredpost"
                                  name="preferredpost"
                                  className="Inputs"
                                  onChange={handleChange}
                                  // value={list.preferredpost}
                                  required
                                >
                                  <option value="Please Select" disabled>
                                    Please select
                                  </option>
                                {designation}
                                </select> */}

                            <Select
                              ref={txtAdpreferredpost}
                              class="Inputs"
                              id="preferredpost"
                              name="preferredpost"
                              disabled={edit ? true : false}
                              options={designation}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={true}
                              onChange={handlePreferredpost}
                              value={selectedPre.map((value) => ({
                                value: value.value,
                                label: value.value,
                              }))}
                              components={{
                                Option: CustomCOption, // Use the custom Option component
                              }}
                              styles={multiCusutomStyle}
                            />

                            {listErrors.preferredpost && (
                              <p className="errors">
                                {listErrors.preferredpost}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Have You Cleared Any Exam Previously?
                      </p>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="forms ">
                            <label htmlFor="ExamPreviously">
                              Have You Cleared Any Exam Previously?
                            </label>
                            <div className="radioGroup d-flex flex-row">
                              <div className="formCheck ">
                                <input
                                  ref={txtAdEdetail}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="ExamPreviously"
                                  id="ExamPreviously1"
                                  onChange={handleChange}
                                  checked={
                                    list.ExamPreviously === "Yes" ? true : false
                                  }
                                  value="Yes"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="ExamPreviously1"
                                >
                                  Yes
                                </label>

                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="ExamPreviously"
                                  id="ExamPreviously2"
                                  onChange={handleChange}
                                  checked={
                                    list.ExamPreviously === "NO" ? true : false
                                  }
                                  value="NO"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="ExamPreviously2"
                                >
                                  NO
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        {list.ExamPreviously === "Yes" && (
                          <>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="ExamWrite">
                                  How many exams have you cleared in the past?
                                </label>
                                <input
                                  ref={txtAdEclear}
                                  id="ExamWrite"
                                  type="number"
                                  name="ExamWrite"
                                  className="Inputs"
                                  onChange={handleChange}
                                  value={
                                    list.ExamWrite < 0 ? 0 : list.ExamWrite
                                  }
                                  placeholder="ExamWrite"
                                  autoComplete="ExamWrite"
                                  required
                                />
                                {listErrors.ExamWrite && (
                                  <p className="errors">
                                    {listErrors.ExamWrite}
                                  </p>
                                )}
                              </div>
                            </div>
                            {examDetailsExam.map((detail, index) => (
                              <div className="col-md-6" key={index}>
                                <div className="forms ">
                                  <label htmlFor={`ExamDetailsRes_${index}`}>
                                    Exam details - {index + 1}
                                  </label>
                                  <select
                                    ref={txtAdEdetail}
                                    id={`ExamDetailsRes_${index}`}
                                    name={`ExamDetailsRes_${index}`}
                                    className="Inputs"
                                    onChange={(e) => handleExamPrev(e, index)}
                                    value={detail.examvalue}
                                  >
                                    <option value="" selected>
                                      Please select
                                    </option>
                                    {previousexam}
                                  </select>
                                  {detail.error_name_exam && (
                                    <p className="errors">
                                      {detail.error_name_exam}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Are you a government employee?
                      </p>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label htmlFor="workingRes">
                              Are you a government employee?
                            </label>
                            <div className="radioGroup d-flex flex-row">
                              <div className="formCheck ">
                                <input
                                  ref={txtAdDesg}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="workingRes"
                                  id="workingRes1"
                                  onChange={handleChange}
                                  checked={
                                    list.workingRes === "Yes" ? true : false
                                  }
                                  value="Yes"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="workingRes1"
                                >
                                  Yes
                                </label>

                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="workingRes"
                                  id="workingRes2"
                                  onChange={handleChange}
                                  checked={
                                    list.workingRes === "NO" ? true : false
                                  }
                                  value="NO"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="workingRes2"
                                >
                                  NO
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        {list.workingRes === "Yes" && (
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="Designation">Designation</label>
                                {/* <input
                                        id="Designation"
                                        type="text"
                                        name="Designation"
                                        className="Inputs"
                                        onChange={handleChange}
                                        value={list.Designation}
                                        placeholder="Designation"
                                        autoComplete="Designation"
                                        required
                                      /> */}
                                <Select
                                  ref={txtAdDesg}
                                  id="Designation"
                                  name="Designation"
                                  value={
                                    list.Designation !== null &&
                                    list.Designation !== ""
                                      ? {
                                          value: list.Designation,
                                          label: list.Designation,
                                        }
                                      : null
                                  }
                                  onChange={(option) =>
                                    handleSelectCH(
                                      option,
                                      "Designation",
                                      "Designation"
                                    )
                                  }
                                  options={
                                    designations
                                      .filter((item) => item && item.label) // Filter out null and undefined elements
                                      .sort((a, b) =>
                                        a.label.localeCompare(b.label)
                                      ) // Sort options alphabetically by label
                                  }
                                  isSearchable={true} // Enables search functionality
                                  placeholder="Select an option"
                                  maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                  filterOption={(option, searchText) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(searchText.toLowerCase())
                                  } // Filter options based on the search text
                                  styles={customStyles} // Apply custom styles her
                                />
                                {listErrors.Designation && (
                                  <p className="errors">
                                    {listErrors.Designation}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="Department">Department</label>
                                {/* <input
                                        id="Department"
                                        type="text"
                                        name="Department"
                                        className="Inputs"
                                        onChange={handleChange}
                                        value={list.Department}
                                        placeholder="Department"
                                        autoComplete="Department"
                                        required
                                      /> */}
                                <Select
                                  ref={txtAdDep}
                                  id="Department"
                                  name="Department"
                                  value={
                                    list.Department !== null &&
                                    list.Department !== ""
                                      ? {
                                          value: list.Department,
                                          label: list.Department,
                                        }
                                      : null
                                  }
                                  onChange={(option) =>
                                    handleSelectCH(
                                      option,
                                      "Department",
                                      "Department"
                                    )
                                  }
                                  options={
                                    department
                                      .filter((item) => item && item.label) // Filter out null and undefined elements
                                      .sort((a, b) =>
                                        a.label.localeCompare(b.label)
                                      ) // Sort options alphabetically by label
                                  }
                                  isSearchable={true} // Enables search functionality
                                  placeholder="Select an option"
                                  maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                  filterOption={(option, searchText) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(searchText.toLowerCase())
                                  } // Filter options based on the search text
                                  styles={customStyles} // Apply custom styles her
                                />
                                {listErrors.Department && (
                                  <p className="errors">
                                    {listErrors.Department}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="forms ">
                                <label htmlFor="JoinGovt">
                                  Joining Date of Government Job
                                </label>
                                {/* <input
                                      ref={txtAdDjoin}
                                      id="JoinGovt"
                                      type="date"
                                      name="JoinGovt"
                                      className="Inputs"
                                      onChange={handleChange}
                                      value={list.JoinGovt}
                                      placeholder="JoinGovt"
                                      autoComplete="JoinGovt"
                                      required
                                       max={currentDate_Join}
                                    /> */}
                                <DatePickerSample
                                  maxDate={currentDate_Join}
                                  txtAdMDob={txtAdDjoin}
                                  name={"JoinGovt"}
                                  handleChange={handleChangeCuDate}
                                  fieldInput={list.JoinGovt}
                                />
                                {listErrors.JoinGovt && (
                                  <p className="errors">
                                    {listErrors.JoinGovt}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                <section className="  sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Are You an AIASA Student?</p>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label htmlFor="AIASAStudent">
                              Are You an AIASA Student?
                              <span
                                id="impo"
                                class="material-icons text-danger"
                              >
                                error
                              </span>
                            </label>
                            <div className="radioGroup d-flex flex-row">
                              <div className="formCheck ">
                                <input
                                  ref={txtAdayear}
                                  className="checkbox_radio"
                                  type="radio"
                                  name="AIASAStudent"
                                  id="AIASAStudent1"
                                  onChange={handleChange}
                                  checked={
                                    list.AIASAStudent === "Yes" ? true : false
                                  }
                                  value="Yes"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="AIASAStudent1"
                                >
                                  Yes
                                </label>

                                <input
                                  className="checkbox_radio"
                                  type="radio"
                                  name="AIASAStudent"
                                  id="AIASAStudent2"
                                  onChange={handleChange}
                                  checked={
                                    list.AIASAStudent === "NO" ? true : false
                                  }
                                  value="NO"
                                />
                                <label
                                  className="checkbox-label"
                                  htmlFor="AIASAStudent2"
                                >
                                  NO
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {list.AIASAStudent === "Yes" && (
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="Year">Year</label>
                                  {/* <input
                                          id="Year"
                                          type="text"
                                          name="Year"
                                          className="Inputs"
                                          onChange={handleChange}
                                          value={list.Year}
                                          placeholder="Year"
                                          autoComplete="Year"
                                          required
                                        /> */}
                                  <select
                                    ref={txtAdayear}
                                    id="Year"
                                    name="Year"
                                    className="Inputs"
                                    onChange={handleChange}
                                    value={list.Year}
                                    required
                                  >
                                    <option value="">-- Select Year --</option>
                                    {Array.from({ length: 50 }, (_, index) => {
                                      const year =
                                        new Date().getFullYear() - index;
                                      return (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      );
                                    })}
                                  </select>

                                  {listErrors.Year && (
                                    <p className="errors">{listErrors.Year}</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="CategoryAias">Category</label>

                                  <Select
                                    ref={txtAdacate}
                                    id="CategoryAias"
                                    name="CategoryAias"
                                    value={
                                      list.CategoryAias !== null &&
                                      list.CategoryAias !== ""
                                        ? {
                                            value: list.CategoryAias,
                                            label: list.CategoryAias,
                                          }
                                        : null
                                    }
                                    onChange={(option) =>
                                      handleSelectCH(
                                        option,
                                        "CategoryAias",
                                        "CategoryAias"
                                      )
                                    }
                                    options={
                                      cOption
                                        .filter((item) => item && item.label) // Filter out null and undefined elements
                                        .sort((a, b) =>
                                          a.label.localeCompare(b.label)
                                        ) // Sort options alphabetically by label
                                    }
                                    isSearchable={true} // Enables search functionality
                                    placeholder="Select an option"
                                    maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                    filterOption={(option, searchText) =>
                                      option.label
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    } // Filter options based on the search text
                                    styles={customStyles} // Apply custom styles her
                                  />
                                  {listErrors.CategoryAias && (
                                    <p className="errors">
                                      {listErrors.CategoryAias}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="CourseAias">Course</label>

                                  <Select
                                    ref={txtAdacourse}
                                    id="CourseAias"
                                    name="CourseAias"
                                    value={
                                      list.CourseAias !== null &&
                                      list.CourseAias !== ""
                                        ? {
                                            value: list.CourseAias,
                                            label: list.CourseAias,
                                          }
                                        : null
                                    }
                                    onChange={(option) =>
                                      handleSelectCH(
                                        option,
                                        "CourseAias",
                                        "CourseAias"
                                      )
                                    }
                                    options={
                                      courseAias
                                        .filter((item) => item && item.label) // Filter out null and undefined elements
                                        .sort((a, b) =>
                                          a.label.localeCompare(b.label)
                                        ) // Sort options alphabetically by label
                                    }
                                    isSearchable={true} // Enables search functionality
                                    placeholder="Select an option"
                                    maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                                    filterOption={(option, searchText) =>
                                      option.label
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    } // Filter options based on the search text
                                    styles={customStyles} // Apply custom styles her
                                  />
                                  {listErrors.CourseAias && (
                                    <p className="errors">
                                      {listErrors.CourseAias}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="IDNumber">ID Number</label>
                                  <input
                                    ref={txtAdaIdnum}
                                    id="IDNumber"
                                    type="text"
                                    name="IDNumber"
                                    className="Inputs"
                                    onChange={handleChange}
                                    value={list.IDNumber}
                                    placeholder="ID Number"
                                    autoComplete="ID Number"
                                    required
                                    maxLength={10}
                                    minLength={10}
                                  />
                                  {listErrors.IDNumber && (
                                    <p className="errors">
                                      {listErrors.IDNumber}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label htmlFor="IDPhoto">
                                    Upload ID Card (In pdf/Image format)
                                    {list.IDPhoto !== "" &&
                                      list.IDPhoto !== null && (
                                        <a
                                          href={`${ImageUrlPrefix}/${list.IDPhoto}`}
                                          target="_blank"
                                          download
                                        >
                                          <span className="material-icons align-middle download_icon">
                                            file_download
                                          </span>
                                        </a>
                                      )}
                                  </label>
                                  <div className="row">
                                    <div
                                      className={
                                        list.IDPhoto !== "" &&
                                        list.IDPhoto !== null
                                          ? "col-md-3 "
                                          : "col-md-12"
                                      }
                                    >
                                      <input
                                        ref={txtAdafile}
                                        id="IDPhoto"
                                        type="file"
                                        name="IDPhoto"
                                        placeholder="Upload ID Card"
                                        className={
                                          list.IDPhoto !== "" &&
                                          list.IDPhoto !== null
                                            ? "InputsFile nn_upload_width"
                                            : "InputsFile  vv_upload_width"
                                        }
                                        onChange={handleChange}
                                        autoComplete="IDPhoto"
                                        required
                                      />
                                    </div>
                                    {list.IDPhoto !== "" &&
                                      list.IDPhoto !== null && (
                                        <div
                                          className={
                                            "col-md-9 d-flex align-content-center p-0"
                                          }
                                        >
                                          <span className="nn_upload_file">
                                            {list.IDPhoto !== "" &&
                                            list.IDPhoto !== null
                                              ? list.original_aiasa_id_path
                                              : ""}
                                          </span>
                                          <span className="material-icons align-middle text-danger verified_upload">
                                            verified
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                  {listErrors.IDPhoto && (
                                    <p className="errors">
                                      {listErrors.IDPhoto}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="heading  mb-4 dflexbetww ">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">
                          {" "}
                          Go to Academic Background
                        </a>
                      </button>
                    </div>
                    <div className="d-flex smButton mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButton"
                        onClick={handleNext}
                      >
                        {" "}
                        <a href="javascript:void(0)">
                          {" "}
                          Save and Review Your Information
                        </a>
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </fieldset>
        )}
        {/* {step === 5} */}
        {step === 5 && (
          <fieldset>
            <div className="row">
              <div className="col-md-12">
                <section className=" sectionSix">
                  <div className="heading dflexbetww">
                    <div className="">
                      <button
                        type="button"
                        className="SaveButton_Prev"
                        onClick={handlePrevious}
                      >
                        <a href="javascript:void(0)">
                          Go to Counselling Awareness Program
                        </a>
                      </button>
                    </div>
                    <div className="d-flex smButton  mobiledown">
                      <Link to="/" className="CancelButton">
                        Back to Home
                      </Link>
                      <button
                        type="button"
                        className="SaveButton"
                        onClick={handleNexts}
                      >
                        <a href="javascript:void(0)"> Submit and Update</a>
                      </button>
                    </div>
                  </div>

                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Basic Details</p>

                      <div className="row">
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  First Name
                                </label>
                                <span className="ReviewLabel">
                                  {list.FirstName || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Last Name
                                </label>
                                <span className="ReviewLabel">
                                  {list.LastName || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-12">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Father's Name/Guardian's Name
                                </label>
                                <span className="ReviewLabel">
                                  {" "}
                                  {list.Fathername || ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mother's Name
                                </label>
                                <span className="ReviewLabel">
                                  {" "}
                                  {list.MotherName || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Date of Birth
                                </label>
                                <span className="ReviewLabel">
                                  {list.DOB || ""}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Gender
                                </label>
                                <span className="ReviewLabel">
                                  {list.gender || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Age
                                </label>
                                <span className="ReviewLabel">
                                  {list.age || ""}
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Marital Status
                                </label>
                                <span className="ReviewLabel">
                                  {" "}
                                  {list.MaritalStatus || ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-5">
                          <div className="forms mb-2  ">
                            <div id="profilephotos">
                              {list.Profilephoto && (
                                <img
                                  src={`${ImageUrlPrefix}/${list.Profilephoto}`}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="sectionSix">
                  <div className="row">
                    <div className="col-md-6">
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">ID Proofs</p>

                            <div className="row mb-3">
                              <div className="col-lg-4 col-md-12">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    AADHAR Number
                                  </label>
                                  <span className="ReviewLabel">
                                    {list.aadharNo || ""}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-8 col-md-12">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Upload AADHAR Card
                                    {list.aadharCardphoto && (
                                      <a
                                        target="_blank"
                                        href={`${ImageUrlPrefix}/${list.aadharCardphoto}`}
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </a>
                                    )}
                                  </label>
                                  <span className="ReviewLabel">
                                    {list.original_aadhar_path || ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="col-md-6">
                      <section className="sectionSix ">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">
                              Communal Reservation Category
                            </p>

                            <div className="row mb-3">
                              <div className="col-lg-4 col-md-12">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Category
                                  </label>
                                  <span className="ReviewLabel">
                                    {list.Cast || ""}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-8 col-md-12">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate
                                    {list.CastFile && (
                                      <a
                                        target="_blank"
                                        href={`${ImageUrlPrefix}/${list.CastFile}`}
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </a>
                                    )}
                                  </label>
                                  <span className="ReviewLabel">
                                    {list.CastFile !== null
                                      ? list.original_caste_path
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>

                <div className="sectionSix">
                  <div className="row">
                    {specialAdmissionPre.length > 0 &&
                      specialAdmissionPre.map((el, index) => (
                        <div
                          className={
                            specialAdmissionPre.length % 2 !== 0
                              ? specialAdmissionPre.length - 1 === index
                                ? "col-md-12"
                                : "col-md-6 "
                              : "col-md-6 "
                          }
                          key={index}
                        >
                          <section className="sectionSix">
                            <div className="row box">
                              <div className="col-md-12">
                                <p className="StepTitle">
                                  Special Category{" "}
                                  {specialAdmissionPre.length === 1
                                    ? " "
                                    : `- ${el.id}`}
                                </p>
                                <div className="row mb-3">
                                  <div className="col-md-4">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Category
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.name || ""}
                                      </span>
                                    </div>
                                  </div>
                                  {el.file !== "" && el.file !== null && (
                                    <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Uploaded Certificate
                                          {el.file !== "" &&
                                            el.file !== null && (
                                              <a
                                                target="_blank"
                                                href={`${ImageUrlPrefix}/${el.file}`}
                                              >
                                                {" "}
                                                <Download className="download_icon" />
                                              </a>
                                            )}
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.file !== null
                                            ? el.original_pref_path
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  {el.path_sslc !== "" &&
                                    el.path_sslc !== null && (
                                      <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                        <div className="forms ">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label text-muted "
                                          >
                                            Uploaded Certificate for sslc
                                            {el.path_sslc !== "" &&
                                              el.path_sslc !== null && (
                                                <a
                                                  target="_blank"
                                                  href={`${ImageUrlPrefix}/${el.path_sslc}`}
                                                >
                                                  {" "}
                                                  <Download className="download_icon" />
                                                </a>
                                              )}
                                          </label>
                                          <span className="ReviewLabel">
                                            {el.path_sslc !== null
                                              ? el.original_path_sslc
                                              : ""}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  {el.path_hsc !== "" &&
                                    el.path_hsc !== null && (
                                      <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                        <div className="forms ">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label text-muted "
                                          >
                                            Uploaded Certificate for HSC
                                            {el.path_hsc !== "" &&
                                              el.path_hsc !== null && (
                                                <a
                                                  target="_blank"
                                                  href={`${ImageUrlPrefix}/${el.path_hsc}`}
                                                >
                                                  {" "}
                                                  <Download className="download_icon" />
                                                </a>
                                              )}
                                          </label>
                                          <span className="ReviewLabel">
                                            {el.path_sslc !== null
                                              ? el.original_path_hsc
                                              : ""}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                  {el.path_ug !== "" && el.path_ug !== null && (
                                    <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Uploaded Certificate for UG
                                          {el.path_ug !== "" &&
                                            el.path_ug !== null && (
                                              <a
                                                target="_blank"
                                                href={`${ImageUrlPrefix}/${el.path_ug}`}
                                              >
                                                {" "}
                                                <Download className="download_icon" />
                                              </a>
                                            )}
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.path_sslc !== null
                                            ? el.original_path_ug
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  {el.path_pg !== "" && el.path_pg !== null && (
                                    <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                      <div className="forms ">
                                        <label
                                          htmlFor="inputName"
                                          className="form-label text-muted "
                                        >
                                          Uploaded Certificate for PG
                                          {el.path_pg !== "" &&
                                            el.path_pg !== null && (
                                              <a
                                                target="_blank"
                                                href={`${ImageUrlPrefix}/${el.path_pg}`}
                                              >
                                                {" "}
                                                <Download className="download_icon" />
                                              </a>
                                            )}
                                        </label>
                                        <span className="ReviewLabel">
                                          {el.path_sslc !== null
                                            ? el.original_path_pg
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      ))}
                  </div>
                </div>

                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Contact details</p>

                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Phone Number
                            </label>
                            <span className="ReviewLabel">
                              {list.Mobile || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              WhatsApp Number
                            </label>
                            <span className="ReviewLabel">
                              {list.ContactNumber === true
                                ? list.Mobile
                                : list.WhatsAppNumber}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Father/Guardian Phone Number
                            </label>
                            <span className="ReviewLabel">
                              {list.ParentMobileNo || ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Email ID
                            </label>
                            <span className="ReviewLabel">
                              {list.emailaddress || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="sectionSix">
                  <div className="row">
                    <div className="col-md-6">
                      <section className="sectionSix">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">Present Address</p>

                            <div className="row mb-3">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Present Address
                                  </label>
                                </div>
                              </div>

                              <span className="ReviewLabel mb-2">
                                {" "}
                                {list.BuildingNumberPresent +
                                  (list.BuildingNumberPresent ? "," : "") || ""}
                                {list.StreetNamePresent +
                                  (list.StreetNamePresent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.AreaPresent +
                                  (list.AreaPresent ? "," : "") || ""}
                                {list.LandmarkPresent +
                                  (list.LandmarkPresent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.TalukPresent +
                                  (list.TalukPresent ? "," : "") || ""}
                                {list.PostPresent +
                                  (list.PostPresent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.PincodePresent +
                                  (list.PincodePresent ? "," : "") || ""}
                                {list.CityPresent +
                                  (list.CityPresent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.districtPresent +
                                  (list.districtPresent ? "," : "") || ""}
                                {list.statePresent +
                                  (list.statePresent ? "," : "") || ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="col-md-6">
                      <section className="sectionSix ">
                        <div className="row box">
                          <div className="col-md-12">
                            <p className="StepTitle">Permanent Address</p>

                            <div className="row mb-3">
                              <div className="col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    {/* {list.SamePresentAddress === true
                                      ? "Persent Address"
                                      : "Permanent Address"} */}
                                    Permanent Address
                                  </label>
                                </div>
                              </div>
                              <span className="ReviewLabel  mb-2">
                                {list.SamePresentAddress === true
                                  ? list.BuildingNumberPresent +
                                    (list.BuildingNumberPresent ? "," : "")
                                  : list.BuildingNumberPermanent +
                                      (list.BuildingNumberPermanent
                                        ? ","
                                        : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.StreetNamePresent +
                                    (list.StreetNamePresent ? "," : "")
                                  : list.StreetNamePermanent +
                                      (list.StreetNamePermanent ? "," : "") ||
                                    ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.SamePresentAddress === true
                                  ? list.AreaPresent +
                                    (list.AreaPresent ? "," : "")
                                  : list.AreaPermanent +
                                      (list.AreaPermanent ? "," : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.LandmarkPresent +
                                    (list.LandmarkPresent ? "," : "")
                                  : list.LandmarkPermanent +
                                      (list.LandmarkPermanent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.SamePresentAddress === true
                                  ? list.TalukPresent +
                                    (list.TalukPresent ? "," : "")
                                  : list.TalukPermanent +
                                      (list.TalukPermanent ? "," : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.PostPresent +
                                    (list.PostPresent ? "," : "")
                                  : list.PostPermanent +
                                      (list.PostPermanent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.SamePresentAddress === true
                                  ? list.PincodePresent +
                                    (list.PincodePresent ? "," : "")
                                  : list.PincodePermanent +
                                      (list.PincodePermanent ? "," : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.CityPresent +
                                    (list.CityPresent ? "," : "")
                                  : list.CityPermanent +
                                      (list.CityPermanent ? "," : "") || ""}
                              </span>
                              <span className="ReviewLabel  mb-2">
                                {" "}
                                {list.SamePresentAddress === true
                                  ? list.districtPresent +
                                    (list.districtPresent ? "," : "")
                                  : list.districtPermanent +
                                      (list.districtPermanent ? "," : "") || ""}
                                {list.SamePresentAddress === true
                                  ? list.statePresent +
                                    (list.statePresent ? "," : "")
                                  : list.statePermanent +
                                      (list.statePermanent ? "," : "") || ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>

                <div className="sectionSix">
                  <div className="row">
                    {educationQualification.includes("SSLC") && (
                      <div
                        className={
                          educationQualification.includes("SSLC") &&
                          !educationQualification.includes("HSC")
                            ? " col-md-12"
                            : " col-md-6"
                        }
                      >
                        <section className="sectionSix">
                          <div className="row box">
                            <div className="col-md-12">
                              <p className="StepTitle">SSLC</p>

                              <div className="row mb-3">
                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Certificate Number
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[0].SSLC_CNo || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      Year of Passing
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[0].SSLC_Year || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      Marks Percentage
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[0].SSLC_Marks || ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      Medium of Instruction
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[0].SSLC_MI || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      School Name
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[0].SSLC_Scl || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      Uploaded Certificate
                                      {sslcCerficates[0].SSLC_Path && (
                                        <a
                                          target="_blank"
                                          href={`${ImageUrlPrefix}/${sslcCerficates[0].SSLC_Path}`}
                                        >
                                          {" "}
                                          <Download className="download_icon" />
                                        </a>
                                      )}
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[0].SSLC_Path !== null
                                        ? sslcCerficates[0].original_file_path
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>{" "}
                      </div>
                    )}
                    {educationQualification.includes("HSC") && (
                      <div
                        className={
                          !educationQualification.includes("SSLC") &&
                          educationQualification.includes("HSC")
                            ? " col-md-12"
                            : " col-md-6"
                        }
                      >
                        <section
                          className={`${
                            !educationQualification.includes("SSLC") &&
                            educationQualification.includes("HSC")
                              ? ""
                              : ""
                          } sectionSix`}
                        >
                          <div className="row box">
                            <div className="col-md-12">
                              <p className="StepTitle">HSC</p>

                              <div className="row mb-3">
                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Certificate Number
                                    </label>
                                    <div className="ReviewLabel">
                                      {sslcCerficates[1].HSC_CNo || ""}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      Year of Passing
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[1].HSC_Year || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      Marks Percentage
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[1].HSC_Marks || ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      Medium of Instruction
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[1].HSC_MI || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      School Name
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[1].HSC_Scl || ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      {" "}
                                      Uploaded Certificate
                                      {sslcCerficates[1].HSC_Path && (
                                        <a
                                          target="_blank"
                                          href={`${ImageUrlPrefix}/${sslcCerficates[1].HSC_Path}`}
                                        >
                                          {" "}
                                          <Download className="download_icon" />
                                        </a>
                                      )}
                                    </label>
                                    <span className="ReviewLabel">
                                      {sslcCerficates[1].HSC_Path !== null
                                        ? sslcCerficates[1].original_file_path
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </div>
                </div>
                {educationQualification.includes("UG") && (
                  <section className=" sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">UG</p>

                        <div className="row mb-3">
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Certificate Number
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[2].UGCertificateNumber || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Year of Passing
                              </label>
                              <span className="ReviewLabel">
                                {" "}
                                {sslcCerficates[2].UGYearpassing || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Degree
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[2].UGDegree || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Major
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[2].UGMajor || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks Percentage
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[2].UGMarksPercentage || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Class
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[2].UGClassName || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Name of the college / university
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[2].UGNameInstitute || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Uploaded UG Certificate
                                {sslcCerficates[2].UG_Path && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${sslcCerficates[2].UG_Path}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[2].UG_Path !== null
                                  ? sslcCerficates[2].original_file_path
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("PG") && (
                  <section className=" sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">PG</p>

                        <div className="row mb-3">
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Certificate Number
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[3].PGCertificateNumber || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Year of Passing
                              </label>
                              <span className="ReviewLabel">
                                {" "}
                                {sslcCerficates[3].PGYearpassing || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Degree
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[3].PGDegree || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Major
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[3].PGMajor || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks Percentage
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[3].PGMarksPercentage || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                class
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[3].PGClassName || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Name of the college / university
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[3].PGNameInstitute || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Uploaded Certificate
                                {sslcCerficates[3].PG_Path && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${sslcCerficates[3].PG_Path}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[3].PG_Path !== null
                                  ? sslcCerficates[3].original_file_path
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("Integrated PG") && (
                  <section className=" sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Integrated PG</p>

                        <div className="row mb-3">
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Certificate Number
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[4].IPCertificateNumber || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Year of Passing
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[4].IPYearpassing || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Degree
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[4].IPDegree || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Major
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[4].IPMajor || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks Percentage
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[4].IPMarksPercentage || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Class
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[4].IPClassName || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Name of the college / university
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[4].IPNameInstitute || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Uploaded Certificate
                                {sslcCerficates[4].IP_Path && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${sslcCerficates[4].IP_Path}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[4].IP_Path !== null
                                  ? sslcCerficates[4].original_file_path
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("Diploma") && (
                  <section className=" sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Diploma</p>

                        <div className="row mb-3">
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Certificate Number
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[5].DCertificateNumber || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Year of Passing
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[5].DYearpassing || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Degree
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[5].DDegree || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Major
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[5].DMajor || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks of percentage
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[5].DMarksPercentage || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Class
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[5].DClassName || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Name of the college / university
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[5].DNameInstitute || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Uploaded Certificate
                                {sslcCerficates[5].D_Path && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${sslcCerficates[5].D_Path}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[5].D_Path !== null
                                  ? sslcCerficates[5].original_file_path
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("Professional Courses") && (
                  <section className=" sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Professional Courses</p>

                        <div className="row mb-3">
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Certificate Number
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[6].PCCertificateNumber || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Year of Passing
                              </label>
                              <span className="ReviewLabel">
                                {" "}
                                {sslcCerficates[6].PCYearpassing || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Degree
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[6].PCDegree || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Major
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[6].PCMajor || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks Percentage
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[6].PCMarksPercentage || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Class
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[6].PCClassName || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Name of the college / university
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[6].PCNameInstitute || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Uploaded Certificate
                                {sslcCerficates[6].PC_Path && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${sslcCerficates[6].PC_Path}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[6].PC_Path !== null
                                  ? sslcCerficates[6].original_file_path
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {educationQualification.includes("Others") && (
                  <section className=" sectionSix">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Others</p>

                        <div className="row mb-3">
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Certificate Number
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[7].OtCertificateNumber || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Year of Passing
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[7].OtYearpassing || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Degree
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[7].OtDegree || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Major
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[7].OtMajor || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks Percentage
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[7].OtMarksPercentage || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Class
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[7].OtClassName || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Name of the college / university
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[7].OtNameInstitute || ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Uploaded Certificate
                                {sslcCerficates[7].Ot_Path && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${sslcCerficates[7].Ot_Path}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[7].Ot_Path !== null
                                  ? sslcCerficates[7].original_file_path
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                <div className="sectionSix">
                  <div className="row">
                    {techQualification.length > 0 &&
                      !(
                        techQualification[0].name === "" &&
                        techQualification[0].level === "" &&
                        techQualification[0].lang === "" &&
                        techQualification[0].Cnum === "" &&
                        techQualification[0].grade === "" &&
                        techQualification[0].year === "" &&
                        techQualification[0].file === ""
                      ) &&
                      techQualification.map((el, index) => (
                        <div
                          className={
                            techQualification.length === 1
                              ? "col-md-12 "
                              : "col-md-12 "
                          }
                          key={index}
                        >
                          <section className=" sectionSix">
                            <div className="row box ">
                              <div className="col-md-12 ">
                                <p className="StepTitle">
                                  Technical Qualification{" "}
                                  {techQualification.length === 1
                                    ? " "
                                    : `- ${el.id}`}
                                </p>

                                <div className="row mb-3">
                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Technicnal Course
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.name || ""}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Typewriting Level
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.level || ""}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Language
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.lang || ""}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Certificate Number
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.Cnum || ""}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Grade
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.grade || ""}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Year of Passing
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.year !== "" ? el.year : ""}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted "
                                      >
                                        Upload Certificate
                                        {el.file && (
                                          <a
                                            target="_blank"
                                            href={`${ImageUrlPrefix}/${el.file}`}
                                          >
                                            {" "}
                                            <Download className="download_icon" />
                                          </a>
                                        )}
                                      </label>
                                      <span className="ReviewLabel">
                                        {el.original_file_path || ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      ))}
                  </div>
                </div>
                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Counselling Awareness Program Enrollment
                      </p>

                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Category
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.category || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the Exam You Passed
                            </label>
                            <span className="ReviewLabel">
                              {list.ExamName_passed !== ""
                                ? list.ExamName_passed
                                : list.ExamName}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Exam Registration Number
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.ExamRegno || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Location
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.location_name || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Exam Result Info</p>

                      <div className="row mb-3">
                        {list.writtenexammark !== null &&
                          list.writtenexammark !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Written Exam Mark
                                </label>
                                <span className="ReviewLabel">
                                  {list.writtenexammark || ""}
                                </span>
                              </div>
                            </div>
                          )}
                        {list.Interviewmarks !== null &&
                          list.Interviewmarks !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Interview marks
                                </label>
                                <span className="ReviewLabel">
                                  {list.Interviewmarks || ""}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>

                      <div className="row mb-3">
                        {list.overallrank !== null &&
                          list.overallrank !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Overall rank
                                </label>
                                <span className="ReviewLabel">
                                  {list.overallrank || ""}
                                </span>
                              </div>
                            </div>
                          )}
                        {list.communalrank !== null &&
                          list.communalrank !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Communal Rank
                                </label>
                                <span className="ReviewLabel">
                                  {list.communalrank || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markPaperone !== null &&
                          list.markPaperone !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mark uptained in Paper I
                                </label>
                                <span className="ReviewLabel">
                                  {list.markPaperone || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markPaperTwo !== null &&
                          list.markPaperTwo !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mark uptained in Paper II
                                </label>
                                <span className="ReviewLabel">
                                  {list.markPaperTwo || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markpaperThree !== null &&
                          list.markpaperThree !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mark uptained in Paper III
                                </label>
                                <span className="ReviewLabel">
                                  {list.markpaperThree || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markPaperFour !== null &&
                          list.markPaperFour !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mark uptained in Paper IV
                                </label>
                                <span className="ReviewLabel">
                                  {list.markPaperFour || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markPaperFive !== null &&
                          list.markPaperFive !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mark uptained in Paper V
                                </label>
                                <span className="ReviewLabel">
                                  {list.markPaperFive || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markPaperSix !== null &&
                          list.markPaperSix !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mark uptained in Paper VI
                                </label>
                                <span className="ReviewLabel">
                                  {list.markPaperSix || ""}
                                </span>
                              </div>
                            </div>
                          )}
                        {list.markPaperSeven !== null &&
                          list.markPaperSeven !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mark uptained in Paper VII
                                </label>
                                <span className="ReviewLabel">
                                  {list.markPaperSeven || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markPaperEight !== null &&
                          list.markPaperEight !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mark uptained in Paper VIII
                                </label>
                                <span className="ReviewLabel">
                                  {list.markPaperEight || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markPaperNine !== null &&
                          list.markPaperNine !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Mark uptained in Paper VIII
                                </label>
                                <span className="ReviewLabel">
                                  {list.markPaperNine || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markUpdatedMains !== null &&
                          list.markUpdatedMains !== "" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Total marks uptained in mains
                                </label>
                                <span className="ReviewLabel">
                                  {list.markUpdatedMains || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.markUpdatedOral !== null &&
                          list.markUpdatedOral !== "" && (
                            <div className="col-md-4">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Total marks uptained marked uptained in oral
                                  test
                                </label>
                                <span className="ReviewLabel">
                                  {list.markUpdatedOral || ""}
                                </span>
                              </div>
                            </div>
                          )}

                        {list.StenoRank !== null && list.StenoRank !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Steno Rank
                              </label>
                              <span className="ReviewLabel">
                                {list.StenoRank || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {list.WindowRank !== null && list.WindowRank !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Widow Rank
                              </label>
                              <span className="ReviewLabel">
                                {list.WindowRank || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {list.tamilrank !== null && list.tamilrank !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks in Tamil
                              </label>
                              <span className="ReviewLabel">
                                {list.tamilrank || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {list.typistrank !== null && list.typistrank !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Typist Rank
                              </label>
                              <span className="ReviewLabel">
                                {list.typistrank || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {list.PSTMRank !== null && list.PSTMRank !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                PSTM Rank
                              </label>
                              <span className="ReviewLabel">
                                {list.PSTMRank || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {list.DRank !== null && list.DRank !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Disabled Rank
                              </label>
                              <span className="ReviewLabel">
                                {list.DRank || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {list.MarksOne !== null && list.MarksOne !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marks in Tier I
                              </label>
                              <span className="ReviewLabel">
                                {list.MarksOne || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {list.MarksTwo !== null && list.MarksTwo !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Marls in Tier II
                              </label>
                              <span className="ReviewLabel">
                                {list.MarksTwo || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {list.tamilrank !== null && list.tamilrank !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Rank in Tamil Medium Reservation
                              </label>
                              <span className="ReviewLabel">
                                {list.tamilrank || ""}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Preferred Post
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {selectedPre
                                .map((item) => `${item.label}`)
                                .join(", ")}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Preferred Department
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {selectedPreDept
                                .map((item) => `${item.label}`)
                                .join(", ")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Batch Details</p>

                      {batchDetails
                        .filter((item) => item.is_selected === true)
                        .map((el, index) => (
                          <div className="row" key={index}>
                            <div className="col-md-12 mt-3 mb-3">
                              <div className="card  text-dark">
                                <div className="card-body BatchImage">
                                  <div className="row">
                                    <div className="col-md-4 d-flex align-item-center">
                                      <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                          <img
                                            src={`${ImageUrlPrefix}/${el.img_url}`}
                                            // src="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg"
                                            id={el.id}
                                            alt="Imge"
                                          />
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-12 ImageText ">
                                          <span className="ReviewLabels ms-3">
                                            {el.course_name || ""}
                                            {"-"}
                                            {el.name || ""}
                                          </span>

                                          <p className="subtitle mb-1 mt-4  ms-5">
                                            Interview Fee
                                          </p>
                                          <div className="row mt-1 d-flex ms-5 flex-column-reverse">
                                            <div className="col-md-11 p-0">
                                              <span
                                                class="material-icons"
                                                id="mockinmcurr"
                                              >
                                                currency_rupee
                                              </span>
                                              <p className="subtitle cxcxcxc mt-1 mb-0">
                                                {el.exam_fees.map((mm) => {
                                                  const selectedPanelCount =
                                                    el.panel_details.filter(
                                                      (panel) =>
                                                        panel.is_selected_panel
                                                    ).length;

                                                  if (
                                                    selectedPanelCount ===
                                                    mm.fees_count
                                                  ) {
                                                    return mm.fees; // Convert fees to string if you need it as a string
                                                  } else {
                                                    return 0;
                                                  }
                                                })}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-7 mt-3 mb-2 col-md-10 col-sm-12 col-xl-6">
                                      {el.panel_details.map((items, index) => (
                                        <>
                                          <div className="row">
                                            <div className="col-md-12 col-sm-12 ">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <li className="IconsImage">
                                                    <span className="material-icons me-3">
                                                      format_shapes
                                                    </span>
                                                    <div className="d-flex flex-column">
                                                      <p className="title ms-1">
                                                        Panel Name
                                                      </p>
                                                      <p className="subtitle ms-1">
                                                        {items.panel_name}
                                                      </p>
                                                    </div>
                                                  </li>
                                                </div>
                                                <div className="col-md-4">
                                                  <li className="IconsImage">
                                                    <span className="material-icons me-3">
                                                      event
                                                    </span>
                                                    <div className="d-flex flex-column ">
                                                      <p className="title ms-1">
                                                        Date
                                                      </p>
                                                      <p className="subtitle ms-1">
                                                        {moment(
                                                          items.date
                                                        ).format("Do MMM YYYY")}
                                                      </p>
                                                    </div>
                                                  </li>
                                                </div>
                                                <div className="col-md-4">
                                                  <li className="IconsImage">
                                                    <span className="material-icons me-3">
                                                      timer
                                                    </span>
                                                    <div className="d-flex flex-column ">
                                                      <p className="title ms-1">
                                                        Time
                                                      </p>
                                                      <p className="subtitle ms-1">
                                                        {items.event_time}
                                                      </p>
                                                    </div>
                                                  </li>
                                                </div>
                                              </div>
                                            </div>
                                            {index !== 0 && (
                                              <span className="border mt-3 mb-3"></span>
                                            )}
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </section>

                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Have You Cleared Any Exam Previously?
                      </p>
                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted  oneline"
                            >
                              Have You Cleared Any Exam Previously?
                            </label>
                            <span className="ReviewLabel mt-2">
                              {list.ExamPreviously || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted  oneline"
                            >
                              How many exams have you cleared in the past?
                            </label>
                            <span className="ReviewLabel mt-2">
                              {list.ExamPreviously === "Yes"
                                ? list.ExamWrite || ""
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted  oneline"
                            >
                              Exam details
                            </label>
                            <span className="register_app_val mb-3">
                              {list.ExamPreviously === "Yes"
                                ? examDetailsExam
                                    .map((item) => item.examvalue)
                                    .join(", ") || ""
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Are you a government employee?
                      </p>
                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted  oneline"
                            >
                              Are you a government employee?
                            </label>
                            <span className="ReviewLabel">
                              {list.workingRes || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Designation
                            </label>
                            <span className="ReviewLabel">
                              {list.workingRes === "Yes"
                                ? list.Designation || ""
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Department
                            </label>
                            <span className="ReviewLabel">
                              {list.workingRes === "Yes"
                                ? list.Department || ""
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Joining Date of Government Job
                            </label>
                            <span className="ReviewLabel">
                              {list.workingRes === "Yes" ? list.JoinGovt : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Are You an AIASA Student?</p>
                      <div className="row mb-3">
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted  oneline"
                            >
                              Are You an AIASA Student?
                            </label>
                            <span className="ReviewLabel">
                              {list.AIASAStudent || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Year
                            </label>
                            <span className="ReviewLabel">
                              {list.AIASAStudent === "Yes"
                                ? list.Year || ""
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              category
                            </label>
                            <span className="ReviewLabel">
                              {list.AIASAStudent === "Yes"
                                ? list.CategoryAias || ""
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Course
                            </label>
                            <span className="ReviewLabel">
                              {list.AIASAStudent === "Yes"
                                ? list.CourseAias || ""
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              ID Number
                            </label>
                            <span className="ReviewLabel">
                              {list.AIASAStudent === "Yes"
                                ? list.IDNumber || ""
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted  oneline"
                            >
                              Upload ID Card
                              {list.AIASAStudent === "Yes" &&
                                list.IDPhoto !== "" &&
                                list.IDPhoto !== null && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${list.IDPhoto}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                            </label>
                            <span className="ReviewLabel">
                              {list.AIASAStudent === "Yes"
                                ? list.IDPhoto !== null && list.IDPhoto !== ""
                                  ? list.original_aiasa_id_path
                                  : ""
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {edit != null && (
                    <section className="sectionSix">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">Review by Admin</p>
                          <div className="row mb-3">
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label mb-2 text-muted  oneline"
                                >
                                  Application Status
                                </label>
                                <span className="ReviewLabel">
                                  {edit == 2 && "Hold"}
                                  {edit == 3 && "Rejected"}
                                  {edit == 0 && "Wait for approval"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                  {edit != null && (
                    <section className="sectionSix">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">Student Communication</p>

                          <div className="row  mb-3 mt-3">
                            {commentSec.length > 0 &&
                              commentSec.map((el) => (
                                <div className="col-md-12">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label mt-2 mb-2 text-muted  oneline"
                                    >
                                      {el.user_type == 0 && "Comment by Admin"}{" "}
                                      {el.user_type == 1 && "Reply By Student"}{" "}
                                      {el.updated_dt != null &&
                                        `- ${moment(el.updated_dt).format(
                                          "YYYY-MM-DD"
                                        )}`}
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.comment != null || el.comment != ""
                                        ? el.comment
                                        : "None"}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            <div className="col-md-6">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label mb-0 text-muted  oneline"
                                >
                                  {" "}
                                  Reply by Student
                                </label>
                                <textarea
                                  class="Inputs"
                                  ref={txtAdReply_student}
                                  onChange={handleChange}
                                  defaultValue={list.reply_student}
                                  id="reply_student"
                                  rows="4"
                                  name="reply_student"
                                  placeholder="Reply by Student"
                                ></textarea>
                                {listErrors.reply_student && (
                                  <p className="errors">
                                    {listErrors.reply_student}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div
                          className="form-check form-check-inline"
                          id="Verifycheckbox"
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Verify"
                            ref={txtadVerifyHere}
                            name="Verify"
                            onChange={handleChange}
                            checked={list.Verify === true ? true : false}
                            value="Yes"
                          />
                          <label className="LablePrivacyText" htmlFor="Verify">
                            I hereby certify that the above information are true
                            and correct to the best of my knowledge and belief.
                            <a
                              className="LablePrivacy align-baseline"
                              href={require("../../../PrivacyPolicyTermsCondition.pdf")}
                              target="_blank"
                            >
                              Terms and Conditions
                            </a>
                          </label>
                          {listErrors.Verify && (
                            <span className="errors align-top ms-3">
                              {listErrors.Verify}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="heading  mt-3 mb-4 dflexbetww ">
                        <div className="">
                          <button
                            type="button"
                            className="SaveButton_Prev"
                            onClick={handlePrevious}
                          >
                            <a href="javascript:void(0)">
                              Go to Course Selection
                            </a>
                          </button>
                        </div>
                        <div className="d-flex smButton  mobiledown">
                          <Link to="/" className="CancelButton">
                            Back to Home
                          </Link>
                          <button
                            type="button"
                            className="SaveButton"
                            onClick={handleNexts}
                          >
                            <a href="javascript:void(0)">Submit and Update</a>
                          </button>
                        </div>
                      </div>
                    </section>
                  )}
                </section>
              </div>
            </div>
          </fieldset>
        )}
      </form>
    </div>
  );
}

export default ReviewCounselling;
