
import React, { useEffect, useState } from "react";
import PanelMembersLiistview from "./PanelMembersLiistview";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReadViewPMem from "./ReadViewPMem";
import PanelMemberschedule from "./PanelMemberschedule";
import PanelMemberSListview from "./PanelMemberSListview";




function PanelMemberView() {
  const {userId , staff_id , active_tab} = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
 
  useEffect(() => {
    // Update the activeTab state when the component mounts and whenever active_tab changes in the URL
    setActiveTab(Number(active_tab) || 1);
    // setActiveTab(1);
  }, [active_tab]);
 
 
  const handleTabClick = (index) => {
    setActiveTab(index);
 
    navigate(`/Faculties/PanelMember/${userId}/${staff_id}/${index}`);
 
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  return (
    <div className="BathDet ">
      <div className="row">
      <div className="col-lg-3 col-md-5 col-6 col-sm-6">
          <h6 className="Title">View Faculty Profile</h6>
        </div>
        <div className="col-md-7 col-lg-9 d-flex justify-content-end mt-2">
        <Link to="/Faculties/3"  className="CancelButtons">
              Back to Faculties
         </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
        {/* nav-tabs */}
          <ul  
            className="nav  nav-fill mb-3"
            id="listViewTabs"
            role="tablist"
          >
          
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 1 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-2"
                aria-selected={activeTab === 1}
                onClick={() => handleTabClick(1)}
              >
              Schedule
              </a>
            </li>
           
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 2 ? "nav-link active " : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex3-tabs-3"
                aria-selected={activeTab === 2}
                onClick={() => handleTabClick(2)}
              >
               Salary
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 3 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-1"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-1"
                aria-selected={activeTab === 3}
                onClick={() => handleTabClick(3)}
              >
                Profile
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="borderActive"></div> */}
      </div>
{/* // tab content   */}
<PanelMemberschedule  userId={userId} staff_id={staff_id} activeTab={activeTab}/>
<ReadViewPMem userId={userId} staff_id={staff_id} activeTab={activeTab}/>

<PanelMemberSListview staff_type={3}  userId={userId} staff_id={staff_id} activeTab={activeTab} />
 
    </div>
  );
}

export default PanelMemberView;
