import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { AxiosInstance } from "../axios/index";

export const UserContext = React.createContext();

const UserDetailsContextProvider = (props) => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails) {
      sessionStorage.setItem("userDetails", userDetails);
      setUser(JSON.parse(userDetails));
    } else {
      setUser("");
    }
  }, []);

  //login user details

  const loginUser = (arg, callback) => {
    sessionStorage.setItem("userDetails", JSON.stringify(arg));
    localStorage.setItem("userDetails", JSON.stringify(arg));
    setUser(arg);
    callback();
  };
  //logout user details
  const logout = () => {
    AxiosInstance.post(`api/user/logout`, {
      user_id: user.user_id,
    })
      .then((res) => {
        if (res.status === 200) {
          setUser("");
          sessionStorage.removeItem("userDetails");
          sessionStorage.removeItem("stateRefreshs", true);
          sessionStorage.removeItem("stateRefreshs_test", true);
          sessionStorage.removeItem("stateRefreshs_spl", true);
          sessionStorage.removeItem("stateRefreshs_model", true);
          sessionStorage.removeItem("stateRefreshs_mock", true);
          sessionStorage.removeItem("stateRefreshs_coun", true);
          sessionStorage.removeItem("user_id", true);
          sessionStorage.removeItem("role_id", true);
          sessionStorage.removeItem("Is_remember", true);
          sessionStorage.removeItem("active_user_count", true);

          localStorage.removeItem("userDetails");
          localStorage.removeItem("stateRefreshs", true);
          localStorage.removeItem("stateRefreshs_test", true);
          localStorage.removeItem("stateRefreshs_spl", true);
          localStorage.removeItem("stateRefreshs_model", true);
          localStorage.removeItem("stateRefreshs_mock", true);
          localStorage.removeItem("stateRefreshs_coun", true);
          localStorage.removeItem("user_id", true);
          localStorage.removeItem("role_id", true);
          localStorage.removeItem("Is_remember", true);
          localStorage.removeItem("active_user_count", true);
        } else {
          Swal.fire({
            title: "warning",
            text: res.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  return (
    <UserContext.Provider
      value={{ user: user, login: loginUser, logout: logout }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserDetailsContextProvider;
