import React from "react";
import "./CustomDropdown.css";
function CustomDropdown(props) {
  return (
    <div className="selectInputs">
      <select
        className={`form-select dropdownarrow_image  m-2 ${props.className}`}
        aria-label="Select admission type"
        value={props.selectedValue}
        onChange={props.onChange}
      >
        <option value="" disabled>
          Please Select
        </option>
        {props.options?.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CustomDropdown;
