import React, { useEffect, useState } from "react";
import { json, Link } from "react-router-dom";

export default function BranchLocation() {
  const [data, setData] = useState([]);

  const handleEdit = (index) => {
    window.location.href = `/branchedit/${index}`;
  };

  const handleDelete = (index) => {
    // console.log(index,"...delete index");
    const Item = sessionStorage.getItem("branchdata");
    if (Item) {
      const Items = JSON.parse(Item);
      const DeleteItem = Items.filter((Item, i) => i !== index);
      // console.log(DeleteCourse,"....selectedCourse");
      sessionStorage.setItem("branchdata", JSON.stringify(DeleteItem));
      window.location.reload();
    }
  };

  useEffect(() => {
    const storedItem = sessionStorage.getItem("branchdata");
    if (storedItem) {
      setData(JSON.parse(storedItem));
    }
  }, []);
  // console.log(data);
  return (
    <>
      <div className="p-5 ">
        <button className="btn btn-success Test">
          <Link to="/branchedit" className="Test_link">
            Create Branch
          </Link>
        </button>
      </div>

      <div className="p-5">
        <h1>Branch List</h1>
        {data.length > 0 ? (
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Branch Name</th>
                <th>Address</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>City</th>
                <th>District</th>
                <th>State</th>
                <th>Zipcode</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.branch_name}</td>
                  <td>{item.address}</td>
                  <td>{item.email}</td>
                  <td>{item.mobile_no}</td>
                  <td>{item.city}</td>
                  <td>{item.district}</td>
                  <td>{item.state}</td>
                  <td>{item.zipcode}</td>

                  <td>
                    <button
                      className="btn btn-primary me-2"
                      onClick={() => handleEdit(index)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div class="alert alert-danger" role="alert">
            No branch data found.
          </div>
        )}
      </div>
    </>
  );
}
