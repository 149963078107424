import React from "react";
import styles from "./Pagination.module.css";
function Pagination(props) {
  return (
    <div className={`${styles.paginationHeader} container-fluid`}>
      <div className="row ">
        <div className="offset-md-7 col-md-5 ">
          <div
            className={`${styles.supPageListDrop} ${styles.paginatonDiv} w-100 `}
          >
            <span className={`${styles.itemPage}`}>Items Per Page</span>
            <select
              id={`${styles.paginationList}`}
              name="modelExamList"
              className={`${styles.pagecountDropdown}`}
              defaultValue={props.rowsPerPage}
              onChange={props.handlePageCount}
              required
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className={`${styles.pageNumber}`}>
              {props.startPage}-{props.endPage}
              {"  "}
              of {props.totalRecords}
            </span>

            <button
              className={props.prevButton}
              onClick={props.handlePrev}
              disabled={props.currentPrevPage}
            >
              <span
                class={`${styles.materialIconsRightAndLeft} material-icons`}
              >
                chevron_left
              </span>
            </button>
            <button
              className={props.prevButton}
              onClick={props.handleNext}
              disabled={props.currentNextPage}
            >
              <span
                class={`${styles.materialIconsRightAndLeft} material-icons`}
              >
                chevron_right
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
