// HomeFull.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomeFull.css'; // Import the CSS file for styling

const HomeFull = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className="homefull-popup">
      <div className="homefull-content">
        <h1>Suggestion</h1>
        <p>Are you sure you want to go back to the home page?</p>
        <button onClick={handleBackToHome}>Back to Home</button>
      </div>
    </div>
  );
};

export default HomeFull;
