import React, { useEffect, useState } from 'react'
import { json, Link } from 'react-router-dom';
import { AxiosInstance } from '../../../../axios';

export default function BatchUpdate() {

const [data, setData] = useState([]);

const handleEdit=(id)=>
{
    
}

const handleDelete=(id)=>
{
 

}

useEffect(() => {

  AxiosInstance.post("api/batch/list")
 .then((res) => {
  const batchList = res.data.data;
  setData(batchList);
 })
.catch((error) => {
return error;
});


}, [])


  return (


<div className="container-fluid Classes mt-5">
<div className="row">
  <div className="col-md-3">
    <Link to="/batchedit" className="SaveButton">
      Create Batch List
    </Link>
  </div>
</div>

<div className="row mt-5 mb-5">
  <div className="col-md-12 text-center">
    <h1 className="Title text-center">Batch List</h1>
    {data.length > 0 ? (
      <table class="table table-bordered">
        <thead>
          <tr>
              <th>Course Category</th>
              <th>Class Mode</th>
              <th>Fees</th>
              <th>Medium</th>
              <th>Duration</th>
              <th>Start on</th>
              <th>Batch timing</th>
              <th>Action</th>
          </tr>
        </thead>
        <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.category} - {item.subcategory !=null ? item.subcategory : ""} - {item.category_class}</td>
                <td>{item.class_mode}</td>
                <td>{item.fees}</td>
                <td>{item.medium}</td>
                <td>{item.duration} </td>
                <td>{item.started_dt}</td>
                <td>{item.batch_type} - {item.start_time} - {item.end_time}</td>
                <td>
                  <button className='button me-2' onClick={()=>handleEdit(item.id)}> <span class="material-icons">edit</span></button>
                  <button className='button' onClick={()=>handleDelete(item.id)}>       <span class="material-icons">delete</span></button>
                </td>
              </tr>
            ))}
             
        </tbody>
      </table>
   ) : (
      <div class="alert alert-danger" role="alert">
        No class data found.
      </div>
    )} 
  </div>
</div>
</div>
  )
}
