import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined, Pending } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
import DatePickerSample from "../../DatePicker";
import Loader from "../../Loader";
function PendingAdmission(props) {
  //usestate

  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const txtBSearch = useRef(null); //search icon
  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const [updatePag, setUpdatePag] = useState(false);
  const [updatePagDis, setUpdatePagDis] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updateListData, setUpdateListData] = useState(listData);
  const [isOpen, setIsOpen] = useState({
    status: false,
    startDate: false,
    section: false,
    category: false,
    pay_duedate: false,
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const [appDataDue, setAppDataDue] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("UserId Not available");
  }

  const [updateChipData, setUpdateChipData] = useState([]);

  const [status, setStatus] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [appOn_due, setAppOn_due] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [section, setSection] = useState([]);

  let filterStatus = [];
  let filterType = [];
  let filterApplyed = [];
  let filterApplyedDue = [];
  let filterSearch = [];
  let filtersection = [];

  // filter useEffect

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    filterStatus = status;
  }, [status]);

  useEffect(() => {
    filtersection = section;
  }, [section]);

  useEffect(() => {
    // console.log(" names : ", typeOn);
    filterType = typeOn;
  }, [typeOn]);

  useEffect(() => {
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    filterApplyedDue = appOn_due;
  }, [appDataDue]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    if (props.activeTab === 1) {
      loadChipdata();
    }
  }, []);

  useEffect(() => {
    if (props.activeTab === 1) {
      loadListdata();
    }
  }, [props.activeTab]);

  // useEffect(() => {
  //   if (!updatePag) {
  //     loadListdata();
  //   }
  // }, [rowsPerPage, currentPage]);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  // useEffect(() => {
  //   console.log(" updateListData : ", updateListData);
  //   currentData = updateListData.slice(start, end);
  //   // total table data  with pages
  //   totalPages = Math.ceil(updateListData.length / rowsPerPage);
  // }, [updateListData]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    filterApplyed = appOn;
    filtersection = section;

    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  const handlePayFee = (CourseDetail) => {
    navigate(`/PayFee`, {
      state: {
        CourseDetail,
        type_label: 0,
      },
    });
  };

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    // console.log(typename, " : ", status);
    const { value, checked, name } = event.target;
    if (
      name === "Upcoming" ||
      name === "Ongoing" ||
      name === "Completed" ||
      name === "WaitForApproval" ||
      name === "Rejected" ||
      name === "Approved" ||
      name === "OnHold"
    ) {
      if (checked) {
        // setStatus([...status, value]);
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (
      name === "course" ||
      name === "TestBatch" ||
      name === "SpecialClass" ||
      name === "Counselling" ||
      name === "MockInterview" ||
      name === "ModelExam"
    ) {
      if (checked) {
        setTypeOn([...typeOn, { id: value, name: name, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.id !== value));
      }
    } else if (name === "A" || name === "B" || name === "C" || name === "D") {
      if (checked) {
        // setStatus([...status, value]);
        setSection([...section, { id: value, name: name, type: "section" }]);
      } else {
        setSection(section.filter((item) => item.id !== value));
      }
    }
  };

  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  //----handle Search Icon
  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        bubbles: true,
        code: "Enter",
        keyCode: 13,
        which: 13,
      });
      txtBSearch.current.dispatchEvent(event);
    }
  };
  //---end

  //chip data list view
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setSection([]);
    setUpdateChipData([]);
    setAppOn([]);
    setAppOn_due([]);
    setTypeOn([]);
    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setAppDataDue({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    // console.log(section);
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "section") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        section: false,
      });
    } else if (name === "pay_duedate") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        pay_duedate: false,
      });
    }
  };

  //load list data for table
  function loadListdata() {
    let tempstatus = [];
    let temptypeon = [];
    let tempnumstatus = [];
    let tempTypenum = [];
    //  console.log(typeOn,"typeOn");
    let tempsection = [];
    status.map((obj) => {
      tempstatus.push(obj.id);
    });
    typeOn.map((obj) => {
      temptypeon.push(obj.id);
    });
    section.map((obj) => {
      tempsection.push(obj.name);
    });
    // status array to number array

    tempnumstatus = tempstatus.map(Number);

    tempTypenum = temptypeon.map(Number);

    let statusFilter = {
      type: typeOn.length > 0 ? tempTypenum : "",
      section: section.length > 0 ? tempsection : "",
      a_start_date: appOn.length > 0 ? appOn[0].fdate : "",
      a_end_date: appOn.length > 0 ? appOn[0].edate : "",
      p_start_date: appOn_due.length > 0 ? appOn_due[0].fdate : "",
      p_end_date: appOn_due.length > 0 ? appOn_due[0].edate : "",
      search: searchTerm.length > 0 ? searchTerm : "",
      user_id: userid,
      // page_size: rowsPerPage,
      // page_no: currentPage,
      page_no: "",
      page_size: "",
    };
    setIsLoading(true);
    AxiosInstance.post("api/purchase/pending/payment", statusFilter)
      .then((res) => {
        if (res.data.status === true) {
          const fiata = res.data.data;
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          setUpdateListData(serialNumberAddedData);
          setIsLoading(false);
        } else if (res.data.status === false) {
          setUpdateListData([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setUpdateListData([]);
        setIsLoading(false);
        return error;
      });
  }
  //load chip data
  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });

    filterType.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyed.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyedDue.map((obj) => {
      tempArr.push(obj);
    });

    filtersection.map((obj) => {
      tempArr.push(obj);
    });

    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }
  // applied on handlechange
  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };

  const handleAppliedOnDue = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppDataDue((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn_due([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppDataDue((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn_due([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn_due([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn_due([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn_due([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn_due([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn_due([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRangeDue",
          },
        ]);
      }
    }
  };
  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "section") {
      setSection([]);
      filtersection = [];

      setIsOpen({
        ...isOpen,
        section: false,
      });
    } else if (name === "pay_duedate") {
      setAppDataDue({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn_due([]);
      filterApplyedDue = [];
      setIsOpen({
        ...isOpen,
        pay_duedate: false,
      });
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.id !== chipToDelete.id));
      filterType = typeOn.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "section") {
      setSection(section.filter((item) => item.id !== chipToDelete.id));
      filtersection = section.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else if (chipToDelete.type === "dayRangeDue") {
      setAppDataDue({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn_due([]);
      filterApplyedDue = [];
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setAppDataDue({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn_due([]);
      filterApplyedDue = [];
    }
    loadChipdata();
  };

  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };

  const handleChangeDateDue = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppDataDue((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn_due([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };

  //  filter box tootle function
  const handleFilter = (e, boxName) => {
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        startDate: false,
        section: false,
        category: false,
        pay_duedate: false,
        status: !prevState.status,
      }));
    } else if (boxName === "startDate") {
      setIsOpen((prevState) => ({
        section: false,
        category: false,
        status: false,
        pay_duedate: false,
        startDate: !prevState.startDate,
      }));
    } else if (boxName === "section") {
      setIsOpen((prevState) => ({
        category: false,
        status: false,
        startDate: false,
        pay_duedate: false,
        section: !prevState.section,
      }));
    } else if (boxName === "category") {
      setIsOpen((prevState) => ({
        status: false,
        startDate: false,
        section: false,
        pay_duedate: false,
        category: !prevState.category,
      }));
    } else if (boxName === "pay_duedate") {
      setIsOpen((prevState) => ({
        status: false,
        startDate: false,
        section: false,
        category: false,
        pay_duedate: !prevState.pay_duedate,
      }));
    }
  };

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add a title to the PDF document
    doc.text("Table Data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    // Add header row to tableData array
    const headers = [];
    const tableHeader = document.querySelectorAll("#example th");
    for (const header of tableHeader) {
      headers.push(header.innerText);
    }
    tableData.push(headers);

    // Add data rows to tableData array
    const tableRows = document.querySelectorAll("#example tbody tr");
    for (const row of tableRows) {
      const rowData = [];
      const rowCells = row.querySelectorAll("td");
      for (const cell of rowCells) {
        rowData.push(cell.innerText);
      }
      tableData.push(rowData);
    }

    // Add the table to the PDF document
    doc.autoTable({
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
    });
    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
    // setUpdatePag(true);
    // setUpdatePagDis(false);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
    // setUpdatePag(false);
    // setUpdatePagDis(true);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  //detail view for this list data
  const handleReadView = (user_id, batch_id, add_id, type) => {
    navigate(
      `/TransactionHistory/ViewAdmission/${user_id}/${batch_id}/${add_id}/${type}/0`
    );
  };

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div
      className={
        props.activeTab === 1
          ? "row tab-content mt-4"
          : "row tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      <div
        className={
          props.activeTab === 1
            ? "tab-pane fade show active col-md-12"
            : "tab-pane fade col-md-12 "
        }
      >
        <div className="row mb-3 ms-1">
          <div className="col-lg-12 col-md-12 ps-0">
            <div className="ReViewTableInfo_note h-0">
              <strong> NOTE: </strong> If you wish to settle pending fees with
              cash payment, please visit the office reception and contact the
              finance admin for assistance.
            </div>
          </div>
        </div>
        <div className="row ms-1">
          <div className="col-lg-3 col-md-6 ps-0">
            <div class="input-group">
              <input
                ref={txtBSearch} //New
                type="text"
                class="Inputs"
                id="SearchInput"
                name="SearchInput"
                placeholder="Search by Name and course"
                onKeyDown={handleSearch}
              />
              <span
                class="input-group-text"
                id="SearchInput"
                onClick={handleSearchFocus}
              >
                <span className="material-icons" onClick={handleSearchFocus}>
                  search
                </span>
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-4"></div>
          <div className="col-lg-6  d-flex justify-content-evenly col-md-6"></div>
        </div>
        {updateChipData.length > 0 ? (
          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {updateChipData.map((data, index) => {
                  // console.log(data, " - data - ", index);
                  let icon;
                  let key = "" + data.name;
                  return (
                    <ListItem key={data}>
                      <Chip
                        icon={icon}
                        label={key}
                        onDelete={handleDelete(data, index)}
                      />
                    </ListItem>
                  );
                })}

                <ListItem>
                  {/* <Chip
                      label=" Clear All Filters"
                      onClick={handleAllClear}
                    /> */}
                  <a className="allclear" href="#" onClick={handleAllClear}>
                    Clear All Filters
                  </a>
                </ListItem>
              </Paper>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12 table-responsive p-0">
            <table className="table" id="example">
              <thead>
                <tr>
                  <th id="th" style={{ width: "5%" }}>
                    {" "}
                    <span className="text_th">S.No</span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "serialNumber")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th">
                    {" "}
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "admission_id")}
                    >
                      {" "}
                      Admission ID
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "admission_id")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "type")}
                    >
                      Type
                    </span>
                    {/* <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "type")}
                    >
                      {" "}
                      import_export{" "}
                    </span>{" "} */}
                    <span
                      class={` align-middle material-icons  ${
                        typeOn.length > 0 ? "active_icons" : ""
                      }`}
                      onClick={(e) => handleFilter(e, "category")}
                    >
                      filter_list
                    </span>
                    {isOpen.category === true && (
                      <div className="filterBoxTwo p-1">
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="course"
                                value="0"
                                checked={typeOn.some((element) => {
                                  if (element.id === "0") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "course")
                                }
                                id="course"
                              />
                              <label
                                class="form-check-label YellowText"
                                for="defaultCheck1"
                              >
                                Course
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="TestBatch"
                                value="1"
                                checked={typeOn.some((element) => {
                                  if (element.id === "1") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "TestBatch")
                                }
                                id="TestBatch"
                              />
                              <label
                                class="form-check-label GreenText"
                                for="defaultCheck1"
                              >
                                Test Batch
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="SpecialClass"
                                value="3"
                                checked={typeOn.some((element) => {
                                  if (element.id === "3") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "SpecialClass")
                                }
                                id="SpecialClass"
                              />
                              <label
                                class="form-check-label YellowText"
                                for="defaultCheck1"
                              >
                                Special Class
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Counselling"
                                value="2"
                                checked={typeOn.some((element) => {
                                  if (element.id === "2") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Counselling")
                                }
                                id="Counselling"
                              />
                              <label
                                class="form-check-label RedText"
                                for="defaultCheck1"
                              >
                                Counselling
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="MockInterview"
                                value="5"
                                checked={typeOn.some((element) => {
                                  if (element.id === "5") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "MockInterview")
                                }
                                id="MockInterview"
                              />
                              <label
                                class="form-check-label RedText"
                                for="defaultCheck1"
                              >
                                Mock Interview
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="ModelExam"
                                value="4"
                                checked={typeOn.some((element) => {
                                  if (element.id === "4") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "ModelExam")
                                }
                                id="ModelExam"
                              />
                              <label
                                class="form-check-label RedText"
                                for="defaultCheck1"
                              >
                                Model Exam
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("type")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              disabled={typeOn.length === 0 ? true : false}
                              onClick={() => handleApplyFilter("type")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>

                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "batch_name")}
                    >
                      Batch
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "batch_name")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "section")}
                    >
                      Section
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "section")}
                    >
                      import_export
                    </span>{" "}
                    <span
                      class={` align-middle material-icons  ${
                        section.length > 0 ? "active_icons" : ""
                      }`}
                      onClick={(e) => handleFilter(e, "section")}
                    >
                      filter_list
                    </span>
                    {isOpen.section === true && (
                      <div className="filterBoxs p-1">
                        <div className="row mt-4">
                          <div className="col-md-3">
                            <div class="form-check ms-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="A"
                                value="A"
                                id="A"
                                checked={section.some((element) => {
                                  if (element.name === "A") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) => handleCheckboxChange(e, "A")}
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                A
                              </label>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div class="form-check ">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="B"
                                value="B"
                                id="B"
                                checked={section.some((element) => {
                                  if (element.name === "B") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) => handleCheckboxChange(e, "B")}
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                B
                              </label>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div class="form-check ">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="C"
                                value="C"
                                id="C"
                                checked={section.some((element) => {
                                  if (element.name === "C") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) => handleCheckboxChange(e, "C")}
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                C
                              </label>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div class="form-check ">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="D"
                                value="D"
                                id="D"
                                checked={section.some((element) => {
                                  if (element.name === "D") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) => handleCheckboxChange(e, "D")}
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                D
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("section")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("section")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "applied_on")}
                    >
                      Applied On
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "applied_on")}
                    >
                      import_export
                    </span>{" "}
                    <span
                      onClick={(e) => handleFilter(e, "startDate")}
                      class={` align-middle material-icons  ${
                        appOn.length > 0 ? "active_icons" : ""
                      }`}
                    >
                      filter_list
                    </span>
                    {isOpen.startDate === true && (
                      <div className="filterBoxOne container p-1">
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="FDate"
                                className="d-flex form-label"
                              >
                                From Date
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              {/* <input
                                id="FDate"
                                type="date"
                                name="FDate"
                                value={appData.FDate}
                                className="FilterInputs"
                                onChange={handleAppliedOn}
                                placeholder="FDate"
                                autoComplete="FDate"
                                required
                              /> */}
                              <DatePickerSample
                                IconInput={"filterIocnBoxInput"}
                                class={"FilterInputs"}
                                name={"FDate"}
                                handleChange={handleChangeDate}
                                fieldInput={appData.FDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="TDate "
                                className="d-flex form-label"
                              >
                                To Date
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              {/* <input
                                id="TDate"
                                type="date"
                                name="TDate"
                                value={appData.TDate}
                                className="FilterInputs"
                                placeholder="TDate"
                                onChange={handleAppliedOn}
                                autoComplete="TDate"
                                required
                              /> */}
                              <DatePickerSample
                                class={"FilterInputs"}
                                IconInput={"filterIocnBoxInput"}
                                name={"TDate"}
                                handleChange={handleChangeDate}
                                fieldInput={appData.TDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                id="Today"
                                value="Today"
                                checked={appData.Days === "Today"}
                                onChange={handleAppliedOn}
                              />
                              <span class="form-check-label d-flex" for="Today">
                                Today
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Yesterday"
                                checked={appData.Days === "Yesterday"}
                                id="Yesterday"
                                onChange={handleAppliedOn}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Yesterday"
                              >
                                Yesterday
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Tweek"
                                checked={appData.Days === "Tweek"}
                                id="Tweek"
                                onChange={handleAppliedOn}
                              />
                              <span class="form-check-label d-flex" for="Tweek">
                                This week
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Lweek"
                                checked={appData.Days === "Lweek"}
                                id="Lweek"
                                onChange={handleAppliedOn}
                              />
                              <span class="form-check-label d-flex" for="Lweek">
                                Last week
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Tmonth"
                                checked={appData.Days === "Tmonth"}
                                id="Tmonth"
                                onChange={handleAppliedOn}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Tmonth"
                              >
                                This month
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                checked={appData.Days === "Lmonth"}
                                value="Lmonth"
                                id="Lmonth"
                                onChange={handleAppliedOn}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Lmonth"
                              >
                                Last month
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("appliedOn")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("appliedOn")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "paid_amount")}
                    >
                      Fee Paid
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "paid_amount")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "balance_amt")}
                    >
                      Balance Fee
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "balance_amt")}
                    >
                      import_export
                    </span>
                  </th>

                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "pay_duedate")}
                    >
                      Due Date
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "pay_duedate")}
                    >
                      import_export
                    </span>
                    <span
                      onClick={(e) => handleFilter(e, "pay_duedate")}
                      class={` align-middle material-icons  ${
                        appOn_due.length > 0 ? "active_icons" : ""
                      }`}
                    >
                      filter_list
                    </span>
                    {isOpen.pay_duedate === true && (
                      <div className="filterBoxOne container p-1">
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="FDate"
                                className="d-flex form-label"
                              >
                                From Date
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              {/* <input
                                id="FDate"
                                type="date"
                                name="FDate"
                                value={appData.FDate}
                                className="FilterInputs"
                                onChange={handleAppliedOn}
                                placeholder="FDate"
                                autoComplete="FDate"
                                required
                              /> */}
                              <DatePickerSample
                                IconInput={"filterIocnBoxInput"}
                                class={"FilterInputs"}
                                name={"FDate"}
                                handleChange={handleChangeDateDue}
                                fieldInput={appDataDue.FDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="TDate "
                                className="d-flex form-label"
                              >
                                To Date
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              {/* <input
                                id="TDate"
                                type="date"
                                name="TDate"
                                value={appData.TDate}
                                className="FilterInputs"
                                placeholder="TDate"
                                onChange={handleAppliedOn}
                                autoComplete="TDate"
                                required
                              /> */}
                              <DatePickerSample
                                class={"FilterInputs"}
                                IconInput={"filterIocnBoxInput"}
                                name={"TDate"}
                                handleChange={handleChangeDateDue}
                                fieldInput={appDataDue.TDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                id="Today"
                                value="Today"
                                checked={appDataDue.Days === "Today"}
                                onChange={handleAppliedOnDue}
                              />
                              <span class="form-check-label d-flex" for="Today">
                                Today
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Yesterday"
                                checked={appDataDue.Days === "Yesterday"}
                                id="Yesterday"
                                onChange={handleAppliedOnDue}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Yesterday"
                              >
                                Yesterday
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Tweek"
                                checked={appDataDue.Days === "Tweek"}
                                id="Tweek"
                                onChange={handleAppliedOnDue}
                              />
                              <span class="form-check-label d-flex" for="Tweek">
                                This week
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Lweek"
                                checked={appDataDue.Days === "Lweek"}
                                id="Lweek"
                                onChange={handleAppliedOnDue}
                              />
                              <span class="form-check-label d-flex" for="Lweek">
                                Last week
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Tmonth"
                                checked={appDataDue.Days === "Tmonth"}
                                id="Tmonth"
                                onChange={handleAppliedOnDue}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Tmonth"
                              >
                                This month
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                checked={appDataDue.Days === "Lmonth"}
                                value="Lmonth"
                                id="Lmonth"
                                onChange={handleAppliedOnDue}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Lmonth"
                              >
                                Last month
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("pay_duedate")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("pay_duedate")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    {" "}
                    <span className="text_th">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentData.length === 0 ? (
                  <tr>
                    <td colspan="10" style={{ textAlign: "center" }}>
                      No record to display
                    </td>
                  </tr>
                ) : (
                  currentData.map((course, index) => (
                    <tr key={index} title={course.admission_id}>
                      <td id="td">
                        {course.comment === 1 && course.status !== 1 && (
                          <div className="purchase_dot_chat"></div>
                        )}
                        <span>{course.serialNumber}</span>
                      </td>

                      <td id="td">{course.admission_id}</td>
                      {course.type === 0 && (
                        <td id="td">
                          <span>course</span>
                        </td>
                      )}
                      {course.type === 1 && (
                        <td id="td">
                          <span>Test Batch</span>
                        </td>
                      )}
                      {course.type === 2 && (
                        <td id="td">
                          <span>Counselling</span>
                        </td>
                      )}
                      {course.type === 3 && (
                        <td id="td">
                          <span>Special classes</span>
                        </td>
                      )}
                      {course.type === 4 && (
                        <td id="td">
                          <span>Model exam </span>
                        </td>
                      )}

                      {course.type === 5 && (
                        <td id="td">
                          <span>Mock Interview </span>
                        </td>
                      )}
                      <td id="td"> {course.batch_name}</td>
                      <td id="td">{course.section}</td>
                      <td id="td">
                        {moment(course.applied_on).format("YYYY-MM-DD")}
                      </td>
                      <td id="td">₹ {course.paid_amount}</td>
                      <td id="td">₹ {course.balance_amt}</td>
                      <td id="td">
                        {course.pay_duedate
                          ? moment(
                              course.pay_duedate,
                              "DD/MM/YYYY",
                              true
                            ).isValid()
                            ? moment(course.pay_duedate, "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                              )
                            : moment(course.pay_duedate).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td id="td">
                        <button
                          type="button"
                          onClick={() => handlePayFee(course)}
                          className="PyNowBtn"
                        >
                          <span class="material-icons">send</span>
                          <Link className="PyNowBtnLink">Pay Now</Link>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12">
            <div className="pag w-100 dflexcenter">
              <span className="ipage">Items Per Page</span>
              <select
                name="pageCount"
                id="pageCount"
                defaultValue={rowsPerPage}
                onChange={handlePageCount}
                className="ms-2"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pagenumber ms-2">
                {" "}
                {updateListData.length > 0
                  ? currentPage * rowsPerPage + 1 - rowsPerPage
                  : 0}{" "}
                -
                {updateListData.length < currentPage * rowsPerPage
                  ? updateListData.length
                  : currentPage * rowsPerPage}{" "}
                of {updateListData.length}
              </span>
              <button
                className="prev_button ms-2 me-2"
                onClick={(e) => handlePrev(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span class="material-icons">chevron_left</span>
              </button>
              <button
                className="prev_button"
                onClick={() => handleNext(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <span class="material-icons">chevron_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingAdmission;
