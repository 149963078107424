// ModalComponent.js
import React from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import DatePickerSample from "../../DatePicker";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import {
  validateMainHall,
  validateUploadMark,
} from "../Faculty/validateTeachingProgress";
import toast from "react-hot-toast";

function UploadMarkSheet(props) {
  // console.log(props.detail, "dfgggggggg");
  const [uploadMarkSheet, setUploadMarkSheet] = useState([]);
  const [formData, setFormData] = useState({
    marksheet: "",
    original_mainexam_marksheet: null,
  });
  const navigate = useNavigate();
  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "marksheet" && files && files.length > 0) {
      const marksheet = files[0];
      if (
        files[0].type == "text/csv" ||
        files[0].type == "application/vnd.ms-excel"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const payload = new FormData();
          payload.append("marksheet", marksheet);
          AxiosInstance.post("api/upload/", payload)
            .then((res) => {
              setFormData({
                ...formData,
                marksheet: res.data.path,
                original_mainexam_marksheet: res.data.originalname,
              });
              setError({
                ...error,
                marksheet: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setError({
            ...error,
            marksheet: err,
          });
        }
      } else {
        let err = "CSV files only allowed";
        setError({
          ...error,
          marksheet: err,
        });
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };
  const handleUploadMark = (event) => {
    // console.log(formData, props.detail);
    event.preventDefault(); // Prevent the default form submission behavior
    const errors = validateUploadMark(formData);
    if (Object.keys(errors).length === 0) {
      try {
        const dat = {
          batch_id: props.detail ? props.detail.batch_id : "",
          exam_detail_cstm_pk: props.detail
            ? props.detail.exam_detail_cstm_pk
            : "",
          exam_center_cstm_pk: props.detail
            ? props.detail.exam_center_cstm_pk
            : "",
          center_staff_user_id: props.staff_id ? props.staff_id : "",
          paper_type: props.detail ? props.detail.paper_type : "",
          exam_detail_paper_pk:
            props.detail && props.detail.exam_detail_paper_pk
              ? props.detail.exam_detail_paper_pk
              : "",
          created_by: props.userid ? props.userid : "",
          filePath: formData.marksheet,
          original_marksheet_file_path: formData.original_mainexam_marksheet,
          type: 4,
        };
        // console.log(dat, "dat");
        AxiosInstance.post("api/batch/meSupervisor/upload/marksheet", dat)
          .then((res) => {
            if (res.data.status === true) {
              // toast.success(`${res.data.message}`);
              Swal.fire({
                title: "Success",
                text: res.data.message,
                icon: "Success",
                confirmButtonText: "Ok",
              });
              props.handleHallClose();
              props.handleViewApiAgain();
            } else {
              props.handleHallClose();
              // toast.error(`${res.data.message}`);
              Swal.fire({
                title: "Warning",
                text: res.data.message,
                icon: "Warning",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            return error;
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleHallClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 id="exampleModalLabel" className="HallModelTitle mt-2">
            Upload Mark Sheet
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 mt-2 mb-2">
              <div className="forms ">
                <p className="UploadMarkSheetLabel_note alert_info">
                  <strong> NOTE: </strong> Please download the mark sheet
                  template available on the 'View Model Exam' page, fill in the
                  marks for each student according to their exam registration
                  number, and then upload it here.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-lg-9 col-md-12 mb-3">
              <div className="forms ">
                <label htmlFor="marksheet" className="UploadMarkSheetLabel">
                  Upload Mark Sheet
                  {formData.marksheet !== "" && formData.marksheet !== null && (
                    <a
                      href={`${ImageUrlPrefix}/${formData.marksheet}`}
                      target="_blank"
                      download
                    >
                      <span className="material-icons align-middle download_icon">
                        file_download
                      </span>
                    </a>
                  )}
                </label>
                <div className="row">
                  <div
                    className={
                      formData.marksheet !== "" && formData.marksheet !== null
                        ? "col-md-3 "
                        : "col-md-12"
                    }
                  >
                    <input
                      type="file"
                      class={
                        formData.marksheet !== "" && formData.marksheet !== null
                          ? "UploadExamSheetFile nn_upload_width"
                          : "UploadExamSheetFile  vv_upload_width"
                      }
                      onChange={handleChange}
                      id="marksheet"
                      name="marksheet"
                      placeholder="Upload Main Exam Hall Ticket"
                    />
                  </div>
                  {formData.marksheet !== "" && formData.marksheet !== null && (
                    <div className={"col-md-9 d-flex align-content-center p-0"}>
                      <span className="nn_upload_file">
                        {formData.marksheet !== "" &&
                        formData.marksheet !== null
                          ? formData.original_mainexam_marksheet
                          : ""}
                      </span>
                      <span className="material-icons align-middle text-danger verified_upload">
                        verified
                      </span>
                    </div>
                  )}
                </div>

                {error.marksheet && (
                  <p className="errors_main ms-2">{error.marksheet}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link
          data-bs-dismiss="modal"
          onClick={props.handleHallClose}
          className="DashboardCancelButton"
        >
          Back to View Model Exam
        </Link>
        <button
          type="button"
          className="DashboardButton"
          onClick={(e) => handleUploadMark(e)}
        >
          <Link href={"javascript:void(0)"}>Upload Mark Sheet</Link>
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadMarkSheet;
