import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { MEDIA_SIZE_LG } from "../../../../../utils/helper";
import StyledScrollableContainer from "../../../../../ui/StyledScrollableContainer";

// Chart.register(ChartDataLabels);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const BACKGROUNDCOLOR = [
  "rgba(178,0,0,1)",
  "rgba(153,102,255,1)",
  "rgba(0,178,178,1)",
];

const StyledLabelBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 5px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    width: 30px;
    margin-right: 10px;
  }
`;

const StyledLabel = styled.span`
  font-size: 12px;
  /* color: #ccc; */
  @media (min-width: ${MEDIA_SIZE_LG}) {
    margin-right: 30px;
  }
`;
const StyledLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 10px 0;
`;
const TIME_FIXING_BY_MINUTE = 0.016; //New Changes
const options = {
  indexAxis: "y",
  responsive: false,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
      text: "overall analysis",
    },
    legend: {
      display: false,
      position: "top",
    },
    datalabels: {
      color: "#fff",
      display: "true",
      anchor: "end",
      align: "start",
      offset: 2,
      font: {
        weight: "bolder",
      },
      formatter: function (value) {
        // minutes to milliseconds
        // try-1
        // const millisecondsValue = value * 0.017;
        //milliseconds to time
        // const millisecondsValue = value * 60000;
        // const seconds = Math.floor(millisecondsValue % 60);
        // const minutes = Math.floor((millisecondsValue / (1000 * 60)) % 60);
        // const hours = Math.floor((millisecondsValue / (1000 * 60 * 60)) % 24);
        // return `${hours.toString().padStart(2, "0")}h : ${minutes
        //   .toString()
        //   .padStart(2, "0")}m : ${seconds.toString().padStart(2, "0")}s`;
        // return [`0h : om : ${value}s`];

        // try-2 SECONDS TO HOURS:MINUTES:SECONDS (new changes)
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = Math.floor((value % 3600) % 60);
        return `${hours.toString().padStart(2, "0")}h:${minutes
          .toString()
          .padStart(2, "0")}m:${seconds.toString().padStart(2, "0")}s`;
      },
    },
    tooltip: {
      callbacks: {
        title: function (funcData) {
          let title = "";
          title = funcData[0]?.dataset?.label;

          return title;
        },
        label: function (context) {
          const { raw: value } = context;
          // const millisecondsValue = value * 0.017;
          const millisecondsValue = value * 60000;
          // const seconds = Math.floor(millisecondsValue % 60);
          // const minutes = Math.floor((millisecondsValue / (1000 * 60)) % 60);
          // const hours = Math.floor((millisecondsValue / (1000 * 60 * 60)) % 24);
          // REFERENCE
          // const seconds =  (milliseconds / 1000) % 60 ;
          // const minutes =  ((milliseconds / (1000*60)) % 60);
          // const hours   =  ((milliseconds / (1000*60*60)) % 24);
          // REFERENCE
          // const seconds = (millisecondsValue / 1000) % 60;
          // const minutes = (millisecondsValue / (1000 * 60)) % 60;
          // const hours = (millisecondsValue / (1000 * 60 * 60)) % 24;
           // TRY-2 SECONDS TO HOURS,MINUTES,SECONDS
           const hours = Math.floor(value / 3600);
           const minutes = Math.floor((value % 3600) / 60);
           const seconds = Math.floor((value % 3600) % 60);
          const label = `${String(hours).padStart(2, "0")}h : ${String(
            minutes
          ).padStart(2, "0")}m : ${String(seconds).padStart(2, "0")}s`;
          // const label = ` Time `;

          return label;
        },
      },
    },
  },

  scales: {
    x: {
      display: true,
      // stepSize: 15,
      grid: {
        display: false,
      },

      title: {
        display: true,
        text: "Time",
        // padding: 20,
      },
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          // return Math.floor(value * 1.6667e-5) + "m";
          // console.log("sk-callback",Math.floor(value*TIME_FIXING_BY_MINUTE) + "m");
          return Math.floor(value*TIME_FIXING_BY_MINUTE) + "m";
        },
      },
    },
    y: {
      display: true,
      position: "left",
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "",
        padding: 10,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
      },
    },
  },
};
const HorizontalBarChart = ({ labels = [], data = [] }) => {
  // Sample data for 13 tests
  const generatedBgColors = Array.from({ length: labels.length }, (_, i) => {
    let bgArray = [];
    // Calculate the index based on the current value of i
    let ind = i % 13;

    // Push the corresponding color to bgArray
    bgArray.push(BACKGROUNDCOLOR[ind]);

    return bgArray;
  }).flatMap((colorArray) => colorArray);
  const testData = {
    labels: labels,
    datasets: [
      {
        label: "Time",
        backgroundColor: generatedBgColors,
        borderWidth: 0,
        data: data,
      },
    ],
  };
  // Generate legend items
  const legendItems = testData.labels.map((label, index) => {
    return (
      <div
        key={index}
        style={{ display: "flex", alignItems: "center", margin: "5px" }}
      >
        <StyledLabelBox
          style={{
            backgroundColor: testData.datasets[0].backgroundColor[index],
          }}
        ></StyledLabelBox>
        <StyledLabel>{label}</StyledLabel>
      </div>
    );
  });
  return (
    <div>
      <StyledScrollableContainer>
        <Bar data={testData} options={options} height={400} width={1000} />
      </StyledScrollableContainer>
      <StyledLegendContainer>{legendItems}</StyledLegendContainer>
    </div>
  );
};

export default HorizontalBarChart;
