export const validateFEvent = (data, sDate, type, useref_validation) => {
  const errors = {};

  if (data.name == "") {
    useref_validation.name.focus();
  } else if (!data.Method_type && type === "1") {
    useref_validation.Method_type.focus();
  } else if (!data.testType && type === "1") {
    useref_validation.testType.focus();
  } else if (data.eventDate == "" && sDate == "") {
    useref_validation.date.focus();
  } else if (data.startTime == "") {
    useref_validation.name.focus();
  } else if (data.endTime == "") {
    useref_validation.name.focus();
  } else if (data.subject == "" && type != "1") {
    useref_validation.subject.focus();
  } else if (data.staff == "" && type != "1") {
    useref_validation.faculty.focus();
  } else if (!data.no_question && type === "1") {
    useref_validation.testNQ.focus();
  } else if (!data.total_marks && type === "1") {
    useref_validation.testMarks.focus();
  } else if (data.desc == "") {
    useref_validation.description.focus();
  } else if (data.desc_staff == "" && type === "0") {
    useref_validation.staff_dec.focus();
  } else if (!data.qsyllpath) {
    useref_validation.syllabuspaper.focus();
  } else if (!data.DanswerSheet && type === "1") {
    useref_validation.DanswerSheet.focus();
  }
  // else if (!data.qpath && type === "1") {
  //   useref_validation.paper.focus();
  // }
  else if (data.qpath && data.cut_off === null && type === "1") {
    useref_validation.cut_off.focus();
  } else if (
    data.total_marks &&
    data.cut_off &&
    Number(data.cut_off) > Number(data.total_marks) &&
    type === "1"
  ) {
    useref_validation.cut_off.focus();
  }

  return errors;
};
