import React, { useEffect, useState } from "react";
import "./Degree.css";
import { Error } from "../../../../Themes/themes";
import { AxiosInstance } from "../../../../axios/index";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function DegreeEdit() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [editcourse, setEditcourse] = useState([]);

  // setting error value for all input fields
  const [error, setError] = useState({});
  const [initialfields, setInitialfields] = useState({
    degreeType: "",
    degree_name: "",
    department: "",
  });

  /**fetch the data for the id from the url */
  useEffect(() => {
    const coursestoredItem = sessionStorage.getItem("degreedata");
    if (coursestoredItem) {
      const courses = JSON.parse(coursestoredItem);
      const selectedCourse = courses.filter((course, i) => i == id);
      setEditcourse(selectedCourse);
    }
  }, []);

  /**
   * is saved function
   */
  function save() {
    alert("Degree details Saved Successfully");
  }

  /**assign the edit datas to the form in all fields */
  useEffect(() => {
    if (editcourse.length) {
      setInitialfields({
        ...initialfields,
        degreeType: editcourse[0].degreeType,
        degree_name: editcourse[0].degree_name,
        department: editcourse[0].department,
      });
    }
  }, [editcourse]);

  // validate form fields and setting error
  const errors = {};

  const validate = (data) => {
    const currentDate = new Date();
    const selectedDate = new Date(data.startDate);

    if (data.degreeType == "") {
      errors.degreeType = "Please select Degree Type.";
    } else if (data.degree_name == "") {
      errors.degree_name = "Please Enter Degree Name.";
    } else if (data.department == "") {
      errors.department = "Please Enter Department.";
    }

    return errors;
  };

  /**
   * Handle change event for password fields.
   * Updates the corresponding field value in state based on the input field's name.
   * @param {object} event - The change event object.
   * @param {string} event.target.name - The name of the input field.
   * @param {string} event.target.value - The value of the input field.
   */
  const handleChange = (event) => {
    // console.log(event.target.name,event.target.value,"...handlechange...");
    const { name, value, id, checked } = event.target;

    // set input values into state
    setInitialfields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));

    // set error values into state
    setError({
      ...error,
      [name]: "",
    });
  };

  /**
   * Handles submission of the form when form submits.
   * Saves the updated form values to local storage.
   * @param {Object} e - The form submission event.
   */

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(initialfields);

    if (Object.keys(errors).length === 0) {
      try {
        const existingData = sessionStorage.getItem("degreedata") || "[]";
        const parsedData = JSON.parse(existingData);

        if (id) {
          // If an id is provided, update the data at the specified index
          parsedData[id] = initialfields;
          // try {
          //   const response = await AxiosInstance.post("/api/degree",{degreeType:initialfields.degreeType,degree_name:initialfields.degree_name,department:initialfields.department});
          //   console.log(response.data);
          //   if(response.status="true")
          //   {
          //     save();
          //     window.location.href = '/ViewDegree';
          //   }
          // } catch (error) {
          //   console.log(error);
          // }

          save();
          navigate("/ViewDegree");
        } else {
          // If no id is provided, add the new data to the end of the array
          parsedData.push(initialfields);
          // try {
          //   const response = await AxiosInstance.post("/api/degree",{degreeType:initialfields.degreeType,degree_name:initialfields.degree_name,department:initialfields.department});
          //   console.log(response.data);
          //   if(response.status="true")
          //   {
          //     save();
          //     window.location.href = '/ViewDegree';
          //   }
          // } catch (error) {
          //   console.log(error);
          // }

          save();
          navigate("/ViewDegree");
        }

        sessionStorage.setItem("degreedata", JSON.stringify(parsedData));
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <div>
            {id ? (
              <h4>Edit Test Course Details</h4>
            ) : (
              <h4>Create Test Course Details</h4>
            )}
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="degreeType">Degree Type:</label>
                <select
                  class="form-control"
                  id="degreeType"
                  name="degreeType"
                  onChange={handleChange}
                  value={initialfields.degreeType}
                >
                  <option value="">--- Select Degree Type ---</option>
                  <option value="diploma">Diploma</option>
                  <option value="UG">UG</option>
                  <option value="PG">PG</option>
                  <option value="Integrated Courses">Integrated Courses</option>
                  <option value="Professional Courses">
                    Professional Courses
                  </option>
                </select>
              </div>
              <Error>
                {error.degreeType && (
                  <small className="error">{error.degreeType}</small>
                )}
              </Error>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="degree_name">Degree Name:</label>
                <input
                  type="text"
                  class="form-control"
                  id="degree_name"
                  name="degree_name"
                  placeholder="Enter Degree name"
                  value={initialfields.degree_name}
                  onChange={handleChange}
                />
              </div>
              <Error>
                {error.degree_name && (
                  <small className="error">{error.degree_name}</small>
                )}
              </Error>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="department">Department:</label>
                <input
                  type="text"
                  class="form-control"
                  id="department"
                  name="department"
                  placeholder="Enter Department name"
                  value={initialfields.department}
                  onChange={handleChange}
                />
              </div>
              <Error>
                {error.department && (
                  <small className="error">{error.department}</small>
                )}
              </Error>
            </div>

            <div class="col-sm-4 mt-3">
              <button type="submit" class="btn btn-primary col-sm-4 ">
                Save
              </button>
              <button type="button" class="btn btn-secondary col-sm-4 ms-5">
                <Link to="/ViewDegree" className="course_cancel">
                  Cancel
                </Link>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
