import { Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../utils/helper";

const Header = styled.span`
  font-size: 14px;
  text-transform: capitalize;
  color: var(--color--text-secondary);
`;
const Body = styled.span`
  font-size: 16px;
  color: var(--color-text-primary);
  /* text-transform: capitalize; */
  font-weight: 700;
  // capitalize style applies when only normal text is present
  ${(props) => props.variant !== "normal" && "text-transform: capitalize"}
`;
Body.defaultProps = {
  variant: "abnormal",
};

export default function FloatedContainerText({
  title = "",
  text = "",
  normalText = "",
}) {
  return (
    <>
      <Stack>
        <Header>{title}</Header>
        {/* {text ? <Body> {text}</Body> : capitalizeFirstLetter(normalText)} */}
        <Body variant={normalText ? "normal" : "abnormal"}>
          {text ? text : capitalizeFirstLetter(normalText)}
        </Body>
      </Stack>
    </>
  );
}
