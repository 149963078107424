import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import ICON_ADD from "@mui/icons-material/AddCircle";
import ICON_REMOVE from "@mui/icons-material/RemoveCircle";

import CustomContainer from "../../../../ui/CustomContainer";
import StyledTitle from "../../../../ui/StyledTitle";
import ButtonContainer from "../../../../ui/ButtonContainer";
import SecondaryButton from "../../../../ui/SecondaryButton";
import PrimaryButton from "../../../../ui/PrimaryButton";
import FloatedTitleContainer from "../../../../ui/FloatedTitleContainer";
import TextInput from "../../../../ui/inputs/TextInput";
import SelectInput from "../../../../ui/inputs/SelectInput";
import { MEDIA_SIZE_MD } from "../../../../utils/helper";
import toast from "react-hot-toast";
import {
  apiGetAllSupervisors,
  apiUpdateModelExamCentre,
} from "../../../../services/services";
import SkeletonLoaderPage from "../../../../ui/SkeletonLoaderPage";
import { object } from "yup";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ModalDialogContent from "../../../../ui/ModalDialogContent";
import ModalDialogActions from "../../../../ui/ModalDialogActions";
import NavigationButton from "../../../../ui/NavigationButton";
import Select, { components, useStateManager } from "react-select";
import { customStyles } from "../../../../pages/customStyles";
const StyledInput = styled.input`
  border: 1px solid var(--color-text-primary);
  border-radius: var(--border-radius-sm);
  font-size: 14px;
  padding: 10px;
  margin: 0px;
  width: 100%;
  outline: none;
  &:hover {
    border: 1px solid var(--color-blue-600);
  }
`;
const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-primary);
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
`;
const InnerContainer = styled.div`
  padding: 30px 20px;
`;
const MarginRightDiv = styled.div`
  margin-bottom: 20px;
  @media (min-width: ${MEDIA_SIZE_MD}) {
    margin-left: 10px;
  }
`;
const MarginLeftDiv = styled.div`
  margin-bottom: 20px;
  @media (min-width: ${MEDIA_SIZE_MD}) {
    margin-right: 10px;
  }
`;
const StyledInputContainer = styled.div`
  margin-bottom: 20px;
`;
const StyledAddDeleteBtn = styled.button`
  border: none;
  background: none;
  outline: none;
  text-align: center;
  /* padding: 10px; */
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  /* margin: 0px 5px; */
  color: var(--color-brand-800);
  letter-spacing: 0.2px;
  border: 1px solid var(--color-white);
  &:active {
    border: 1px solid var(--color-brand-800);
    border-radius: var(--border-radius-sm);
  }
`;
const StyledPara = styled.p`
  color: var(--color-text-primary);
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
`;
const HighLight = styled.span`
  color: var(--color-brand-900);
`;

const RULE_NAME_CENTRE = {
  required: "Centre name  is required",
  minLength: { value: 3, message: "Minimum 3 characters required" },
};
const RULE_COUNT = {
  required: "Count  is required",
  min: { value: 0, message: "count should be equal to or above zero" },
  max: {
    value: 10000,
    message: "count should be lesser than or equal to 10,000",
  },
  pattern: {
    value: /^[0-9][0-9]*$/,
    message: "Enter numbers only",
  },
  //  ^[1-9]d*$
};

const ICON_SIZE = 10;

export default function ExamCentreByDistrict() {
  // ##### VARIABLES
  const tempLocationState = useLocation();
  const USER_ID = JSON.parse(sessionStorage.getItem("user_id"));
  const navigate = useNavigate();
  // console.log(tempLocationState.state, "tempLocationState");
  const locationState = tempLocationState.state.districtData.map((dist) => {
    const tempCentreDetails = dist?.centreDetails.map((centre) => {
      return {
        pk: centre.pk > 0 ? centre.pk : 0,
        name: centre.name,
        url: centre.location_url,
        count: centre.max_count,
        supervisor: centre.staff_id,
      };
    });
    return {
      districtID: dist.id,
      districtName: dist.name,
      districtCode: dist.code,
      centreDetails: tempCentreDetails,
    };
  });
  const BATCH_ID = tempLocationState.state.batchID;
  const EXAM_DETAIL_ID = tempLocationState.state.examDetailLID;
  const EXAM_DETAIL_IDS = tempLocationState.state.examDetailIDS;
  const EXAM_DETAIL_NAME = tempLocationState.state.examDetailTile;
  // console.log(EXAM_DETAIL_ID, "EXAM_DETAIL_ID", EXAM_DETAIL_IDS);

  const [examCentreData, setExamCentreData] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const [ispageLoading, setIsPageLoading] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDistricIndex, setSelectedDistricIndex] = useState(0);
  const [selectedCentreIndex, setSelectedCentreIndex] = useState(0);
  const form = useForm({
    defaultValues: { data: examCentreData },
  });
  const { handleSubmit, control, setValue, getValues, reset } = form;
  const reactHookFormState = getValues();
  const RULE_URL = {
    required: "Centre URL  is required",
    pattern: {
      value: new RegExp(
        "^([a-zA-Z]+:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$", // fragment locator
        "i"
      ),
      message: "Enter valid URL",
    },
    validate: (fieldValue) => {
      let stateUrlArray = [];
      const deleteDistrictData = reactHookFormState.data.map(
        (district, index, arr) => {
          const tempDeleteDistrictData = district.centreDetails.map(
            (centre, indexC, arrC) => {
              const centreValues = Object.values(centre);
              stateUrlArray.push(centreValues[3]);
              return centre;
            }
          );
          return district;
        }
      );
      const isSame = stateUrlArray.filter((id) => fieldValue === id);
      if (isSame.length > 1) {
        return "URl already Entered";
      }
    },
  };
  const RULE_SUPERVISOR = {
    required: "Please select centre supervisor",
    validate: (fieldValue) => {
      let stateSupervisorArray = [];
      const deleteDistrictData = reactHookFormState.data.map(
        (district, index, arr) => {
          const tempDeleteDistrictData = district.centreDetails.map(
            (centre, indexC, arrC) => {
              const centreValues = Object.values(centre);
              stateSupervisorArray.push(centreValues[4]);
              return centre;
            }
          );
          return district;
        }
      );
      const isSame = stateSupervisorArray.filter((id) => +fieldValue === +id);
      if (isSame.length > 1) {
        return "Supervisor already allocated";
      }
    },
  };
  async function deleteFunction(distictIndex, centreIndex) {
    const centreDetails = Object.values(
      reactHookFormState.data[distictIndex].centreDetails[centreIndex]
    );
    const isHavePK = centreDetails[0] > 0;
    if (isHavePK) {
      // setIsOpen(false);
      return toast.error(
        "Exam centre cannot be deleted,Please contact IT admin"
      );
    } else if (
      centreDetails[1] ||
      centreDetails[2] ||
      centreDetails[3] ||
      centreDetails[4]
    ) {
      setSelectedDistricIndex(distictIndex);
      setSelectedCentreIndex(centreIndex);
      setIsOpen(true);
    } else {
      handleDeleteCentre(distictIndex, centreIndex);
    }
  }

  const handleAddCentre = (addedDistrictIndex, addedCentreIndex) => {
    // if (addedCentreIndex > 8) {
    //   return toast.error("Maximum of 10 centres reached.");
    // }
    let tempCentreDetails = [];
    const addedExamCentreData = reactHookFormState.data.map(
      (district, index, arr) => {
        // const districtIndex = index + 1;
        const districtIndex = index;
        if (index === addedDistrictIndex) {
          const tempCentrelength = district.centreDetails.length;
          // const centrelength = tempCentrelength + 1;
          const centrelength = tempCentrelength;
          tempCentreDetails = [
            ...district.centreDetails,
            {
              pk: 0,
              [`centreName${districtIndex}${centrelength}`]: "",
              [`centreCount${districtIndex}${centrelength}`]: "",
              [`centreURL${districtIndex}${centrelength}`]: "",
              [`centreSupervisor${districtIndex}${centrelength}`]: null,
            },
          ];
          return { ...district, centreDetails: tempCentreDetails };
        } else {
          return district;
        }
      }
    );
    setValue(`data.${addedDistrictIndex}.centreDetails`, tempCentreDetails);
    setExamCentreData(addedExamCentreData);
  };

  const handleDeleteCentre = (deletedDistrictIndex, deletedCentreIndex) => {
    let tempCentreDetails = [];
    const deletedExamCentreData = reactHookFormState.data.map(
      (district, index, arr) => {
        // #1.PREVENT WHEN TRIES TO DELETE UPDATED EXAM CENTRE
        if (index === deletedDistrictIndex) {
          // 1.create centredetails array without deleted array
          const deletedCentreDetails = district.centreDetails.flatMap(
            (centre, indexC, arrC) => {
              if (indexC === deletedCentreIndex) {
                return [];
              } else {
                return centre;
              }
            }
          );
          // 2.create valida names using index
          tempCentreDetails = deletedCentreDetails.map(
            (centre, indexC, arrC) => {
              const centreValuesArray = Object.values(centre);
              return {
                pk: centreValuesArray[0] > 0 ? centreValuesArray[0] : 0,
                [`centreName${index}${indexC}`]: centreValuesArray[1],
                [`centreCount${index}${indexC}`]: centreValuesArray[2],
                [`centreURL${index}${indexC}`]: centreValuesArray[3],
                [`centreSupervisor${index}${indexC}`]: centreValuesArray[4],
              };
            }
          );
          return { ...district, centreDetails: tempCentreDetails };
        } else {
          return district;
        }
      }
    );
    setIsOpen(false);
    setValue(`data.${deletedDistrictIndex}.centreDetails`, tempCentreDetails);
    setExamCentreData(deletedExamCentreData);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const onSubmitData = (data) => {
    let tempFormData = [];
    const deleteDistrictData = data.data.map((dataItem, index, arr) => {
      const districtValues = Object.values(dataItem);
      const tempCentreDetails = dataItem.centreDetails.map(
        (centre, indexC, arrC) => {
          const centreValues = Object.values(centre);
          const centreData = {
            district_id: districtValues[0],
            exam_district_code: districtValues[2],
            exam_center_district: districtValues[1],
            exam_center_name: centreValues[1],
            max_count: parseInt(centreValues[2]),
            location_url: centreValues[3],
            center_staff_user_id: parseInt(centreValues[4]),
            id: centreValues[0],
            exam_center_id: indexC + 1,
            exam_detail_id: EXAM_DETAIL_ID,
            exam_details_id: EXAM_DETAIL_IDS,
          };
          // console.log(centreData, "centreData");
          // return;
          tempFormData.push(centreData);
        }
      );
    });
    const formData = {
      batch_id: BATCH_ID,
      updated_by: USER_ID,
      exam_center: tempFormData,
    };
    // console.log("formData", formData);
    setIsApiLoading(true);
    apiUpdateModelExamCentre(formData)
      .then((res) => {
        if (res.status) {
          setIsApiLoading(false);
          navigate(`/DetailViewBatch/${BATCH_ID}/4`);
          toast.success("Exam Centre Updated Successfully");
        } else {
          setIsApiLoading(false);
          toast.warning("Something went wrong! Please try again");
        }
      })
      .catch((err) => {
        console.error(err);
        setIsApiLoading(false);
      });
  };
  // ##### EFFECTS
  useEffect(() => {
    // debugger;
    // setIsPageLoading(false);
    const generatedDistrictData = locationState.map((dist, index) => {
      // const tempIndex = index + 1;
      const tempIndex = index;
      const tempCentredetails = dist.centreDetails.map(
        (centre, indexCentre) => {
          // const tempCentreIndex = indexCentre + 1;
          const tempCentreIndex = indexCentre;
          return {
            pk: centre.pk,
            // exam_centre_id: tempCentreIndex,
            ["centreName" + tempIndex + tempCentreIndex]: centre.name,
            ["centreCount" + tempIndex + tempCentreIndex]: centre.count,
            ["centreURL" + tempIndex + tempCentreIndex]: centre.url,
            ["centreSupervisor" + tempIndex + tempCentreIndex]:
              centre.supervisor,
          };
        }
      );
      return {
        ["districtID" + tempIndex]: dist.districtID,
        ["districtName" + tempIndex]: dist.districtName,
        ["districtCode" + tempIndex]: dist.districtCode,
        centreDetails: tempCentredetails,
      };
    });
    apiGetAllSupervisors()
      .then((res) => {
        if (res.status) {
          setSupervisorList(res.data);
          setIsPageLoading(false);
          setExamCentreData(generatedDistrictData);
          setValue("data", generatedDistrictData);
        } else {
          setSupervisorList([]);
          setIsPageLoading(false);
          setExamCentreData(generatedDistrictData);
          setValue("data", generatedDistrictData);
        }
      })
      .catch((err) => {
        console.error("all-supervisors list", err);
        setIsPageLoading(false);
      });
    const data = [
      {
        districtName1: "Chennai",
        districtCode: "1010",
        centreDetails: [
          {
            centreName11: "name1",
            centreCount11: 1,
            centreURL11: "url1",
            centreSupervisor11: 1,
          },
          {
            centreName12: "name2",
            centreCount12: 2,
            centreURL12: "url2",
            centreSupervisor12: 2,
          },
        ],
      },
    ];
    // setExamCentreData(generatedDistrictData);
    // setValue("data", generatedDistrictData);
  }, []);

  if (ispageLoading) {
    return <SkeletonLoaderPage />;
  }

  const groupedSupervisors = supervisorList.reduce((acc, supervisor) => {
    const type = supervisor.staff_type;
    if (!acc[type]) acc[type] = [];
    acc[type].push(supervisor);
    return acc;
  }, {});

  return (
    <CustomContainer>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <section
          id="title"
          className="row justify-content-center align-items-center p-0"
          // style={{ margin: "10px 0" }}
        >
          <div className="col-12 col-lg-4">
            <StyledTitle>{EXAM_DETAIL_NAME} - Exam Centres </StyledTitle>
          </div>

          <div className="col-12 col-lg-8">
            <ButtonContainer>
              <SecondaryButton onClick={handleBack}>
                Back to View Model Exam Batch
              </SecondaryButton>
              <PrimaryButton type="submit" disabled={isApiLoading}>
                {" "}
                {isApiLoading ? "Loading" : "Save Exam Centres"}
              </PrimaryButton>
            </ButtonContainer>
          </div>
        </section>
        {examCentreData.map((district, index, arr) => {
          // const districtIndex = index + 1;
          const districtIndex = index;

          return (
            <section key={district.districtID}>
              <FloatedTitleContainer
                title={`Exam District ${districtIndex + 1}`}
                className="mt-4"
              >
                <InnerContainer>
                  <div className="row p-0 m-0">
                    <div className="col-12 col-md-6 p-0 m-0">
                      <MarginLeftDiv>
                        <StyledLabel>District</StyledLabel>
                        <StyledInput
                          // value="Chennai"
                          value={district[`districtName${districtIndex}`]}
                          disabled
                          style={{
                            border: "1px solid #868e96",
                            color: "#868e96",
                          }}
                        />
                      </MarginLeftDiv>
                    </div>
                    <div className="col-12 col-md-6 p-0 m-0">
                      <MarginRightDiv>
                        <StyledLabel>District Code</StyledLabel>
                        <StyledInput
                          // value="1010"
                          value={district[`districtCode${districtIndex}`]}
                          disabled
                          style={{
                            border: "1px solid #868e96",
                            color: "#868e96",
                          }}
                        />
                      </MarginRightDiv>
                    </div>
                    <div className="col-12 col-md-6 p-0 m-0">
                      <MarginLeftDiv>
                        <StyledLabel>Number of Exam Centres</StyledLabel>
                        <StyledInput
                          // value="Chennai"
                          value={district.centreDetails.length}
                          disabled
                          style={{
                            border: "1px solid #868e96",
                            color: "#868e96",
                          }}
                        />
                      </MarginLeftDiv>
                    </div>
                  </div>
                </InnerContainer>
              </FloatedTitleContainer>
              {district.centreDetails.map((centre, indexC, arrC) => {
                // const indexCentre = indexC + 1;
                const indexCentre = indexC;
                return (
                  <FloatedTitleContainer
                    title={`Exam District ${districtIndex + 1} - Exam Centre ${
                      indexCentre + 1
                    }`}
                    className="mt-4"
                  >
                    <InnerContainer>
                      <div className="row p-0 m-0">
                        <div className="col-12 col-md-12 p-0 m-0">
                          <StyledInputContainer>
                            <TextInput
                              label="Name and Address of the Exam Centre"
                              name={`data.${index}.centreDetails.${indexC}.centreName${districtIndex}${indexCentre}`} //${districtIndex}${indexCentre}
                              // name="data.0.districtName"
                              readOnly={false}
                              placeholder="Name of the Exam Centre"
                              control={control}
                              rules={RULE_NAME_CENTRE}
                            />
                          </StyledInputContainer>

                          {/* <MarginRightDiv></MarginRightDiv> */}
                        </div>
                        <div className="col-12 col-md-12 p-0 m-0">
                          <StyledInputContainer>
                            <TextInput
                              label="Location URL"
                              name={`data.${index}.centreDetails.${indexC}.centreURL${districtIndex}${indexCentre}`}
                              readOnly={false}
                              placeholder="Location URL"
                              control={control}
                              rules={RULE_URL}
                            />
                          </StyledInputContainer>

                          {/* <MarginRightDiv></MarginRightDiv> */}
                        </div>
                        <div className="col-12 col-md-6 p-0 m-0">
                          <MarginLeftDiv>
                            <TextInput
                              label="Max Count"
                              name={`data.${index}.centreDetails.${indexC}.centreCount${districtIndex}${indexCentre}`}
                              readOnly={false}
                              placeholder="Maximum Count"
                              control={control}
                              rules={RULE_COUNT}
                            />
                          </MarginLeftDiv>
                        </div>

                        <div className="col-12 col-md-6 p-0 m-0">
                          <MarginRightDiv>
                            <SelectInput
                              label="Centre Supervisor"
                              name={`data.${index}.centreDetails.${indexC}.centreSupervisor${districtIndex}${indexCentre}`}
                              readOnly={false}
                              placeholder="Centre Supervisor"
                              control={control}
                              rules={RULE_SUPERVISOR}
                            >
                              {Object.keys(groupedSupervisors).map((type) => (
                                <optgroup
                                  key={type}
                                  label={
                                    type === "0"
                                      ? "Faculty"
                                      : type === "1"
                                      ? "Staff"
                                      : "Panel Member"
                                  }
                                >
                                  {groupedSupervisors[type].map((sup) => (
                                    <option value={sup.id} key={sup.id}>
                                      {sup.full_name}
                                    </option>
                                  ))}
                                </optgroup>
                              ))}
                            </SelectInput>
                          </MarginRightDiv>
                        </div>
                        <div
                          className="col-12 p-0"
                          style={{ textAlign: "end" }}
                        >
                          {arrC.length - 1 === indexC && (
                            <StyledAddDeleteBtn
                              onClick={() => handleAddCentre(index, indexC)}
                              type="button"
                            >
                              <ICON_ADD fontSize={`${ICON_SIZE}px`} /> Add More
                              Exam Centre
                            </StyledAddDeleteBtn>
                          )}
                          {arrC.length > 1 && (
                            <StyledAddDeleteBtn
                              // onClick={() => handleDeleteCentre(index, indexC)}
                              onClick={() => deleteFunction(index, indexC)}
                              type="button"
                            >
                              <ICON_REMOVE fontSize={`${ICON_SIZE}px`} /> Delete
                              This Exam Centre
                            </StyledAddDeleteBtn>
                          )}
                        </div>
                      </div>
                    </InnerContainer>
                  </FloatedTitleContainer>
                );
              })}
            </section>
          );
        })}
        <div
          className="d-flex justify-content-end m-0 my-4 p-0"
          style={{ flexWrap: "wrap" }}
        >
          <SecondaryButton onClick={handleBack}>
            Back to View Model Exam Batch
          </SecondaryButton>
          <PrimaryButton type="submit" disabled={isApiLoading}>
            {isApiLoading ? "Loading" : "Save Exam Centres"}
          </PrimaryButton>
        </div>
        {/* <DevTool control={control} /> */}
      </form>
      {/* DIALOGUE FOR AFTER TAKING TEST */}
      <Dialog open={isOpen} aria-describedby="alert-dialog-can-delete">
        <DialogContent style={{ padding: "20px 40px" }}>
          <StyledPara>
            Do u want to delete Exam centre data,this action can't be reversible
          </StyledPara>
          <div
            style={{
              marginTop: "40px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SecondaryButton
              style={{ border: "1px solid red" }}
              onClick={() => setIsOpen(false)}
            >
              No
            </SecondaryButton>
            <PrimaryButton
              onClick={() =>
                handleDeleteCentre(selectedDistricIndex, selectedCentreIndex)
              }
            >
              Yes
            </PrimaryButton>
          </div>
        </DialogContent>
      </Dialog>
    </CustomContainer>
  );
}
