import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdmissionPayments from "./AdmissionPayments";
import ExamRecords from "./ExamRecords";

function GenarateReportListView() {
  const { batch_id, active_tab } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    // Update the activeTab state when the component mounts and whenever active_tab changes in the URL
    setActiveTab(Number(active_tab) || 1);
    // setActiveTab(1);
  }, [active_tab]);

  const handleTabClick = (index) => {
    setActiveTab(index);
    navigate(`/GenerateReport/${batch_id}/${index}`);
  };

  const handleBackModel = () => {
    navigate(`/DetailViewBatch/${batch_id}/4`);
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  return (
    <div className="BathDet ">
      <div className="row">
        <div className="col-lg-12 col-md-5 col-12 col-sm-6 d-flex justify-content-between">
          <h6 className="Title">Generate Report</h6>
          <div className="d-flex mt-2 smButton mobiledown">
            <Link
              to={"javascript:void(0)"}
              onClick={() => handleBackModel()}
              className="CancelButton"
            >
              Back to View Model Exam Batch
            </Link>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <ul className="nav  nav-fill mb-3" id="listViewTabs" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 1 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-2"
                aria-selected={activeTab === 1}
                onClick={() => handleTabClick(1)}
              >
                Admission Payments
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 2 ? "nav-link active " : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex3-tabs-3"
                aria-selected={activeTab === 2}
                onClick={() => handleTabClick(2)}
              >
                Exam Records
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* // tab content   */}
      <AdmissionPayments batch_id={batch_id} activeTab={activeTab} />
      <ExamRecords batch_id={batch_id} activeTab={activeTab} />
    </div>
  );
}

export default GenarateReportListView;
