import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { UserContext } from "../context/UserDetails";

function RequireAuth({ children, allowedRoles }) {
  let { user, logout } = useContext(UserContext);

  let location = useLocation();
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (
    user &&
    typeof user === "object" &&
    !Array.isArray(user) &&
    Object.keys(user).length > 0 &&
    !allowedRoles.includes(user.user_type)
  ) {
    return logout();
  } else if (
    Array.isArray(user) &&
    user.length > 0 &&
    !allowedRoles.includes(user.user_type)
  ) {
    // Handle case where user is an empty array
    return logout();
  }

  return children;
}
export default RequireAuth;
