import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import StyledScrollableContainer from "../../../../../ui/StyledScrollableContainer";
import styled from "styled-components";
import { MEDIA_SIZE_LG } from "../../../../../utils/helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: false,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
      text: "perfomance analysis - student vs toppper",
    },
    legend: {
      display: false,
      position: "bottom",
      labels: {
        // padding: 40,
        padding: 30,
        border: 0,
      },
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        maxRotation: 45,
        minRotation: 45,
      },
    },
    y: {
      position: "left",
      title: {
        display: true,
        text: "Test Score in Percentage",
        padding: 10,
      },
    },
    // y: {
    //   type: "linear",
    //   display: true,
    //   position: "left",
    //   title: {
    //     display: true,
    //     text: "Test Score in Percentage",
    //     padding: 10,
    //   },
    // },
    // y1: {
    //   type: "linear",
    //   display: false,
    //   position: "right",
    //   grid: {
    //     drawOnChartArea: false,
    //   },
    // },
  },
};

const StyledLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
`;
const StyledLabelBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 5px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    width: 30px;
    margin-right: 10px;
  }
`;

const StyledLabel = styled.span`
  font-size: 12px;
  /* color: #ccc; */
  @media (min-width: ${MEDIA_SIZE_LG}) {
    margin-right: 30px;
  }
`;
export default function LineChart({
  labels = [],
  studentScore = [],
  toppperScore = [],
}) {
  const data = {
    labels,
    datasets: [
      {
        label: "You",
        // data: [100, 200, 300, 400, 500, 600, 700],
        data: studentScore,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        // yAxisID: "y",
      },
      {
        label: "Topper",
        // data: [500, 600, 700, 800, 900, 100, 200],
        data: toppperScore,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        // yAxisID: "y1",
      },
    ],
  };
  const staticData = [
    { label: "You", backgroundColor: "rgb(255, 99, 132)" },
    { label: "Toppper", backgroundColor: "rgb(53, 162, 235)" },
  ];
  const legendItems = staticData.map((label, index) => {
    return (
      <div
        key={index}
        style={{ display: "flex", alignItems: "center", margin: "5px" }}
      >
        <StyledLabelBox
          style={{
            backgroundColor: label.backgroundColor,
          }}
        ></StyledLabelBox>
        <StyledLabel>{label.label}</StyledLabel>
      </div>
    );
  });
  return (
    <>
      <StyledScrollableContainer>
        <Line options={options} data={data} width={1000} height={400} />
      </StyledScrollableContainer>
      <StyledLegendContainer>{legendItems}</StyledLegendContainer>
    </>
  );
}
