import React from 'react'
import { components } from "react-select";
import { default as ReactSelect } from "react-select";

export default function ReadViewSubject(props) {
// console.log(props.record,"props ", props.record.id);
  return (
    <div className="row box" id={"cate_" + props.record.id}>
      <div className="col-md-12">
        <p className="StepTitle">
          Subject in Category {props.record.id > 1 ? "-" : ""}{" "}
          {props.record.id > 1 ? props.record.id : ""}
        </p>
        <div className="row mt-2 mb-2" >
          <div className="col-md-6">
            <div className="forms ">
              <label for={`category`}>Category</label>
              <span className="ReviewLabel mt-2">
                    {props.record.category}
                  </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="forms " id="subjectdata">
              <label htmlFor={`subject`}>Subject</label>
             {props.record.optionSelected.length > 0 && (

<span
class="ReviewLabel mt-2"
>
{props.record.optionSelected.map((option, index) => (
<>
<small>{option.value}</small>
{index !== props.record.optionSelected.length - 1 && ', '}
</>
))}
</span>


             )}
            
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
