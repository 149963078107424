import React from "react";
import styled from "styled-components";
import { MEDIA_SIZE_FLIP, MEDIA_SIZE_LG } from "../../../../../utils/helper";

const TestDetailsBox = styled.div`
  border: none;
  padding: 14px 7px;
  margin: 7px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #ff6384 0%, #803242 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${MEDIA_SIZE_FLIP}) {
    min-height: 140px;
  }
  @media (min-width: ${MEDIA_SIZE_LG}) {
    min-height: 185px;
  }
`;

const TestDeatilsScore = styled.p`
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 36px;
  }
`;

const TestDetailsLabel = styled.p`
  font-size: 16px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 24px;
  }
`;

export default function TestDetailsNumbers({ label, value, bgcolor }) {
  return (
    <TestDetailsBox
      style={{
        background: bgcolor,
      }}
    >
      <div>
        <TestDeatilsScore>{value}</TestDeatilsScore>
        <TestDetailsLabel>{label}</TestDetailsLabel>
      </div>
    </TestDetailsBox>
  );
}
