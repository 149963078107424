import { useEffect, useRef, useState } from "react";
import { ImageUrlPrefix } from "../labels";
import { AxiosInstance } from "../axios";
import { customStyles } from "./customStyles";
import Select, { components } from "react-select";
import MonthDatePicker from "../shared/Component/MonthDatePicker";

export const TechQualification = (props) => {
  const [classOption, setClassOption] = useState([]);
  const [thqOption, setThqOption] = useState([]);
  const [thqOptionCoa, setThqOptionCoa] = useState([]);
  const txtadTech = useRef(null);

  useEffect(() => {
    AxiosInstance.get("api/codevalues?codetype=CLASS_THQ")
      .then((res) => {
        const classOptions = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setClassOption(classOptions);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=THQ_K")
      .then((res) => {
        const classOption_thqsC = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setThqOption(classOption_thqsC);
      })
      .catch((error) => {
        return error;
      });

    AxiosInstance.get("api/codevalues?codetype=THQ_COA")
      .then((res) => {
        const classOption_thqsC = res.data.map((item) => (
          <option key={item.name} value={item.name}>
            {item.name}
          </option>
        ));
        setThqOptionCoa(classOption_thqsC);
      })
      .catch((error) => {
        return error;
      });
  }, []);
  // console.log("laksh", props.record.name);
  return (
    <div className="row box">
      <div className="col-md-12">
        <p className="StepTitle">
          Technical Qualification {props.record.id > 1 ? "-" : ""}{" "}
          {props.record.id > 1 ? props.record.id : ""}{" "}
        </p>
        <div className="row">
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor={`name`}>Technical Course</label>
              <Select
                id={`name`}
                name={`name`}
                isDisabled={props.admission_status === 1 ? true : false}
                value={
                  props.record.name !== null && props.record.name !== ""
                    ? { value: props.record.name, label: props.record.name }
                    : null
                }
                onChange={(option) =>
                  props.TechhandleChanges(option, props.record.id)
                }
                options={props.technicalOption}
                // options={
                //   props.technicalOption
                //     .filter((item) => item && item.label) // Filter out null and undefined elements
                //     .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                // }
                isSearchable={true} // Enables search functionality
                placeholder="Select an option"
                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                filterOption={(option, searchText) =>
                  option.label.toLowerCase().includes(searchText.toLowerCase())
                } // Filter options based on the search text
                styles={customStyles} // Apply custom styles her
              />
              {props.record.error_name && (
                <small className="errors ">{props.record.error_name}</small>
              )}
            </div>
          </div>
          {/* {props.record.name !== "Nil" && ( */}
          {props.record.name !== "Nil" && (
            <div className="col-md-6">
              <div className="forms ">
                <label htmlFor={`level`}>
                  {/* AIASA-000091 - v1 issues solved by lalitha */}
                  {props.record.name === "Steno"
                    ? "Stenographer Level"
                    : props.record.name === "COA"
                    ? "Computer Office Automation"
                    : props.record.name === "Knowledge in computer"
                    ? "Course In Computer"
                    : "Typewriting Level"}
                </label>
                {/* pending_with_aiasa data pending  */}
                {props.record.name === "COA" &&
                  props.record.name !== "Others" && (
                    <select
                      id={`level`}
                      name={`level`}
                      disabled={props.admission_status === 1 ? true : false}
                      className="Inputs"
                      value={props.record.level}
                      onChange={(e) =>
                        props.TechhandleChange(e, props.record.id)
                      }
                      required
                    >
                      <option value="" disabled>
                        Please select
                      </option>
                      {thqOptionCoa}
                    </select>
                  )}

                {props.record.name === "Knowledge in computer" &&
                  props.record.name !== "Others" && (
                    <select
                      id={`level`}
                      name={`level`}
                      disabled={props.admission_status === 1 ? true : false}
                      className="Inputs"
                      value={props.record.level}
                      onChange={(e) =>
                        props.TechhandleChange(e, props.record.id)
                      }
                      required
                    >
                      <option value="" selected>
                        Please select
                      </option>
                      {thqOption}
                    </select>
                  )}

                {props.record.name !== "COA" &&
                  props.record.name !== "Others" &&
                  props.record.name !== "Knowledge in computer" && (
                    <select
                      id={`level`}
                      name={`level`}
                      disabled={props.admission_status === 1 ? true : false}
                      className="Inputs"
                      value={props.record.level}
                      onChange={(e) =>
                        props.TechhandleChange(e, props.record.id)
                      }
                      required
                    >
                      <option value="" disabled>
                        Please select
                      </option>
                      <option value="Junior">Junior</option>
                      <option value="Senior">Senior</option>
                      <option value="HighSpeed">High Speed</option>
                    </select>
                  )}
                {props.record.name === "Others" && (
                  <input
                    id={`level`}
                    type="text"
                    name={`level`}
                    value={props.record.level}
                    onChange={(e) => props.TechhandleChange(e, props.record.id)}
                    className="Inputs"
                    placeholder="Typewriting level"
                    autocomplete="Typewriting level"
                    required
                    pattern="^[A-Fa-f0-9]+$"
                  />
                )}
                {props.record.error_level && (
                  <small className="errors ">{props.record.error_level}</small>
                )}
              </div>
            </div>
          )}
        </div>
        {props.record.name !== "Nil" && (
          <div className="row">
            <div className="col-md-6">
              <div className="forms ">
                <label htmlFor={`lang`}>Language</label>
                {props.record.name === "Others" ? (
                  <input
                    id={`lang`}
                    type="text"
                    name={`lang`}
                    value={props.record.lang}
                    onChange={(e) => props.TechhandleChange(e, props.record.id)}
                    className="Inputs"
                    placeholder="Language"
                    autocomplete="Language"
                    required
                    pattern="^[A-Fa-f0-9]+$"
                  />
                ) : (
                  <select
                    id={`lang`}
                    disabled={props.admission_status === 1 ? true : false}
                    name={`lang`}
                    className="Inputs"
                    value={props.record.lang}
                    onChange={(e) => props.TechhandleChange(e, props.record.id)}
                    required
                  >
                    <option value="" disabled selected>
                      Please select
                    </option>
                    <option value="English">English</option>
                    <option value="Tamil">Tamil</option>
                    <option value="Hindi">Hindi</option>
                    {/* <option value="malayalam">malayalam</option> */}
                  </select>
                )}

                {props.record.error_edu_medium && (
                  <small className="errors ">
                    {props.record.error_edu_medium}
                  </small>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="forms ">
                <label htmlFor={`Cnum`}>Certificate Number</label>
                <input
                  id="Cnum"
                  //  maxLength={10}
                  //  minLength={10}
                  type="text"
                  disabled={props.admission_status === 1 ? true : false}
                  name="Cnum"
                  className="Inputs"
                  onChange={(e) => props.TechhandleChange(e, props.record.id)}
                  placeholder="Certificate Number"
                  value={props.record.Cnum}
                  autocomplete="Certificate Number"
                  required
                />
                {props.record.error_certificate_no && (
                  <small className="errors ">
                    {props.record.error_certificate_no}
                  </small>
                )}
              </div>
            </div>
          </div>
        )}

        {props.record.name !== "Nil" && (
          <div className="row">
            <div className="col-md-6">
              <div className="forms ">
                <label htmlFor={`grade`}>Grade</label>

                {props.record.name === "Others" ? (
                  <input
                    id={`grade`}
                    type="text"
                    name={`grade`}
                    value={props.record.grade}
                    onChange={(e) => props.TechhandleChange(e, props.record.id)}
                    className="Inputs"
                    placeholder="Grade"
                    autocomplete="grade"
                    required
                    pattern="^[A-Fa-f0-9]+$"
                  />
                ) : (
                  <select
                    id={`grade`}
                    name={`grade`}
                    className="Inputs"
                    disabled={props.admission_status === 1 ? true : false}
                    value={props.record.grade}
                    onChange={(e) => props.TechhandleChange(e, props.record.id)}
                    required
                  >
                    <option value="" disabled selected>
                      Please select
                    </option>
                    {classOption}
                  </select>
                )}
                {props.record.error_grade && (
                  <small className="errors ">{props.record.error_grade}</small>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="forms ">
                <label htmlFor={`year`}>Year of Passing</label>
                {/* <input
       id={`year`}
       type="month"
       disabled ={props.admission_status === 1 ? true : false}
       name={`year`}
       className="Inputs"
       value={props.record.year} onChange={e => props.TechhandleChange(e, props.record.id)}
       placeholder="Year of Passing"
       autocomplete="year"
       required
       pattern="^[0-9]{4}$"
     /> */}
                <MonthDatePicker
                  disabledProperty={props.admission_status === 1 ? true : false}
                  name={"year"}
                  txtAdSpass={txtadTech}
                  handleIdChange={props.TechhandleChange_date}
                  fieldsId={props.record.id}
                  fieldInput={props.record.year}
                />
                {props.record.error_year_of_passing && (
                  <small className="errors ">
                    {props.record.error_year_of_passing}
                  </small>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="forms ">
                <label htmlFor={`file`}>
                  Upload Certificate (In pdf/Image format){" "}
                  <span className="optionFields">(Optional)</span>
                  {props.record.file !== "" && props.record.file != null && (
                    <a
                      href={`${ImageUrlPrefix}/${props.record.file}`}
                      target="_blank"
                      download
                    >
                      <span className="material-icons align-middle download_icon">
                        file_download
                      </span>
                    </a>
                  )}
                </label>
                <div className="row">
                  <div
                    className={
                      props.record.file !== "" && props.record.file !== null
                        ? "col-md-3 "
                        : "col-md-12"
                    }
                  >
                    <input
                      id={`file`}
                      type="file"
                      disabled={props.admission_status === 1 ? true : false}
                      name={`file`}
                      // value={props.record.file}
                      onChange={(e) =>
                        props.TechhandleChange(e, props.record.id)
                      }
                      className={
                        props.record.file !== "" && props.record.file !== null
                          ? "InputsFile nn_upload_width"
                          : "InputsFile  vv_upload_width"
                      }
                      placeholder="Upload Certificate"
                      autocomplete="file"
                      required
                    />
                  </div>
                  {props.record.file !== "" && props.record.file !== null && (
                    <div className={"col-md-9 d-flex align-content-center p-0"}>
                      <span className="nn_upload_file">
                        {props.record.file !== "" && props.record.file !== null
                          ? props.record.original_file_path
                          : ""}
                      </span>
                      <span className="material-icons align-middle text-danger verified_upload">
                        verified
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mb-4">
          <div className="col-md-12 d-flex justify-content-end">
            {/* {props.record.id === 1 ? (

              ""
              
            ) : (
              <a className="AddMore me-3"  disabled ={props.admission_status === 1 ? true : false} onClick={() => props.admission_status === 1   ? "" :props.DeleteQual(props.record.id, props.record.name)} ><span  disabled ={props.admission_status === 1 ? true : false} className="material-icons text-danger align-middle">remove_circle</span>Delete Technical Qualification</a>
            )}


               {props.record.id === props.length  ? (

<a className="AddMore"   onClick={props.admission_status === 1 ? "" : props.AddQualification} ><span  className="material-icons text-danger align-middle">add_circle</span>Add More Technical Qualification</a>
               
            ) : (
            //  <a className="AddMore"   onClick={()=> props.admission_status === 1 ? "" : props.DeleteQual(props.record.id, props.record.name)} ><span  disabled ={props.admission_status === 1 ? true : false} className="material-icons text-danger align-middle">remove_circle</span>Delete Technical Qualification</a> 
            ""
            )} */}

            {props.record.id != 1 && props.record.id === props.length && (
              <a
                className="AddMore me-3"
                disabled={props.admission_status === 1 ? true : false}
                onClick={() =>
                  props.admission_status === 1
                    ? ""
                    : props.DeleteQual(props.record.id, props.record.name)
                }
              >
                <span
                  disabled={props.admission_status === 1 ? true : false}
                  className="material-icons download_icon align-middle"
                >
                  remove_circle
                </span>
                Delete Technical Qualification
              </a>
            )}

            {props.record.id === props.length ? (
              <a
                className="AddMore"
                onClick={
                  props.admission_status === 1 ? "" : props.AddQualification
                }
              >
                <span className="material-icons download_icon align-middle">
                  add_circle
                </span>
                Add More Technical Qualification
              </a>
            ) : (
              /* For all other items, show the delete button */
              <a
                className="AddMore me-3"
                disabled={props.admission_status === 1 ? true : false}
                onClick={() =>
                  props.admission_status === 1
                    ? ""
                    : props.DeleteQual(props.record.id, props.record.name)
                }
              >
                <span
                  disabled={props.admission_status === 1 ? true : false}
                  className="material-icons download_icon align-middle"
                >
                  remove_circle
                </span>
                Delete Technical Qualification
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
