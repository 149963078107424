export const ValidateCfocus = (
  data,
  certificates,
  edu,
  step,
  selectedPre,
  selectedPreDept,
  AdmissionRef_validation,
  batchDetails,
  selectedPrev
) => {
  // console.log(selectedPrev, "selectedPrev");
  const errors = {};
  if (step === 1 && !data.FirstName) {
    AdmissionRef_validation.firstName.current.focus();
  } else if (step === 1 && !data.LastName) {
    AdmissionRef_validation.lastName.current.focus();
  } else if (step === 1 && !data.Fathername) {
    AdmissionRef_validation.father.current.focus();
  } else if (step === 1 && !data.MotherName) {
    AdmissionRef_validation.mother.current.focus();
  } else if (step === 1 && !data.DOB) {
    AdmissionRef_validation.mdob.current.setFocus();
  } else if (step === 1 && !data.age) {
    AdmissionRef_validation.age.current.focus();
  } else if (step === 1 && !data.gender) {
    AdmissionRef_validation.gender.current.focus();
  } else if (step === 1 && !data.MaritalStatus) {
    AdmissionRef_validation.mStatus.current.focus();
  } else if (step === 1 && !data.Profilephoto) {
    AdmissionRef_validation.pSize.current.focus();
  } else if (step === 1 && !data.aadharNo) {
    AdmissionRef_validation.ano.current.focus();
  } else if (step === 1 && data.aadharNo && data.aadharNo.length < 12) {
    AdmissionRef_validation.ano.current.focus();
  } else if (step === 1 && data.aadharNo && data.aadharNo.length > 12) {
    AdmissionRef_validation.ano.current.focus();
  } else if (step === 1 && !data.aadharCardphoto) {
    AdmissionRef_validation.apath.current.focus();
  } else if (step === 1 && !data.Cast) {
    AdmissionRef_validation.cno.current.focus();
  } else if (step === 1 && !data.SPCcategory) {
    AdmissionRef_validation.SPCcategory.current.focus();
  }
  //  else if (step === 1 && (!data.CastFile || data.CastFile.length === 0)) {
  //     AdmissionRef_validation.cpath.current.focus();
  // }
  // else if (step === 1 && data.CastFile.size > 2 * 1024 * 1024) {
  // }
  else if (step === 2 && !data.Mobile) {
    AdmissionRef_validation.pno.current.focus();
  } else if (step === 2 && data.Mobile && data.Mobile.length < 10) {
    AdmissionRef_validation.pno.current.focus();
  } else if (step === 2 && data.Mobile && !/^\d+$/.test(data.Mobile)) {
    AdmissionRef_validation.pno.current.focus();
  } else if (
    step === 2 &&
    (!data.WhatsAppNumber || !/^\d+$/.test(data.WhatsAppNumber)) &&
    !data.ContactNumber
  ) {
    AdmissionRef_validation.wno.current.focus();
  } else if (
    step === 2 &&
    data.WhatsAppNumber &&
    data.WhatsAppNumber.length < 10 &&
    data.ContactNumber === false
  ) {
    AdmissionRef_validation.wno.current.focus();
  } else if (
    step === 2 &&
    data.WhatsAppNumber &&
    !/^\d+$/.test(data.WhatsAppNumber) &&
    !data.ContactNumber
  ) {
    AdmissionRef_validation.wno.current.focus();
  } else if (step === 2 && !data.ParentMobileNo) {
    AdmissionRef_validation.fpo.current.focus();
  } else if (
    step === 2 &&
    data.ParentMobileNo &&
    data.ParentMobileNo.length < 10
  ) {
    AdmissionRef_validation.fpo.current.focus();
  } else if (
    step === 2 &&
    data.ParentMobileNo &&
    !/^\d+$/.test(data.ParentMobileNo)
  ) {
    AdmissionRef_validation.fpo.current.focus();
  } else if (step === 2 && !data.emailaddress) {
    AdmissionRef_validation.email.current.focus();
  } else if (
    step === 2 &&
    data.emailaddress &&
    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailaddress)
  ) {
    AdmissionRef_validation.email.current.focus();
  } else if (step === 2 && !data.BuildingNumberPresent) {
    AdmissionRef_validation.phno.current.focus();
  } else if (step === 2 && !data.StreetNamePresent) {
    AdmissionRef_validation.psname.current.focus();
  } else if (step === 2 && !data.TalukPresent) {
    AdmissionRef_validation.ptaluk.current.focus();
  } else if (step === 2 && !data.PostPresent) {
    AdmissionRef_validation.ppost.current.focus();
  } else if (step === 2 && !data.AreaPresent) {
    AdmissionRef_validation.parea.current.focus();
  } else if (step === 2 && !data.PincodePresent) {
    AdmissionRef_validation.ppincode.current.focus();
  } else if (step === 2 && !/^[1-9][0-9]{5}$/.test(data.PincodePresent)) {
    AdmissionRef_validation.ppincode.current.focus();
  } else if (step === 2 && !data.CityPresent) {
    AdmissionRef_validation.pcity.current.focus();
  } else if (
    step === 2 &&
    !data.BuildingNumberPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.perhno.current.focus();
  } else if (
    step === 2 &&
    !data.StreetNamePermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.persname.current.focus();
  } else if (
    step === 2 &&
    !data.TalukPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.pertaluk.current.focus();
  } else if (
    step === 2 &&
    !data.PostPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.pererpost.current.focus();
  } else if (
    step === 2 &&
    !data.AreaPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.perarea.current.focus();
  } else if (
    step === 2 &&
    !data.PincodePermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.perpincode.current.focus();
  } else if (
    step === 2 &&
    !/^[1-9][0-9]{5}$/.test(data.PincodePermanent) &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.perpincode.current.focus();
  } else if (
    step === 2 &&
    !data.CityPermanent &&
    data.SamePresentAddress === false
  ) {
    AdmissionRef_validation.percity.current.focus();
  } else if (step === 4 && !data.category) {
    AdmissionRef_validation.category.current.focus();
  } else if (step === 4 && !data.ExamName) {
    AdmissionRef_validation.examPassed.current.focus();
  } else if (step === 4 && !data.ExamRegno) {
    AdmissionRef_validation.examRegno.current.focus();
  } else if (step === 4 && data.ExamRegno && data.ExamRegno.length > 10) {
    AdmissionRef_validation.examRegno.current.focus();
  } else if (step === 4 && !data.location) {
    AdmissionRef_validation.location.current.focus();
  } else if (step === 4 && selectedPreDept.length === 0) {
    AdmissionRef_validation.preferredep.current.focus();
  } else if (
    step === 4 &&
    batchDetails.length > 0 &&
    !batchDetails.some((batch) => batch.is_selected)
  ) {
    AdmissionRef_validation.batchDetails.current.focus();
  } else if (
    step === 4 &&
    batchDetails.length > 0 &&
    batchDetails.some(
      (batch) => batch.panel_details && batch.panel_details.length === 0
    )
  ) {
    AdmissionRef_validation.batchDetails.current.focus();
  } else if (
    step === 4 &&
    batchDetails.length > 0 &&
    batchDetails.some((batch) => batch.is_selected) &&
    batchDetails.some(
      (batch) => batch.panel_details && batch.panel_details.length > 0
    ) &&
    !batchDetails.some(
      (batch) =>
        batch.panel_details &&
        batch.panel_details.some((exam) => exam.is_selected_panel)
    )
  ) {
    AdmissionRef_validation.batchDetails.current.focus();
  } else if (step === 4 && !data.markPaperone && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper1.current.focus();
  } else if (
    step === 4 &&
    data.markPaperone &&
    typeof data.markPaperone === "string" &&
    data.markPaperone.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper1.current.focus();
  } else if (step === 4 && !data.markPaperTwo && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper2.current.focus();
  } else if (
    step === 4 &&
    data.markPaperTwo &&
    typeof data.markPaperTwo === "string" &&
    data.markPaperTwo.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper2.current.focus();
  } else if (step === 4 && !data.markpaperThree && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper3.current.focus();
  } else if (
    step === 4 &&
    data.markpaperThree &&
    typeof data.markpaperThree === "string" &&
    data.markpaperThree.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper3.current.focus();
  } else if (step === 4 && !data.markPaperFour && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper4.current.focus();
  } else if (
    step === 4 &&
    data.markPaperFour &&
    typeof data.markPaperFour === "string" &&
    data.markPaperFour.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper4.current.focus();
  } else if (step === 4 && !data.markPaperFive && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper5.current.focus();
  } else if (
    step === 4 &&
    data.markPaperFive &&
    typeof data.markPaperFive === "string" &&
    data.markPaperFive.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper5.current.focus();
  } else if (step === 4 && !data.markPaperSix && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper6.current.focus();
  } else if (
    step === 4 &&
    data.markPaperSix &&
    typeof data.markPaperSix === "string" &&
    data.markPaperSix.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper6.current.focus();
  } else if (step === 4 && !data.markPaperSeven && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper7.current.focus();
  } else if (
    step === 4 &&
    data.markPaperSeven &&
    typeof data.markPaperSeven === "string" &&
    data.markPaperSeven.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper7.current.focus();
  } else if (step === 4 && !data.markPaperEight && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper8.current.focus();
  } else if (
    step === 4 &&
    data.markPaperEight &&
    typeof data.markPaperEight === "string" &&
    data.markPaperEight.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper8.current.focus();
  } else if (step === 4 && !data.markPaperSeven && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper7.current.focus();
  } else if (
    step === 4 &&
    data.markPaperSeven &&
    typeof data.markPaperSeven === "string" &&
    data.markPaperSeven.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper7.current.focus();
  } else if (step === 4 && !data.markPaperNine && data.category === "UPSC") {
    AdmissionRef_validation.Markpaper9.current.focus();
  } else if (
    step === 4 &&
    data.markPaperNine &&
    typeof data.markPaperNine === "string" &&
    data.markPaperNine.length > 10 &&
    data.category === "UPSC"
  ) {
    AdmissionRef_validation.Markpaper9.current.focus();
  } else if (
    step === 4 &&
    (data.category === "UPSC" ||
      (data.category === "TNPSC" &&
        (data.ExamName === "3" ||
          data.ExamName === "34" ||
          data.ExamName === 3 ||
          data.ExamName === 34))) &&
    !data.markUpdatedMains
  ) {
    AdmissionRef_validation.markUpdatedMains.current.focus();
  } else if (
    step === 4 &&
    (data.category === "UPSC" ||
      (data.category === "TNPSC" &&
        (data.ExamName === "3" ||
          data.ExamName === "34" ||
          data.ExamName === 3 ||
          data.ExamName === 34))) &&
    data.markUpdatedMains &&
    typeof data.markUpdatedMains === "string" &&
    data.markUpdatedMains.length > 10
  ) {
    AdmissionRef_validation.markUpdatedMains.current.focus();
  } else if (
    step === 4 &&
    (data.category === "UPSC" ||
      (data.category === "TNPSC" &&
        (data.ExamName === "3" ||
          data.ExamName === "34" ||
          data.ExamName === 3 ||
          data.ExamName === 34))) &&
    !data.markUpdatedOral
  ) {
    AdmissionRef_validation.markUpdatedOral.current.focus();
  } else if (
    step === 4 &&
    (data.category === "UPSC" ||
      (data.category === "TNPSC" &&
        (data.ExamName === "3" ||
          data.ExamName === "34" ||
          data.ExamName === 3 ||
          data.ExamName === 34))) &&
    data.markUpdatedOral &&
    typeof data.markUpdatedOral === "string" &&
    data.markUpdatedOral.length > 10
  ) {
    AdmissionRef_validation.markUpdatedOral.current.focus();
  } else if (
    step === 4 &&
    (data.category === "SSC" ||
      data.category === "TNPSC" ||
      data.category === "RRB" ||
      data.category === "UPSC") &&
    !data.overallrank
  ) {
    AdmissionRef_validation.overallrank.current.focus();
  } else if (
    step === 4 &&
    (data.category === "SSC" ||
      data.category === "TNPSC" ||
      data.category === "RRB" ||
      data.category === "UPSC") &&
    data.overallrank &&
    typeof data.overallrank === "string" &&
    data.overallrank.length > 10
  ) {
    AdmissionRef_validation.overallrank.current.focus();
  } else if (
    step === 4 &&
    (data.category === "SSC" || data.category === "RRB") &&
    !data.MarksOne
  ) {
    AdmissionRef_validation.MarksOne.current.focus();
  } else if (
    step === 4 &&
    (data.category === "SSC" || data.category === "RRB") &&
    data.MarksOne &&
    typeof data.MarksOne === "string" &&
    data.MarksOne.length > 10
  ) {
    AdmissionRef_validation.MarksOne.current.focus();
  } else if (
    step === 4 &&
    (data.category === "SSC" || data.category === "RRB") &&
    !data.MarksTwo
  ) {
    AdmissionRef_validation.MarksTwo.current.focus();
  } else if (
    step === 4 &&
    (data.category === "SSC" || data.category === "RRB") &&
    data.MarksTwo &&
    typeof data.MarksTwo === "string" &&
    data.MarksTwo.length > 10
  ) {
    AdmissionRef_validation.MarksTwo.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "4" || data.ExamName === 4) &&
    !data.Interviewmarks
  ) {
    AdmissionRef_validation.Interviewmarks.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "4" || data.ExamName === 4) &&
    data.Interviewmarks &&
    typeof data.Interviewmarks === "string" &&
    data.Interviewmarks.length > 10
  ) {
    AdmissionRef_validation.Interviewmarks.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === "4" || data.ExamName === 4) &&
    !data.StenoRank
  ) {
    AdmissionRef_validation.StenoRank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === "4" || data.ExamName === 4) &&
    data.StenoRank &&
    typeof data.StenoRank === "string" &&
    data.StenoRank.length > 10
  ) {
    AdmissionRef_validation.StenoRank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === "4" || data.ExamName === 4) &&
    !data.WindowRank
  ) {
    AdmissionRef_validation.WindowRank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === "4" || data.ExamName === 4) &&
    data.WindowRank &&
    typeof data.WindowRank === "string" &&
    data.WindowRank.length > 10
  ) {
    AdmissionRef_validation.WindowRank.current.focus();
  } else if (
    step === 4 &&
    (data.category === "SSC" ||
      data.category === "TNPSC" ||
      data.category === "RRB" ||
      data.category === "UPSC") &&
    !data.communalrank
  ) {
    AdmissionRef_validation.communalrank.current.focus();
  } else if (
    step === 4 &&
    (data.category === "SSC" ||
      data.category === "TNPSC" ||
      data.category === "RRB" ||
      data.category === "UPSC") &&
    data.communalrank &&
    typeof data.communalrank === "string" &&
    data.communalrank.length > 10
  ) {
    AdmissionRef_validation.communalrank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === 5) &&
    !data.typistrank
  ) {
    AdmissionRef_validation.typistrank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === 5) &&
    data.typistrank &&
    typeof data.typistrank === "string" &&
    data.typistrank.length > 10
  ) {
    AdmissionRef_validation.typistrank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === 5) &&
    !data.tamilrank
  ) {
    AdmissionRef_validation.tamilrank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" || data.ExamName === 5) &&
    data.tamilrank &&
    typeof data.tamilrank === "string" &&
    data.tamilrank.length > 10
  ) {
    AdmissionRef_validation.tamilrank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" ||
      data.ExamName === "4" ||
      data.ExamName === 5 ||
      data.ExamName === 4) &&
    !data.PSTMRank
  ) {
    AdmissionRef_validation.PSTMRank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" ||
      data.ExamName === "4" ||
      data.ExamName === 5 ||
      data.ExamName === 4) &&
    data.PSTMRank &&
    typeof data.PSTMRank === "string" &&
    data.PSTMRank.length > 10
  ) {
    AdmissionRef_validation.PSTMRank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" ||
      data.ExamName === "4" ||
      data.ExamName === 5 ||
      data.ExamName === 4) &&
    !data.DRank
  ) {
    AdmissionRef_validation.DRank.current.focus();
  } else if (
    step === 4 &&
    data.category === "TNPSC" &&
    (data.ExamName === "5" ||
      data.ExamName === "4" ||
      data.ExamName === 5 ||
      data.ExamName === 4) &&
    data.DRank &&
    typeof data.DRank === "string" &&
    data.DRank.length > 10
  ) {
    AdmissionRef_validation.DRank.current.focus();
  } else if (step === 4 && selectedPre.length === 0) {
    AdmissionRef_validation.preferredpost.current.focus();
  }

  //   else if (step === 4 && !data.writtenexammark) {
  // }
  //     else if (step === 4 && !data.Interviewmarks) {
  // }
  //     else if (step === 4 && !data.overallrank) {
  // }
  //     else if (step === 4 && !data.communalrank) {
  // }
  //     else if (step === 4 && !data.typistrank) {
  // }
  //     else if (step === 4 && !data.tamilrank) {
  // }
  //     else if (step === 4 && !data.preferredpost) {
  // }
  else if (step === 4 && !data.ExamWrite && data.ExamPreviously === "Yes") {
    AdmissionRef_validation.examclear.current.focus();
  }
  //  else if (
  //   step === 4 &&
  //   selectedPrev.length > 1 && selectedPrev.every(item => item.examname === "" && item.examvalue === "") &&
  //   data.ExamPreviously === "Yes"
  // ) {
  //   AdmissionRef_validation.examdetail.current.focus();
  // }
  else if (
    step === 4 &&
    data.ExamWrite &&
    data.ExamPreviously === "Yes" &&
    typeof data.ExamWrite === "string" &&
    data.ExamWrite.length > 5
  ) {
    AdmissionRef_validation.examclear.current.focus();
  } else if (step === 4 && !data.Designation && data.workingRes === "Yes") {
    AdmissionRef_validation.designation.current.focus();
  } else if (step === 4 && !data.Department && data.workingRes === "Yes") {
    AdmissionRef_validation.department.current.focus();
  } else if (step === 4 && !data.JoinGovt && data.workingRes === "Yes") {
    AdmissionRef_validation.departJoin.current.focus();
  } else if (step === 4 && !data.Year && data.AIASAStudent === "Yes") {
    AdmissionRef_validation.aiasayear.current.focus();
  } else if (
    step === 4 &&
    !/^\d{4}$/.test(data.Year) &&
    data.AIASAStudent === "Yes"
  ) {
    AdmissionRef_validation.aiasayear.current.focus();
  } else if (step === 4 && !data.CourseAias && data.AIASAStudent === "Yes") {
    AdmissionRef_validation.aiasacourse.current.focus();
  } else if (step === 4 && !data.CategoryAias && data.AIASAStudent === "Yes") {
    AdmissionRef_validation.aiasacate.current.focus();
  } else if (step === 4 && !data.IDNumber && data.AIASAStudent === "Yes") {
    AdmissionRef_validation.aiasaIdnum.current.focus();
  } else if (
    step === 2 &&
    data.IDNumber &&
    typeof data.IDNumber === "string" &&
    data.IDNumber.length > 20 &&
    data.AIASAStudent === "Yes"
  ) {
    AdmissionRef_validation.aiasaIdnum.current.focus();
  } else if (step === 4 && data.AIASAStudent === "Yes" && !data.IDPhoto) {
    AdmissionRef_validation.aiasafile.current.focus();
  } else if (
    step === 4 &&
    data.AIASAStudent === "Yes" &&
    data.IDPhoto &&
    data.IDPhoto.size > 2 * 1024 * 1024
  ) {
    AdmissionRef_validation.aiasafile.current.focus();
  } else if (step === 3 && edu.length === 0) {
    AdmissionRef_validation.education.current.focus();
  }

  for (let i = 0; i < certificates.length; i++) {
    const cert = certificates[i];
    if (step === 3 && edu.includes("SSLC")) {
      if (!cert.SSLC_CNo && cert.name === "sslc") {
        AdmissionRef_validation.eduSCer.current.focus();
      } else if (
        cert.SSLC_CNo &&
        !cert.SSLC_CNo.trim() &&
        cert.name === "sslc"
      ) {
        AdmissionRef_validation.eduSCer.current.focus();
      } else if (
        cert.SSLC_CNo &&
        !/^[A-Za-z0-9]*$/.test(cert.SSLC_CNo) &&
        cert.name === "sslc"
      ) {
        AdmissionRef_validation.eduSCer.current.focus();
      }
      // else if (
      //   cert.SSLC_CNo &&
      //   typeof cert.SSLC_CNo === "string" &&
      //   cert.SSLC_CNo.length > 10 &&
      //   cert.name === "sslc"
      // ) {
      //   errors.SSLC_CNo = "Certificate number is maximum 10 characters";
      //   AdmissionRef_validation.eduSCer.current.focus();
      // }
      else if (!cert.SSLC_Year && cert.name === "sslc") {
        AdmissionRef_validation.eduSpass.current.focus();
      } else if (!cert.SSLC_Marks && cert.name === "sslc") {
        AdmissionRef_validation.eduSmarks.current.focus();
      } else if (
        cert.SSLC_Marks &&
        typeof cert.SSLC_Marks === "string" &&
        parseInt(cert.SSLC_Marks) > 100 &&
        cert.name === "sslc"
      ) {
        AdmissionRef_validation.eduSmarks.current.focus();
      } else if (!cert.SSLC_Eboard && cert.name === "sslc") {
        AdmissionRef_validation.eduSboard.current.focus();
      } else if (!cert.SSLC_Scl && cert.name === "sslc") {
        AdmissionRef_validation.eduSschool.current.focus();
      } else if (
        cert.SSLC_Scl &&
        !/^[A-Za-z\s]*$/.test(cert.SSLC_Scl) &&
        cert.name === "sslc"
      ) {
        AdmissionRef_validation.eduSschool.current.focus();
      } else if (!cert.SSLC_MI && cert.name === "sslc") {
        AdmissionRef_validation.eduSins.current.focus();
      }
      // else if (!cert.SSLC_Path && cert.name === "sslc") {

      //   AdmissionRef_validation.eduSfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("HSC")) {
      if (!cert.HSC_CNo && cert.name == "hsc" && edu.includes("HSC")) {
        AdmissionRef_validation.eduHCer.current.focus();
      } else if (
        cert.HSC_CNo &&
        !cert.HSC_CNo.trim() &&
        cert.name == "hsc" &&
        edu.includes("HSC")
      ) {
        AdmissionRef_validation.eduHCer.current.focus();
      } else if (
        cert.HSC_CNo &&
        !/^[A-Za-z0-9]*$/.test(cert.HSC_CNo) &&
        cert.name == "hsc" &&
        edu.includes("HSC")
      ) {
        AdmissionRef_validation.eduHCer.current.focus();
      }
      // else if (
      //   cert.HSC_CNo &&
      //   typeof cert.HSC_CNo === "string" &&
      //   cert.HSC_CNo.length > 10 &&
      //   cert.name === "hsc" &&
      //   edu.includes("HSC")
      // ) {
      //   errors.HSC_CNo = "Certificate number is maximum 10 number";
      //   AdmissionRef_validation.eduHCer.current.focus();
      // }
      else if (!cert.HSC_Year && cert.name == "hsc" && edu.includes("HSC")) {
        AdmissionRef_validation.eduHpass.current.setFocus();
      } else if (!cert.HSC_Marks && cert.name == "hsc" && edu.includes("HSC")) {
        AdmissionRef_validation.eduHmarks.current.focus();
      } else if (
        cert.HSC_Marks &&
        typeof cert.HSC_Marks === "string" &&
        parseInt(cert.HSC_Marks) > 100 &&
        cert.name === "HSC"
      ) {
        AdmissionRef_validation.eduHmarks.current.focus();
      } else if (
        !cert.HSC_Ebaord &&
        cert.name == "hsc" &&
        edu.includes("HSC")
      ) {
        AdmissionRef_validation.eduHboard.current.focus();
      } else if (!cert.HSC_Scl && cert.name == "hsc" && edu.includes("HSC")) {
        AdmissionRef_validation.eduHschool.current.focus();
      } else if (!cert.HSC_MI && cert.name == "hsc" && edu.includes("HSC")) {
        AdmissionRef_validation.eduHins.current.focus();
      }
    }
    // else if (!cert.HSC_Path && cert.name == "hsc" && edu.includes("HSC")) {

    //   AdmissionRef_validation.eduHfile.current.focus();
    // }

    if (step === 3 && edu.includes("UG")) {
      if (!cert.UGDegree && cert.name === "UG") {
        AdmissionRef_validation.eduUdeg.current.focus();
      } else if (!cert.UGMajor && cert.name === "UG") {
        AdmissionRef_validation.eduUMaj.current.focus();
      } else if (
        cert.UGMajor &&
        cert.UGMajor === "Others" &&
        !cert.UGMajor_Other &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUMajOther.current.focus();
      } else if (!cert.UGYearpassing && cert.name === "UG") {
        AdmissionRef_validation.eduUpass.current.setFocus();
      } else if (!cert.UGCertificateNumber && cert.name === "UG") {
        AdmissionRef_validation.eduUCer.current.focus();
      } else if (
        cert.UGCertificateNumber &&
        !cert.UGCertificateNumber.trim() &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUCer.current.focus();
      } else if (
        cert.UGCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.UGCertificateNumber) &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUCer.current.focus();
      } else if (!cert.UGMarksPercentage && cert.name === "UG") {
        AdmissionRef_validation.eduUmarks.current.focus();
      } else if (
        cert.UGMarksPercentage &&
        typeof cert.UGMarksPercentage == "string" &&
        parseInt(cert.UGMarksPercentage) > 100 &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUmarks.current.focus();
      } else if (!cert.UGClassName && cert.name === "UG") {
        AdmissionRef_validation.eduUclass.current.focus();
      }
      //  else if (cert.UGClassName && !/^[A-Za-z\s]*$/.test(cert.UGClassName) && cert.name === "UG") {
      //   errors.UGClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduUclass.current.focus();
      // }
      else if (!cert.UGNameInstitute && cert.name === "UG") {
        AdmissionRef_validation.eduUins.current.focus();
      } else if (
        cert.UGNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.UGNameInstitute) &&
        cert.name === "UG"
      ) {
        AdmissionRef_validation.eduUins.current.focus();
      }
      // else if (!cert.UG_Path && cert.name === "UG") {

      //   AdmissionRef_validation.eduUfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("PG")) {
      if (cert.PGDegree == "" && cert.name === "PG") {
        AdmissionRef_validation.eduPdeg.current.focus();
      } else if (!cert.PGMajor && cert.name === "PG") {
        AdmissionRef_validation.eduPMaj.current.focus();
      } else if (
        cert.PGMajor &&
        cert.PGMajor === "Others" &&
        !cert.PGMajor_Other &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPMajOther.current.focus();
      } else if (!cert.PGCertificateNumber && cert.name === "PG") {
        AdmissionRef_validation.eduPCer.current.focus();
      } else if (!cert.PGYearpassing && cert.name === "PG") {
        AdmissionRef_validation.eduPpass.current.setFocus();
      } else if (
        cert.PGCertificateNumber &&
        !cert.PGCertificateNumber.trim() &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPCer.current.focus();
      } else if (
        cert.PGCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.PGCertificateNumber) &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPCer.current.focus();
      } else if (!cert.PGMarksPercentage && cert.name === "PG") {
        AdmissionRef_validation.eduPmarks.current.focus();
      } else if (
        cert.PGMarksPercentage &&
        typeof cert.PGMarksPercentage === "string" &&
        parseInt(cert.PGMarksPercentage) > 100 &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPmarks.current.focus();
      } else if (!cert.PGClassName && cert.name === "PG") {
        AdmissionRef_validation.eduPclass.current.focus();
      }
      // else if (cert.PGClassName && !/^[A-Za-z\s]*$/.test(cert.PGClassName) && cert.name === "PG") {
      //   errors.PGClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduPclass.current.focus();
      // }
      else if (!cert.PGNameInstitute && cert.name === "PG") {
        AdmissionRef_validation.eduPins.current.focus();
      } else if (
        cert.PGNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.PGNameInstitute) &&
        cert.name === "PG"
      ) {
        AdmissionRef_validation.eduPins.current.focus();
      }
      //  else if (!cert.PG_Path && cert.name === "PG") {

      //   AdmissionRef_validation.eduPfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("Integrated PG")) {
      if (!cert.IPDegree && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIdeg.current.focus();
      } else if (!cert.IPMajor && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIMaj.current.focus();
      } else if (
        cert.IPMajor &&
        cert.IPMajor === "Others" &&
        !cert.IPMajor_Other &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduIMajOther.current.focus();
      } else if (!cert.IPYearpassing && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIpass.current.setFocus();
      } else if (!cert.IPCertificateNumber && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduICer.current.focus();
      } else if (
        cert.IPCertificateNumber &&
        !cert.IPCertificateNumber.trim() &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduICer.current.focus();
      } else if (
        cert.IPCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.IPCertificateNumber) &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduICer.current.focus();
      } else if (!cert.IPMarksPercentage && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduImarks.current.focus();
      } else if (
        cert.IPMarksPercentage &&
        typeof cert.IPMarksPercentage === "string" &&
        parseInt(cert.IPMarksPercentage) > 100 &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduImarks.current.focus();
      } else if (!cert.IPClassName && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIclass.current.focus();
      }
      //  else if (cert.IPClassName && !/^[A-Za-z\s]*$/.test(cert.IPClassName) && cert.name === "Integrated PG") {
      //   errors.IPClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduIclass.current.focus();
      // }
      else if (!cert.IPNameInstitute && cert.name === "Integrated PG") {
        AdmissionRef_validation.eduIins.current.focus();
      } else if (
        cert.IPNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.IPNameInstitute) &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduIins.current.focus();
      }
      //  else if (!cert.IP_Path && cert.name === "Integrated PG") {

      //   AdmissionRef_validation.eduIfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("Diploma")) {
      if (!cert.DDegree && cert.name === "Diploma") {
        AdmissionRef_validation.eduDdeg.current.focus();
      } else if (!cert.DMajor && cert.name === "Diploma") {
        AdmissionRef_validation.eduDMaj.current.focus();
      } else if (
        cert.DMajor &&
        cert.DMajor === "Others" &&
        !cert.DMajor_Other &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eduDMajOther.current.focus();
      } else if (!cert.DYearpassing && cert.name === "Diploma") {
        AdmissionRef_validation.eduDpass.current.setFocus();
      } else if (!cert.DCertificateNumber && cert.name === "Diploma") {
        AdmissionRef_validation.eduDCer.current.focus();
      } else if (
        cert.DCertificateNumber &&
        !cert.DCertificateNumber.trim() &&
        cert.name === "Diploma"
      ) {
        AdmissionRef_validation.eduDCer.current.focus();
      } else if (
        cert.DCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.DCertificateNumber) &&
        cert.name === "Diploma"
      ) {
        AdmissionRef_validation.eduDCer.current.focus();
      } else if (!cert.DMarksPercentage && cert.name === "Diploma") {
        AdmissionRef_validation.eduDmarks.current.focus();
      } else if (
        cert.DMarksPercentage &&
        typeof cert.DMarksPercentage === "string" &&
        parseInt(cert.DMarksPercentage) > 100 &&
        cert.name === "Diploma"
      ) {
        AdmissionRef_validation.eduDmarks.current.focus();
      } else if (!cert.DClassName && cert.name === "Diploma") {
        AdmissionRef_validation.eduDclass.current.focus();
      }
      // else if (cert.DClassName && !/^[A-Za-z\s]*$/.test(cert.DClassName) && cert.name === "Diploma") {
      //   errors.DClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduDclass.current.focus();
      // }
      else if (!cert.DNameInstitute && cert.name === "Diploma") {
        AdmissionRef_validation.eduDins.current.focus();
      } else if (
        cert.DNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.DNameInstitute) &&
        cert.name === "Diploma"
      ) {
        AdmissionRef_validation.eduDins.current.focus();
      }
      //   else if (!cert.D_Path && cert.name === "Diploma") {

      //   AdmissionRef_validation.eduDfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("Professional Courses")) {
      if (!cert.PCDegree && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCdeg.current.focus();
      } else if (!cert.PCMajor && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCMaj.current.focus();
      } else if (
        cert.PCMajor &&
        cert.PCMajor === "Others" &&
        !cert.PCMajor_Other &&
        cert.name === "Integrated PG"
      ) {
        AdmissionRef_validation.eeduCMajOther.current.focus();
      } else if (!cert.PCYearpassing && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCpass.current.setFocus();
      } else if (
        !cert.PCCertificateNumber &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCCer.current.focus();
      } else if (
        cert.PCCertificateNumber &&
        !cert.PCCertificateNumber.trim() &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCCer.current.focus();
      } else if (
        cert.PCCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.PCCertificateNumber) &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCCer.current.focus();
      } else if (
        !cert.PCMarksPercentage &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCmarks.current.focus();
      } else if (
        cert.PCMarksPercentage &&
        typeof cert.PCMarksPercentage === "string" &&
        parseInt(cert.PCMarksPercentage) > 100 &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCmarks.current.focus();
      } else if (!cert.PCClassName && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCclass.current.focus();
      }
      // else if (cert.PCClassName && !/^[A-Za-z\s]*$/.test(cert.PCClassName) && cert.name === "Professional Courses") {
      //   errors.PCClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduCclass.current.focus();
      // }
      else if (!cert.PCNameInstitute && cert.name === "Professional Courses") {
        AdmissionRef_validation.eduCins.current.focus();
      } else if (
        cert.PCNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.PCNameInstitute) &&
        cert.name === "Professional Courses"
      ) {
        AdmissionRef_validation.eduCins.current.focus();
      }
      //  else if (!cert.PC_Path && cert.name === "Professional Courses") {

      //   AdmissionRef_validation.eduCfile.current.focus();
      // }
    } else if (step === 3 && edu.includes("Others")) {
      if (!cert.OtDegree && cert.name === "Others") {
        AdmissionRef_validation.eduOdeg.current.focus();
      } else if (!cert.OtMajor && cert.name === "Others") {
        AdmissionRef_validation.eduOMaj.current.focus();
      } else if (!cert.OtYearpassing && cert.name === "Others") {
        AdmissionRef_validation.eduOpass.current.setFocus();
      } else if (!cert.OtCertificateNumber && cert.name === "Others") {
        AdmissionRef_validation.eduOCer.current.focus();
      } else if (
        cert.OtCertificateNumber &&
        !cert.OtCertificateNumber.trim() &&
        cert.name === "Others"
      ) {
        AdmissionRef_validation.eduOCer.current.focus();
      } else if (
        cert.OtCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.OtCertificateNumber) &&
        cert.name === "Others"
      ) {
        AdmissionRef_validation.eduOCer.current.focus();
      } else if (!cert.OtMarksPercentage && cert.name === "Others") {
        AdmissionRef_validation.eduOmarks.current.focus();
      } else if (
        cert.OtMarksPercentage &&
        typeof cert.OtMarksPercentage === "string" &&
        parseInt(cert.OtMarksPercentage) > 100 &&
        cert.name === "Others"
      ) {
        AdmissionRef_validation.eduOmarks.current.focus();
      } else if (!cert.OtClassName && cert.name === "Others") {
        AdmissionRef_validation.eduOclass.current.focus();
      }
      // else if (cert.OtClassName && !/^[A-Za-z\s]*$/.test(cert.OtClassName) && cert.name === "Others") {
      //   errors.OtClassName = "class can only contain letters and spaces";
      //   AdmissionRef_validation.eduOclass.current.focus();
      // }
      else if (!cert.OtNameInstitute && cert.name === "Others") {
        AdmissionRef_validation.eduOins.current.focus();
      } else if (
        cert.OtNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.OtNameInstitute) &&
        cert.name === "Others"
      ) {
        AdmissionRef_validation.eduOins.current.focus();
      }
      // else if (!cert.Ot_Path && cert.name === "Others") {

      //  AdmissionRef_validation.eduOfile.current.focus();
      // }
    }

    // Add additional validation rules for SSLC/HSC_Path if necessary
  }

  return errors;
};
