import React, { useState } from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";
import { AxiosInstance } from "../../../../axios";
import toast from "react-hot-toast";
export default function PublicResult({
  batch_id,
  event_id,
  userid,
  handleflagReload,
}) {
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => {
    setShowModal(false);
  };
  const handleSubmit = () => {
    const createPublishResults = {
      batch_id: batch_id,
      event_id: event_id,
      published_by: userid,
    };
    // console.log(raiseQuery);
    AxiosInstance.post("api/test/publish/results", createPublishResults).then(
      (res) => {
        if (res.data.status === true) {
          toast.success(res.data.message);
          handleClose();
          handleflagReload();
        } else {
          toast.error(res.data.message);
          handleClose();
        }
      }
    );
    // } else {
    //   setError(errors);
    // }
  };
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 class="view-title" id="exampleModalLabel">
            Publish Results
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12  mb-3">
              <div>
                <p className="view-bold AddMargin">
                  Are you sure you want to publish the result now?
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p className="cancel-button" onClick={handleClose}>
          Back to View Test
        </p>
        <button
          type="button"
          className="DashboardButton"
          onClick={handleSubmit}
        >
          <a href="javascript:void(0)">Publish Now</a>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
