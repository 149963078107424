export const validateModFocus = (
  data,
  certificates,
  edu,
  step,
  AdmissionRef_validation,
  batchDetails,
  appCastLable
) => {
  const errors = {};
  if (step === 1 && !data.FirstName) {
    AdmissionRef_validation.firstName.current.focus();
  } else if (step === 1 && !data.LastName) {
    AdmissionRef_validation.lastName.current.focus();
  } else if (step === 1 && !data.Fathername) {
    AdmissionRef_validation.father.current.focus();
  } else if (step === 1 && !data.gender) {
    AdmissionRef_validation.gender.current.focus();
  } else if (step === 1 && !data.DOB) {
    AdmissionRef_validation.mdob.current.setFocus();
  } else if (step === 1 && !data.age) {
    AdmissionRef_validation.age.current.focus();
  } else if (
    (step === 1 && isNaN(data.age)) ||
    data.age < 1 ||
    data.age > 150
  ) {
    AdmissionRef_validation.age.current.focus();
  } else if (step === 1 && !data.MaritalStatus) {
    AdmissionRef_validation.mStatus.current.focus();
  } else if (step === 1 && !data.Profilephoto) {
    AdmissionRef_validation.pSize.current.focus();
  } else if (step === 1 && !data.signaturePhoto) {
    AdmissionRef_validation.signpath.current.focus();
  } else if (step === 1 && !data.aadharNo) {
    AdmissionRef_validation.ano.current.focus();
  } else if (step === 1 && data.aadharNo && data.aadharNo.length < 12) {
    AdmissionRef_validation.ano.current.focus();
  } else if (step === 1 && data.aadharNo && data.aadharNo.length > 12) {
    AdmissionRef_validation.ano.current.focus();
  } else if (
    step === 1 &&
    data.aadharCardphoto !== null &&
    !data.aadharCardphoto
  ) {
    AdmissionRef_validation.apath.current.focus();
  } else if (step === 1 && appCastLable.length === 0) {
    AdmissionRef_validation.AppCast.current.focus();
  } else if (step === 1 && !data.Cast) {
    AdmissionRef_validation.cno.current.focus();
  }
  // -------------STEP 2-----------------
  // else if (step === 2 && !data.Mobile) {
  //   AdmissionRef_validation.pno.current.focus();
  // } else if (step === 2 && data.Mobile && data.Mobile.length < 10) {
  //   AdmissionRef_validation.pno.current.focus();
  // } else if (step === 2 && data.Mobile && !/^\d+$/.test(data.Mobile)) {
  //   AdmissionRef_validation.pno.current.focus();
  // } else if (
  //   step === 2 &&
  //   (!data.WhatsAppNumber || !/^\d+$/.test(data.WhatsAppNumber)) &&
  //   !data.ContactNumber
  // ) {
  //   AdmissionRef_validation.wno.current.focus();
  // } else if (
  //   step === 2 &&
  //   data.WhatsAppNumber &&
  //   data.WhatsAppNumber.length < 10 &&
  //   !data.ContactNumber
  // ) {
  //   AdmissionRef_validation.wno.current.focus();
  // } else if (
  //   step === 2 &&
  //   data.WhatsAppNumber &&
  //   !/^\d+$/.test(data.WhatsAppNumber) &&
  //   !data.ContactNumber
  // ) {
  //   AdmissionRef_validation.wno.current.focus();
  // } else if (step === 2 && !data.ParentMobileNo) {
  //   AdmissionRef_validation.fpo.current.focus();
  // } else if (
  //   step === 2 &&
  //   data.ParentMobileNo &&
  //   data.ParentMobileNo.length < 10
  // ) {
  //   AdmissionRef_validation.fpo.current.focus();
  // } else if (
  //   step === 2 &&
  //   data.ParentMobileNo &&
  //   !/^\d+$/.test(data.ParentMobileNo)
  // ) {
  //   AdmissionRef_validation.fpo.current.focus();
  // } else if (step === 2 && !data.emailaddress) {
  //   AdmissionRef_validation.email.current.focus();
  // } else if (
  //   step === 2 &&
  //   data.emailaddress &&
  //   !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailaddress)
  // ) {
  //   AdmissionRef_validation.email.current.focus();
  // }
  // // -------------------
  // else if (step === 2 && !data.BuildingNumberPresent) {
  //   AdmissionRef_validation.phno.current.focus();
  // } else if (step === 2 && !data.StreetNamePresent) {
  //   AdmissionRef_validation.psname.current.focus();
  // } else if (step === 2 && !data.TalukPresent) {
  //   AdmissionRef_validation.ptaluk.current.focus();
  // } else if (step === 2 && !data.PostPresent) {
  //   AdmissionRef_validation.ppost.current.focus();
  // } else if (step === 2 && !data.AreaPresent) {
  //   AdmissionRef_validation.parea.current.focus();
  // } else if (step === 2 && !data.PincodePresent) {
  //   AdmissionRef_validation.ppincode.current.focus();
  // } else if (step === 2 && !/^[1-9][0-9]{5}$/.test(data.PincodePresent)) {
  //   AdmissionRef_validation.ppincode.current.focus();
  // } else if (step === 2 && !data.CityPresent) {
  //   AdmissionRef_validation.pcity.current.focus();
  // } else if (
  //   step === 2 &&
  //   !data.BuildingNumberPermanent &&
  //   (data.SamePresentAddress  === false || data.SamePresentAddress  === undefined)
  // ) {
  //   AdmissionRef_validation.perhno.current.focus();
  // } else if (
  //   step === 2 &&
  //   !data.StreetNamePermanent &&
  //   (data.SamePresentAddress  === false || data.SamePresentAddress  === undefined)
  // ) {
  //   AdmissionRef_validation.persname.current.focus();
  // } else if (
  //   step === 2 &&
  //   !data.TalukPermanent &&
  //   (data.SamePresentAddress  === false || data.SamePresentAddress  === undefined)
  // ) {
  //   AdmissionRef_validation.pertaluk.current.focus();
  // } else if (
  //   step === 2 &&
  //   !data.PostPermanent &&
  //   (data.SamePresentAddress  === false || data.SamePresentAddress  === undefined)
  // ) {
  //   AdmissionRef_validation.pererpost.current.focus();
  // } else if (
  //   step === 2 &&
  //   !data.AreaPermanent &&
  //   (data.SamePresentAddress  === false || data.SamePresentAddress  === undefined)
  // ) {
  //   AdmissionRef_validation.perarea.current.focus();
  // } else if (
  //   step === 2 &&
  //   !data.PincodePermanent &&
  //   (data.SamePresentAddress  === false || data.SamePresentAddress  === undefined)
  // ) {
  //   AdmissionRef_validation.perpincode.current.focus();
  // } else if (
  //   step === 2 &&
  //   !/^[1-9][0-9]{5}$/.test(data.PincodePermanent) &&
  //   (data.SamePresentAddress  === false || data.SamePresentAddress  === undefined)
  // ) {
  //   AdmissionRef_validation.perpincode.current.focus();
  // } else if (
  //   step === 2 &&
  //   !data.CityPermanent &&
  //   (data.SamePresentAddress  === false || data.SamePresentAddress  === undefined)
  // ) {
  //   AdmissionRef_validation.percity.current.focus();
  // }
  // -----------------------
  else if (step === 3 && !data.category) {
    AdmissionRef_validation.category.current.focus();
  } else if (step === 3 && !data.CourseCate) {
    AdmissionRef_validation.course.current.focus();
  } else if (step === 3 && !data.TeachMedium) {
    AdmissionRef_validation.medium.current.focus();
  } else if (step === 3 && !data.classMode) {
    AdmissionRef_validation.classMode.current.focus();
  } else if (step === 3 && !data.districts) {
    AdmissionRef_validation.district.current.focus();
  } else if (step === 3 && data.hallticket === "Yes" && !data.ExamRegNo) {
    AdmissionRef_validation.examRegno.focus();
  } else if (
    step === 3 &&
    data.hallticket === "Yes" &&
    data.ExamRegNo &&
    typeof data.ExamRegNo === "string" &&
    data.ExamRegNo.length > 10
  ) {
    AdmissionRef_validation.examRegno.focus();
  } else if (
    step === 3 &&
    data.hallticket === "Yes" &&
    data.hallExamPhoto &&
    data.hallExamPhoto.size > 2 * 1024 * 1024
  ) {
    AdmissionRef_validation.hallExamPhoto.current.focus();
  } else if (
    step === 3 &&
    batchDetails.length > 0 &&
    !batchDetails.some((batch) => batch.is_selected)
  ) {
    AdmissionRef_validation.batchDetails.current.focus();
  } else if (
    step === 3 &&
    batchDetails.length > 0 &&
    batchDetails.some(
      (batch) => batch.exam_details && batch.exam_details.length === 0
    )
  ) {
    AdmissionRef_validation.batchDetails.current.focus();
  } else if (
    step === 3 &&
    batchDetails.length > 0 &&
    batchDetails.some((batch) => batch.is_selected) &&
    batchDetails.some(
      (batch) => batch.exam_details && batch.exam_details.length > 0
    ) &&
    !batchDetails.some(
      (batch) =>
        batch.exam_details &&
        batch.exam_details.some((exam) => exam.is_selected_exam === true)
    )
  ) {
    AdmissionRef_validation.batchDetails.current.focus();
  } else if (
    step === 3 &&
    batchDetails.length > 0 &&
    !batchDetails.some((batch) => batch.is_selected) &&
    batchDetails.some(
      (batch) => batch.exam_details && batch.exam_details.length > 0
    ) &&
    !batchDetails.some(
      (batch) =>
        batch.exam_details &&
        batch.exam_details.some((exam) => exam.is_selected_exam)
    )
  ) {
    AdmissionRef_validation.batchDetails.current.focus();
  } else if (step === 3 && !data.AIASAStudent) {
    AdmissionRef_validation.aiasayear.current.focus();
  } else if (step === 3 && !data.Year && data.AIASAStudent === "Yes") {
    AdmissionRef_validation.aiasayear.current.focus();
  } else if (
    step === 3 &&
    data.Year &&
    !/^\d{4}$/.test(data.Year) &&
    data.AIASAStudent === "Yes"
  ) {
    AdmissionRef_validation.aiasayear.current.focus();
  } else if (step === 3 && !data.CategoryAias && data.AIASAStudent === "Yes") {
    AdmissionRef_validation.aiasacate.current.focus();
  } else if (step === 3 && !data.CourseAias && data.AIASAStudent === "Yes") {
    AdmissionRef_validation.aiasacourse.current.focus();
  } else if (step === 3 && !data.IDNumber && data.AIASAStudent === "Yes") {
    AdmissionRef_validation.aiasaIdnum.current.focus();
  } else if (
    step === 3 &&
    data.IDNumber &&
    typeof data.IDNumber === "string" &&
    data.IDNumber.length > 20 &&
    data.AIASAStudent === "Yes"
  ) {
    AdmissionRef_validation.aiasaIdnum.current.focus();
  } else if (step === 3 && data.AIASAStudent === "Yes" && !data.IDPhoto) {
    AdmissionRef_validation.aiasafile.current.focus();
  } else if (
    step === 3 &&
    data.AIASAStudent === "Yes" &&
    data.IDPhoto &&
    data.IDPhoto.size > 2 * 1024 * 1024
  ) {
    AdmissionRef_validation.aiasafile.current.focus();
  }

  return errors;
};
