export const LBL_BASIC_DETAILS = "Basic Details";
export const LBL_ID_PROOFS = "ID Proofs";
export const LBL_CONTACT_DETAILS = "Contact details";

export const R_PER_PAGE = 10; // all tables per page count

// img url for node js | react js

// export const ImageUrlPrefix ="https://annaiasacademy.com/api";

// export const ImageUrlPrefix = "https://annaiasacademy.com/api";
export const ImageUrlPrefix = "https://annaiasacademy.com/api";

export const GSTPRICE = 0;
// https://annaiasacademy.com

// export const ImageUrlPrefix ="https://annaiasacademy.com/api";

// payment secret key

// live key from aiasa  ==>  rzp_live_L7ooVeMu3Cr6KY

// test key from bharatclouds -->   rzp_test_c0hHzXEKePlGCL

// key_id: 'rzp_test_c0hHzXEKePlGCL',
//   key_secret: 'UvGYjU9s2fpftiqyT4HM2fKH'  from bharat clouds

// export const PaymentSKey ="rzp_live_L7ooVeMu3Cr6KY";

export const PaymentSKey = "rzp_live_L7ooVeMu3Cr6KY";

// microsoft teams client id

// 2ce154ce-34ed-46d2-9227-b4292d3d5fcf  - aiasa

// d8b05e43-1c70-4e43-b2a8-99618c1d1d57  - bharatclouds

export const ClientID = "2ce154ce-34ed-46d2-9227-b4292d3d5fcf"; //  client id

// when login its redirect to using domain -> portal azure add redirect url same as this url

// http://localhost:3000/  for localhost

// https://aiasadev.annaiasacademy.com/ for dev site

// https://annaiasacademy.com

export const RedirectURL = "https://annaiasacademy.com"; // create online meeting redirect url for microsoft instance

export const MenuRoleAccess = [1, 2, 3, 4, 5];
export const AdminRoleAccess = [1];
export const FacultyRoleAccess = [3, 4, 5];
export const TeachingRoles = [3];
export const NonTeachingRoles = [4];
export const PanelMemberRoles = [5];
export const StudentRoleAccess = [2];
export const AdminStudentRoleAccess = [1, 2];
export const AdminFacultyRoleAccess = [1, 3, 4, 5];

//  Admin Menu

// https://aiasadev.annaiasacademy.com/ListViewStudentConfirm

// https://aiasadev.annaiasacademy.com/studentConfirm/1407351f98ef69b22166e207d34d61a5/80/2024-07-16/0

// https://aiasadev.annaiasacademy.com/ViewCourseBatch

// https://aiasadev.annaiasacademy.com/CreateCourseBatch/0

// https://aiasadev.annaiasacademy.com/DetailViewBatch/11/0

// https://aiasadev.annaiasacademy.com/Faculties

// https://aiasadev.annaiasacademy.com/Faculties/addfaculty/0

// https://aiasadev.annaiasacademy.com/ReviewFacultyProfile/9b25affcf0039889077eedb3bf0f3416

// https://aiasadev.annaiasacademy.com/Faculties/FacultyDetailView/cd14b96a8a2f3bee7aa6dfcc99689575/5

// https://aiasadev.annaiasacademy.com/Faculties/AddPanelMember

// https://aiasadev.annaiasacademy.com/Faculties/PanelMember/8022eefb0b1b00885aabe144676995c0/4

// https://aiasadev.annaiasacademy.com/TestList

// https://aiasadev.annaiasacademy.com/DetailTestView/1/1/1

// https://aiasadev.annaiasacademy.com/ViewQuestionPaper

// https://aiasadev.annaiasacademy.com/UploadQuestionFile

// https://aiasadev.annaiasacademy.com/RegisteredUser

// https://aiasadev.annaiasacademy.com/DownloadHallTicket

// Student Menu

// https://aiasadev.annaiasacademy.com/Admission/Course

// https://aiasadev.annaiasacademy.com/Admission/TestBatch

// https://aiasadev.annaiasacademy.com/Admission/SpecialClasses

// https://aiasadev.annaiasacademy.com/Admission/Modelexam

// https://aiasadev.annaiasacademy.com/TransactionHistory

// https://aiasadev.annaiasacademy.com/ViewSchedule/joinTest

// https://aiasadev.annaiasacademy.com/PayFee

// https://aiasadev.annaiasacademy.com/TransactionHistory/ViewAdmission/98ad9f020629a1d3be61f69e0b747e42/26/75/1/0

// https://aiasadev.annaiasacademy.com/TransactionHistory/ViewAdmission/ViewPaymentHistory/1/75

// https://aiasadev.annaiasacademy.com/TransactionHistory/ViewAdmission/ViewSchedule/26/1

// https://aiasadev.annaiasacademy.com/TestViewSchedule/18/4

// https://aiasadev.annaiasacademy.com/ReviewSubmission/8/52

// Staff menu

// https://aiasadev.annaiasacademy.com/ProfileFacultyView

// https://aiasadev.annaiasacademy.com/CashPayment

// https://aiasadev.annaiasacademy.com/ModelExamCenter

// https://aiasadev.annaiasacademy.com/ViewModelExam

// https://aiasadev.annaiasacademy.com/StudentsQuery

// https://aiasadev.annaiasacademy.com/facultyProfile
