import { createGlobalStyle } from "styled-components";

const globalStyles = createGlobalStyle` 
:root {
    --color-brand-900:#C0272D;
    --color-brand-800:#c1393d;
    --color-brand-600:#760909ed;
    --color-brand-200:#F2DEDE;

    --color-text-primary:#041134;
    --color-text-secondary:rgba(4,17,52,0.5);
    --color-secondary-border:#e9e9e9;

    --color-white:#fff;
    --color-black:#111;

    --color-blue-600:#2378e9;
    /* Grey */
    --color-grey-0: #f8f9fa;
    --color-grey-50: #f9fafb;
    --color-grey-100: #f3f4f6;
    --color-grey-200: #e5e7eb;
    --color-grey-300: #d1d5db;
    --color-grey-400: #9ca3af;
    --color-grey-500: #6b7280;
    --color-grey-600: #4b5563;
    --color-grey-700: #374151;
    --color-grey-800: #1f2937;
    --color-grey-900: #111827;

    --backdrop-color: rgba(255, 255, 255, 0.1);

    --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.04);
    --shadow-md: 0px 0.6rem 2.4rem rgba(0, 0, 0, 0.06);
    --shadow-lg: 0 2.4rem 3.2rem rgba(0, 0, 0, 0.12);
  
    --border-radius-tiny: 3px;
    --border-radius-sm: 5px;
    --border-radius-md: 8px;
    --border-radius-lg: 10px;

    --font-weight-normal:600;
    --font-weight-medium:700;
    --font-weight-bold:800;
    --font-weight-bolder:900;
}`;

export default globalStyles;
