import React, { useState, useRef } from "react";
import "./ForgetPassword.css";
import "./ResetPasswordEmail.css";
import Logo from "../img/AIASA.png";
import IconLogo from "../img/IconLogo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../axios";
import Swal from "sweetalert2";

function ForgetPassword() {
  const txtForgetEmail = useRef(null);

  const navigate = useNavigate();

  const [resetEmail, setResetEmail] = useState({
    Email: "",
  });
  const [resetEmailError, setResetEmailError] = useState({});
  const [emaillink, setEmaillink] = useState(false);
  const [resendemaildisabled, setResendemaildisabled] = useState(false);
  const [remainingtime, setRemainingtime] = useState();
  const handleChange = (event) => {
    var eventValue = event.target.value;
    setResetEmail({
      ...resetEmail,
      [event.target.name]: eventValue,
    });

    setResetEmailError({
      ...resetEmailError,
      [event.target.name]: "",
    });
  };

  const validateFields = (data) => {
    const errors = {};

    if (!data.Email) {
      errors.Email = "Please Enter Email.";
      txtForgetEmail.current.focus();
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFields(resetEmail);
    if (Object.keys(errors).length === 0) {
      try {
        // api request to reset email
        const response = await AxiosInstance.post("/api/user/forgot", {
          email: resetEmail.Email,
        });

        if (response.data.status) {
          setEmaillink(true);
        } else {
          Swal.fire({
            title: "Status",
            text: response.data.message,
            icon: "Warning",
            confirmButtonText: "Back to Login",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setResetEmailError(errors);
    }
  };

  const handleResendEmailClick = async () => {
    setResendemaildisabled(true); // disable the button
    try {
      // Make the API call to resend the email
      // console.log(resetEmail,"resetEmail.Email");

      const response = await AxiosInstance.post("/api/user/forgot", {
        email: resetEmail.Email,
      });

      const durationInMs = 60 * 1000;
      if (response.data.status) {
        // console.log(resendemaildisabled,"...resendemaildisabled..")
        let remainingTime = durationInMs / 1000;
        const countdownInterval = setInterval(() => {
          const minutes = Math.floor(remainingTime / 60)
            .toString()
            .padStart(2, "0"); // calculate the remaining minutes and format them as a string with leading zeros
          const seconds = Math.floor(remainingTime % 60)
            .toString()
            .padStart(2, "0"); // calculate the remaining seconds and format them as a string with leading zeros
          // console.log(`${minutes}:${seconds}`);
          const formattedTime = `${minutes}:${seconds}`;

          setRemainingtime(formattedTime);
          remainingTime--;
          if (remainingTime === 0) {
            clearInterval(countdownInterval);
            setResendemaildisabled(false); // enable the button after the delay is over
          }
        }, 1000);
      } else {
        Swal.fire({
          title: "Warning",
          text: response.data.message,
          icon: "Warning",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {emaillink ? (
        <div className="ForgetForm " id="wrapper_Forget">
          <div className="row">
            <div className="col-md-12 box">
              <img src={IconLogo} id="img" alt="Logo" />
              <h1 className="">Check Your Email</h1>
              <p>
                Get back in control
                <br />
                check your email for you password reset link.
              </p>

              <div className="row SignupText">
                <div className="col-md-12">
                  <div className="reg">
                    <span>Didn't receive the email?</span>
                    {resendemaildisabled ? (
                      <div className="remainingtime">
                        <span>
                          Reset link sent to your mail id.Please wait for resend
                        </span>{" "}
                        <span></span>
                        <span>|</span> <span>{remainingtime}</span>
                      </div>
                    ) : (
                      <Link
                        to="#"
                        className="rega"
                        onClick={handleResendEmailClick}
                      >
                        Click to Resend
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="BackLogin">
                <span className="material-icons align-middle me-1">
                  keyboard_backspace
                </span>
                <Link to="/">Back to Log in</Link>
              </div>
              <div className="copyRights">
                © {new Date().getFullYear()} All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="PasswordForm " id="wrapper_Forget_Password">
          <div className="row">
            <div className="col-md-12 box">
              <img src={IconLogo} id="img" alt="Logo" />
              <h1 className="fw-bold">Forgot Password?</h1>
              <p>
                Don't let a forgotten password hold you back Reset and get
                going.
              </p>
              <form action="#" onSubmit={handleSubmit}>
                <input
                  type="email"
                  className="ForgetInputs"
                  ref={txtForgetEmail}
                  placeholder="Email"
                  onChange={handleChange}
                  value={resetEmail.Email}
                  name="Email"
                />
                {resetEmailError.Email && (
                  <small className="error">{resetEmailError.Email}</small>
                )}
                <button
                  type="submit"
                  className="LoginBtn"
                  name="ResetEmail"
                  value=""
                >
                  Send Password Reset Email
                </button>
              </form>
              <div className="BackLogin">
                <Link to="/login">
                  <i class="fa fa-long-arrow-left" id="MIcons"></i>Back to Log
                  in
                </Link>
              </div>
              <div className="copyRights">
                © {new Date().getFullYear()} All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgetPassword;
