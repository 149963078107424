// ModalComponent.js
import React from "react";
import "./dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import DatePickerSample from "../shared/Component/DatePicker";
import { ImageUrlPrefix } from "../labels";
import { AxiosInstance } from "../axios";

function PaymentDueModel(props) {
  const [first, setfirst] = useState(props.show);

  const navigate = useNavigate();

  const handleClose = () => {
    setfirst(false);
  };

  const handlePayNow = () => {
    AxiosInstance.post("api/purchase/pending/payment", {
      type: parseInt(props.admission_type),
      section: "",
      a_start_date: "",
      a_end_date: "",
      p_start_date: "",
      p_end_date: "",
      search: "",
      user_id: props.user_id,
    })
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data.length > 0) {
            const CourseDetail = res.data.data.find(
              (el) => el.adm_pk === props.adm_pk
            );

            navigate(`/PayFee`, {
              state: {
                CourseDetail,
              },
            });
          }
        }
      })
      .catch((error) => {
        return error;
      });
  };

  return (
    <Modal show={first} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5 class="modal-title modelPaymentDue" id="exampleModalLabel">
            {props.showModalData.type === 0 && "Payment Due Reminder"}
            {props.showModalData.type === 1 && "Payment Due - 1st Warning"}
            {props.showModalData.type === 2 && "Payment Due - 2nd Warning"}
            {props.showModalData.type === 3 && "Account Deactivated"}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {props.showModalData.type !== 3 && (
            <>
              <div className="row">
                <div className="col-md-3 p-1 ">
                  <div className="forms  ">
                    <label
                      htmlFor="examNumber"
                      className="form-labels ms-0 paymentLabel"
                    >
                      Admission ID
                    </label>
                    <span className="PaymentSp">
                      {props.showModalData.admission_id}
                    </span>
                  </div>
                </div>
                <div className="col-md-3 p-1 ">
                  <div className="forms  ">
                    <label
                      htmlFor="examNumber"
                      className="form-labels ms-0 paymentLabel"
                    >
                      Type
                    </label>
                    <span className="PaymentSp">
                      {props.showModalData.admission_type === 0 && "Course"}
                      {props.showModalData.admission_type === 1 && "Test Batch"}
                      {props.showModalData.admission_type === 3 &&
                        "Special Class Batch"}
                      {props.showModalData.admission_type === 4 && "Model Exam"}
                      {props.showModalData.admission_type === 2 &&
                        "Counselling"}
                      {props.showModalData.admission_type === 5 &&
                        "Mock Interview"}
                    </span>
                  </div>
                </div>

                <div className="col-md-4 p-1">
                  <div className="forms  ">
                    <label
                      htmlFor="examNumber"
                      className="form-labels ms-0  paymentLabel"
                    >
                      Batch
                    </label>
                    <span className="PaymentSp">
                      {props.showModalData.batch}
                    </span>
                  </div>
                </div>

                <div className="col-md-2 p-1">
                  <div className="forms ">
                    <label
                      htmlFor="examNumber"
                      className="form-labels ms-0   paymentLabel"
                    >
                      Section
                    </label>
                    <span className="PaymentSp">
                      {props.showModalData.section}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3 p-1">
                  <div className="forms   ">
                    <label
                      htmlFor="examNumber"
                      className="form-labels ms-0 paymentLabel"
                    >
                      Applied On
                    </label>
                    <span className="PaymentSp">
                      {props.showModalData.applied_on}
                    </span>
                  </div>
                </div>
                <div className="col-md-3 p-1">
                  <div className="forms   ">
                    <label
                      htmlFor="examNumber"
                      className="form-labels ms-0 paymentLabel"
                    >
                      Fee Paid
                    </label>
                    <span className="PaymentSp">
                      {props.showModalData.fees_paid}
                    </span>
                  </div>
                </div>

                <div className="col-md-4 p-1">
                  <div className="forms   ">
                    <label
                      htmlFor="examNumber"
                      className="form-labels ms-0 paymentLabel"
                    >
                      Balance Fee
                    </label>
                    <span className="PaymentSp">
                      {props.showModalData.balance_fees}
                    </span>
                  </div>
                </div>

                <div className="col-md-2 p-1">
                  <div className="forms  ">
                    <label
                      htmlFor="examNumber"
                      className="form-labels ms-0  color_red paymentLabel"
                    >
                      Due Date
                    </label>
                    <span className="PaymentSp color_red">
                      {props.showModalData.due_date}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          {props.showModalData.type === 0 && (
            <div className="row mt-3 mb-3">
              <span className="ReviewLabel_notes alert_skyblue">
                Pay your fees within <strong>6 days</strong> to avoid schedule
                deactivation. If you don't pay on time, you will not be allowed
                to attend classes until the fees are paid. Pay now to avoid any
                interruptions. Thank you for your timely cooperation.
              </span>
            </div>
          )}
          {props.showModalData.type === 1 && (
            <div className="row mt-3 mb-3">
              <span className="ReviewLabel_notes alert_Red">
                Your fees payment is overdue. Please pay within 7 days to keep
                your schedule. If not, you will not be allowed to attend classes
                until the fees are paid. Pay now to avoid any interruptions.
                Thank you for your prompt cooperation.
              </span>
            </div>
          )}
          {props.showModalData.type === 2 && (
            <div className="row mt-3 mb-3">
              <span className="ReviewLabel_notes alert_Red">
                Your fees payment is overdue. Please pay within 7 days to keep
                your schedule. If not, you will not be allowed to attend classes
                until the fees are paid. Pay now to avoid any interruptions.
                Thank you for your prompt cooperation.
              </span>
            </div>
          )}
          {props.showModalData.type === 3 && (
            <div className="row mt-3 mb-3">
              <span className="ReviewLabel_notes alert_Red">
                Your account is temporarily inactive due to unpaid fees. Please
                reach out to our support team, settle the fees, and reactivate
                your account. Thank you for your prompt attention
              </span>
            </div>
          )}{" "}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link
          to="javascript:void(0)"
          data-bs-dismiss="modal"
          onClick={handleClose}
          className="DashboardCancelButton"
        >
          Cancel
        </Link>
        {props.showModalData.type !== 3 ? (
          <button type="button" onClick={handlePayNow} className="DueButton">
            {" "}
            <span class="material-icons me-2">send</span>
            <a href="javascript:void(0)">Pay Now</a>
          </button>
        ) : (
          <button type="button" onClick={handleClose} className="DueButton">
            {" "}
            <a href="javascript:void(0)">Okey</a>
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default PaymentDueModel;
