import moment from "moment";
import React from "react";

export default function ReviewstatusCheck(props) {
  const { startDate, duration } = props;
  // console.log(props, "startDate");
  const startMoment = moment(startDate);
  const endMoment = startMoment.clone().add(duration, "months");
  const currentDate = moment();
  return (
    <td id="td">
      {endMoment.isBefore(currentDate) ? (
        <span className="ReviewLabel GreyText">completed</span>
      ) : currentDate.isBetween(
          startMoment.startOf("day").add(10, "hours"),
          endMoment.startOf("day").add(10, "hours"),
          null,
          "[]"
        ) ? (
        <span className="ReviewLabel GreenTexts">OnGoing</span>
      ) : (
        <span className="ReviewLabel PinkText">Upcoming</span>
      )}
    </td>
  );
}
