
import React, { useEffect, useState } from "react";
import TeachingListview from "./TeachingListview";
import NteachingListView from "./NteachingListView";
import PanelMembersLiistview from "./PanelMembersLiistview";
import { useNavigate, useParams } from "react-router-dom";



function ListViewFaculty() {

   const {active_tab} = useParams();
   const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    // Update the activeTab state when the component mounts and whenever active_tab changes in the URL
    setActiveTab(Number(active_tab) || 1);
    // setActiveTab(1);
  }, [active_tab]);


  const handleTabClick = (index) => {
    setActiveTab(index);
    navigate(`/Faculties/${index}`);
  };

  return (
    <div className=" BathDet ">
        <h6 className="Title">Team Members</h6>
      <div className="row mt-3">
        <div className="col-md-12">
        {/* nav-tabs */}
          <ul  
            className="nav  nav-fill mb-3"
            id="listViewTabs"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 1 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-1"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-1"
                aria-selected={activeTab === 1}
                onClick={() => handleTabClick(1)}
              >
                Faculty
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 2 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-2"
                aria-selected={activeTab === 2}
                onClick={() => handleTabClick(2)}
              >
              Staff
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 3 ? "nav-link active " : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex3-tabs-3"
                aria-selected={activeTab === 3}
                onClick={() => handleTabClick(3)}
              >
              Panel Members
              </a>
            </li>
          
         
          </ul>
        </div>
        {/* <div className="borderActive"></div> */}
      </div>
{/* // tab content   */}
  <TeachingListview activeTab={activeTab} />
  <NteachingListView activeTab={activeTab} />
  <PanelMembersLiistview activeTab={activeTab} />
    </div>
  );
}

export default ListViewFaculty;



