import React from "react";
import styled from "styled-components";
import { MEDIA_SIZE_FLIP } from "../utils/helper";

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin: 15px;
  @media (min-width: ${MEDIA_SIZE_FLIP}) {
    margin: 43px;
  }
`;

export default function CustomContainer({ children }) {
  return <Container>{children}</Container>;
}
