import React from "react";
import "./Question.css";
import lo from "./images/img1.png";
import parse from "html-react-parser";
import ImageDisplay from "../StudentTestWorkflow/ImageDisplay";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

//display type 6 means - mcq with image
// sample design

function Qtypeseven(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");
  const image =
    props.list.en_question != "" &&
    props.list.en_question != null &&
    EsplitLines.length > 1
      ? EsplitLines[1].split(" - ")
      : [];
  const image_tm =
    props.list.tm_question != "" &&
    props.list.tm_question != null &&
    splitLines.length > 1
      ? splitLines[1].split(" - ")
      : [];
  const imgTag = image[0] === "image" ? image[1] : "";
  const imgTag_tm = image_tm[0] === "image" ? image_tm[1] : "";
  const optionType = props.list.options_type;

  const generateOptionsEnglish = (optionType, index, props, userid) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      const valueKey = `options_en_${option}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );

      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_en`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  option,
                  props.list.subject_type
                )
              }
            />
            <span
              className="answer_label ms-1"
              onClick={(e) => {
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              ({option})
            </span>
          </div>
          <div>
            <span
              className="answer_label ms-1"
              onClick={(e) => {
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              {/* {parse(props.list[valueKey])} */}
              <LatexProcessorComponent originalString={props.list[valueKey]} />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType, index, props, userid) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      const valueKey = `options_tm_${option}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );

      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_tm`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                )
              }
            />
            <span
              className="answer_label_tamil ms-1"
              onClick={(e) => {
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              ({option})
            </span>
          </div>
          <div>
            <span
              className="answer_label_tamil ms-1"
              onClick={(e) => {
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              {/* {parse(props.list[valueKey])} */}
              <LatexProcessorComponent originalString={props.list[valueKey]} />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  return (
    <div className=" Question_temp">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 1 || props.Lang === 2))) && (
          <div className="row">
            {EsplitLines.map((line, index) => {
              if (line.includes("imgtext=")) {
                return (
                  <div className="col-md-12" key={index}>
                    <span className="question_labels_english">
                      {/* {parse(line.substring(10))} */}
                      <LatexProcessorComponent
                        originalString={line.substring(8)}
                      />
                    </span>
                  </div>
                );
              } else if (line.includes("image=")) {
                return (
                  <div className="col-md-12" key={index}>
                    <ImageDisplay
                      className={"imgtn"}
                      ImgUrl={`${parse(line.substring(8))}`}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="col-md-12" key={index}>
                    <span className="question_labels_english">
                      {/* {parse(line)} */}
                      <LatexProcessorComponent originalString={line} />
                    </span>
                  </div>
                );
              }
            })}
            <div className="col-md-12 mt-1">
              <div className="row">
                {generateOptionsEnglish(optionType, 1, props, userid)}
              </div>
            </div>
          </div>
        )}
      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 0 || props.Lang === 2))) &&
        splitLines[0] != "" && (
          <div className="row tamilQues">
            {splitLines.map((line, index) => {
              if (line.includes("imgtext=")) {
                return (
                  <div className="col-md-12" key={index}>
                    <span className="question_labels_english">
                      {/* {parse(line.substring(10))} */}
                      <LatexProcessorComponent
                        originalString={line.substring(8)}
                      />
                    </span>
                  </div>
                );
              } else if (line.includes("image=")) {
                return (
                  <div className="col-md-12" key={index}>
                    <ImageDisplay
                      className={"imgtn"}
                      ImgUrl={`${parse(line.substring(8))}`}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="col-md-12" key={index}>
                    <span className="question_labels_english">
                      {/* {parse(line)} */}
                      <LatexProcessorComponent originalString={line} />
                    </span>
                  </div>
                );
              }
            })}
            <div className="col-md-12 mt-1">
              <div className="row">
                {generateOptionsTamil(optionType, 1, props, userid)}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default Qtypeseven;
