import React from 'react'

export default function ReviewMockPanelMember(props) {

  return (
    <section className=" StepOne">
    <div className="row box">
      <div className="col-md-12">
        <p className="StepTitle">Panel Details</p>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-lg-6 col-md-4 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                    Panel Name
                  </label>
                  <span className="ReviewLabel">
                  {props.record.panel_name}
                  </span>
                </div>
              </div>

              <div className="col-lg-6 col-md-8 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                    Available dates
                  </label>
                  <span className="ReviewLabel">
                    {" "}
           
                    {props.record.available_dates.length> 0 ? props.record.available_dates.map((el) => el).join(', ') : "_"}
                  </span>
                </div>
              </div>

              
              <div className="col-lg-6 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                 Panel Members
                  </label>
                  <span className="ReviewLabel">
                  {props.record.panelMem.length> 0 ? props.record.panelMem.map((staff) => staff.name).join(', ') : "_"}
                
                  </span>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                  Teaching Faculty
                  </label>
                  <span className="ReviewLabel">
                  {props.record.staffMem.length> 0 ?  props.record.staffMem.map((staff) => staff.name).join(', '):"_"}
                
                  </span>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
