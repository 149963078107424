import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FaculityProfile from "./FaculityProfile";
import Faculityschedule from "../Faculityschedule";
import SalaryListView from "../SalaryListView";
import TeachingProgress from "./TeachingProgress";

function FaculityView() {
  const { active_tab } = useParams();
  const navigate = useNavigate();
  //   const {userId}  = useParams();
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    // Update the activeTab state when the component mounts and whenever active_tab changes in the URL
    setActiveTab(Number(active_tab) || 1);
    // setActiveTab(1);
  }, [active_tab]);

  const handleTabClick = (index) => {
    setActiveTab(index);

    navigate(`/ProfileFacultyView/${index}`);
  };
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  let user_type = null;
  let staff_id = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
    if ((user && user.user_type === 3) || user.user_type === 4) {
      staff_id = user.id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }
  // console.log(staff_id);
  return (
    <div className="BathDet ">
      <div className="row">
        <div className="col-lg-3 col-md-5 col-7 col-sm-7">
          <h6 className="Title">View Faculty Profile</h6>
        </div>
        <div className="col-lg-9 col-5 col-sm-5 col-md-7 d-flex justify-content-end mt-2">
          <Link to="/" className="CancelButtons can_mobile">
            Back to Home
          </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <ul className="nav  nav-fill mb-3" id="listViewTabs" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 1 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-2"
                aria-selected={activeTab === 1}
                onClick={() => handleTabClick(1)}
              >
                Schedule
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 2 ? "nav-link active " : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex3-tabs-4"
                aria-selected={activeTab === 2}
                onClick={() => handleTabClick(2)}
              >
                Teaching Progress
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 3 ? "nav-link active " : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex3-tabs-3"
                aria-selected={activeTab === 3}
                onClick={() => handleTabClick(3)}
              >
                Salary
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 4 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-1"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-1"
                aria-selected={activeTab === 4}
                onClick={() => handleTabClick(4)}
              >
                Profile
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="borderActive"></div> */}
      </div>
      <Faculityschedule
        staff_id={staff_id}
        userId={userid}
        activeTab={activeTab}
      />
      <FaculityProfile
        staff_id={staff_id}
        userId={userid}
        activeTab={activeTab}
      />

      <SalaryListView
        staff_id={staff_id}
        userId={userid}
        activeTab={activeTab}
      />
      <TeachingProgress
        staff_id={staff_id}
        userId={userid}
        activeTab={activeTab}
      />
    </div>
  );
}

export default FaculityView;
