import React from "react";
import Select, { components } from "react-select";
import { customStyles } from "../../../../pages/customStyles";
function AddJobInfo(props) {

  
  
  return (
    <div className="row box">
      <div className="col-md-12">
        <p className="StepTitle">
        Job Info {props.record.id > 1 ? "-" : ""}{" "}
          {props.record.id > 1 ? props.record.id : ""}
        </p>
        <div className="row ">
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor="department">Department : </label>
              <Select
                id="department"
                name="department"
                value={(props.record.department !== null && props.record.department !== "") ? { value: props.record.department, label: props.record.department } : null}
                onChange={option => props.handleChangeAjobs(option, props.record.id, "department")}
                options={props.department
                  .filter((item) => item && item.label) // Filter out null and undefined elements
                  .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                }
                isSearchable={true} // Enables search functionality
                placeholder="Select an option"
                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5 
                filterOption={(option, searchText) =>
                  option.label.toLowerCase().includes(searchText.toLowerCase())
                } // Filter options based on the search text
                styles={customStyles} // Apply custom styles her
              />
              {/* <select
                id="department"
                name="department"
                className="Inputs"
                onChange={(e) =>
                  props.handleChangeAjob(
                    e,
                    props.record.id,
                    props.record.department
                  )
                }
                value={props.record.department}
                required
              >
                <option value="" disabled>
                  Please select department
                </option>
            
                {props.department}
              </select> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="forms ">
              <label htmlFor="exp_designation">Designation:</label>
              <Select
                id="exp_designation"
                name="exp_designation"
                value={(props.record.exp_designation !== null && props.record.exp_designation !== "") ? { value: props.record.exp_designation, label: props.record.exp_designation } : null}
                onChange={option => props.handleChangeAjobs(option, props.record.id, "exp_designation")}
                options={props.designation
                  .filter((item) => item && item.label) // Filter out null and undefined elements
                  .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                }
                isSearchable={true} // Enables search functionality
                placeholder="Select an option"
                maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5 
                filterOption={(option, searchText) =>
                  option.label.toLowerCase().includes(searchText.toLowerCase())
                } // Filter options based on the search text
                styles={customStyles} // Apply custom styles her
              />
              {/* <select
                id="exp_designation"
                name="exp_designation"
             
                className="Inputs"
                onChange={(e) =>
                  props.handleChangeAjob(
                    e,
                    props.record.id,
                    props.record.exp_designation
                  )
                }
                value={props.record.exp_designation}
                required
              >
                <option value="" disabled>
                  Please select Designation
                </option>
                {props.designation}
              </select> */}
            </div>
          </div>

        </div>
        {/* <div className="row">
            <div className="col-md-12">
            {props.error.department && (
                        <small className="error">{props.error.department}</small>
             )}
            </div>
        </div> */}
        <div className="row mb-4">
          <div className="col-md-12 d-flex justify-content-end">
            {props.record.id === 1 ? (
              ""
            ) : (
              <a 
                className="AddMore me-2" 
                onClick={(e) => props.admission_status === 1 ?  "" : 
                  props.DeleteAddJobinfo(props.record.id, props.record.department)
                }
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                <span>{props.record.length}</span>
                Delete JobInfo
              </a>
              
            )}

               {props.length > 1 && props.record.id === 1   ? (
              <a  
                className="AddMore me-2"
                onClick={() => props.admission_status === 1 ?  "" :
                  props.DeleteAddJobinfo(props.record.id, props.record.department)
                }
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                <span>{props.record.length}</span>
                Delete JobInfo
              </a>
              
            ) : (
              <a className="AddMore" disabled ={props.admission_status === 1 ? true : false} onClick={props.admission_status === 1 ?  "" : props.handleAddJobinfo}>
              <span className="material-icons download_icon align-middle">
                 add_circle
              </span>
                Add More JobInfo
            </a>

              
            )}
           
          {/* <a className="AddMore" onClick={props.handleAddJobinfo}>
              <span className="material-icons text-danger align-middle">
                 add_circle
              </span>
              Add More jobInfo
            </a> */}

          </div>
        </div>
      </div>
    </div>
  );
}
export default AddJobInfo;

