import { validateModFocus } from "./validateModFocus";

export const validateFields = (
  data,
  certificates,
  edu,
  step,
  AdmissionRef_validation,
  batchDetails,
  techQualification,
  appCastLable
) => {
  const errors = {};
  if (step === 1 && !data.FirstName) {
    errors.FirstName = "Please enter your first name";
  }
  if (step === 1 && !data.LastName) {
    errors.LastName = "Please enter your LastName";
  }
  if (step === 1 && !data.Fathername) {
    errors.Fathername = "Please enter your Father's Name/Guardian's Name";
  }
  if (step === 1 && !data.gender) {
    errors.gender = "Please choose gender";
  }
  if (step === 1 && !data.DOB) {
    errors.DOB = "Please enter your DOB";
  }
  if (step === 1 && !data.age) {
    errors.age = "Please enter your age";
  }
  if ((step === 1 && isNaN(data.age)) || data.age < 1 || data.age > 150) {
    errors.age = "Please enter a valid age between 18 and 60";
  }
  if (step === 1 && !data.MaritalStatus) {
    errors.MaritalStatus = "Please choose your MaritalStatus";
  }
  // ------------------
  if (step === 1 && !data.Profilephoto) {
    errors.Profilephoto = "Please upload Profilephoto";
  }
  // ---------------------
  if (step === 1 && !data.signaturePhoto) {
    errors.signaturePhoto = "Please upload Specimen Signature in Image format";
  }
  // ------------------
  if (step === 1 && !data.aadharNo) {
    errors.aadharNo = "Please enter Aadhar Number";
  }
  if (step === 1 && data.aadharNo && data.aadharNo.length < 12) {
    errors.aadharNo = "Aadhar No must be 12 digit";
  }
  if (step === 1 && data.aadharNo && data.aadharNo.length > 12) {
    errors.aadharNo = " Aadhar No must be 12 digit";
  }
  if (step === 1 && !data.aadharCardphoto) {
    errors.aadharCardphoto = "Please upload Pdf/Img format";
  }
  // -------------

  if (step === 1 && !data.Cast) {
    errors.Cast = "Please choose category";
  }
  if (step === 1 && appCastLable.length === 0) {
    errors.AppCast = "Please choose Application Category";
  }
  // -------------STEP 2-----------------

  // if (step === 2 && !data.Mobile) {
  //   errors.Mobile = "Please enter Mobile number";
  // }
  // if (step === 2 && data.Mobile && data.Mobile.length < 10) {
  //   errors.Mobile = "Please enter 10 digit Mobile number";
  // }
  // if (step === 2 && data.Mobile && !/^\d+$/.test(data.Mobile)) {
  //   errors.Mobile = "Please enter a valid Mobile number";
  // }
  // if (step === 2 && !data.WhatsAppNumber && data.ContactNumber === false) {
  //   errors.WhatsAppNumber = "Please enter WhatsApp Number";
  // }
  // if (
  //   step === 2 &&
  //   data.WhatsAppNumber &&
  //   data.WhatsAppNumber.length < 10 &&
  //   data.ContactNumber === false
  // ) {
  //   errors.WhatsAppNumber = "Please enter 10 digit WhatsApp Number";
  // }
  // if (
  //   step === 2 &&
  //   data.WhatsAppNumber &&
  //   !/^\d+$/.test(data.WhatsAppNumber) &&
  //   data.ContactNumber === false
  // ) {
  //   errors.WhatsAppNumber = "Please enter a valid WhatsApp number";
  // }
  // if (step === 2 && !data.ParentMobileNo) {
  //   errors.ParentMobileNo = "Please Enter Father/Guardian Phone Number";
  // }
  // if (step === 2 && data.ParentMobileNo && data.ParentMobileNo.length < 10) {
  //   errors.ParentMobileNo = "Please Enter Father/Guardian Phone Number";
  // }
  // if (step === 2 && data.ParentMobileNo && !/^\d+$/.test(data.ParentMobileNo)) {
  //   errors.ParentMobileNo = "Please Enter Father/Guardian Phone Number";
  // }
  // if (step === 2 && !data.emailaddress) {
  //   errors.emailaddress = "Please enter email address";
  // }
  // if (
  //   step === 2 &&
  //   data.emailaddress &&
  //   !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailaddress)
  // ) {
  //   errors.emailaddress = "Please enter a valid email address";
  // }

  // // ------------------
  // if (step === 2 && !data.BuildingNumberPresent) {
  //   errors.BuildingNumberPresent = "Please enter Building Number";
  // }
  // if (step === 2 && !data.StreetNamePresent) {
  //   errors.StreetNamePresent = "Please enter Street Name";
  // }
  // if (step === 2 && !data.TalukPresent) {
  //   errors.TalukPresent = "Please  enter Taluk";
  // }
  // if (step === 2 && !data.PostPresent) {
  //   errors.PostPresent = "Please  enter Post";
  // }
  // if (step === 2 && !data.AreaPresent) {
  //   errors.AreaPresent = "Please  enter Area";
  // }
  // if (step === 2 && !data.PincodePresent) {
  //   errors.PincodePresent = "Please  enter Pincode";
  // }
  // if (step === 2 && !/^[1-9][0-9]{5}$/.test(data.PincodePresent)) {
  //   errors.PincodePresent = "Please enter a valid Pincode";
  // }
  // if (step === 2 && !data.CityPresent) {
  //   errors.CityPresent = "Please  enter City";
  // }
  // if (
  //   step === 2 &&
  //   !data.BuildingNumberPermanent &&
  //   (data.SamePresentAddress === false || data.SamePresentAddress === undefined)
  // ) {
  //   errors.BuildingNumberPermanent = "Please enter Building Number";
  // }
  // if (
  //   step === 2 &&
  //   !data.StreetNamePermanent &&
  //   (data.SamePresentAddress === false || data.SamePresentAddress === undefined)
  // ) {
  //   errors.StreetNamePermanent = "Please enter Street Name";
  // }
  // if (
  //   step === 2 &&
  //   !data.AreaPermanent &&
  //   (data.SamePresentAddress === false || data.SamePresentAddress === undefined)
  // ) {
  //   errors.AreaPermanent = "Please  enter Area";
  // }
  // if (
  //   step === 2 &&
  //   !data.TalukPermanent &&
  //   (data.SamePresentAddress === false || data.SamePresentAddress === undefined)
  // ) {
  //   errors.TalukPermanent = "Please  enter Taluk";
  // }
  // if (
  //   step === 2 &&
  //   !data.PostPermanent &&
  //   (data.SamePresentAddress === false || data.SamePresentAddress === undefined)
  // ) {
  //   errors.PostPermanent = "Please  enter Post";
  // }
  // if (
  //   step === 2 &&
  //   !data.PincodePermanent &&
  //   (data.SamePresentAddress === false || data.SamePresentAddress === undefined)
  // ) {
  //   errors.PincodePermanent = "Please  enter Pincode";
  // }
  // if (
  //   step === 2 &&
  //   !/^[1-9][0-9]{5}$/.test(data.PincodePermanent) &&
  //   (data.SamePresentAddress === false || data.SamePresentAddress === undefined)
  // ) {
  //   errors.PincodePermanent = "Please enter a valid Pincode";
  // }
  // if (
  //   step === 2 &&
  //   !data.CityPermanent &&
  //   (data.SamePresentAddress === false || data.SamePresentAddress === undefined)
  // ) {
  //   errors.CityPermanent = "Please  enter City";
  // }
  // -----------------
  if (step === 3 && !data.category) {
    errors.category = "please Select Category";
  }
  if (step === 3 && !data.CourseCate) {
    errors.CourseCate = "please enter Course Category";
  }
  if (step === 3 && !data.classMode) {
    errors.classMode = "please choose class Mode";
  }
  if (step === 3 && !data.TeachMedium) {
    errors.TeachMedium = "please choose TeachMedium";
  }
  if (step === 3 && !data.districts) {
    errors.districts = "please enter district";
  }
  if (step === 3 && data.hallticket === "Yes" && !data.ExamRegNo) {
    errors.ExamRegNo = "Please Enter Exam Register Number";
  }
  if (
    step === 3 &&
    data.hallticket === "Yes" &&
    data.ExamRegNo &&
    typeof data.ExamRegNo === "string" &&
    data.ExamRegNo.length > 10
  ) {
    errors.ExamRegNo = "Please Enter Exam Register Number 10 digit";
  }
  if (step === 3 && data.hallticket === "Yes" && !data.hallExamPhoto) {
    errors.hallExamPhoto = "Please Upload File";
  }
  if (
    step === 3 &&
    data.hallticket === "Yes" &&
    data.hallExamPhoto &&
    data.hallExamPhoto.size > 2 * 1024 * 1024
  ) {
    errors.hallExamPhoto = "File size must be less than 2MB";
  }
  if (
    step === 3 &&
    batchDetails.length > 0 &&
    !batchDetails.some((batch) => batch.is_selected)
  ) {
    errors.batchDetails = "Please choose a Batch and Exam Detail";
  }
  if (
    step === 3 &&
    batchDetails.length > 0 &&
    batchDetails.some(
      (batch) => batch.exam_details && batch.exam_details.length === 0
    )
  ) {
    errors.batchDetails = "Please choose a Batch and Exam Detail";
  }
  if (
    step === 3 &&
    batchDetails.length > 0 &&
    batchDetails.some((batch) => batch.is_selected) &&
    batchDetails.some(
      (batch) => batch.exam_details && batch.exam_details.length > 0
    ) &&
    !batchDetails.some(
      (batch) =>
        batch.exam_details &&
        batch.exam_details.some((exam) => exam.is_selected_exam === true)
    )
  ) {
    errors.batchDetails = "Please choose a Batch and Exam Detail";
  }
  if (
    step === 3 &&
    batchDetails.length > 0 &&
    !batchDetails.some((batch) => batch.is_selected) &&
    batchDetails.some(
      (batch) => batch.exam_details && batch.exam_details.length > 0
    ) &&
    !batchDetails.some(
      (batch) =>
        batch.exam_details &&
        batch.exam_details.some((exam) => exam.is_selected_exam)
    )
  ) {
    errors.batchDetails = "Please choose a Batch and Exam Detail";
  }

  if (step === 3 && !data.AIASAStudent) {
    errors.AIASAStudent = "Please select value for AIASA Student";
  }
  if (step === 3 && !data.Year && data.AIASAStudent === "Yes") {
    errors.Year = "Please enter Year";
  }
  if (
    step === 3 &&
    data.Year &&
    !/^\d{4}$/.test(data.Year) &&
    data.AIASAStudent === "Yes"
  ) {
    errors.Year = "Please enter a valid year";
  }
  if (step === 3 && !data.CategoryAias && data.AIASAStudent === "Yes") {
    errors.CategoryAias = "Please Choose Category";
  }
  if (step === 3 && !data.CourseAias && data.AIASAStudent === "Yes") {
    errors.CourseAias = "Please choose Course";
  }
  if (step === 3 && !data.IDNumber && data.AIASAStudent === "Yes") {
    errors.IDNumber = "Please enter ID Number";
  }
  if (
    step === 3 &&
    data.IDNumber &&
    typeof data.IDNumber === "string" &&
    data.IDNumber.length > 20 &&
    data.AIASAStudent === "Yes"
  ) {
    errors.IDNumber = "Please enter maximum 10 number only";
  }
  if (step === 3 && data.AIASAStudent === "Yes" && !data.IDPhoto) {
    errors.IDPhoto = "Please choose ID Photo";
  }
  if (
    step === 3 &&
    data.AIASAStudent === "Yes" &&
    data.IDPhoto &&
    data.IDPhoto.size > 2 * 1024 * 1024
  ) {
    errors.IDPhoto = "File size must be less than 2MB";
  }

  validateModFocus(
    data,
    certificates,
    edu,
    step,
    AdmissionRef_validation,
    batchDetails,
    appCastLable
  );
  return errors;
};
