// ModalComponent.js
import React from "react";
import "./Modelcss.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { validateMainHall } from "../../shared/Component/BackOffice/Faculty/validateTeachingProgress";
import { AxiosInstance } from "../../axios";
import Swal from "sweetalert2";
import { ImageUrlPrefix } from "../../labels";
import DatePickerSample from "../../shared/Component/DatePicker";
import moment from "moment";

function ModelCompontent({ show, showModelData, handleClose, loadListdata }) {
  const [formData, setFormData] = useState({
    examNumber: null,
    hallTicket: null,
    original_mainexam_hallticket: null,
    examDate: "",
  });
  const navigate = useNavigate();
  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "examNumber") {
      // console.log(formData, "formData");
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "hallTicket" && files && files.length > 0) {
      const gov_hall_ticket = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 3 * 1024 * 1024) {
          const formDataS = new FormData();
          formDataS.append("gov_hall_ticket", gov_hall_ticket);
          AxiosInstance.post("api/upload/", formDataS)
            .then((res) => {
              setFormData({
                ...formData,
                hallTicket: res.data.path,
                original_mainexam_hallticket: res.data.originalname,
              });
              setError({
                ...error,
                hallTicket: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 3MB.";
          setError({
            ...error,
            hallTicket: err,
          });
        }
      } else {
        let err = "pdf files only allowed";
        setError({
          ...error,
          hallTicket: err,
        });
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const handleMainHallThicket = (event) => {
    // console.log(formData, "formData.examNumber");
    event.preventDefault();
    const errors = validateMainHall(formData);
    if (Object.keys(errors).length === 0) {
      try {
        const dat = {
          main_exam_reg_no: formData.examNumber,
          mainexam_hallticket_filepath: formData.hallTicket,
          original_mainexam_hallticket: formData.original_mainexam_hallticket,
          user_id: showModelData.user_id,
          batch_id: showModelData.batch_id,
          admission_id: showModelData.admission_id,
          exam_id: showModelData.exam_id,
        };
        AxiosInstance.post("api/user/mainExam/add", dat)
          .then((res) => {
            if (res.data.status === true) {
              const path = res.data.path;
              if (path) {
                window.open(path, "_blank");
              }
              handleClose();
              loadListdata();
            } else {
              Swal.fire({
                title: "Success",
                text: res.data.message,
                icon: "Success",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            return error;
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 class="modal-title modelHallTicketTitle" id="exampleModalLabel">
            Upload Your TNPSC Hall Ticket
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 mt-2 mb-2">
              <div className="forms ">
                <p className="alert_info_ReviewLabel">
                  <strong> NOTE: </strong> Please upload the hall ticket issued
                  by the recruitment board. Only then can you download your
                  AIASA's hall ticket for the model exam.
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-8  mb-3">
              <div className="forms ">
                <label htmlFor="examNumber" className="form-labels ms-0">
                  Registration Number of the Exam
                </label>
                <input
                  type="number"
                  class="Inputss ms-0"
                  value={formData.examNumber}
                  onChange={handleChange}
                  id="examNumber"
                  name="examNumber"
                  placeholder="Registration Number of the Exam"
                />
                {error.examNumber && (
                  <p className="errors_main ms-0">{error.examNumber}</p>
                )}
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mb-3">
              <div className="forms ">
                <label htmlFor="hallTicket" className="form-labels ms-0">
                  Upload Hall Ticket Issued by the Recruitment Board (In pdf
                  format)
                  {formData.hallTicket !== "" &&
                    formData.hallTicket !== null &&
                    formData.original_mainexam_hallticket !== null && (
                      <a
                        href={`${ImageUrlPrefix}/${formData.hallTicket}`}
                        target="_blank"
                        download
                      >
                        <span className="material-icons align-middle download_icon">
                          file_download
                        </span>
                      </a>
                    )}
                </label>
                <div className="row">
                  <div
                    className={
                      formData.hallTicket !== "" && formData.hallTicket !== null
                        ? "col-md-3 "
                        : "col-md-12"
                    }
                  >
                    <input
                      type="file"
                      class={
                        formData.hallTicket !== "" &&
                        formData.hallTicket !== null
                          ? "InputssFile nn_upload_widthHall  ms-0"
                          : "InputssFile  vv_upload_width  ms-0"
                      }
                      onChange={handleChange}
                      id="hallTicket"
                      name="hallTicket"
                      placeholder="Upload Main Exam Hall Ticket"
                    />
                  </div>
                  {formData.hallTicket !== "" &&
                    formData.hallTicket !== null && (
                      <div
                        className={"col-md-9 d-flex align-content-center p-0"}
                      >
                        <span className="nn_upload_file">
                          {formData.hallTicket !== "" &&
                          formData.hallTicket !== null
                            ? formData.original_mainexam_hallticket
                            : ""}
                        </span>
                        <span className="material-icons align-middle text-danger verified_upload">
                          verified
                        </span>
                      </div>
                    )}
                </div>

                {error.hallTicket && (
                  <p className="errors_main ms-0">{error.hallTicket}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link
          data-bs-dismiss="modal"
          onClick={handleClose}
          className="DashboardCancelButton"
        >
          Back to Transaction History
        </Link>
        <button
          type="button"
          className="DashboardButton"
          onClick={handleMainHallThicket}
        >
          <a href="javascript:void(0)">Download AIASA's Hall Ticket</a>
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModelCompontent;
