import React, { useEffect, useState } from "react";
import "./BatchUpdate.css";
import { Error } from "../../../../Themes/themes";
import { AxiosInstance } from "../../../../axios/index";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { PersonSearch } from "@mui/icons-material";
import { LaptopChromebook } from "@mui/icons-material";
import { Today } from "@mui/icons-material";
import { Alarm } from "@mui/icons-material";
import { CurrencyRupee } from "@mui/icons-material";
import { ImageUrlPrefix } from "../../../../labels";
import Swal from "sweetalert2";
function BatchEdit() {
  const [editbatch, setEditbatch] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [batch, setBatch] = useState([]);
  const [stId, setStId] = useState({
    stId: "",
  });

  const navigate = useNavigate();
  const [coures, setCoures] = useState([]);
  // setting error value for all input fields
  const [error, setError] = useState({});
  const [initialfields, setInitialfields] = useState([
    {
      studentid: "",
      courseCategory: "",
    },
  ]);
  useEffect(() => {
    if (initialfields.courseCategory !=="") {
      AxiosInstance.post("api/batch/student/batchlist", {
        student_id: initialfields.student_id,
        course_id: initialfields.courseCategory,
        student_user_id: stId.stId,
      })
        .then((res) => {
          const batchInfo = res.data.data;
          setBatch(batchInfo);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [initialfields.courseCategory]);

  // validate form fields and setting error
  const errors = {};

  const validate = (data) => {
    const currentDate = new Date();
    const selectedDate = new Date(data.startDate);

    if (data.studentid == "") {
      errors.studentid = "Please Enter Student id.";
    }
    // else if(data.courseCategory==""){
    //   errors.courseCategory = 'Please select course Category.';
    // }
    // else if(data.subCategory==""){
    //   errors.subCategory = 'Please select Sub Category.';
    // }
    // else if(data.testMode==""){
    //     errors.testMode = 'Please select Test Mode.';
    // }
    // else if(data.fees==""){
    //     errors.fees = 'Please Enter Fees Amount.';
    // }
    // else if(data.fees.length >= 10){
    //     errors.fees = 'Fees amount length exists';
    // }
    // else if(data.fees <= 0){
    //     errors.fees = 'Fees should be greater than 0.';
    // }
    // else if(data.duration==""){
    //     errors.duration = 'Please Select Duration.';
    // }
    // else if(data.startDate==""){
    //     errors.startDate = 'Please Select start Date.';
    // }
    // else if (selectedDate.toDateString() === currentDate.toDateString()) {
    // errors.startDate = "Start date cannot be today's date";
    // }
    // else if(selectedDate < currentDate){
    //     errors.startDate = 'Start Date cannot be in the past.';
    // }
    return errors;
  };

  /**
   * Handle change event for password fields.
   * Updates the corresponding field value in state based on the input field's name.
   * @param {object} event - The change event object.
   * @param {string} event.target.name - The name of the input field.
   * @param {string} event.target.value - The value of the input field.
   */
  const handleChange = (event, index) => {
    const { name, value, checked } = event.target;
    // console.log(name, value, "...handlechange");

    if (name === "studentid") {
      setInitialfields({
        ...initialfields,
        [name]: value,
      });
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "courseCategory") {
      // console.log(value, "courescategory");
      setInitialfields({
        ...initialfields,
        [name]: value,
      });
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const handleBatch = (batchId, courseId, e) => {
    const { name, value, files, checked } = e.target;
    // console.log(name);
    setBatch((prevState) => {
      return prevState.map((q) => {
        if (q.batch_id === batchId && q.course_id === courseId) {
          return { ...q, [name]: checked };
        } else {
          return { ...q, [name]: false };
        }
        return q;
      });
    });
  };


//handle reset function 
const handleReset = (e) => {
    e.preventDefault();
      setInitialfields({
        studentid: "",
        courseCategory: "",
    });





}


  /**
   * Handles submission of the form when form submits.
   * Saves the updated form values to local storage.
   * @param {Object} e - The form submission event.
   */

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(editbatch);
    if (Object.keys(errors).length === 0) {
      try {
        batch
          .filter((item) => item.isSelected === true)
          .map((el) =>
            AxiosInstance.post("api/batch/student/batchupdate", {
              student_user_id: stId.stId,
              course_id: el.course_id,
              batch_id: el.batch_id,
            })
              .then((res) => {
                // console.log(res);
                Swal.fire({
                  title: "Success",
                  text: "Created Successfully",
                  icon: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/batch");
                  }
                });
              })
              .catch((error) => {
                return error;
              })
          );
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  const handlesearch = () => {

    if (initialfields.studentid !=="") {
      AxiosInstance.post("api/batch/student/courselist", {
        student_id: initialfields.studentid,
      })
        .then((res) => {
          // console.log(res);
          const coures = res.data.data.map((item, index) => (
            <option key={index} value={item.course_id}>
              {item.category_code}-{item.subcategory_code}-{item.course_name}
            </option>
          ));
          setCoures(coures);

          const stuId = res.data.data[0].student_id;
          setStId({
            stId: stuId,
          });
        })
        .catch((error) => {
          return error;
        });
    }
  };

  // console.log(batch, "batchList");

  return (
    <div className="container-fluid updateBatch mt-5 mb-5 ">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne ">
          <div className="row m-3 mb-2">
              <div className="col-md-3">
              {/* <Link to="/classedit" className="SaveButton_disabled" disabled>
                Create 
              </Link> */}
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3">
               
              </div>
              <div className="col-md-3">
                <Link to="/batch" className="SaveButton">
                Batch List
                </Link>
              </div>
            </div>
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Search Student ID</p>
                <div className="row mb-5">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="studentid">Student id:</label>
                      <div class="input-group  ms-3 mt-2">
                        <span class="input-group-text" id="basic-addon1">
                          <span
                            className="material-icons"
                            onClick={handlesearch}
                          >
                            search
                          </span>
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="studentid"
                          name="studentid"
                          placeholder="Enter Student id"
                          value={initialfields.studentid}
                          onChange={handleChange}
                        />
                      </div>

                      {error.studentid && (
                        <small className="error">{error.studentid}</small>
                      )}
                    </div>
                  </div>

                  {coures.length > 0 && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="Course">Course:</label>
                        <select
                          class="Inputs"
                          id="courseCategory"
                          name="courseCategory"
                          onChange={handleChange}
                          value={initialfields.courseCategory}
                        >
                          <option value="select" disabled selected>
                            Select Course Category ---{" "}
                          </option>
                          {coures}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {batch && batch.length > 0 && (
              <div className="row box">
                <p className="StepTitle">Update Batch Details</p>
                <div className="col-md-12">
                  <div className="row">
                    {batch.map((item, index) => (
                      <div className="col-md-12 mt-3 mb-3" key={index}>
                        <div className="card  text-dark">
                          <div className="card-body BatchImage">
                            <div className="row">
                              <div className="col-lg-2 col-md-6 col-sm-12">
                                <img
                                  src={`${ImageUrlPrefix}/${item.img_url}`}
                                  id={index}
                                  alt="Imge"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column ImageText  justify-content-center">
                                <p className="title m-0 ">
                                  {item.subcategory !==null
                                    ? item.subcategory
                                    : ""}{" "}
                                  {item.category_class}
                                </p>
                                <p className="subtitle">
                                  {item.batch_type} - {item.start_time} -{" "}
                                  {item.end_time}
                                </p>
                              </div>
                              <div className="col-lg-6 col-md-10 col-sm-12 col-xl-6">
                                <div className="row">
                                  <div className="col-md-12 col-sm-12 ">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            laptop_chromebook
                                          </span>
                                          <div className="d-flex flex-column">
                                            <p className="title ms-1">
                                              className Mode
                                            </p>
                                            <p className="subtitle ms-1">
                                              {item.class_mode}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                      <div className="col-md-4">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            event
                                          </span>
                                          <div className="d-flex flex-column ">
                                            <p className="title ms-1">
                                              Starts On
                                            </p>
                                            <p className="subtitle ms-1">
                                              {item.started_dt}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                      <div className="col-md-4">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            timer
                                          </span>
                                          <div className="d-flex flex-column">
                                            <p className="title ms-1">
                                              Duration
                                            </p>
                                            <p className="subtitle ms-1">
                                              {item.duration} Months
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="border mt-3"></span>
                                  <div className="col-md-12 col-sm-12">
                                    <div className="row mt-2">
                                      <div className="col-md-4">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            currency_rupee
                                          </span>
                                          <div className="d-flex flex-column">
                                            <p className="title ms-1">
                                              Actual Course Fee
                                            </p>
                                            <p className="subtitle ms-1">
                                              {item.fees}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                      <div className="col-md-4">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            currency_rupee
                                          </span>
                                          <div className="d-flex flex-column">
                                            <p className="title ms-1">
                                              Special Offer
                                            </p>
                                            <p className="subtitle ms-1">
                                              {item.discount}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                      <div className="col-md-4">
                                        <li className="IconsImage">
                                          <span className="material-icons">
                                            currency_rupee
                                          </span>
                                          <div className="d-flex flex-column">
                                            <p className="title ms-1">
                                              Discount
                                            </p>
                                            <p className="subtitle ms-1">
                                              {item.discounted_fees}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-1 col-xl-1 col-md-2 col-sm-12 mt-2 d-flex justify-content-start justify-content-sm-start  ">
                                <input
                                  className="checkbox radiobutton"
                                  type="radio"
                                  name="isSelected"
                                  id="inlineRadio1"
                                  defaultChecked={
                                    item.isSelected === 1 ? true : false
                                  }
                                  onChange={(e) =>
                                    handleBatch(
                                      item.batch_id,
                                      item.course_id,
                                      e
                                    )
                                  }
                                  value={item.isSelected}
                                />
                                <label
                                  className="checkbox-label ms-2 "
                                  htmlFor="inlineRadio1"
                                  style={{
                                    alignSelf: "center",
                                  }}
                                >
                                  Select
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="row m-3 mb-5">
              <div className="col-md-3"></div>
              <div className="col-md-3"></div>
              <div className="col-md-3">
                <button type="button" className="SaveButton" onClick={handleReset}>
                  reset
                </button>
              </div>
              <div className="col-md-3">
                <button
                  type="button"
                  className="SaveButton"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default BatchEdit;
