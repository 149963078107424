import React, { useState, useEffect } from "react";
import { data } from "jquery";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";

import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import UploadMarkSheet from "./UploadMarkSheet";
import ICON_DOWNLOAD from "@mui/icons-material/Download";
import ICON_SORT from "@mui/icons-material/SwapVert";
import styledSD from "styled-components";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import toast from "react-hot-toast";
import Loader from "../../Loader";

const StyledContainer = styledSD.div`
  margin: 30px 20px;
`;
const StyledTable = styledSD.table`
  && {
    border: none;
    outline: none;
  }
`;
const StyledThead = styledSD.thead`
  margin-bottom: 10px;
`;
const StyledTh = styledSD.th`
  font-size: 13px;
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-bold);
  padding: 10px 2px;
`;
const StyledTd = styledSD.td`
  && {
    color: var(--color-text-primary);
    font-size: 13px;
    font-weight: var(--font-weight-medium);
    padding: 10px 2px;
  }
`;
const StyledIconButton = styledSD(IconButton)`
  && {
    padding: 3px;
    margin: 0;
    color: var(--color-brand-900);
  }
`;
const SORT_BY_NUMBER = 1;
const SORT_BY_STRING = 2;

function ViewModelExam() {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const [emails, setEmails] = useState([]);
  const USER_ID = JSON.parse(sessionStorage.getItem("user_id"));
  const STAFF_ID = JSON.parse(sessionStorage.getItem("userDetails"))?.id;

  const { id, type } = useParams();

  const location = useLocation();

  const currentDate = moment();
  const [isLoading, setIsLoading] = useState(false);
  const startOfDay = moment(currentDate).startOf("day");
  const tenAM = startOfDay.clone().hour(10);
  const [selectedMem, setSelectedMem] = useState([]);
  const [selectedStaffMem, setSelectedStaffMem] = useState([]);
  const [viewModelExamCentreData, setViewModelExamCentreData] = useState({
    basicInfo: {},
    examDetail: {},
    examCentredetails: {},
    attendanceOverview: {},
  });
  const [topStudents, setTopStudents] = useState([]);
  const [isAscending, setIsAscending] = useState(true); //true->ascending false-descending
  const userJSON = sessionStorage.getItem("userDetails");
  const detailsProps = sessionStorage.getItem("detailsio");
  let user_type = null;
  let staff_id = null;
  let userid = null;
  let detailsio = {};
  if (userJSON) {
    const user = JSON.parse(userJSON);
    const detaill = JSON.parse(detailsProps);
    if (user && user.user_id) {
      userid = user.user_id;
      staff_id = user.id;
      detailsio = detaill;
    }
    if (user && user.user_type) {
      user_type = user.user_type;
    }
  } else {
    console.log("user not available in session storage");
  }
  // console.log("laksh", detailsio);
  const [formFields, setFormFields] = useState({
    Name: "",
    Section: "",
    Category: "",
    Course: "",
    category_course_id: "", //new change
    subCate: "",
    batchImg: null,
    original_img_url: null,
    ClassMode: "",
    MediumInstruction: "",
    SchedulePreference: "",
    StartDate: "",
    endDate: "",
    Time: "",
    endTime: "",
    Duration: "",
    NoStudents: "",
    NoTest: "",
    subject: "",
    TestPdf: null,
    original_test_img_url: null,
    exampassed: "",
    EnrollStu: "",
    Fees: "",
    Discount: "",
    OldsDiscount: "",
    OldSBatch: "",
    message: "",
    location: "",
    LastDateApply: false,
    ask_core_exam: "",
    subject_file_path: null,
    original_subject_file_path: null,
    pPayment: "",
    perOne: "",
    perTwo: "",
    perTwoDate: "",
    perThree: "",
    perThreeDate: "",
    Papers: "",
    NModelExam: "",
    MNPapers: "",
    MExamTitle: "",
  });

  const [selectedDistrict, setSelectDistrict] = useState([]);
  const [examWiseEnroll, setExamWiseEnroll] = useState([]);
  const [openHallModal, setOpenHallModal] = useState(false);
  // panel details for mock interview

  const [mockPanel, setMockPanel] = useState([
    {
      id: 1,
      panel_name: "",
      available_dates: [],
      panelMem: [],
      staffMem: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
    },
  ]);

  // panel details for Counselling

  const [cPanel, setCPanel] = useState([
    {
      id: 1,
      panel_name: "",
      date: "",
      StartTime: "",
      EndTime: "",
      department: [],
      CPaneMem: [],
      CStaffM: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
      error_6: "",
      error_7: "",
      error_8: "",
    },
  ]);

  // const convertSecondsToTime = (totalSeconds) => {
  //   const hours = Math.floor(totalSeconds / 3600);
  //   const remainingSeconds = totalSeconds % 3600;
  //   const minutes = Math.floor(remainingSeconds / 60);

  //   if (minutes > 0) {
  //     return `${hours}.${minutes.toString().padStart(2, "0")}`;
  //   } else {
  //     return `${hours}`;
  //   }
  // };
  const convertSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    const decimalMinutes = minutes / 60;
    const decimalTime = hours + decimalMinutes;

    return decimalTime.toFixed(2);
  };

  const [mExamSF, setMExamSF] = useState([
    {
      id: 1,
      NoStudentBatch: "",
      SOfferDis: 0,
      selectedOptions: [],
      ApplicableBatch: [],
      MEABatch: "",
      StudentBatchCount: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
    },
  ]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    ViewApi(detailsio);
  }, []);

  const ViewApi = (def) => {
    const locationData = def;

    const formData = {
      batch_id: locationData.batch_id,
      event_pk: locationData.event_pk,
      exam_detail_cstm_pk: locationData.exam_detail_cstm_pk,
      exam_center_cstm_pk: locationData.exam_center_cstm_pk,
      center_staff_user_id: STAFF_ID,
      paper_type: locationData.paper_type,
      created_by: USER_ID,
      type: 4,
    };
    if (locationData.paper_type) {
      // send the key only if the paper_type is 1 otherwise don't send
      formData.exam_detail_paper_pk = locationData.exam_detail_paper_pk;
    }
    setIsLoading(true);
    AxiosInstance.post("api/batch/meSupervisor/view", formData)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          const data = res.data.data;
          let examData = {};
          if (data.basic_info.paper_type === 1) {
            examData = {
              name: data.batch_exam_details.paper_detail[0].paper_title,
              date: data.batch_exam_details.paper_detail[0].start_dt,
              duration: convertSecondsToTime(
                data.batch_exam_details.paper_detail[0].duration
              ),
              startTime: data.batch_exam_details.paper_detail[0].start_time,
              endTime: data.batch_exam_details.paper_detail[0].end_time,
              uploadedExamSyllabusOriginal:
                data.batch_exam_details.paper_detail[0]
                  .original_syllabus_filename,
              uploadedExamSyllabus:
                data.batch_exam_details.paper_detail[0].syllabus_path,
              examCount: data.batch_exam_details.exam_count,
              paperCount: data.batch_exam_details.paper_detail[0].paper_count,
            };
          } else {
            examData = {
              name: data.batch_exam_details.exam_title,
              date: data.batch_exam_details.started_dt,
              duration: convertSecondsToTime(data.batch_exam_details.duration),
              startTime: data.batch_exam_details.start_time,
              endTime: data.batch_exam_details.end_time,
              uploadedExamSyllabusOriginal:
                data.batch_exam_details.original_syllabus_url,
              uploadedExamSyllabus: data.batch_exam_details.syllabus_url,
              examCount: data.batch_exam_details.exam_count,
            };
          }
          const basicInfo = {
            categoryCode: data.basic_info.category_code,
            courseName: data.basic_info.course_name,
            paperType: data.basic_info.paper_type,
            name: data.basic_info.name,
            medium: data.basic_info.medium,
            classMode: data.basic_info.class_mode,
            marksheetFrame: data.basic_info.mark_sheet_frame,
            updatedMarkSheet: data.basic_info.marksheet_file_path,
            updatedMarkSheetOriginal:
              data.basic_info.original_marksheet_file_path,
            batchImage: data.basic_info.img_url,
            attendance_file_path: data.basic_info.attendance_file_path
              ? data.basic_info.attendance_file_path
              : null,
            req_attendance_redwnld: data.basic_info.req_attendance_redwnld,
            redwnld_req_approved: data.basic_info.redwnld_req_approved,
          };
          const examDetail = examData;
          const examCentredetails = {
            name: data.batch_exam_center.exam_center_name,
            count: data.batch_exam_center.max_count,
            enrollCount: data.batch_exam_center.enroll_cnt,
            location_url: data.batch_exam_center.location_url,
          };
          const attendanceOverview = {
            totalStudent:
              data.attendance_overview.tot_stud === 0
                ? updateListData.length
                : data.attendance_overview.tot_stud,
            present: data.attendance_overview.present,
            absent: data.attendance_overview.absent,
          };
          const tempState = {
            basicInfo,
            examDetail,
            examCentredetails,
            attendanceOverview,
          };
          const topStud = data.top_3_stud || [];
          const modifiedTopStud = topStud.map((student, index) => ({
            ...student,
            serialNumber: index + 1,
          }));
          setViewModelExamCentreData(tempState);
          setTopStudents(modifiedTopStud);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [eventFields, setEventFields] = useState([]);

  //chip data list view
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  // use state
  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [totalStudents, setTotalStudents] = useState(0);
  const [updateChipData, setUpdateChipData] = useState([]);
  const [isOpen, setIsOpen] = useState({
    mode: false,
    gender: false,
    exam_district: false,
  });

  const [dexamD, setDexamD] = useState([]);
  const [dexamDS, setDexamDS] = useState([]);

  function tableHandleSort(name, type = SORT_BY_NUMBER) {
    // type=1->number,2->string
    if (topStudents) {
      if (isAscending) {
        let sortedStudents = [];
        if (type === SORT_BY_NUMBER) {
          sortedStudents = topStudents.sort((a, b) => b[name] - a[name]);
        } else {
          sortedStudents = [...topStudents].sort((a, b) => {
            if (a[name] < b[name]) return -1;
            if (a[name] > b[name]) return 1;
            return 0;
          });
        }

        setIsAscending((prevState) => !prevState);
        setTopStudents([...sortedStudents]);
      } else {
        let sortedStudents = [];
        if (type === SORT_BY_NUMBER) {
          sortedStudents = topStudents.sort((a, b) => a[name] - b[name]);
        } else {
          sortedStudents = [...topStudents].sort((a, b) => {
            if (a[name] < b[name]) return 1;
            if (a[name] > b[name]) return -1;
            return 0;
          });
        }
        setIsAscending((prevState) => !prevState);
        setTopStudents([...sortedStudents]);
      }
    } else {
      console.error("un-handle type in tableHandleSort");
    }
  }

  const [examDetail, setExamDetails] = useState([
    {
      id: 1,
      title: "",
      exam_title_id: "",
      pk: "",
      date: "",
      duration: "",
      startTime: "",
      endTime: "",
      syllabus: null,
      original_syllabus_url: null,
      hall_ticket_status: "",
      LastDateApply: "",
      NPapers: "",
      PaperDetailsSection: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
      error_6: "",
      error_7: "",
    },
  ]);

  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  const [districtWise, setDistrictWise] = useState([
    {
      id: 1,
      pk: "",
      district: "",
      districtCode: "",
      enroll_count: [],
      examCenter: [
        {
          ExamCenter_id: 1,
          examCenterOption: [],
          exam_center: "",
          MaxCount: 0,
          locationUrl: "",
          enroll_count: [],
          error_3: "",
          error_4: "",
          error_5: "",
        },
      ],
      error_1: "",
      error_2: "",
    },
  ]);
  const [oldBatch, setOldBatch] = useState([
    {
      id: 1,
      OldDPercentage: "",
      BatchRFrom: "",
      BatchRTo: "",
      selectedOptions: [],
      ApplicableBatch: [],
    },
  ]);

  useEffect(() => {
    // Get all "Show More" buttons within elements having the class "rbc-today"
    const showMoreButtons = document.querySelectorAll(
      ".rbc-today .rbc-button-link.rbc-show-more"
    );

    // Iterate through the "Show More" buttons within "rbc-today" and add the class
    showMoreButtons.forEach((element) => {
      element.classList.add("rbc_show_more_today");
    });
  }, []);

  const [mode, setMode] = useState([]);
  const [gender, setGender] = useState([]);
  const [exam_district, setExam_district] = useState([]);
  let filterSearch = [];
  let filterMode = [];
  let filterGender = [];
  let filterexamD = [];

  useEffect(() => {
    filterMode = mode;
  }, [mode]);
  useEffect(() => {
    filterGender = gender;
  }, [gender]);
  useEffect(() => {
    filterexamD = exam_district;
  }, [exam_district]);
  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  const handleFilterCourseSearch = (event) => {
    const searchInput = event.target.value;

    if (searchInput !== "") {
      // console.log(searchInput, "searchInput", dexamD);
      const matchingObjects = dexamDS.filter(
        (item) =>
          item.label &&
          item.label.toLowerCase().includes(searchInput.toLowerCase())
      );
      // console.log(matchingObjects, "matchingObjects");
      // Do something with the matchingObjects, such as updating state or displaying them in the UI
      setDexamD(matchingObjects);
    } else {
      // setDexamDS(dexamD);
      setDexamD(dexamDS);
    }
  };
  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "mode") {
      setMode([]);
      filterMode = [];
      setIsOpen({
        ...isOpen,
        mode: false,
      });
    } else if (name === "gender") {
      setGender([]);
      filterGender = [];
      setIsOpen({
        ...isOpen,
        gender: false,
      });
    } else if (name === "exam_district") {
      setExam_district([]);
      filterexamD = [];
      setIsOpen({
        ...isOpen,
        exam_district: false,
      });
    }
    loadChipdata();
  };
  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setMode([]);
    setGender([]);
    setExam_district([]);
    loadChipdata();
    // setUpdateListData(listData);
  };

  const getExamCenterView = (value) => {
    return AxiosInstance.post("api/examcenter/name", { district: value })
      .then((res) => res.data.data)
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    if (name === "Classroom" || name === "Online") {
      if (checked) {
        // setStatus([...status, value]);
        setMode([...mode, { id: value, name: name, type: "mode" }]);
      } else {
        setMode(mode.filter((item) => item.id !== value));
      }
    } else if (name === "male" || name === "female" || name === "transgender") {
      if (checked) {
        // setStatus([...status, value]);
        setGender([...gender, { id: value, name: name, type: "gender" }]);
      } else {
        setGender(gender.filter((item) => item.id !== value));
      }
    } else if (names === "exam_district") {
      if (checked) {
        // setStatus([...status, value]);
        setExam_district([
          ...exam_district,
          { id: value, name: name, type: "exam_district" },
        ]);
      } else {
        setExam_district(exam_district.filter((item) => item.id !== value));
      }
    }
  };

  async function getOldBatchView(fTime, Ttime) {
    const dats = {
      start_date: fTime,
      end_date: Ttime,
    };

    try {
      const response = await AxiosInstance.post("api/batch/oldStud/list", dats);
      if (response.data.data.length > 0) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "mode") {
      setMode(mode.filter((item) => item.id !== chipToDelete.id));
      filterMode = mode.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "gender") {
      setGender(gender.filter((item) => item.id !== chipToDelete.id));
      filterGender = gender.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "exam_district") {
      setExam_district(
        exam_district.filter((item) => item.id !== chipToDelete.id)
      );
      filterexamD = exam_district.filter((item) => item.id !== chipToDelete.id);
    }
    loadChipdata();
  };

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "mode") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        mode: false,
      });
    } else if (name === "gender") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        gender: false,
      });
    } else if (name === "exam_district") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        exam_district: false,
      });
    }
  };

  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };

  function updateStateData() {
    // filterStatus = status;

    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  function loadListdata() {
    let tempMode = [];
    let tempGender = [];

    let tempEXDNumber = [];

    let tempExD = [];

    mode.map((obj) => {
      tempMode.push(obj.name);
    });
    gender.map((obj) => {
      tempGender.push(obj.name);
    });

    exam_district.map((obj) => {
      tempExD.push(obj.id);
    });

    tempEXDNumber = tempExD.map(Number);

    let statusFilter = {
      batch_id: detailsio.batch_id,
      event_pk: detailsio.event_pk,
      exam_center_cstm_pk: detailsio.exam_center_cstm_pk,
      exam_detail_cstm_pk: detailsio.exam_detail_cstm_pk,
      // page_no: currentPage,
      // page_size: rowsPerPage,
      page_no: "",
      page_size: "",
      search: searchTerm.length > 0 ? searchTerm : "",
    };
    AxiosInstance.post("api/batch/meSupervisor/view/studList", statusFilter)
      .then((res) => {
        if (res.data.status === true) {
          const fiata = res.data.data;
          // const fiataTotalstudent = res.data.total;
          const fiataTotalstudent =
            res.data.data.length > 0 ? res.data.data.length : res.data.total;
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          setUpdateListData(serialNumberAddedData);
          setTotalStudents(fiataTotalstudent);
        } else if (res.data.status === false) {
          setUpdateListData([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setUpdateListData([]);
        return error;
      });
  }

  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });

    filterMode.map((obj) => {
      tempArr.push(obj);
    });
    filterGender.map((obj) => {
      tempArr.push(obj);
    });
    filterexamD.map((obj) => {
      tempArr.push(obj);
    });

    setUpdateChipData(tempArr);
  }

  const handleFilter = (e, dame) => {
    // alert(dame,"dame");
    if (dame === "mode") {
      setIsOpen((prevState) => ({
        ...prevState,
        mode: !prevState.mode,
        gender: false,
        exam_district: false,
      }));
    } else if (dame === "gender") {
      setIsOpen((prevState) => ({
        ...prevState,
        gender: !prevState.gender,
        mode: false,
        exam_district: false,
      }));
    } else if (dame === "exam_district") {
      setIsOpen((prevState) => ({
        ...prevState,
        exam_district: !prevState.exam_district,
        gender: false,
        mode: false,
      }));
    }
  };

  // generate pdf when user click download button

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };

  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  // console.log(updateListData, "updateListData");
  const currentData = updateListData.slice(start, end);

  const startedDt = moment.utc(formFields.StartDate);

  const twelveAMNextDay = startedDt.clone().add(1, "day").startOf("day");

  const handleAttenSheet = (datten) => {
    AxiosInstance.post("api/batch/attendance/dwld", {
      batch_id: detailsio.batch_id,
      exam_detail_cstm_pk: detailsio.exam_detail_cstm_pk,
      exam_center_cstm_pk: detailsio.exam_center_cstm_pk,
      center_staff_user_id: staff_id,
      paper_type: detailsio.paper_type,
      reattempt: datten === 1 ? 1 : 0,
      exam_detail_paper_pk:
        detailsio.paper_type === 1 ? detailsio.exam_detail_paper_pk : "", // send the key only if the paper_type is 1 otherwise don't send
      created_by: userid,
    })
      .then((res) => {
        if (res.data.status === true) {
          const path = res.data.data;
          if (path !== "") {
            window.open(`${ImageUrlPrefix}/${path}`, "_blank");
          }
          ViewApi(detailsio);
          loadListdata();
        } else {
          Swal.fire({
            title: "Warning",
            text: res.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const hanldeReDownload = () => {
    AxiosInstance.post("api/batch/meSupervisor/attendance/req", {
      batch_id: detailsio.batch_id,
      exam_detail_cstm_pk: detailsio.exam_detail_cstm_pk,
      exam_center_cstm_pk: detailsio.exam_center_cstm_pk,
      center_staff_user_id: staff_id,
      paper_type: detailsio.paper_type,
      exam_detail_paper_pk:
        detailsio.paper_type === 1 ? detailsio.exam_detail_paper_pk : "",
      req_attendance_redwnld: 1, // send as 1 if u are requesting else dont call this pai
      updated_by: userid,
      type: 4,
    })
      .then((res) => {
        if (res.data.status === true) {
          ViewApi(detailsio);
          toast.success(`${res.data.message}`);
          loadListdata();
        } else {
          Swal.fire({
            title: "Warning",
            text: res.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handleOpenHallPopup = () => {
    setOpenHallModal(true);
  };

  const handleHallClose = () => {
    setOpenHallModal(false);
  };

  const handleViewApiAgain = () => {
    ViewApi(detailsio);
    loadListdata();
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  let dataMarksheetFramename = "";
  if (viewModelExamCentreData.basicInfo.marksheetFrame) {
    const arr = viewModelExamCentreData.basicInfo.marksheetFrame.split("/");
    dataMarksheetFramename = arr[arr.length - 1];
  }

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className=" BathDet">
      <div className="row">
        <div className="col-md-12">
          {openHallModal && (
            <UploadMarkSheet
              show={openHallModal}
              handleHallClose={handleHallClose}
              handleViewApiAgain={handleViewApiAgain}
              userid={userid}
              staff_id={staff_id}
              detail={detailsio}
            />
          )}
          {/* button container */}
          <section className="StepOne">
            <div className="heading  dflexbetww ">
              <div className="mt-2 dbetw">
                <h6 className="Title ">View Model Exam</h6>
                <Link
                  to={`/ModelExamCenter`}
                  className="CancelButtons MobileShow "
                >
                  Back to Model Exams
                </Link>
              </div>
              <div className="d-flex smButton mobiledown  mt-4">
                <Link
                  to={`/ModelExamCenter`}
                  className="CancelButtons MobileHide "
                >
                  Back to Model Exams
                </Link>

                <button
                  type="button"
                  className={
                    detailsio.batch_status === 0
                      ? `ListViewBtn DisabledListviS  me-3`
                      : `ListViewBtn  me-3`
                  }
                  disabled={detailsio.batch_status === 0 ? true : false}
                  onClick={() => handleOpenHallPopup()}
                >
                  <Link to={`javascript:void(0)`}>
                    <span className="material-icons me-1 fs-5 align-middle text-white">
                      upload
                    </span>
                  </Link>
                  <Link
                    disabled={detailsio.batch_status === 0 ? true : false}
                    onClick={() => handleOpenHallPopup()}
                  >
                    Upload Mark Sheet
                  </Link>
                </button>

                {viewModelExamCentreData.basicInfo.attendance_file_path ===
                  null ||
                (viewModelExamCentreData.basicInfo.req_attendance_redwnld ===
                  1 &&
                  viewModelExamCentreData.basicInfo.redwnld_req_approved ===
                    1 &&
                  viewModelExamCentreData.basicInfo.attendance_file_path !==
                    null) ? (
                  <button
                    type="button"
                    onClick={() =>
                      handleAttenSheet(
                        viewModelExamCentreData.basicInfo.req_attendance_redwnld
                      )
                    }
                    className="ListViewBtn  me-3"
                  >
                    <Link to={`javascript:void(0)`}>
                      <span className="material-icons me-1 fs-5 align-middle text-white">
                        file_download
                      </span>
                    </Link>

                    <Link>Download Attendance Sheet</Link>
                  </button>
                ) : (
                  <button
                    onClick={
                      viewModelExamCentreData.basicInfo
                        .req_attendance_redwnld === 0
                        ? () => hanldeReDownload()
                        : () => {}
                    }
                    type="button"
                    className={`ListViewBtn ${
                      viewModelExamCentreData.basicInfo
                        .req_attendance_redwnld === 0
                        ? ""
                        : "DisabledListviS"
                    } me-3`}
                  >
                    <Link to={`javascript:void(0)`}>
                      <span
                        className={`material-icons me-1 fs-5 align-middle text-white ${
                          viewModelExamCentreData.basicInfo
                            .req_attendance_redwnld === 0
                            ? ""
                            : "DisabledListviS"
                        }`}
                      >
                        file_download
                      </span>
                    </Link>

                    <Link
                      className={`${
                        viewModelExamCentreData.basicInfo
                          .req_attendance_redwnld === 0
                          ? ""
                          : "DisabledListviS"
                      }`}
                    >
                      Request Download Attendance Sheet Again
                    </Link>
                  </button>
                )}
              </div>
            </div>
            {/* Basic Info */}
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Basic Info</p>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-4 "
                          >
                            Category
                          </label>
                          <span className="ReviewLabel">
                            {viewModelExamCentreData.basicInfo.categoryCode}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-4 "
                          >
                            Course
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {viewModelExamCentreData.basicInfo.courseName}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-4 "
                          >
                            Single Papers / Combined Papers?
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {}
                            {viewModelExamCentreData.basicInfo.paperType === 0
                              ? "Single Papers"
                              : "Combined Papers" || ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-4 "
                          >
                            Name
                          </label>
                          <span className="ReviewLabel">
                            {viewModelExamCentreData.basicInfo.name}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-4 "
                          >
                            {type === "4"
                              ? "Medium of Exam"
                              : "Medium of Instruction"}
                          </label>
                          <span className="ReviewLabel">
                            {viewModelExamCentreData.basicInfo.medium}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-4 "
                          >
                            Mode of Exam
                          </label>
                          <span className="ReviewLabel">
                            {viewModelExamCentreData.basicInfo.classMode ===
                            "ClassRoom"
                              ? "Offline"
                              : "Online"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-5">
                    <div className="forms mt-4 mb-4  ">
                      <div id="batchImageSmall">
                        <img
                          src={`${ImageUrlPrefix}/${viewModelExamCentreData.basicInfo.batchImage}`}
                          alt="batch profile"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Model Exam 1 - Paper 1 Details */}
          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">
                  {" "}
                  {`Model Exam ${
                    viewModelExamCentreData.examDetail.examCount
                  } ${
                    viewModelExamCentreData.basicInfo.paperType === 1
                      ? `- Paper ${viewModelExamCentreData.examDetail.paperCount}`
                      : ""
                  } Details`}
                </p>
                {/* - Paper 1 Details */}
                <div className="row mt-2 mb-2">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Exam Title
                      </label>
                      <span className="ReviewLabel">
                        {viewModelExamCentreData.examDetail.name}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Date
                      </label>
                      <span className="ReviewLabel">
                        {moment(
                          viewModelExamCentreData.examDetail.date,
                          "DD-MM-YYYY"
                        ).format("DD/MM/YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Duration (in Hours)
                      </label>
                      <span className="ReviewLabel">
                        {viewModelExamCentreData.examDetail.duration}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Time
                      </label>
                      <span className="ReviewLabel">
                        {" "}
                        {moment(
                          viewModelExamCentreData.examDetail.startTime,
                          "h:mm:ss A"
                        ).format("h:mm A")}{" "}
                        to{" "}
                        {moment(
                          viewModelExamCentreData.examDetail.endTime,
                          "h:mm:ss A"
                        ).format("h:mm A")}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Uploaded Exam Syllabus
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${ImageUrlPrefix}/${viewModelExamCentreData.examDetail.uploadedExamSyllabus}`}
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <ICON_DOWNLOAD color="error" />
                        </a>
                      </label>
                      <span className="ReviewLabel">
                        {
                          viewModelExamCentreData.examDetail
                            .uploadedExamSyllabusOriginal
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Exam Centre Details */}
          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12 ">
                <p className="StepTitle">
                  Exam Center Details{" "}
                  {/* {districtWise.length > 0 ? `- ${el.id}` : ""} */}
                </p>
                <div className="row mb-2">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Name of the Exam Center
                      </label>
                      <span className="ReviewLabel">
                        {viewModelExamCentreData.examCentredetails.name}
                      </span>
                      <span className="ReviewLabelText">
                        {`(${viewModelExamCentreData.examCentredetails.location_url})`}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Max Count
                      </label>
                      <span className="ReviewLabel">
                        {viewModelExamCentreData.examCentredetails.count}
                      </span>
                    </div>
                  </div>
                  {viewModelExamCentreData.examCentredetails.enrollCount?.map(
                    (count, index) => (
                      <div className="col-md-2" key={index}>
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            {count.gender}
                          </label>
                          <span className="ReviewLabel">
                            {count.gender_cnt}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </section>
          {/* Student Details */}
          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Students Details</p>
                <div className="row">
                  <div className="col-lg-3 col-md-6 mb-2 ms-3 mt-3">
                    <div class="input-group">
                      <input
                        type="text"
                        class="Inputs mobileInput"
                        id="SearchInput"
                        name="SearchInput"
                        placeholder="Search by Reg.No"
                        onKeyDown={handleSearch}
                      />
                      <span class="input-group-text" id="SearchInput">
                        <span className="material-icons">search</span>
                      </span>
                    </div>
                  </div>
                </div>
                {updateChipData.length > 0 ? (
                  <div className="row mt-3">
                    <div
                      className="col-md-12 col-sm-12 col-xs-12 "
                      id="paperil"
                    >
                      <Paper
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul"
                      >
                        {updateChipData.map((data, index) => {
                          // console.log(data, " - data - ", index);
                          let icon;
                          let key = "" + data.name;
                          return (
                            <ListItem key={data}>
                              <Chip
                                icon={icon}
                                label={key}
                                onDelete={handleDelete(data, index)}
                              />
                            </ListItem>
                          );
                        })}

                        <ListItem>
                          <a
                            className="allclear"
                            href="javascript:void(0)"
                            onClick={handleAllClear}
                          >
                            Clear All Filters
                          </a>
                        </ListItem>
                      </Paper>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="row mt-2 ms-1 me-1">
                  <div className="col-md-12 table-responsive p-0">
                    <table className="table" id="example">
                      <thead>
                        <tr>
                          <th id="th">
                            {" "}
                            <span className="text_th ms-2">S.No</span>
                            <span
                              class="material-icons align-middle"
                              onClick={(e) => handleSort(e, "serialNumber")}
                            >
                              {" "}
                              import_export{" "}
                            </span>
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "aiasa_reg_no")}
                            >
                              {" "}
                              Exam Reg.No.
                            </span>
                            <span
                              class="material-icons align-middle"
                              onClick={(e) => handleSort(e, "aiasa_reg_no")}
                            >
                              {" "}
                              import_export{" "}
                            </span>{" "}
                          </th>

                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "marks_obtained")}
                            >
                              Marks Obtained
                            </span>
                            <span
                              class="material-icons align-middle mr-2"
                              onClick={(e) => handleSort(e, "marks_obtained")}
                            >
                              import_export
                            </span>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.length === 0 ? (
                          <tr>
                            <td colspan="10" style={{ textAlign: "center" }}>
                              No record to display
                            </td>
                          </tr>
                        ) : (
                          currentData.map((batch, index) => (
                            <tr key={index} title={batch.aiasa_reg_no}>
                              <td id="td">{batch.serialNumber}</td>
                              <td id="td">{batch.aiasa_reg_no}</td>
                              <td id="td">
                                {" "}
                                {batch.marks_obtained !== null
                                  ? batch.marks_obtained === 0
                                    ? "A"
                                    : batch.marks_obtained
                                  : "_"}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row mt-3 ms-1 me-1">
                  <div className="col-md-12">
                    <div className="pag w-100 dflexcenter">
                      <span className="ipage">Items Per Page</span>
                      <select
                        name="pageCount"
                        id="pageCount"
                        defaultValue={rowsPerPage}
                        onChange={handlePageCount}
                        className="ms-2"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      <span className="pagenumber ms-2">
                        {" "}
                        {updateListData.length > 0
                          ? currentPage * rowsPerPage + 1 - rowsPerPage
                          : 0}{" "}
                        -
                        {/* {currentPage * rowsPerPage} of {updateListData.length} */}
                        {updateListData.length < currentPage * rowsPerPage
                          ? updateListData.length
                          : currentPage * rowsPerPage}{" "}
                        of {updateListData.length}
                      </span>
                      <button
                        className="prev_button ms-2 me-2"
                        onClick={(e) => handlePrev(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <span class="material-icons">chevron_left</span>
                      </button>
                      <button
                        className="prev_button"
                        onClick={() => handleNext(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <span class="material-icons">chevron_right</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Student's Mark Sheet */}
          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Student's Mark Sheet </p>
                <div className="row mt-2 mb-2">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Mark Sheet Frame Set
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${ImageUrlPrefix}/${viewModelExamCentreData.basicInfo.marksheetFrame}`}
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <ICON_DOWNLOAD color="error" />
                        </a>
                      </label>
                      <span className="ReviewLabel">
                        {dataMarksheetFramename}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Updated Mark Sheet
                        {viewModelExamCentreData.basicInfo.updatedMarkSheet && (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`${ImageUrlPrefix}/${viewModelExamCentreData.basicInfo.updatedMarkSheet}`}
                            style={{ textDecoration: "none" }}
                          >
                            {" "}
                            <ICON_DOWNLOAD color="error" />
                          </a>
                        )}
                      </label>
                      <span className="ReviewLabel">
                        {viewModelExamCentreData.basicInfo
                          .updatedMarkSheetOriginal || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Top 3 Scorers */}
          {topStudents.length > 0 && (
            <section className="StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Top 3 Scorers</p>
                </div>
                <div className="col-12 p-0 m-0">
                  <StyledContainer className="">
                    <StyledTable>
                      <StyledThead>
                        <tr>
                          <StyledTh>
                            S.No.{" "}
                            <StyledIconButton
                              onClick={() => tableHandleSort("serialNumber")}
                            >
                              <ICON_SORT
                                style={{
                                  fontSize: "16px",
                                  color: true ? "rgba(4,17,52,0.5)" : "inherit",
                                }}
                              />
                            </StyledIconButton>
                          </StyledTh>
                          <StyledTh>
                            Name{" "}
                            <StyledIconButton
                              onClick={() =>
                                tableHandleSort("full_name", SORT_BY_STRING)
                              }
                            >
                              <ICON_SORT
                                style={{
                                  fontSize: "16px",
                                  color: true ? "rgba(4,17,52,0.5)" : "inherit",
                                }}
                              />
                            </StyledIconButton>
                          </StyledTh>
                          <StyledTh>
                            Exam Reg. No.{" "}
                            <StyledIconButton
                              onClick={() => tableHandleSort("aiasa_reg_no")}
                            >
                              <ICON_SORT
                                style={{
                                  fontSize: "16px",
                                  color: true ? "rgba(4,17,52,0.5)" : "inherit",
                                }}
                              />
                            </StyledIconButton>
                          </StyledTh>
                          <StyledTh>
                            Rank{" "}
                            <StyledIconButton
                              onClick={() => tableHandleSort("classrank")}
                            >
                              <ICON_SORT
                                style={{
                                  fontSize: "16px",
                                  color: true ? "rgba(4,17,52,0.5)" : "inherit",
                                }}
                              />
                            </StyledIconButton>
                          </StyledTh>
                          <StyledTh>
                            Marks Obtained{" "}
                            <StyledIconButton
                              onClick={() => tableHandleSort("marks_obtained")}
                            >
                              <ICON_SORT
                                style={{
                                  fontSize: "16px",
                                  color: true ? "rgba(4,17,52,0.5)" : "inherit",
                                }}
                              />
                            </StyledIconButton>
                          </StyledTh>
                        </tr>
                      </StyledThead>
                      <tbody>
                        {topStudents.map((student, index) => (
                          <tr
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#F7F8F9" : "#FFFFFF",
                            }}
                            key={student.serialNumber}
                          >
                            <StyledTd style={{ width: "10%" }}>
                              {student.serialNumber}
                            </StyledTd>
                            <StyledTd style={{ width: "30%" }}>
                              {student.full_name}
                            </StyledTd>
                            <StyledTd style={{ width: "30%" }}>
                              {student.aiasa_reg_no}
                            </StyledTd>
                            <StyledTd style={{ width: "20%" }}>
                              {student.classrank}
                            </StyledTd>
                            <StyledTd style={{ width: "10%" }}>
                              {student.marks_obtained !== null
                                ? student.marks_obtained === 0
                                  ? "A"
                                  : student.marks_obtained
                                : "-"}
                            </StyledTd>
                          </tr>
                        ))}
                      </tbody>
                    </StyledTable>
                  </StyledContainer>
                </div>
              </div>
            </section>
          )}

          {/* Attendance Overview */}
          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Attendance Overview</p>
                <div className="row mt-2 mb-2">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Total Number of Students Enrolled
                      </label>
                      <span className="ReviewLabel">
                        {viewModelExamCentreData.attendanceOverview
                          .totalStudent === 0
                          ? updateListData.length
                          : viewModelExamCentreData.attendanceOverview
                              .totalStudent}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Total Number of Students Present
                      </label>
                      <span className="ReviewLabel">
                        {viewModelExamCentreData.attendanceOverview.present ||
                          "_"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Total Number of Students Absent
                      </label>
                      <span className="ReviewLabel">
                        {viewModelExamCentreData.attendanceOverview.absent ??
                          "_"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ViewModelExam;
