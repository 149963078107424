import { configureStore } from "@reduxjs/toolkit";
import ChartReducer from "./ChartSlice";
import CentreSupervisorReducer from "./CentreSupervisorSlice";

const store = configureStore({
  reducer: {
    chart: ChartReducer,
    centreSupervisor: CentreSupervisorReducer,
  },
});

export default store;
