import { Skeleton } from "@mui/material";
import React from "react";
import CustomContainer from "./CustomContainer";

export default function SkeletonLoaderPage() {
  return (
    <div>
      <CustomContainer>
        <div className="row">
          <div className="col-4">
            <Skeleton
              variant="rounded"
              width="100%"
              height={250}
              animation="wave"
            />
          </div>
          <div className="col-4">
            <Skeleton
              variant="rounded"
              width="100%"
              height={250}
              animation="wave"
            />
          </div>
          <div className="col-4">
            <Skeleton
              variant="rounded"
              width="100%"
              height={250}
              animation="wave"
            />
          </div>
        </div>
      </CustomContainer>
    </div>
  );
}
