import React from "react";
import styled from "styled-components";
import { MEDIA_SIZE_MD } from "../utils/helper";

const StyledButton = styled.button`
  border: 1px solid var(--colo-white);
  border-radius: var(--border-radius-sm);
  background: none;
  color: var(--color-brand-800);
  font-weight: 600;
  font-size: 14px;
  padding: 6px 12px;
  min-width: 300px;
  letter-spacing: 0.8px;
  cursor: pointer;
  @media (min-width: ${MEDIA_SIZE_MD}) {
    min-width: 200px;
  }

  /* &:hover {
    border: 1px solid var(--color-brand-800);
  } */
`;

export default function SecondaryButton({ children, onClick, style }) {
  return (
    <StyledButton onClick={onClick} style={{ ...style }}>
      {children}
    </StyledButton>
  );
}
