import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  centreSupervisorList: [],
  centreSupervisorDropdownList: {
    dropdownList: [],
  },
};

const CentreSupervisorSlice = createSlice({
  name: "centreSupervisorList",
  initialState,
  reducers: {
    createCentreSupervisorList(state, action) {
      state.centreSupervisorList = action.payload;
    },
    createCentreSupDropdownList(state, action) {
      state.centreSupervisorDropdownList.dropdownList = action.payload;
    },
  },
});

export const { createCentreSupervisorList, createCentreSupDropdownList } =
  CentreSupervisorSlice.actions;
export default CentreSupervisorSlice.reducer;
