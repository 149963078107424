import axios from "axios";

//baseurl for node js

const AxiosInstance = axios.create({
  // baseURL: "https://aiasadev.annaiasacademy.com/api",
  // baseURL: "https://aiasatest.annaiasacademy.com/api",
  baseURL: "https://annaiasacademy.com/api",
});

// Attach token to requests (optional, depending on how you're handling the token)
AxiosInstance.interceptors.request.use(
  (config) => {
    // const token = JSON.parse(localStorage.getItem('UserDetails')); // or wherever you store the token
    const UserDetails = localStorage.getItem("userDetails");
    if (UserDetails) {
      const userInfo = JSON.parse(UserDetails);
      if (userInfo.device_information) {
        config.headers[
          "Authorization"
        ] = `Bearer ${userInfo.device_information} ${userInfo.user_id}`;
        // config.headers["user_id"] = `${userInfo.user_id}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling errors
AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // const history = useHistory(); // Assuming you're using React Router for navigation
    if (error.response != null) {
      if (error.response.status === 401 || error.response.status === 403) {
        // Unauthorized or Forbidden, so force logout the user
        localStorage.removeItem("userDetails"); // Remove token from local storage
        // history.push('/login'); // Redirect to login page
        window.location.href = "/login";
      } else {
        console.log("error else", error);
      }
    }
    return Promise.reject(error);
  }
);

export { AxiosInstance };

export const ReactBaseUrl = "https://annaiasacademy.com";
