import React from "react";
import styled from "styled-components";
import { MEDIA_SIZE_LG } from "../utils/helper";

const StyledTitleText = styled.span`
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 20px;
  }
`;

export default function StyledTitle({ children }) {
  return <StyledTitleText>{children}</StyledTitleText>;
}
