import React from 'react'

export default function MockInterviewFees(props) {
  return (
  <div className="col-md-6">
        <div className="forms ">
          <label for="fees">Fee for {props.record.id > 0 ? props.record.id : ""} Mock Interview</label>
          <input
            ref={props.txtBatchMFees}
            type="text"
            class="Inputs"
            id="fees"
            name="fees"
            placeholder="fees"
            value={props.record.fees < 0 ? 0 : props.record.fees}
            onChange={e => props.mockPanelHandlechange(e, props.record.id)}
          />
              {props.record.error_5 && (
               <small className="error">{props.record.error_5}</small>)}
        </div>
      </div>
  )
}

