import React, { useEffect, useState } from "react";
import logo from "../../../img/AIASA.png";
import IconLogo from "../../../img/IconLogo.png";
import { Link } from "react-router-dom";
import Image from "../../../img/img1.png";
import Video from "../../../img/india.mp4";
import "./Resetpassword.css";
function Resetpassword() {
  useEffect(() => {
    const videoElement = document.getElementById(
      "ResetPassword-background-image-poster"
    );

    const isIPhone = () => {
      return /iPhone/.test(navigator.userAgent) && !window.MSStream;
    };

    if (isIPhone()) {
      videoElement.setAttribute("disablePictureInPicture", true);
      videoElement.setAttribute("disableRemotePlayback", true);
      videoElement.setAttribute("playsInline", true);
    }

    // In case the mini player still appears, we pause the video
    videoElement.addEventListener("enterpictureinpicture", (event) => {
      if (isIPhone()) {
        event.preventDefault();
        videoElement.pause();
      }
    });
  }, []);
  return (
    <>
      <section
        className="container-fluid ForgetForm videoWrapper"
        id="wrapper_Forget"
      >
        <video
          id="ResetPassword-background-image-poster"
          poster={Image}
          autoPlay
          muted
          loop
          data-wf-ignore="true"
          data-object-fit="cover"
          disableRemotePlayback
          disablePictureInPicture
          playsInline
          controls={false}
        >
          <source src={Video} type="video/mp4" />
        </video>

        <div className="row">
          <div className="col-md-12 box" id="boxs">
            <img src={IconLogo} id="img" alt="Logo" />
            <h1 className="">Password Reset Successful</h1>
            <p>
              Back in business.
              <br />
              Stay secure and stay connected.
            </p>
            <div className="BackLogin">
              <Link to="/login">
                <i class="fa fa-long-arrow-left" id="MIcons"></i>Back to Log in
              </Link>
            </div>
            <div className="copyRights">
              © {new Date().getFullYear()} All Rights Reserved
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Resetpassword;
