import { DialogContent } from "@mui/material";
import React from "react";
import { MEDIA_SIZE_LG } from "../utils/helper";
import styled from "styled-components";

const StyledDialogContent = styled(DialogContent)`
  && {
    border-top: 1px solid var(--color-text-primary);
    border-bottom: 1px solid var(--color-text-primary);
    padding: 16px;
    @media (min-width: ${MEDIA_SIZE_LG}) {
      padding: 20px 40px;
    }
  }
`;

export default function ModalDialogContent({ children }) {
  return <StyledDialogContent>{children}</StyledDialogContent>;
}
