import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import Swal from "sweetalert2";
import RtypeOne from "../../StudentTestWorkflow/RtypeOne";
import ExplanationQuery from "./ExplanationQuery";
import QuerySection from "./QuerySection";
import { ImageUrlPrefix } from "../../../../../labels";
import { AxiosInstance } from "../../../../../axios";
import RtypeTwo from "../../StudentTestWorkflow/RtypeTwo";
import RtypeThree from "../../StudentTestWorkflow/RtypeThree";
import RtypeFour from "../../StudentTestWorkflow/RtypeFour";
import RtypeFive from "../../StudentTestWorkflow/RtypeFive";
import RtypeSix from "../../StudentTestWorkflow/RtypeSix";
import RtypeSeven from "../../StudentTestWorkflow/RtypeSeven";
import RtypeEight from "../../StudentTestWorkflow/RtypeEight";
import RtypeNine from "../../StudentTestWorkflow/RtypeNine";
import RtypeTen from "../../StudentTestWorkflow/RtypeTen";
import Rtypepara from "../../StudentTestWorkflow/Rtypepara";
import RtypeParaOption from "../../StudentTestWorkflow/RtypeParaOption";
import Visibility from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
export default function ViewStudentQuery() {
  const navigate = useNavigate();

  const location = useLocation();

  const { userId, batch_id, event_id } = location.state;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const txtQueryStatus = useRef(null);
  const txtQueryPath = useRef(null);
  const txtQueryReply = useRef(null);

  const [viewQueries, setViewQueries] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);

  const [fieldsQuery, setFieldsQuery] = useState([
    {
      query_status: "",
      query_path: null,
      reply_query: "",
      original_query_path: "",
    },
  ]);

  const [flagReload, setFlagReload] = useState(false);

  const [error, setError] = useState({});

  useEffect(() => {
    // userid , batch_id , event_id
    ViewQuery();
  }, []);

  useEffect(() => {
    ViewQuery();
  }, [flagReload]);

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  // userid , batch_id , event_id
  const ViewQuery = () => {
    AxiosInstance.post("api/test/faculty/view", {
      event_id: event_id,
      batch_id: batch_id,
      user_id: userId,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const el = res.data.data;
          const query_section =
            el.query.length > 0
              ? el.query.map((query) => ({
                  ...query,
                  query_status:
                    query.assignee_info && query.assignee_info.query_status
                      ? query.assignee_info.query_status === 1
                        ? "1"
                        : "0"
                      : "",
                  query_path:
                    query.assignee_info &&
                    query.assignee_info.query_proof_filepath
                      ? query.assignee_info.query_proof_filepath
                      : null,
                  reply_query:
                    query.assignee_info && query.assignee_info.reply_by_staff
                      ? query.assignee_info.reply_by_staff
                      : "",
                  original_query_path:
                    query.assignee_info &&
                    query.assignee_info.original_query_filepath
                      ? query.assignee_info.original_query_filepath
                      : "",
                  query_Status_error: "",
                  query_path_error: null,
                  reply_status_error: "",
                  isUpdate: false,
                  isEdit:
                    query.assignee_info && query.assignee_info.reply_by_staff
                      ? query.assignee_info.reply_by_staff
                        ? true
                        : false
                      : false,
                  query_stage:
                    query.assignee_info && query.assignee_info.query_stage
                      ? query.assignee_info.query_stage
                      : 0,
                }))
              : [];
          setViewQueries(query_section);
          //   const aFieldsQuery = el.query.length > 0 ? el.query.map((ok) => ({
          //     query_Status: ok.assignee_info.query_status,
          //     query_path: ok.assignee_info.query_proof_filepath ? ok.assignee_info.query_proof_filepath : null,
          //     reply_query: ok.assignee_info.reply_by_staff ? ok.assignee_info.reply_by_staff : "",
          //     original_query_path: ok.assignee_info.original_query_filepath ? ok.assignee_info.original_query_filepath : "",
          // })) : {};

          // setFieldsQuery(aFieldsQuery);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleChange = (event, elements) => {
    const { name, value, files, checked } = event.target;
    if (name === "query_path" && files && files.length > 0) {
      const query_file = files[0];
      if (
        files[0].type == "application/pdf" ||
        files[0].type == "image/png" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/jpeg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("query_file", query_file);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setViewQueries((prevState) => {
                return prevState.map((q) => {
                  if (q.question_pk === elements.question_pk) {
                    return {
                      ...q,
                      query_path: res.data.path,
                      original_query_path: res.data.originalname,
                      query_path_error: "",
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setViewQueries((prevState) => {
            return prevState.map((q) => {
              if (q.question_pk === elements.question_pk) {
                return { ...q, query_path_error: err };
              }
              return q;
            });
          });
        }
      } else {
        let err = "pdf files only allowed";
        setViewQueries((prevState) => {
          return prevState.map((q) => {
            if (q.question_pk === elements.question_pk) {
              return { ...q, query_path_error: err };
            }
            return q;
          });
        });
      }
    } else if (name === "query_status") {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return { ...q, query_status: value, query_Status_error: "" };
          }
          return q;
        });
      });
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "reply_query") {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return { ...q, reply_query: value, reply_status_error: "" };
          }
          return q;
        });
      });
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  // validation

  const validateQueryReply = (queries) => {
    const UpdateReplyss = queries.map((OB) => {
      let updatedOB = { ...OB };
      if (OB.query_status === "") {
        updatedOB.query_Status_error = "Please Choose Query Status";
      }
      if (OB.query_path === null) {
        updatedOB.query_path_error = "Please Choose Query Path";
      }
      if (OB.reply_query === "") {
        updatedOB.reply_status_error = "Please enter reply ";
      }

      return updatedOB;
    });

    setViewQueries(UpdateReplyss);
  };

  const handleReplyNow = (element) => {
    validateQueryReply(viewQueries);

    if (
      viewQueries.length > 0 &&
      viewQueries.every(
        (el) =>
          el.query_Status_error === "" &&
          (el.query_path_error === "" || el.query_path_error === null) &&
          el.reply_status_error === ""
      )
    ) {
      const filteredQuery = viewQueries.find(
        (query) => query.question_pk === element.question_pk
      );
      if (filteredQuery) {
        const resultObject = {
          query_status: filteredQuery.query_status,
          query_proof_filepath: filteredQuery.query_path,
          original_query_filepath: filteredQuery.original_query_path,
          reply_by_staff: filteredQuery.reply_query,
        };

        const dfs = {
          user_type: 1, // faculty assign to deafult user_type  0→admin, 1→faculty
          event_type: 1, // default as test so event type 1
          event_pk: element.event_pk ? element.event_pk : "",
          batch_pk: element.batch_id ? element.batch_id : "",
          question_pk: element.question_pk,
          // rquery_pk:
          //   element.stud_query && element.stud_query.length > 0
          //     ? element.stud_query.map((el) => el.pk)
          //     : null,
          query_status: resultObject.query_status
            ? parseInt(resultObject.query_status)
            : "", // 0 valid 1 invalid
          query_proof_filepath: resultObject.query_proof_filepath,
          original_query_filepath: resultObject.original_query_filepath,
          reply_by_staff: resultObject.reply_by_staff,
          reply_dt: moment().format("YYYY-MM-DD HH:mm:ss"),
          created_by: userid,
          updated_by: userid,
        };
        if (
          resultObject.query_status !== "" &&
          resultObject.reply_by_staff !== ""
        ) {
          const vg = true;
          const vg_false = true;
          ReplyApi(dfs, vg, vg_false, element.question_pk);
        }
      }
    }
    //  else {
    //   Swal.fire({
    //     title: "Warning",
    //     text: "Something went wrong",
    //     icon: "Warning",
    //     confirmButtonText: "Ok",
    //   });
    // }
  };

  const handleUpdateReplyNow = (element) => {
    validateQueryReply(viewQueries);

    if (
      viewQueries.length > 0 &&
      viewQueries.every(
        (el) =>
          el.query_Status_error === "" &&
          (el.query_path_error === "" || el.query_path_error === null) &&
          el.reply_status_error === ""
      )
    ) {
      const filteredQuery = viewQueries.find(
        (query) => query.question_pk === element.question_pk
      );
      if (filteredQuery) {
        const resultObject = {
          query_status: filteredQuery.query_status,
          query_proof_filepath: filteredQuery.query_path,
          original_query_filepath: filteredQuery.original_query_path,
          reply_by_staff: filteredQuery.reply_query,
        };

        const dfs = {
          user_type: 1, // faculty assign to deafult user_type  0→admin, 1→faculty
          event_type: 1, // default as test so event type 1
          event_pk: element.event_pk ? element.event_pk : "",
          batch_pk: element.batch_id ? element.batch_id : "",
          question_pk: element.question_pk,
          query_status: resultObject.query_status
            ? parseInt(resultObject.query_status)
            : "", // 0 valid 1 invalid
          query_proof_filepath: resultObject.query_proof_filepath,
          original_query_filepath: resultObject.original_query_filepath,
          reply_by_staff: resultObject.reply_by_staff,
          reply_dt: moment().format("YYYY/MM/DD HH:mm:ss"),
          created_by: userid,
          updated_by: userid,
        };
        if (
          resultObject.query_status !== "" &&
          resultObject.reply_by_staff !== ""
        ) {
          const vg = true;
          const vg_false = false;
          ReplyApi(dfs, vg, vg_false, element.question_pk);
        }
      }
    }
  };

  const ReplyApi = (df, isedit, isUpdt, pkk) => {
    AxiosInstance.post("api/test/raise/query/byAdmin", df)
      .then((response) => {
        if (response.data.status) {
          setFlagReload(true);
          setViewQueries((prevState) => {
            return prevState.map((q) => {
              if (q.question_pk === pkk) {
                return {
                  ...q,
                  isEdit: isedit,
                  isUpdate: isUpdt,
                };
              }
              return q;
            });
          });
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "Success",
            confirmButtonText: "Ok",
          });
        } else {
          setFlagReload(false);
          Swal.fire({
            title: "Warning",
            text: response.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleEditStatus = (el) => {
    setViewQueries((prevState) => {
      return prevState.map((q) => {
        if (q.question_pk === el.question_pk) {
          return {
            ...q,
            isEdit: false,
            isUpdate: true,
          };
        }
        return q;
      });
    });
    // setFlagReload(true);
  };

  // console.log(viewQueries);
  // const reply_by_time = "28-06-2024 12:52:22 PM";
  // function convertDateTime(dateTimeStr) {
  //   let [datePart, timePart, period] = dateTimeStr.split(" ");

  //   let [day, month, year] = datePart.split("-");

  //   return `${day}/${month}/${year} ${timePart} ${period}`;
  // }

  // let formatteReplydDateTime = convertDateTime(reply_by_time);
  // console.log(reply_by_time, "reply_by_time");
  return (
    <div className=" Faculty">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading dflexbetww ">
              <div className=" mt-4 dbetw">
                <h6 className="Title">View Query</h6>
                <Link
                  to={"Javascript:void(0)"}
                  onClick={() => navigateToPreviousScreen()}
                  className="CancelButtons MobileShow mt-0"
                >
                  Back to Query
                </Link>
              </div>
              <div className="d-flex smButton   mobiledown mt-4">
                <Link
                  to={"Javascript:void(0)"}
                  onClick={() => navigateToPreviousScreen()}
                  className="CancelButtons MobileHide me-0"
                >
                  Back to Query
                </Link>
              </div>
            </div>

            {viewQueries.length > 0 ? (
              viewQueries.map((el, index) => (
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">
                      Query - Question No:{el.ques_no}
                    </p>
                    <div className="row p-3 mt-3">
                      <div className="col-md-12 p-0">
                        {el.display_type === 0 && (
                          <RtypeOne type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 1 && (
                          <RtypeTwo type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 2 && (
                          <RtypeThree type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 3 && (
                          <RtypeFour type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 4 && (
                          <RtypeFive type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 5 && (
                          <RtypeSix type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 6 && (
                          <RtypeSeven type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 7 && (
                          <RtypeEight type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 8 && (
                          <RtypeNine type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 9 && (
                          <RtypeTen type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 10 && (
                          <>
                            {el.para_text_tm !== "" && (
                              <Rtypepara
                                type={"2"}
                                classET={"question_labels"}
                                index={index}
                                list={el.para_text_tm}
                              />
                            )}

                            {el.para_text_en !== "" && (
                              <Rtypepara
                                type={"2"}
                                classET={"question_labels_english"}
                                index={index}
                                list={el.para_text_en}
                              />
                            )}
                            <RtypeParaOption
                              type={"2"}
                              index={index}
                              list={el}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <ExplanationQuery
                      lang_type={el.lang_type}
                      margin_yes={0}
                      en_explan={el.en_explanation}
                      tm_expla={el.tm_explanation}
                    />
                    <div className="BorderQuery"></div>
                    {el.stud_query &&
                      el.stud_query.map((ojk, index) => {
                        return <QuerySection query_rise={ojk} index={index} />;
                      })}
                    <div className="BorderQuery"></div>
                    {el.isEdit === false || isUpdate === true ? (
                      <div className="row mt-3 mb-4">
                        <div className="col-md-12">
                          <p className="titleQuery mb-1 ">Assigned By</p>
                          <p className="explain_ques mt-0">
                            {" "}
                            {el.assignee_info !== null &&
                              el.assignee_info.assigned_to_name}{" "}
                          </p>
                        </div>
                        <div className="col-md-6 ">
                          <div className="forms ">
                            <label for="query_status">Query Status:</label>
                            <select
                              ref={txtQueryStatus}
                              id="query_status"
                              name="query_status"
                              className="Inputs"
                              onChange={(e) => handleChange(e, el)}
                              required
                            >
                              <option value="" disabled selected>
                                Please select
                              </option>
                              <option
                                selected={
                                  el.query_status === "1" ? true : false
                                }
                                value="1"
                              >
                                Valid
                              </option>
                              <option
                                selected={
                                  el.query_status === "0" ? true : false
                                }
                                value="0"
                              >
                                Invalid
                              </option>
                            </select>
                            {el.query_Status_error && (
                              <small className="error">
                                {el.query_Status_error}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="forms ">
                            <label for="query_path">
                              Upload Proof for Query Reference:
                              {el.query_path !== "" &&
                                el.query_path !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${el.query_path}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons download_icon align-middle text-danger ">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                            <div className="row">
                              <div
                                className={
                                  el.query_path !== "" && el.query_path !== null
                                    ? "col-md-3 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={txtQueryPath}
                                  id="query_path"
                                  type="file"
                                  name="query_path"
                                  placeholder="Upload query proof"
                                  className={
                                    el.query_path !== "" &&
                                    el.query_path !== null
                                      ? "InputsFile nn_upload_width"
                                      : "InputsFile  vv_upload_width"
                                  }
                                  onChange={(e) => handleChange(e, el)}
                                  autoComplete="query_path"
                                  required
                                />
                              </div>
                              {el.query_path !== "" &&
                                el.query_path !== null && (
                                  <div
                                    className={
                                      "col-md-9 d-flex align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_file">
                                      {el.original_query_path}
                                    </span>
                                    <span className="material-icons align-middle text-danger verified_upload">
                                      verified
                                    </span>
                                  </div>
                                )}
                            </div>
                            {el.query_path_error && (
                              <small className="error">
                                {el.query_path_error}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="forms ">
                            <label for="reply_query">Reply by You:</label>
                            <textarea
                              ref={txtQueryReply}
                              id="reply_query"
                              className="textareaInput"
                              name="reply_query"
                              rows="4"
                              cols="20"
                              onChange={(e) => handleChange(e, el)}
                              placeholder="Reply by You"
                              value={el.reply_query}
                            ></textarea>
                            {el.reply_status_error && (
                              <small className="error">
                                {el.reply_status_error}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="heading mt-4 dflexCenter ">
                          <button
                            onClick={
                              el.isUpdate === true
                                ? () => handleUpdateReplyNow(el)
                                : () => handleReplyNow(el)
                            }
                            type="button"
                            className="QueryButton"
                          >
                            <Link to="javascript:void(0)">
                              {el.isUpdate === true ? "Update" : "Reply Now"}
                            </Link>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="row mt-3 mb-4">
                        <div className="col-md-6">
                          <p className="titleQuery mb-1 ">Assigned By</p>
                          <p className="explain_ques mt-0">
                            {" "}
                            {el.assignee_info !== null &&
                              el.assignee_info.assigned_to_name}{" "}
                          </p>
                        </div>
                        <div className="col-md-6 ">
                          <div className="forms ">
                            <p className="titleQuery mb-1">Query Status:</p>
                            <p className="explain_ques mt-0">
                              {" "}
                              {el.assignee_info !== null &&
                              el.assignee_info.query_status === 1
                                ? "Valid"
                                : "Invalid"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="forms ">
                            <p className="titleQuery mb-1">
                              Reply by{" "}
                              {el.assignee_info !== null &&
                                el.assignee_info.assigned_to_name}{" "}
                              {/* ({formatteReplydDateTime}) */}({" "}
                              {el.assignee_info !== null &&
                                moment(el.assignee_info.reply_dt).format(
                                  "DD/MM/YYYY HH:mm:ss A"
                                )}{" "}
                              )
                            </p>
                            <p className="explain_ques mt-0">
                              {" "}
                              {el.assignee_info !== null &&
                                el.assignee_info.reply_by_staff}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="forms ">
                            <div class="d-flex">
                              {" "}
                              <p className="titleQuery mb-1">Attachments</p>
                              <a
                                download
                                target="_blank"
                                className="attachmentQuery"
                                href={`${ImageUrlPrefix}/${
                                  el.assignee_info &&
                                  el.assignee_info.query_proof_filepath
                                    ? el.assignee_info.query_proof_filepath
                                    : null
                                }`}
                              >
                                <span class="material-icons iconQuery">
                                  download
                                </span>
                              </a>
                              <a
                                className="attachmentQuery"
                                target="_blank"
                                href={`${ImageUrlPrefix}/${
                                  el.assignee_info &&
                                  el.assignee_info.query_proof_filepath
                                    ? el.assignee_info.query_proof_filepath
                                    : null
                                }`}
                              >
                                <span class="material-icons iconQuery">
                                  visibility
                                </span>
                              </a>
                            </div>
                            {/* <p className="titleQuery mb-1">
                              Attachments
                              <a
                                download
                                target="_blank"
                                className="attachmentQuery"
                                href={`${ImageUrlPrefix}/${
                                  el.assignee_info &&
                                  el.assignee_info.query_proof_filepath
                                    ? el.assignee_info.query_proof_filepath
                                    : null
                                }`}
                              >
                                <DownloadIcon />
                              </a>
                              <a
                                className="attachmentQuery"
                                target="_blank"
                                href={`${ImageUrlPrefix}/${
                                  el.assignee_info &&
                                  el.assignee_info.query_proof_filepath
                                    ? el.assignee_info.query_proof_filepath
                                    : null
                                }`}
                              >
                                <Visibility />
                              </a>
                            </p> */}
                            <p className="explain_ques mt-0">
                              {" "}
                              {el.assignee_info !== null &&
                                el.assignee_info.original_query_filepath}
                            </p>
                          </div>
                        </div>
                        {el.query_stage === 0 && (
                          <div className="heading mt-4 dflexCenter ">
                            <button
                              type="button"
                              onClick={() => handleEditStatus(el)}
                              className="QueryButton"
                            >
                              <Link to="javascript:void(0)">
                                <span class="material-icons me-2">
                                  border_color
                                </span>
                                Edit
                              </Link>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Query</p>
                  <div className="row mt-3">
                    <div className="col-md-12 p-0">
                      <p className="explain_ques mt-0">No record to display</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}
