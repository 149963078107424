// real

import "jspdf-autotable";

import { useEffect, useRef, useState } from "react";

import $ from "jquery";
import { AxiosInstance } from "../../../axios";
import { CSVLink } from "react-csv";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import React from "react";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import jsPDF from "jspdf";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { R_PER_PAGE } from "../../../labels";
import DatePickerSample from "../DatePicker";
import { TailSpin } from "react-loader-spinner";

function Listview() {
  const moment = require("moment-timezone");
  let page = R_PER_PAGE;

  const location = useLocation();

  const { typeFilter } = useParams();
  const txtBSearch = useRef(null); //search icon
  const {
    filStatus,
    filCateCode,
    fillPayType,
    fillSec,
    fillBatch,
    fillLoc,
    fillAon,
    fillType,
    fillSearch,
  } = location.state ? location.state : {};

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [chData, setChData] = useState();
  const [filData, setFiledData] = useState([]);
  const [updateListData, setUpdateListData] = useState([]);
  const [updatePag, setUpdatePag] = useState(false);
  const [updatePagDis, setUpdatePagDis] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [csvListData, setCsvListData] = useState([]);
  const [isOpen, setIsOpen] = useState({
    status: false,
    appliedOn: false,
    type: false,
    section: false,
    category_code: false,
    payment_type: false,
    course_name: false,
    batch_name: false,
    location_name: false,
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const [section, setSection] = useState([]);
  const [category_code, setCategory_code] = useState([]);
  const [dCourse, setDcourse] = useState([]);
  const [dCourses, setDcourses] = useState([]);
  const [dLocation, setDLocation] = useState([]);
  const [dSection, setDSection] = useState([]);
  const [dSections, setDSections] = useState([]);
  const [dLocations, setDLocations] = useState([]);
  const [course_name, setCourse_name] = useState([]);
  const [batch_name, setBatch_name] = useState([]);
  // { id: "1", name: "Online", type: "payment_type" }
  const [location_name, setLocation_name] = useState([]);
  const [payment_type, setPayment_type] = useState([]);
  const [updateChipData, setUpdateChipData] = useState([
    // { id: typeFilter ? typeFilter : 0, name: "typeFilter", type: "typeFilter" },
  ]);

  const [status, setStatus] = useState([
    // { id: "0", name: "WaitingForApproval", type: "status" },
  ]);

  // const [status, setStatus] = useState([{ id: "0", name: "waitingForApproval",type:"status" }]);

  const [appOn, setAppOn] = useState([]);
  const [typeOn, setTypeOn] = useState([]);

  // pagination start end -   item per page changing
  let start = (currentPage - 1) * rowsPerPage;
  let end = start + rowsPerPage;
  let currentData = updateListData.slice(start, end);
  // total table data  with pages
  let totalPages = Math.ceil(updateListData.length / rowsPerPage);

  let filterStatus = [];
  let filterType = [];
  let filterApplyed = [];
  let filterSearch = [];
  let filtersection = [];
  let filterCateCode = [];
  let filterPayType = [];
  let filterCouName = [];
  let filterBatchName = [];
  let filterLName = [];

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    filtersection = section;
  }, [section]);

  useEffect(() => {
    // console.log(" names : ", status);
    filterStatus = status;
  }, [status]);

  useEffect(() => {
    // console.log(" names : ", typeOn);
    filterType = typeOn;
  }, [typeOn]);
  useEffect(() => {
    // console.log(" names : ", typeOn);
    filterCateCode = category_code;
  }, [category_code]);
  useEffect(() => {
    // console.log(" names : ", typeOn);
    filterPayType = payment_type;
  }, [payment_type]);
  useEffect(() => {
    // console.log(" names : ", typeOn);
    filterCouName = course_name;
  }, [course_name]);

  useEffect(() => {
    // console.log(" names : ", typeOn);
    filterBatchName = batch_name;
  }, [batch_name]);

  useEffect(() => {
    // console.log(" names : ", typeOn);
    filterLName = location_name;
  }, [location_name]);

  useEffect(() => {
    // console.log(" appOn : ", appOn);
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    // console.log(" searchTerm : ", searchTerm);
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    if (filStatus && filStatus.length > 0) {
      setStatus(filStatus);
      filterStatus = filStatus;
    } else {
      setStatus([{ id: "0", name: "WaitingForApproval", type: "status" }]);
      filterStatus = [{ id: "0", name: "WaitingForApproval", type: "status" }];
    }
    if (filCateCode && filCateCode.length > 0) {
      setCategory_code(filCateCode);
      filterCateCode = filCateCode;
    }
    if (fillPayType && fillPayType.length > 0) {
      setPayment_type(fillPayType);
      filterPayType = fillPayType;
    }
    if (fillSec && fillSec.length > 0) {
      setSection(fillSec);
      filtersection = fillSec;
    }
    if (fillBatch && fillBatch.length > 0) {
      setBatch_name(fillBatch);
      filterBatchName = fillBatch;
    }
    if (fillLoc && fillLoc.length > 0) {
      setLocation_name(fillLoc);
      filterLName = fillLoc;
    }
    if (fillSearch && fillSearch.length > 0) {
      filterSearch = [{ name: "" + fillSearch, type: "search" }];
      setSearchTerm(fillSearch);
    } else {
      if (searchTerm) {
        setSearchTerm(searchTerm);
        filterSearch = [{ name: "" + searchTerm, type: "search" }];
      }
    }
    if (fillAon && fillAon.length > 0) {
      setAppOn(fillAon);
      filterApplyed = fillAon;
    } else {
      setAppOn(appOn);
      filterApplyed = appOn;
    }
    if (fillType && fillType.length > 0) {
      setTypeOn(fillType);
      filterType = fillType;
    } else if (typeFilter === "0" && !fillType) {
      setTypeOn([{ id: "0", name: "course", type: "type" }]);
      filterType = [{ id: "0", name: "course", type: "type" }];
    } else if (typeFilter === "1" && !fillType) {
      setTypeOn([{ id: "1", name: "TestBatch", type: "type" }]);
      filterType = [{ id: "1", name: "TestBatch", type: "type" }];
    } else if (typeFilter === "3" && !fillType) {
      setTypeOn([{ id: "3", name: "SpecialClass", type: "type" }]);
      filterType = [{ id: "3", name: "SpecialClass", type: "type" }];
    } else if (typeFilter === "2" && !fillType) {
      setTypeOn([{ id: "2", name: "Counselling", type: "type" }]);
      filterType = [{ id: "2", name: "Counselling", type: "type" }];
    } else if (typeFilter === "4" && !fillType) {
      setTypeOn([{ id: "4", name: "ModelExam", type: "type" }]);
      filterType = [{ id: "4", name: "ModelExam", type: "type" }];
    } else if (typeFilter === "5" && !fillType) {
      setTypeOn([{ id: "5", name: "MockInterview", type: "type" }]);
      filterType = [{ id: "5", name: "MockInterview", type: "type" }];
    } else {
      setTypeOn(typeOn);
      filterType = typeOn;
    }
    loadChipdata();
  }, [typeFilter]);

  useEffect(() => {
    if (updateChipData.length > 0) {
      loadListdata();
    }
    // else {
    // loadListdata();
    // }
  }, [updateChipData]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    filtersection = section;
    filterCateCode = category_code;
    filterPayType = payment_type;
    filterBatchName = batch_name;
    filterLName = location_name;
    filterCouName = course_name;
    filterApplyed = appOn;
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  const handleFilterCourseSearch = (event) => {
    const searchInput = event.target.value;
    if (searchInput !== "") {
      const matchingObjects = dCourse.filter(
        (item) => item && item.toLowerCase().includes(searchInput.toLowerCase())
      );
      // Do something with the matchingObjects, such as updating state or displaying them in the UI
      setDcourses(matchingObjects);
    } else {
      setDcourses(dCourse);
    }
  };

  const handleFilterSectionSearch = (event) => {
    const searchInput = event.target.value;
    if (searchInput !== "") {
      const matchingObjects = dSection.filter(
        (item) => item && item.toLowerCase().includes(searchInput.toLowerCase())
      );

      // Do something with the matchingObjects, such as updating state or displaying them in the UI
      setDSection(matchingObjects);
    } else {
      setDSection(dSections);
    }
  };

  const handleFilterLocatonSearch = (event) => {
    const searchInput = event.target.value;
    if (searchInput !== "") {
      const matchingObjects = dLocation.filter(
        (item) => item && item.toLowerCase().includes(searchInput.toLowerCase())
      );
      // Do something with the matchingObjects, such as updating state or displaying them in the UI
      setDLocations(matchingObjects);
    } else {
      setDLocations(dLocation);
    }
  };
  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    // console.log(names, " : ", status);
    const { value, checked, name } = event.target;
    if (
      name === "waitingForApproval" ||
      name === "Rejected" ||
      name === "approved" ||
      name === "onHold"
    ) {
      if (checked) {
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (
      name === "course" ||
      name === "TestBatch" ||
      name === "SpecialClass" ||
      name === "Counselling" ||
      name === "MockInterview" ||
      name === "ModelExam"
    ) {
      if (checked) {
        setTypeOn([...typeOn, { id: value, name: name, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.id !== value));
      }
    } else if (typename === "category_code") {
      if (checked) {
        setCategory_code([
          ...category_code,
          { name: value, type: "category_code" },
        ]);
      } else {
        setCategory_code(category_code.filter((item) => item.name !== value));
      }
    } else if (names === "Offline" || names === "Online") {
      if (checked) {
        setPayment_type([
          ...payment_type,
          { id: value, name: name, type: "payment_type" },
        ]);
      } else {
        setPayment_type(payment_type.filter((item) => item.id !== value));
      }
    } else if (names === "course_name") {
      if (checked) {
        setCourse_name([...course_name, { name: value, type: "course_name" }]);
      } else {
        setCourse_name(course_name.filter((item) => item.name !== value));
      }
    } else if (names === "batch_name") {
      if (checked) {
        setBatch_name([
          ...batch_name,
          { id: value, name: name, type: "batch_name" },
        ]);
      } else {
        setBatch_name(batch_name.filter((item) => item.id !== value));
      }
    } else if (names === "section") {
      if (checked) {
        setSection([...section, { id: value, name: name, type: "section" }]);
      } else {
        setSection(section.filter((item) => item.id !== value));
      }
    } else if (names === "location_name") {
      if (checked) {
        setLocation_name([
          ...location_name,
          { id: value, name: name, type: "location_name" },
        ]);
      } else {
        setLocation_name(location_name.filter((item) => item.id !== value));
      }
    }
  };

  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  // -------Search Icon function--
  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        code: "Enter",
        keyCode: 13,
        which: 13,
        bubbles: true,
      });
      txtBSearch.current.dispatchEvent(event);
    }
  };
  //-----end--
  // handle edit function
  const handleEdit = (userId, adm_pk, appliedOn, type) => {
    const admissId = adm_pk;
    navigate(`/studentConfirm/${userId}/${admissId}/${appliedOn}/${type}`, {
      state: {
        FSF: status,
        FCAF: category_code,
        FPYM: payment_type,
        FSEC: section,
        FBTN: batch_name,
        FLOC: location_name,
        FAPON: appOn,
        FTF: typeOn,
        FSEAR: searchTerm,
      },
    });
  };

  // generate pdf when user click download button
  // const generatePDF = () => {
  //   // Create a new jsPDF instance
  //   const doc = new jsPDF();

  //   // Add a title to the PDF document
  //   doc.text("Table Data", 10, 10);

  //   // Convert the table to a JavaScript array
  //   const tableData = [];

  //   // Add header row to tableData array
  //   const headers = [];
  //   const tableHeader = document.querySelectorAll("#example th");
  //   for (const header of tableHeader) {
  //     headers.push(header.innerText);
  //   }
  //   tableData.push(headers);

  //   // Add data rows to tableData array
  //   const tableRows = document.querySelectorAll("#example tbody tr");
  //   for (const row of tableRows) {
  //     const rowData = [];
  //     const rowCells = row.querySelectorAll("td");
  //     for (const cell of rowCells) {
  //       rowData.push(cell.innerText);
  //     }
  //     tableData.push(rowData);
  //   }
  //   const tableOptions = {
  //     head: [tableData[0]], // Use the first row as the table header
  //     body: tableData.slice(1), // Use the remaining rows as the table body
  //     margin: { top: 20, right: 10, bottom: 10, left: 10 }, // Set margins
  //     didDrawPage: function (data) {
  //       // Set the table width to fit within the margins
  //       const widthPt = data.settings.margin.left + data.settings.margin.right;
  //       const tableWidth = data.table.width;
  //       if (tableWidth > doc.internal.pageSize.width - widthPt) {
  //         const scaleFactor = (doc.internal.pageSize.width - widthPt) / tableWidth;
  //         doc.autoTableScaleToFitOptions.scaleFactor = scaleFactor;
  //         doc.autoTable(tableOptions);
  //       }
  //     },
  //   };

  //   // Add the table to the PDF document
  //   doc.autoTable(tableOptions);
  //   // Save the PDF document
  //   doc.save("AdmissionData.pdf");
  // };

  const generatePDF = () => {
    // Create a new jsPDF instance
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Landscape orientation
      unit: "in", // Use inches as the unit of measurement
      format: [9.5, 11], // Set the dimensions to a standard letter size (9.5 x 11 inches)
    });

    doc.setFont("arabic"); // Set the font to 'arabic'
    doc.setFontSize(12); // Adjust the desired font size
    // Add a title to the PDF document
    doc.text("Course batch data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    const headers = [
      "fullname",
      "admission_id",
      "phone_no",
      "section",
      "type",
      "batch_name",
      "appliedOn",
    ]; // Assuming updateListData is your state variable

    tableData.push(headers);

    updateListData.forEach((item) => {
      const rowData = [];
      headers.forEach((header) => {
        rowData.push(item[header]);
      });
      tableData.push(rowData);
    });

    // Define the column widths for the header columns (adjust as needed)
    // Calculate column widths based on the content of header and tbody cells
    const headerColumnStyles = {};
    const tbodyColumnStyles = {};

    headers.forEach((header, index) => {
      const maxHeaderWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 1 + 6; // Adjust the multiplier as needed
          })
      );
      const maxTbodyWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 2 + 6; // Adjust the multiplier as needed
          }) // Adjust the multiplier as needed
      );

      headerColumnStyles[index] = { columnWidth: maxHeaderWidth };
      tbodyColumnStyles[index] = { columnWidth: maxTbodyWidth };
    });

    // Add data rows to tableData array
    const tableOptions = {
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
      // columnStyles: { ...headerColumnStyles, ...tbodyColumnStyles }, // Specify dynamic column widths
    };

    // Add the table to the PDF document
    doc.autoTable(tableOptions);

    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: from !== "" ? moment(from).format("YYYY-MM-DD") : "",
          edate: to !== "" ? moment(to).format("YYYY-MM-DD") : "",
          type: "dateRange",
        },
      ]);
    }
  };

  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: from !== "" ? moment(from).format("YYYY-MM-DD") : "",
          edate: to !== "" ? moment(to).format("YYYY-MM-DD") : "",
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
    // setUpdatePag(true);
    // setUpdatePagDis(false);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
    // setUpdatePag(false);
    // setUpdatePagDis(true);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      // setUpdateListData([]);
    }
    // loadListdata();
  };

  //  filter box open function

  const handleFilter = (e, filname) => {
    if (filname === "status") {
      setIsOpen((prevState) => ({
        appliedOn: false,
        type: false,
        payment_type: false,
        section: false,
        course_name: false,
        location_name: false,
        category_code: false,
        batch_name: false,
        status: !prevState.status,
      }));
    } else if (filname === "appliedOn") {
      setIsOpen((prevState) => ({
        type: false,
        section: false,
        status: false,
        payment_type: false,
        category_code: false,
        location_name: false,
        course_name: false,
        batch_name: false,
        appliedOn: !prevState.appliedOn,
      }));
    } else if (filname === "type") {
      setIsOpen((prevState) => ({
        section: false,
        status: false,
        payment_type: false,
        appliedOn: false,
        category_code: false,
        location_name: false,
        course_name: false,
        batch_name: false,
        type: !prevState.type,
      }));
    } else if (filname === "batch_name") {
      setIsOpen((prevState) => ({
        section: false,
        status: false,
        payment_type: false,
        appliedOn: false,
        location_name: false,
        type: false,
        category_code: false,
        course_name: false,
        batch_name: !prevState.batch_name,
      }));
    } else if (filname === "section") {
      setIsOpen((prevState) => ({
        status: false,
        appliedOn: false,
        type: false,
        batch_name: false,
        payment_type: false,
        location_name: false,
        category_code: false,
        course_name: false,
        section: !prevState.section,
      }));
    } else if (filname === "category_code") {
      setIsOpen((prevState) => ({
        status: false,
        appliedOn: false,
        type: false,
        location_name: false,
        batch_name: false,
        section: false,
        payment_type: false,
        course_name: false,
        category_code: !prevState.category_code,
      }));
    } else if (filname === "payment_type") {
      setIsOpen((prevState) => ({
        status: false,
        appliedOn: false,
        type: false,
        location_name: false,
        batch_name: false,
        category_code: false,
        section: false,
        course_name: false,
        payment_type: !prevState.payment_type,
      }));
    } else if (filname === "course_name") {
      setIsOpen((prevState) => ({
        status: false,
        appliedOn: false,
        type: false,
        batch_name: false,
        category_code: false,
        location_name: false,
        section: false,
        payment_type: false,
        course_name: !prevState.course_name,
      }));
    } else if (filname === "batch_name") {
      setIsOpen((prevState) => ({
        status: false,
        appliedOn: false,
        type: false,
        course_name: false,
        category_code: false,
        location_name: false,
        section: false,
        payment_type: false,
        batch_name: !prevState.batch_name,
      }));
    } else if (filname === "location_name") {
      setIsOpen((prevState) => ({
        status: false,
        appliedOn: false,
        type: false,
        batch_name: false,
        category_code: false,
        course_name: false,
        section: false,
        payment_type: false,
        location_name: !prevState.location_name,
      }));
    }
  };

  //chip list style

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setSection([]);
    setCategory_code([]);
    setPayment_type([]);
    setCourse_name([]);
    setBatch_name([]);
    setLocation_name([]);
    setUpdateChipData([{ id: 0, name: "ClearFilter", type: "ClearFilter" }]);
    setAppOn([]);
    setTypeOn([]);
    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    filterStatus = [];
    filterType = [];
    filterApplyed = [];
    filterSearch = [];
    filtersection = [];
    filterCateCode = [];
    filterPayType = [];
    filterCouName = [];
    filterBatchName = [];
    filterLName = [];
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        appliedOn: false,
      });
    } else if (name === "type") {
      // console.log(typeOn,"typeOn");
      loadChipdata();
      setIsOpen({
        ...isOpen,
        type: false,
      });
    } else if (name === "section") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        section: false,
      });
    } else if (name === "category_code") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category_code: false,
      });
    } else if (name === "payment_type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        payment_type: false,
      });
    } else if (name === "course_name") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        course_name: false,
      });
    } else if (name === "batch_name") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        batch_name: false,
      });
    } else if (name === "location_name") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        location_name: false,
      });
    }
  };

  function loadListdata() {
    setIsLoading(true);
    let tempstatus = [];
    let temptypeon = [];
    let tempstatusnum = [];
    let tempTypenum = [];
    let tempsection = [];
    let tempCateCode = [];
    let tempPayTypenum = [];
    let tempLocationNum = [];
    let tempCouName = [];
    let tempBatchName = [];
    let tempLocationName = [];
    let tempPayType = [];

    status.map((obj) => {
      tempstatus.push(obj.id);
    });
    section.map((obj) => {
      tempsection.push(obj.name);
    });
    typeOn.map((obj) => {
      temptypeon.push(obj.id);
    });
    category_code.map((obj) => {
      tempCateCode.push(obj.name);
    });
    payment_type.map((obj) => {
      tempPayType.push(obj.id);
    });
    course_name.map((obj) => {
      tempCouName.push(obj.name);
    });

    batch_name.map((obj) => {
      tempBatchName.push(obj.name);
    });

    location_name.map((obj) => {
      tempLocationName.push(obj.id);
    });

    tempTypenum = temptypeon.map(Number);
    tempPayTypenum = tempPayType.map(Number);
    tempLocationNum = tempLocationName.map(Number);
    tempstatusnum = [...new Set(tempstatus)].map(Number);

    let statusFilter = {
      type: typeOn.length > 0 ? tempTypenum : "",
      location: location_name.length > 0 ? tempLocationNum : "",
      course_name: course_name.length > 0 ? tempCouName : "",
      mode_of_payment:
        payment_type.length > 0
          ? tempPayTypenum.length === 1
            ? tempPayTypenum[0]
            : ""
          : "",
      payment_type: 1, // 1 - online , 0 - offline
      category: category_code.length > 0 ? tempCateCode : "",
      section: section.length > 0 ? tempsection : "",
      status: status.length > 0 ? tempstatusnum : "",
      start_date:
        appOn.length > 0
          ? appOn[0].edate !== ""
            ? appOn[0].fdate + " 00:00:00"
            : appOn[0].fdate
          : "",
      end_date:
        appOn.length > 0
          ? appOn[0].edate !== ""
            ? appOn[0].edate + " 23:59:00"
            : appOn[0].edate
          : "",
      search: searchTerm.length > 0 ? searchTerm : "",
      batch_name: batch_name.length > 0 ? tempBatchName : "",
      page_size: "",
      page_no: "",
      // page_size: rowsPerPage,
      // page_no: currentPage,
    };

    AxiosInstance.post("api/course/admission/list", statusFilter)
      .then((res) => {
        if (res.data.status === true) {
          setIsLoading(false);
          const fiata = res.data.data;
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          setUpdateListData(serialNumberAddedData);
          const batch_nameList = fiata
            .map((item) => (item.batch_name !== null ? item.batch_name : []))
            .flat();
          // Remove duplicates by converting the array to a Set and back to an array
          const uniqueBatchNameList = [...new Set(batch_nameList)];
          setDcourse(uniqueBatchNameList);
          setDcourses(uniqueBatchNameList);
          const locationList = fiata
            .map((item) =>
              item.location_name !== null
                ? { name: item.location_name, id: item.location_id }
                : null
            )
            .flat();
          // Remove duplicates by converting the array to a Set and back to an array
          const uniqueLocationList = [
            ...new Map(locationList.map((item) => [item.id, item])).values(),
          ];
          setDLocation(uniqueLocationList);
          setDLocations(uniqueLocationList);
          const SectionList = fiata
            .map((item) => (item.section !== null ? item.section : []))
            .flat();
          // Remove duplicates by converting the array to a Set and back to an array
          const UniqueSection = [...new Set(SectionList)];
          setDSection(UniqueSection);
          setDSections(UniqueSection);
        } else if (res.data.status === false) {
          setUpdateListData([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setUpdateListData([]);
        return error;
      });
  }

  function loadChipdata() {
    let tempArr = [];

    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });
    filterType.map((obj) => {
      tempArr.push(obj);
    });
    filtersection.map((obj) => {
      tempArr.push(obj);
    });
    filterApplyed.map((obj) => {
      tempArr.push(obj);
    });
    filterCateCode.map((obj) => {
      tempArr.push(obj);
    });
    filterPayType.map((obj) => {
      tempArr.push(obj);
    });
    filterCouName.map((obj) => {
      tempArr.push(obj);
    });

    filterBatchName.map((obj) => {
      tempArr.push(obj);
    });
    filterLName.map((obj) => {
      tempArr.push(obj);
    });
    if (tempArr.length > 0) {
      setUpdateChipData(tempArr);
    } else {
      setUpdateChipData([{ id: 0, name: "ClearFilter", type: "ClearFilter" }]);
    }
  }

  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        appliedOn: false,
      });
    } else if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        type: false,
      });
    } else if (name === "section") {
      setSection([]);
      filtersection = [];

      setIsOpen({
        ...isOpen,
        section: false,
      });
    } else if (name === "category_code") {
      setCategory_code([]);
      filterCateCode = [];

      setIsOpen({
        ...isOpen,
        category_code: false,
      });
    } else if (name === "payment_type") {
      setPayment_type([]);
      filterPayType = [];

      setIsOpen({
        ...isOpen,
        payment_type: false,
      });
    } else if (name === "course_name") {
      setCourse_name([]);
      filterCouName = [];

      setIsOpen({
        ...isOpen,
        course_name: false,
      });
    } else if (name === "batch_name") {
      setBatch_name([]);
      filterBatchName = [];

      setIsOpen({
        ...isOpen,
        batch_name: false,
      });
    } else if (name === "location_name") {
      setLocation_name([]);
      filterLName = [];

      setIsOpen({
        ...isOpen,
        location_name: false,
      });
    }
    loadChipdata();
  };

  //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.id !== chipToDelete.id));
      filterType = typeOn.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "category_code") {
      setCategory_code(
        category_code.filter((item) => item.id !== chipToDelete.id)
      );
      filterCateCode = category_code.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "payment_type") {
      setPayment_type(
        payment_type.filter((item) => item.id !== chipToDelete.id)
      );
      filterPayType = payment_type.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "course_name") {
      setCourse_name(course_name.filter((item) => item.id !== chipToDelete.id));
      filterCouName = course_name.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "batch_name") {
      setBatch_name(batch_name.filter((item) => item.id !== chipToDelete.id));
      filterBatchName = batch_name.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "location_name") {
      setLocation_name(
        location_name.filter((item) => item.id !== chipToDelete.id)
      );
      filterLName = location_name.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else if (chipToDelete.type === "section") {
      setSection(section.filter((item) => item.id !== chipToDelete.id));
      filtersection = section.filter((item) => item.id !== chipToDelete.id);
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    }
    loadChipdata();
  };

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && (
        <TailSpin
          visible={true}
          height="100"
          width="90"
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="custom-spinner"
        />
      )}
    </div>
  ) : (
    <div className=" ListViewForm ">
      <h4 className="Title">Admission Form</h4>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="input-group ">
            <input
              ref={txtBSearch}
              type="text"
              class="Inputs"
              id="SearchInput"
              name="SearchInput"
              placeholder="Search by Name,ID,Phone Number and Course"
              onKeyDown={handleSearch}
            />
            <span
              class="input-group-text"
              id="SearchInput"
              onClick={handleSearchFocus}
            >
              <span className="material-icons" onClick={handleSearchFocus}>
                search
              </span>
            </span>
          </div>
        </div>
        <div className="col-lg-3 col-md-1 col-sm-12 col-xs-12"></div>
        <div className="col-lg-5 dflex col-md-7">
          <button type="button" className="SaveButton me-3">
            <span className="material-icons me-1 align-middle text-white">
              file_download
            </span>
            <CSVLink
              data={updateListData.map((el) => ({
                fullname: el.fullname,
                admission_id: el.admission_id,
                phone_no: el.phone_no,
                section: el.section,
                batch_name: el.batch_name,
                appliedOn: el.appliedOn,
                type:
                  el.type === 4
                    ? "Model exam "
                    : el.type === 0
                    ? "Course"
                    : el.type === 1
                    ? "Test Batch"
                    : el.type === 3
                    ? " Special class"
                    : el.type === 2
                    ? "Counselling"
                    : el.type === 5
                    ? "Mock Interview"
                    : "None",
              }))}
              filename={"AdmissionList.csv"}
            >
              Download as CSV
            </CSVLink>
          </button>
          <button type="button" className="SaveButton" onClick={generatePDF}>
            <span className="material-icons me-1 align-middle text-white">
              file_download
            </span>
            <a> Download as PDF</a>
          </button>
        </div>
      </div>
      {/* chip items */}
      {updateChipData.filter((io) => io.name !== "ClearFilter").length > 0 ? (
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {updateChipData
                .filter((io) => io.name !== "ClearFilter")
                .map((data, index) => {
                  let key = "";
                  if (data.name === "WaitingForApproval") {
                    key = "" + "Waiting For Approval";
                  } else if (data.name === "course") {
                    key = "" + "Course";
                  } else if (data.name === "approved") {
                    key = "" + "Approved";
                  } else if (data.name === "onHold") {
                    key = "" + "OnHold";
                  } else if (data.name === "ModelExam") {
                    key = "" + "Model Exam";
                  } else if (data.name === "MockInterview") {
                    key = "" + "Mock Interview";
                  } else if (data.name === "SpecialClass") {
                    key = "" + "Special Class";
                  } else if (data.name === "TestBatch") {
                    key = "" + "Test Batch";
                  } else {
                    key = "" + data.name;
                  }
                  let icon;
                  return (
                    <ListItem key={data}>
                      <Chip
                        icon={icon}
                        label={key}
                        onDelete={handleDelete(data, index)}
                      />
                    </ListItem>
                  );
                })}

              <ListItem>
                <a
                  className="allclear"
                  href="javascript:void(0)"
                  onClick={handleAllClear}
                >
                  Clear All Filters
                </a>
              </ListItem>
            </Paper>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div className="row mt-2">
        <div className="col-md-12 table-responsive">
          <table className="table" id="example">
            {" "}
            <thead>
              <tr>
                <th id="th">
                  <span className="text_th">S.No</span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "fullname")}
                  >
                    Name
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "fullname")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "admission_id")}
                  >
                    {" "}
                    AdmissionId
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "admission_id")}
                  >
                    import_export
                  </span>
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    Phone Number
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "mode_of_payment")}
                  >
                    Mode of <br /> payment
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "mode_of_payment")}
                  >
                    import_export
                  </span>
                  <span
                    onClick={(e) => handleFilter(e, "payment_type")}
                    class={` align-middle material-icons  ${
                      payment_type.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.payment_type === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Online"
                              value="1"
                              id="Online"
                              checked={payment_type.some((element) => {
                                if (element.name === "Online") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Online")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Online
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Offline"
                              value="0"
                              id="Offline"
                              checked={payment_type.some((element) => {
                                if (element.name === "Offline") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Offline")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Offline
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("payment_type")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("payment_type")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "category_code")}
                  >
                    Category
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "category_code")}
                  >
                    import_export
                  </span>
                  <span
                    onClick={(e) => handleFilter(e, "category_code")}
                    class={` align-middle material-icons  ${
                      category_code.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.category_code === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="UPSC"
                              value="UPSC"
                              id="UPSC"
                              checked={category_code.some((element) => {
                                if (element.name === "UPSC") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "UPSC", "category_code")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              UPSC
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TNPSC"
                              value="TNPSC"
                              id="TNPSC"
                              checked={category_code.some((element) => {
                                if (element.name === "TNPSC") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  "TNPSC",
                                  "category_code"
                                )
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TNPSC
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="RRB"
                              value="RRB"
                              id="RRB"
                              checked={category_code.some((element) => {
                                if (element.name === "RRB") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "RRB", "category_code")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              RRB
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TET"
                              value="TET"
                              id="TET"
                              checked={category_code.some((element) => {
                                if (element.name === "TET") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TET", "category_code")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TET
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TRB"
                              value="TRB"
                              id="TRB"
                              checked={category_code.some((element) => {
                                if (element.name === "TRB") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TRB", "category_code")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TRB
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TNUSRB"
                              value="TNUSRB"
                              id="TNUSRB"
                              checked={category_code.some((element) => {
                                if (element.name === "TNUSRB") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  "TNUSRB",
                                  "category_code"
                                )
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              TNUSRB
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="SSC"
                              value="SSC"
                              id="SSC"
                              checked={category_code.some((element) => {
                                if (element.name === "SSC") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "SSC", "category_code")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              SSC
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Banking"
                              value="Banking"
                              checked={category_code.some((element) => {
                                if (element.name === "Banking") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  "Banking",
                                  "category_code"
                                )
                              }
                              id="Banking"
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Banking
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("category_code")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("category_code")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "course_name")}
                  >
                    Course
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "course_name")}
                  >
                    import_export
                  </span>
                  {/* <span
                      class={` align-middle material-icons  ${
                        course_name.length > 0 ? "active_icons" : ""
                      }`}
                      onClick={(e) => handleFilter(e, "course_name")}
                    >
                      filter_list
                    </span> */}
                  {/* {isOpen.course_name === true && (
                        <div className="filterBoxs p-1">
                        <div className="filterBoxText">
                        <div className="row ms-1">
                            <div className="col-lg-12 col-md-12">
                              <div class="input-group searchInputsss">
                                <input
                                  type="text"
                                  class="Inputs "
                                  id="SearchFilterInputs"
                                  name="SearchFilterInputs"
                                  placeholder="Search Here"
                                  // onChange={handleSearch}
                                  onKeyDown={handleFilterCourseSearch}
                                />
                                <span class="input-group-text" id="SearchInput">
                                  <span className="material-icons">search</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr/>
                          {dCourses.map((el) => (
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <div class="form-check ms-3">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name={el} // Use el.label to set the name and value
                                    value={el}
                                    id={el}
                                    checked={course_name.some((element) => {
                                      if (element.name === el) {
                                        return true;
                                      }
                                      return false;
                                    })}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, "course_name")
                                    }
                                  />
                                  <label
                                    class="form-check-label PinkText preWrap"
                                    for="defaultCheck1"
                                  >
                                    {el}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
  
                        <hr />
                        <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("course_name")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("course_name")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )} */}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    Section
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "section")}
                  >
                    import_export
                  </span>
                  <span
                    onClick={(e) => handleFilter(e, "section")}
                    class={` align-middle material-icons  ${
                      section.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.section === true && (
                    <div className="filterBoxTwo p-1">
                      <div className="row ms-1">
                        <div className="col-lg-12 col-md-12">
                          <div class="input-group searchInputsss">
                            <input
                              type="text"
                              class="Inputs "
                              id="SearchFilterInputs"
                              name="SearchFilterInputs"
                              placeholder="Search Here"
                              // onChange={handleSearch}
                              onKeyDown={handleFilterSectionSearch}
                            />
                            <span class="input-group-text" id="SearchInput">
                              <span className="material-icons">search</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      {dSection.map((el) => (
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name={el} // Use el.label to set the name and value
                                value={el}
                                id={el}
                                checked={section.some((element) => {
                                  if (element.name === el) {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "section")
                                }
                              />
                              <label
                                class="form-check-label PinkText preWrap"
                                for="defaultCheck1"
                              >
                                {el}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("section")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            disabled={section.length === 0 ? true : false}
                            onClick={() => handleApplyFilter("section")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "type")}
                  >
                    Type
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "type")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    onClick={(e) => handleFilter(e, "type")}
                    class={` align-middle material-icons  ${
                      typeOn.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.type === true && (
                    <div className="filterBoxTwo p-1">
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="course"
                              value="0"
                              checked={typeOn.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "course")
                              }
                              id="course"
                            />
                            <label
                              class="form-check-label YellowText"
                              for="defaultCheck1"
                            >
                              Course
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="TestBatch"
                              value="1"
                              checked={typeOn.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "TestBatch")
                              }
                              id="TestBatch"
                            />
                            <label
                              class="form-check-label GreenText"
                              for="defaultCheck1"
                            >
                              Test Batch
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="SpecialClass"
                              value="3"
                              checked={typeOn.some((element) => {
                                if (element.id === "3") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "SpecialClass")
                              }
                              id="SpecialClass"
                            />
                            <label
                              class="form-check-label YellowText"
                              for="defaultCheck1"
                            >
                              Special Class
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Counselling"
                              value="2"
                              checked={typeOn.some((element) => {
                                if (element.id === "2") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Counselling")
                              }
                              id="Counselling"
                            />
                            <label
                              class="form-check-label RedText"
                              for="defaultCheck1"
                            >
                              Counselling
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="MockInterview"
                              value="5"
                              checked={typeOn.some((element) => {
                                if (element.id === "5") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "MockInterview")
                              }
                              id="MockInterview"
                            />
                            <label
                              class="form-check-label RedText"
                              for="defaultCheck1"
                            >
                              Mock Interview
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="ModelExam"
                              value="4"
                              checked={typeOn.some((element) => {
                                if (element.id === "4") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "ModelExam")
                              }
                              id="ModelExam"
                            />
                            <label
                              class="form-check-label RedText"
                              for="defaultCheck1"
                            >
                              Model Exam
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("type")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            disabled={typeOn.length === 0 ? true : false}
                            onClick={() => handleApplyFilter("type")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "batch_name")}
                  >
                    Batch
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "batch_name")}
                  >
                    import_export
                  </span>
                  <span
                    onClick={(e) => handleFilter(e, "batch_name")}
                    class={` align-middle material-icons  ${
                      batch_name.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.batch_name === true && (
                    <div className="filterBoxTwo p-1">
                      <div className="row ms-1">
                        <div className="col-lg-12 col-md-12">
                          <div class="input-group searchInputsss">
                            <input
                              type="text"
                              class="Inputs "
                              id="SearchFilterInputs"
                              name="SearchFilterInputs"
                              placeholder="Search Here"
                              // onChange={handleSearch}
                              onKeyDown={handleFilterCourseSearch}
                            />
                            <span class="input-group-text" id="SearchInput">
                              <span className="material-icons">search</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      {dCourses.map((el) => (
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name={el} // Use el.label to set the name and value
                                value={el}
                                id={el}
                                checked={batch_name.some((element) => {
                                  if (element.name === el) {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "batch_name")
                                }
                              />
                              <label
                                class="form-check-label PinkText preWrap"
                                for="defaultCheck1"
                              >
                                {el}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("batch_name")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            disabled={batch_name.length === 0 ? true : false}
                            onClick={() => handleApplyFilter("batch_name")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "location_name")}
                  >
                    Location
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "location_name")}
                  >
                    import_export
                  </span>
                  <span
                    onClick={(e) => handleFilter(e, "location_name")}
                    class={` align-middle material-icons  ${
                      location_name.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.location_name === true && (
                    <div className="filterBoxTwo p-1">
                      <div className="row ms-1">
                        <div className="col-lg-12 col-md-12">
                          <div class="input-group searchInputsss">
                            <input
                              type="text"
                              class="Inputs "
                              id="SearchFilterInputs"
                              name="SearchFilterInputs"
                              placeholder="Search Here"
                              // onChange={handleSearch}
                              onKeyDown={handleFilterLocatonSearch}
                            />
                            <span class="input-group-text" id="SearchInput">
                              <span className="material-icons">search</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      {dLocation.map((el) => (
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name={el.name} // Use el.label to set the name and value
                                value={el.id}
                                id={el.name}
                                checked={location_name.some((element) => {
                                  if (element.name === el.name) {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "location_name")
                                }
                              />
                              <label
                                class="form-check-label PinkText preWrap"
                                for="defaultCheck1"
                              >
                                {el.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("location_name")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            disabled={location_name.length === 0 ? true : false}
                            onClick={() => handleApplyFilter("location_name")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "paid_amount")}
                  >
                    Paid <br /> Amount
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "paid_amount")}
                  >
                    import_export
                  </span>{" "}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "balance_amt")}
                  >
                    Balance <br /> Amount
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "balance_amt")}
                  >
                    import_export
                  </span>{" "}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "appliedOn")}
                  >
                    Applied <br /> On
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "appliedOn")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    onClick={(e) => handleFilter(e, "appliedOn")}
                    class={` align-middle material-icons  ${
                      appOn.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.appliedOn === true && (
                    <div className="filterBoxOne filter_mmm container p-1">
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="FDate"
                              className="d-flex form-label"
                            >
                              From Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                              id="FDate"
                              type="date"
                              name="FDate"
                              value={appData.FDate}
                              className="FilterInputs"
                              onChange={handleAppliedOn}
                              placeholder="FDate"
                              autoComplete="FDate"
                              required
                            /> */}
                            <DatePickerSample
                              IconInput={"filterIocnBoxInput"}
                              class={"FilterInputs"}
                              name={"FDate"}
                              handleChange={handleChangeDate}
                              fieldInput={appData.FDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="TDate "
                              className="d-flex form-label"
                            >
                              To Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                              id="TDate"
                              type="date"
                              name="TDate"
                              value={appData.TDate}
                              className="FilterInputs"
                              placeholder="TDate"
                              onChange={handleAppliedOn}
                              autoComplete="TDate"
                              required
                            /> */}
                            <DatePickerSample
                              class={"FilterInputs"}
                              IconInput={"filterIocnBoxInput"}
                              name={"TDate"}
                              handleChange={handleChangeDate}
                              fieldInput={appData.TDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              id="Today"
                              value="Today"
                              checked={appData.Days === "Today"}
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Today">
                              Today
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Yesterday"
                              checked={appData.Days === "Yesterday"}
                              id="Yesterday"
                              onChange={handleAppliedOn}
                            />
                            <span
                              class="form-check-label d-flex"
                              for="Yesterday"
                            >
                              Yesterday
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tweek"
                              checked={appData.Days === "Tweek"}
                              id="Tweek"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Tweek">
                              This week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Lweek"
                              checked={appData.Days === "Lweek"}
                              id="Lweek"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Lweek">
                              Last week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tmonth"
                              checked={appData.Days === "Tmonth"}
                              id="Tmonth"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Tmonth">
                              This month
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              checked={appData.Days === "Lmonth"}
                              value="Lmonth"
                              id="Lmonth"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Lmonth">
                              Last month
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("appliedOn")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("appliedOn")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "status")}
                  >
                    status
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "status")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    onClick={(e) => handleFilter(e, "status")}
                    class={` align-middle material-icons  ${
                      status.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.status === true && (
                    <div className="filterBox_bb p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="waitingForApproval"
                              value="0"
                              checked={status.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "waitingForApproval")
                              }
                              id="waitingForApproval"
                            />
                            <label
                              class="form-check-label YellowText"
                              for="waitingForApproval"
                            >
                              Waiting For Approval
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="approved"
                              value="1"
                              checked={status.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Approved")
                              }
                              id="Approved"
                            />
                            <label
                              class="form-check-label GreenText"
                              for="Approved"
                            >
                              Approved
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="onHold"
                              value="2"
                              checked={status.some((element) => {
                                if (element.id === "2") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "OnHold")
                              }
                              id="OnHold"
                            />
                            <label
                              class="form-check-label YellowText"
                              for="OnHold"
                            >
                              On Hold
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Rejected"
                              value="3"
                              checked={status.some((element) => {
                                if (element.id === "3") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Rejected")
                              }
                              id="Rejected"
                            />
                            <label
                              class="form-check-label RedText"
                              for="Rejected"
                            >
                              Rejected
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("status")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            disabled={status.length === 0 ? true : false}
                            onClick={() => handleApplyFilter("status")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  {" "}
                  <span className="text_th">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.length > 0 ? (
                <>
                  {currentData.map((el, index) => (
                    <tr key={index}>
                      <td id="td">
                        {el.comment === 1 && el.status !== 1 && (
                          <div className="purchase_dot_chat"></div>
                        )}
                        <span>{el.serialNumber}</span>
                      </td>
                      <td id="td">{el.fullname}</td>
                      <td id="td">{el.admission_id}</td>
                      <td id="td">{el.phone_no}</td>
                      <td id="td">
                        {el.mode_of_payment === 1 ? "Online" : "Offline"}
                      </td>
                      <td id="td">{el.category_code}</td>
                      <td id="td">{el.course_name}</td>
                      <td id="td">{el.section ? el.section : "_"}</td>
                      {el.type === 0 && (
                        <td id="td">
                          <span>course</span>
                        </td>
                      )}
                      {el.type === 1 && (
                        <td id="td">
                          <span>Test Batch</span>
                        </td>
                      )}
                      {el.type === 2 && (
                        <td id="td">
                          <span>Counselling</span>
                        </td>
                      )}
                      {el.type === 3 && (
                        <td id="td">
                          <span>Special classes</span>
                        </td>
                      )}
                      {el.type === 4 && (
                        <td id="td">
                          <span>Model exam </span>
                        </td>
                      )}
                      {el.type === 5 && (
                        <td id="td">
                          <span>Mock Interview </span>
                        </td>
                      )}
                      <td id="td">{el.batch_name}</td>
                      <td id="td">{el.location_name}</td>
                      <td id="td">
                        ₹ {el.paid_amount !== null ? el.paid_amount : 0}
                      </td>
                      <td id="td">
                        ₹ {el.balance_amt !== null ? el.balance_amt : 0}
                      </td>
                      <td id="td">
                        {moment(el.appliedOn).format("YYYY-MM-DD")}
                      </td>
                      {el.status === 2 && (
                        <td id="td">
                          <span class="YellowText">OnHold</span>
                        </td>
                      )}
                      {el.status === 1 && (
                        <td id="td">
                          <span class="GreenText">Approved</span>
                        </td>
                      )}
                      {el.status === 3 && (
                        <td id="td">
                          <span class="RedText">Rejected</span>
                        </td>
                      )}
                      {el.status === 0 && (
                        <td id="td">
                          <span class="YellowText">Waiting For Approval</span>
                        </td>
                      )}
                      <td>
                        {" "}
                        <span
                          onClick={(e) =>
                            handleEdit(
                              el.user_id,
                              el.adm_pk,
                              moment(el.appliedOn).format("YYYY-MM-DD"),
                              el.type
                            )
                          }
                          class="material-icons text-danger"
                        >
                          exit_to_app
                        </span>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  {/* <td colspan="10" style={{ textAlign: "center" }}>
        No record to display 
      </td> */}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {currentData.length > 0 ? (
        <div className="row">
          <div className="col-md-12 TableItemPerpage">
            <div className="pag w-100 dflexcenter mt-4">
              <span className="ipage">Items Per Page</span>
              <select
                name="pageCount"
                id="pageCount"
                defaultValue={rowsPerPage}
                onChange={handlePageCount}
                className="ms-2"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pagenumber ms-2">
                {" "}
                {currentPage * rowsPerPage + 1 - rowsPerPage} -{" "}
                {/* {currentPage * rowsPerPage} of {updateListData.length} */}
                {updateListData.length < currentPage * rowsPerPage
                  ? updateListData.length
                  : currentPage * rowsPerPage}{" "}
                of {updateListData.length}
              </span>
              <button
                className="prev_button ms-2 me-2"
                onClick={(e) => handlePrev(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span class="material-icons">chevron_left</span>
              </button>
              <button
                className="prev_button"
                onClick={() => handleNext(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <span class="material-icons">chevron_right</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div
            className="col-md-12 text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#e3e5e2",
            }}
          >
            <div className="col-md-3"> </div>
            <div className="col-md-6 r" role="alert">
              We're sorry, but there are no results that match the search and
              filter criteria you've applied. Please adjust your filters or try
              a different search term to see if you can find what you're looking
              for.
            </div>
            <div className="col-md-3"> </div>
          </div>
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12">
              <div className="pag w-100 dflexcenter">
                <span className="ipage">Items Per Page</span>
                <select
                  name="pageCount"
                  id="pageCount"
                  defaultValue={rowsPerPage}
                  onChange={handlePageCount}
                  className="ms-2"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <span className="pagenumber ms-2">
                  {" "}
                  {currentPage * rowsPerPage + 1 - rowsPerPage} -{" "}
                  {/* {currentPage * rowsPerPage} of {updateListData.length} */}
                  {updateListData.length < currentPage * rowsPerPage
                    ? updateListData.length
                    : currentPage * rowsPerPage}{" "}
                  of {updateListData.length}
                </span>
                <button
                  className="prev_button ms-2 me-2"
                  onClick={(e) => handlePrev(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <span class="material-icons">chevron_left</span>
                </button>
                <button
                  className="prev_button"
                  onClick={() => handleNext(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <span class="material-icons">chevron_right</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Listview;
