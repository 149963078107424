import React, { useEffect, useState } from 'react'
import { Calendar, TimeGrid, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosInstance } from '../../../../axios';
import CustomEvent from '../CourseBatch/CustomEvent';

const formats = {
  weekdayFormat: (date, culture, localizer) => {
    return localizer.format(date, 'ddd', culture);
  },
  dayHeaderFormat: (date, culture, localizer) => {
    return localizer.format(date, 'ddd', culture);
  },
  dayFormat: (date, culture, localizer) => {
    return localizer.format(date, 'D', culture);
  },
  monthHeaderFormat: (date, culture, localizer) => {
    return localizer.format(date, 'MMMM YYYY', culture);
  },
};
const eventStyleGetter = (event) => {
  const backgroundColor = '#' + event.hexColor;
  const style = {
    backgroundColor: backgroundColor,
    borderRadius: '0px',
    opacity: 0.8,
    color: 'white',
    border: '0px',
    display: 'block',
  };
  return {
    style: style,
  };
};

//  const  MyToolbar = ({ dates, view , onView ,onNavigate, label }) => {
const MyToolbar = ({ date, view, onView, onNavigate, label }) => {

  const { staff_id , eventFields } = useParams();
  const [events, setEvents] = useState([]);

  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedPickDate, setSelectedPickDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handlePrevClick = (newDate) => {
    // console.log(newDate);
    onNavigate('PREV');
    const dates = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    // console.log(dates,"skjhdasjfhksajhf");
    setSelectedPickDate(dates.toDate());
    const formattedDate = dates.format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    const datess = moment(formattedDate); // Replace with your desired date
    const startOfMonth = datess.startOf("month").toDate(); // Start date of the month
    const endOfMonth = datess.endOf("month").toDate(); // End date of the month

    AxiosInstance.post("api/staff/schedulelist", {
      staff_id: staff_id,
      start_date: startOfMonth,
      end_date: endOfMonth,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${event.start_time
              }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${event.end_time
              }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              start: startDate,
              end: endDate,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              startTime: event.start_time,
              endTime: event.end_time,
              description: event.description,
              syllabus_filepath:event.syllabus_filepath,
            };
          });
          setEvents(parsedEvents);
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handleFilter = (event) => {
    onView(event.target.value);
  };

  const handleNextClick = (newDate) => {
    onNavigate('NEXT');
    const dates = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    setSelectedPickDate(dates.toDate());
    const formattedDate = dates.format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    const datess = moment(formattedDate); // Replace with your desired date
    const startOfMonth = datess.startOf("month").toDate(); // Start date of the month
    const endOfMonth = datess.endOf("month").toDate(); // End date of the month

    AxiosInstance.post("api/staff/schedulelist", {
      staff_id: staff_id,
      start_date: startOfMonth,
      end_date: endOfMonth,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${event.start_time
              }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${event.end_time
              }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              start: startDate,
              end: endDate,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              startTime: event.start_time,
              endTime: event.end_time,
              description: event.description,
              syllabus_filepath:event.syllabus_filepath,
            };
          });
          setEvents(parsedEvents);
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handleChange = (dates) => {
    setSelectedPickDate(dates);
    const formattedDate = moment(dates).format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    setShowDatePicker(false);
    const newDate = moment(dates).toDate();
    onNavigate('DATE', newDate);
  };
  const handleToday = () => {
    const today = moment();
    onNavigate('TODAY');
    setSelectedDates(today.format("YYYY-MM-DD"));
    setSelectedPickDate(today.toDate());
    
  }
  return (
    <div className='row mb-3' id="mytoolbar">
      <div className='col-md-3'>
        <h4 className='CalendarTitle' onClick={toggleDatePicker}>{selectedDates ? moment(selectedDates).format("MMMM YYYY") : moment().format("MMMM YYYY")}
          <span class="material-icons align-bottom">arrow_drop_down</span>
        </h4>
        {showDatePicker && (
          <DatePicker
            selected={selectedPickDate}
            onChange={handleChange}
            inline
            className="datepicker"
          />
        )}
      </div>
      <div className='col-md-3'>
        <button className='calViewBtn' onClick={handleToday}>
          Go to today
        </button>
      </div>
      <div className='col-md-3 d-flex justify-content-lg-end justify-content-start'>
        <div className="up-in-toggle">
          <input
            type="radio"
            id="switch_left"
            name="view"
            value="month"
            checked={view === "month"}
            onChange={handleFilter}
          />
          <label htmlFor="switch_left">Month</label>
          <input
            type="radio"
            id="switch_right"
            name="view"
            value="week"
            checked={view === "week"}
            onChange={handleFilter}
          />
          <label htmlFor="switch_right">Week</label>
        </div>
      </div>
      <div className='col-md-3 d-flex justify-content-lg-end justify-content-start'>
        <button className='PrevBtns' onClick={() => handlePrevClick(moment(date).subtract(1, view === 'month' ? 'month' : 'week').toDate(), view)}>
          <span class="material-icons">
            navigate_before
          </span>
        </button>
        <button className='PrevBtns' onClick={() => handleNextClick(moment(date).add(1, view === 'month' ? 'month' : 'week').toDate(), view)}>
          <span class="material-icons">
            navigate_next
          </span>
        </button>
      </div>

      {/* <input value={moment(date).format('YYYY-MM-DD')}  onChange={handleChange} type="date"/> */}
    </div>
  );
};

function ScheduleView(props) {

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (props.eventFields) {
      setEvents(props.eventFields);
    }
  }, [props.eventFields,props.staff_id]);

  const moment = require('moment-timezone');
  const navigate = useNavigate();
  const [view, setView] = useState('month');
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedPickDate, setSelectedPickDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handleDateChange = (date) => {
    setSelectedPickDate(date);

    const formattedDate = moment(date).format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    setShowDatePicker(false);

  };
  const [date, setDate] = useState(moment().toDate());


  const handleNavigate = (newDate, newView) => {
 
    setDate(newDate);
    const date = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const formattedDate = date.format("YYYY-MM-DD");
    const dates = moment(formattedDate); // Replace with your desired date
    const startOfMonth = dates.startOf("month").toDate(); // Start date of the month
    const endOfMonth = dates.endOf("month").toDate(); // End date of the month
    setView(newView);
    AxiosInstance.post("api/staff/schedulelist", {
      staff_id: props.staff_id,
      start_date: startOfMonth,
      end_date: endOfMonth,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${event.start_time
              }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${event.end_time
              }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              start: startDate,
              end: endDate,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              startTime: event.start_time,
              endTime: event.end_time,
              description: event.description,
              syllabus_filepath:event.syllabus_filepath,
            };
          });
          setEvents(parsedEvents);
        }
      })
      .catch((error) => {
        return error;
      });

  };

  const handleView = (view) => {
    // console.log(view);
    setView(view);
  };

  const handleDrillDown = ()  => {
    setView('week');
  };



  const [selectedDate, setSelectedDate] = useState(null);



  const handleSelectEvent = (event) => {
    // console.log(event,"event");
    const sDates = event.start;
    const eDates = event.start;
    const outputFormat = 'YYYY-MM-DD';
    // console.log(sDates,"sDates");
    // create a new moment object using the input date string and parse it
    const momentObj = moment(sDates, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    const momentObjs = moment(sDates, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    // format the moment object using the output format
    // console.log(momentObj,"momentObj");
    const sDate = momentObj.format(outputFormat);
    const eDate = momentObjs.format(outputFormat);
    let id = event.id;
    let staff_id = props.staff_id;

    // console.log(sDate,"sDate");
    navigate('/StaffDetailCalendar', {
      state: {
        id,
        staff_id,
      },
    });
  };
  const handleFilter = (e) => {
    const filter = e.target.value;
    if (filter === 'month') {
      setView('month');
    } else if (filter === 'week') {
      setView('week');
    }
  };
  const handleTodayClick = () => {
    const now = new Date();
    this.calendarRef.current.getApi().gotoDate(now);
  };
  const localizer = momentLocalizer(moment)
  return (
    <>
      <div className='row'>

        {/* AIASA-000122  v1 issues solved by lalitha */}
        <Calendar
          weekStartsOn={1}
          localizer={localizer}
          events={events}
          startAccessor="start"
          onDrillDown={handleDrillDown} // Handle the "Show More" button click
          endAccessor="end"
          style={{ height: 700 }}
          view={view}
          // formats={formats}
          components={{
            toolbar: MyToolbar,
            event: (props) => <CustomEvent {...props} />,

          }}
      
          selectable
          onView={handleView}
          onSelectEvent={handleSelectEvent}
          date={date}
          onNavigate={handleNavigate}

        />
      </div>
    </>
  )
}
export default ScheduleView
