import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";

import { styled } from "@mui/material/styles";
import { AxiosInstance } from "../../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../../labels";
function StudentsQuery() {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);

  // List data load table

  // use state
  let page = R_PER_PAGE;
  // New LOCALsTOAGE

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  let user_type = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      user_type = user.user_type;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }
  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [csvListData, setCsvListData] = useState([]);
  const [isOpen, setIsOpen] = useState({
    // status: false,
    category: false,
    category_class: false, //New
    batch_name: false, //New
    section: false, //New
    event_type_title: false, //New
    emedium: false,
    query_count: false, //New
    reply_status: false, //New
    batch_pk: false, //New
    medium: false, //New
    course: false,
    test_topic: false,
    schedule: false,
  });

  let TeachingFaculty = 0;
  const [updateChipData, setUpdateChipData] = useState([]);

  const [status, setStatus] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [time, setTime] = useState([]);
  const [coursess, setcoursess] = useState([]); //category
  const [category, setCategory] = useState([]); //category
  const [schedule, setSchedule] = useState([]); //Schedule
  const [test, setTest] = useState([]); // testtopic
  // Subject
  const [ssubject, setSsubject] = useState([]);
  const [ssubjects, setSsubjects] = useState(ssubject);
  const [mediums, setMedium] = useState([]);
  // Event Name
  const [esubject, setEsubject] = useState([]);
  const [esubjects, setEsubjects] = useState(esubject);

  const [courseData, setCourseData] = useState([]);
  const [courseDatas, setCourseDatas] = useState(courseData);
  const [emediums, setEMedium] = useState([]);
  // Test Topic
  const [testTopic, setTestTopic] = useState([]);
  const [testTopics, setTestTopics] = useState(testTopic);
  const [test_topics, setTest_topics] = useState([]);

  const [classModes, setClassModes] = useState([]);

  const [dCourse, setDcourse] = useState([]);
  const [dCourses, setDcourses] = useState([]);
  let filterStatus = [];
  let filterType = [];
  let filterTime = [];
  let filterSchedule = [];
  let filtercourse = [];
  let filterTest = [];
  let filterCate = [];
  let filterClassMode = [];
  let filterMedium = [];
  let filterEMedium = [];
  let filterApplyed = [];
  let filterSearch = [];
  let filterTest_topics = [];

  useEffect(() => {
    filterStatus = status;
  }, [status]);
  useEffect(() => {
    filtercourse = coursess;
  }, [coursess]);
  useEffect(() => {
    filterType = typeOn;
  }, [typeOn]);
  useEffect(() => {
    //schedule
    filterSchedule = schedule;
  }, [schedule]);
  useEffect(() => {
    //Event Name
    filterEMedium = emediums;
  }, [emediums]);
  useEffect(() => {
    //schedule
    filterMedium = mediums;
  }, [mediums]);
  useEffect(() => {
    filterTest_topics = test_topics;
  }, [test_topics]);

  useEffect(() => {
    //testtopic
    filterTest = test;
  }, [test]);
  useEffect(() => {
    // console.log(" names : ", typeOn);
    filterCate = category;
  }, [category]);
  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  // useEffect(() => {
  //   console.log(" updateListData : ", updateListData);
  //   currentData = updateListData.slice(start, end);
  //   // total table data  with pages
  //   totalPages = Math.ceil(updateListData.length / rowsPerPage);
  // }, [updateListData]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    filterSchedule = schedule;
    filterCate = category;
    filtercourse = coursess;
    filterMedium = mediums;
    filterEMedium = emediums;
    filterTest_topics = test_topics;
    filterTest = test;
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;

    if (names === "Replied" || names === "Not Yet Replied") {
      if (checked) {
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (typename === "type") {
      if (checked) {
        setTypeOn([...typeOn, { name: value, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.name !== value));
      }
    } else if (typename === "category") {
      //category
      if (checked) {
        setCategory([...category, { name: value, type: "category" }]);
      } else {
        setCategory(category.filter((item) => item.name !== value));
      }
    } else if (names === "course") {
      //category
      if (checked) {
        setcoursess([...coursess, { name: value, type: "course" }]);
      } else {
        setcoursess(coursess.filter((item) => item.name !== value));
      }
    } else if (names === "schedule") {
      //schedule
      if (checked) {
        setSchedule([...schedule, { id: value, name: name, type: "schedule" }]);
      } else {
        setSchedule(schedule.filter((item) => item.id !== value));
      }
    } else if (names === "medium") {
      //mediums
      if (checked) {
        setMedium([...mediums, { id: value, name: name, type: "medium" }]);
      } else {
        setMedium(mediums.filter((item) => item.id !== value));
      }
    } else if (names === "emedium") {
      //Event Name
      if (checked) {
        setEMedium([...emediums, { id: value, name: name, type: "emedium" }]);
      } else {
        setEMedium(emediums.filter((item) => item.id !== value));
      }
    } else if (names === "test_topic") {
      if (checked) {
        setTest_topics([
          ...test_topics,
          { id: value, name: name, type: "test_topic" },
        ]);
      } else {
        setTest_topics(test_topics.filter((item) => item.id !== value));
      }
    } else if (names === "test") {
      //testtopic
      if (checked) {
        setTest([...test, { id: value, name: name, type: "test" }]);
      } else {
        setTest(test.filter((item) => item.id !== value));
      }
    }
  };

  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setSchedule([]);
    setcoursess([]);
    setMedium([]);
    setTest_topics([]);
    setTest([]);
    setUpdateChipData([]);
    setTypeOn([]);
    setCategory([]);

    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "reply_status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        reply_status: false,
      });
    } else if (name === "medium") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } else if (name === "emedium") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        emedium: false,
      });
    } else if (name === "event_name") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        event_name: false,
      });
    } else if (name === "test") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        test: false,
      });
    } else if (name === "category") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "course") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        course: false,
      });
    } else if (name === "schedule") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "test_topic") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        test_topic: false,
      });
    }
  };
  // NEW added Filter
  const handleFilterSearch = (event, name) => {
    if (name === "subject") {
      const searchInput = event.target.value.toLowerCase();
      if (searchInput !== "") {
        const matchingObjects = ssubject.filter((item) => {
          if (typeof item.subjectName === "string") {
            // Check if subjectName is a string
            return item.subjectName.toLowerCase().includes(searchInput);
          }
          return false; // Return false if subjectName is not a string
        });
        setSsubjects(matchingObjects);
      } else {
        setSsubjects(ssubject);
      }
    } else if (name === "event_name") {
      //Event Name
      const searchInput = event.target.value.toLowerCase();
      if (searchInput !== "") {
        const ematchingObjects = esubject.filter((item) => {
          if (typeof item.subjectName === "string") {
            // Check if subjectName is a string
            return item.subjectName.toLowerCase().includes(searchInput);
          }
          return false; // Return false if subjectName is not a string
        });
        setEsubjects(ematchingObjects);
      } else {
        setEsubjects(esubject);
      }
    } else if (name === "test_topic") {
      const searchInput = event.target.value.toLowerCase(); // Convert search input to lowercase

      if (searchInput !== "") {
        const matchingObjects = testTopic.filter((ed) => {
          if (typeof ed === "string") {
            // Check if ed is a string
            return ed.toLowerCase().includes(searchInput);
          }
          return false; // Return false if ed is not a string
        });
        setTestTopics(matchingObjects);
      } else {
        setTestTopics(testTopic);
      }
    } else {
      setTestTopics(testTopic);
      setSsubjects(ssubject);
      setEsubjects(esubject);
    }
  };

  // const handleFilterCourseSearch = (event) => {
  //   const searchInput = event.target.value;
  //   if (searchInput !== "") {
  //     const matchingObjects = dCourse.filter(
  //       (item) => item && item.toLowerCase().includes(searchInput.toLowerCase())
  //     );
  //     // Do something with the matchingObjects, such as updating state or displaying them in the UI
  //     setDcourses(matchingObjects);
  //   } else {
  //     setDcourses(dCourse);
  //   }
  // };

  function loadListdata() {
    let tempstatus = [];
    let temptypeon = [];
    let tempLocStatus = [];
    let tempLocEMedium = [];
    let tempEMedium = []; //Event Name
    let tempLocMedium = [];
    let tempMedium = []; //medium
    let tempCourse = []; //course
    let tempsche = []; //schedule
    let temptest = []; //testtopic
    let tempCate = []; //Category

    status.map((obj) => {
      tempstatus.push(obj.id);
    });
    mediums.map((obj) => {
      tempMedium.push(obj.id);
    });
    emediums.map((obj) => {
      tempEMedium.push(obj.id);
    });
    schedule.map((obj) => {
      tempsche.push(obj.name);
    });
    coursess.map((obj) => {
      tempCourse.push(obj.name);
    });
    test.map((obj) => {
      temptest.push(obj.name);
    });

    typeOn.map((obj) => {
      temptypeon.push(obj.name);
    });
    category.map((obj) => {
      tempCate.push(obj.name);
    });
    tempLocMedium = tempMedium.map(Number);
    tempLocEMedium = tempEMedium.map(Number);

    tempLocStatus = tempstatus.map(Number);

    let statusFilter = {
      user_id: userid,
      course: coursess.length > 0 ? tempCourse : "",
      test_topic: test.length > 0 ? temptest : "",
      subject: mediums.length > 0 ? tempLocMedium : "",
      event: emediums.length > 0 ? tempLocEMedium : "",
      category: category.length > 0 ? tempCate : "",
      status: status.length > 0 ? tempLocStatus : "",
      search: searchTerm.length > 0 ? searchTerm : "",
    };

    AxiosInstance.post("api/test/report/list/faculty", statusFilter)
      .then((res) => {
        // console.log("editadasd ", res.data.status);
        if (res.data.status === true) {
          const updatedData = res.data.data;
          const serialNumberAddedData = updatedData.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          // setUpdateListData(fiata);
          setUpdateListData(serialNumberAddedData);
          // setUpdateListData(updatedData);
          // Subject and Test Topic
          const subjectSet = res.data.data.flatMap((event) => event.subject);
          setSsubject(subjectSet);
          setSsubjects(subjectSet);
          //Event_Name
          const esubjectSet = res.data.data.flatMap((event) => ({
            event_name: event.event_name,
            event_pk: event.event_pk,
          }));
          setEsubject(esubjectSet);
          setEsubjects(esubjectSet);

          const uniqueCourseSet = new Set(
            res.data.data.flatMap((cou) => cou.category_class)
          );
          // Convert the Set back to an array
          const uniqueCourses = [...uniqueCourseSet];
          setCourseData(uniqueCourses);
          setCourseDatas(uniqueCourses);

          const testtopicSet = res.data.data.flatMap(
            (event) => event.test_topic
          );
          setTestTopic(testtopicSet);
          setTestTopics(testtopicSet);
        } else {
          setUpdateListData([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setUpdateListData([]);
        return error;
      });
  }

  function loadChipdata() {
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });
    filterSchedule.map((obj) => {
      tempArr.push(obj);
    });
    filterMedium.map((obj) => {
      tempArr.push(obj);
    });
    filterEMedium.map((obj) => {
      tempArr.push(obj);
    });
    filterTest_topics.map((obj) => {
      tempArr.push(obj);
    });
    filterTest.map((obj) => {
      tempArr.push(obj);
    });
    filterType.map((obj) => {
      tempArr.push(obj);
    });
    filterCate.map((obj) => {
      tempArr.push(obj);
    });
    filtercourse.map((obj) => {
      tempArr.push(obj);
    });
    setUpdateChipData(tempArr);
  }

  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "reply_status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        reply_status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        type: false,
      });
    } else if (name === "category") {
      setCategory([]);
      filterCate = [];

      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "schedule") {
      setSchedule([]);
      filterSchedule = [];

      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "course") {
      setcoursess([]);
      filtercourse = [];

      setIsOpen({
        ...isOpen,
        course: false,
      });
    } else if (name === "medium") {
      setMedium([]);
      filterMedium = [];

      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } else if (name === "emedium") {
      setEMedium([]);
      filterEMedium = [];
      setIsOpen({
        ...isOpen,
        emedium: false,
      });
    } else if (name === "test_topic") {
      setTestTopic([]);
      filterTest_topics = [];

      setIsOpen({
        ...isOpen,
        test_topic: false,
      });
    } else if (name === "test") {
      setTest([]);
      filterTest = [];

      setIsOpen({
        ...isOpen,
        test: false,
      });
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.name !== chipToDelete.name));
      filterType = typeOn.filter((item) => item.name !== chipToDelete.name);
    } else if (chipToDelete.type === "category") {
      setCategory(category.filter((item) => item.id !== chipToDelete.id));
      filterCate = category.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "course") {
      setcoursess(coursess.filter((item) => item.id !== chipToDelete.id));
      filtercourse = coursess.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "schedule") {
      setSchedule(schedule.filter((item) => item.id !== chipToDelete.id));
      filterSchedule = schedule.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "medium") {
      setMedium(mediums.filter((item) => item.id !== chipToDelete.id));
      filterMedium = mediums.filter((item) => item.id !== chipToDelete.id);
    }
    // Event_Name
    else if (chipToDelete.type === "emedium") {
      setEMedium(emediums.filter((item) => item.id !== chipToDelete.id));
      filterEMedium = emediums.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "test_topic") {
      setTestTopic(testTopics.filter((item) => item.id !== chipToDelete.id));
      filterTest_topics = testTopics.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "test") {
      setTest(test.filter((item) => item.id !== chipToDelete.id));
      filterTest = test.filter((item) => item.id !== chipToDelete.id);
    } else {
      filterApplyed = [];
    }
    loadChipdata();
  };

  const handleFilter = (e, boxName) => {
    if (boxName === "reply_status") {
      setIsOpen((prevState) => ({
        medium: false,
        category: false,
        course: false,
        emedium: false,
        schedule: false,
        test_topic: false,
        reply_status: !prevState.reply_status,
      }));
    } else if (boxName === "category") {
      setIsOpen((prevState) => ({
        medium: false,
        emedium: false,
        schedule: false,
        reply_status: false,
        test_topic: false,
        category: !prevState.category,
      }));
    } else if (boxName === "course") {
      setIsOpen((prevState) => ({
        medium: false,
        schedule: false,
        reply_status: false,
        category: false,
        emedium: false,
        test_topic: false,
        course: !prevState.course,
      }));
    } else if (boxName === "emedium") {
      setIsOpen((prevState) => ({
        medium: false,
        schedule: false,
        reply_status: false,
        category: false,
        course: false,
        test_topic: false,
        emedium: !prevState.emedium,
      }));
    } else if (boxName === "test_topic") {
      setIsOpen((prevState) => ({
        medium: false,
        schedule: false,
        reply_status: false,
        category: false,
        course: false,
        emedium: false,
        test_topic: !prevState.test_topic,
      }));
    } else if (boxName === "schedule") {
      setIsOpen((prevState) => ({
        medium: false,
        reply_status: false,
        category: false,
        course: false,
        emedium: false,
        test_topic: false,
        schedule: !prevState.schedule,
      }));
    } else if (boxName === "medium") {
      setIsOpen((prevState) => ({
        schedule: false,
        reply_status: false,
        category: false,
        course: false,
        emedium: false,
        test_topic: false,
        medium: !prevState.medium,
      }));
    }
  };

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Landscape orientation
      unit: "in", // Use inches as the unit of measurement
      format: [9.5, 11], // Set the dimensions to a standard letter size (9.5 x 11 inches)
    });

    doc.setFont("arabic"); // Set the font to 'arabic'
    doc.setFontSize(12); // Adjust the desired font size
    // Add a title to the PDF document
    doc.text("Course batch data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    const headers = [
      "fullname",
      "faculty_id",
      "mobile_no",
      "email",
      "category",
      "reply_status", //New
      "category_class", //New
      "course",
      "subject",
      "test_topic",
      "event_name",
    ]; // Assuming updateListData is your state variable

    tableData.push(headers);

    updateListData.forEach((item) => {
      const rowData = [];
      headers.forEach((header) => {
        rowData.push(item[header]);
      });
      tableData.push(rowData);
    });

    // Define the column widths for the header columns (adjust as needed)
    // Calculate column widths based on the content of header and tbody cells
    const headerColumnStyles = {};
    const tbodyColumnStyles = {};

    headers.forEach((header, index) => {
      const maxHeaderWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 1 + 6; // Adjust the multiplier as needed
          })
      );
      const maxTbodyWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue = row[index] !== null ? row[index].toString() : ""; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 2 + 6; // Adjust the multiplier as needed
          }) // Adjust the multiplier as needed
      );

      headerColumnStyles[index] = { columnWidth: maxHeaderWidth };
      tbodyColumnStyles[index] = { columnWidth: maxTbodyWidth };
    });

    // Add data rows to tableData array
    const tableOptions = {
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
      // columnStyles: { ...headerColumnStyles, ...tbodyColumnStyles }, // Specify dynamic column widths
    };

    // Add the table to the PDF document
    doc.autoTable(tableOptions);

    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);
  const handleReviewPage = (userId) => {
    navigate(`/ReviewFacultyProfile/${userId}`);
  };
  const handleReadView = (userId) => {
    navigate(`/readfaculty/${userId}`);
  };

  const handleDetailView = (batch_id, event_id) => {
    navigate(`/Query`, {
      state: {
        userId: userid,
        batch_id: batch_id,
        event_id: event_id,
      },
    });
  };

  const handleEditView = (userId) => {
    navigate(`/Faculties/addfaculty/${userId}`);
  };
  // console.log(dCourses,"dCourses");
  // className="row tab-content mt-4"
  return (
    <div className=" BathDet ">
      <h6 class="Title">Student's Query</h6>
      <div className="row tab-content mt-4 " id="ex2-content">
        <div className="tab-pane fade show active col-md-12">
          {updateChipData.length > 0 ? (
            <div className="row mt-3">
              <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 0,
                  }}
                  component="ul"
                >
                  {updateChipData.map((data, index) => {
                    // console.log(data, " - data - ", index);
                    let icon;
                    let key = "" + data.name;
                    return (
                      <ListItem key={data}>
                        <Chip
                          icon={icon}
                          label={key}
                          onDelete={handleDelete(data, index)}
                        />
                      </ListItem>
                    );
                  })}

                  <ListItem>
                    {/* <Chip
                      label=" Clear All Filters"
                      onClick={handleAllClear}
                    /> */}
                    <a className="allclear" href="#" onClick={handleAllClear}>
                      Clear All Filters
                    </a>
                  </ListItem>
                </Paper>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12 table-responsive p-0">
              <table className="table" id="example">
                <thead>
                  <tr>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "serialNumber")}
                      >
                        S.No
                      </span>
                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "serialNumber")}
                      >
                        {" "}
                        import_export
                      </span>
                    </th>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "category")}
                      >
                        Category
                      </span>

                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "category")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${
                          typeOn.length > 0 ? "active_icons" : ""
                        }`}
                        onClick={(e) => handleFilter(e, "category")}
                      >
                        filter_list
                      </span>
                      {isOpen.category === true && (
                        <div className="filterBoxs p-1">
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="UPSC"
                                  value="UPSC"
                                  id="UPSC"
                                  checked={category.some((element) => {
                                    if (element.name === "UPSC") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "UPSC", "category")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  UPSC
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="TNPSC"
                                  value="TNPSC"
                                  id="TNPSC"
                                  checked={category.some((element) => {
                                    if (element.name === "TNPSC") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "TNPSC", "category")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  TNPSC
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="RRB"
                                  value="RRB"
                                  id="RRB"
                                  checked={category.some((element) => {
                                    if (element.name === "RRB") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "RRB", "category")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  RRB
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="TET"
                                  value="TET"
                                  id="TET"
                                  checked={category.some((element) => {
                                    if (element.name === "TET") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "TET", "category")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  TET
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="TRB"
                                  value="TRB"
                                  id="TRB"
                                  checked={category.some((element) => {
                                    if (element.name === "TRB") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "TRB", "category")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  TRB
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="TNUSRB"
                                  value="TNUSRB"
                                  id="TNUSRB"
                                  checked={category.some((element) => {
                                    if (element.name === "TNUSRB") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      "TNUSRB",
                                      "category"
                                    )
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  TNUSRB
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="SSC"
                                  value="SSC"
                                  id="SSC"
                                  checked={category.some((element) => {
                                    if (element.name === "SSC") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "SSC", "category")
                                  }
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  SSC
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="Banking"
                                  value="Banking"
                                  checked={category.some((element) => {
                                    if (element.name === "Banking") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      "Banking",
                                      "category"
                                    )
                                  }
                                  id="Banking"
                                />
                                <label
                                  class="form-check-label "
                                  for="defaultCheck1"
                                >
                                  Banking
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4  col-4 col-sm-4">
                              <span
                                className="clearFilter ms-2 "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("category")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8  col-8 col-sm-8">
                              <button
                                className="FilterButton"
                                onClick={() => handleApplyFilter("category")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "category_class")}
                      >
                        Course
                      </span>

                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "category_class")}
                      >
                        import_export
                      </span>
                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleFilter(e, "course")}
                      >
                        filter_list
                      </span>
                      {isOpen.course === true && (
                        <div className="filterBoxs p-1">
                          <div className="filterBoxText ">
                            <div className="row ms-1">
                              <div className="col-lg-12 col-md-12">
                                {/* <div class="input-group searchInputsss">
                                  <input
                                    type="text"
                                    class="Inputs width_75"
                                    id="SearchFilterInput"
                                    name="SearchFilterInput"
                                    placeholder="Search Here"
                                    // onChange={handleSearch}
                                    onKeyDown={(e) =>
                                      handleFilterSearch(e, "course")
                                    }
                                  />
                                  <span
                                    class="input-group-text"
                                    id="SearchInput"
                                  >
                                    <span className="material-icons">
                                      search
                                    </span>
                                  </span>
                                </div> */}
                              </div>
                            </div>
                            <hr />
                            {courseData.map((el) => (
                              <div className="row mt-2 ">
                                <div className="col-md-12">
                                  <div class="form-check ms-3">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      name={el}
                                      value={el}
                                      id={el}
                                      checked={coursess.some((element) => {
                                        // console.log(
                                        //   element.name,
                                        //   "element.name",
                                        //   el
                                        // );
                                        if (element.name === el) {
                                          return true;
                                        }
                                        return false;
                                      })}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, "course")
                                      }
                                    />
                                    <label
                                      class="form-check-label PinkText"
                                      for="defaultCheck1"
                                    >
                                      {el}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4  col-4 col-sm-4">
                              <span
                                className="clearFilter ms-2 "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("course")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8  col-8 col-sm-8">
                              <button
                                className="FilterButton"
                                onClick={() => handleApplyFilter("course")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>

                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "batch_name")}
                      >
                        Batch Name
                      </span>
                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "batch_name")}
                      >
                        {" "}
                        import_export{" "}
                      </span>{" "}
                    </th>
                    {/* Event Name */}
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "event_name")}
                      >
                        Event Name
                      </span>

                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "event_name")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${
                          typeOn.length > 0 ? "active_icons" : ""
                        }`}
                        onClick={(e) => handleFilter(e, "emedium")}
                      >
                        filter_list
                      </span>
                      {isOpen.emedium === true && (
                        <div className="filterBoxs p-1">
                          <div className="filterBoxText ">
                            <div className="row ms-1">
                              <div className="col-lg-12 col-md-12">
                                <div class="input-group searchInputsss">
                                  <input
                                    type="text"
                                    class="Inputs width_75"
                                    id="SearchFilterInput"
                                    name="SearchFilterInput"
                                    placeholder="Search Here"
                                    // onChange={handleSearch}
                                    onKeyDown={(e) =>
                                      handleFilterSearch(e, "event_name")
                                    }
                                  />
                                  <span
                                    class="input-group-text"
                                    id="SearchInput"
                                  >
                                    <span className="material-icons">
                                      search
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {esubjects.map((el) => (
                              <div className="row mt-2 ">
                                <div className="col-md-12">
                                  <div class="form-check ms-3">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      name={el.event_name}
                                      value={el.event_pk}
                                      id={el.event_name}
                                      checked={emediums.some((element) => {
                                        if (element.id == el.event_pk) {
                                          return true;
                                        }
                                        return false;
                                      })}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, "emedium")
                                      }
                                    />
                                    <label
                                      class="form-check-label PinkText"
                                      for="defaultCheck1"
                                    >
                                      {el.event_name}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4  col-4 col-sm-4">
                              <span
                                className="clearFilter ms-2 "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("emedium")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8  col-8 col-sm-8">
                              <button
                                className="FilterButton"
                                onClick={() => handleApplyFilter("emedium")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    {/* <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "email")}
                      >
                        Test Number
                      </span>
                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "email")}
                      >
                        import_export
                      </span>{" "}
                    </th> */}

                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "subject")}
                      >
                        Subject
                      </span>{" "}
                      <span
                        class="material-icons align-middle "
                        onClick={(e) => handleSort(e, "subject")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons   ${
                          mediums.length > 0 ? "active_icons" : ""
                        }`}
                        onClick={(e) => handleFilter(e, "medium")}
                      >
                        filter_list
                      </span>
                      {isOpen.medium === true && (
                        <div className="filterBoxs p-1">
                          <div className="filterBoxText ">
                            <div className="row ms-1">
                              <div className="col-lg-12 col-md-12">
                                <div class="input-group searchInputsss">
                                  <input
                                    type="text"
                                    class="Inputs width_75"
                                    id="SearchFilterInput"
                                    name="SearchFilterInput"
                                    placeholder="Search Here"
                                    // onChange={handleSearch}
                                    onKeyDown={(e) =>
                                      handleFilterSearch(e, "subject")
                                    }
                                  />
                                  <span
                                    class="input-group-text"
                                    id="SearchInput"
                                  >
                                    <span className="material-icons">
                                      search
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {ssubjects.map((el) => (
                              <div className="row mt-2 ">
                                <div className="col-md-12">
                                  <div class="form-check ms-3">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      name={el.subject}
                                      value={el.subject_pk}
                                      id={el.subject}
                                      checked={mediums.some((element) => {
                                        if (element.id == el.subject_pk) {
                                          return true;
                                        }
                                        return false;
                                      })}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, "medium")
                                      }
                                    />
                                    <label
                                      class="form-check-label PinkText"
                                      for="defaultCheck1"
                                    >
                                      {el.subject}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4  col-4 col-sm-4">
                              <span
                                className="clearFilter ms-2 "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("medium")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8  col-8 col-sm-8">
                              <button
                                className="FilterButton"
                                onClick={() => handleApplyFilter("medium")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>

                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "test_topic")}
                      >
                        Test Topic
                      </span>

                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "test_topic")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${
                          test_topics.length > 0 ? "active_icons" : ""
                        }`}
                        onClick={(e) => handleFilter(e, "test_topic")}
                      >
                        filter_list
                      </span>
                      {isOpen.test_topic === true && (
                        <div className="filterBoxs p-1">
                          <div className="filterBoxText ">
                            <div className="row ms-1">
                              <div className="col-lg-12 col-md-12">
                                <div class="input-group searchInputsss">
                                  <input
                                    type="text"
                                    class="Inputs width_75"
                                    id="SearchFilterInput"
                                    name="SearchFilterInput"
                                    placeholder="Search Here"
                                    // onChange={handleSearch}
                                    onKeyDown={(e) =>
                                      handleFilterSearch(e, "test_topic")
                                    }
                                  />
                                  <span
                                    class="input-group-text"
                                    id="SearchInput"
                                  >
                                    <span className="material-icons">
                                      search
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {testTopics.map((el) => (
                              <div className="row mt-2 ">
                                <div className="col-md-12">
                                  <div class="form-check ms-3">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      name={el.test_topic}
                                      value={el.test_topic_pk}
                                      id={el.test_topic}
                                      checked={test_topics.some((element) => {
                                        if (element.id == el.test_topic_pk) {
                                          return true;
                                        }
                                        return false;
                                      })}
                                      onChange={(e) =>
                                        handleCheckboxChange(e, "test_topic")
                                      }
                                    />
                                    <label
                                      class="form-check-label PinkText"
                                      for="defaultCheck1"
                                    >
                                      {el.test_topic}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4  col-4 col-sm-4">
                              <span
                                className="clearFilter ms-2 "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("test_topic")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8  col-8 col-sm-8">
                              <button
                                className="FilterButton"
                                onClick={() => handleApplyFilter("test_topic")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "query_count")}
                      >
                        Query Count
                      </span>
                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "query_count")}
                      >
                        import_export
                      </span>{" "}
                    </th>
                    <th id="th">
                      <span
                        className="text_th"
                        onClick={(e) => handleSort(e, "reply_status")}
                      >
                        Status
                      </span>

                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "reply_status")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${
                          status.length > 0 ? "active_icons" : ""
                        }`}
                        onClick={(e) => handleFilter(e, "reply_status")}
                      >
                        filter_list
                      </span>
                      {isOpen.reply_status === true && (
                        <div className="filterBoxs p-1">
                          <div className="row mt-4">
                            <div className="col-md-12"></div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="Not Yet Replied"
                                  value="0"
                                  id="Not Yet Replied"
                                  checked={status.some((element) => {
                                    if (element.id === "0") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "Not Yet Replied")
                                  }
                                />
                                <label
                                  class="form-check-label PinkText"
                                  for="defaultCheck1"
                                >
                                  Not Yet Replied
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="Replied"
                                  value="1"
                                  id="Replied"
                                  checked={status.some((element) => {
                                    if (element.id === "1") {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "Replied")
                                  }
                                />
                                <label
                                  class="form-check-label GreenText"
                                  for="defaultCheck1"
                                >
                                  Replied
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4  col-4 col-sm-4">
                              <span
                                className="clearFilter ms-2 "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("reply_status")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8  col-8 col-sm-8">
                              <button
                                className="FilterButton"
                                onClick={() =>
                                  handleApplyFilter("reply_status")
                                }
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    <th id="th">
                      {" "}
                      <span className="text_th">Action</span>{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.length === 0 ? (
                    <tr>
                      <td colspan="10" style={{ textAlign: "center" }}>
                        No record to display
                      </td>
                    </tr>
                  ) : (
                    currentData.map((course, index) => (
                      <tr key={index}>
                        <td id="td">{course.serialNumber}</td>
                        <td id="td">{course.category}</td>
                        <td id="td">{course.category_class}</td>
                        <td id="td">{course.batch_name}</td>
                        <td id="td">{course.event_name}</td>
                        <td id="td">
                          {course.subject.length > 0
                            ? course.subject
                                .map((subject) => subject.subject)
                                .join(", ")
                            : "_"}
                        </td>
                        <td id="td">
                          {course.test_topic.length > 0
                            ? course.test_topic
                                .map((test_topic) => test_topic.test_topic)
                                .join(", ")
                            : "_"}
                        </td>
                        {/* <td id="td">{course.subject}</td>
                        <td id="td">{course.test_topic}</td> */}
                        <td id="td">{course.query_count}</td>

                        {course.reply_status === 0 && (
                          <td id="td" style={{ width: "13%" }}>
                            <span className="RedText">Not Yet Replied </span>
                          </td>
                        )}

                        {course.reply_status === 1 && (
                          <td id="td" style={{ width: "13%" }}>
                            <span className="GreenText">Replied </span>
                          </td>
                        )}
                        {(course.reply_status === 0 ||
                          course.reply_status === 1) && (
                          <td id="td" style={{ width: "13%" }}>
                            <button
                              className="button"
                              onClick={() =>
                                handleDetailView(
                                  course.batch_pk,
                                  course.event_pk
                                )
                              }
                            >
                              <span class="material-icons">visibility</span>
                            </button>
                          </td>
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-3 ">
            <div className="col-md-12">
              <div className="pag pag_fff w-100 dflexcenter">
                <span className="ipage">Items Per Page</span>
                <select
                  name="pageCount"
                  id="pageCount"
                  defaultValue={rowsPerPage}
                  onChange={handlePageCount}
                  className="ms-2"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <span className="pagenumber ms-2">
                  {" "}
                  {updateListData.length > 0
                    ? currentPage * rowsPerPage + 1 - rowsPerPage
                    : 0}{" "}
                  -
                  {updateListData.length < currentPage * rowsPerPage
                    ? updateListData.length
                    : currentPage * rowsPerPage}{" "}
                  of {updateListData.length}
                </span>
                <button
                  className="prev_button ms-2 me-2"
                  onClick={(e) => handlePrev(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <span class="material-icons">chevron_left</span>
                </button>
                <button
                  className="prev_button"
                  onClick={() => handleNext(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <span class="material-icons">chevron_right</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsQuery;
