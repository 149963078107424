export const FormValidationFocus = (data,focus_var) => {
    const errors = {};
  
    if (!data.FirstName) {
      focus_var.firstName.focus();
    } else if (!data.LastName) {
      focus_var.lastName.focus();
    } else if (!data.Fathername) {
      focus_var.father.focus();
    } else if (!data.MotherName) {
      focus_var.mother.focus();
    } else if (!data.DOB) {
      focus_var.mdob.focus();
    } else if (!data.age) {
      focus_var.age.focus();
    } else if (isNaN(data.age)) {
      focus_var.age.focus();
    } else if (!data.gender) {
      focus_var.gender.focus();
    } else if (!data.MaritalStatus) {
      focus_var.mStatus.focus();
    } else if (!data.Profilephoto) {
      focus_var.pSize.focus();
    }
    //  else if (data.Profilephoto.size > 2 * 1024 * 1024) {
    //   focus_var.pSize.focus();
    // }
     else if (!data.aadharNo) {
      focus_var.ano.focus();
    }
    //    else if (!/^[2-9]\d{3}\s\d{4}\s\d{4}$/.test(data.aadharNo)) {
    //   }
    else if (!data.aadharCardphoto) {
      focus_var.apath.focus();
    }
    //  else if (data.aadharCardphoto.size > 2 * 1024 * 1024) {
    //   focus_var.apath.focus();
    // }
     else if (!data.Cast) {
      focus_var.cno.focus();
    } else if (!data.CastFile || data.CastFile.length === 0) {
      focus_var.cpath.focus();
    }
    //  else if (data.CastFile.size > 2 * 1024 * 1024) {
    //   focus_var.cpath.focus();
    // }
     else if (!data.Mobile) {
      focus_var.pno.focus();
    } else if (data.Mobile && data.Mobile.length < 10) {
      focus_var.pno.focus();
    } else if (!data.Mobile  &&  !/^\d+$/.test(data.Mobile)) {
      focus_var.pno.focus();
    } else if (!data.WhatsAppNumber && data.ContactNumber === false) {
      focus_var.wno.focus();
    }  else if (data.WhatsAppNumber && data.WhatsAppNumber.length < 10 && data.ContactNumber === false) {
      focus_var.wno.focus();
    } else if (!/^\d+$/.test(data.WhatsAppNumber) && data.ContactNumber === false) {
      focus_var.wno.focus();
    }  else if (!data.ParentMobileNo) {
      focus_var.fpo.focus();
    } else if (data.ParentMobileNo && data.ParentMobileNo.length < 10) {
      focus_var.fpo.focus();
    } else if (!data.ParentMobileNo  &&  !/^\d+$/.test(data.ParentMobileNo)) {
      focus_var.fpo.focus();
    } else if (!data.emailaddress) {
      focus_var.email.focus();
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailaddress)) {
      focus_var.email.focus();
    }
    else if (!data.BuildingNumberPresent) {
          focus_var.phno.focus();
        } else if (!data.StreetNamePresent) {
          focus_var.psname.focus();
        }
         else if (!data.AreaPresent) {
          focus_var.parea.focus();
        }
        else if (!data.LandmarkPresent) {
          focus_var.pland.focus();
        } 
        else if (!data.CityPresent) {
          focus_var.pcity.focus();
        }
        else if (!data.districtPresent) {
          focus_var.pdistrict.focus();
        }  else if (!data.statePresent) {
          focus_var.pstate.focus();
        } else if (!data.PincodePresent) {
          focus_var.ppincode.focus();
        }
        else if (!/^[1-9][0-9]{5}$/.test(data.PincodePresent)) {
          focus_var.ppincode.focus();
        }
        else if (!data.BuildingNumberPermanent  && data.SamePresentAddress === false ) {
          focus_var.perhno.focus();
        } else if (!data.StreetNamePermanent  && data.SamePresentAddress === false ) {
          focus_var.persname.focus();
        } 
        else if (!data.AreaPermanent  && data.SamePresentAddress === false ) {
          focus_var.perarea.focus();
        }
        else if ( !data.LandmarkPermanent  && data.SamePresentAddress === false) {
          focus_var.perland.focus();
        } 
        else if (!data.CityPermanent  && data.SamePresentAddress === false ) {
          focus_var.percity.focus();
        } 
        else if ( !data.districtPermanent  && data.SamePresentAddress === false) {
          focus_var.perdistrict.focus();
        } 
        else if (!data.statePermanent  && data.SamePresentAddress === false ) {
          focus_var.perstate.focus();
        } else if (!data.PincodePermanent  && data.SamePresentAddress === false ) {
          focus_var.perpincode.focus();
        } else if (!/^[1-9][0-9]{5}$/.test(data.PincodePermanent) && data.SamePresentAddress === false ) {
          focus_var.perpincode.focus();
        }
  
    return errors;
  };
  