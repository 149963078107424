import moment from 'moment';
import React from 'react'

export default function ReviewCounselingPanel(props) {
  // console.log(props);
  return (
    <section className="StepOne">
    <div className="row box">
      <div className="col-md-12">
        <p className="StepTitle">Panel Details</p>
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                    Panel Name
                  </label>
                  <span className="ReviewLabel">
                  {props.record.panel_name}
              
                  </span>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                     Date
                  </label>
                  <span className="ReviewLabel">
                  {props.record.date}
                
                  </span>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                     StartTime
                  </label>
                  <span className="ReviewLabel">
                  {/* {props.record.StartTime} */}
                  {moment(props.record.StartTime, "h:mm:ss A").format("h:mm A")}
                
                  </span>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                     EndTime
                  </label>
                  <span className="ReviewLabel">
                  {/* {props.record.EndTime} */}
                  {moment(props.record.EndTime, "h:mm:ss A").format("h:mm A")}
                
                  </span>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                     Department
                  </label>
                  <span className="ReviewLabel">
                  {props.record.department.length> 0 ? props.record.department.join(',') : "_"}
                
                  </span>
                </div>
              </div>
              
              <div className="col-lg-3 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                 Panel Members
                  </label>
                  <span className="ReviewLabel">
                  {props.record.CPaneMem.length> 0 ? props.record.CPaneMem.map((staff) => staff.name).join(', ') : "_"}
                
                  </span>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                  Teaching Faculty
                  </label>
                  <span className="ReviewLabel">
                    {" "}
                    {props.record.CStaffM.length> 0 ? props.record.CStaffM.map((staff) => staff.name).join(', ') : "_"}
                  </span>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
