import React, { useEffect, useState } from "react";
import "./TestBatchUpdate.css";
import { Error } from "../../../../Themes/themes";
import { AxiosInstance } from "../../../../axios/index";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { PersonSearch } from "@mui/icons-material";
import { LaptopChromebook } from "@mui/icons-material";
import { Today } from "@mui/icons-material";
import { Alarm } from "@mui/icons-material";
import { CurrencyRupee } from "@mui/icons-material";
function TestBatchUpdateEdit() {
  const [editbatch, setEditbatch] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  sessionStorage.setItem(
    "testbatchdetails",
    JSON.stringify([
      {
        id: "1",
        courseCategory: "TNPSC",
        subCategory: "GROUP IV/VAO",
        classMode: "online",
        medium: "Tamil",
        fees: "12000",
        duration: "6 Months",
        batchtype: "regular",
        startson: "2022-12-11",
        batch_available: "batch1",
        batch_timing: "Batch - 05:00 PM to 08:00 PM",
        image:
          "https://i2.wp.com/aiasabook.com/wp-content/uploads/2022/03/457291.jpg?w=800&ssl=1",
        discount: "3000",
        discounted_fees: "9000",
      },
      {
        id: "2",
        courseCategory: "TNPSC",
        subCategory: "GROUP IV/VAO",
        classMode: "online",
        medium: "Tamil",
        fees: "2000",
        duration: "9 Months",
        batchtype: "Weekend",
        startson: "2023-4-11",
        batch_available: "batch2",
        batch_timing: "Batch - 02:00 PM to 05:00 PM",
        image:
          "https://i1.wp.com/aiasabook.com/wp-content/uploads/2022/03/457287.jpg?w=800&ssl=1",
        discount: "500",
        discounted_fees: "1500",
      },
    ])
  );

  // setting error value for all input fields
  const [error, setError] = useState({});
  const [initialfields, setInitialfields] = useState([
    {
      studentid: "",
    },
  ]);

  // /**fetch the data for the id from the url */
  //   useEffect(() => {
  //      const coursestoredItem = sessionStorage.getItem('batchupdate');
  //   if (coursestoredItem) {
  //     const courses = JSON.parse(coursestoredItem);
  //     const selectedCourse = courses.filter((course, i) => i == id);
  //     setEditbatch(selectedCourse);
  //   }
  //   }, []);

  /**
   * is saved function
   */
  function save() {
    alert("Test Batch details Saved Successfully");
  }

  // validate form fields and setting error
  const errors = {};

  const validate = (data) => {
    const currentDate = new Date();
    const selectedDate = new Date(data.startDate);

    if (data.studentid == "") {
      errors.studentid = "Please Enter Student id.";
    }
    // else if(data.courseCategory==""){
    //   errors.courseCategory = 'Please select course Category.';
    // }
    // else if(data.subCategory==""){
    //   errors.subCategory = 'Please select Sub Category.';
    // }
    // else if(data.testMode==""){
    //     errors.testMode = 'Please select Test Mode.';
    // }
    // else if(data.fees==""){
    //     errors.fees = 'Please Enter Fees Amount.';
    // }
    // else if(data.fees.length >= 10){
    //     errors.fees = 'Fees amount length exists';
    // }
    // else if(data.fees <= 0){
    //     errors.fees = 'Fees should be greater than 0.';
    // }
    // else if(data.duration==""){
    //     errors.duration = 'Please Select Duration.';
    // }
    // else if(data.startDate==""){
    //     errors.startDate = 'Please Select start Date.';
    // }
    // else if (selectedDate.toDateString() === currentDate.toDateString()) {
    // errors.startDate = "Start date cannot be today's date";
    // }
    // else if(selectedDate < currentDate){
    //     errors.startDate = 'Start Date cannot be in the past.';
    // }
    return errors;
  };

  /**
   * Handle change event for password fields.
   * Updates the corresponding field value in state based on the input field's name.
   * @param {object} event - The change event object.
   * @param {string} event.target.name - The name of the input field.
   * @param {string} event.target.value - The value of the input field.
   */
  const handleChange = (event, index) => {
    const { name, value, checked } = event.target;
    // console.log(name, value,"...handlechange");

    // update studentid field
    if (name === "studentid") {
      setInitialfields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    } else if (name == "id") {
      setSelectedIds((prevSelectedIds) => {
        if (checked) {
          return [...prevSelectedIds, index];
        } else {
          return prevSelectedIds.filter((id) => id !== index);
        }
      });
    }
    // update classMode field
    else {
      const [fieldName, fieldIndex] = name.split("-");
      const batchIndex = parseInt(fieldIndex);

      setEditbatch((prevBatches) => {
        const updatedBatches = [...prevBatches];
        const batchToUpdate = updatedBatches[batchIndex];

        batchToUpdate[fieldName] = value;

        return updatedBatches;
      });

      setError({
        ...error,
        [name]: "",
      });
    }
  };

  // console.log(selectedIds,"...setSelectedIds")

  /**
   * Handles submission of the form when form submits.
   * Saves the updated form values to local storage.
   * @param {Object} e - The form submission event.
   */

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("clicked submit");

    const errors = validate(editbatch);

    if (Object.keys(errors).length === 0) {
      try {
        // const existingData = sessionStorage.getItem("testbatchdetails") || "[]";
        // const parsedData = JSON.parse(existingData);

        // if (parsedData[index]) {
        //   parsedData[index] = editbatch;

        //   // const index = parsedData.findIndex(batch => batch.id === editbatch.id);
        //   // parsedData[index] = editbatch;
        // } else {
        // parsedData.push(editbatch);
        // }

        // try {
        //   const response = await AxiosInstance.post("/api/user/register",{batch_id:selectedIds});
        //   console.log(response.data);
        //   if(response.status="true")
        //   {
        //     save();
        //     window.location.href = '/batch';
        //   }
        // } catch (error) {
        //   console.log(error);
        // }

        // sessionStorage.setItem("testbatchdetails", JSON.stringify(parsedData));
        //  Redirect to batch page
        window.location.href = "/testbatch";
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  const handlesearch = async (e) => {
    // if (initialfields.studentid !== '') {

    // try {
    //   const response = await AxiosInstance.post("/api/user/register",{studentid:initialfields.studentid});
    //   console.log(response.data);
    //   if(response.status="200")
    //   {
    //     setEditbatch(response.data);
    //      window.alert(response.data.message);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    const batchData = sessionStorage.getItem("testbatchdetails") || "[]";
    const parsedData = JSON.parse(batchData);
    setEditbatch(parsedData);
    // }
    // else{
    //   setError({
    //     ...error,
    //     studentid:'Please Enter Student id.'
    //   });
    // }
  };

  /**assign the edit datas to the form in all fields */

  // useEffect(() => {
  //   if (editbatch!='') {
  //     setInitialfields({
  //       ...initialfields,
  //       studentid:editbatch[0].id,
  //       courseCategory: editbatch[0].courseCategory,
  //       subCategory: editbatch[0].subCategory,
  //       classMode: editbatch[0].classMode,
  //       medium:editbatch[0].medium,
  //       fees:editbatch[0].fees,
  //       duration:editbatch[0].duration,
  //       batch:editbatch[0].batchtype,
  //       startDate:editbatch[0].startson,
  //     });
  //   }
  // }, [editbatch]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* <div> */}
        <div>
          <h4>Update Test Batch Details</h4>

          <div class="row">
            <div class="form-group col-md-6">
              <label for="studentid">Student id:</label>
              <input
                type="text"
                class="form-control"
                id="studentid"
                name="studentid"
                placeholder="Enter Student id"
                value={initialfields.studentid}
                onChange={handleChange}
              />
            </div>
            <span>
              <PersonSearch id="search-btn" onClick={() => handlesearch()} />
            </span>
            <Error>
              {error.studentid && (
                <small className="error">{error.studentid}</small>
              )}
            </Error>
          </div>
          {editbatch != "" ? <h3>Batch available List: </h3> : ""}
          {/* <label>Batch available:</label> */}

          {editbatch.map((batch, index) => (
            <div>
              {/* {console.log(batch,"...batch")} */}
              <div class="form-row">
                <div class="form-group col-md-6">
                  <input
                    type="checkbox"
                    id={`batch-${batch.batch_available}`}
                    name="id"
                    value={batch.batch_available}
                    checked={selectedIds.includes(batch.batch_available)}
                    onChange={(event) =>
                      handleChange(event, batch.batch_available)
                    }
                  />
                  <label htmlFor={`item-${batch.id}`}>
                    {batch.batch_available}
                  </label>
                </div>
              </div>

              {/* ******************************************************************************* */}
              <div className="">
                <div className="row mb-4">
                  <div className="col-sm-10 p-4">
                    <div className="card mb-2">
                      <div className="card-body batchupdate">
                        <div className="row  p-4 ">
                          <div className="col_size col-8 col-sm-4 col-lg-4">
                            <img
                              className="batchupdate"
                              src={batch.image}
                              alt="course_book"
                              style={{ width: "130px", height: "130px" }}
                            />

                            <h5 className="card-title batchupdate_course_name">
                              {batch.subCategory}
                            </h5>
                            <p className=" batchupdate_batch_name">
                              {batch.batchtype}-{batch.batch_timing}
                            </p>
                          </div>
                          <div className="col-12 col-sm-8 col-lg-8">
                            <div className="row mb-4">
                              <div
                                className="d-flex flex-row align-items-start justify-content-between batchupdate 
"
                              >
                                <div className="">
                                  <LaptopChromebook className="batchupdate_icon" />
                                  <div className="innerflex_batchupdate">
                                    <h6 className="batchupdate flex_title">
                                      Class Mode
                                    </h6>
                                    <h6 className="batchupdate flex_subtitle">
                                      {batch.classMode}
                                    </h6>
                                  </div>
                                </div>

                                <div className="">
                                  <Today className="batchupdate_icon" />
                                  <div className="innerflex_batchupdate">
                                    <h6 className="batchupdate flex_title">
                                      Starts On
                                    </h6>
                                    <h6 className="batchupdate flex_subtitle">
                                      {batch.startson}
                                    </h6>
                                  </div>
                                </div>
                                <div className="">
                                  <Alarm className="batchupdate_icon" />
                                  <div className="innerflex_batchupdate">
                                    <h6 className="batchupdate flex_title">
                                      Duration
                                    </h6>
                                    <h6 className="batchupdate flex_subtitle">
                                      {batch.duration}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className="d-flex flex-row align-items-start justify-content-between batchupdate
"
                              >
                                <div className="">
                                  <CurrencyRupee className="batchupdate_icon" />
                                  <div className="innerflex_batchupdate">
                                    <h6 className="batchupdate flex_title">
                                      Actual Course Fee
                                    </h6>
                                    <h6 className="batchupdate flex_subtitle">
                                      {batch.fees}
                                    </h6>
                                  </div>
                                </div>

                                <div className="">
                                  <CurrencyRupee className="batchupdate_icon" />
                                  <div className="innerflex_batchupdate">
                                    <h6 className="batchupdate flex_title">
                                      Special Offer
                                    </h6>
                                    <h6 className="batchupdate flex_subtitle">
                                      {batch.discount}
                                    </h6>
                                  </div>
                                </div>
                                <div className="">
                                  <CurrencyRupee className="batchupdate_icon" />
                                  <div className="innerflex_batchupdate">
                                    <h6 className="batchupdate flex_title">
                                      Discounted Fee
                                    </h6>
                                    <h6 className="batchupdate flex_subtitle">
                                      {batch.discounted_fees}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ******************************************************************************* */}

              {/* <div class="form-row">
     <div class="form-group col-md-6">
    <div class="form-check form-check-inline">
      <input class="form-check-input " type="radio" name={`batch_available-${index}`} id={`batch_available-${index}`}  value={editbatch[index].batch_available}  onChange={(event) =>handleChange(event, index)} checked={batch.batch_available === editbatch[index].batch_available} />
      <label class="form-check-label" for={`batch_available-${index}`}>{batch.batch_available}</label>
      </div>
      </div>
      </div> */}

              {/* <div>
  <div class="form-row">
    <div class="form-group col-md-6">
    <label for={`courseCategory-${index}`}>Course Category:</label>
    <select class="form-control" id={`courseCategory-${index}`} name={`courseCategory-${index}`}  onChange={(event) =>handleChange(event, index)} value={batch.courseCategory}>
      <option value="">--- Select Category ---</option>
      <option value="UPSC">UPSC</option>
      <option value="TNPSC">TNPSC</option>
      <option value="Police/SI">Police/SI</option>
      <option value="Banking">Banking</option>
      <option value="TNTET">TNTET</option>
      <option value="RRB">RRB</option>
      <option value="TRB">TRB</option>
    </select>
    </div>
    <Error>{error.courseCategory && <small className="error">{error.courseCategory}</small>}</Error>
  </div>

  <div class="form-row">
    <div class="form-group col-md-6">
    <label for={`subCategory-${index}`}>Sub Category:</label>
    <select class="form-control" id={`subCategory-${index}`} name={`subCategory-${index}`} onChange={(event) =>handleChange(event, index)}>
      <option value="">--- Select Sub Category ---</option>
      <option value="GROUP I">GROUP I</option>
      <option value="GROUP II/IIA">GROUP II/IIA</option>
      <option value="GROUP IV/VAO">GROUP IV/VAO</option>
      <option value="GROUP VII">GROUP VII</option>
      <option value="GROUP VIII">GROUP VIII</option>
    </select>
    </div>
    <Error>{error.subCategory && <small className="error">{error.subCategory}</small>}</Error>
  </div> */}

              {/* <div class="form-row">
     <div class="form-group col-md-6">
  <label>Batch available:</label>
  {editbatch.map((batch) => (
    <div>
{batch.batch_available.map((batchname=>
  <div key={batchname}>
    
      <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="batch_available" id="batch_available"  value={batchname}  onChange={(event) =>handleChange(event, index)} checked={initialfields.batch_available === batchname} />
      <label class="form-check-label" for="batch_available">{batchname}</label>
      </div>
       </div>
  ))}
  </div>
  ))}
  </div>
   </div>   */}

              {/* <div class="form-row">
     <div class="form-group col-md-6">
     <label for={`classMode-${index}`}>Class Mode:</label>
 
      <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name={`classMode-${index}`} id={`online-${index}`} value="online"   onChange={(event) =>handleChange(event, index)} checked={batch.classMode == 'online'} />
      <label class="form-check-label" for={`online-${index}`}>Online</label>
      </div>
 
      <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name={`classMode-${index}`} id={`offline-${index}`} value="offline" checked={batch.classMode == 'offline'} onChange={(event) =>handleChange(event, index)}/>
      <label class="form-check-label" for={`offline-${index}`}>Offline</label>
      </div>

     </div>
     <Error>{error.classMode && <small className="error">{error.classMode}</small>}</Error>
 </div>


  <div class="form-row">
    <div class="form-group col-md-6">
    <label for={`medium-${index}`}>Medium:</label>
  
    <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name={`medium-${index}`} id={`Tamil-${index}`} value="Tamil"  checked={batch.medium === 'Tamil'}   onChange={(event) =>handleChange(event, index)} />
    <label class="form-check-label" for={`Tamil-${index}`}>Tamil</label>
    </div>
    
    <div class="form-check form-check-inline">
    <input class="form-check-input" type="radio" name={`medium-${index}`} id={`English-${index}`} value="English" checked={batch.medium === 'English'}     onChange={(event) =>handleChange(event, index)} />
    <label class="form-check-label" for={`English-${index}`}>English</label>
    </div>
  
  </div>
  <Error>{error.medium && <small className="error">{error.medium}</small>}</Error>
  </div>
          
  <div class="form-row">
    <div class="form-group col-md-6">
    <label for={`fees-${index}`}>Fees:</label>
    <input type="number" class="form-control" id={`fees-${index}`} name={`fees-${index}`} placeholder="Enter Fees" value={batch.fees}   onChange={(event) =>handleChange(event, index)} />
    </div>
<Error>{error.fees && <small className="error">{error.fees}</small>}</Error>
</div>
  
  <div class="form-row">
    <div class="form-group col-md-6">
        <label for={`duration-${index}`}>Duration:</label>
        <select class="form-control" id={`duration-${index}`} name={`duration-${index}`} value={batch.duration}   onChange={(event) =>handleChange(event, index)} >
        <option value="">--- Select Duration ---</option>
        <option value="6 Months">6 Months</option>
        <option value="9 Months">9 Months</option>
        <option value="1 Year">1 Year</option>
        </select>
    </div>
     <Error>{error.duration && <small className="error">{error.duration}</small>}</Error>      
   </div>
        
  <div class="form-row">
    <div class="form-group col-md-6">
    <label for={`batchtype-${index}`}>Batch Details:</label>
      
        <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name={`batchtype-${index}`} id={`weekend-${index}`} value="weekend" checked={batch.batchtype === 'weekend'}  onChange={(event) =>handleChange(event, index)}  />
        <label class="form-check-label" for={`weekend-${index}`}>Week End</label>
        </div>
      
        <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name={`batchtype-${index}`} id={`regular-${index}`} value="regular" checked={batch.batchtype === 'regular'}  onChange={(event) =>handleChange(event, index)}  />
        <label class="form-check-label" for={`regular-${index}`}> Regular</label>
        </div>
    </div>
<Error>{error.batch && <small className="error">{error.batch}</small>}</Error>
  </div>
        
<div class="form-row">
  <div class="form-group col-md-6">
    <label for={`startDate-${index}`}>Start on:</label>
    <div class="input-group date">
    <input type="date"  class="form-control" id={`startDate-${index}`} name={`startDate-${index}`} value={batch.startson}  onChange={(event) =>handleChange(event, index)} />
    </div>
  </div>
  <Error>{error.startDate && <small className="error">{error.startDate}</small>}</Error>
</div>  
</div>   */}
              {/* ))} */}
            </div>
          ))}
          {/* 

     {/* ))} */}
          <div class="col-sm-4 mt-3">
            <button type="submit" class="btn btn-primary col-sm-4 ">
              Save
            </button>
            <button type="button" class="btn btn-secondary col-sm-4 ms-5">
              <Link to="/Test" className="course_cancel">
                Cancel
              </Link>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TestBatchUpdateEdit;
