import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AxiosInstance } from "../../../../axios";
import Swal from "sweetalert2";
import { ImageUrlPrefix } from "../../../../labels";
// import { useNavigate } from "react-router-dom";

export default function RaiseQuery(props) {
  const { showModal, onSetShowModal, onsetflagQuery } = props;
  // const navigate = useNavigate();

  // console.log(props);
  // const [showModal, setShowModal] = useState(true);
  const [queryText, setQueryText] = useState({
    queryTextarea: "",
    queryFile: null,
    queryFile_original: "",
  });
  const [fileSelected, setFileSelected] = useState(false);
  const [isQueryFilled, setIsQueryFilled] = useState(true);
  const [error, setError] = useState({});
  // -----------
  // const navigateToPreviousScreen = () => {
  //   navigate(-1);
  // };
  const handleClose = () => {
    onsetflagQuery(true);
    onSetShowModal(!showModal);
  };

  const handleInputChange = (e) => {
    const { name, files } = e.target;

    if (name === "queryTextarea") {
      setQueryText((prevFormData) => ({
        ...prevFormData,
        [name]: e.target.value,
      }));
      setError({
        ...error,
        [name]: "", // Clear error if there was one
      });
    } else if (name === "queryFile" && files && files.length > 0) {
      const query_file = files[0];
      if (
        query_file.type === "application/pdf" ||
        query_file.type == "image/png" ||
        query_file.type == "image/jpg" ||
        query_file.type == "image/jpeg"
      ) {
        if (query_file.size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("query_file", query_file);
          AxiosInstance.post("api/upload/", formData)
            .then((response) => {
              setQueryText((prevData) => ({
                ...prevData,
                queryFile: response.data.path,
                queryFile_original: response.data.originalname,
              }));
              setError({
                ...error,
                queryFile: "", // Clear error if there was one
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setError({
            ...error,
            queryFile: "File size must be less than 2MB.",
          });
        }
      } else {
        setError({
          ...error,
          queryFile: "Only PDF files are allowed.",
        });
      }
    }
  };

  const validateRaiseQuery = (data) => {
    const errors = {};

    if (data.queryTextarea === "") {
      errors.queryTextarea = "Please enter text area.";
    }

    if (!data.queryFile) {
      errors.queryFile = "Please select a file.";
    }

    return errors;
  };

  const handleSubmit = () => {
    const errors = validateRaiseQuery(queryText);
    if (Object.keys(errors).length === 0) {
      const createRaiseQuery = {
        user_id: props.userid,
        query: queryText.queryTextarea,
        event_type: 1, // static for test event
        event_pk: props.element.event_pk,
        batch_pk: props.element.batch_id,
        question_pk: props.element.question_pk,
        query_proof_filepath: queryText.queryFile,
        original_query_filepath: queryText.queryFile_original,
        created_by: props.userid,
        updated_by: props.userid,
      };
      // console.log(raiseQuery);
      AxiosInstance.post("api/test/raise/query", createRaiseQuery).then(
        (res) => {
          if (res.data.status === true) {
            Swal.fire({
              title: "Success",
              text: res.data.message,
              icon: "Success",
              confirmButtonText: "Ok",
            }).then((result) => {
              handleClose(); // Close the modal
            });
          } else {
            Swal.fire({
              title: "warning",
              text: res.data.message,
              icon: "warning",
              confirmButtonText: "Ok",
            }).then((result) => {
              handleClose(); // Close the modal
            });
          }
        }
      );
    } else {
      setError(errors);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">
              Raise a query
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="queryText" className="raise-title">
                    Query
                  </label>
                  <div>
                    <input
                      type="text"
                      className="raise-input"
                      id="queryText"
                      name="queryTextarea"
                      value={queryText.queryTextarea}
                      onChange={handleInputChange}
                      placeholder="Query"
                    />
                    {error.queryTextarea && (
                      <small className="errors ms-0">
                        {error.queryTextarea}
                      </small>
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <label htmlFor="file" className="raise-title">
                    Upload Proof for Query Reference
                    {queryText.queryFile !== "" &&
                      queryText.queryFile !== null && (
                        <a
                          href={`${ImageUrlPrefix}/${queryText.queryFile}`}
                          target="_blank"
                          download
                        >
                          <span className="material-icons download_icon align-middle text-danger ">
                            file_download
                          </span>
                        </a>
                      )}
                  </label>
                  <div className="row">
                    <div
                      className={
                        queryText.queryFile !== "" &&
                        queryText.queryFile !== null
                          ? "col-md-3 "
                          : "col-md-12"
                      }
                    >
                      <input
                        id="file"
                        type="file"
                        name="queryFile"
                        className={
                          queryText.queryFile !== "" &&
                          queryText.queryFile !== null
                            ? "InputsFiles nonefield nn_upload_width"
                            : "InputsFiles  nonefield vv_upload_width"
                        }
                        // className="InputsFiles nonefield vv_upload_width"
                        placeholder="Upload PDF"
                        autoComplete="file"
                        onChange={handleInputChange}
                      />

                      {error.queryFile && (
                        <small className="errors ms-0">{error.queryFile}</small>
                      )}
                    </div>
                    {queryText.queryFile !== "" &&
                      queryText.queryFile !== null && (
                        <div
                          className={"col-md-9 d-flex align-content-center p-0"}
                        >
                          <span className="nn_upload_file ms-3">
                            {queryText.queryFile_original}
                          </span>
                          <span className="material-icons align-middle text-danger verified_upload">
                            verified
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="cancel-button" onClick={handleClose}>
            Cancel Query
          </p>
          <button
            type="button"
            onClick={handleSubmit}
            className="DashboardButton "
          >
            <a href="javascript:void(0)">Raise Query Now</a>
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
