import React from "react";
import Select, { components } from "react-select";
import { ImageUrlPrefix } from "../../../../labels";
import DatePickerSample from "../../DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import moment from "moment";
import TimeIcon from "@mui/icons-material/AccessTime";

function ExamPaperDetails(props) {
  const calculateEndTime = (starttime, duration) => {
    if (starttime && duration !== "") {
      const startParts = starttime.split(":");
      const hoursToAdd = parseInt(duration, 10); // Parse duration to integer
      const newHour = parseInt(startParts[0], 10) + hoursToAdd;
      const newMinute = parseInt(startParts[1], 10);
      const newSecond = parseInt(startParts[2], 10); // Add this line for seconds

      // Ensure that newHour, newMinute, and newSecond are represented with two digits
      const formattedHour = newHour.toString().padStart(2, "0");
      const formattedMinute = newMinute.toString().padStart(2, "0");
      const formattedSecond = newSecond.toString().padStart(2, "0"); // Add this line for seconds

      // Use the same format as the input
      const endTime = `${formattedHour}:${formattedMinute}:${formattedSecond}`;
      // console.log(endTime, "endTime");
      return endTime;
    } else {
      return "";
    }
  };
  const handleTimingFocus = (add) => {
    if (add.current) {
      add.current.focus();
      add.current.click(); // Simulate a click to open the picker
    }
  };
  return (
    <div className="row box ">
      <div className="col-md-12">
        <p className="StepTitle">
          {" "}
          Model Exam {props.parentId > 0 ? "" : ""}{" "}
          {props.parentId > 0 ? props.parentId : ""} Paper{" "}
          {props.record.id > 0 ? "" : ""}{" "}
          {props.record.id > 0 ? props.record.id : ""} Details
        </p>
        <div className="row mb-4" style={{ paddingRight: "20px" }}>
          <div className="col-md-6">
            <div className="forms ">
              <label for="title">Paper Title</label>
              <input
                ref={props.txtBatchPETitle}
                type="text"
                class="Inputs"
                id="title"
                name="title"
                placeholder="Exam Title"
                value={props.record.title}
                onChange={(e) =>
                  props.examdetailschangePaper(
                    e,
                    props.record.id,
                    props.parentId
                  )
                }
              />
              {props.record.error_1 && (
                <small className="error">{props.record.error_1}</small>
              )}
            </div>
          </div>
          {console.log(props.txtBatchPEdate, "props.txtBatchPEdate")}
          <div className="col-md-6">
            <div className="forms ">
              <label for="date">Date</label>
              {/* !== null ? moment(props.record.LastDateApply).add(1, 'day').toDate() : "" */}
              <DatePickerSample
                minDate={props.minExamDateCombined}
                // txtAdMDob={props.record.txtBatchPEdate}
                txtAdMDob={props.txtBatchPEdate}
                name={"date"}
                handleIdChange={(date) =>
                  props.examdetailschange_datePaper(
                    date,
                    props.record.id,
                    props.parentId,
                    "date"
                  )
                }
                fieldsId={props.record.id}
                // fieldInput={props.record.date}
                fieldInput={
                  props.record.date
                    ? moment(props.record.date).format("DD/MM/YYYY")
                    : props.record.date
                }
              />
              {props.record.error_2 && (
                <small className="error">{props.record.error_2}</small>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="forms " style={{ position: "relative" }}>
              <label for="startTime">Start Time:</label>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  ref={props.txtBatchPEstartTime}
                  id="startTime"
                  step="1"
                  name="startTime"
                  placeholder="Enter startTime"
                  title={props.record.startTime}
                  //  value={dayjs(props.record.startTime , "HH:mm:ss")}
                  value={moment(props.record.startTime)}
                  format="hh:mm:ss A"
                  onChange={(e) =>
                    props.onHandleExamTimeChangePaper(
                      e,
                      props.record.id,
                      props.parentId,
                      "startTime"
                    )
                  }
                />
                <TimeIcon
                  className="TimerIconME"
                  color="primary"
                  onClick={() => handleTimingFocus(props.txtBatchPEstartTime)}
                  style={{
                    position: "absolute",
                    top: "50px",
                    right: "10px",
                  }}
                />
              </LocalizationProvider>
              {props.record.error_4 && (
                <small className="error">{props.record.error_4}</small>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="forms " style={{ position: "relative" }}>
              <label for="endTime">End Time:</label>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  ref={props.txtBatchPEendTime}
                  id="endTime"
                  step="1"
                  name="endTime"
                  placeholder="Enter endTime"
                  title={props.record.endTime}
                  value={moment(props.record.endTime)}
                  format="hh:mm:ss A"
                  //  minTime={props.record.startTime !==null &&  props.record.startTime !=="" ? dayjs(props.record.startTime, "HH:mm:ss").startOf("day") : ""}
                  onChange={(e) =>
                    props.onHandleExamTimeChangePaper(
                      e,
                      props.record.id,
                      props.parentId,
                      "endTime"
                    )
                  }
                />
                <TimeIcon
                  className="TimerIconME"
                  color="primary"
                  onClick={() => handleTimingFocus(props.txtBatchPEendTime)}
                  style={{
                    position: "absolute",
                    top: "50px",
                    right: "10px",
                  }}
                />
              </LocalizationProvider>

              {props.record.error_5 && (
                <small className="error">{props.record.error_5}</small>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="forms ">
              <label for="duration">Duration (In Hours)</label>
              <input
                ref={props.txtBatchPEduration}
                type="text"
                class="Inputs"
                id="duration"
                name="duration"
                disabled
                placeholder="Duration (In Hours)"
                value={props.record.duration < 0 ? 0 : props.record.duration}
                onChange={(e) =>
                  props.examdetailschangePaper(
                    e,
                    props.record.id,
                    props.parentId
                  )
                }
              />
              {props.record.error_3 && (
                <small className="error">{props.record.error_3}</small>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="forms ">
              <label for="syllabus">
                Upload Exam Syllabus (in pdf/Image format):
                {props.record.syllabus !== "" &&
                  props.record.syllabus !== null && (
                    <a
                      href={`${ImageUrlPrefix}/${props.record.syllabus}`}
                      target="_blank"
                      download
                    >
                      <span className="material-icons align-middle download_icon">
                        file_download
                      </span>
                    </a>
                  )}
              </label>
              <div className="row">
                <div
                  className={
                    props.record.syllabus !== "" &&
                    props.record.syllabus !== null
                      ? "col-md-3 "
                      : "col-md-12"
                  }
                >
                  <input
                    ref={props.txtBatchPEsyllabus}
                    type="file"
                    className={
                      props.record.syllabus !== "" &&
                      props.record.syllabus !== null
                        ? "InputsFilessss nn_upload_width"
                        : "InputsFilessss  vv_upload_width"
                    }
                    id="syllabus"
                    name="syllabus"
                    onChange={(e) =>
                      props.examdetailschangePaper(
                        e,
                        props.record.id,
                        props.parentId
                      )
                    }
                  />
                </div>
                {props.record.syllabus !== "" &&
                  props.record.syllabus !== null && (
                    <div className={"col-md-9 d-flex align-content-center p-0"}>
                      <span className="nn_upload_file">
                        {props.record.syllabus !== "" &&
                        props.record.syllabus !== null
                          ? props.record.original_syllabus_url
                          : ""}
                      </span>
                    </div>
                  )}
              </div>

              {props.record.error_6 && (
                <small className="error">{props.record.error_6}</small>
              )}
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12 dflexendcolu">
            {/* <a
                className="AddMore"
                onClick={() => props.DeleteExamDetailsPaper(props.record.id, props.parentId)}
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Paper
              </a> */}

            {props.record.id !== 1 && props.record.id === props.length && (
              <a
                className="AddMore me-3"
                onClick={() =>
                  props.DeleteExamDetailsPaper(props.record.id, props.parentId)
                }
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Paper
              </a>
            )}
            {props.record.id !== props.length ? (
              <a
                className="AddMore"
                onClick={() =>
                  props.DeleteExamDetailsPaper(props.record.id, props.parentId)
                }
              >
                <span className="material-icons download_icon align-middle">
                  remove_circle
                </span>
                Delete This Paper
              </a>
            ) : (
              <a
                className="AddMore"
                onClick={() =>
                  props.AddExamDetailsPaper(props.record.id, props.parentId)
                }
              >
                <span className="material-icons download_icon align-middle">
                  add_circle
                </span>
                Add More Paper
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ExamPaperDetails;
