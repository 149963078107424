import React from 'react'
// import { default as ReactSelect } from "react-select";
import Select, { components } from "react-select";
import { multiCusutomStyle } from '../../../../pages/Multicustomstyles';
import { CustomCOption } from '../../../../labels/Lableoption';


export default function CategorySubject(props) {


 

// console.log(props.record,"props ", props.record.id);
  return (
    <div className="row box" id={"cate_" + props.record.id}>
      <div className="col-md-12">
        <p className="StepTitle">
          Subject in Category {props.record.id > 1 ? "-" : ""}{" "}
          {props.record.id > 1 ? props.record.id : ""}{" "}
        </p>
        <div className="row" style={{marginTop: "10px",marginBottom:"10px"}}>
          <div className="col-md-6">
            <div className="forms ">
              <label for={`category`}>Category</label>
              <div className="d-flex  col-md-12" id="radiobuttons">
                <div className="form-check form-check-inline">
                  <label className="checkbox-label">
                    {props.record.category}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="forms " id="subjectdata">
              <label htmlFor={`subject`}>Subject</label>
              {/* <select
                id={"subject_" + props.record.id}
                name={`subject`}
                className="InputsMutiple"
                multiple
                value={props.record.subject}
                onChange={(e) => props.handleSubjectChange(e, props.record.id)}
                required
              >
                <option value="" disabled selected>
                  Please select
                </option>
                {props.record.subject_name}
              </select> */}
              <span
                class="d-inline-block"
                data-toggle="popover"
                data-trigger="focus"
                data-content="Please selecet account(s)"
              >

<Select
                        options={props.record.subject_name}
                        isMulti
                        closeMenuOnSelect={false}
                        isDisabled ={props.disabled}
                       
                        hideSelectedOptions={false}
                        components={{
                          Option: CustomCOption, // Use the custom Option component
                        }}
                        onChange={(e) =>
                          props.handleChangeCheckbox(e, props.record.id)
                        }
                        allowSelectAll={true}
                        value={props.record.optionSelected}
                        styles={multiCusutomStyle}
                      />

               
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
