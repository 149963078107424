import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Stack } from "@mui/material";
import styled from "styled-components";
import IconCalendar from "@mui/icons-material/EventAvailable";
import IconDownload from "@mui/icons-material/FileDownload";
import ICON_WARNING from "@mui/icons-material/Warning";

import FloatedTitleContainer from "../../../../../ui/FloatedTitleContainer";
import FloatedContainerText from "../../../../../ui/FloatedContainerText";
import Title from "../../../../../ui/Title";
import CustomContainer from "../../../../../ui/CustomContainer";
import { MEDIA_SIZE_LG } from "../../../../../utils/helper";
import SecondaryButton from "../../../../../ui/SecondaryButton";
import PrimaryButton from "../../../../../ui/PrimaryButton";

const Header = styled.span`
  font-size: 14px;
  text-transform: capitalize;
  color: var(--color--grey-300);
`;
const Body = styled.span`
  font-size: 16px;
  color: var(--color-black);
  /* text-transform: capitalize; */
  font-weight: 700;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
  /* margin-left: auto; */
  margin-bottom: 20px;
  flex-wrap: wrap;
`;
export const StyledTestDetailsContainer = styled.div`
  padding: 16px 8px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    padding: 30px 16px;
  }
`;

// warning-section
const WarningSectionContainer = styled.section`
  background-color: var(--color-brand-200);
  color: var(--color-brand-800);
  padding: 15px;
  border: 1px solid var(--color-brand-800);
  border-radius: var(--border-radius-md);
  margin-bottom: 20px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    padding: 30px;
    margin-bottom: 30px;
  }
`;

const WarningSectionPara = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
`;

export default function ViewTestDetail() {
  const navigate = useNavigate();
  return (
    <CustomContainer className="my-4">
      {/* title section start */}
      <Title className="d-block d-sm-none">view test</Title>
      <div className="d-flex justify-content-between align-items-center">
        <Title className="d-none d-sm-block">view test</Title>
        <ButtonContainer>
          <SecondaryButton>Back to Shedule</SecondaryButton>
          <PrimaryButton className="ms-1" onClick={() => navigate("/JoinTest")}>
            <Stack
              direction="row"
              alignItems="center"
              columnGap={1}
              justifyContent="center"
            >
              <IconCalendar />
              Join Test
            </Stack>
          </PrimaryButton>
        </ButtonContainer>
      </div>
      {/* warning section */}
      <WarningSectionContainer>
        <WarningSectionPara style={{ fontWeight: 700 }}>
          <ICON_WARNING
            style={{ width: "16px", height: "16px", marginRight: "2px" }}
          />{" "}
          WARNING:
        </WarningSectionPara>
        <WarningSectionPara>
          The allotted time for attending the exam has ended, and you were
          marked absent.{" "}
        </WarningSectionPara>
        <WarningSectionPara>
          {" "}
          You can retake the test by clicking the 'Retake Test' button once it
          is enabled after the test's end time. However, please be aware that
          the results will not be reflected in your performance.
        </WarningSectionPara>
      </WarningSectionContainer>
      {/* body */}
      <Stack spacing={4}>
        <FloatedTitleContainer title="Test Details">
          <StyledTestDetailsContainer>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <FloatedContainerText title="event name" text="daily test 01" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FloatedContainerText title="method of test" text="objective" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FloatedContainerText title="test type" text="daily test 01" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FloatedContainerText title="date" text="12/10/2023" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FloatedContainerText title="start time" text="07:00 AM" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FloatedContainerText title="end time" text="11:00 AM" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FloatedContainerText
                  title="total number of questions"
                  text="100"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FloatedContainerText title="total marks" text="100" />
              </Grid>
              <Grid item>
                <FloatedContainerText
                  title="description of student"
                  normalText="bring your notebook and textbook without fail."
                />
              </Grid>
            </Grid>
          </StyledTestDetailsContainer>
        </FloatedTitleContainer>
        <FloatedTitleContainer title="Subjects and Topics">
          <StyledTestDetailsContainer>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Stack>
                  <Header>
                    uploaded syllabus{" "}
                    <IconDownload
                      style={{ color: "#C0272D", marginLeft: "5px" }}
                    />
                  </Header>
                  <Body>syllabus.pdf</Body>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FloatedContainerText title="subject" text="tamil" />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <FloatedContainerText
                  title="test topics"
                  text="tamil ilakkanam, literature, tamil history"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FloatedContainerText title="subject" text="english" />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <FloatedContainerText title="test topics" text="literature" />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FloatedContainerText title="subject" text="mathematics" />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <FloatedContainerText
                  title="test topics"
                  text="reasoning,non-reasoning"
                />
              </Grid>
            </Grid>
          </StyledTestDetailsContainer>
        </FloatedTitleContainer>
      </Stack>
    </CustomContainer>
  );
}
