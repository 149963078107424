import styled from "styled-components";

export const Logostyle=styled.img`
width: 98px;
height: 98px;
`;

export const Error=styled.div`
    color: #c1393d;
    font-weight:bold;
    


    `;