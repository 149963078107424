import React, { useState, useEffect } from "react";
import { ImageUrlPrefix } from "../../../../labels";
import { Download } from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import moment from "moment";
import { validateForms } from "./validation";
import Swal from "sweetalert2";
import ReadViewSubject from "./ReadViewSubject";
import ModelComForP from "../../../../pages/ModelComForP";

export default function ReviewFaculty() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState({
    FirstName: "",
    LastName: "",
    Fathername: "",
    DOB: "",
    age: 0,
    PlaceBirth: "",
    gender: "Male",
    MaritalStatus: "Single/Unmarried",
    Spouse: "",
    Occupation: "",
    Mobile: "",
    WhatsAppNumber: "",
    ContactNumber: false,
    emailaddress: "",
    Profilephoto: null,
    aadharNo: "",
    aadharCardphoto: null,
    panNo: "",
    panPhoto: null,
    BuildingNumberPresent: "",
    BuildingNumberPresent: "",
    StreetNamePresent: "",
    AreaPresent: "",
    LandmarkPresent: "",
    districtPresent: "",
    CityPresent: "",
    statePresent: "",
    PostPresent: "",
    TalukPresent: "",
    PincodePresent: 0,
    SamePresentAddress: false,
    BuildingNumberPermanent: "",
    StreetNamePermanent: "",
    AreaPermanent: "",
    LandmarkPermanent: "",
    CityPermanent: "",
    districtPermanent: "",
    statePermanent: "",
    PincodePermanent: 0,
    PostPermanent: "",
    TalukPermanent: "",
    spq: "",
    prevTeachexp: "Yes",
    workingexp: "",
    teachexp: "",
    teachType: "",
    designation: "",
    USub: [],
    DJoin: "",
    VoiceOpinion: "",
    Verify: false,
    faculty_id: "",
    status: 0,
    comments: "",
    Adminstatus: "",
    location: "",
    original_aadhar_path: null,
    original_profile_path: null,
    original_caste_path: null,
    original_pan_path: null,
    original_workexp: null,
    original_teachexp: null,
    location_name: "",
  });
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }
  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  //education useState
  const [educationQualification, setEducationQualification] = useState(["UG"]);
  //education useState
  const [sslcCerficates, setSslcCerficates] = useState([
    {
      name: "UG",
      UGCertificateNumber: "",
      UGYearpassing: "",
      UGDegree: "",
      UGMajor: "",
      UGMarksPercentage: "",
      UGClassName: "",
      UGNameInstitute: "",
      UG_Path: null,
      original_file_path: null,
    },
    {
      name: "PG",
      PGCertificateNumber: "",
      PGYearpassing: "",
      PGDegree: "",
      PGMajor: "",
      PGMarksPercentage: "",
      PGClassName: "",
      PGNameInstitute: "",
      PG_Path: null,
      original_file_path: null,
    },
    {
      name: "Integrated PG",
      IPCertificateNumber: "",
      IPYearpassing: "",
      IPDegree: "",
      IPMajor: "",
      IPMarksPercentage: "",
      IPClassName: "",
      IPNameInstitute: "",
      IP_Path: null,
      original_file_path: null,
    },
    {
      name: "phd",
      PHDCertificateNumber: "",
      PHDYearpassing: "",
      PHDDegree: "",
      PHDMajor: "",
      PHDMarksPercentage: "",
      PHDClassName: "",
      PHDNameInstitute: "",
      PHD_Path: null,
      original_file_path: null,
    },
    {
      name: "Diploma",
      DCertificateNumber: "",
      DYearpassing: "",
      DDegree: "",
      DMajor: "",
      DMarksPercentage: "",
      DClassName: "",
      DNameInstitute: "",
      D_Path: null,
      original_file_path: null,
    },
    {
      name: "Professional Courses",
      PCCertificateNumber: "",
      PCYearpassing: "",
      PCDegree: "",
      PCMajor: "",
      PCMarksPercentage: "",
      PCClassName: "",
      PCNameInstitute: "",
      PC_Path: null,
      original_file_path: null,
    },
    {
      name: "Others",
      OtCertificateNumber: "",
      OtYearpassing: "",
      OtDegree: "",
      OtMajor: "",
      OtMarksPercentage: "",
      OtClassName: "",
      OtNameInstitute: "",
      Ot_Path: null,
      original_file_path: null,
    },
  ]);
  const [error, setError] = useState([]);
  //technical qualification
  const [techQualification, setTechQualification] = useState([
    {
      id: 1,
      name: "",
      level: "",
      lang: "",
      Cnum: "",
      grade: "",
      year: "",
      file: "",
    },
  ]);
  //date and time convert to normal data
  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const [category, setCategory] = useState([]);
  const [subject, setSubject] = useState([]);
  useEffect(() => {
    if (userId) {
      AxiosInstance.post("api/staff/view", {
        user_id: userId,
      })
        .then((res) => {
          // console.log(res.data.data);
          const userinfo = res.data.data;
          const PersonDetails = {
            FirstName: userinfo.first_name,
            LastName: userinfo.last_name,
            Fathername: userinfo.father_name,
            MotherName: userinfo.mother_name,
            Profilephoto:
              userinfo.profile_path !== null ? userinfo.profile_path : null,
            DOB: userinfo.dob !== null ? dobCon(userinfo.dob) : "",
            PlaceBirth: userinfo.place_of_birth,
            age: userinfo.age,
            gender: userinfo.gender,
            MaritalStatus: userinfo.marital_status,
            Spouse: userinfo.spouse_name,
            Occupation: userinfo.spouse_ocp,
            aadharNo: userinfo.aadhar_no,
            aadharCardphoto: userinfo.aadhar_path
              ? userinfo.aadhar_path.split("/").pop()
              : "",
            original_aadhar_path: userinfo.original_aadhar_path,
            original_profile_path: userinfo.original_profile_path,
            original_caste_path: userinfo.original_caste_path,
            original_pan_path: userinfo.original_pan_path,
            panNo: userinfo.pan_no,
            panPhoto: userinfo.pan_path
              ? userinfo.pan_path.split("/").pop()
              : "",
            panNo: userinfo.pan_no,
            panPhoto: userinfo.pan_path,
            Mobile: userinfo.mobile_no,
            WhatsAppNumber: userinfo.whatsapp_no,
            ContactNumber: userinfo.contact_type === 0 ? false : true,
            emailaddress: userinfo.email,
            spq: userinfo.special_qualification,
            faculty_id: userinfo.faculty_id,
            status: userinfo.status,
            prevTeachexp: userinfo.experience === 1 ? "Yes" : "NO",
            workingexp: userinfo.working_exp,
            teachexp: userinfo.teaching_exp,
            working_ex_file: userinfo.working_exp_filepath,
            Teaching_ex_file: userinfo.teaching_exp_filepath,
            original_workexp: userinfo.original_workexp,
            original_teachexp: userinfo.original_teachexp,
            designation: userinfo.designation,
            teachType: userinfo.staff_type === 0 ? "0" : "1",
            DJoin:
              userinfo.aiasa_doj !== null ? dobCon(userinfo.aiasa_doj) : "",
            location: userinfo.staff_loc_id,
            location_name: userinfo.staff_loc_name,
            VoiceOpinion: userinfo.feedback,
          };

          userinfo.address.map((obj) => {
            if (obj.address_type === 1) {
              PersonDetails.SamePresentAddress =
                obj.same_address === 0 ? true : false;
              PersonDetails.BuildingNumberPresent = obj.address1;
              PersonDetails.StreetNamePresent = obj.address2;
              PersonDetails.AreaPresent = obj.address3;
              PersonDetails.LandmarkPresent = obj.landmark;
              PersonDetails.districtPresent = obj.district_name;
              PersonDetails.CityPresent = obj.city;
              PersonDetails.statePresent = obj.state_name;
              PersonDetails.PostPresent = obj.post_name;
              PersonDetails.TalukPresent = obj.taluk;
              PersonDetails.PincodePresent = obj.postal_code;
            } else {
              PersonDetails.SamePresentAddress =
                obj.same_address === 0 ? true : false;
              PersonDetails.BuildingNumberPermanent = obj.address1;
              PersonDetails.StreetNamePermanent = obj.address2;
              PersonDetails.AreaPermanent = obj.address3;
              PersonDetails.LandmarkPermanent = obj.landmark;
              PersonDetails.districtPermanent = obj.district_name;
              PersonDetails.CityPermanent = obj.city;
              PersonDetails.statePermanent = obj.state_name;
              PersonDetails.PostPermanent = obj.post_name;
              PersonDetails.TalukPermanent = obj.taluk;
              PersonDetails.PincodePermanent = obj.postal_code;
            }
          });
          setLists(PersonDetails);
          const ca =
            userinfo.category !== null &&
            userinfo.category !== "" &&
            userinfo.category
              .split(",")
              .map((category) => category.toUpperCase());
          setCategory(ca);

          const originalData =
            userinfo.subject !== null &&
            userinfo.subject !== "" &&
            userinfo.subject.reduce((acc, cur) => {
              const categoryIndex = acc.findIndex(
                (item) => item.category === cur.category
              );
              if (categoryIndex === -1) {
                acc.push({
                  id: acc.length + 1,
                  category: cur.category,
                  optionSelected: [
                    {
                      value: cur.subject,
                      label: cur.subject,
                      pk: cur.subject_pk,
                    },
                  ],
                });
              } else {
                acc[categoryIndex].optionSelected.push({
                  value: cur.subject,
                  label: cur.subject,
                });
              }
              return acc;
            }, []);

          // console.log(originalData,"originalData");
          setSubject(originalData);

          let edq = userinfo.edu
            .filter((item) => item.qualification_type === "EDQ")
            .map((obj) => {
              let name = "";
              if (obj.name === "sslc") {
                name = "SSLC";
              } else if (obj.name === "hsc") {
                name = "HSC";
              } else {
                name = obj.name;
              }
              return name;
            });

          setEducationQualification(edq);

          let teach = userinfo.edu
            .filter((item) => item.qualification_type === "THQ")
            .map((el, index) => {
              const Teachqu = {};

              // if a matching certificate is found, update its properties
              Teachqu.id = index === 0 ? 1 : index + 1;
              Teachqu.name = el.name;
              Teachqu.level = el.level;
              Teachqu.lang = el.edu_medium;
              Teachqu.Cnum = el.certificate_no;
              Teachqu.grade = el.grade;
              Teachqu.year = el.year_of_passing;
              Teachqu.original_file_path = el.original_file_path;
              Teachqu.kComputer = el.kdg_in_com;
              Teachqu.file =
                el.certificate_path !== null
                  ? el.certificate_path.split("/").pop()
                  : "";
              return Teachqu;
            });
          setTechQualification(teach);

          let sslcCert = userinfo.edu
            .filter((item) => item.qualification_type === "EDQ")
            .map((el, index) => {
              if (el.name === "UG") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.UGCertificateNumber = el.certificate_no;
                matchingCertificate.UGYearpassing = el.year_of_passing;
                matchingCertificate.UGDegree = el.degree_name;
                matchingCertificate.UGMajor = el.major_subject;
                matchingCertificate.UGMarksPercentage = el.marks_percentage;
                matchingCertificate.UGClassName = el.grade;
                matchingCertificate.UGNameInstitute = el.institute;
                matchingCertificate.original_file_path = el.original_file_path;
                matchingCertificate.UG_Path =
                  el.certificate_path !== null
                    ? el.certificate_path.split("/").pop()
                    : "";
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "PG") {
                const matchingCertificate = {};
                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.PGCertificateNumber = el.certificate_no;
                matchingCertificate.PGYearpassing = el.year_of_passing;
                matchingCertificate.PGMarksPercentage = el.marks_percentage;
                matchingCertificate.PGDegree = el.degree_name;
                matchingCertificate.PGMajor = el.major_subject;
                matchingCertificate.PGClassName = el.grade;
                matchingCertificate.PGNameInstitute = el.institute;
                matchingCertificate.original_file_path = el.original_file_path;
                matchingCertificate.PG_Path =
                  el.certificate_path !== null
                    ? el.certificate_path.split("/").pop()
                    : "";
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "Integrated PG") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.IPCertificateNumber = el.certificate_no;
                matchingCertificate.IPYearpassing = el.year_of_passing;
                matchingCertificate.IPDegree = el.degree_name;
                matchingCertificate.IPMajor = el.major_subject;
                matchingCertificate.IPMarksPercentage = el.marks_percentage;
                matchingCertificate.IPClassName = el.grade;
                matchingCertificate.IPNameInstitute = el.institute;
                matchingCertificate.original_file_path = el.original_file_path;
                matchingCertificate.IP_Path =
                  el.certificate_path !== null
                    ? el.certificate_path.split("/").pop()
                    : "";
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "phd") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.PHDCertificateNumber = el.certificate_no;
                matchingCertificate.PHDYearpassing = el.year_of_passing;
                matchingCertificate.PHDDegree = el.degree_name;
                matchingCertificate.PHDMajor = el.major_subject;
                matchingCertificate.PHDMarksPercentage = el.marks_percentage;
                matchingCertificate.PHDClassName = el.grade;
                matchingCertificate.PHDNameInstitute = el.institute;
                matchingCertificate.original_file_path = el.original_file_path;
                matchingCertificate.PHD_Path =
                  el.certificate_path !== null
                    ? el.certificate_path.split("/").pop()
                    : "";
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "Diploma") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.DCertificateNumber = el.certificate_no;
                matchingCertificate.DYearpassing = el.year_of_passing;
                matchingCertificate.DDegree = el.degree_name;
                matchingCertificate.DMajor = el.major_subject;
                matchingCertificate.DMarksPercentage = el.marks_percentage;
                matchingCertificate.DClassName = el.grade;
                matchingCertificate.DNameInstitute = el.institute;
                matchingCertificate.original_file_path = el.original_file_path;
                matchingCertificate.D_Path =
                  el.certificate_path !== null
                    ? el.certificate_path.split("/").pop()
                    : "";
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "Professional Courses") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.PCCertificateNumber = el.certificate_no;
                matchingCertificate.PCYearpassing = el.year_of_passing;
                matchingCertificate.PCDegree = el.degree_name;
                matchingCertificate.PCMajor = el.major_subject;
                matchingCertificate.PCMarksPercentage = el.marks_percentage;
                matchingCertificate.PCClassName = el.grade;
                matchingCertificate.PCNameInstitute = el.institute;
                matchingCertificate.original_file_path = el.original_file_path;
                matchingCertificate.PC_Path =
                  el.certificate_path !== null
                    ? el.certificate_path.split("/").pop()
                    : "";
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "Others") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.OtCertificateNumber = el.certificate_no;
                matchingCertificate.OtYearpassing = el.year_of_passing;
                matchingCertificate.OtDegree = el.degree_name;
                matchingCertificate.OtMajor = el.major_subject;
                matchingCertificate.OtMarksPercentage = el.marks_percentage;
                matchingCertificate.OtClassName = el.grade;
                matchingCertificate.OtNameInstitute = el.institute;
                matchingCertificate.original_file_path = el.original_file_path;
                matchingCertificate.Ot_Path =
                  el.certificate_path !== null
                    ? el.certificate_path.split("/").pop()
                    : "";
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              }
            });
        })
        .catch((error) => {
          return error;
        });
    }
  }, []);
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForms(lists);
    // console.log(errors);
    if (Object.keys(errors).length === 0) {
      try {
        if (
          lists.spq !== null &&
          lists.VoiceOpinion !== null &&
          lists.spq !== "" &&
          lists.VoiceOpinion !== ""
        ) {
          const reqdata = {
            user_id: userId,
            updated_by: userid,
            status: parseInt(lists.Adminstatus),
            comments: lists.comments,
          };
          // console.log(reqdata);
          const response = await AxiosInstance.post(
            "api/staff/approval",
            reqdata
          );
          if ((response.data.status = true)) {
            setSwalMsg({
              show: true,
              status: "Success",
              message: response.data.message,
              title: "Success",
              BottomOkClose: "Ok",
            });
            navigateToPreviousScreen();
            // Swal.fire({
            //   title: 'Success',
            //   text: response.data.message,
            //   icon: 'success',
            //   confirmButtonText: 'Ok'
            // }).then((result) => {
            //   if (result.isConfirmed) {

            //   }
            // });
          } else {
            setSwalMsg({
              show: true,
              status: "Failure",
              message: response.data.message,
              title: "Failure",
              BottomOkClose: "Ok",
            });
          }
        } else {
          setSwalMsg({
            show: true,
            status: "Failure",
            message:
              "This Faculty cannot fill the profile, so you can't active this faculty",
            title: "Failure",
            BottomOkClose: "Ok",
          });
        }
      } catch (error) {
        setSwalMsg({
          show: true,
          status: "Failure",
          message: error.response.data.message,
          title: "Failure",
          BottomOkClose: "Ok",
        });
      }
    } else {
      setError(errors);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Adminstatus") {
      if (checked) {
        setLists((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
        setError({
          ...error,
          [name]: "",
        });
      }
    } else {
      setLists((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };
  return (
    <div className=" Faculty">
      <div className="row">
        <div className="col-md-12">
          {swalMsg.show === true && (
            <ModelComForP
              show={swalMsg.show}
              message={swalMsg.message}
              status={swalMsg.status}
              title={swalMsg.title}
              BottomOkBtn={swalMsg.BottomOkBtn}
              BottomCancelBtn={swalMsg.BottomCancelBtn}
              handleCloseModels={handleCloseModel}
              // handleClose_yes={handleSPayfee}
              BottomOkClose={swalMsg.BottomOkClose}
              handleCloseModels_Succ={handleCloseModels_Succ}
            />
          )}
          <section className="StepOne">
            <div className="heading  d-flex justify-content-between ">
              <div className=" mt-4">
                <h6 className="Title ms-2">Review Faculty Profile</h6>
              </div>
            </div>

            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Basic Details</p>

                <div className="row mb-3">
                  <div className="col-lg-9 col-md-7">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            First Name
                          </label>
                          <span className="ReviewLabel">
                            {lists.FirstName || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Last Name
                          </label>
                          <span className="ReviewLabel">
                            {lists.LastName || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-12">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Father's Name/Guardian's Name
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {lists.Fathername || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Date of Birth
                          </label>
                          <span className="ReviewLabel">
                            {lists.DOB || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Age
                          </label>
                          <span className="ReviewLabel">
                            {lists.age || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Gender
                          </label>
                          <span className="ReviewLabel">
                            {lists.gender || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Place of Birth
                          </label>
                          <span className="ReviewLabel">
                            {lists.PlaceBirth || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Marital Status
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {lists.MaritalStatus || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Name of the Spouse
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {lists.Spouse || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Occupation of the Spouse
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {lists.Occupation || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Status
                          </label>
                          {lists.status === 0 && (
                            <span className="ReviewLabel">Email Sent</span>
                          )}
                          {lists.status === 1 && (
                            <span className="ReviewLabel">
                              Waiting For Approval
                            </span>
                          )}
                          {lists.status === 4 && (
                            <span className="ReviewLabel">Active</span>
                          )}

                          {(lists.status === 2 || lists.status === 3) && (
                            <span className="ReviewLabel">InActive</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-5">
                    <div className="forms mb-2  ">
                      <div id="profilephotos">
                        {lists.Profilephoto && (
                          <img
                            src={`${ImageUrlPrefix}/${lists.Profilephoto}`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Contact details</p>

                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Phone Number
                      </label>
                      <span className="ReviewLabel">{lists.Mobile || "-"}</span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        WhatsApp Number
                      </label>
                      <span className="ReviewLabel">
                        {lists.WhatsAppNumber || "-"}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Email ID
                      </label>
                      <span className="ReviewLabel">
                        {lists.emailaddress || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">ID Proofs</p>

                <div className="row mb-3">
                  <div className="col-lg-3 col-md-6">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        AADHAR Number
                      </label>
                      <span className="ReviewLabel">
                        {lists.aadharNo || "-"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Upload AADHAR Card
                        {lists.aadharCardphoto && (
                          <a
                            href={`${ImageUrlPrefix}/${lists.aadharCardphoto}`}
                          >
                            {" "}
                            <Download className="download_icon" />
                          </a>
                        )}
                      </label>
                      <span className="ReviewLabel">
                        {lists.original_aadhar_path || "-"}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        PAN Number
                      </label>
                      <span className="ReviewLabel">{lists.panNo || "-"}</span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Uploaded PAN Card
                        {lists.panPhoto && (
                          <a href={`${ImageUrlPrefix}/${lists.panPhoto}`}>
                            {" "}
                            <Download className="download_icon" />
                          </a>
                        )}
                      </label>
                      <span className="ReviewLabel">
                        {lists.original_pan_path || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="StepOne">
            <div className="row">
              <div className="col-md-6">
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Present Address</p>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Present Address
                            </label>
                          </div>
                        </div>
                        <span className="ReviewLabel mb-2">
                          {" "}
                          {lists.BuildingNumberPresent || "-"}{" "}
                          {lists.BuildingNumberPresent ? "," : ""}{" "}
                          {lists.StreetNamePresent || "-"}
                        </span>

                        <span className="ReviewLabel  mb-2">
                          {" "}
                          {lists.AreaPresent || "-"}{" "}
                          {lists.AreaPresent ? "," : ""}{" "}
                          {lists.LandmarkPresent || "-"}
                        </span>
                        <span className="ReviewLabel  mb-2">
                          {" "}
                          {lists.TalukPresent || "-"}{" "}
                          {lists.TalukPresent ? "," : ""}{" "}
                          {lists.PostPresent || "-"}
                        </span>

                        <span className="ReviewLabel  mb-2">
                          {" "}
                          {lists.PincodePresent || "-"}{" "}
                          {lists.PincodePresent ? "," : ""}{" "}
                          {lists.CityPresent || "-"}
                        </span>

                        <span className="ReviewLabel  mb-2">
                          {" "}
                          {lists.districtPresent || "-"}{" "}
                          {lists.districtPresent ? "," : ""}{" "}
                          {lists.statePresent || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* widthForms */}
              <div className="col-md-6">
                <section className="StepOne ">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle"> Permanent Address</p>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              {lists.SamePresentAddress === true
                                ? "Present Address"
                                : "Permanent Address"}
                            </label>
                          </div>
                        </div>
                        <span className="ReviewLabel  mb-2">
                          {" "}
                          {lists.BuildingNumberPermanent || "-"}{" "}
                          {lists.BuildingNumberPermanent ? "," : ""}{" "}
                          {lists.StreetNamePermanent || "-"}
                        </span>
                        <span className="ReviewLabel  mb-2">
                          {" "}
                          {lists.AreaPermanent || "-"}
                          {lists.AreaPermanent ? "," : ""}{" "}
                          {lists.LandmarkPermanent || "-"}
                        </span>
                        <span className="ReviewLabel  mb-2">
                          {" "}
                          {lists.TalukPermanent || "-"}{" "}
                          {lists.TalukPermanent ? "," : ""}{" "}
                          {lists.PostPermanent || "-"}
                        </span>
                        <span className="ReviewLabel  mb-2">
                          {" "}
                          {lists.PincodePermanent || "-"}
                          {lists.PincodePermanent ? "," : ""}{" "}
                          {lists.CityPermanent || "-"}
                        </span>
                        <span className="ReviewLabel  mb-2">
                          {" "}
                          {lists.districtPermanent || "-"}{" "}
                          {lists.districtPermanent ? "," : ""}{" "}
                          {lists.statePermanent || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          {educationQualification.includes("UG") && (
            <section className=" StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">UG</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[0].UGCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {sslcCerficates[0].UGYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[0].UGDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[0].UGMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[0].UGMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[0].UGClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[0].UGNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded UG Certificate
                          {sslcCerficates[0].UG_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[0].UG_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[0].UG_Path !== null
                            ? sslcCerficates[0].original_file_path
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {educationQualification.includes("PG") && (
            <section className="StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">PG</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[1].PGCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {sslcCerficates[1].PGYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[1].PGDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[1].PGMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[1].PGMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[1].PGClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[1].PGNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[1].PG_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[1].PG_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[1].original_file_path || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {educationQualification.includes("Integrated PG") && (
            <section className=" StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Integrated PG</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].IPCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].IPYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].IPDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].IPMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].IPMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].IPClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].IPNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[2].IP_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[2].IP_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].original_file_path || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {educationQualification.includes("phd") && (
            <section className=" StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">PHD</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PHDCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PHDYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PHDDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PHDMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PHDMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PHDClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PHDNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[3].PHD_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[3].PHD_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].original_file_path || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {educationQualification.includes("Diploma") && (
            <section className="StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Diploma</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].DCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].DYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].DDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].DMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks of percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].DMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].DClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].DNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[4].D_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[4].D_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].D_Path || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {educationQualification.includes("Professional Courses") && (
            <section className=" StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Professional Courses</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].PCCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {sslcCerficates[5].PCYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].PCDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].PCMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].PCMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].PCClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].PCNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[5].PC_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[5].PC_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].PC_Path !== null
                            ? sslcCerficates[5].original_file_path
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {educationQualification.includes("Others") && (
            <section className=" StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Others</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[6].OtCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[6].OtYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[6].OtDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[6].OtMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[6].OtMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[6].OtClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[6].OtNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[6].Ot_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[6].Ot_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[6].Ot_Path !== null
                            ? sslcCerficates[6].original_file_path
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <div className="StepOne">
            <div className="row">
              {techQualification.map((el, index) => (
                <div
                  className={
                    techQualification.length === 1 ? "col-md-12 " : "col-md-12 "
                  }
                  key={index}
                >
                  <section className=" StepOne">
                    <div className="row box ">
                      <div className="col-md-12 ">
                        <p className="StepTitle">
                          Technical Qualification{" "}
                          {techQualification.length === 1 ? " " : `- ${el.id}`}
                        </p>

                        <div className="row mb-3">
                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Technicnal Course
                              </label>
                              <span className="ReviewLabel">
                                {el.name || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Typewriting Level
                              </label>
                              <span className="ReviewLabel">
                                {el.level || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Language
                              </label>
                              <span className="ReviewLabel">
                                {el.lang || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Certificate Number
                              </label>
                              <span className="ReviewLabel">
                                {el.Cnum || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Grade
                              </label>
                              <span className="ReviewLabel">
                                {el.grade || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Year of Passing
                              </label>
                              <span className="ReviewLabel">
                                {el.year !== "" ? el.year : ""}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Upload Certificate
                                {el.file && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${el.file}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {el.original_file_path || "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              ))}
            </div>
          </div>

          <div className="StepOne">
            <div className="row">
              <div className="col-md-12">
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Special / Preferential Qualification (if any)
                      </p>

                      <div className="row mb-3 mt-3">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Special / Preferential Qualification
                            </label>
                          </div>
                        </div>

                        <span className="ReviewLabel mb-2">
                          {lists.spq || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="StepOne">
            <div className="row">
              <div className="col-md-12">
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Previous Experience</p>

                      <div className="row ">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Do you have any previous working or teaching
                              experience?
                            </label>
                          </div>
                        </div>
                        <span className="ReviewLabel mb-2 mt-3">
                          {lists.prevTeachexp === "Yes" ? "Yes" : "No" || "-"}
                        </span>
                      </div>

                      <div className="row ">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Working Experience
                            </label>
                          </div>
                        </div>
                        <span className="ReviewLabel mb-2">
                          {lists.prevTeachexp === "Yes" ? lists.workingexp : ""}
                        </span>
                      </div>

                      <div className="row ">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Teaching Experience
                            </label>
                          </div>
                        </div>
                        <span className="ReviewLabel mb-2">
                          {lists.prevTeachexp === "Yes" ? lists.teachexp : ""}
                        </span>
                      </div>

                      <div className="row ">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Working Experience File{" "}
                              {lists.working_ex_file !== "" &&
                                lists.working_ex_file !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${lists.working_ex_file}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                          </div>
                        </div>
                        <span className="ReviewLabel mb-2">
                          {lists.prevTeachexp === "Yes"
                            ? lists.original_workexp
                            : ""}
                        </span>
                      </div>

                      <div className="row mb-3 ">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Teaching Experience File{" "}
                              {lists.Teaching_ex_file !== "" &&
                                lists.Teaching_ex_file !== null && (
                                  <a
                                    href={`${ImageUrlPrefix}/${lists.Teaching_ex_file}`}
                                    target="_blank"
                                    download
                                  >
                                    <span className="material-icons align-middle download_icon">
                                      file_download
                                    </span>
                                  </a>
                                )}
                            </label>
                          </div>
                        </div>
                        <span className="ReviewLabel mb-2">
                          {lists.prevTeachexp === "Yes"
                            ? lists.original_teachexp
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Current Job Info</p>

                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Job Type
                      </label>
                      <span className="ReviewLabel">
                        {lists.teachType === "0"
                          ? "Teaching"
                          : "NonTeaching" || "-"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Designation
                      </label>
                      <span className="ReviewLabel">
                        {lists.designation || "-"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Date of Joining
                      </label>
                      <span className="ReviewLabel">{lists.DJoin || "-"}</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Branch Location
                      </label>
                      <span className="ReviewLabel">
                        {lists.location_name || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" StepOne">
            {subject.length > 0 &&
              subject.map((item, index) => (
                <ReadViewSubject
                  id={"category_" + index}
                  key={"category_key" + index}
                  record={item}
                />
              ))}
          </section>
          <div className="StepOne">
            <div className="row">
              <div className="col-md-12">
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Voice Your Opinion</p>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Insights and Proposals
                            </label>
                          </div>
                        </div>

                        <span className="ReviewLabel mb-2">
                          {lists.VoiceOpinion || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Enrollment Details</p>

                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Faculty ID
                      </label>
                      <span className="ReviewLabel">
                        {lists.faculty_id || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Review by Admin</p>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="forms ">
                      <label htmlFor="Active">Employee Status:</label>
                      <div className="d-flex  col-md-12" id="radiobuttons">
                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="Adminstatus"
                            id="Active"
                            value="4"
                            checked={lists.Adminstatus === "4"}
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="Active">
                            Active
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="Adminstatus"
                            id="Hold"
                            value="2"
                            checked={lists.Adminstatus === "2"}
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="Hold">
                            Hold
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="Adminstatus"
                            id="Reject"
                            value="3"
                            checked={lists.Adminstatus === "3"}
                            onChange={handleChange}
                          />
                          <label className="checkbox-label" htmlFor="Reject">
                            Reject
                          </label>
                        </div>
                      </div>
                      {error.Adminstatus && (
                        <p className="error">{error.Adminstatus}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Comments
                      </label>
                      <textarea
                        name="comments"
                        id="comments"
                        onChange={handleChange}
                        defaultValue={lists.comments}
                        className="Inputs"
                        placeholder="Comments"
                      ></textarea>
                      {error.comments && (
                        <p className="error">{error.comments}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4">
                <button type="button" className="editSaveButton ">
                  <Link
                    to={
                      lists.teachType === "0"
                        ? `/facultyProfile/${userId}`
                        : `/NonFacultyProfile/${userId}`
                    }
                  >
                    <span className="material-icons  fs-5 align-middle ">
                      border_color
                    </span>
                  </Link>

                  <Link
                    to={
                      lists.teachType === "0"
                        ? `/facultyProfile/${userId}`
                        : `/NonFacultyProfile/${userId}`
                    }
                  >
                    Edit Faculty Profile
                  </Link>
                </button>
              </div>
              <div className="col-md-8">
                <div className="heading   dflex ">
                  <Link
                    to="javascript:void(0)"
                    onClick={navigateToPreviousScreen}
                    className="CancelButton "
                  >
                    Back to Faculties
                  </Link>
                  <button
                    type="button"
                    className="SaveButton MarginTop"
                    onClick={handleSubmit}
                  >
                    <a href="javascript:void(0)">Update and Submit</a>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
