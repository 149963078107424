import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import moment from "moment";
import Swal from "sweetalert2";
import ReadViewSubject from "./ReadViewSubject";
import CategorySubject from "./CategorySubject";

export default function ReadFaculty() {
  const modalCloseRef = useRef(null);
  const { userId } = useParams();
  const navigate = useNavigate();

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const [formFields, setFormFields] = useState({
    fname: "",
    lname: "",
    phoneNumber: "",
    email: "",
    TechType: "",
    designation: "",
    DJoin: "",
    location_name: "",
    location: "",
  });
  const [category, setCategory] = useState([]);
  const [subject, setSubject] = useState([]);

  useEffect(() => {
    AxiosInstance.post("api/staff/view", { user_id: userId })
      .then((res) => {
        // console.log(res,"findbyid");
        if (res.data.status === true) {
          setFormFields({
            fname: res.data.data.first_name,
            lname: res.data.data.last_name,
            phoneNumber: res.data.data.mobile_no,
            email: res.data.data.email,
            TechType:
              res.data.data.staff_type === 0 ? "Teaching" : "NonTeaching",
            designation: res.data.data.designation,
            location: res.data.data.staff_loc_id,
            location_name: res.data.data.staff_loc_name,
            DJoin: moment(res.data.data.aiasa_doj).format("YYYY-MM-DD"),
          });
          let categoryArray =
            res.data.data.category !== "" &&
            res.data.data.category !== null &&
            res.data.data.category.split(",");
          setCategory(categoryArray);
          const originalData =
            res.data.data.subject !== null &&
            res.data.data.subject !== "" &&
            res.data.data.subject.reduce((acc, cur) => {
              const categoryIndex = acc.findIndex(
                (item) => item.category === cur.category
              );
              if (categoryIndex === -1) {
                acc.push({
                  id: acc.length + 1,
                  category: cur.category,
                  optionSelected: [
                    {
                      value: cur.subject,
                      label: cur.subject,
                    },
                  ],
                });
              } else {
                acc[categoryIndex].optionSelected.push({
                  value: cur.subject,
                  label: cur.subject,
                });
              }
              return acc;
            }, []);

          // console.log(originalData,"originalData");
          setSubject(originalData);
        }
      })
      .catch((error) => {
        return error;
      });
  }, [userId]);

  const handleCInvitation = (e) => {
    AxiosInstance.post("api/staff/cancelInvite", {
      user_id: userId,
      updated_by: userid,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          Swal.fire({
            title: "Success",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "Ok",
            willClose: () => {
              const modal = document.querySelector("#exampleModal"); // Adjust the selector as needed
              // const modal_close = document.querySelector('#closeModel');
              // if (modal) {
              //   modal_close.click();
              // }
              const modal_close = modalCloseRef.current;
              if (modal && modal_close) {
                modal_close.click();
              }
            },
          });
        } else {
          Swal.fire({
            title: "warning",
            text: res.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handleResend = (e) => {
    const ResendLink = {
      user_id: userId,
    };
    // console.log(ResendLink, "ResendLink");
    AxiosInstance.post("api/staff/resendInvite", ResendLink)
      .then((res) => {
        if (res.data.status === true) {
          Swal.fire({
            title: "Success",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigateToPreviousScreen();
            }
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  return (
    <div className=" Faculty">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading dflexbetww ">
              <div className=" mt-4 dbetw">
                <h6 className="Title">View Faculty Profile</h6>
                <Link
                  to="javascript:void(0)"
                  onClick={navigateToPreviousScreen}
                  className="CancelButtons MobileShow mt-0"
                >
                  Back to Faculties
                </Link>
              </div>
              <div className="d-flex smButton  mobiledown mt-4">
                <Link
                  to="javascript:void(0)"
                  onClick={navigateToPreviousScreen}
                  className="CancelButtons MobileHide"
                >
                  Back to Faculties
                </Link>
                <button
                  type="button"
                  className="ListViewBtn_facul me-xl-4 me-md-2"
                >
                  <Link
                    to={`/Faculties/addfaculty/${
                      formFields.TechType === "Teaching" ? 0 : 1
                    }/${userId}`}
                  >
                    <span className="material-icons me-1 fs-5 align-middle text-white">
                      border_color
                    </span>
                  </Link>

                  <Link
                    to={`/Faculties/addfaculty/${
                      formFields.TechType === "Teaching" ? 0 : 1
                    }/${userId}`}
                  >
                    Edit Faculty Profile
                  </Link>
                </button>

                <button
                  type="button"
                  className="ListViewBtn_facul me-xl-4 me-md-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <Link to="javascript:void(0)">
                    <span className="material-icons me-1 fs-5 align-middle text-white">
                      delete
                    </span>
                  </Link>

                  <Link to="javascript:void(0)">Cancel Invitation</Link>
                </button>
                <button
                  type="button"
                  className="ListViewBtn"
                  onClick={handleResend}
                >
                  <a href="javascript:void(0)">Resend Invitation Link</a>
                </button>
              </div>
            </div>

            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Basic Details</p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-4">
                      <div className="col-md-6 col-lg-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            First Name
                          </label>
                          <span className="ReviewLabel">
                            {formFields.fname || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Last Name
                          </label>
                          <span className="ReviewLabel">
                            {formFields.lname || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Phone Number
                          </label>
                          <span className="ReviewLabel">
                            {formFields.phoneNumber || ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Email ID
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {formFields.email || ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Current Job Info</p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-4">
                      <div className="col-md-6 col-lg-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Job Type
                          </label>
                          <span className="ReviewLabel">
                            {formFields.TechType || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Designation
                          </label>
                          <span className="ReviewLabel">
                            {formFields.designation || ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Date of Joining
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {formFields.DJoin || ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Branch Location
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {formFields.location_name || ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="StepOne">
            {subject.length > 0 &&
              subject.map((item, index) => (
                <ReadViewSubject
                  id={"category_" + index}
                  key={"category_key" + index}
                  record={item}
                />
              ))}
          </section>
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Cancel Invitation
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    ref={modalCloseRef}
                    id="closeModel"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <p>Are you sure you want to cancel the invitation?</p>
                </div>
                <div class="modal-footer">
                  <span class="backView" data-bs-dismiss="modal">
                    Back to View Faculty Profile
                  </span>
                  <button
                    type="button"
                    class="Modalbtn"
                    onClick={handleCInvitation}
                  >
                    <a href="javascript:void(0)">Yes, Cancel Now</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
