import React, { useEffect, useState } from "react";
import { json, Link } from "react-router-dom";

export default function ViewTestCourseBatch() {
  const [courseData, setCourseData] = useState([]);

  const handleEdit = (index) => {
    window.location.href = `/createTestcoursebatch/${index}`;
  };

  const handleDelete = (index) => {
    // console.log(index,"...delete index");
    const courseItem = sessionStorage.getItem("createTestcoursebatch");
    if (courseItem) {
      const courses = JSON.parse(courseItem);
      const DeleteCourse = courses.filter((course, i) => i !== index);
      // console.log(DeleteCourse,"....selectedCourse");
      sessionStorage.setItem(
        "createTestcoursebatch",
        JSON.stringify(DeleteCourse)
      );
      window.location.reload();
    }
  };

  useEffect(() => {
    const storedItem = sessionStorage.getItem("createTestcoursebatch");
    if (storedItem) {
      // const storedObject = JSON.parse(storedItem);
      // const arrayFromObject = Object.entries(storedObject).map(([key, value]) => ({ key, value }));
      setCourseData(JSON.parse(storedItem));
    }
  }, []);
  // console.log(courseData);
  return (
    <>
      <div className="p-5 ">
        <button className="btn btn-success course">
          <Link to="/createTestcoursebatch" className="course_link">
            Create Test Batch Course
          </Link>
        </button>
      </div>

      <div className="p-5">
        <h1>Test Batch Course List</h1>
        {courseData.length > 0 ? (
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Class Name</th>
                <th>Class Mode</th>
                <th>Medium</th>
                <th>Fees</th>
                <th>Duration</th>
                <th>Batch Details</th>
                <th>Start on</th>
                {/* <th>End on</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {courseData.map((course, index) => (
                <tr key={index}>
                  <td>{course.courseCategory}</td>
                  <td>{course.subCategory}</td>
                  <td>{course.class_name}</td>
                  <td>{course.classMode}</td>
                  <td>{course.medium}</td>
                  <td>{course.fees}</td>
                  <td>{course.duration} Months</td>
                  <td>{course.batch}</td>
                  <td>{course.startDate}</td>
                  {/* <td>{course.endDate}</td>                 */}
                  <td>
                    <button
                      className="btn btn-primary me-2"
                      onClick={() => handleEdit(index)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div class="alert alert-danger" role="alert">
            No Test batch course data found.
          </div>
        )}
      </div>
    </>
  );
}
