import React from "react";
import styled from "styled-components";
import { MEDIA_SIZE_MD } from "../utils/helper";

const ButtonContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  @media (min-width: ${MEDIA_SIZE_MD}) {
    justify-content: end;
  }
`;

export default function ButtonContainer({ children }) {
  return <ButtonContainerBox>{children}</ButtonContainerBox>;
}
