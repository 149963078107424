import moment from "moment";

export default function ModelExamStatus({ course, currentDate, tenAM }) {
  // Convert course dates to moment objects
  const startDate = moment(course.started_dt);
  const endDate = moment(course.end_dt);

  return (
    <>
      {currentDate.isBetween(
        startDate,
        endDate,
        "day",
        "[]" // Include the end date
      ) && currentDate.isAfter(tenAM) ? (
        <td id="td">
          <span className="GreenTexts">OnGoing</span>
        </td>
      ) : currentDate.isBefore(startDate, "day") ||
        (currentDate.isSame(startDate, "day") &&
          currentDate.isBefore(tenAM)) ? (
        <td id="td">
          <span className="PinkText">Upcoming</span>
        </td>
      ) : (
        <td id="td">
          <span className="GreyText">Completed</span>
        </td>
      )}
    </>
  );
}
