import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import {
  BACKGROUNDCOLOR,
  CHART_OVERALL,
  MEDIA_SIZE_LG,
} from "../../../../../utils/helper";
import StyledScrollableContainer from "../../../../../ui/StyledScrollableContainer";
import { studentPerfomanceChart } from "../../../../../services/services";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { createOverallAnalysisScore } from "../../../../../redux/ChartSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
const ZERODATA_OVER_WRITE_VALUE = 2;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  border-radius: 6px;
  border-bottom: 1px solid var(--color-white);
  /* &:hover {
    border-bottom: 1px solid var(--color-brand-800);
  } */
  @media (min-width: ${MEDIA_SIZE_LG}) {
    margin-right: 30px;
  }
`;

const StyledLabelBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 5px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    width: 30px;
    margin-right: 10px;
  }
`;

const StyledLabel = styled.span`
  font-size: 12px;
  /* color: #ccc; */
  /* @media (min-width: ${MEDIA_SIZE_LG}) {
    margin-right: 30px;
  } */
`;
const StyledLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const BarChart = ({
  chartData = [],
  batchID = 0,
  eventID = 0,
  userID = 0,
  isOverall,
  onSetIsoverall,
  onSetLabel,
  onSetSelectLoading,
}) => {
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "overall analysis",
      },
      legend: {
        display: false,
        position: "top",
      },
      datalabels: {
        color: "#111",
        display: function (context) {
          return context.dataset.data[context.dataIndex] > 0;
        },
        formatter: function (value) {
          // here we overwrite the data value by substracting added value to show the mark scored by the user
          return value - ZERODATA_OVER_WRITE_VALUE;
        },
        anchor: "end",
        align: "top",
        // offset: -5,
        offset: -7,
        font: {
          weight: "bold",
        },
      },
      tooltip: {
        callbacks: {
          label: function (labelData) {
            const { raw, dataset } = labelData;
            return `${dataset.label} : ${raw - ZERODATA_OVER_WRITE_VALUE}`;
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: isOverall ? "Test Type" : "Test Name",
          // padding: 20,
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        display: true,
        position: "left",
        beginAtZero: true,
        title: {
          display: true,
          text: "Test Score in Percentage",
          padding: 10,
        },
      },
    },
  };
  const dispatch = useDispatch();
  const labels = chartData.map((data) => data.name);
  // here we overwrite data value to show bars event when value is zero
  const data = chartData.map((data) => data.mark + ZERODATA_OVER_WRITE_VALUE);
  // const data = chartData.map((data) => data.mark);

  const generatedBgColors = Array.from({ length: labels.length }, (_, i) => {
    let bgArray = [];
    // generate seques of bg colors from BACKGROUND COLOR array
    let ind = i % 13;

    // Push the corresponding color to bgArray
    bgArray.push(BACKGROUNDCOLOR[ind]);

    return bgArray;
  }).flatMap((colorArray) => colorArray);
  const testData = {
    labels: labels,
    datasets: [
      {
        label: "Percentage",
        backgroundColor: generatedBgColors,
        borderRadius: 5,
        borderWidth: 0,
        data: data,
        minBarLength: 1,
      },
    ],
  };
  // Generate legend items
  const legendItems = testData.labels.map((label, index) => {
    return (
      <div
        key={index}
        style={{ display: "flex", alignItems: "center", margin: "5px" }}
      >
        <StyledButton
          onClick={() =>
            handleLegend(chartData[index].testType, chartData[index].name)
          }
          style={{
            cursor: isOverall ? "pointer" : "default",
            border: isOverall ? "2px solid #c1393d" : "none",
          }}
        >
          <StyledLabelBox
            style={{
              backgroundColor: testData.datasets[0].backgroundColor[index],
            }}
          ></StyledLabelBox>
          <StyledLabel>{label}</StyledLabel>
        </StyledButton>
      </div>
    );
  });
  const handleLegend = (testType, name) => {
    if (!isOverall) return;

    const formData = {
      batch_id: batchID,
      event_id: eventID,
      user_id: userID,
      type: CHART_OVERALL,
      overall: 0,
      test_type_pk: testType,
    };
    onSetSelectLoading(true);
    studentPerfomanceChart(formData)
      .then((res) => {
        const { status, data } = res;
        if (status) {
          const { scores } = data;
          if (scores.length > 0) {
            const modifiedStScore = scores.map((score) => {
              return {
                // name: score.event_type_title,
                name: score.event_name,
                mark: score.marks_in_perc,
                testType: score.test_type_pk,
              };
            });

            if (isOverall) {
              // overwrite the label for overall score analysis only if overall is availabel
              onSetIsoverall(false);
            }
            onSetLabel(name);
            dispatch(createOverallAnalysisScore(modifiedStScore));
          } else {
            toast.error(`${name} Not Yet Taken`);
          }
        } else {
          console.error("studentPerfomanceChart api error");
        }
      })
      .catch((err) => err)
      .finally(() => onSetSelectLoading(false));
  };
  return (
    <div>
      <StyledScrollableContainer>
        <Bar data={testData} options={options} height={400} width={1000} />
      </StyledScrollableContainer>
      <StyledLegendContainer>{legendItems}</StyledLegendContainer>
    </div>
  );
};

export default BarChart;
