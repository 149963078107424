import moment from "moment";
import React from "react";

export default function StatusCheck(props) {
  const { status } = props;
  const startMoment = moment(status.started_dt);
  const endMoment = startMoment.clone().add(status.duration, "months");
  const currentDate = moment();
  // const { status } = props;
  // const startMoment = moment(status.started_dt.toString() + " 10:00:00 am");
  // const startMoment_f = moment(moment(startMoment).format('YYYY-MM-DD hh:mm:ss a'));
  // const endMoment = moment( moment(startMoment.toString()).add(1,'months')).format("YYYY-MM-DD");
  // const endMoment_T = moment(endMoment.toString() + " 11:00:00 pm");
  // const endMoment_f = moment(moment(endMoment_T).format('YYYY-MM-DD hh:mm:ss a'));
  // console.log(startMoment_f.toString(),"dfsdf",endMoment_T.toString(),"dfdf",endMoment_f.toString(),"fsf");
  // const currentDate = moment();
  //   const tenAM = moment().startOf("day").hour(10); // Start of day is 10 AM
  return (
    <td id="td">
      {endMoment.isBefore(currentDate) ? (
        <span className="GreyText">completed</span>
      ) : currentDate.isBetween(
          startMoment.startOf("day").add(10, "hours"),
          endMoment.startOf("day").add(10, "hours"),
          null,
          "[]"
        ) ? (
        <span className="GreenTexts">Ongoing</span>
      ) : (
        <span className="PinkText">Upcoming</span>
      )}{" "}
    </td>
  );
}
