import React, { useEffect, useState } from "react";
import { json, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Degree() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const handleEdit = (index) => {
    navigate(`/createDegree/${index}`);
  };

  const handleDelete = (index) => {
    // console.log(index,"...delete index");
    const storedItem = sessionStorage.getItem("degreedata");
    if (storedItem) {
      const items = JSON.parse(storedItem);
      const Deleteitem = items.filter((item, i) => i !== index);
      sessionStorage.setItem("degreedata", JSON.stringify(Deleteitem));
      window.location.reload();
    }
  };

  useEffect(() => {
    const storedItem = sessionStorage.getItem("degreedata");
    if (storedItem) {
      setData(JSON.parse(storedItem));
    }
  }, []);
  return (
    <>
      <div className="p-5 ">
        <button className="btn btn-success Test">
          <Link to="/createDegree" className="Test_link">
            Create Degree
          </Link>
        </button>
      </div>

      <div className="p-5">
        <h1>Degree List</h1>
        {data.length > 0 ? (
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Degree Type</th>
                <th>Degree Name</th>
                <th>Department</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.degreeType}</td>
                  <td>{item.degree_name}</td>
                  <td>{item.department}</td>
                  <td>
                    <button
                      className="btn btn-primary me-2"
                      onClick={() => handleEdit(index)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div class="alert alert-danger" role="alert">
            No degree data found.
          </div>
        )}
      </div>
    </>
  );
}
