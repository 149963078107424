import React, { useEffect, useState } from "react";
import { Calendar, TimeGrid, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import CustomEvent from "../CourseBatch/CustomEvent";

const formats = {
  weekdayFormat: (date, culture, localizer) => {
    return localizer.format(date, "ddd", culture);
  },
  dayHeaderFormat: (date, culture, localizer) => {
    return localizer.format(date, "ddd", culture);
  },
  dayFormat: (date, culture, localizer) => {
    return localizer.format(date, "D", culture);
  },
  monthHeaderFormat: (date, culture, localizer) => {
    return localizer.format(date, "MMMM YYYY", culture);
  },
};
const eventStyleGetter = (event) => {
  const backgroundColor = "#" + event.hexColor;
  const style = {
    backgroundColor: backgroundColor,
    borderRadius: "0px",
    opacity: 0.8,
    color: "white",
    border: "0px",
    display: "block",
  };
  return {
    style: style,
  };
};

//  const  MyToolbar = ({ dates, view , onView ,onNavigate, label }) => {
const MyToolbar = ({ date, view, onView, onNavigate, label }) => {
  // console.log(date,"sdsdsf");
  const { batch_id, batch_type } = useParams();
  const [events, setEvents] = useState([]);
  //  console.log(batch_id,"batch_Id" , batch_type);
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedPickDate, setSelectedPickDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handlePrevClick = (newDate) => {
    // console.log(newDate);
    onNavigate("PREV");
    const dates = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    // console.log(dates,"skjhdasjfhksajhf");
    setSelectedPickDate(dates.toDate());
    const formattedDate = dates.format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    const datess = moment(formattedDate); // Replace with your desired date
    const startOfMonth = datess.startOf("month").toDate(); // Start date of the month
    const endOfMonth = datess.endOf("month").toDate(); // End date of the month

    AxiosInstance.post("api/event/list", {
      batch_id: parseInt(batch_id),
      start_date: startOfMonth,
      end_date: endOfMonth,
      // event_type: batch_type === "1" || batch_type === 1 ? 1 : 0,
      event_type: "",
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${
              event.start_time
            }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${
              event.end_time
            }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              event_type: event.event_type,
              start: startDate,
              end: endDate,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              startTime: event.start_time,
              endTime: event.end_time,
              description: event.description,
              syllabus_filepath: event.syllabus_filepath,
            };
          });
          setEvents(parsedEvents);
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handleFilter = (event) => {
    onView(event.target.value);
  };

  const handleNextClick = (newDate) => {
    onNavigate("NEXT");
    const dates = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    setSelectedPickDate(dates.toDate());
    const formattedDate = dates.format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    const datess = moment(formattedDate); // Replace with your desired date
    const startOfMonth = datess.startOf("month").toDate(); // Start date of the month
    const endOfMonth = datess.endOf("month").toDate(); // End date of the month

    AxiosInstance.post("api/event/list", {
      batch_id: parseInt(batch_id),
      start_date: startOfMonth,
      end_date: endOfMonth,
      // event_type: batch_type === "1" || batch_type === 1 ? 1 : 0,
      event_type: "",
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${
              event.start_time
            }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${
              event.end_time
            }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              start: startDate,
              event_type: event.event_type,
              end: endDate,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              startTime: event.start_time,
              endTime: event.end_time,
              description: event.description,
              syllabus_filepath: event.syllabus_filepath,
            };
          });
          setEvents(parsedEvents);
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const handleChange = (dates) => {
    setSelectedPickDate(dates);
    const formattedDate = moment(dates).format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    setShowDatePicker(false);
    const newDate = moment(dates).toDate();
    onNavigate("DATE", newDate);
  };
  const handleToday = () => {
    const today = moment();
    onNavigate("TODAY");
    setSelectedDates(today.format("YYYY-MM-DD"));
    setSelectedPickDate(today.toDate());

    //   // Calculate the current scroll position
    // const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    // // Calculate the new scroll position (700 pixels down from the current position)
    // const targetScrollPosition = currentScrollPosition ;

    // // Scroll the window to the new calculated position
    // window.scrollTo({
    //   top: targetScrollPosition,
    //   behavior: 'smooth'
    // });
  };
  return (
    <div className="row mb-3" id="mytoolbar">
      <div className="col-md-3">
        <h4 className="CalendarTitle" onClick={toggleDatePicker}>
          {selectedDates
            ? moment(selectedDates).format("MMMM YYYY")
            : moment().format("MMMM YYYY")}
          <span class="material-icons align-bottom">arrow_drop_down</span>
        </h4>
        {showDatePicker && (
          <DatePicker
            selected={selectedPickDate}
            onChange={handleChange}
            inline
            className="datepicker"
          />
        )}
      </div>
      <div className="col-md-3">
        <button className="calViewBtn" onClick={handleToday}>
          Go to today
        </button>
      </div>
      <div className="col-md-3 d-flex justify-content-lg-end justify-content-start">
        <div className="up-in-toggle">
          <input
            type="radio"
            id="switch_left"
            name="view"
            value="month"
            checked={view === "month"}
            onChange={handleFilter}
          />
          <label htmlFor="switch_left">Month</label>
          <input
            type="radio"
            id="switch_right"
            name="view"
            value="week"
            checked={view === "week"}
            onChange={handleFilter}
          />
          <label htmlFor="switch_right">Week</label>
        </div>
      </div>
      <div className="col-md-3 d-flex justify-content-lg-end justify-content-start">
        <button
          className="PrevBtns"
          onClick={() =>
            handlePrevClick(
              moment(date)
                .subtract(1, view === "month" ? "month" : "week")
                .toDate(),
              view
            )
          }
        >
          <span class="material-icons">navigate_before</span>
        </button>
        <button
          className="PrevBtns"
          onClick={() =>
            handleNextClick(
              moment(date)
                .add(1, view === "month" ? "month" : "week")
                .toDate(),
              view
            )
          }
        >
          <span class="material-icons">navigate_next</span>
        </button>
      </div>

      {/* <input value={moment(date).format('YYYY-MM-DD')}  onChange={handleChange} type="date"/> */}
    </div>
  );
};

// calendar function

function TestCalendar(props) {
  // const batch_Id = props.batch_id;
  // console.log(props.batch_id,"propsid");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (props.eventFields) {
      setEvents(props.eventFields);
    }
  }, [props.eventFields]);

  const moment = require("moment-timezone");
  const navigate = useNavigate();
  const [view, setView] = useState("month");
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedPickDate, setSelectedPickDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handleDateChange = (date) => {
    setSelectedPickDate(date);

    const formattedDate = moment(date).format("YYYY-MM-DD");
    setSelectedDates(formattedDate);

    setShowDatePicker(false);
    // const newDate = moment(formattedDate).toDate();
    // onNavigate('DATE', newDate);
  };
  const [date, setDate] = useState(moment().toDate());

  //navigate function
  const handleNavigate = (newDate, newView) => {
    // alert('lo');
    setDate(newDate);
    const date = moment(newDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const formattedDate = date.format("YYYY-MM-DD");
    const dates = moment(formattedDate); // Replace with your desired date
    const startOfMonth = dates.startOf("month").toDate(); // Start date of the month
    const endOfMonth = dates.endOf("month").toDate(); // End date of the month
    setView(newView);
    AxiosInstance.post("api/event/list", {
      batch_id: parseInt(props.batch_id),
      start_date: startOfMonth,
      end_date: endOfMonth,
      // event_type: props.type === "1" || props.type === 1 ? 1 : 0,
      event_type: "",
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const parsedEvents = res.data.data.map((event) => {
            const startDateStr = `${event.start_date.split("T")[0]}T${
              event.start_time
            }`;
            const endDateStr = `${event.start_date.split("T")[0]}T${
              event.end_time
            }`;
            const startDate = moment(startDateStr).toDate();
            const endDate = moment(endDateStr).toDate();
            return {
              id: event.id,
              title: event.name,
              event_type: event.event_type,
              start: startDate,
              end: endDate,
              url: event.teams_url,
              subject: event.subject,
              staff_name: event.staff_name,
              startTime: event.start_time,
              endTime: event.end_time,
              description: event.description,
              syllabus_filepath: event.syllabus_filepath,
            };
          });
          setEvents(parsedEvents);
        }
      })
      .catch((error) => {
        return error;
      });
  };
  // view change function
  const handleView = (view) => {
    setView(view);
  };
  const handleDrillDown = () => {
    setView("week");
  };

  const [selectedDate, setSelectedDate] = useState(null);

  //create event function
  const handleSelectSlot = ({ start }) => {
    const sDate = moment(start).format("YYYY-MM-DD");
    const batch_id = props.batch_id;
    const category = props.category;
    // navigate(`/CreateEvent/${sDate}/${props.batch_id}/${props.category}`)

    navigate(`/CreateEvent`, {
      state: {
        sDate,
        batch_id,
        category,
      },
    });
    //  setSelectedDate(start);
    // setEvents([...events, { start, end: moment(start).add(1, 'hour') }]);
  };
  //edit event function
  const handleSelectEvent = (event) => {
    const sDates = event.start;
    const eDates = event.start;
    const outputFormat = "YYYY-MM-DD";

    const momentObj = moment(sDates, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const momentObjs = moment(sDates, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    // format the moment object using the output format
    // console.log(momentObj,"momentObj");
    const sDate = momentObj.format(outputFormat);
    const eDate = momentObjs.format(outputFormat);

    // console.log(sDate,eDate,"sjhsjfhsjf" ,event);
    let id = event.id;
    let type = event.type;
    let startTime = event.startTime;
    let endTime = event.endTime;
    // let event_types = event.type === "1" || event.type === 1 ? 1 : 0;
    let event_type = event.event_type;
    if (event_type === 1 || event_type === "1") {
      const startDate = moment(sDate, "YYYY-MM-DD");
      const endDate = moment(eDate, "YYYY-MM-DD");
      const currentDate = moment();
      // //         console.log("currentDate:", currentDate.format("YYYY-MM-DD"));
      // // console.log("startDate:", startDate.format("YYYY-MM-DD"));
      // // console.log("endDate:", endDate.format("YYYY-MM-DD"));

      // if (currentDate.isBefore(startDate)) {
      //   // console.log("Future: navigate with status 2");
      //   navigate(`/ViewTestDetail/${props.batch_id}/${id}/2/${event_type}`);
      // } else if (
      //   currentDate.isSameOrAfter(startDate) &&
      //   currentDate.isSameOrBefore(endDate)
      // ) {
      //   // console.log("Ongoing: navigate with status 1");
      //   navigate(`/ViewTestDetail/${props.batch_id}/${id}/1/${event_type}`);
      // } else {
      //   // console.log("Past: navigate with status 0");
      //   navigate(`/ViewTestDetail/${props.batch_id}/${id}/0/${event_type}`);
      // }

      if (
        moment(startDate).isAfter(currentDate, "day") ||
        (moment(currentDate).isSame(startDate, "day") &&
          moment(startTime, "HH:mm:ss").isAfter(currentDate, "minute") &&
          moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
      ) {
        // Event is in the future
        navigate(
          `/ViewTestDetail/${props.batch_id}/${id}/2/${event_type}/${props.batch_type}/0`
        );
      } else if (
        (moment(currentDate).isSame(startDate, "day") &&
          moment(startTime, "HH:mm:ss").isSame(currentDate, "minute")) ||
        (moment(currentDate).isSame(startDate, "day") &&
          moment(startTime, "HH:mm:ss").isBefore(currentDate, "minute") &&
          moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
      ) {
        // Event is ongoing
        navigate(
          `/ViewTestDetail/${props.batch_id}/${id}/1/${event_type}/${props.batch_type}/0`
        );
      } else if (
        (moment(currentDate).isSame(startDate, "day") &&
          moment(endTime, "HH:mm:ss").isSame(currentDate, "minute")) ||
        (moment(currentDate).isSame(startDate, "day") &&
          moment(startTime, "HH:mm:ss").isBefore(currentDate, "minute") &&
          moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
      ) {
        // Event is ongoing
        navigate(
          `/ViewTestDetail/${props.batch_id}/${id}/1/${event_type}/${props.batch_type}/0`
        );
      } else if (
        moment(startDate).isBefore(currentDate, "day") ||
        (moment(currentDate).isSame(startDate, "day") &&
          moment(endTime, "HH:mm:ss").isBefore(currentDate, "minute"))
      ) {
        // Event is in the past
        navigate(
          `/ViewTestDetail/${props.batch_id}/${id}/0/${event_type}/${props.batch_type}/0`
        );
      }
    } else {
      navigate("/ViewEventDetails", {
        state: {
          id,
          type,
        },
      });
    }
  };

  //month / week radio button
  const handleFilter = (e) => {
    const filter = e.target.value;
    if (filter === "month") {
      setView("month");
    } else if (filter === "week") {
      setView("week");
    }
  };

  //go to today button function

  const handleTodayClick = () => {
    // setSelectedDates(moment().format('YYYY-MM-DD'));
    // setSelectedPickDate(new Date());
    const now = new Date();
    this.calendarRef.current.getApi().gotoDate(now);
    // onNavigate('TODAY');
  };
  const localizer = momentLocalizer(moment);
  return (
    <>
      <div className="row">
        <Calendar
          //  cla_id={batch_Id}
          weekStartsOn={1}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700 }}
          onDrillDown={handleDrillDown} // Handle the "Show More" button click
          view={view}
          formats={formats}
          components={{
            toolbar: MyToolbar,
            event: (props) => <CustomEvent {...props} />,
          }}
          selectable
          // onSelectSlot={(slotInfo) => {
          //   const startOfMonth = moment(date).startOf('month');
          //   const endOfMonth = moment(date).endOf('month');
          //   const start = moment(slotInfo.start);
          //   const end = moment(slotInfo.end);
          //   if (start.isBetween(startOfMonth, endOfMonth, undefined, '[]') && end.isBetween(startOfMonth, endOfMonth, undefined, '[]')) {
          //     handleSelectSlot(slotInfo);
          //   } else {
          //     alert('Please select a date within the  month.');
          //   }
          // }}
          onView={handleView}
          onSelectEvent={handleSelectEvent}
          date={date}
          // defaultView={view.WEEK}
          // showMultiDayTimes={false}
          onNavigate={handleNavigate}
        />
      </div>
    </>
  );
}
export default TestCalendar;
