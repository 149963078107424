import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";
import DatePickerSample from "../../DatePicker";
function BookPurchase(props) {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const txtBSearch = useRef(null); //Search icon
  // List data load table
  let page = R_PER_PAGE;
  const [isLoading, setIsLoading] = useState(false);
  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [isOpen, setIsOpen] = useState({
    status: false,
    startDate: false,
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const [updateChipData, setUpdateChipData] = useState([]);

  const [status, setStatus] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [time, setTime] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [classModes, setClassModes] = useState([]);
  const [mediums, setMedium] = useState([]);
  let filterStatus = [];
  let filterType = [];
  let filterApplyed = [];
  let filterSearch = [];

  useEffect(() => {
    filterStatus = status;
  }, [status]);

  useEffect(() => {
    filterType = typeOn;
  }, [typeOn]);

  useEffect(() => {
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  // useEffect(() => {
  //   console.log(" updateListData : ", updateListData);
  //   currentData = updateListData.slice(start, end);
  //   // total table data  with pages
  //   totalPages = Math.ceil(updateListData.length / rowsPerPage);
  // }, [updateListData]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    filterApplyed = appOn;

    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    // console.log(names, " : ", status);
    const { value, checked, name } = event.target;
    if (name === "Upcoming" || name === "Ongoing" || name === "Completed") {
      if (checked) {
        // setStatus([...status, value]);
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (typename === "type") {
      if (checked) {
        setTypeOn([...typeOn, { name: value, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.name !== value));
      }
    }
  };

  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  // --------New code (Search Icon)----
  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      // Create a new KeyboardEvent
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        keyCode: 13,
        which: 13,
        code: "Enter",
        bubbles: true,
      });

      // Dispatch the event
      txtBSearch.current.dispatchEvent(event);
    }
  };
  //-------end ------
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setUpdateChipData([]);
    setAppOn([]);
    setTypeOn([]);
    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    }
  };

  function loadListdata() {
    let tempstatus = [];
    let temptypeon = [];

    let tempMedium = [];
    status.map((obj) => {
      tempstatus.push(obj.id);
    });

    typeOn.map((obj) => {
      temptypeon.push(obj.name);
    });

    let statusFilter = {
      //   category: typeOn.length > 0 ? temptypeon : "",
      //   status: status.length > 0 ? tempstatus : "",
      //   batch_type: schedule.length > 0 ? tempsche : "",
      //   medium: mediums.length > 0 ? tempMedium : "",
      //   class_mode: classModes.length > 0 ? tempclassMode : "",
      //   start_date: appOn.length > 0 ? appOn[0].fdate + " 00:00:00" : "",
      //   end_date: appOn.length > 0 ? appOn[0].edate + " 23:59:00" : "",
      //   start_time: time.length > 0 ? [time[0].STime] : "",
      //   end_time: time.length > 0 ? [time[0].TTime] : "",
      //   search: searchTerm.length > 0 ? searchTerm : "",
    };
    // console.log(statusFilter, "statusFilter");

    // AxiosInstance.post("", statusFilter)
    //   .then((res) => {
    //     // console.log("editadasd ", res);
    //     if (res.data.status === true) {
    //       const fiata = res.data.data;
    //       const serialNumberAddedData = fiata.map((data, index) => ({
    //         ...data,
    //         serialNumber: index + 1,
    //       }));
    //       // setUpdateListData(fiata);
    //       setUpdateListData(serialNumberAddedData);
    //     } else if (res.data.status === false) {
    //       setUpdateListData([]);
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log("Error res: ", error.response.data);
    //     setUpdateListData([]);
    //     return error;
    //   });
  }

  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });

    filterType.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyed.map((obj) => {
      tempArr.push(obj);
    });

    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }

  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };
  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        type: false,
      });
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.name !== chipToDelete.name));
      filterType = typeOn.filter((item) => item.name !== chipToDelete.name);
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    }
    loadChipdata();
  };

  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };

  const handleFilter = (e, boxName) => {
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        ...prevState,
        status: !prevState.status,
      }));
    } else if (boxName === "startDate") {
      setIsOpen((prevState) => ({
        ...prevState,
        startDate: !prevState.startDate,
      }));
    }
    // else if (boxName === "course") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     course: !prevState.course,
    //   }));
    // }
  };

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add a title to the PDF document
    doc.text("Table Data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    // Add header row to tableData array
    const headers = [];
    const tableHeader = document.querySelectorAll("#example th");
    for (const header of tableHeader) {
      headers.push(header.innerText);
    }
    tableData.push(headers);

    // Add data rows to tableData array
    const tableRows = document.querySelectorAll("#example tbody tr");
    for (const row of tableRows) {
      const rowData = [];
      const rowCells = row.querySelectorAll("td");
      for (const cell of rowCells) {
        rowData.push(cell.innerText);
      }
      tableData.push(rowData);
    }

    // Add the table to the PDF document
    doc.autoTable({
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
    });
    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const handleReadView = (id) => {
    // navigate(`/DetailViewBatch/${id}`);
  };

  const handleEditView = (id) => {
    // navigate(`/createCourseBatch/${id}`);
  };
  // className="row tab-content mt-4"
  return (
    <div
      className={
        props.activeTab === 3
          ? "row tab-content mt-4"
          : "row tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      <div
        className={
          props.activeTab === 3
            ? "tab-pane fade show active col-md-12"
            : "tab-pane fade col-md-12 "
        }
      >
        <div className="row ms-1">
          <div className="col-lg-3 col-md-4 ps-0">
            <div class="input-group">
              <input
                ref={txtBSearch} //new
                type="text"
                class="Inputs"
                id="SearchInput"
                name="SearchInput"
                placeholder="Search by Name and course"
                onKeyDown={handleSearch}
              />
              <span
                class="input-group-text"
                id="SearchInput"
                onClick={handleSearchFocus}
              >
                <span className="material-icons" onClick={handleSearchFocus}>
                  search
                </span>
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-4"></div>
          <div className="col-lg-6  d-flex justify-content-evenly col-md-6"></div>
        </div>
        {updateChipData.length > 0 ? (
          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {updateChipData.map((data, index) => {
                  // console.log(data, " - data - ", index);
                  let icon;
                  let key = "" + data.name;
                  return (
                    <ListItem key={data}>
                      <Chip
                        icon={icon}
                        label={key}
                        onDelete={handleDelete(data, index)}
                      />
                    </ListItem>
                  );
                })}

                <ListItem>
                  {/* <Chip
                      label=" Clear All Filters"
                      onClick={handleAllClear}
                    /> */}
                  <a className="allclear" href="#" onClick={handleAllClear}>
                    Clear All Filters
                  </a>
                </ListItem>
              </Paper>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12 table-responsive p-0">
            <table className="table table-striped  table-hover" id="example">
              <thead>
                <tr>
                  <th id="th" style={{ width: "5%" }}>
                    {" "}
                    <span className="text_th">S.No</span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "serialNumber")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "name")}
                    >
                      Purchase ID
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "name")}
                    >
                      {" "}
                      import_export{" "}
                    </span>
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "category")}
                    >
                      Book Name
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "category")}
                    >
                      {" "}
                      import_export{" "}
                    </span>{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleFilter(e, "category")}
                    >
                      filter_list
                    </span>
                    {isOpen.category === true && (
                      <div className="filterBoxs p-1">
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="UPSC"
                                value="UPSC"
                                id="UPSC"
                                checked={typeOn.some((element) => {
                                  if (element.name === "UPSC") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "UPSC", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                UPSC
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="TNPSC"
                                value="TNPSC"
                                id="TNPSC"
                                checked={typeOn.some((element) => {
                                  if (element.name === "TNPSC") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "TNPSC", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                TNPSC
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="RRB"
                                value="RRB"
                                id="RRB"
                                checked={typeOn.some((element) => {
                                  if (element.name === "RRB") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "RRB", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                RRB
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="TET"
                                value="TET"
                                id="TET"
                                checked={typeOn.some((element) => {
                                  if (element.name === "TET") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "TET", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                TET
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="TRB"
                                value="TRB"
                                id="TRB"
                                checked={typeOn.some((element) => {
                                  if (element.name === "TRB") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "TRB", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                TRB
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="TNUSRB"
                                value="TNUSRB"
                                id="TNUSRB"
                                checked={typeOn.some((element) => {
                                  if (element.name === "TNUSRB") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "TNUSRB", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                TNUSRB
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="SSC"
                                value="SSC"
                                id="SSC"
                                checked={typeOn.some((element) => {
                                  if (element.name === "SSC") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "SSC", "type")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                SSC
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Banking"
                                value="Banking"
                                checked={typeOn.some((element) => {
                                  if (element.name === "Banking") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Banking", "type")
                                }
                                id="Banking"
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                Banking
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("type")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("type")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>

                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "category_class")}
                    >
                      Category
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "category_class")}
                    >
                      import_export
                    </span>
                    {/* <span class="material-icons align-middle" onClick={(e)=>handleFilter(e,"course")}>
                    filter_list
                  </span>
                  {isOpen.course === true && (
                <div className="filterBoxs p-1">
                  <div className="row mt-4">
                    <div className="col-md-12">
                    <div class="form-check p-1">
                            <label
                              htmlFor="course"
                              className="d-flex form-label"
                            >
                               select Course
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            <select   class="FilterInputs"   id="course" name="course"  >
                        <option>Please select</option>
                      </select>
                           
                          </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <span
                        className="clearFilter ms-2 "
                        style={{ cursor: "pointer" }}
                      >
                        Clear
                      </span>
                    </div>
                    <div className="col-md-8  col-8 col-sm-8">
                      <button
                        className="FilterButton"
                      >
                        Apply Filter
                      </button>
                    </div>
                  </div>
                </div>
                 )} */}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "class_mode")}
                    >
                      Purchase Mode
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "class_mode")}
                    >
                      import_export
                    </span>{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleFilter(e, "classMode")}
                    >
                      filter_list
                    </span>
                    {isOpen.classMode === true && (
                      <div className="filterBoxs p-1">
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Online"
                                value="1"
                                id="Online"
                                checked={classModes.some((element) => {
                                  if (element.name === "Online") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Online")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                Online
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="Classroom"
                                value="0"
                                id="Classroom"
                                checked={classModes.some((element) => {
                                  if (element.name === "Classroom") {
                                    return true;
                                  }
                                  return false;
                                })}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "Classroom")
                                }
                              />
                              <label
                                class="form-check-label "
                                for="defaultCheck1"
                              >
                                Classroom
                              </label>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("classMode")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("classMode")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "started_dt")}
                    >
                      Purchased On
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "started_dt")}
                    >
                      import_export
                    </span>{" "}
                    <span
                      onClick={(e) => handleFilter(e, "startDate")}
                      class="material-icons align-middle"
                    >
                      filter_list
                    </span>
                    {isOpen.startDate === true && (
                      <div className="filterBoxOne container p-1">
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="FDate"
                                className="d-flex form-label"
                              >
                                From Date
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              {/* <input
                                id="FDate"
                                type="date"
                                name="FDate"
                                value={appData.FDate}
                                className="FilterInputs"
                                onChange={handleAppliedOn}
                                placeholder="FDate"
                                autoComplete="FDate"
                                required
                              /> */}
                              <DatePickerSample
                                IconInput={"filterIocnBoxInput"}
                                class={"FilterInputs"}
                                name={"FDate"}
                                handleChange={handleChangeDate}
                                fieldInput={appData.FDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div class="form-check p-1">
                              <label
                                htmlFor="TDate "
                                className="d-flex form-label"
                              >
                                To Date
                                <span
                                  data-required="true"
                                  aria-hidden="true"
                                ></span>
                              </label>
                              {/* <input
                                id="TDate"
                                type="date"
                                name="TDate"
                                value={appData.TDate}
                                className="FilterInputs"
                                placeholder="TDate"
                                onChange={handleAppliedOn}
                                autoComplete="TDate"
                                required
                              /> */}
                              <DatePickerSample
                                class={"FilterInputs"}
                                IconInput={"filterIocnBoxInput"}
                                name={"TDate"}
                                handleChange={handleChangeDate}
                                fieldInput={appData.TDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                id="Today"
                                value="Today"
                                checked={appData.Days === "Today"}
                                onChange={handleAppliedOn}
                              />
                              <span class="form-check-label d-flex" for="Today">
                                Today
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Yesterday"
                                checked={appData.Days === "Yesterday"}
                                id="Yesterday"
                                onChange={handleAppliedOn}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Yesterday"
                              >
                                Yesterday
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Tweek"
                                checked={appData.Days === "Tweek"}
                                id="Tweek"
                                onChange={handleAppliedOn}
                              />
                              <span class="form-check-label d-flex" for="Tweek">
                                This week
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Lweek"
                                checked={appData.Days === "Lweek"}
                                id="Lweek"
                                onChange={handleAppliedOn}
                              />
                              <span class="form-check-label d-flex" for="Lweek">
                                Last week
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                value="Tmonth"
                                checked={appData.Days === "Tmonth"}
                                id="Tmonth"
                                onChange={handleAppliedOn}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Tmonth"
                              >
                                This month
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="Days"
                                checked={appData.Days === "Lmonth"}
                                value="Lmonth"
                                id="Lmonth"
                                onChange={handleAppliedOn}
                              />
                              <span
                                class="form-check-label d-flex"
                                for="Lmonth"
                              >
                                Last month
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clearFilter ms-2 "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("appliedOn")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8  col-8 col-sm-8">
                            <button
                              className="FilterButton"
                              onClick={() => handleApplyFilter("appliedOn")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>

                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "Status")}
                    >
                      Price
                    </span>
                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "Status")}
                    >
                      import_export
                    </span>{" "}
                  </th>
                  <th id="th">
                    {" "}
                    <span className="text_th">Action</span>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentData.length === 0 ? (
                  <tr>
                    <td colspan="10" style={{ textAlign: "center" }}>
                      No record to display
                    </td>
                  </tr>
                ) : (
                  currentData.map((course, index) => (
                    <tr key={index} title={course.id}>
                      <td id="td">{course.serialNumber}</td>
                      <td id="td">{course.name}</td>
                      <td id="td">{course.category}</td>
                      <td id="td"> {course.category_class}</td>
                      <td id="td">{course.class_mode}</td>
                      <td id="td">{course.medium}</td>
                      <td id="td">{course.batch_type}</td>
                      <td id="td">
                        {moment(course.started_dt).format("YYYY-MM-DD")}
                      </td>
                      <td id="td">
                        {course.start_time} - {course.end_time}
                      </td>
                      {/* <td><img src={`${ImageUrlPrefix}/${course.img_url}`} class="rounded-circle" id="img" alt="avatar1" /></td> */}
                      {course.status === 0 && (
                        <td id="td">
                          <span className="PinkText">Upcoming</span>
                        </td>
                      )}
                      {course.status === 1 && (
                        <td id="td">
                          <span className="GreenTexts">Ongoing</span>
                        </td>
                      )}
                      {course.status === 2 && (
                        <td id="td">
                          <span className="GreyTexts">Completed</span>
                        </td>
                      )}

                      <td id="td">
                        <button
                          className="button"
                          onClick={() => handleReadView(course.id)}
                        >
                          <span class="material-icons">visibility</span>
                        </button>
                        <button
                          className="button me-2"
                          onClick={() => handleEditView(course.id)}
                        >
                          <span class="material-icons">edit</span>
                        </button>{" "}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12">
            <div className="pag w-100 dflexcenter">
              <span className="ipage">Items Per Page</span>
              <select
                name="pageCount"
                id="pageCount"
                defaultValue={rowsPerPage}
                onChange={handlePageCount}
                className="ms-2"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pagenumber ms-2">
                {" "}
                {updateListData.length > 0
                  ? currentPage * rowsPerPage + 1 - rowsPerPage
                  : 0}{" "}
                -
                {updateListData.length < currentPage * rowsPerPage
                  ? updateListData.length
                  : currentPage * rowsPerPage}{" "}
                of {updateListData.length}
              </span>
              <button
                className="prev_button ms-2 me-2"
                onClick={(e) => handlePrev(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span class="material-icons">chevron_left</span>
              </button>
              <button
                className="prev_button"
                onClick={() => handleNext(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <span class="material-icons">chevron_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookPurchase;
