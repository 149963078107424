import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddToCardTemplate from "./AddToCardTemplate";
import "./CardTemplate1.css";

const CardTemplate1 = (props) => {
  const [cartlist, setCartlist] = useState([]);

  useEffect(() => {
    const localStorageValue = sessionStorage.getItem("cart") || "";
    if (localStorageValue) {
      setCartlist(JSON.parse(localStorageValue));
    }
  }, []);

  function handleproductid(data) {
    // console.log(data,`Button ${data.id} was clicked.`);
    const existingcartData = sessionStorage.getItem("cart");
    if (existingcartData === null) {
      const datas = { ...data, quantity: "1" };
      const updateCart = [...cartlist, datas];
      sessionStorage.setItem("cart", JSON.stringify(updateCart));
    } else {
      const existingcartData = JSON.parse(sessionStorage.getItem("cart")) || {};
      const filteredItems = existingcartData.filter(
        (item) => item.id === data.id
      );

      if (filteredItems.length == 0) {
        const datas = { ...data, quantity: Number(1) };
        const updateCart = [...cartlist, datas];
        sessionStorage.setItem("cart", JSON.stringify(updateCart));
        setCartlist(updateCart);
      }
    }
    window.location.reload();
  }

  function productdetail(id) {
    window.location.href = `/products/${id}`;
  }

  // const originalAmount = (price) => {
  //   return price;
  // };

  // const discountAmount = (price,discount) => {
  //   const original = originalAmount(price);
  //   return original * (discount / 100);
  // };

  // // const discountedPrice = props.price - (discountAmount(props.price, props.discount));
  // // console.log((typeof discountedPrice));

  // const products = JSON.parse(sessionStorage.getItem("products"));
  // const updatedCart = products.map((props) => {
  //   const discountedPrice = props.price - discountAmount(props.price, props.discount);
  //   return {
  //     ...props,
  //     discountedPrice
  //   };
  // });
  // sessionStorage.setItem("products", JSON.stringify(updatedCart));

  // console.log(props);
  return (
    <>
      <div
        className="col-4 m-3  p-2"
        style={{ width: "19rem", height: "28rem" }}
      >
        {/* <div className='card-body'> */}
        {/* <div className="label-top shadow-sm"  id='label-top'>
            <span className='text-center text-capitalize mb-2'>Sale !</span>
                </div> */}
        <div className="card shadow">
          <img
            src={props.images}
            height={250}
            width={286}
            onClick={() => productdetail(props.id)}
          />
          <div className="card-footer border-top border-gray-300 p-3">
            <h6 className="text-start">{props.name}</h6>
            <h3 className="h6 fw-light text-gray text-start">{props.title} </h3>

            {/* <div className="d-flex mt-3">
                    <span className="star fas fa-star text-warning me-1"></span> 
                    <span className="star fas fa-star text-warning me-1"></span> 
                    <span className="star fas fa-star text-warning me-1"></span> 
                    <span className="star fas fa-star text-warning me-1"></span> 
                    <span className="star fas fa-star text-warning"></span> 
                    <span className="badge bg-primary ms-2">4.7</span>
                </div> */}
            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="mb-0 mx-1 text-gray text-decoration-line-through">
                ₹{props.price.toFixed(2)}
              </span>
              <span className="h5 mb-0 me-5 text-gray">
                ₹{props.discountedPrice.toFixed(2)}
              </span>

              <span className="mb-0 text-gray text-end text-danger">
                {props.discount}% off
              </span>
            </div>
            <div className="align-items-center mt-3">
              <button
                className="text-center btn btn-danger"
                onClick={() => handleproductid(props)}
              >
                Add to cart
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default CardTemplate1;
