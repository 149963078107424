import styled from "styled-components";
import { MEDIA_SIZE_XL } from "../utils/helper";

const Title = styled.h4`
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--color-black);
  @media (min-width: ${MEDIA_SIZE_XL}) {
    font-size: 20px;
  }
`;

export default Title;
