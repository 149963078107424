import "./AdmissionForm.css";
import Razorpay from "react-razorpay";
import React, { useState, useEffect, useRef } from "react";
import {
  Alarm,
  CurrencyRupee,
  LaptopChromebook,
  Today,
} from "@mui/icons-material";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { AxiosInstance } from "../axios";
import { GSTPRICE, ImageUrlPrefix, PaymentSKey } from "../labels";
import moment from "moment";
import Swal from "sweetalert2";
// import { toWords } from "number-to-words";
import { ToWords } from "to-words";
import { Dialog, DialogContent } from "@mui/material";
import Loader from "../shared/Component/Loader";

function PayFee() {
  const location = useLocation();

  const navigate = useNavigate();
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const { CourseDetail, type_label } = location.state;
  const txtAdPPayment = useRef(null);

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("UserId Not available");
  }

  const [dis, setDis] = useState(null);

  const [admissionDetails, setAdmissionDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [listErrors, setListErrors] = useState({});
  const [batchDetails, setBatchDetails] = useState([]);
  const [courseBatchDetails, setCourseBatchDetails] = useState([]);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [offSpl, setOffSpl] = useState(0);
  const [offSplDisplayType, setOffSplDisplayType] = useState(0);
  useEffect(() => {
    if (CourseDetail.type != "4") {
      if (type_label === 0) {
        AxiosInstance.post("api/course/admission/getOrderby/rpay", {
          admission_id: CourseDetail.adm_pk,
        })
          .then((res_mk) => {
            if (res_mk.data.status === true) {
              let lastIndexCaptured = {};
              const lastIndexCaptureds =
                res_mk.data.data.items.length > 0 &&
                res_mk.data.data.items
                  .filter((el, inex) => el.status === "captured")
                  .map((el) => el);
              if (lastIndexCaptureds.length > 0) {
                lastIndexCaptured = lastIndexCaptureds[0];
              } else {
                lastIndexCaptured =
                  res_mk.data.data.items[res_mk.data.data.items.length - 1];
              }

              let payment_status_info_ko = "";
              if (lastIndexCaptured.status === "failed") {
                payment_status_info_ko = 0;
              } else if (lastIndexCaptured.status === "created") {
                payment_status_info_ko = 1;
              } else if (lastIndexCaptured.status === "authenticated") {
                payment_status_info_ko = 2;
              } else if (lastIndexCaptured.status === "authorized") {
                payment_status_info_ko = 3;
              } else if (lastIndexCaptured.status === "captured") {
                payment_status_info_ko = 4;
              } else if (lastIndexCaptured.status === "refunded") {
                payment_status_info_ko = 5;
              }
              const lastIndexreqdata = res_mk.data.basic_info;
              if (payment_status_info_ko === 4) {
                const dats = {
                  id: lastIndexreqdata.admission_history_id
                    ? lastIndexreqdata.admission_history_id
                    : "",
                  step: 7,
                  location_id: lastIndexreqdata.location
                    ? lastIndexreqdata.location
                    : "",
                  category: lastIndexreqdata.category_code
                    ? lastIndexreqdata.category_code
                    : "",
                  subject: lastIndexreqdata.subject
                    ? lastIndexreqdata.subject
                    : [], //send  if subject having only
                  type: CourseDetail.type,
                  Isverify: lastIndexreqdata.Isverify
                    ? lastIndexreqdata.Isverify
                    : true,
                  AdmissionID: CourseDetail.adm_pk,
                  CouresName: lastIndexreqdata.course_name
                    ? lastIndexreqdata.course_name
                    : "",
                  discountAmount: lastIndexreqdata.discount_amt,
                  gst: lastIndexreqdata.gst_percent,
                  SpecialOffer: lastIndexreqdata.special_offer,
                  spc_offer_type: lastIndexreqdata.spc_offer_type,
                  batch_id: lastIndexreqdata.batch_id
                    ? lastIndexreqdata.batch_id
                    : "",
                  coures_id: lastIndexreqdata.course_id
                    ? lastIndexreqdata.course_id
                    : "",
                  user_id: lastIndexreqdata.user_id
                    ? lastIndexreqdata.user_id
                    : "",
                  old_stud_disc_perc: lastIndexreqdata.old_stud_disc_perc,
                  old_stud_disc: lastIndexreqdata.old_stud_disc,
                  iscoupon: lastIndexreqdata.is_coupon,
                  couponAmount: lastIndexreqdata.coupon_amount,
                  couponcode: lastIndexreqdata.coupon_code,
                  Netamount: lastIndexCaptured.amount
                    ? lastIndexCaptured.amount / 100
                    : "",
                  status: payment_status_info_ko,
                  transaction_id: lastIndexCaptured.id
                    ? lastIndexCaptured.id
                    : "",
                  razorpay_order_id: lastIndexCaptured.order_id
                    ? lastIndexCaptured.order_id
                    : "",
                  razorpay_signature: "",
                  created_at: moment
                    .unix(lastIndexCaptured.created_at)
                    .format("YYYY-MM-DD HH:mm:ss"),
                  partial_payment: lastIndexreqdata.partial_payment,
                  installment_type:
                    lastIndexreqdata.partial_payment === "1" ? [1] : [1, 2, 3],
                  old_stud_disc_applied: lastIndexreqdata.old_stud_disc_applied,
                  tot_amt: lastIndexreqdata.tot_amt,
                  gst_amt: lastIndexreqdata.gst_amt,
                  payment_type: 1,
                  created_by: userid,
                  updated_by: userid,
                };
                AxiosInstance.post(`api/course/admission/register`, dats)
                  .then((RegsisteRes) => {
                    if (RegsisteRes.data.status === true) {
                      Swal.fire({
                        title: "success",
                        text: "Payment Already Captured",
                        icon: "success",
                        confirmButtonText: "Ok",
                      });
                      navigate("/TransactionHistory/2");
                    } else {
                      PurchaseInfoView(
                        userid,
                        CourseDetail.adm_pk,
                        CourseDetail.type,
                        type_label
                      );
                    }
                  })
                  .catch((error) => {
                    return error;
                  });
              } else {
                PurchaseInfoView(
                  userid,
                  CourseDetail.adm_pk,
                  CourseDetail.type,
                  type_label
                );
              }
            } else {
              PurchaseInfoView(
                userid,
                CourseDetail.adm_pk,
                CourseDetail.type,
                type_label
              );
            }
          })
          .catch((error) => {
            return error;
          });
      } else {
        PurchaseInfoView(
          userid,
          CourseDetail.adm_pk,
          CourseDetail.type,
          type_label
        );
      }
    } else {
      if (type_label === 0) {
        AxiosInstance.post("api/course/admission/getOrderby/rpay", {
          admission_id: CourseDetail.adm_pk,
        })
          .then((res_mk) => {
            if (res_mk.data.status === true) {
              let lastIndexCaptured = {};
              const lastIndexCaptureds =
                res_mk.data.data.items.length > 0 &&
                res_mk.data.data.items
                  .filter((el, inex) => el.status === "captured")
                  .map((el) => el);
              if (lastIndexCaptureds.length > 0) {
                lastIndexCaptured = lastIndexCaptureds[0];
              } else {
                lastIndexCaptured =
                  res_mk.data.data.items[res_mk.data.data.items.length - 1];
              }

              let payment_status_info_ko = "";
              if (lastIndexCaptured.status === "failed") {
                payment_status_info_ko = 0;
              } else if (lastIndexCaptured.status === "created") {
                payment_status_info_ko = 1;
              } else if (lastIndexCaptured.status === "authenticated") {
                payment_status_info_ko = 2;
              } else if (lastIndexCaptured.status === "authorized") {
                payment_status_info_ko = 3;
              } else if (lastIndexCaptured.status === "captured") {
                payment_status_info_ko = 4;
              } else if (lastIndexCaptured.status === "refunded") {
                payment_status_info_ko = 5;
              }

              const lastIndexreqdata = res_mk.data.basic_info;
              if (payment_status_info_ko === 4) {
                const dats = {
                  id: lastIndexreqdata.admission_history_id
                    ? lastIndexreqdata.admission_history_id
                    : "",
                  step: 7,
                  location_id: lastIndexreqdata.location
                    ? lastIndexreqdata.location
                    : "",
                  category: lastIndexreqdata.category_code
                    ? lastIndexreqdata.category_code
                    : "",
                  subject: lastIndexreqdata.subject
                    ? lastIndexreqdata.subject
                    : [], //send  if subject having only
                  type: 4,
                  Isverify: lastIndexreqdata.Isverify
                    ? lastIndexreqdata.Isverify
                    : true,
                  AdmissionID: CourseDetail.adm_pk,
                  CouresName: lastIndexreqdata.course_name
                    ? lastIndexreqdata.course_name
                    : "",
                  discountAmount: lastIndexreqdata.discount_amt,
                  gst: lastIndexreqdata.gst_percent,
                  SpecialOffer: lastIndexreqdata.special_offer,

                  spc_offer_type: lastIndexreqdata.spc_offer_type,
                  batch_id: lastIndexreqdata.batch_id
                    ? lastIndexreqdata.batch_id
                    : "",
                  coures_id: lastIndexreqdata.course_id
                    ? lastIndexreqdata.course_id
                    : "",
                  user_id: lastIndexreqdata.user_id
                    ? lastIndexreqdata.user_id
                    : "",
                  old_stud_disc_perc: lastIndexreqdata.old_stud_disc_perc,
                  old_stud_disc: lastIndexreqdata.old_stud_disc,
                  iscoupon: lastIndexreqdata.is_coupon,
                  couponAmount: lastIndexreqdata.coupon_amount,
                  couponcode: lastIndexreqdata.coupon_code,
                  Netamount: lastIndexCaptured.amount
                    ? lastIndexCaptured.amount / 100
                    : "",
                  status: payment_status_info_ko,
                  transaction_id: lastIndexCaptured.id
                    ? lastIndexCaptured.id
                    : "",
                  razorpay_order_id: lastIndexCaptured.order_id
                    ? lastIndexCaptured.order_id
                    : "",
                  razorpay_signature: "",
                  created_at: moment
                    .unix(lastIndexCaptured.created_at)
                    .format("YYYY-MM-DD HH:mm:ss"),
                  partial_payment: 0,
                  old_stud_disc_applied: 0,
                  tot_amt: lastIndexCaptured.amount
                    ? lastIndexCaptured.amount / 100
                    : "",
                  gst_amt: lastIndexreqdata.gst_amt,
                  payment_type: 1,
                  created_by: userid,
                  updated_by: userid,
                  installment_type: [1, 2, 3],
                };

                AxiosInstance.post(`api/course/admission/register`, dats)
                  .then((RegsisteRes) => {
                    if (RegsisteRes.data.status === true) {
                      Swal.fire({
                        title: "success",
                        text: "Payment Already Captured",
                        icon: "success",
                        confirmButtonText: "Ok",
                      });
                      navigate("/TransactionHistory/2");
                    } else {
                      ModelInfoView(
                        userid,
                        CourseDetail.adm_pk,
                        CourseDetail.type
                      );
                    }
                  })
                  .catch((error) => {
                    return error;
                  });
              } else {
                ModelInfoView(userid, CourseDetail.adm_pk, CourseDetail.type);
              }
            } else {
              ModelInfoView(userid, CourseDetail.adm_pk, CourseDetail.type);
            }
          })
          .catch((error) => {
            return error;
          });
      } else {
        ModelInfoView(userid, CourseDetail.adm_pk, CourseDetail.type);
      }
    }
  }, []);

  const PurchaseInfoView = (useridss, admpk, typee, labeltype) => {
    setIsLoading(true);
    AxiosInstance.post("api/purchase/view", {
      user_id: useridss,
      admission_id: admpk,
      type: typee,
      user_type: labeltype,
    })
      .then((res) => {
        const userinfo = res.data.data;
        const PersonDetails = {
          FirstName: userinfo.first_name,
          LastName: userinfo.last_name,
          mobile: userinfo.phone_no,
          email: userinfo.email,
          Profilephoto:
            userinfo.profile_path !== null ? userinfo.profile_path : null,
        };

        const PartialPayment =
          userinfo.Admission_details.length > 0 && userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].partial_payment !== null
              ? userinfo.Admission_details[0].partial_payment === 1
                ? "1"
                : "0"
              : "0"
            : userinfo.Admission_details &&
              userinfo.Admission_details.partial_payment !== null
            ? userinfo.Admission_details.partial_payment === 1
              ? "1"
              : "0"
            : "0";

        const student_id =
          userinfo.Admission_details.length > 0 && userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].student_id !== null
              ? userinfo.Admission_details[0].student_id
              : null
            : userinfo.Admission_details &&
              userinfo.Admission_details.student_id !== null
            ? userinfo.Admission_details[0].student_id
            : null;

        PersonDetails.PPayment = PartialPayment;
        PersonDetails.student_id = student_id;

        setList(PersonDetails);

        const admissiondetails =
          userinfo.Admission_details.length > 0
            ? userinfo.Admission_details[0]
            : {};

        setAdmissionDetails(admissiondetails);

        setCourseBatchDetails(userinfo.Courses);

        userinfo.Courses.batch_details !== null
          ? getDisocount(userinfo.Courses.batch_details.batch_id)
          : getDisocount(null);

        const batchDe =
          userinfo.Courses.batch_details !== null
            ? {
                batch_id: userinfo.Courses.batch_details.batch_id,
                id: userinfo.Courses.batch_details.batch_id,
                img_url: userinfo.Courses.batch_details.img_url,
                course_name: userinfo.Courses.batch_details.course_name,
                batch_name: userinfo.Courses.batch_details.batch_name,
                course_id: userinfo.Courses.batch_details.course_id,
                batch_details: userinfo.Courses.batch_details.batch_details,
                class_mode: userinfo.Courses.batch_details.class_mode,
                start_on: userinfo.Courses.batch_details.start_on
                  ? dobCon(userinfo.Courses.batch_details.start_on)
                  : "",
                duration: userinfo.Courses.batch_details.duration,
                act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                offer_spl: userinfo.Courses.batch_details.offer_spl,
                dis_amt: userinfo.Courses.batch_details.dis_amt,
                medium: userinfo.Courses.batch_details.medium,
                is_selected: userinfo.Courses.batch_details.is_selected,
                exam_details:
                  userinfo.Courses.batch_details.exam_details &&
                  userinfo.Courses.batch_details.exam_details.exam_details &&
                  userinfo.Courses.batch_details.exam_details.exam_details
                    .length > 0
                    ? userinfo.Courses.batch_details.exam_details.exam_details
                    : [],
                panel_details:
                  Object.keys(userinfo.Courses.batch_details.panel_details)
                    .length > 0
                    ? userinfo.Courses.batch_details.panel_details.panel_list
                    : [],
                exam_fees:
                  Object.keys(userinfo.Courses.batch_details.exam_details)
                    .length > 0 &&
                  userinfo.Courses.batch_details.exam_details.exam_fees.length >
                    0
                    ? userinfo.Courses.batch_details.exam_details.exam_fees
                    : Object.keys(userinfo.Courses.batch_details.panel_details)
                        .length > 0
                    ? userinfo.Courses.batch_details.panel_details.exam_fees
                    : [],
              }
            : null;
        let ExamObj = [];
        batchDe.exam_details.map((el) => {
          ExamObj.push({
            pk: el.exam_detail_id,
            batch_id: el.batch_id,
            exam_title: el.exam_title,
            exam_title_id: el.exam_detail_id,
            started_dt: el.started_dt,
            batch_time: el.batch_time,
            duration_hrs: el.duration,
            syllabus_url: el.syllabus_url,
            is_selected_exam: el.is_selected_exam === 1 ? true : false,
          });
        });
        batchDe.exam_details = ExamObj;
        let PaneldetailsObj = [];
        batchDe.panel_details.map((el) => {
          PaneldetailsObj.push({
            pk: el.panel_pk,
            batch_id: el.batch_id,
            batch_type: el.batch_type,
            panel_name: el.panel_name,
            available_dates: el.available_dates,
            event_time: el.event_time,
            is_selected_panel: el.is_selected_panel == 1 ? true : false,
          });
        });
        batchDe.panel_details =
          PaneldetailsObj.length > 0 ? PaneldetailsObj : {};
        setBatchDetails([batchDe]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };

  const ModelInfoView = (userid_mo, adm_mo, type_mo) => {
    setIsLoading(true);
    AxiosInstance.post("api/modelexam/view", {
      user_id: userid_mo,
      admission_id: adm_mo,
      type: type_mo,
    })
      .then((res) => {
        if (res.data.status === true) {
          const userinfo = res.data.data;
          const PersonDetails = {
            FirstName: userinfo.first_name,
            LastName: userinfo.last_name,
            father_name: userinfo.father_name,
            mobile: userinfo.phone_no,
            email: userinfo.email,
            Profilephoto:
              userinfo.profile_path !== null ? userinfo.profile_path : null,
          };

          const PartialPayment = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].partial_payment !== null
              ? userinfo.Admission_details[0].partial_payment === 1
                ? "1"
                : "0"
              : "0"
            : userinfo.Admission_details &&
              userinfo.Admission_details.partial_payment !== null
            ? userinfo.Admission_details.partial_payment === 1
              ? "1"
              : "0"
            : "0";

          const student_id = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].student_id !== null
              ? userinfo.Admission_details[0].student_id
              : null
            : userinfo.Admission_details &&
              userinfo.Admission_details.student_id !== null
            ? userinfo.Admission_details[0].student_id
            : null;

          const applied_on_dty = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].applied_on !== null
              ? userinfo.Admission_details[0].applied_on
              : null
            : userinfo.Admission_details &&
              userinfo.Admission_details.applied_on !== null
            ? userinfo.Admission_details[0].applied_on
            : null;

          const coupon_amount_jk =
            userinfo.Admission_details[0] &&
            userinfo.Admission_details[0].is_coupon !== 0
              ? userinfo.Admission_details[0] &&
                userinfo.Admission_details[0].coupon_amount !== null
                ? userinfo.Admission_details[0].coupon_amount
                : null
              : userinfo.Admission_details &&
                userinfo.Admission_details.coupon_amount !== null
              ? userinfo.Admission_details[0].coupon_amount
              : null;

          const special_offer_amount = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].special_offer !== null
              ? userinfo.Admission_details[0].special_offer
              : null
            : userinfo.Admission_details &&
              userinfo.Admission_details.special_offer !== null
            ? userinfo.Admission_details[0].special_offer
            : null;

          const old_Student_discount_Amt = userinfo.Admission_details[0]
            ? userinfo.Admission_details[0] &&
              userinfo.Admission_details[0].old_stud_disc !== null
              ? userinfo.Admission_details[0].old_stud_disc
              : null
            : userinfo.Admission_details &&
              userinfo.Admission_details.old_stud_disc !== null
            ? userinfo.Admission_details[0].old_stud_disc
            : null;

          PersonDetails.PPayment = PartialPayment;
          PersonDetails.student_id = student_id;
          PersonDetails.applied_on = applied_on_dty;
          PersonDetails.coupon_amount = coupon_amount_jk;
          PersonDetails.special_offer = special_offer_amount;
          PersonDetails.old_stud_disc = old_Student_discount_Amt;

          setList(PersonDetails);

          const admissiondetails =
            userinfo.Admission_details.length > 0
              ? userinfo.Admission_details[0]
              : {};

          setAdmissionDetails(admissiondetails);
          userinfo.Courses.batch_details !== null
            ? getSpeciOfferDis(userinfo.Courses.batch_details.batch_id)
            : getSpeciOfferDis(null);
          setCourseBatchDetails(userinfo.Courses);
          const batchDe =
            userinfo.Courses.batch_details !== null
              ? {
                  batch_id: userinfo.Courses.batch_details.batch_id,
                  id: userinfo.Courses.batch_details.batch_id,
                  paper_type: userinfo.Courses.batch_details.paper_type,
                  img_url: userinfo.Courses.batch_details.img_url,
                  course_name: userinfo.Courses.batch_details.course_name,
                  batch_name: userinfo.Courses.batch_details.name,
                  course_id: userinfo.Courses.batch_details.course_id,
                  batch_details: userinfo.Courses.batch_details.batch_details,
                  class_mode: userinfo.Courses.batch_details.class_mode,
                  start_on: userinfo.Courses.batch_details.start_on
                    ? dobCon(userinfo.Courses.batch_details.start_on)
                    : "",
                  duration: convertSecondsToTime(
                    userinfo.Courses.batch_details.duration
                  ),
                  act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                  offer_spl: userinfo.Courses.batch_details.offer_spl,
                  dis_amt: userinfo.Courses.batch_details.dis_amt,
                  medium: userinfo.Courses.batch_details.batch_medium,
                  is_selected: userinfo.Courses.batch_details.is_selected,
                  tot_old_stud_amt:
                    userinfo.Courses.batch_details.tot_old_stud_amt,
                  old_stud_disc_applied:
                    userinfo.Courses.batch_details.old_stud_disc_applied,
                  exam_details:
                    userinfo.Courses.batch_details.exam_details &&
                    userinfo.Courses.batch_details.exam_details.exam_details &&
                    userinfo.Courses.batch_details.exam_details.exam_details
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_details
                      : [],
                  // panel_details:
                  //   Object.keys(userinfo.Courses.batch_details.panel_details)
                  //     .length > 0
                  //     ? userinfo.Courses.batch_details.panel_details.panel_list
                  //     : [],
                  exam_fees:
                    Object.keys(userinfo.Courses.batch_details.exam_details)
                      .length > 0 &&
                    userinfo.Courses.batch_details.exam_details.exam_fees
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_fees
                      : Object.keys(
                          userinfo.Courses.batch_details.panel_details
                        ).length > 0
                      ? userinfo.Courses.batch_details.panel_details.exam_fees
                      : [],
                }
              : null;
          let ExamObj = [];
          batchDe.exam_details.map((el) => {
            ExamObj.push({
              pk: el.exam_detail_id,
              batch_id: el.batch_id,
              exam_title: el.exam_title,
              exam_title_id: el.exam_detail_id,
              started_dt: el.started_dt,
              batch_time: el.batch_time,
              duration_hrs: el.duration,
              syllabus_url: el.syllabus_url,
              is_selected_exam: el.is_selected_exam === 1 ? true : false,
              syllabus_url: el.syllabus_url,
              last_dt_apply: el.last_dt_apply,
              no_of_paper: el.no_of_paper,
              papers: el.papers.length > 0 ? el.papers : [],
            });
          });
          batchDe.exam_details = ExamObj;
          // let PaneldetailsObj = [];
          // batchDe.panel_details.map((el) => {
          //   PaneldetailsObj.push({
          //     pk: el.panel_pk,
          //     batch_id: el.batch_id,
          //     batch_type: el.batch_type,
          //     panel_name: el.panel_name,
          //     available_dates: el.available_dates,
          //     event_time: el.event_time,
          //     is_selected_panel: el.is_selected_panel == 1 ? true : false,
          //   });
          // });
          // batchDe.panel_details =
          //   PaneldetailsObj.length > 0 ? PaneldetailsObj : {};
          setBatchDetails([batchDe]);
          setIsLoading(false);
        } else {
          Swal.fire({
            title: "Warning",
            text: res.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
          setIsLoading(false);
          navigateToPreviousScreen();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };

  const [list, setList] = useState({
    PPayment: "",
    FirstName: "",
    LastName: "",
    email: "",
    mobile: "",
    Profilephoto: null,
    student_id: null,
  });

  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const validateFieldsPayments = (data) => {
    // console.log(data, "data");
    const errors = {};
    if (!data.PPayment) {
      errors.PPayment = "please select Payment Type";
    }
    validateFieldsPaymentF(data);
    return errors;
  };

  const validateFieldsPaymentF = (data) => {
    if (!data.PPayment) {
      txtAdPPayment.current.focus();
    }
  };

  const handleChange = (event) => {
    const { name, value, files, checked } = event.target;
    if (checked) {
      setList((prevList) => ({
        ...prevList,
        [name]: value,
      }));
      setListErrors({
        ...listErrors,
        ["PPayment"]: "",
      });
    } else {
      setList((prevList) => ({
        ...prevList,
        [name]: "",
      }));
    }
  };

  const getDisocount = (batchId) => {
    const df = {
      user_id: userid,
      batch_id: batchId,
    };
    AxiosInstance.post("api/all/getOldStudDisc", df)
      .then((res) => {
        // console.log(res, "discount batch");
        if (res.data.status === true) {
          let discount = res.data.old_stud_disc;
          // console.log(discount, "discount");
          setDis(discount);
        } else {
          setDis(null); // or set a default value for no discount
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSpeciOfferDis = (batchId) => {
    const df = {
      user_id: userid,
      type: 4,
      batch_id: batchId,
    };

    // discount_type → 0:stud_enrolled 1:ongoing_batch 2:n_num_stud 3:none
    AxiosInstance.post("api/all/getSpcOfferDisc", df)
      .then((res) => {
        if (res.data.status === true) {
          let SpeciaOffer = res.data.data;
          setOffSpl(SpeciaOffer.offer_perc);
          // 0:stud_enrolled 1:ongoing_batch 2:n_num_stud 3:none
          let speType = 0;
          if (SpeciaOffer.discount_type === 0) {
            speType = 4;
          } else if (SpeciaOffer.discount_type === 1) {
            speType = 1;
          } else if (SpeciaOffer.discount_type === 2) {
            speType = 2;
          } else if (SpeciaOffer.discount_type === 3) {
            speType = 3;
          }
          // 4:stud_enrolled 1:ongoing_batch 2:n_num_stud 3: none 0:Nil

          setOffSplDisplayType(speType);
          // setOffSpl(2); // or set a default value for no discount
        } else {
          setOffSpl(0);
          setOffSplDisplayType(0);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSPayfee = (details, elements, Payamount) => {
    const errorss = validateFieldsPayments(list);
    if (Object.keys(errorss).length === 0) {
      const courseAmo = Payamount;
      const receipt = "Order_" + Math.floor(Math.random() * 900 + 100);
      if (courseAmo > 0) {
        AxiosInstance.post("api/payemnt/create_order", {
          amount: courseAmo * 100,
          currency: "INR",
          partial_payment: true,
          receipt: receipt,
        })
          .then((res) => {
            if (res.data.status) {
              const { data } = res;
              const stepData = {
                id: "",
                type: details.type,
                user_id: userid,
                admission_history_id: details.adm_his_id,
                entity: "",
                amount: data.amount / 100,
                currency: "INR",
                status: 1,
                order_id: data.id,
                invoice_id: "",
                international: "",
                method: "",
                amount_refunded: 0,
                refund_status: "",
                captured: "",
                description: "",
                card_id: "",
                bank: "",
                wallet: "",
                vpa: "",
                email: list.email,
                contact: list.mobile,
                notes: "",
                fee: 0,
                tax: 0,
                error_code: "",
                error_description: "",
                error_source: "",
                error_step: "",
                error_reason: "",
                acquirer_data: {
                  rrn: "",
                  upi_transaction_id: "",
                },
                created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                created_by: userid,
                updated_by: userid,
                payment_type: 1, //1 → online ,0 → offline // we use online now
                installment_type:
                  list.PPayment === "1"
                    ? details.instment_pay[0].payment_status !== "1"
                      ? [2]
                      : [3]
                    : [2, 3],
              };
              AxiosInstance.post(
                "api/course/admission/create_order_partialpayment",
                stepData
              )
                .then((resss) => {
                  setIsPaymentLoading(true);
                  if (resss.data.status === true) {
                    setIsPaymentLoading(false);
                    const options = {
                      key: PaymentSKey,
                      amount: resss.data.data.amount,
                      currency: "INR",
                      name: details.batch_name,
                      prefill: {
                        name:
                          list.FirstName !== null && list.FirstName !== ""
                            ? list.FirstName
                            : "Anna iasa",
                        email:
                          list.email !== null && list.email !== ""
                            ? list.email
                            : "info@annaiasacademy.com",
                        contact:
                          list.mobile !== null && list.mobile !== ""
                            ? list.mobile
                            : "1234567890",
                      },
                      description: "Admission Pending Fees",
                      image: "B",
                      order_id: resss.data.data.order_id,
                      handler: function (response) {
                        if (response) {
                          setIsPaymentLoading(true);
                          AxiosInstance.get(
                            `api/payments/${response.razorpay_payment_id}`
                          )
                            .then((res) => {
                              if (res.status === 200) {
                                const payInfo = res.data;
                                let payment_status_info = "";
                                if (payInfo.status === "failed") {
                                  payment_status_info = 0;
                                } else if (payInfo.status === "created") {
                                  payment_status_info = 1;
                                } else if (payInfo.status === "authenticated") {
                                  payment_status_info = 2;
                                } else if (payInfo.status === "authorized") {
                                  payment_status_info = 3;
                                } else if (payInfo.status === "captured") {
                                  payment_status_info = 4;
                                } else if (payInfo.status === "refunded") {
                                  payment_status_info = 5;
                                }

                                const paymentHistory = {
                                  id: payInfo.id,
                                  admission_history_id: details.adm_his_id,
                                  entity: payInfo.entity,
                                  amount: payInfo.amount / 100,
                                  currency: payInfo.currency,
                                  status: payment_status_info,
                                  order_id: payInfo.order_id,
                                  invoice_id: payInfo.invoice_id,
                                  international: payInfo.international,
                                  method: payInfo.method,
                                  amount_refunded: payInfo.amount_refunded,
                                  refund_status: payInfo.refund_status,
                                  captured: payInfo.captured,
                                  description: payInfo.description,
                                  card_id: payInfo.card_id,
                                  bank: payInfo.bank,
                                  wallet: payInfo.wallet,
                                  vpa: payInfo.vpa,
                                  email: payInfo.email,
                                  contact: payInfo.contact,
                                  notes:
                                    payInfo.notes.length > 0
                                      ? payInfo.notes.toString()
                                      : null,
                                  fee: payInfo.fee,
                                  tax: payInfo.tax,
                                  error_code: payInfo.error_code,
                                  error_description: payInfo.error_description,
                                  error_source: payInfo.error_source,
                                  error_step: payInfo.error_step,
                                  error_reason: payInfo.error_reason,
                                  acquirer_data: {
                                    rrn: payInfo.acquirer_data.rrn,
                                    upi_transaction_id:
                                      payInfo.acquirer_data.upi_transaction_id,
                                  },
                                  created_at: moment
                                    .unix(payInfo.created_at)
                                    .format("YYYY-MM-DD hh:mm:ss"),
                                  created_by: userid,
                                  payment_type: 1, //1 → online ,0 → offline // we use online now
                                  installment_type:
                                    list.PPayment === "1"
                                      ? details.instment_pay[0]
                                          .payment_status !== "1"
                                        ? [2]
                                        : [3]
                                      : [2, 3],
                                };

                                AxiosInstance.post(
                                  "api/course/admission/create_order_partialpayment",
                                  paymentHistory
                                )
                                  .then((res_d) => {
                                    setIsPaymentLoading(false);
                                    if (res_d.data.status === true) {
                                      navigate("/TransactionHistory");
                                    }
                                  })
                                  .catch((error) => {
                                    setIsPaymentLoading(false);
                                    return error;
                                  });
                              }
                            })
                            .catch((error) => {
                              setIsPaymentLoading(false);
                              return error;
                            });
                        } else {
                          setIsPaymentLoading(false);
                        }
                      },
                    };
                    const rzp1 = new window.Razorpay(options);
                    rzp1.open();
                  } else {
                    setIsPaymentLoading(false);
                    Swal.fire({
                      title: "warning",
                      text: resss.data.message,
                      icon: "warning",
                      confirmButtonText: "Ok",
                    });
                    navigate("/TransactionHistory");
                  }
                })
                .catch((error) => {
                  setIsPaymentLoading(false);
                  return error;
                });
            } else {
              Swal.fire({
                title: "warning",
                text: res.data.message,
                icon: "warning",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: error,
              icon: "warning",
              confirmButtonText: "Ok",
            });
            console.log(error, "error");
            return error;
          });
      } else {
        Swal.fire({
          title: "warning",
          text: "Your fee should be a minimum of 1 rupee. Kindly reach out to the administrator for assistance with this matter.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setListErrors(errorss);
    }
  };

  const handleSPayfeeFirstPayment = (details, elements, Payamounts) => {
    const errorss = validateFieldsPayments(list);
    if (Object.keys(errorss).length === 0) {
      const courseAmo = Payamounts;
      const receipt = "Order_" + Math.floor(Math.random() * 900 + 100);
      if (courseAmo > 0) {
        AxiosInstance.post("api/payemnt/create_order", {
          amount: courseAmo * 100,
          currency: "INR",
          partial_payment: true,
          receipt: receipt,
        })
          .then((res) => {
            if (res.data.status) {
              const { data } = res;
              let payment_status = "";
              if (data.status === "failed") {
                payment_status = 0;
              } else if (data.status === "created") {
                payment_status = 1;
              } else if (data.status === "authenticated") {
                payment_status = 2;
              } else if (data.status === "authorized") {
                payment_status = 3;
              } else if (data.status === "captured") {
                payment_status = 4;
              } else if (data.status === "refunded") {
                payment_status = 5;
              }
              const dat = {
                step: 6,
                location_id: courseBatchDetails.location_id,
                category: courseBatchDetails.batch_details.category,
                subject:
                  courseBatchDetails.subject.length > 0
                    ? courseBatchDetails.subject.map((item) => item.value)
                    : [], //send  if subject having only
                type: details.type,
                Isverify: true,
                AdmissionID: details.adm_pk,
                CouresName: courseBatchDetails.batch_details.course_name,
                discountAmount: courseBatchDetails.batch_details.dis_amt,
                gst: details.gst_percent,
                SpecialOffer: courseBatchDetails.batch_details.offer_spl,
                spc_offer_type: 0, // 0 means Nil
                batch_id: details.batch_id,
                coures_id: courseBatchDetails.batch_details.course_id,
                user_id: userid,
                old_stud_disc_perc: admissionDetails.old_stud_disc,
                old_stud_disc: admissionDetails.old_stud_disc,
                iscoupon: admissionDetails.is_coupon,
                couponAmount: admissionDetails.coupon_amount,
                couponcode: admissionDetails.coupon_code,
                Netamount: data.amount / 100,
                status: payment_status,
                transaction_id: "",
                razorpay_order_id: data.id,
                razorpay_signature: "",
                created_at: moment
                  .unix(data.created_at)
                  .format("YYYY-MM-DD HH:mm:ss"),
                partial_payment: parseInt(list.PPayment),
                old_stud_disc_applied: admissionDetails.old_stud_disc_applied,
                tot_amt: details.balance_amt,
                gst_amt: details.gst_amt,
                payment_type: 1,
              };

              AxiosInstance.post("api/course/admission/register", dat)
                .then((res) => {
                  const options = {
                    key: PaymentSKey,
                    amount: res.data.data.Netamount,
                    currency: "INR",
                    name: courseBatchDetails.batch_details.course_name,
                    prefill: {
                      name:
                        list.FirstName !== null && list.FirstName !== ""
                          ? list.FirstName
                          : "Anna iasa",
                      email:
                        list.email !== null && list.email !== ""
                          ? list.email
                          : "info@annaiasacademy.com",
                      contact:
                        list.mobile !== null && list.mobile !== ""
                          ? list.mobile
                          : "1234567890",
                    },
                    description: `For ${details.type === 0 && "Course"} ${
                      details.type === 1 && "Test"
                    } ${details.type === 3 && "Special Class"} ${
                      details.type === 4 && "Model Exam"
                    } Admission - ${details.batch_id}`,
                    image: "B",
                    order_id: res.data.data.razorpay_order_id,
                    handler: function (response) {
                      if (response) {
                        setIsPaymentLoading(true);
                        const dats = {
                          id: res.data.data.id ? res.data.data.id : null,
                          step: 7,
                          category: courseBatchDetails.batch_details.category,
                          subject:
                            courseBatchDetails.subject.length > 0
                              ? courseBatchDetails.subject.map(
                                  (item) => item.value
                                )
                              : [], //send  if subject having only
                          type: details.type,
                          Isverify: true,
                          location_id: courseBatchDetails.location_id,
                          AdmissionID: details.adm_pk,
                          CouresName:
                            courseBatchDetails.batch_details.course_name,
                          discountAmount:
                            courseBatchDetails.batch_details.dis_amt,
                          gst: details.gst_percent,
                          SpecialOffer:
                            courseBatchDetails.batch_details.offer_spl,
                          spc_offer_type: 0, // 0 means Nil
                          batch_id: details.batch_id,
                          coures_id: courseBatchDetails.batch_details.course_id,
                          user_id: userid,
                          old_stud_disc_perc: admissionDetails.old_stud_disc,
                          old_stud_disc: admissionDetails.old_stud_disc,
                          iscoupon: admissionDetails.is_coupon,
                          couponAmount: admissionDetails.coupon_amount,
                          couponcode: admissionDetails.coupon_code,
                          Netamount: res.data.data.Netamount,
                          status: res.data.data.status,
                          transaction_id: response.razorpay_payment_id,
                          razorpay_order_id: response.razorpay_order_id,
                          razorpay_signature: response.razorpay_signature,
                          created_at: res.data.data.created_at,
                          partial_payment: parseInt(list.PPayment),
                          old_stud_disc_applied:
                            admissionDetails.old_stud_disc_applied,
                          tot_amt: details.balance_amt,
                          gst_amt: details.gst_amt,
                          payment_type: 1,
                          created_by: userid,
                          updated_by: userid,
                          installment_type:
                            list.PPayment === "1" &&
                            CourseDetail.payment_status === 0 &&
                            CourseDetail.instment_pay.length > 0
                              ? [1]
                              : [1, 2, 3],
                        };
                        AxiosInstance.post(
                          "api/course/admission/register",
                          dats
                        )
                          .then((res_kk) => {
                            if (res_kk.data.status === true) {
                              setIsPaymentLoading(false);
                              Swal.fire({
                                title: "Success",
                                text: res_kk.data.message,
                                icon: "success",
                                confirmButtonText: "Ok",
                              });
                              navigate("/TransactionHistory/1");
                            } else {
                              setIsPaymentLoading(false);
                              Swal.fire({
                                title: "warning",
                                text: res_kk.data.message,
                                icon: "warning",
                                confirmButtonText: "Ok",
                              });
                            }
                          })
                          .catch((error) => {
                            setIsPaymentLoading(false);
                            Swal.fire({
                              title: "warning",
                              text: error,
                              icon: "warning",
                              confirmButtonText: "Ok",
                            });
                            navigate("/TransactionHistory/1");
                          });
                      } else {
                        Swal.fire({
                          title: "warning",
                          text: res.data.message,
                          icon: "warning",
                          confirmButtonText: "Ok",
                        });
                      }
                    },
                  };
                  // setIsPaymentLoading(false);
                  const rzp1 = new window.Razorpay(options);
                  rzp1.open();
                })
                .catch((error) => {
                  Swal.fire({
                    title: "warning",
                    text: error,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                  navigate("/TransactionHistory/1");
                });
            } else {
              Swal.fire({
                title: "warning",
                text: res.data.message,
                icon: "warning",
                confirmButtonText: "Ok",
              });
              navigate("/TransactionHistory/1");
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: error,
              icon: "warning",
              confirmButtonText: "Ok",
            });
            navigate("/TransactionHistory/1");
          });
      } else {
        Swal.fire({
          title: "warning",
          text: "Your fee should be a minimum of 1 rupee. Kindly reach out to the administrator for assistance with this matter.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setListErrors(errorss);
    }
  };

  const handleSpayeeModelExam = (
    details,
    el,
    admissionDtt,
    Payamounts,
    discount_amt,
    gstAmt
  ) => {
    const errorss = validateFieldsPayments(list);
    if (Object.keys(errorss).length === 0) {
      const courseAmo = Payamounts;
      const receipt = "Order_" + Math.floor(Math.random() * 900 + 100);
      if (courseAmo > 0) {
        AxiosInstance.post("api/payemnt/create_order", {
          amount: courseAmo * 100,
          currency: "INR",
          partial_payment: true,
          receipt: receipt,
        })
          .then((res) => {
            if (res.data.status) {
              const { data } = res;
              let payment_status = "";
              if (data.status === "failed") {
                payment_status = 0;
              } else if (data.status === "created") {
                payment_status = 1;
              } else if (data.status === "authenticated") {
                payment_status = 2;
              } else if (data.status === "authorized") {
                payment_status = 3;
              } else if (data.status === "captured") {
                payment_status = 4;
              } else if (data.status === "refunded") {
                payment_status = 5;
              }
              const dat = {
                step: 6,
                type: 4,
                category: list.category ? list.category : "",
                location_id: 2,
                subject: [],
                SpecialOffer: offSpl,
                Isverify: true,
                spc_offer_type: offSplDisplayType,
                AdmissionID: details.adm_pk,
                CouresName: courseBatchDetails.batch_details.course_name,
                discountAmount: discount_amt,
                gst: details.gst_percent,
                batch_id: details.batch_id,
                coures_id: courseBatchDetails.batch_details.course_id,
                user_id: userid,
                old_stud_disc_perc: admissionDetails.old_stud_disc,
                old_stud_disc: admissionDetails.old_stud_disc,
                iscoupon: admissionDetails.is_coupon,
                couponAmount: admissionDetails.coupon_amount,
                couponcode: admissionDetails.coupon_code,
                Netamount: data.amount / 100,
                status: payment_status,
                transaction_id: "",
                razorpay_order_id: data.id,
                razorpay_signature: "",
                created_at: moment
                  .unix(data.created_at)
                  .format("YYYY-MM-DD HH:mm:ss"),
                partial_payment: 0,
                old_stud_disc_applied: admissionDetails.old_stud_disc_applied,
                tot_amt: details.balance_amt,
                gst_amt: gstAmt,
                payment_type: 1,
              };

              AxiosInstance.post("api/course/admission/register", dat)
                .then((res) => {
                  const options = {
                    key: PaymentSKey,
                    amount: res.data.data.Netamount,
                    currency: "INR",
                    name: courseBatchDetails.batch_details.course_name,
                    prefill: {
                      name:
                        list.FirstName !== null && list.FirstName !== ""
                          ? list.FirstName
                          : "Anna iasa",
                      email:
                        list.email !== null && list.email !== ""
                          ? list.email
                          : "info@annaiasacademy.com",
                      contact:
                        list.mobile !== null && list.mobile !== ""
                          ? list.mobile
                          : "1234567890",
                    },
                    description: `For ${details.type === 0 && "Course"} ${
                      details.type === 1 && "Test"
                    } ${details.type === 3 && "Special Class"} ${
                      details.type === 4 && "Model Exam"
                    } Admission - ${details.batch_id}`,
                    image: "B",
                    order_id: res.data.data.razorpay_order_id,
                    handler: function (response) {
                      if (response) {
                        setIsPaymentLoading(true);
                        const dats = {
                          id: res.data.data.id ? res.data.data.id : null,
                          step: 7,
                          SpecialOffer: offSpl,
                          spc_offer_type: offSplDisplayType,
                          subject: [],
                          type: 4,
                          category: courseBatchDetails.batch_details.category,
                          location_id: 2,
                          Isverify: true,
                          AdmissionID: details.adm_pk,
                          CouresName:
                            courseBatchDetails.batch_details.course_name,
                          discountAmount: discount_amt,
                          gst: details.gst_percent,
                          batch_id: details.batch_id,
                          coures_id: courseBatchDetails.batch_details.course_id,
                          user_id: userid,
                          old_stud_disc_perc: admissionDetails.old_stud_disc,
                          old_stud_disc: admissionDetails.old_stud_disc,
                          iscoupon: admissionDetails.is_coupon,
                          couponAmount: admissionDetails.coupon_amount,
                          couponcode: admissionDetails.coupon_code,
                          Netamount: res.data.data.Netamount,
                          status: res.data.data.status,
                          transaction_id: response.razorpay_payment_id,
                          razorpay_order_id: response.razorpay_order_id,
                          razorpay_signature: response.razorpay_signature,
                          created_at: res.data.data.created_at,
                          partial_payment: 0,
                          old_stud_disc_applied:
                            admissionDetails.old_stud_disc_applied,
                          tot_amt: details.balance_amt,
                          gst_amt: gstAmt,
                          payment_type: 1,
                          created_by: userid,
                          updated_by: userid,
                          installment_type: [1, 2, 3],
                        };
                        AxiosInstance.post(
                          "api/course/admission/register",
                          dats
                        )
                          .then((res_kk) => {
                            if (res_kk.data.status === true) {
                              setIsPaymentLoading(false);
                              Swal.fire({
                                title: "Success",
                                text: res_kk.data.message,
                                icon: "success",
                                confirmButtonText: "Ok",
                              });
                              navigate("/TransactionHistory/1");
                            } else {
                              setIsPaymentLoading(false);
                              Swal.fire({
                                title: "warning",
                                text: res_kk.data.message,
                                icon: "error",
                                confirmButtonText: "Ok",
                              });
                            }
                          })
                          .catch((error) => {
                            setIsPaymentLoading(false);
                            Swal.fire({
                              title: "warning",
                              text: error,
                              icon: "error",
                              confirmButtonText: "Ok",
                            });
                            navigate("/TransactionHistory/1");
                          });
                      } else {
                        Swal.fire({
                          title: "warning",
                          text: res.data.message,
                          icon: "error",
                          confirmButtonText: "Ok",
                        });
                      }
                    },
                  };
                  // setIsPaymentLoading(false);
                  const rzp1 = new window.Razorpay(options);
                  rzp1.open();
                })
                .catch((error) => {
                  Swal.fire({
                    title: "warning",
                    text: error,
                    icon: "error",
                    confirmButtonText: "Ok",
                  });
                  navigate("/TransactionHistory/1");
                });
            } else {
              Swal.fire({
                title: "warning",
                text: res.data.message,
                icon: "error",
                confirmButtonText: "Ok",
              });
              navigate("/TransactionHistory/1");
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: error,
              icon: "error",
              confirmButtonText: "Ok",
            });
            navigate("/TransactionHistory/1");
          });
      } else {
        Swal.fire({
          title: "warning",
          text: "Your fee should be a minimum of 1 rupee. Kindly reach out to the administrator for assistance with this matter.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setListErrors(errorss);
    }
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const convertSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    if (minutes > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    } else {
      return `${hours}`;
    }
  };

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className="AdmissionForm AdmissionForm_section">
      {batchDetails.length > 0 &&
        batchDetails.map((el, index) => (
          <form>
            <div className="sectionSix">
              <>
                <div className="heading mb-4 dflexbetww align-items-center">
                  <h4 className="Title">Pay Fee</h4>
                  <div className="d-flex smButton mobiledown">
                    <Link to="/TransactionHistory" className="CancelButton">
                      Back to Transaction History
                    </Link>
                    <button
                      type="button"
                      className={
                        admissionDetails.old_stud_disc !== 0 &&
                        CourseDetail.instment_pay.length > 0 &&
                        CourseDetail.instment_pay[1].due_amount < 0 &&
                        list.PPayment === "1"
                          ? `SaveButton disabledButton`
                          : `SaveButton`
                      }
                      disabled={
                        admissionDetails.old_stud_disc !== 0 &&
                        CourseDetail.instment_pay.length > 0 &&
                        CourseDetail.instment_pay[1].due_amount < 0 &&
                        list.PPayment === "1"
                          ? true
                          : false
                      }
                      onClick={
                        CourseDetail.type === 4
                          ? () =>
                              handleSpayeeModelExam(
                                CourseDetail,
                                el,
                                admissionDetails,
                                el.exam_fees
                                  .filter(
                                    (fee) =>
                                      fee.fees_count ===
                                      el.exam_details.filter(
                                        (el) => el.is_selected_exam
                                      ).length
                                  )
                                  .map((fee) => fee.fees) -
                                  (list.coupon_amount +
                                    (list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? list.special_offer
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? 0
                                        : 0
                                      : list.special_offer
                                      ? list.special_offer
                                      : 0) +
                                    (list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? 0
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? list.old_stud_disc
                                        : 0
                                      : list.old_stud_disc
                                      ? list.old_stud_disc
                                      : 0)) +
                                  GSTPRICE *
                                    (el.exam_fees
                                      .filter(
                                        (fee) =>
                                          fee.fees_count ===
                                          el.exam_details.filter(
                                            (el) => el.is_selected_exam
                                          ).length
                                      )
                                      .map((fee) => fee.fees) -
                                      (list.coupon_amount +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? list.special_offer
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? 0
                                            : 0
                                          : list.special_offer
                                          ? list.special_offer
                                          : 0) +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? 0
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? list.old_stud_disc
                                            : 0
                                          : list.old_stud_disc
                                          ? list.old_stud_disc
                                          : 0))),
                                parseInt(
                                  list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? list.special_offer
                                      : list.old_stud_disc > list.special_offer
                                      ? 0
                                      : 0
                                    : list.special_offer
                                    ? list.special_offer
                                    : 0
                                ) +
                                  parseInt(list.coupon_amount) +
                                  parseInt(
                                    list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? 0
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? list.old_stud_disc
                                        : 0
                                      : list.old_stud_disc
                                      ? list.old_stud_disc
                                      : 0
                                  ),
                                (el.exam_fees
                                  .filter(
                                    (fee) =>
                                      fee.fees_count ===
                                      el.exam_details.filter(
                                        (el) => el.is_selected_exam
                                      ).length
                                  )
                                  .map((fee) => fee.fees) -
                                  (list.coupon_amount +
                                    (list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? list.special_offer
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? 0
                                        : 0
                                      : list.special_offer
                                      ? list.special_offer
                                      : 0) +
                                    (list.old_stud_disc && list.special_offer
                                      ? list.old_stud_disc < list.special_offer
                                        ? 0
                                        : list.old_stud_disc >
                                          list.special_offer
                                        ? list.old_stud_disc
                                        : 0
                                      : list.old_stud_disc
                                      ? list.old_stud_disc
                                      : 0))) *
                                  GSTPRICE
                              )
                          : list.PPayment === "1" &&
                            CourseDetail.instment_pay.length > 0 &&
                            CourseDetail.payment_status === 1
                          ? () =>
                              handleSPayfee(
                                CourseDetail,
                                el,
                                CourseDetail.instment_pay.length > 0 &&
                                  CourseDetail.instment_pay[0]
                                    .payment_status !== "1"
                                  ? list.PPayment === "1"
                                    ? CourseDetail.instment_pay[0].due_amount
                                    : Number(
                                        CourseDetail.instment_pay[0].due_amount
                                      ) +
                                      Number(
                                        CourseDetail.instment_pay[1].due_amount
                                      )
                                  : CourseDetail.instment_pay.length > 0
                                  ? CourseDetail.instment_pay[1].due_amount
                                  : 0
                              )
                          : () =>
                              handleSPayfeeFirstPayment(
                                CourseDetail,
                                el,
                                CourseDetail.balance_amt
                              )
                      }
                    >
                      <a href="javascript:void(0)">
                        Save and Continue to Payment Gateway
                      </a>
                    </button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="row">
                      {CourseDetail.type !== 4 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="card h-100">
                            <div className="row">
                              <div className="col-md-6 d-flex flex-column  justify-content-center align-center mt-4">
                                <img
                                  title={el.img_url}
                                  src={`${ImageUrlPrefix}/${el.img_url}`}
                                  id={el.id}
                                  alt="Imge"
                                  style={{
                                    width: "130px",
                                    height: "130px",
                                    alignSelf: "center",
                                  }}
                                />
                                <h5 className="card-title payment_course_name">
                                  {el.course_name || ""} {"-"} {el.name || ""}
                                </h5>
                                <p className=" ms-3 text-center payment_batch_name">
                                  {el.batch_details}
                                </p>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex mt-4 flex-column align-items-start payment">
                                  <div className="">
                                    <LaptopChromebook className="payment_icon" />
                                    <div className="innerflex_payment">
                                      <h6 className="payment flex_title">
                                        Class Mode
                                      </h6>
                                      <h6 className="payment flex_subtitle">
                                        {el.class_mode}
                                      </h6>
                                    </div>
                                  </div>

                                  <div className="">
                                    <Today className="payment_icon" />
                                    <div className="innerflex_payment">
                                      <h6 className="payment flex_title">
                                        Starts On
                                      </h6>
                                      <h6 className="payment flex_subtitle">
                                        {moment
                                          .utc(el.start_on)
                                          .format("YYYY-MM-DD")}
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="">
                                    <Alarm className="payment_icon" />
                                    <div className="innerflex_payment">
                                      <h6 className="payment flex_title">
                                        Duration
                                      </h6>
                                      <h6 className="payment flex_subtitle">
                                        {el.duration}
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="">
                                    <span class="material-icons payment_icon">
                                      g_translate
                                    </span>
                                    <div className="innerflex_payment">
                                      <h6 className="payment flex_title">
                                        Medium of Instruction
                                      </h6>
                                      <h6 className="payment flex_subtitle">
                                        {el.medium}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {CourseDetail.type === 4 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="card h-100">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 d-flex flex-column  justify-content-center align-center mt-4">
                                <img
                                  className="payment"
                                  src={`${ImageUrlPrefix}/${el.img_url}`}
                                  alt="course_book"
                                  style={{
                                    width: "130px",
                                    height: "130px",
                                    alignSelf: "center",
                                  }}
                                />
                                <h5 className="card-title payment_course_name">
                                  {el.course_name || ""} {"-"}{" "}
                                  {el.batch_name || ""}
                                </h5>
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-6  d-flex flex-column  justify-content-center align-center mt-4 ">
                                <li className="IconsImage">
                                  <span className="material-icons">
                                    format_shapes
                                  </span>
                                  <div className="d-flex flex-column">
                                    <p className="title ms-1">Mode of Exam</p>
                                    <p className="subtitle ms-1">
                                      {el.class_mode === "ClassRoom"
                                        ? "Offline"
                                        : "Online"}
                                    </p>
                                  </div>
                                </li>
                              </div>

                              <div className="col-lg-12 mb-4">
                                {el.paper_type === 0 &&
                                  el.exam_details.length > 0 &&
                                  el.exam_details
                                    .filter(
                                      (ell) => ell.is_selected_exam === true
                                    )
                                    ?.sort(
                                      (a, b) =>
                                        new Date(a.started_dt) -
                                        new Date(b.started_dt)
                                    )
                                    ?.map((oj, index) => (
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12 mx-2">
                                          <div className="row">
                                            <div className="col-md-4 mt-2">
                                              <li className="IconsImage">
                                                <span className="material-icons">
                                                  format_shapes
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Exam Title
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.exam_title}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                              <li className="IconsImage">
                                                <span className="material-icons-outlined">
                                                  timer
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Date
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.started_dt
                                                      ? moment(
                                                          oj.started_dt
                                                        ).format("Do MMM YYYY")
                                                      : "_"}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                              <li className="IconsImage">
                                                <span className="material-icons-outlined">
                                                  timer
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Time
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.batch_time}{" "}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                              <li className="IconsImage">
                                                <span className="material-icons">
                                                  event
                                                </span>
                                                <div className="d-flex flex-column ">
                                                  <p className="st_confirm_label ms-1">
                                                    Last Date to Apply
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {moment
                                                      .utc(oj.last_dt_apply)
                                                      .format("Do MMM YYYY")}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                        {el.exam_details.length > 1 &&
                                          el.exam_details.map(
                                            (el) => el.is_selected_exam
                                          ).length > 1 &&
                                          index <
                                            el.exam_details.length - 1 && (
                                            <span className="border-exam-short my-2"></span>
                                          )}
                                      </div>
                                    ))}

                                {el.paper_type === 1 &&
                                  el.exam_details.length > 0 &&
                                  el.exam_details
                                    .filter(
                                      (ell) => ell.is_selected_exam === true
                                    )
                                    ?.sort(
                                      (a, b) =>
                                        new Date(a.last_dt_apply) -
                                        new Date(b.last_dt_apply)
                                    )
                                    ?.map((oj, index) => (
                                      <div className="row">
                                        <div className="col-md-12 mt-3 col-sm-12 ">
                                          <div className="row">
                                            <div className="col-md-4 dflex justify-content-center">
                                              <li className="IconsImage">
                                                <span className="material-icons">
                                                  format_shapes
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Model Exam Title
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.exam_title}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 dflex justify-content-center">
                                              <li className="IconsImage">
                                                <span className="material-icons-outlined">
                                                  timer
                                                </span>
                                                <div className="d-flex flex-column ">
                                                  <p className="st_confirm_label ms-1">
                                                    Number of Papers
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {oj.no_of_paper}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                            <div className="col-md-4 dflex justify-content-center">
                                              <li className="IconsImage">
                                                <span className="material-icons">
                                                  event
                                                </span>
                                                <div className="d-flex flex-column">
                                                  <p className="st_confirm_label ms-1">
                                                    Last Date to Apply
                                                  </p>
                                                  <p className="st_confirm_value ms-1">
                                                    {moment
                                                      .utc(oj.last_dt_apply)
                                                      .format("Do MMM YYYY")}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 mx-2">
                                          <div
                                            className="card"
                                            style={{ width: "97%" }}
                                          >
                                            {oj.papers
                                              ?.sort((a, b) => {
                                                // Sort by start date first
                                                const dateComparison = moment(
                                                  a.start_dt
                                                ).diff(moment(b.start_dt));
                                                if (dateComparison !== 0)
                                                  return dateComparison;

                                                // If start dates are the same, sort by test time using Moment.js
                                                const timeA = moment(
                                                  `${
                                                    a.start_dt.split("T")[0]
                                                  } ${
                                                    a.test_time.split(" to ")[0]
                                                  }`,
                                                  "YYYY-MM-DD hh:mm A"
                                                );
                                                const timeB = moment(
                                                  `${
                                                    b.start_dt.split("T")[0]
                                                  } ${
                                                    b.test_time.split(" to ")[0]
                                                  }`,
                                                  "YYYY-MM-DD hh:mm A"
                                                );
                                                return timeA.diff(timeB);
                                              })
                                              ?.map((io, ind) => (
                                                <div className="row ">
                                                  <div className="col-md-4 dflex justify-content-center mt-2">
                                                    <li className="IconsImage">
                                                      <span className="material-icons">
                                                        format_shapes
                                                      </span>
                                                      <div className="d-flex flex-column">
                                                        <p className="st_confirm_label  ms-1">
                                                          Paper Title
                                                        </p>
                                                        <p className="st_confirm_value ms-1">
                                                          {io.paper_title}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                  {/* New added */}

                                                  <div className="col-md-3 dflex justify-content-center mt-2">
                                                    <li className="IconsImage">
                                                      <span className="material-icons">
                                                        event
                                                      </span>
                                                      <div className="d-flex flex-column">
                                                        <p className="st_confirm_label  ms-1">
                                                          Date
                                                        </p>
                                                        <p className="st_confirm_value ms-1">
                                                          {io.start_dt
                                                            ? moment(
                                                                io.start_dt
                                                              ).format(
                                                                "Do MMM YYYY"
                                                              )
                                                            : "_"}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>
                                                  <div className="col-md-4 dflex justify-content-center mt-2">
                                                    <li className="IconsImage">
                                                      <span className="material-icons-outlined">
                                                        timer
                                                      </span>
                                                      <div className="d-flex flex-column">
                                                        <p className="st_confirm_label  ms-1">
                                                          Time
                                                        </p>
                                                        <p className="st_confirm_value ms-1">
                                                          {io.test_time}{" "}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </div>

                                                  {oj.papers.length > 1 &&
                                                    ind <
                                                      oj.papers.length - 1 && (
                                                      <span className="border-exam-short_stepssA my-2"></span>
                                                    )}
                                                </div>
                                              ))}
                                          </div>
                                        </div>

                                        {el.exam_details.length > 1 &&
                                          el.exam_details.filter(
                                            (opp) =>
                                              opp.is_selected_exam === true
                                          ).length > 1 &&
                                          index <
                                            el.exam_details.length - 1 && (
                                            <span className="border-exam-short_stepss my-2 "></span>
                                          )}
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {CourseDetail.type !== 4 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="card h-100">
                            <div className="row mt-4">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfo">
                                  Actual Course Fee
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfos">
                                  <CurrencyRupee className="payment_currency" />
                                  {el.act_c_fee}
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">
                                  Special Offer
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {CourseDetail.special_offer}
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name PymentCoun">
                                  Coupon Code
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {CourseDetail.coupon_amount}
                                </h6>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6
                                  className="Payment_labelInfo"
                                  style={{ paddingTop: "9px" }}
                                >
                                  Total Discounted Fee
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfos pt-2">
                                  <CurrencyRupee className="payment_currency" />
                                  <span>
                                    {parseInt(CourseDetail.special_offer) +
                                      parseInt(CourseDetail.coupon_amount)}
                                  </span>
                                </h6>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">
                                  {list.PPayment === "1"
                                    ? "Final Course Fee"
                                    : "Amount Payable"}
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  <span className="">
                                    {CourseDetail.tot_amt}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6
                                  className="payment_fees_name"
                                  id="remain_fee"
                                >
                                  {list.PPayment === "1"
                                    ? "Fee Paid"
                                    : "Net Amount To Pay"}
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  <span>
                                    {list.PPayment === "1"
                                      ? CourseDetail.paid_amount
                                      : CourseDetail.balance_amt}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {CourseDetail.type === 4 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="card h-100">
                            <div className="row mt-4">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfo">
                                  Actual Exam Fee
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfos">
                                  <CurrencyRupee className="payment_currency" />
                                  {el.exam_fees
                                    .filter(
                                      (fee) =>
                                        fee.fees_count ===
                                        el.exam_details.filter(
                                          (esl) => esl.is_selected_exam
                                        ).length
                                    )
                                    .map((fee) => fee.fees)}
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">
                                  Old Student Discount
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? 0
                                      : list.old_stud_disc > list.special_offer
                                      ? list.old_stud_disc
                                      : 0
                                    : list.old_stud_disc
                                    ? list.old_stud_disc
                                    : 0}
                                </h6>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">
                                  Special Offer
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? list.special_offer
                                      : list.old_stud_disc > list.special_offer
                                      ? 0
                                      : 0
                                    : list.special_offer
                                    ? list.special_offer
                                    : 0}
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name PymentCoun">
                                  Coupon Code
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  {list.coupon_amount}
                                </h6>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6
                                  className="Payment_labelInfo"
                                  style={{ paddingTop: "9px" }}
                                >
                                  Total Discounted Fee
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="Payment_labelInfos pt-2">
                                  <CurrencyRupee className="payment_currency" />
                                  <span>
                                    {parseInt(
                                      list.old_stud_disc && list.special_offer
                                        ? list.old_stud_disc <
                                          list.special_offer
                                          ? list.special_offer
                                          : list.old_stud_disc >
                                            list.special_offer
                                          ? 0
                                          : 0
                                        : list.special_offer
                                        ? list.special_offer
                                        : 0
                                    ) +
                                      parseInt(list.coupon_amount) +
                                      parseInt(
                                        list.old_stud_disc && list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? 0
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? list.old_stud_disc
                                            : 0
                                          : list.old_stud_disc
                                          ? list.old_stud_disc
                                          : 0
                                      )}
                                  </span>
                                </h6>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees_name">
                                  Amount Payable
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  <span className="">
                                    {" "}
                                    {el.exam_fees
                                      .filter(
                                        (fee) =>
                                          fee.fees_count ===
                                          el.exam_details.filter(
                                            (el) => el.is_selected_exam
                                          ).length
                                      )
                                      .map((fee) => fee.fees) -
                                      (list.coupon_amount +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? list.special_offer
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? 0
                                            : 0
                                          : list.special_offer
                                          ? list.special_offer
                                          : 0) +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? 0
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? list.old_stud_disc
                                            : 0
                                          : list.old_stud_disc
                                          ? list.old_stud_disc
                                          : 0)) >
                                    0
                                      ? el.exam_fees
                                          .filter(
                                            (fee) =>
                                              fee.fees_count ===
                                              el.exam_details.filter(
                                                (el) => el.is_selected_exam
                                              ).length
                                          )
                                          .map((fee) => fee.fees) -
                                        (list.coupon_amount +
                                          (list.old_stud_disc &&
                                          list.special_offer
                                            ? list.old_stud_disc <
                                              list.special_offer
                                              ? list.special_offer
                                              : list.old_stud_disc >
                                                list.special_offer
                                              ? 0
                                              : 0
                                            : list.special_offer
                                            ? list.special_offer
                                            : 0) +
                                          (list.old_stud_disc &&
                                          list.special_offer
                                            ? list.old_stud_disc <
                                              list.special_offer
                                              ? 0
                                              : list.old_stud_disc >
                                                list.special_offer
                                              ? list.old_stud_disc
                                              : 0
                                            : list.old_stud_disc
                                            ? list.old_stud_disc
                                            : 0))
                                      : 0}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6
                                  className="payment_fees_name"
                                  id="remain_fee"
                                >
                                  Net Amount To Pay
                                </h6>
                              </div>
                              <div className="col-6 col-sm-5 col-lg-6 col-md-6">
                                <h6 className="payment_fees">
                                  <CurrencyRupee className="payment_currency" />
                                  <span>
                                    {" "}
                                    {el.exam_fees
                                      .filter(
                                        (fee) =>
                                          fee.fees_count ===
                                          el.exam_details.filter(
                                            (el) => el.is_selected_exam
                                          ).length
                                      )
                                      .map((fee) => fee.fees) -
                                      (list.coupon_amount +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? list.special_offer
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? 0
                                            : 0
                                          : list.special_offer
                                          ? list.special_offer
                                          : 0) +
                                        (list.old_stud_disc &&
                                        list.special_offer
                                          ? list.old_stud_disc <
                                            list.special_offer
                                            ? 0
                                            : list.old_stud_disc >
                                              list.special_offer
                                            ? list.old_stud_disc
                                            : 0
                                          : list.old_stud_disc
                                          ? list.old_stud_disc
                                          : 0)) +
                                      GSTPRICE *
                                        (el.exam_fees
                                          .filter(
                                            (fee) =>
                                              fee.fees_count ===
                                              el.exam_details.filter(
                                                (el) => el.is_selected_exam
                                              ).length
                                          )
                                          .map((fee) => fee.fees) -
                                          (list.coupon_amount +
                                            (list.old_stud_disc &&
                                            list.special_offer
                                              ? list.old_stud_disc <
                                                list.special_offer
                                                ? list.special_offer
                                                : list.old_stud_disc >
                                                  list.special_offer
                                                ? 0
                                                : 0
                                              : list.special_offer
                                              ? list.special_offer
                                              : 0) +
                                            (list.old_stud_disc &&
                                            list.special_offer
                                              ? list.old_stud_disc <
                                                list.special_offer
                                                ? 0
                                                : list.old_stud_disc >
                                                  list.special_offer
                                                ? list.old_stud_disc
                                                : 0
                                              : list.old_stud_disc
                                              ? list.old_stud_disc
                                              : 0))) >
                                    0
                                      ? (
                                          el.exam_fees
                                            .filter(
                                              (fee) =>
                                                fee.fees_count ===
                                                el.exam_details.filter(
                                                  (el) => el.is_selected_exam
                                                ).length
                                            )
                                            .map((fee) => fee.fees) -
                                          (list.coupon_amount +
                                            (list.old_stud_disc &&
                                            list.special_offer
                                              ? list.old_stud_disc <
                                                list.special_offer
                                                ? list.special_offer
                                                : list.old_stud_disc >
                                                  list.special_offer
                                                ? 0
                                                : 0
                                              : list.special_offer
                                              ? list.special_offer
                                              : 0) +
                                            (list.old_stud_disc &&
                                            list.special_offer
                                              ? list.old_stud_disc <
                                                list.special_offer
                                                ? 0
                                                : list.old_stud_disc >
                                                  list.special_offer
                                                ? list.old_stud_disc
                                                : 0
                                              : list.old_stud_disc
                                              ? list.old_stud_disc
                                              : 0)) +
                                          GSTPRICE *
                                            (el.exam_fees
                                              .filter(
                                                (fee) =>
                                                  fee.fees_count ===
                                                  el.exam_details.filter(
                                                    (el) => el.is_selected_exam
                                                  ).length
                                              )
                                              .map((fee) => fee.fees) -
                                              (list.coupon_amount +
                                                (list.old_stud_disc &&
                                                list.special_offer
                                                  ? list.old_stud_disc <
                                                    list.special_offer
                                                    ? list.special_offer
                                                    : list.old_stud_disc >
                                                      list.special_offer
                                                    ? 0
                                                    : 0
                                                  : list.special_offer
                                                  ? list.special_offer
                                                  : 0) +
                                                (list.old_stud_disc &&
                                                list.special_offer
                                                  ? list.old_stud_disc <
                                                    list.special_offer
                                                    ? 0
                                                    : list.old_stud_disc >
                                                      list.special_offer
                                                    ? list.old_stud_disc
                                                    : 0
                                                  : list.old_stud_disc
                                                  ? list.old_stud_disc
                                                  : 0)))
                                        ).toFixed(2)
                                      : 0}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            </div>

            {admissionDetails.old_stud_disc !== 0 && (
              <section className="sectionSix">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Old Student Discount</p>

                    <div className="row mb-3">
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Old Student Discount
                          </label>
                          <span className="ReviewLabel ms-3">
                            <CurrencyRupee className="payment_currency" />
                            {dis}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-12 col-md-12">
                        <div className="forms">
                          <span className="ReviewLabel_note alert_info">
                            <strong> Note :</strong> The Old Student Discount
                            will be applied to the final installment only if you
                            select the Partial Payment option. Alternatively, if
                            you choose the Full Payment option, the Old Student
                            Discount amount will be deducted from the payment
                            immediately.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Payment Option</p>

                  <div className="row mb-3">
                    <div className="col-lg-7 ol-md-7">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Payment Option
                        </label>
                        {CourseDetail.instment_pay.length > 0 &&
                        CourseDetail.payment_status === 1 &&
                        CourseDetail.instment_pay[0].payment_status == 0 ? (
                          <div className="d-flex  col-md-12" id="radiobuttons">
                            <div className="form-check form-check-inline">
                              <input
                                ref={txtAdPPayment}
                                className="checkbox_radio"
                                type="radio"
                                name="PPayment"
                                id="full"
                                checked={list.PPayment === "0" ? true : false}
                                value="0"
                                onChange={handleChange}
                              />
                              <label
                                className="checkbox-label mt-1"
                                htmlFor="full"
                              >
                                Full Balance Payment (2nd + 3rd Payment)
                              </label>
                            </div>
                            <div className="form-check form-check-inline p-sm-0 me-sm-0">
                              <input
                                className="checkbox_radio"
                                type="radio"
                                name="PPayment"
                                id="Partial"
                                value="1"
                                checked={list.PPayment === "1" ? true : false}
                                onChange={handleChange}
                              />
                              <label
                                className="checkbox-label mt-1"
                                htmlFor="Partial"
                              >
                                Partial Payment (2nd Payment)
                              </label>
                            </div>
                          </div>
                        ) : list.PPayment === "1" &&
                          CourseDetail.instment_pay.length > 0 &&
                          CourseDetail.payment_status === 0 ? (
                          <div className="d-flex  col-md-12" id="radiobuttons">
                            <div className="form-check form-check-inline">
                              <input
                                ref={txtAdPPayment}
                                className="checkbox_radio"
                                type="radio"
                                name="PPayment"
                                id="Partial"
                                value="1"
                                checked={
                                  list.PPayment === "1" || list.PPayment === "0"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <label
                                className="checkbox-label mt-1"
                                htmlFor="full"
                              >
                                Partial Payment (1st Payment)
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex  col-md-12" id="radiobuttons">
                            <div className="form-check form-check-inline">
                              <input
                                ref={txtAdPPayment}
                                className="checkbox_radio"
                                type="radio"
                                name="PPayment"
                                id="Partial"
                                value="1"
                                checked={
                                  list.PPayment === "1" || list.PPayment === "0"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <label
                                className="checkbox-label mt-1"
                                htmlFor="full"
                              >
                                Final Payment
                              </label>
                            </div>
                          </div>
                        )}

                        {listErrors.PPayment && (
                          <p className="errors">{listErrors.PPayment}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-5">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Amount To Pay{" "}
                          {CourseDetail.instment_pay.length > 0 &&
                          CourseDetail.payment_status === 1
                            ? CourseDetail.instment_pay[0].payment_status !==
                              "1"
                              ? admissionDetails.old_stud_disc !== 0
                                ? list.PPayment === "1"
                                  ? `(${CourseDetail.instment_pay[0].due_perc}% of Final Course Fee)`
                                  : `(${
                                      CourseDetail.instment_pay[0].due_perc +
                                      CourseDetail.instment_pay[1].due_perc
                                    }% of Final Course Fee - Old Student
                                  Discount)`
                                : list.PPayment === "1"
                                ? `(${CourseDetail.instment_pay[0].due_perc}% of Final Course Fee )`
                                : `(${
                                    CourseDetail.instment_pay[0].due_perc +
                                    CourseDetail.instment_pay[1].due_perc
                                  }% of Final Course Fee )`
                              : admissionDetails.old_stud_disc !== 0
                              ? list.PPayment === "1"
                                ? `(${
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay[1].due_perc
                                  }% of Final Course Fee - Old Student
                                  Discount)`
                                : `(${
                                    CourseDetail.instment_pay.length > 0 &&
                                    CourseDetail.instment_pay[1].due_perc
                                  }% of Final Course Fee - Old Student
                                    Discount)`
                              : list.PPayment === "1"
                              ? `(${
                                  CourseDetail.instment_pay.length > 0 &&
                                  CourseDetail.instment_pay[1].due_perc
                                }% of Final Course Fee)`
                              : `(${
                                  CourseDetail.instment_pay.length > 0
                                    ? CourseDetail.instment_pay[1].due_perc
                                    : "100"
                                } % of Final Course Fee)`
                            : list.PPayment === "1"
                            ? `(${CourseDetail.pay_1_perc}% of Final Course Fee)`
                            : `(100 % of Final Course Fee)`}
                        </label>
                        <span className="ReviewLabel mt-md-1 mt-sm-4">
                          <CurrencyRupee className="payment_currency" />
                          {CourseDetail.instment_pay.length > 0 &&
                          CourseDetail.payment_status === 1
                            ? CourseDetail.instment_pay[0].payment_status !==
                              "1"
                              ? list.PPayment === "1"
                                ? CourseDetail.instment_pay.length > 0 &&
                                  CourseDetail.instment_pay[0].due_amount
                                : Number(
                                    CourseDetail.instment_pay[0].due_amount
                                  ) +
                                  Number(
                                    CourseDetail.instment_pay[1].due_amount
                                  )
                              : CourseDetail.instment_pay.length > 0 &&
                                CourseDetail.instment_pay[1].due_amount
                            : CourseDetail.balance_amt}
                        </span>
                      </div>
                    </div>
                  </div>
                  {admissionDetails.old_stud_disc !== 0 &&
                    CourseDetail.instment_pay.length > 0 &&
                    CourseDetail.instment_pay[1].due_amount < 0 &&
                    list.PPayment === "1" && (
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="forms ">
                            <span className="ReviewLabel_warning alert_warning ">
                              <div className="">
                                <span class="material-icons ">warning</span>
                                <strong className="waning_message">
                                  WARNING
                                </strong>
                                <span className="">
                                  {" "}
                                  : If you choose{" "}
                                  <b style={{ color: "black" }}>
                                    Partial Payment (2nd Payment)
                                  </b>
                                  , the remaining fee will be less than the Old
                                  Student Discount. We request you to select
                                  <b
                                    style={{ color: "black" }}
                                    className="ms-1"
                                  >
                                    Full Balance Payment (2nd + 3rd Payment)
                                  </b>{" "}
                                  to apply the discount and pay the fees.
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </section>

            {list.PPayment === "1" &&
              CourseDetail.instment_pay.length > 0 &&
              CourseDetail.instment_pay[0].payment_status !== "1" && (
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Partial Payment Schedule Details
                      </p>

                      <div className="row">
                        <div className="col-lg-6 ol-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              {" "}
                              2nd Payment Date (On or Before)
                            </label>
                            <span className="ReviewLabel">
                              {CourseDetail.instment_pay[0].due_date
                                ? moment(
                                    CourseDetail.instment_pay[0].due_date,
                                    "DD/MM/YYYY",
                                    true
                                  ).isValid()
                                  ? moment(
                                      CourseDetail.instment_pay[0].due_date,
                                      "DD/MM/YYYY"
                                    ).format("YYYY-MM-DD")
                                  : moment(
                                      CourseDetail.instment_pay[0].due_date
                                    ).format("YYYY-MM-DD")
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              2nd Payment Amount{" "}
                              {`(${CourseDetail.instment_pay[0].due_perc}% of Final Course Fee)`}
                            </label>
                            <span className="ReviewLabel  mt-md-1 mt-sm-4">
                              <CurrencyRupee className="payment_currency" />
                              {CourseDetail.instment_pay[0].due_amount}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-lg-6 ol-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              3rd Payment Date (On or Before)
                            </label>
                            <span className="ReviewLabel">
                              {CourseDetail.instment_pay[1].due_date
                                ? moment(
                                    CourseDetail.instment_pay[1].due_date,
                                    "DD/MM/YYYY",
                                    true
                                  ).isValid()
                                  ? moment(
                                      CourseDetail.instment_pay[1].due_date,
                                      "DD/MM/YYYY"
                                    ).format("YYYY-MM-DD")
                                  : moment(
                                      CourseDetail.instment_pay[1].due_date
                                    ).format("YYYY-MM-DD")
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              3rd Payment Amount{" "}
                              {admissionDetails.old_stud_disc !== 0
                                ? `(${CourseDetail.instment_pay[1].due_perc}% of Final Course Fee -
                   Old Student Discount)`
                                : `(${CourseDetail.instment_pay[1].due_perc}% of Final Course Fee)`}
                            </label>
                            <span className="ReviewLabel mt-md-1 mt-sm-4">
                              <CurrencyRupee className="payment_currency" />
                              {CourseDetail.instment_pay[1].due_amount}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                          <div className="forms ">
                            <span className="ReviewLabel_warning alert_warning ">
                              <div className="blink">
                                <span class="material-icons ">warning</span>
                                <strong className="waning_message">
                                  WARNING
                                </strong>
                                <span className="">
                                  : Please pay your fees by the given date to
                                  keep your schedule active. If you don't pay on
                                  time, you will not be allowed to attend
                                  classes until the fees are paid.
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            <div className="sectionSix">
              <div className="heading mb-4 dflexbetwwend align-items-center">
                <div className="d-flex smButton mobiledown">
                  <Link to="/TransactionHistory" className="CancelButton">
                    Back to Transaction History
                  </Link>
                  <button
                    type="button"
                    className={
                      admissionDetails.old_stud_disc !== 0 &&
                      CourseDetail.instment_pay.length > 0 &&
                      CourseDetail.instment_pay[1].due_amount < 0 &&
                      list.PPayment === "1"
                        ? `SaveButton disabledButton`
                        : `SaveButton`
                    }
                    disabled={
                      admissionDetails.old_stud_disc !== 0 &&
                      CourseDetail.instment_pay.length > 0 &&
                      CourseDetail.instment_pay[1].due_amount < 0 &&
                      list.PPayment === "1"
                        ? true
                        : false
                    }
                    onClick={
                      CourseDetail.type === 4
                        ? () =>
                            handleSpayeeModelExam(
                              CourseDetail,
                              el,
                              admissionDetails,
                              el.exam_fees
                                .filter(
                                  (fee) =>
                                    fee.fees_count ===
                                    el.exam_details.filter(
                                      (el) => el.is_selected_exam
                                    ).length
                                )
                                .map((fee) => fee.fees) -
                                (list.coupon_amount +
                                  (list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? list.special_offer
                                      : list.old_stud_disc > list.special_offer
                                      ? 0
                                      : 0
                                    : list.special_offer
                                    ? list.special_offer
                                    : 0) +
                                  (list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? 0
                                      : list.old_stud_disc > list.special_offer
                                      ? list.old_stud_disc
                                      : 0
                                    : list.old_stud_disc
                                    ? list.old_stud_disc
                                    : 0)) +
                                GSTPRICE *
                                  (el.exam_fees
                                    .filter(
                                      (fee) =>
                                        fee.fees_count ===
                                        el.exam_details.filter(
                                          (el) => el.is_selected_exam
                                        ).length
                                    )
                                    .map((fee) => fee.fees) -
                                    (list.coupon_amount +
                                      (list.old_stud_disc && list.special_offer
                                        ? list.old_stud_disc <
                                          list.special_offer
                                          ? list.special_offer
                                          : list.old_stud_disc >
                                            list.special_offer
                                          ? 0
                                          : 0
                                        : list.special_offer
                                        ? list.special_offer
                                        : 0) +
                                      (list.old_stud_disc && list.special_offer
                                        ? list.old_stud_disc <
                                          list.special_offer
                                          ? 0
                                          : list.old_stud_disc >
                                            list.special_offer
                                          ? list.old_stud_disc
                                          : 0
                                        : list.old_stud_disc
                                        ? list.old_stud_disc
                                        : 0))),
                              parseInt(
                                list.old_stud_disc && list.special_offer
                                  ? list.old_stud_disc < list.special_offer
                                    ? list.special_offer
                                    : list.old_stud_disc > list.special_offer
                                    ? 0
                                    : 0
                                  : list.special_offer
                                  ? list.special_offer
                                  : 0
                              ) +
                                parseInt(list.coupon_amount) +
                                parseInt(
                                  list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? 0
                                      : list.old_stud_disc > list.special_offer
                                      ? list.old_stud_disc
                                      : 0
                                    : list.old_stud_disc
                                    ? list.old_stud_disc
                                    : 0
                                ),
                              (el.exam_fees
                                .filter(
                                  (fee) =>
                                    fee.fees_count ===
                                    el.exam_details.filter(
                                      (el) => el.is_selected_exam
                                    ).length
                                )
                                .map((fee) => fee.fees) -
                                (list.coupon_amount +
                                  (list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? list.special_offer
                                      : list.old_stud_disc > list.special_offer
                                      ? 0
                                      : 0
                                    : list.special_offer
                                    ? list.special_offer
                                    : 0) +
                                  (list.old_stud_disc && list.special_offer
                                    ? list.old_stud_disc < list.special_offer
                                      ? 0
                                      : list.old_stud_disc > list.special_offer
                                      ? list.old_stud_disc
                                      : 0
                                    : list.old_stud_disc
                                    ? list.old_stud_disc
                                    : 0))) *
                                GSTPRICE
                            )
                        : list.PPayment === "1" &&
                          CourseDetail.instment_pay.length > 0 &&
                          CourseDetail.payment_status === 1
                        ? () =>
                            handleSPayfee(
                              CourseDetail,
                              el,
                              CourseDetail.instment_pay.length > 0 &&
                                CourseDetail.instment_pay[0].payment_status !==
                                  "1"
                                ? list.PPayment === "1"
                                  ? CourseDetail.instment_pay[0].due_amount
                                  : Number(
                                      CourseDetail.instment_pay[0].due_amount
                                    ) +
                                    Number(
                                      CourseDetail.instment_pay[1].due_amount
                                    )
                                : CourseDetail.instment_pay.length > 0
                                ? CourseDetail.instment_pay[1].due_amount
                                : 0
                            )
                        : () =>
                            handleSPayfeeFirstPayment(
                              CourseDetail,
                              el,
                              CourseDetail.balance_amt
                            )
                    }
                  >
                    <a href="javascript:void(0)">
                      Save and Continue to Payment Gateway
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))}
      {/* loader */}
      {isPaymentLoading && (
        <Dialog open={isPaymentLoading}>
          <DialogContent>
            <div style={{ margin: "15px" }}>
              {/* <div className="spinner-border text-danger"></div>{" "} */}
              <p> We are processing your payment. Please wait...</p>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
export default PayFee;
