import React, { useState, useRef, useEffect } from "react";

import $, { data } from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chip, Typography } from "@material-ui/core";
import { CloseOutlined, FormatPaintSharp } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";

import DatePickerSample from "../../DatePicker";
import ExamAdvancedFilterModel from "./ExamAdvancedFilterModel";
import { type } from "@testing-library/user-event/dist/type";
import Loader from "../../Loader";
function ExamRecords(props) {
  // console.log("LalithaloadListdata");
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const txtBSearch = useRef(null);
  // List data load table
  // AIASA-000185 issues solved by lalitha
  const currentDate = moment();
  const startOfDay = moment(currentDate).startOf("day");
  const tenAM = startOfDay.clone().hour(10);
  const [isLoading, setIsLoading] = useState(false);
  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [error, setError] = useState({});
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [ClearAllFilterBut, setClearAllFilterBut] = useState(false);
  const [csvListData, setCsvListData] = useState([]);

  const [isOpen, setIsOpen] = useState({
    status: false,
    time: false,
    startDate: false,
    schedule: false,
    category: false,
    medium: false,
    classMode: false,
    location: false,
    created_on: false,
  });

  const [formData, setFormData] = useState({
    total_no_student_enrolled: 0,
    total_no_student_present: 0,
    total_no_student_absent: 0,
    male_stud_enrolled: 0,
    female_stud_enrolled: 0,
    transGender_stud_enrolled: 0,
    male_stud_present: 0,
    female_stud_present: 0,
    transGender_stud_present: 0,
    male_stud_absent: 0,
    female_stud_absent: 0,
    transGender_stud_absent: 0,
  });

  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const [appData_on, setAppData_on] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const [timedata, setTimedate] = useState({
    STime: "",
    TTime: "",
    name: "",
  });

  const [updateChipData, setUpdateChipData] = useState([]);

  const [district, setDistrict] = useState([]);
  const [examCenterOption, setExamCenterOption] = useState({});

  const [status, setStatus] = useState([]);
  const [modelExam, setModelExam] = useState([]);
  const [genderAll, setGenderAll] = useState([]);
  const [districtf, setDistrictf] = useState([]);
  const [examCenterf, setExamCenterfm] = useState([]);
  const [modelExamOption, setModelExamOption] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState({});
  const [selectedExamCenter, setSelectedExamCenter] = useState({});
  const [ModelExamValue, setModelExamValue] = useState({});
  const [centerDistrict, setCenterDistrict] = useState({
    centerD: null,
  });
  // const [filterClose, setFilterClose] = useState({
  //   maleGender: true,
  //   FemaleGender: true,
  //   TransGender: true,
  // });
  const [clearFilterDisabled, setClearFilterDisabled] = useState({
    districtsec: false,
    gendersec: false,
    modelexamsec: false,
  });
  const [genderOption, setGenderOption] = useState([]);
  const [location, setLocation] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [appOn_on, setAppOn_on] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [time, setTime] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [classModes, setClassModes] = useState([]);
  const [mediums, setMedium] = useState([]);

  let filterGender = [];
  let filterModelExam = [];
  let filterDistrictCenter = [];
  let filterCenterDistrict = [];
  let filterSearch = [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.activeTab === 2) {
      ModelexamDropdown(props.batch_id);
    }
  }, [props.activeTab]);

  useEffect(() => {
    filterModelExam = modelExam;
  }, [modelExam]);

  useEffect(() => {
    filterGender = genderAll;
  }, [genderAll]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  // useEffect(() => {
  //   if (props.activeTab === 2) {
  //     loadListdata();
  //     console.log("this load data call from activetab filter");
  //   }
  // }, [props.activeTab]);

  useEffect(() => {
    if (props.activeTab === 2) {
      loadListdata();
    }
  }, [updateChipData]);

  const ModelexamDropdown = (batchId) => {
    if (batchId) {
      AxiosInstance.post(`api/modelexam/list/byBatch`, {
        batch_id: batchId,
        type: 4, // default model exam type
        list_type: 1, // default as 1 for exam records tab
      })
        .then((res) => {
          if (res.data.status === true) {
            const modelExamOne = res.data.data
              // .filter((item) => item.exam_count === 1)
              .filter((item) => {
                if (item.paper_type === 0) {
                  return item.exam_count === 1;
                } else {
                  return item.paper_count === 1 && item.exam_count === 1;
                }
              })
              .map((el) => ({
                id: el.exam_detail_cstm_pk,
                event_pk: el.event_pk,
                name: el.exam_title,
                type: el.paper_type === 1 ? "paper_name" : "model_exam_name",
              }));
            filterModelExam = modelExamOne;
            setModelExam(modelExamOne);
            const bh = res.data.data
              .sort((a, b) => a.exam_count - b.exam_count)
              .map((item, index) => ({
                value: item.exam_detail_cstm_pk,
                label: item.exam_title,
                event_pk: item.event_pk,
                exam_title: item.exam_title,
                exam_count: item.exam_count,
                paper_count: item.paper_count,
                paper_type: item.paper_type,
                exam_detail_paper_pk: item.exam_detail_paper_pk,
                exam_detail_cstm_pk: item.exam_detail_cstm_pk,
              }));
            setModelExamOption(bh);
            const examValue = res.data.data
              // .filter((item) => item.exam_count === 1)
              .filter((item) => {
                if (item.paper_type === 0) {
                  return item.exam_count === 1;
                } else {
                  return item.paper_count === 1 && item.exam_count === 1;
                }
              })
              .map((els) => ({
                value:
                  els.paper_type === 1
                    ? els.exam_detail_paper_pk
                    : els.exam_detail_cstm_pk,
                paper_type: els.paper_type,
                label: els.exam_title,
                event_pk: els.event_pk,
                id: els.exam_detail_cstm_pk,
                name: els.exam_title,
              }));
            DistrictData(props.batch_id, examValue[0].id);
            setModelExamValue(examValue[0]);

            loadChipdata();
          } else {
            setModelExamOption([]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  function updateStateData() {
    filterModelExam = modelExam;
    filterGender = genderAll;
    filterDistrictCenter = districtf;
    filterCenterDistrict = examCenterf;

    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  function DistrictData(BID, EDID) {
    AxiosInstance.post("api/modelexam/district", {
      batch_id: BID,
      exam_details_id: [EDID],
    })
      .then((res) => {
        const districtsss = res.data.data.map((item, index) => ({
          value: item.exam_center_district,
          district_id: item.district_id,
          label: item.exam_center_district,
          district_code: item.exam_district_code,
        }));
        setDistrict(districtsss);
      })
      .catch((error) => {
        return error;
      });
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleExamChange = (option, names) => {
    if (option) {
      const OptionSelected = {
        value:
          option.paper_type === 1
            ? option.exam_detail_paper_pk
            : option.exam_detail_cstm_pk,
        paper_type: option.paper_type,
        label: option.exam_title,
        event_pk: option.event_pk,
        id: option.exam_detail_cstm_pk,
        name: option.exam_title,
      };
      setModelExamValue(OptionSelected);
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        modelexamsec: true,
      }));
      setDistrict([]);
      DistrictData(props.batch_id, option.exam_detail_cstm_pk);
    } else {
      setModelExamValue({});
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        modelexamsec: false,
      }));
      setDistrict([]);
    }
  };

  const handleExamRecordsFilter = (event, dname) => {
    const { name, value, checked } = event.target;
    if (dname === "centerD") {
      if (checked) {
        setCenterDistrict((prevState) => ({
          ...prevState,
          [dname]: value,
        }));
        setError((prevState) => ({
          ...prevState,
          ["exam_center_name"]: "",
        }));
        setSelectedDistrict({});
        setDistrictf([]);
        setExamCenterfm([]);
        setSelectedExamCenter({});
      } else {
        setCenterDistrict((prevState) => ({
          ...prevState,
          [dname]: "",
        }));
        setSelectedDistrict({});
        setDistrictf([]);
        setExamCenterfm([]);
        setSelectedExamCenter({});
      }
    } else if (dname === "gender") {
      if (checked) {
        setGenderOption([...genderOption, value]);
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          gendersec: true,
        }));
      } else {
        setGenderOption(genderOption.filter((item) => item !== value));
        if (genderOption.length === 1) {
          setClearFilterDisabled((prevFields) => ({
            ...prevFields,
            gendersec: false,
          }));
        }
      }
    }
  };

  const handleExamDistrict = (option, dnames) => {
    if (dnames === "district_name") {
      if (option) {
        const OptionSelectedDis = {
          value: option.value,
          district_id: option.district_id,
          label: option.label,
          district_code: option.district_code,
        };
        setSelectedDistrict(OptionSelectedDis);
        examCenterDistrict(option.district_id);
        setSelectedExamCenter({});
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          districtsec: true,
        }));
        setError((prevState) => ({
          ...prevState,
          ["district_name"]: "",
        }));
      } else {
        setSelectedDistrict({});
        setSelectedExamCenter({});
        setDistrictf([]);
        setExamCenterfm([]);
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          districtsec: false,
        }));
      }
    } else if (dnames === "exam_center_name") {
      if (option) {
        if (Object.keys(selectedDistrict).length > 0) {
          const OptionSelectedCenter = {
            value: option.value,
            label: option.label,
            district_id: option.district_id,
          };
          setSelectedExamCenter(OptionSelectedCenter);
          setError((prevState) => ({
            ...prevState,
            ["exam_center_name"]: "",
            ["district_name"]: "",
          }));

          setClearFilterDisabled((prevFields) => ({
            ...prevFields,
            districtsec: true,
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            ["exam_center_name"]: "Please select district",
          }));
          setSelectedExamCenter({});
          setClearFilterDisabled((prevFields) => ({
            ...prevFields,
            districtsec: false,
          }));
        }
      } else {
        setSelectedDistrict({});
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          districtsec: false,
        }));
      }
    }
  };

  const handleClearSection = (cname) => {
    if (cname === "gender") {
      setGenderOption([]);
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        gendersec: false,
      }));
      setClearAllFilterBut(true);
    } else if (cname === "centerD") {
      setCenterDistrict({
        centerD: null,
      });
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        districtsec: false,
      }));
      setSelectedDistrict({});
      setSelectedExamCenter({});
      setExamCenterOption([]);
      setExamCenterfm([]);
      setDistrictf([]);
      setClearAllFilterBut(true);
    }
  };

  const examCenterDistrict = (DistrictID) => {
    if (DistrictID) {
      AxiosInstance.post("api/modelexam/examCenter/byDistrict", {
        batch_id: props.batch_id,
        type: 4,
        district_id: DistrictID,
      })
        .then((res) => {
          if (res.data.status === true) {
            const exOption = res.data.data.map((item, index) => ({
              value: item.exam_center_cstm_pk,
              label: item.exam_center_name,
              district_id: item.district_id,
              exam_detail_id: item.exam_detail_id,
            }));
            setExamCenterOption(exOption);
          } else {
            setExamCenterOption({});
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };
  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };

  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      // Create a new KeyboardEvent
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        keyCode: 13,
        which: 13,
        code: "Enter",
        bubbles: true,
      });

      // Dispatch the event
      txtBSearch.current.dispatchEvent(event);
    }
  };

  const OpenFilterModel = () => {
    setOpenFilterModal(true);
  };
  const handleFilterClose = () => {
    setOpenFilterModal(false);
    const examValuess = modelExam.map((els) => ({
      value: els.id,
      label: els.name,
      event_pk: els.event_pk,
      id: els.id,
      name: els.name,
    }));
    setModelExamValue(examValuess[0]);

    if (updateChipData.length > 0) {
      const newGenderOption = [];
      clearState();
      updateChipData.forEach((el) => {
        if (el.type === "gender") {
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            gendersec: true,
          }));
          newGenderOption.push(el.name);
        } else if (el.type === "district") {
          setSelectedDistrict({
            value: el.name,
            district_id: el.id,
            label: el.name,
            district_code: el.district_code,
          });
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            districtsec: true,
          }));
          setCenterDistrict((prevState) => ({
            ...prevState,
            centerD: "0",
          }));
        } else if (el.type === "exam_center") {
          setSelectedExamCenter({
            value: el.id,
            district_id: el.district_id,
            label: el.name,
          });
          examCenterDistrict(el.district_id);
          setCenterDistrict((prevState) => ({
            ...prevState,
            centerD: "1",
          }));
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            districtsec: true,
          }));
        } else {
          clearState();
        }
      });
      setGenderOption(newGenderOption);
    }
  };

  const clearState = () => {
    setCenterDistrict({
      centerD: null,
    });
    setGenderOption([]);
    setDistrictf([]);
    setExamCenterfm([]);
    setSelectedDistrict({});
    setSelectedExamCenter({});
  };

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  const BoldTypography = styled(Typography)({
    textAlign: "left",
    width: "max-content",
    display: "contents",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    fontFamily: "Roboto",
    letterSpacing: "0px",
    color: "#041134",
    opacity: 1,
  });

  const BoldTypographyText = styled(Typography)({
    textAlign: "right",
    width: "max-content",
    display: "contents",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    fontFamily: "Roboto",
    letterSpacing: "0px",
    color: "#041134",
    opacity: 0.5,
  });

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setGenderAll([]);
    setSelectedDistrict({});
    setSelectedExamCenter({});
    setCenterDistrict({
      centerD: null,
    });
    // setFilterClose({
    //   maleGender: true,
    //   FemaleGender: true,
    //   TransGender: true,
    // });
    setClearFilterDisabled({
      districtsec: false,
      gendersec: false,
    });
    setDistrictf([]);
    setGenderOption([]);
    setExamCenterfm([]);
    setOpenFilterModal(false);
    setUpdateListData(listData);
    setUpdateChipData(
      updateChipData.filter(
        (item) => item.type === "model_exam_name" || item.type === "paper_name"
      )
    );
    setExamCenterOption([]);
  };

  const validateExamCenter = (district, examcenter) => {
    const errorx = {};
    if (
      Object.keys(district).length > 0 &&
      Object.keys(selectedExamCenter).length === 0
    ) {
      errorx.exam_center_name = "Please choose exam center";
    } else if (
      Object.keys(district).length === 0 &&
      Object.keys(selectedExamCenter).length === 0
    ) {
      errorx.district_name = "Please choose district";
      errorx.exam_center_name = "Please choose exam center";
    }
    return errorx;
  };
  // filter apply button
  const handleApplyFilterAll = () => {
    if (
      (centerDistrict.centerD === "1" &&
        Object.keys(selectedDistrict).length > 0 &&
        Object.keys(selectedExamCenter).length === 0) ||
      (centerDistrict.centerD === "1" &&
        Object.keys(selectedDistrict).length === 0 &&
        Object.keys(selectedExamCenter).length === 0)
    ) {
      const errorx = validateExamCenter(selectedDistrict, selectedExamCenter);
      if (Object.keys(errorx).length === 0) {
        applyFilter();
      } else {
        setError(errorx);
      }
    } else {
      applyFilter();
    }
  };

  const applyFilter = () => {
    updateStateData();
    const modelExamOne = [
      {
        id: ModelExamValue.id,
        event_pk: ModelExamValue.event_pk,
        name: ModelExamValue.name,
        type:
          ModelExamValue.paper_type === 1 ? "paper_name" : "model_exam_name",
      },
    ];
    filterModelExam = modelExamOne;
    setModelExam(modelExamOne);

    const genderfil = genderOption.map((el) => ({
      id: el,
      name: el,
      type: "gender",
    }));
    filterGender = genderfil;
    setGenderAll(genderfil);

    if (Object.keys(selectedDistrict).length > 0) {
      const districtFil = [
        {
          id: selectedDistrict.district_id,
          name: selectedDistrict.value,
          type: "district",
          district_code: selectedDistrict.district_code,
        },
      ];
      filterDistrictCenter = districtFil;
      setDistrictf(districtFil);
    } else {
      setCenterDistrict((prevState) => ({
        ...prevState,
        centerD: null,
      }));
    }

    if (Object.keys(selectedExamCenter).length > 0) {
      const examcenterDF = [
        {
          id: selectedExamCenter.value,
          name: selectedExamCenter.label,
          type: "exam_center",
          district_id: selectedExamCenter.district_id,
        },
      ];
      filterCenterDistrict = examcenterDF;
      setExamCenterfm(examcenterDF);
    }

    loadChipdata();
    setOpenFilterModal(false);
    setClearAllFilterBut(false);
  };

  // load table data

  function loadListdata() {
    let tempModelExam = [];
    let tempModelEventPk = [];
    let tempModelExamNum = [];
    let tempGender = [];
    let tempDistrict = [];
    let tempExamCenter = [];
    let tempModelEventPkNum = [];
    modelExam.map((obj) => {
      tempModelExam.push(obj.id);
    });
    modelExam.map((obj) => {
      tempModelEventPk.push(obj.event_pk);
    });
    genderAll.map((obj) => {
      tempGender.push(obj.name);
    });
    districtf.map((obj) => {
      tempDistrict.push(obj.id);
    });
    examCenterf.map((obj) => {
      tempExamCenter.push(obj.id);
    });
    tempModelExamNum = tempModelExam.map(Number);
    tempModelEventPkNum = tempModelEventPk.map(Number);
    let statusFilter = {
      batch_id: props.batch_id,
      type: 4,
      page_size: "",
      page_no: "",
      exam_detail_id: modelExam.length > 0 ? tempModelExamNum[0] : "",
      event_pk: modelExam.length > 0 ? tempModelEventPkNum[0] : "",
      district_id:
        Object.keys(selectedDistrict).length > 0 ? tempDistrict[0] : "",
      exam_center_cstm_pk: examCenterf.length > 0 ? tempExamCenter[0] : "",
      gender: genderAll.length > 0 ? tempGender : [],
      search: searchTerm.length > 0 ? searchTerm : "",
    };
    setIsLoading(true);
    AxiosInstance.post("api/batch/admList/byExam", statusFilter)
      .then((res) => {
        if (res.data.status === true) {
          setIsLoading(false);
          const overviewData = res.data.data.overview;
          setFormData({
            total_no_student_enrolled:
              overviewData.no_of_stud > 0 ? overviewData.no_of_stud : 0,
            total_no_student_present:
              overviewData.present > 0 ? overviewData.present : 0,
            total_no_student_absent:
              overviewData.absent > 0 ? overviewData.absent : 0,

            male_stud_enrolled:
              overviewData.male_count > 0 ? overviewData.male_count : 0,
            female_stud_enrolled:
              overviewData.female_count > 0 ? overviewData.female_count : 0,
            transGender_stud_enrolled:
              overviewData.transgender_count > 0
                ? overviewData.transgender_count
                : 0,
            male_stud_present:
              overviewData.male_present_count > 0
                ? overviewData.male_present_count
                : 0,
            female_stud_present:
              overviewData.female_present_count > 0
                ? overviewData.female_present_count
                : 0,
            transGender_stud_present:
              overviewData.transgender_present_count > 0
                ? overviewData.transgender_present_count
                : 0,
            male_stud_absent:
              overviewData.male_absent_count > 0
                ? overviewData.male_absent_count
                : 0,
            female_stud_absent:
              overviewData.female_absent_count > 0
                ? overviewData.female_absent_count
                : 0,
            transGender_stud_absent:
              overviewData.transgender_absent_count > 0
                ? overviewData.transgender_absent_count
                : 0,
          });
          const fiata = res.data.data.stud_data;
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          setUpdateListData(serialNumberAddedData);
          // const uniqueDistricts = new Set();
          // const districtData = fiata
          //   .filter((esssl) => {
          //     // Check if the district name is already in the Set
          //     if (uniqueDistricts.has(esssl.exam_district)) {
          //       return false; // Skip this element
          //     } else {
          //       // Add the district name to the Set
          //       uniqueDistricts.add(esssl.exam_district);
          //       return true; // Include this element
          //     }
          //   })
          //   .map((esssl) => ({
          //     value: esssl.exam_district,
          //     district_id: esssl.district_id,
          //     label: esssl.exam_district,
          //     district_code: esssl.district_code,
          //   }));
          // setDistrict((prevState) => {
          //   if (prevState.length === districtData.length) {
          //     return districtData;
          //   } else if (prevState.length < districtData.length) {
          //     return districtData;
          //   } else if (prevState.length === 0) {
          //     return districtData;
          //   } else {
          //     return prevState;
          //   }
          // });
          const csvData = fiata.map((el) => {
            return {
              "Full Name": el.fullname,
              "Admission ID": el.admission_id,
              "Phone Number": el.phone_no,
              Email: el.email,
              Gender: el.gender,
              "Exam District": el.exam_district,
              Attendance: el.attendance_status === 1 ? "Present" : "Absent",
              "Model Exam Name": el.model_exam_name,
              "Exam Center": el.aiasa_exam_center,
              "Total Marks": el.tot_marks,
              "Obtained Marks": el.marks_obtained,
              "Class Rank": el.classrank,
            };
          });
          setCsvListData(csvData);
        } else if (res.data.status === false) {
          setUpdateListData([]);
          setIsLoading(false);
          setFormData({
            total_no_student_enrolled: 0,
            total_no_student_present: 0,
            total_no_student_absent: 0,
            male_stud_enrolled: 0,
            female_stud_enrolled: 0,
            transGender_stud_enrolled: 0,
            male_stud_present: 0,
            female_stud_present: 0,
            transGender_stud_present: 0,
            male_stud_absent: 0,
            female_stud_absent: 0,
            transGender_stud_absent: 0,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setUpdateListData([]);
        return error;
      });
  }

  // chip data load

  function loadChipdata() {
    let tempArr = [];
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterModelExam.map((obj) => {
      tempArr.push(obj);
    });
    filterGender.map((obj) => {
      tempArr.push(obj);
    });
    filterDistrictCenter.map((obj) => {
      tempArr.push(obj);
    });
    filterCenterDistrict.map((obj) => {
      tempArr.push(obj);
    });

    setUpdateChipData(tempArr);
  }

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();

    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "gender") {
      // if (chipToDelete.id === "Male") {
      //   setFilterClose((prevState) => ({
      //     maleGender:
      //       prevState.TransGender === true || prevState.FemaleGender === true
      //         ? false
      //         : true,
      //     TransGender:
      //       prevState.maleGender === false ||
      //       prevState.FemaleGender === false ||
      //       prevState.TransGender === true
      //         ? true
      //         : false,
      //     FemaleGender:
      //       prevState.maleGender === false ||
      //       prevState.TransGender === false ||
      //       prevState.FemaleGender === true
      //         ? true
      //         : false,
      //   }));
      // }
      // if (chipToDelete.id === "Female") {
      //   setFilterClose((prevState) => ({
      //     FemaleGender:
      //       prevState.TransGender === true || prevState.maleGender === true
      //         ? false
      //         : true,
      //     TransGender:
      //       prevState.FemaleGender === false ||
      //       prevState.maleGender === false ||
      //       prevState.TransGender === true
      //         ? true
      //         : false,
      //     maleGender:
      //       prevState.maleGender === true ||
      //       prevState.TransGender === false ||
      //       prevState.FemaleGender === false
      //         ? true
      //         : false,
      //   }));
      // }
      // if (chipToDelete.id === "Transgender") {
      //   setFilterClose((prevState) => ({
      //     TransGender:
      //       prevState.FemaleGender === true || prevState.maleGender === true
      //         ? false
      //         : true,
      //     FemaleGender:
      //       prevState.TransGender === false ||
      //       prevState.maleGender === false ||
      //       prevState.FemaleGender === true
      //         ? true
      //         : false,
      //     maleGender:
      //       prevState.TransGender === false ||
      //       prevState.FemaleGender === false ||
      //       prevState.maleGender === true
      //         ? true
      //         : false,
      //   }));
      // }
      setGenderAll(genderAll.filter((item) => item.id !== chipToDelete.id));
      filterGender = genderAll.filter((item) => item.id !== chipToDelete.id);
      setGenderOption(genderOption.filter((item) => item !== chipToDelete.id));
      setClearFilterDisabled((prevState) => ({
        ...prevState,
        gendersec: genderOption.length > 0 ? true : false,
      }));
    } else if (chipToDelete.type === "district") {
      setDistrictf(districtf.filter((item) => item.id !== chipToDelete.id));
      filterDistrictCenter = districtf.filter(
        (item) => item.id !== chipToDelete.id
      );
      setCenterDistrict({ centerD: null });
      setSelectedDistrict({});
      // setFilterClose((prevState) => ({
      //   maleGender: true,
      //   TransGender: true,
      //   FemaleGender: true,
      // }));
      setExamCenterfm([]);
      filterCenterDistrict = [];

      setSelectedExamCenter({});
      setClearFilterDisabled((prevState) => ({
        ...prevState,
        districtsec: false,
      }));
    } else if (chipToDelete.type === "exam_center") {
      setExamCenterfm(
        examCenterf.filter((item) => item.id !== chipToDelete.id)
      );
      filterCenterDistrict = examCenterf.filter(
        (item) => item.id !== chipToDelete.id
      );
      setCenterDistrict((prevState) => ({
        ...prevState,
        centerD: "0",
      }));
      setSelectedExamCenter({});
      if (Object.keys(selectedDistrict).length === 0) {
        setClearFilterDisabled((prevState) => ({
          ...prevState,
          districtsec: false,
        }));
      }
    }

    loadChipdata();
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const handleReadView = (id, type, useridd) => {
    navigate(
      `/TransactionHistory/ViewAdmission/${useridd}/${props.batch_id}/${id}/${type}/1`
    );
  };
  const handleAllClearFilter = () => {
    setGenderAll([]);
    setSelectedDistrict({});
    setClearAllFilterBut(true);
    setSelectedExamCenter({});
    setDistrictf([]);
    setGenderOption([]);
    setCenterDistrict({
      centerD: null,
    });
    // setFilterClose({
    //   maleGender: true,
    //   FemaleGender: true,
    //   TransGender: true,
    // });
    setClearFilterDisabled({
      districtsec: false,
      gendersec: false,
    });
    setExamCenterfm([]);
    // setOpenFilterModal(false);
    // loadListdata();
    // setUpdateChipData(
    //   updateChipData.filter((item) => item.type === "model_exam_name")
    // );
    setExamCenterOption([]);
  };

  // console.log(district, "districtdistrictdistrict");

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div
      className={
        props.activeTab === 2 ? "tab-content mt-4" : "tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      <div className="row ">
        {openFilterModal && (
          <ExamAdvancedFilterModel
            examCenterOption={examCenterOption}
            handleClearSection={handleClearSection}
            selectedExamCenter={selectedExamCenter}
            selectedDistrict={selectedDistrict}
            clearFilterDisabled={clearFilterDisabled}
            handleExamDistrict={handleExamDistrict}
            district={district}
            handleApplyFilterAll={handleApplyFilterAll}
            genderOption={genderOption}
            centerDistrict={centerDistrict}
            handleExamRecordsFilter={handleExamRecordsFilter}
            modelExamOption={modelExamOption}
            handleExamChange={handleExamChange}
            ModelExamValue={ModelExamValue}
            error={error}
            ClearAllFilterBut={ClearAllFilterBut}
            show={openFilterModal}
            handleFilterClose={handleFilterClose}
            handleAllClearFilter={handleAllClearFilter}
          />
        )}
        <div className="col-lg-4 col-md-12">
          <div class="input-group">
            <input
              ref={txtBSearch}
              type="text"
              class="Inputs_searchList"
              id="SearchInput"
              name="SearchInput"
              placeholder="Search by Name, ID, Phone Number or Email"
              onKeyDown={handleSearch}
            />
            <span
              class="input-group-text"
              onClick={handleSearchFocus}
              id="SearchInput"
            >
              <span className="material-icons" onClick={handleSearchFocus}>
                search
              </span>
            </span>
          </div>
        </div>
        <div className="col-lg-2 col-md-2"></div>
        <div className="col-lg-6  dflex col-md-12 justify-content-md-between justify-content-lg-end">
          <button
            type="button"
            className="ListViewBtn me-3"
            onClick={() => OpenFilterModel()}
          >
            <span className="material-icons me-2 fs-4 align-middle text-white">
              filter_list
            </span>
            Advanced Filters
          </button>
          <button type="button" className="ListViewBtn">
            <span className="material-icons me-2 fs-4 align-middle text-white">
              file_download
            </span>
            <CSVLink data={csvListData} filename={"ExamRecords.csv"}>
              Download as CSV
            </CSVLink>
          </button>
        </div>
      </div>

      {updateChipData.length > 0 ? (
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {updateChipData.map((data, index) => {
                // console.log(data, " - data - ", index);
                let icon;
                let keyss = "";
                let keyWords = "";
                if (data.type === "model_exam_name") {
                  keyss = "Model Exam Name";
                  keyWords = " " + data.name;
                }
                if (data.type === "paper_name") {
                  keyss = "Paper Name";
                  keyWords = " " + data.name;
                } else if (data.type === "gender") {
                  keyss = "Gender ";
                  keyWords = " " + data.name;
                } else if (data.type === "district") {
                  keyss = "District ";
                  keyWords = " " + data.name;
                } else if (data.type === "exam_center") {
                  keyss = "Exam Center ";
                  if (data.name.length > 10) {
                    keyWords = data.name.substring(0, 10) + "...";
                  } else {
                    keyWords = data.name;
                  }
                } else if (data.type === "search") {
                  keyss = "" + "Searched Keyword";
                  keyWords = " " + data.name;
                }
                return (
                  <ListItem key={data}>
                    <Chip
                      icon={icon}
                      label={
                        <>
                          <span className="BoldTy">{keyss}</span>
                          <span className="BoldTyText">{keyWords}</span>
                        </>
                      }
                      onDelete={handleDelete(data, index)}
                    />
                  </ListItem>
                );
              })}

              <ListItem>
                <a
                  className={`allclear ${
                    updateChipData.length === 1 ? "DisabledFilter" : ""
                  }`}
                  href="#"
                  onClick={
                    updateChipData.length === 1
                      ? () => {}
                      : () => handleAllClear()
                  }
                >
                  Clear All Filters
                </a>
              </ListItem>
            </Paper>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <section className="StepOne">
        <div className="row box">
          <div className="col-md-12">
            <p className="StepTitle">Exam Records Overview</p>

            <div className="row mb-3">
              {/* {filterClose.maleGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Male Students Enrolled
                  </label>
                  <span className="ReviewLabel">
                    {formData.male_stud_enrolled || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              {/* {filterClose.FemaleGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Female Students Enrolled
                  </label>
                  <span className="ReviewLabel">
                    {formData.female_stud_enrolled || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              {/* {filterClose.TransGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Transgender Students Enrolled
                  </label>
                  <span className="ReviewLabel">
                    {formData.transGender_stud_enrolled || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}

              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Total Students Enrolled
                  </label>
                  <span className="ReviewLabel">
                    {formData.total_no_student_enrolled || "_"}
                  </span>
                </div>
              </div>
              {/* {filterClose.maleGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Male Students Present
                  </label>
                  <span className="ReviewLabel">
                    {formData.male_stud_present || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              {/* {filterClose.FemaleGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Female Students Present
                  </label>
                  <span className="ReviewLabel">
                    {formData.female_stud_present || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              {/* {filterClose.TransGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Transgender Students Present
                  </label>
                  <span className="ReviewLabel">
                    {formData.transGender_stud_present || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}

              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Total Students Present
                  </label>
                  <span className="ReviewLabel">
                    {formData.total_no_student_present === 0
                      ? "_"
                      : `${formData.total_no_student_present} (${(
                          (formData.total_no_student_present /
                            formData.total_no_student_enrolled) *
                          100
                        ).toFixed(2)}%)` || "_"}
                  </span>
                </div>
              </div>
              {/* {filterClose.maleGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Male Students Absent
                  </label>
                  <span className="ReviewLabel">
                    {formData.male_stud_absent || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              {/* {filterClose.FemaleGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Female Students Absent
                  </label>
                  <span className="ReviewLabel">
                    {formData.female_stud_absent || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              {/* {filterClose.TransGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Transgender Students Absent
                  </label>
                  <span className="ReviewLabel">
                    {formData.transGender_stud_absent || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}

              <div className="col-lg-3 col-md-6">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Total Students Absent
                  </label>
                  <span className="ReviewLabel">
                    {formData.total_no_student_absent === 0
                      ? "_"
                      : `${formData.total_no_student_absent} (${(
                          (formData.total_no_student_absent /
                            formData.total_no_student_enrolled) *
                          100
                        ).toFixed(2)}%)` || "_"}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="row mt-4">
        <div className="col-md-12 table-responsive">
          <table className="table" id="example">
            <thead>
              <tr>
                <th id="th" style={{ width: "5% !important" }}>
                  {" "}
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    S.No
                  </span>
                  <span
                    onClick={(e) => handleSort(e, "serialNumber")}
                    class="material-icons align-middle"
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    onClick={(e) => handleSort(e, "fullname")}
                    className="text_th"
                  >
                    Name
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "fullname")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "admission_id")}
                  >
                    Admission ID
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "admission_id")}
                  >
                    {" "}
                    import_export{" "}
                  </span>{" "}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    Phone Number
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "email")}
                  >
                    Email ID
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "email")}
                  >
                    import_export
                  </span>{" "}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "gender")}
                  >
                    Gender
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "gender")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "exam_district")}
                  >
                    Preferred Exam District
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "exam_district")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "attendance_status")}
                  >
                    {" "}
                    Attendance
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "attendance_status")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "tot_marks")}
                  >
                    Total Marks
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "tot_marks")}
                  >
                    import_export
                  </span>{" "}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "marks_obtained")}
                  >
                    Obtained Marks
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "marks_obtained")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "classrank")}
                  >
                    Rank
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "classrank")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span className="text_th">Action</span>{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td
                    colspan="15"
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid #dee2e6",
                    }}
                  >
                    No record to display
                  </td>
                </tr>
              ) : (
                currentData.map((course, index) => (
                  <tr key={index} title={course.serialNumber}>
                    <td id="td">{course.serialNumber}</td>
                    <td id="td"> {course.fullname}</td>
                    <td id="td">{course.admission_id}</td>
                    <td id="td"> {course.phone_no}</td>
                    <td id="td">{course.email}</td>
                    <td id="td">{course.gender}</td>
                    <td id="td">{course.exam_district}</td>
                    <td id="td">
                      {course.attendance_status === 1 ? (
                        <span className="PresentSta">Present</span>
                      ) : (
                        <span className="AbsentSta">Absent</span>
                      )}
                    </td>
                    <td id="td">
                      {course.tot_marks
                        ? course.tot_marks === 0
                          ? "_"
                          : course.tot_marks
                        : "_"}
                    </td>
                    <td id="td">
                      {course.marks_obtained !== null
                        ? course.marks_obtained === 0
                          ? "A"
                          : course.marks_obtained
                        : "-"}
                    </td>
                    <td id="td">{course.classrank}</td>
                    <td id="td">
                      <button
                        className="button"
                        onClick={() =>
                          handleReadView(course.pk, 4, course.user_id)
                        }
                      >
                        <span class="material-icons">visibility</span>
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="pag w-100 dflexcenter">
            <span className="ipage">Items Per Page</span>
            <select
              name="pageCount"
              id="pageCount"
              defaultValue={rowsPerPage}
              onChange={handlePageCount}
              className="ms-2"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pagenumber ms-2">
              {" "}
              {updateListData.length > 0
                ? currentPage * rowsPerPage + 1 - rowsPerPage
                : 0}{" "}
              -{" "}
              {updateListData.length < currentPage * rowsPerPage
                ? updateListData.length
                : currentPage * rowsPerPage}{" "}
              of {updateListData.length}
            </span>
            <button
              className="prev_button ms-2 me-2"
              onClick={(e) => handlePrev(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span class="material-icons">chevron_left</span>
            </button>
            <button
              className="prev_button"
              onClick={() => handleNext(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <span class="material-icons">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamRecords;
