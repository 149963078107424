import { validateNtFocus } from "./validateNTfocus";

export const validateProfiles = (
  data,
  certificates,
  edu,
  step,
  AdmissionRef_validation,
  techQualification,
  setTechQualification
) => {
  const errors = {};
  if (step === 1 && !data.FirstName) {
    errors.FirstName = "Please Enter your FirstName";
  }
  if (step === 1 && !data.LastName) {
    errors.LastName = "Please Enter your LastName";
  }
  if (step === 1 && !data.Fathername) {
    errors.Fathername = "Please Enter your Fathername";
  }
  if (step === 1 && !data.DOB) {
    errors.DOB = "Please Enter your DOB";
  }
  if (step === 1 && !data.age) {
    errors.age = "Please Enter your age";
  }
  if (step === 1 && !data.PlaceBirth) {
    errors.PlaceBirth = "Please Enter PlaceBirth";
  }
  if (step === 1 && !data.gender) {
    errors.gender = "Please choose gender";
  }
  if (step === 1 && !data.MaritalStatus) {
    errors.MaritalStatus = "Please choose Marital Status";
  }
  if (step === 1 && data.MaritalStatus === "Married" && !data.Spouse) {
    errors.Spouse = "Please Enter Name - Spouse";
  }
  if (step === 1 && data.MaritalStatus === "Married" && !data.Occupation) {
    errors.Occupation = "Please Enter Occupation";
  }
  if (step === 1 && !data.Mobile) {
    errors.Mobile = "Please Enter Mobile number";
  }
  if (step === 1 && data.Mobile && data.Mobile.length < 10) {
    errors.Mobile = "Please Enter 10 digit Mobile number";
  }
  if (step === 1 && data.Mobile && !/^\d+$/.test(data.Mobile)) {
    errors.Mobile = "Please Enter a valid Mobile Number";
  }
  if (
    step === 1 &&
    (!data.WhatsAppNumber || !/^\d+$/.test(data.WhatsAppNumber)) &&
    !data.ContactNumber
  ) {
    errors.WhatsAppNumber = "Please Enter WhatsApp Number";
  }
  if (
    step === 1 &&
    data.WhatsAppNumber &&
    data.WhatsAppNumber.length < 10 &&
    !data.ContactNumber
  ) {
    errors.WhatsAppNumber = "Please Enter 10 digit WhatsApp Number";
  }
  if (
    step === 1 &&
    data.WhatsAppNumber &&
    !/^\d+$/.test(data.WhatsAppNumber) &&
    !data.ContactNumber
  ) {
    errors.WhatsAppNumber = "Please Enter a valid WhatsApp number";
  }
  if (step === 1 && !data.emailaddress) {
    errors.emailaddress = "Please Enter email address";
  }
  if (
    step === 1 &&
    data.emailaddress &&
    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailaddress)
  ) {
    errors.emailaddress = "Please Enter a valid email address";
  }
  if (step === 1 && !data.Profilephoto) {
    errors.Profilephoto = "Please upload Profilephoto";
  }
  if (step === 1 && !data.aadharNo) {
    errors.aadharNo = "Please Enter Aadhar Number";
  }
  if (step === 1 && data.aadharNo && data.aadharNo.length < 12) {
    errors.aadharNo = " Aadhar No must be 12 digit";
  }
  if (step === 1 && data.aadharNo && data.aadharNo.length > 12) {
    errors.aadharNo = " Aadhar No must be 12 digit";
  }
  //  if ( step === 1 && data.aadharNo && !/^[2-9][0-9]{11}$/.test(data.aadharNo)) {
  //   errors.aadharNo = "Please Enter a valid Aadhar No";
  // }
  if (step === 1 && !data.aadharCardphoto) {
    errors.aadharCardphoto = "Please Upload Pdf/Image files format";
  }
  if (step === 1 && !data.panNo) {
    errors.panNo = "Please Enter PAN Number";
  }
  if (step === 1 && data.panNo && data.panNo.length < 10) {
    errors.panNo = " Pan  No must be 10 digit";
  }
  if (step === 1 && data.panNo && data.panNo.length > 10) {
    errors.panNo = " Pan  No must be 10 digit";
  }
  // The length of a PAN (Permanent Account Number) card in India is 10 characters.
  // AIASA-000117  v1 issues solved by lalitha //ABCDE1234F - sample format for pan no
  if (
    step === 1 &&
    data.panNo &&
    !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(data.panNo)
  ) {
    errors.panNo = "Please Enter a valid PAN Card No";
  }
  if (step === 1 && !data.panPhoto) {
    errors.panPhoto = "Please Upload PAN in Pdf format";
  }
  if (step === 2 && !data.BuildingNumberPresent) {
    errors.BuildingNumberPresent = "Please Enter Building Number";
  }
  if (step === 2 && !data.StreetNamePresent) {
    errors.StreetNamePresent = "Please Enter Street Name";
  }
  if (step === 2 && !data.TalukPresent) {
    errors.TalukPresent = "Please Enter Taluk";
  }
  if (step === 2 && !data.PostPresent) {
    errors.PostPresent = "Please Enter Post";
  }
  if (step === 2 && !data.AreaPresent) {
    errors.AreaPresent = "Please Enter Area";
  }
  if (step === 2 && !data.PincodePresent) {
    errors.PincodePresent = "Please Enter Pincode";
  }
  if (step === 2 && !/^[1-9][0-9]{5}$/.test(data.PincodePresent)) {
    errors.PincodePresent = "Please Enter a valid Pincode";
  }
  if (step === 2 && !data.CityPresent) {
    errors.CityPresent = "Please Enter City";
  }
  if (
    step === 2 &&
    !data.BuildingNumberPermanent &&
    data.SamePresentAddress === false
  ) {
    errors.BuildingNumberPermanent = "Please Enter Building Number";
  }
  if (
    step === 2 &&
    !data.StreetNamePermanent &&
    data.SamePresentAddress === false
  ) {
    errors.StreetNamePermanent = "Please Enter Street Name";
  }
  if (step === 2 && !data.AreaPermanent && data.SamePresentAddress === false) {
    errors.AreaPermanent = "Please Enter Area";
  }
  if (step === 2 && !data.TalukPermanent && data.SamePresentAddress === false) {
    errors.TalukPermanent = "Please Enter Taluk";
  }
  if (step === 2 && !data.PostPermanent && data.SamePresentAddress === false) {
    errors.PostPermanent = "Please Enter Post";
  }
  if (
    step === 2 &&
    !data.PincodePermanent &&
    data.SamePresentAddress === false
  ) {
    errors.PincodePermanent = "Please Enter Pincode";
  }
  if (
    step === 2 &&
    !/^[1-9][0-9]{5}$/.test(data.PincodePermanent) &&
    data.SamePresentAddress === false
  ) {
    errors.PincodePermanent = "Please Enter a valid Pincode";
  }
  if (step === 2 && !data.CityPermanent && data.SamePresentAddress === false) {
    errors.CityPermanent = "Please Enter City";
  }
  //    if (step === 3 && techQualification.length > 0) {
  //   const updateTHQ = techQualification.map((OB) => {
  //     let updatedOB = { ...OB };
  //     console.log(OB,"OB");
  //     if (OB.name === "") {
  //       updatedOB.error_name = "Name is required";
  //     }
  //     if (OB.name !== "") {
  //       updatedOB.error_name = "";
  //     }
  //     if (OB.level === "") {
  //       updatedOB.error_level = "Level is required";
  //     }
  //     if (OB.level !== "") {
  //       updatedOB.error_level = "";

  //     }
  //     if (OB.lang === "") {
  //       updatedOB.error_edu_medium = "Lang is required";
  //     }
  //     if (OB.lang !== "") {
  //       updatedOB.error_edu_medium = "";
  //     }
  //     if (OB.kComputer === "") {
  //       updatedOB.error_kComputer = "Lang is required";
  //     }
  //     if (OB.kComputer !== "") {
  //       updatedOB.error_kComputer = "";
  //     }
  //     if (OB.Cnum === "") {
  //       updatedOB.error_certificate_no = "Certificate No is required";
  //     }
  //     if (OB.Cnum !== "") {
  //       updatedOB.error_certificate_no = "";
  //     }
  //     if (OB.year === "") {
  //       updatedOB.error_year_of_passing = "Year of passing is required";
  //     }
  //     if (OB.year !== "") {
  //       updatedOB.error_year_of_passing = "";
  //     }
  //     if (OB.grade === "") {
  //       updatedOB.error_grade = "Grade is required";
  //     }
  //     if (OB.grade !== "") {
  //       updatedOB.error_grade = "";
  //     }
  //     return updatedOB;
  //   });
  //   console.log(updateTHQ)
  //   setTechQualification(updateTHQ);
  // }
  if (step === 4 && !data.prevTeachexp) {
    errors.prevTeachexp = "please choose Teach experience";
  }
  if (step === 4 && !data.workingexp && data.prevTeachexp === "Yes") {
    errors.workingexp = "Please Enter working experience";
  }
  //   if (step === 4 && !data.teachexp && data.prevTeachexp === "Yes") {
  //   errors.teachexp = "Please Enter teach experience.";
  // }
  if (step === 4 && !data.working_ex_file && data.prevTeachexp === "Yes") {
    errors.working_ex_file = "Please Enter working experience upload file";
  }
  // if (step === 4 && !data.Teaching_ex_file && data.prevTeachexp === "Yes") {
  //   errors.Teaching_ex_file = "Please Enter teach experience upload file";
  // }
  if (step === 4 && !data.teachType) {
    errors.teachType = "please choose teach type";
  }
  if (step === 4 && !data.designation) {
    errors.designation = "please choose designation";
  }
  if (step === 4 && !data.DJoin) {
    errors.DJoin = "please Enter Date of join";
  }
  if (step === 4 && !data.location) {
    errors.location = "please choose location";
  }
  if (step === 4 && !data.VoiceOpinion) {
    errors.VoiceOpinion = "Please Enter Voice Opinion";
  }
  if (step === 3 && edu.length === 0) {
    errors.EducationQualification = "please choose educational";
  }
  if (step === 3 && !data.spq) {
    errors.spq = "please choose special qualification";
  }

  // AIASA-000119  v1 issues solved by lalitha

  for (let i = 0; i < certificates.length; i++) {
    const cert = certificates[i];
    if (step === 3 && edu.includes("UG")) {
      if (!cert.UGDegree && cert.name === "UG") {
        errors.UGDegree = "Degree is required";
      }
      if (!cert.UGMajor && cert.name === "UG") {
        errors.UGMajor = "Major is required";
      }
      if (
        cert.UGMajor &&
        cert.UGMajor === "Others" &&
        !cert.UGMajor_Other &&
        cert.name === "UG"
      ) {
        errors.UGMajor_Other = "Major Other field is required";
      }
      if (!cert.UGYearpassing && cert.name === "UG") {
        errors.UGYearpassing = "Please Enter Year of passing";
      }
      if (!cert.UGCertificateNumber && cert.name === "UG") {
        errors.UGCertificateNumber = "Certificate number is required";
      }
      if (
        cert.UGCertificateNumber &&
        !cert.UGCertificateNumber.trim() &&
        cert.name === "UG"
      ) {
        errors.UGCertificateNumber = "Certificate number is required";
      }
      if (
        cert.UGCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.UGCertificateNumber) &&
        cert.name === "UG"
      ) {
        errors.UGCertificateNumber =
          "Certificate number can only contain letters and numbers";
      }
      if (!cert.UGMarksPercentage && cert.name === "UG") {
        errors.UGMarksPercentage = "Marks percentage is required";
      }
      if (
        cert.UGMarksPercentage &&
        typeof cert.UGMarksPercentage == "string" &&
        parseInt(cert.UGMarksPercentage) > 100 &&
        cert.name === "UG"
      ) {
        errors.UGMarksPercentage = "MarksPercentage is must be less than 100%";
      }
      if (
        cert.UGMarksPercentage &&
        typeof cert.UGMarksPercentage == "string" &&
        parseInt(cert.UGMarksPercentage) < 45 &&
        cert.name === "UG"
      ) {
        errors.UGMarksPercentage =
          "MarksPercentage is must be greater than 45%";
      }
      if (!cert.UGClassName && cert.name === "UG") {
        errors.UGClassName = "class is required";
      }
      //   if (cert.UGClassName && !/^[A-Za-z\s]*$/.test(cert.UGClassName) && cert.name === "UG") {
      //   errors.UGClassName = "class can only contain letters and spaces";
      // }
      if (!cert.UGNameInstitute && cert.name === "UG") {
        errors.UGNameInstitute = "Name of the college / university is required";
      }
      if (
        cert.UGNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.UGNameInstitute) &&
        cert.name === "UG"
      ) {
        errors.UGNameInstitute =
          "Name of the college / university can only contain letters and spaces";
      }
      //   if (!cert.UG_Path && cert.name === "UG") {
      //   errors.UG_Path = "Please Upload UG cerficate";
      // }
    }
    if (step === 3 && edu.includes("PG")) {
      if (cert.PGDegree == "" && cert.name === "PG") {
        errors.PGDegree = "Degree is required";
      }
      if (!cert.PGMajor && cert.name === "PG") {
        errors.PGMajor = "Major is required";
      }
      if (
        cert.PGMajor &&
        cert.PGMajor === "Others" &&
        !cert.PGMajor_Other &&
        cert.name === "PG"
      ) {
        errors.PGMajor_Other = "Major Other field is required";
      }
      if (!cert.PGYearpassing && cert.name === "PG") {
        errors.PGYearpassing = "Please Enter Year of passing";
      }
      if (!cert.PGCertificateNumber && cert.name === "PG") {
        errors.PGCertificateNumber = "Certificate number is required";
      }
      if (
        cert.PGCertificateNumber &&
        !cert.PGCertificateNumber.trim() &&
        cert.name === "PG"
      ) {
        errors.PGCertificateNumber = "Certificate number is required";
      }
      if (
        cert.PGCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.PGCertificateNumber) &&
        cert.name === "PG"
      ) {
        errors.PGCertificateNumber =
          "Certificate number can only contain letters and numbers";
      }
      if (!cert.PGMarksPercentage && cert.name === "PG") {
        errors.PGMarksPercentage = "Marks percentage is required";
      }
      if (
        cert.PGMarksPercentage &&
        typeof cert.PGMarksPercentage === "string" &&
        parseInt(cert.PGMarksPercentage) > 100 &&
        cert.name === "PG"
      ) {
        errors.PGMarksPercentage = "MarksPercentage is must be less than 100%";
      }
      if (
        cert.PGMarksPercentage &&
        typeof cert.PGMarksPercentage === "string" &&
        parseInt(cert.PGMarksPercentage) < 45 &&
        cert.name === "PG"
      ) {
        errors.PGMarksPercentage =
          "MarksPercentage is must be greater than 45%";
      }
      if (!cert.PGClassName && cert.name === "PG") {
        errors.PGClassName = "class is required";
      }
      //  if (cert.PGClassName && !/^[A-Za-z\s]*$/.test(cert.PGClassName) && cert.name === "PG") {
      //   errors.PGClassName = "class can only contain letters and spaces";
      // }
      if (!cert.PGNameInstitute && cert.name === "PG") {
        errors.PGNameInstitute = "Name of the college / university is required";
      }
      if (
        cert.PGNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.PGNameInstitute) &&
        cert.name === "PG"
      ) {
        errors.PGNameInstitute =
          "Name of the college / university can only contain letters and spaces";
      }
      //  if (!cert.PG_Path && cert.name === "PG") {
      //   errors.PG_Path = "Please Upload PG cerficate";
      // }
    }

    if (step === 3 && edu.includes("Integrated PG")) {
      if (!cert.IPDegree && cert.name === "Integrated PG") {
        errors.IPDegree = "Degree is required";
      }
      if (!cert.IPMajor && cert.name === "Integrated PG") {
        errors.IPMajor = "Major is required";
      }
      if (
        cert.IPMajor &&
        cert.IPMajor === "Others" &&
        !cert.IPMajor_Other &&
        cert.name === "Integrated PG"
      ) {
        errors.IPMajor_Other = "Major Other field is required";
      }
      if (!cert.IPYearpassing && cert.name === "Integrated PG") {
        errors.IPYearpassing = "Please Enter Year of passing";
      }
      if (!cert.IPCertificateNumber && cert.name === "Integrated PG") {
        errors.IPCertificateNumber = "Certificate number is required";
      }
      if (
        cert.IPCertificateNumber &&
        !cert.IPCertificateNumber.trim() &&
        cert.name === "Integrated PG"
      ) {
        errors.IPCertificateNumber = "Certificate number is required";
      }
      if (
        cert.IPCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.IPCertificateNumber) &&
        cert.name === "Integrated PG"
      ) {
        errors.IPCertificateNumber =
          "Certificate number can only contain letters and numbers";
      }
      if (!cert.IPMarksPercentage && cert.name === "Integrated PG") {
        errors.IPMarksPercentage = "Marks percentage is required";
      }
      if (
        cert.IPMarksPercentage &&
        typeof cert.IPMarksPercentage === "string" &&
        parseInt(cert.IPMarksPercentage) > 100 &&
        cert.name === "Integrated PG"
      ) {
        errors.IPMarksPercentage = "MarksPercentage is must be less than 100%";
      }
      if (
        cert.IPMarksPercentage &&
        typeof cert.IPMarksPercentage === "string" &&
        parseInt(cert.IPMarksPercentage) < 45 &&
        cert.name === "Integrated PG"
      ) {
        errors.IPMarksPercentage =
          "MarksPercentage is must be greater than 45%";
      }
      if (!cert.IPClassName && cert.name === "Integrated PG") {
        errors.IPClassName = "Class is required";
      }
      //   if (cert.IPClassName && !/^[A-Za-z\s]*$/.test(cert.IPClassName) && cert.name === "Integrated PG") {
      //   errors.IPClassName = "class can only contain letters and spaces";
      // }
      if (!cert.IPNameInstitute && cert.name === "Integrated PG") {
        errors.IPNameInstitute = "Name of the college / university is required";
      }
      if (
        cert.IPNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.IPNameInstitute) &&
        cert.name === "Integrated PG"
      ) {
        errors.IPNameInstitute =
          "Name of the college / university can only contain letters and spaces";
      }
      //  if (!cert.IP_Path && cert.name === "Integrated PG") {
      //   errors.IP_Path = "Please Upload Integrated PG cerficate";
      // }
    }

    if (step === 3 && edu.includes("phd")) {
      if (!cert.PHDDegree && cert.name === "phd") {
        errors.PHDDegree = "Degree is required";
      }
      if (!cert.PHDMajor && cert.name === "phd") {
        errors.PHDMajor = "Major is required";
      }
      if (
        cert.PHDMajor &&
        cert.PHDMajor === "Others" &&
        !cert.PHDMajor_Other &&
        cert.name === "phd"
      ) {
        errors.PHDMajor_Other = "Major Other field is required";
      }
      if (!cert.PHDYearpassing && cert.name === "phd") {
        errors.PHDYearpassing = "Please Enter Year of passing";
      }
      if (!cert.PHDCertificateNumber && cert.name === "phd") {
        errors.PHDCertificateNumber = "Certificate number is required";
      }
      if (
        cert.PHDCertificateNumber &&
        !cert.PHDCertificateNumber.trim() &&
        cert.name === "phd"
      ) {
        errors.PHDCertificateNumber = "Certificate number is required";
      }
      if (
        cert.PHDCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.PHDCertificateNumber) &&
        cert.name === "phd"
      ) {
        errors.PHDCertificateNumber =
          "Certificate number can only contain letters and numbers";
      }
      if (!cert.PHDMarksPercentage && cert.name === "phd") {
        errors.PHDMarksPercentage = "Marks percentage is required";
      }
      if (
        cert.PHDMarksPercentage &&
        typeof cert.PHDMarksPercentage === "string" &&
        cert.PHDMarksPercentage.length > 5 &&
        cert.name === "phd"
      ) {
        errors.PHDMarksPercentage =
          "MarksPercentage is must be 5 digit only allowed";
      }
      if (!cert.PHDClassName && cert.name === "phd") {
        errors.PHDClassName = "class is required";
      }
      //   if (cert.PHDClassName && !/^[A-Za-z\s]*$/.test(cert.PHDClassName) && cert.name === "phd") {
      //   errors.PHDClassName = "class can only contain letters and spaces";
      // }
      if (!cert.PHDNameInstitute && cert.name === "phd") {
        errors.PHDNameInstitute =
          "Name of the college / university is required";
      }
      if (
        cert.PHDNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.PHDNameInstitute) &&
        cert.name === "phd"
      ) {
        errors.PHDNameInstitute =
          "Name of the college / university can only contain letters and spaces";
      }
      // if (!cert.PHD_Path && cert.name === "phd") {
      //   errors.PHD_Path = "Please Upload phd cerficate";
      // }
    }

    if (step === 3 && edu.includes("Diploma")) {
      if (!cert.DDegree && cert.name === "Diploma") {
        errors.DDegree = "Degree is required";
      }
      if (!cert.DMajor && cert.name === "Diploma") {
        errors.DMajor = "Major is required";
      }
      if (
        cert.DMajor &&
        cert.DMajor === "Others" &&
        !cert.DMajor_Other &&
        cert.name === "Diploma"
      ) {
        errors.DMajor_Other = "Major Other field is required";
      }
      if (!cert.DYearpassing && cert.name === "Diploma") {
        errors.DYearpassing = "Please Enter Year of passing";
      }
      if (!cert.DCertificateNumber && cert.name === "Diploma") {
        errors.DCertificateNumber = "Certificate number is required";
      }
      if (
        cert.DCertificateNumber &&
        !cert.DCertificateNumber.trim() &&
        cert.name === "Diploma"
      ) {
        errors.DCertificateNumber = "Certificate number is required";
      }
      if (
        cert.DCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.DCertificateNumber) &&
        cert.name === "Diploma"
      ) {
        errors.DCertificateNumber =
          "Certificate number can only contain letters and numbers";
      }
      if (!cert.DMarksPercentage && cert.name === "Diploma") {
        errors.DMarksPercentage = "Marks percentage is required";
      }
      if (
        cert.DMarksPercentage &&
        typeof cert.DMarksPercentage === "string" &&
        parseInt(cert.DMarksPercentage) > 100 &&
        cert.name === "Diploma"
      ) {
        errors.DMarksPercentage = "MarksPercentage is must be less than 100%";
      }
      if (
        cert.DMarksPercentage &&
        typeof cert.DMarksPercentage === "string" &&
        parseInt(cert.DMarksPercentage) < 45 &&
        cert.name === "Diploma"
      ) {
        errors.DMarksPercentage = "MarksPercentage is must be greater than 45%";
      }
      if (!cert.DClassName && cert.name === "Diploma") {
        errors.DClassName = "Class is required";
      }
      if (!cert.DNameInstitute && cert.name === "Diploma") {
        errors.DNameInstitute = "Name of the college / university is required";
      }
      if (
        cert.DNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.DNameInstitute) &&
        cert.name === "Diploma"
      ) {
        errors.DNameInstitute =
          "Name of the college / university can only contain letters and spaces";
      }
      //   if (!cert.D_Path && cert.name === "Diploma") {
      //   errors.D_Path = "Please Upload Diploma cerficate";
      // }
    }
    if (step === 3 && edu.includes("Professional Courses")) {
      if (!cert.PCDegree && cert.name === "Professional Courses") {
        errors.PCDegree = "Degree is required";
      }
      if (!cert.PCMajor && cert.name === "Professional Courses") {
        errors.PCMajor = "Major is required";
      }
      if (
        cert.PCMajor &&
        cert.PCMajor === "Others" &&
        !cert.PCMajor_Other &&
        cert.name === "Professional Courses"
      ) {
        errors.PCMajor_Other = "Major Other field is required";
      }
      if (!cert.PCYearpassing && cert.name === "Professional Courses") {
        errors.PCYearpassing = "Please Enter Year of passing";
      }
      if (!cert.PCCertificateNumber && cert.name === "Professional Courses") {
        errors.PCCertificateNumber = "Certificate number is required";
      }
      if (
        cert.PCCertificateNumber &&
        !cert.PCCertificateNumber.trim() &&
        cert.name === "Professional Courses"
      ) {
        errors.PCCertificateNumber = "Certificate number is required";
      }
      if (
        cert.PCCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.PCCertificateNumber) &&
        cert.name === "Professional Courses"
      ) {
        errors.PCCertificateNumber =
          "Certificate number can only contain letters and numbers";
      }
      if (!cert.PCMarksPercentage && cert.name === "Professional Courses") {
        errors.PCMarksPercentage = "Marks percentage is required";
      }
      if (
        cert.PCMarksPercentage &&
        typeof cert.PCMarksPercentage === "string" &&
        parseInt(cert.PCMarksPercentage) > 100 &&
        cert.name === "Professional Courses"
      ) {
        errors.PCMarksPercentage = "Marks percentage is must be less than 100%";
      }
      if (
        cert.PCMarksPercentage &&
        typeof cert.PCMarksPercentage === "string" &&
        parseInt(cert.PCMarksPercentage) < 45 &&
        cert.name === "Professional Courses"
      ) {
        errors.PCMarksPercentage = "Marks percentage is must be less than 45%";
      }
      if (!cert.PCClassName && cert.name === "Professional Courses") {
        errors.PCClassName = "Class is required";
      }
      //   if (cert.PCClassName && !/^[A-Za-z\s]*$/.test(cert.PCClassName) && cert.name === "Professional Courses") {
      //   errors.PCClassName = "class can only contain letters and spaces";
      // }
      if (!cert.PCNameInstitute && cert.name === "Professional Courses") {
        errors.PCNameInstitute = "Name of the college / university is required";
      }
      if (
        cert.PCNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.PCNameInstitute) &&
        cert.name === "Professional Courses"
      ) {
        errors.PCNameInstitute =
          "Name of the college / university can only contain letters and spaces";
      }
      //   if (!cert.PC_Path && cert.name === "Professional Courses") {
      //   errors.PC_Path = "Please Upload Professional Courses cerficate";
      // }
    }

    if (step === 3 && edu.includes("Others")) {
      if (!cert.OtDegree && cert.name === "Others") {
        errors.OtDegree = "Degree is required";
      }
      if (!cert.OtMajor && cert.name === "Others") {
        errors.OtMajor = "Major is required";
      }
      if (!cert.OtYearpassing && cert.name === "Others") {
        errors.OtYearpassing = "Please Enter Year of passing";
      }
      if (!cert.OtCertificateNumber && cert.name === "Others") {
        errors.OtCertificateNumber = "Certificate number is required";
      }
      if (
        cert.OtCertificateNumber &&
        !cert.OtCertificateNumber.trim() &&
        cert.name === "Others"
      ) {
        errors.OtCertificateNumber = "Certificate number is required";
      }
      if (
        cert.OtCertificateNumber &&
        !/^[A-Za-z0-9]*$/.test(cert.OtCertificateNumber) &&
        cert.name === "Others"
      ) {
        errors.OtCertificateNumber =
          "Certificate number can only contain letters and numbers";
      }
      if (!cert.OtMarksPercentage && cert.name === "Others") {
        errors.OtMarksPercentage = "Marks percentage is required";
      }
      if (
        cert.OtMarksPercentage &&
        typeof cert.OtMarksPercentage === "string" &&
        parseInt(cert.OtMarksPercentage) > 100 &&
        cert.name === "Others"
      ) {
        errors.OtMarksPercentage = "Marks percentage is must be less than 100%";
      }
      if (
        cert.OtMarksPercentage &&
        typeof cert.OtMarksPercentage === "string" &&
        parseInt(cert.OtMarksPercentage) < 45 &&
        cert.name === "Others"
      ) {
        errors.OtMarksPercentage =
          "Marks percentage is must be greater than 45%";
      }
      if (!cert.OtClassName && cert.name === "Others") {
        errors.OtClassName = "Class is required";
      }
      //   if (cert.OtClassName && !/^[A-Za-z\s]*$/.test(cert.OtClassName) && cert.name === "Others") {
      //   errors.OtClassName = "class can only contain letters and spaces";
      // }
      if (!cert.OtNameInstitute && cert.name === "Others") {
        errors.OtNameInstitute = "Name of the college / university is required";
      }
      if (
        cert.OtNameInstitute &&
        !/^[A-Za-z\s.]*$/.test(cert.OtNameInstitute) &&
        cert.name === "Others"
      ) {
        errors.OtNameInstitute =
          "Name of Institute can only contain letters and spaces";
      }
      //   if (!cert.Ot_Path && cert.name === "Others") {
      //   errors.Ot_Path = "Please Upload Professional Courses cerficate";
      // }
    }
  }
  validateNtFocus(data, certificates, edu, step, AdmissionRef_validation);
  return errors;
};
