import React from "react";
import { CSVLink } from "react-csv";
import "./CustomDownloadButton.css";
function CustomDownloadButton(props) {
  return (
    <CSVLink
      data={props.csvListData}
      filename={"TeachingProgress.csv"}
      className="primaryButton  download-csv-link"
    >
      <span className="material-icons align-middle text-white">
        file_download
      </span>

      {props.csvName}
    </CSVLink>
  );
}

export default CustomDownloadButton;
