import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import RtypeOne from "../StudentTestWorkflow/RtypeOne";
import RtypeTwo from "../StudentTestWorkflow/RtypeTwo";
import RtypeThree from "../StudentTestWorkflow/RtypeThree";
import RtypeFour from "../StudentTestWorkflow/RtypeFour";
import RtypeFive from "../StudentTestWorkflow/RtypeFive";
import RtypeSix from "../StudentTestWorkflow/RtypeSix";
import RtypeSeven from "../StudentTestWorkflow/RtypeSeven";
import RtypeEight from "../StudentTestWorkflow/RtypeEight";
import RtypeNine from "../StudentTestWorkflow/RtypeNine";
import RtypeTen from "../StudentTestWorkflow/RtypeTen";
import Rtypepara from "../StudentTestWorkflow/Rtypepara";
import RtypeParaOption from "../StudentTestWorkflow/RtypeParaOption";
import ExplanationQuery from "../Faculty/StudentsQuery/ExplanationQuery";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Loader from "../../Loader";
export default function ViewQuestionPaper() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const txtAssignTo = useRef(null);
  // console.log(location);
  const { id, status, eventName, bachId, publish_results, Isdisabled } =
    location.state;
  const [fdata, setFdata] = useState([]);
  const [listData, setListData] = useState([]);
  const [publishResult, setPublishResult] = useState(0);
  const [displayType, setDisplayType] = useState([]);
  // difficulty_type:
  // 0→easy, 1→very_easy, 2→hard, 3→very_hard

  useEffect(() => {
    setIsLoading(true);
    AxiosInstance.post("api/test/view/question", {
      batch_id: parseInt(bachId),
      event_id: parseInt(id),
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          setIsLoading(false);
          let ut = res.data.data.question_papers;
          setListData(ut);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  }, [bachId]);

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const Upload_Question = () => {
    // 1 -> // objective type
    navigate(`/UploadQuestionFile/1`, {
      state: {
        id,
        status,
      },
    });
  };

  // console.log(fdata,"aafaf");
  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className="BathDet">
      <div className="row">
        <div className="col-md-12">
          <section className="StepOne">
            <div className="heading mt-3 dflexbetww">
              <div class="mt-2 dbetw">
                <h6 className="Tit_ques w-100"> View Question Paper</h6>
                <Link
                  to="javascript:void(0)"
                  onClick={navigateToPreviousScreen}
                  className="CancelButton MobileShow"
                >
                  Back to View Test
                </Link>
              </div>
              <div className="d-flex smButton mobiledown  ">
                <Link
                  to="javascript:void(0)"
                  onClick={navigateToPreviousScreen}
                  className="CancelButtons  MobileHide"
                >
                  Back to View Test
                </Link>

                <button
                  type="button"
                  className={`detailEventButton ${
                    Isdisabled || publish_results === 1 ? "DisabledListviS" : ""
                  }`}
                  onClick={Upload_Question}
                  disabled={Isdisabled || publish_results === 1 ? true : false}
                >
                  {" "}
                  <span class="material-icons me-1">upload</span>
                  <a>Upload New Question Paper</a>
                </button>
              </div>
            </div>
          </section>

          <section className="StepOne ">
            {listData.length > 0 &&
              listData.map((el, index) => {
                // Calculate the effective step based on the loop count
                if (el.display_type === 0) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        <div className="row">
                          <RtypeOne type={"2"} index={index} list={el} />
                        </div>

                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 1) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeTwo type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 2) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        <div className="row">
                          <RtypeThree type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 3) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeFour type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 4) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeFive type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 5) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeSix type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 6) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeSeven type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 7) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeEight type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 8) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        <div className="row">
                          <RtypeNine type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 9) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>
                        <div className="row">
                          <RtypeTen type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                } else if (el.display_type === 10) {
                  return (
                    <div className="row box_review">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          {(index + 1).toString().padStart(2, "0")}
                        </p>

                        {el.para_text_en !== "" &&
                          (el.lang_type === 1 || el.lang_type === 2) && (
                            <Rtypepara
                              type={"2"}
                              classET={"question_labels_english"}
                              index={index}
                              list={el.para_text_en}
                            />
                          )}

                        {el.para_text_tm !== "" &&
                          (el.lang_type === 0 || el.lang_type === 2) && (
                            <Rtypepara
                              type={"2"}
                              classET={"question_labels_tamil"}
                              index={index}
                              list={el.para_text_tm}
                            />
                          )}

                        <div className="row">
                          <RtypeParaOption type={"2"} index={index} list={el} />
                        </div>
                        <ExplanationQuery
                          lang_type={el.lang_type}
                          margin_yes={1}
                          en_explan={el.en_explanation}
                          tm_expla={el.tm_explanation}
                        />
                      </div>
                    </div>
                  );
                }

                return null; // Return null for non-display_type 10 items
              })}
          </section>
        </div>
      </div>
    </div>
  );
}
