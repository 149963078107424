import React from 'react'

export default function ReviewFeesDetails(props) {
  // console.log(props,"props");
  return (
    <section className="StepOne">
    <div className="row box">
      <div className="col-md-12">
        <p className="StepTitle">{props.type === 2  ? "Counselling " : "Mock Interview"} Fees</p>
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-lg-6 col-md-6 mb-3">
                <div className="forms ">
                  <label
                    htmlFor="inputName"
                    className="form-label text-muted mt-2 "
                  >
                    Fees {props.record.id} for {props.type == 2   ? " Counselling" : "Mock Interview"}
                  </label>
                  <span className="ReviewLabel">
                   {props.record.fees}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
