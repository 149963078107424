import React, { useState, useRef, useEffect } from "react";

import $ from "jquery";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { styled } from "@mui/material/styles";
import MultiRangeSlider from "../Faculty/normalFaculity/RangeSlider";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import DatePickerSample from "../../DatePicker";
import Loader from "../../Loader";

function TestPerformance(props) {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const currentDate = moment();
  // List data load table
  let page = R_PER_PAGE;
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  let role_id = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      role_id = user.role_id;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }

  // const { batch_id } = useParams();

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [isOpen, setIsOpen] = useState({
    status: false,
    time: false,
    startDate: false,
    schedule: false,
    Percentage: false,
    total_marks: false,
    rank: false,
    category: false,
    // course:false,
    medium: false,
    classMode: false,
    location: false,
    no_of_questions: false,
    test_topic: false,
    eventName: false,
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });
  const [timedata, setTimedate] = useState({
    STime: "",
    TTime: "",
    name: "",
  });

  const [ranges, setRanges] = useState([30, 60]); // Initial range values

  const [updateChipData, setUpdateChipData] = useState([]);

  const [status, setStatus] = useState([]);

  const [location, setLocation] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [time, setTime] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [ssubject, setSsubject] = useState([]);
  const [ssubjects, setSsubjects] = useState(ssubject);

  const [eeventName, setEeventName] = useState([]);
  const [eeventNames, setEeventNames] = useState(eeventName);

  const [testTopic, setTestTopic] = useState([]);
  const [testTopics, setTestTopics] = useState(testTopic);

  const [classModes, setClassModes] = useState([]);
  const [mediums, setMedium] = useState([]);
  const [eventNames, setEventNames] = useState([]);
  const [test_topics, setTest_topics] = useState([]);

  const [percen, setPercent] = useState([]);
  const [tmarks, setTmarks] = useState([]);
  const [tQmarks, setTQmarks] = useState([]);
  const [rank, setRank] = useState([]);

  let filterStatus = [];
  let filterType = [];
  let filterTime = [];
  let filterSchedule = [];
  let filterPercen = [];
  let filterRank = [];
  let filterTMarks = [];
  let filterTQMarks = [];
  let filterClassMode = [];
  let filterLocation = [];
  let filterMedium = [];
  let filterApplyed = [];
  let filterTest_topics = [];
  let filterSearch = [];
  let filterEventName = [];

  useEffect(() => {
    filterStatus = status;
  }, [status]);
  useEffect(() => {
    filterLocation = location;
  }, [location]);
  useEffect(() => {
    filterClassMode = classModes;
  }, [classModes]);
  useEffect(() => {
    filterSchedule = schedule;
  }, [schedule]);

  useEffect(() => {
    filterEventName = eventNames;
  }, [eventNames]);

  useEffect(() => {
    filterPercen = percen;
  }, [percen]);

  useEffect(() => {
    filterTMarks = tmarks;
  }, [tmarks]);

  useEffect(() => {
    filterTQMarks = tQmarks;
  }, [tQmarks]);

  useEffect(() => {
    filterRank = rank;
  }, [rank]);

  useEffect(() => {
    filterMedium = mediums;
  }, [mediums]);

  useEffect(() => {
    filterTest_topics = test_topics;
  }, [test_topics]);

  useEffect(() => {
    filterType = typeOn;
  }, [typeOn]);

  useEffect(() => {
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    filterTime = time;
  }, [time]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  useEffect(() => {
    loadListdata();
  }, [props.activeTab]);
  // useEffect(() => {
  //   loadListdata();
  // }, [props.batchId]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    filterApplyed = appOn;
    filterTime = time;
    filterSchedule = schedule;
    filterPercen = percen;
    filterTMarks = tmarks;
    filterTQMarks = tQmarks;
    filterRank = rank;
    filterLocation = location;
    filterClassMode = classModes;
    filterMedium = mediums;
    filterEventName = eventNames;
    filterTest_topics = test_topics;
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  const handleChangeFilter = (values) => {
    const [from, to] = values; // Split the range into separate values

    // Update the state or perform any additional logic
    setRanges(values);

    setPercent([
      { startRange: from, endRange: to, name: values, type: "range" },
    ]);
    // Update the setPercent state
  };

  const handleChangeFilters = (values) => {
    const [from, to] = values; // Split the range into separate values

    // Update the state or perform any additional logic
    setRanges(values);

    setTmarks([
      { startRange: from, endRange: to, name: values, type: "tm_range" },
    ]);
    // Update the setPercent state
  };

  const handleChangeQFilters = (values) => {
    const [from, to] = values; // Split the range into separate values

    // Update the state or perform any additional logic
    setRanges(values);

    setTQmarks([
      { startRange: from, endRange: to, name: values, type: "tQ_range" },
    ]);
    // Update the setPercent state
  };

  const handleChangeFilterss = (values) => {
    const [from, to] = values; // Split the range into separate values

    // Update the state or perform any additional logic
    setRanges(values);

    setRank([{ startRange: from, endRange: to, name: values, type: "rank" }]);
    // Update the setPercent state
  };

  const handleFilterSearch = (event, name) => {
    if (name === "subject") {
      const searchInput = event.target.value.toLowerCase();
      if (searchInput !== "") {
        const matchingObjects = ssubject.filter((item) => {
          if (typeof item.subjectName === "string") {
            // Check if subjectName is a string
            return item.subjectName.toLowerCase().includes(searchInput);
          }
          return false; // Return false if subjectName is not a string
        });
        setSsubjects(matchingObjects);
      } else {
        setSsubjects(ssubject);
      }
    } else if (name === "test_topic") {
      const searchInput = event.target.value.toLowerCase(); // Convert search input to lowercase
      // console.log(searchInput);

      if (searchInput !== "") {
        const matchingObjects = testTopic.filter((ed) => {
          if (typeof ed === "string") {
            // Check if ed is a string
            return ed.toLowerCase().includes(searchInput);
          }
          return false; // Return false if ed is not a string
        });
        setTestTopics(matchingObjects);
      } else {
        setTestTopics(testTopic);
      }
    } else if (name === "eventNames") {
      const searchInput = event.target.value.toLowerCase();
      if (searchInput !== "") {
        const matchingObjects = eeventName.filter((item) => {
          if (typeof item.subjectName === "string") {
            // Check if subjectName is a string
            return item.subjectName.toLowerCase().includes(searchInput);
          }
          return false; // Return false if subjectName is not a string
        });
        setEventNames(matchingObjects);
      } else {
        setEventNames(eeventName);
      }
    } else {
      setTestTopics(testTopic);
      setSsubjects(ssubject);
      setEventNames(eeventName);
    }
  };

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    // console.log(names, " : ", names);
    const { value, checked, name } = event.target;
    if (name === "absent" || name === "completed") {
      if (checked) {
        // setStatus([...status, value]);
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (names === "location") {
      if (checked) {
        // setStatus([...status, value]);
        setLocation([...location, { id: value, name: name, type: "location" }]);
      } else {
        setLocation(location.filter((item) => item.id !== value));
      }
    } else if (typename === "type") {
      if (checked) {
        setTypeOn([...typeOn, { name: value, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.name !== value));
      }
    } else if (name === "Weekend" || name === "Regular") {
      if (checked) {
        setSchedule([...schedule, { id: value, name: name, type: "schedule" }]);
      } else {
        setSchedule(schedule.filter((item) => item.id !== value));
      }
    } else if (name === "Classroom" || name === "Online") {
      if (checked) {
        setClassModes([
          ...classModes,
          { id: value, name: name, type: "classMode" },
        ]);
      } else {
        setClassModes(classModes.filter((item) => item.id !== value));
      }
    } else if (names === "medium") {
      if (checked) {
        setMedium([...mediums, { id: value, name: name, type: "medium" }]);
      } else {
        setMedium(mediums.filter((item) => item.id !== value));
      }
    } else if (names === "test_topic") {
      if (checked) {
        // console.log(value, name);
        setTest_topics([
          ...test_topics,
          { id: value, name: name, type: "test_topic" },
        ]);
      } else {
        setTest_topics(test_topics.filter((item) => item.id !== value));
      }
    } else if (names === "eventName") {
      if (checked) {
        setEventNames([
          ...eventNames,
          { id: value, name: name, type: "eventName" },
        ]);
      } else {
        setEventNames(eventNames.filter((item) => item.id !== value));
      }
    }
  };

  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setSchedule([]);
    setPercent([]);
    setEventNames([]);
    setTmarks([]);
    setTQmarks([]);
    setRank([]);
    setTime([]);
    setLocation([]);
    setClassModes([]);
    setUpdateChipData([]);
    setAppOn([]);
    setMedium([]);
    setEventNames([]);
    setTest_topics([]);
    setTypeOn([]);
    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "location") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        location: false,
      });
    } else if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "time") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "Percentage") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        Percentage: false,
      });
    } else if (name === "total_marks") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        total_marks: false,
      });
    } else if (name === "TPercentage") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        no_of_questions: false,
      });
    } else if (name === "rank") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        rank: false,
      });
    } else if (name === "classMode") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "medium") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } else if (name === "eventName") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        eventName: false,
      });
    } else if (name === "test_topic") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        test_topic: false,
      });
    }
  };

  function loadListdata() {
    // console.log(props, "loadprops");
    let tempstatus = [];
    let temptypeon = [];
    let tempsche = [];
    let tempPercent = [];
    let tempTmarks = [];
    let tempTQmarks = [];
    let tempRank = [];
    let tempclassMode = [];
    let tempLocanum = [];
    let templocation = [];
    let templocsttsu = [];
    let tempMedium = [];
    let tempLocMedium = [];
    let tempTestTopic = [];
    let tempEvent = [];
    let tempLocTestTopic = [];

    status.map((obj) => {
      tempstatus.push(obj.id);
    });

    eventNames.map((obj) => {
      tempEvent.push(obj.id);
    });

    location.map((obj) => {
      templocation.push(obj.id);
    });

    classModes.map((obj) => {
      tempclassMode.push(obj.name);
    });

    mediums.map((obj) => {
      tempMedium.push(obj.id);
    });

    test_topics.map((obj) => {
      tempTestTopic.push(obj.id);
    });

    schedule.map((obj) => {
      tempsche.push(obj.name);
    });

    percen.map((obj) => {
      const [startRange, endRange] = obj.name;
      tempPercent.push(startRange);
      tempPercent.push(endRange);
    });

    tmarks.map((obj) => {
      const [startRange, endRange] = obj.name;
      tempTmarks.push(startRange);
      tempTmarks.push(endRange);
    });

    tQmarks.map((obj) => {
      const [startRange, endRange] = obj.name;
      tempTQmarks.push(startRange);
      tempTQmarks.push(endRange);
    });

    rank.map((obj) => {
      const [startRange, endRange] = obj.name;
      tempRank.push(startRange);
      tempRank.push(endRange);
    });
    typeOn.map((obj) => {
      temptypeon.push(obj.name);
    });

    tempLocanum = templocation.map(Number);

    templocsttsu = tempstatus.map(Number);

    tempLocMedium = tempMedium.map(Number);

    tempLocTestTopic = tempTestTopic.map(Number);

    //   rank default value as 1
    let statusFilter = {
      batch_id: parseInt(props.batchId),
      type: props.batch_type ? parseInt(props.batch_type) : 1,
      user_id: userid,
      event: eventNames.length > 0 ? tempEvent : "",
      start_date: appOn.length > 0 ? appOn[0].fdate + " 00:00:00" : "",
      end_date: appOn.length > 0 ? appOn[0].edate + " 23:59:00" : "",
      start_tot_marks: tempTmarks.length > 0 ? tempTmarks[0] : "",
      end_tot_marks: tempTmarks.length > 0 ? tempTmarks[1] : "",
      start_marks_obtained: tempPercent.length > 0 ? tempPercent[0] : "",
      end_marks_obtained: tempPercent.length > 0 ? tempPercent[1] : "",
      subject: mediums.length > 0 ? tempLocMedium : "",
      test_topic: test_topics.length > 0 ? tempLocTestTopic : "",
      status: status.length > 0 ? templocsttsu : "",
      start_no_of_que: tempTQmarks.length > 0 ? tempTQmarks[0] : "",
      end_no_of_que: tempTQmarks.length > 0 ? tempTQmarks[1] : "",
      search: searchTerm.length > 0 ? searchTerm : "",
    };
    setIsLoading(true);
    if (props.batchId) {
      AxiosInstance.post("api/test/report/list/byUser", statusFilter)
        .then((res) => {
          if (res.data.status === true) {
            setIsLoading(false);
            const fiata = res.data.data;

            const serialNumberAddedData = fiata.map((data, index) => ({
              ...data,
              serialNumber: index + 1,
            }));

            setUpdateListData(serialNumberAddedData);
            const subjectSet = fiata.flatMap((event) => event.subject);
            if (subjectSet) {
              const uniqueSubjectMap = new Map();
              subjectSet.forEach((subj) => {
                // Check if subj and subj.subject exist before using them
                if (subj && subj.subject) {
                  uniqueSubjectMap.set(subj.subject.toLowerCase(), subj); // Use lowercase to ignore case sensitivity
                }
              });
              // Convert the map back to an array
              const uniqueSubjects = Array.from(uniqueSubjectMap.values());
              setSsubject(uniqueSubjects);
              setSsubjects(uniqueSubjects);
            }
            // Create a map to filter unique subjects by subject name

            const testtopicSet = fiata.flatMap((event) => event.test_topic);
            if (testtopicSet) {
              const uniqueTestTopicMap = new Map();
              testtopicSet.forEach((topic) => {
                if (topic && topic.test_topic) {
                  uniqueTestTopicMap.set(topic.test_topic.toLowerCase(), topic); // Use lowercase to ignore case sensitivity
                }
              });

              // Convert the map back to an array
              const uniqueTestTopics = Array.from(uniqueTestTopicMap.values());

              setTestTopic(uniqueTestTopics);
              setTestTopics(uniqueTestTopics);
            }
            // Create a map to filter unique test topics by test_topic name
            const eeventSet = fiata.flatMap((event) => ({
              event_name: event.event_name,
              event_pk: event.event_pk,
            }));
            if (eeventSet) {
              setEeventName(eeventSet);
              setEeventNames(eeventSet);
            }
          } else if (res.data.status === false) {
            setUpdateListData([]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setUpdateListData([]);
          setIsLoading(false);
          return error;
        });
    }
  }

  function loadChipdata() {
    let tempArr = [];

    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });

    filterLocation.map((obj) => {
      tempArr.push(obj);
    });

    filterType.map((obj) => {
      tempArr.push(obj);
    });
    filterTime.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyed.map((obj) => {
      tempArr.push(obj);
    });

    filterSchedule.map((obj) => {
      tempArr.push(obj);
    });

    filterPercen.map((obj) => {
      tempArr.push(obj);
    });
    filterTMarks.map((obj) => {
      tempArr.push(obj);
    });

    filterTQMarks.map((obj) => {
      tempArr.push(obj);
    });

    filterRank.map((obj) => {
      tempArr.push(obj);
    });
    filterClassMode.map((obj) => {
      tempArr.push(obj);
    });

    filterMedium.map((obj) => {
      tempArr.push(obj);
    });

    filterEventName.map((obj) => {
      tempArr.push(obj);
    });

    filterTest_topics.map((obj) => {
      tempArr.push(obj);
    });

    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }

  const handleTime = (e) => {
    const { name, value, checked } = e.target;
    if (name === "STime" || name === "TTime") {
      setTimedate((prevState) => ({ ...prevState, [name]: value }));
      // console.log(timedata.STime);
      // console.log(timedata.TTime);
      const from = name === "STime" ? value : timedata.STime;
      const to = name === "TTime" ? value : timedata.TTime;
      const dateRange = `${from} - ${to}`;
      setTime([
        {
          name: dateRange,
          STime: from,
          TTime: to,
          type: "time",
        },
      ]);
    }
  };

  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };
  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        type: false,
      });
    } else if (name === "time") {
      setTime([]);
      filterTime = [];

      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      setSchedule([]);
      filterSchedule = [];

      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "Percentage") {
      setPercent([]);
      filterPercen = [];

      setIsOpen({
        ...isOpen,
        Percentage: false,
      });
    } else if (name === "total_marks") {
      setTmarks([]);
      filterTMarks = [];

      setIsOpen({
        ...isOpen,
        total_marks: false,
      });
    } else if (name === "TPercentage") {
      setTQmarks([]);
      filterTQMarks = [];

      setIsOpen({
        ...isOpen,
        no_of_questions: false,
      });
    } else if (name === "rank") {
      setRank([]);
      filterRank = [];
      setIsOpen({
        ...isOpen,
        rank: false,
      });
    } else if (name === "classMode") {
      setClassModes([]);
      filterClassMode = [];

      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "medium") {
      setMedium([]);
      filterMedium = [];

      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } else if (name === "eventName") {
      setEventNames([]);
      filterEventName = [];

      setIsOpen({
        ...isOpen,
        eventName: false,
      });
    } else if (name === "test_topic") {
      setTestTopic([]);
      filterTest_topics = [];

      setIsOpen({
        ...isOpen,
        test_topic: false,
      });
    } else if (name === "location") {
      // Clear the status array
      setLocation([]);
      filterLocation = [];
      setIsOpen({
        ...isOpen,
        location: false,
      });
      // setUpdateListData(listData);
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "location") {
      setLocation(location.filter((item) => item.id !== chipToDelete.id));
      filterLocation = location.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.name !== chipToDelete.name));
      filterType = typeOn.filter((item) => item.name !== chipToDelete.name);
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else if (chipToDelete.type === "time") {
      setTimedate({
        STime: "",
        TTime: "",
        name: "",
      });
      setTime([]);
      filterTime = [];
    } else if (chipToDelete.type === "range") {
      setPercent([]);
      filterPercen = [];
    } else if (chipToDelete.type === "tm_range") {
      setTmarks([]);
      filterTMarks = [];
    } else if (chipToDelete.type === "tQ_range") {
      setTQmarks([]);
      filterTQMarks = [];
    } else if (chipToDelete.type === "rank") {
      setRank([]);
      filterRank = [];
    } else if (chipToDelete.type === "schedule") {
      setSchedule(schedule.filter((item) => item.id !== chipToDelete.id));
      filterSchedule = schedule.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "classMode") {
      setClassModes(classModes.filter((item) => item.id !== chipToDelete.id));
      filterClassMode = classModes.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "medium") {
      setMedium(mediums.filter((item) => item.id !== chipToDelete.id));
      filterMedium = mediums.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "eventName") {
      setEventNames(eventNames.filter((item) => item.id !== chipToDelete.id));
      filterEventName = eventNames.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "test_topic") {
      setTestTopic(testTopics.filter((item) => item.id !== chipToDelete.id));
      filterTest_topics = testTopics.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    }
    loadChipdata();
  };
  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };
  const handleFilter = (e, boxName) => {
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        Percentage: false,
        total_marks: false,
        rank: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        location: false,
        test_topic: false,
        eventName: false,
        no_of_questions: false,
        status: !prevState.status,
      }));
    } else if (boxName === "location") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        Percentage: false,
        total_marks: false,
        rank: false,
        category: false,
        test_topic: false,
        medium: false,
        classMode: false,
        no_of_questions: false,
        eventName: false,
        status: false,
        location: !prevState.location,
      }));
    } else if (boxName === "time") {
      setIsOpen((prevState) => ({
        startDate: false,
        schedule: false,
        Percentage: false,
        total_marks: false,
        rank: false,
        category: false,
        eventName: false,
        test_topic: false,
        medium: false,
        classMode: false,
        no_of_questions: false,
        status: false,
        location: false,
        time: !prevState.time,
      }));
    } else if (boxName === "startDate") {
      setIsOpen((prevState) => ({
        schedule: false,
        Percentage: false,
        total_marks: false,
        rank: false,
        category: false,
        test_topic: false,
        medium: false,
        classMode: false,
        eventName: false,
        no_of_questions: false,
        status: false,
        location: false,
        time: false,
        startDate: !prevState.startDate,
      }));
    } else if (boxName === "schedule") {
      setIsOpen((prevState) => ({
        Percentage: false,
        total_marks: false,
        rank: false,
        category: false,
        test_topic: false,
        medium: false,
        eventName: false,
        classMode: false,
        no_of_questions: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: !prevState.schedule,
      }));
    } else if (boxName === "Percentage") {
      setIsOpen((prevState) => ({
        total_marks: false,
        rank: false,
        category: false,
        test_topic: false,
        medium: false,
        classMode: false,
        no_of_questions: false,
        eventName: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        Percentage: !prevState.Percentage,
      }));
    } else if (boxName === "total_marks") {
      setIsOpen((prevState) => ({
        rank: false,
        category: false,
        test_topic: false,
        medium: false,
        classMode: false,
        eventName: false,
        no_of_questions: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        Percentage: false,
        total_marks: !prevState.total_marks,
      }));
    } else if (boxName === "no_of_questions") {
      setIsOpen((prevState) => ({
        rank: false,
        category: false,
        test_topic: false,
        eventName: false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        Percentage: false,
        total_marks: false,
        no_of_questions: !prevState.no_of_questions,
      }));
    } else if (boxName === "rank") {
      setIsOpen((prevState) => ({
        category: false,
        test_topic: false,
        medium: false,
        classMode: false,
        status: false,
        eventName: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        Percentage: false,
        total_marks: false,
        no_of_questions: false,
        rank: !prevState.rank,
      }));
    } else if (boxName === "category") {
      setIsOpen((prevState) => ({
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        eventName: false,
        test_topic: false,
        startDate: false,
        schedule: false,
        Percentage: false,
        total_marks: false,
        no_of_questions: false,
        rank: false,
        category: !prevState.category,
      }));
    }
    // else if (boxName === "course") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     course: !prevState.course,
    //   }));
    // }
    else if (boxName === "medium") {
      setIsOpen((prevState) => ({
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        test_topic: false,
        schedule: false,
        eventName: false,
        Percentage: false,
        total_marks: false,
        no_of_questions: false,
        rank: false,
        category: false,
        medium: !prevState.medium,
      }));
    } else if (boxName === "eventName") {
      setIsOpen((prevState) => ({
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        test_topic: false,
        schedule: false,
        Percentage: false,
        total_marks: false,
        no_of_questions: false,
        rank: false,
        category: false,
        medium: false,
        eventName: !prevState.eventName,
      }));
    } else if (boxName === "test_topic") {
      setIsOpen((prevState) => ({
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        eventName: false,
        schedule: false,
        Percentage: false,
        total_marks: false,
        no_of_questions: false,
        rank: false,
        category: false,
        medium: false,
        test_topic: !prevState.test_topic,
      }));
    } else if (boxName === "classMode") {
      setIsOpen((prevState) => ({
        status: false,
        location: false,
        time: false,
        test_topic: false,
        startDate: false,
        schedule: false,
        Percentage: false,
        eventName: false,
        total_marks: false,
        no_of_questions: false,
        rank: false,
        category: false,
        medium: false,
        classMode: !prevState.classMode,
      }));
    }
  };

  // generate pdf when user click download button
  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add a title to the PDF document
    doc.text("Table Data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    // Add header row to tableData array
    const headers = [];
    const tableHeader = document.querySelectorAll("#example th");
    for (const header of tableHeader) {
      headers.push(header.innerText);
    }
    tableData.push(headers);

    // Add data rows to tableData array
    const tableRows = document.querySelectorAll("#example tbody tr");
    for (const row of tableRows) {
      const rowData = [];
      const rowCells = row.querySelectorAll("td");
      for (const cell of rowCells) {
        rowData.push(cell.innerText);
      }
      tableData.push(rowData);
    }

    // Add the table to the PDF document
    doc.autoTable({
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
    });
    // Save the PDF document
    doc.save("AdmissionData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const handleReadView = (
    event_id,
    batch_id,
    status,
    event_type,
    type_batch
  ) => {
    // status 0 means past 1 means present 2 means future
    // console.log(event_id, "id", batch_id, "handleevent");
    navigate(
      `/ViewTestDetail/${batch_id}/${event_id}/${status}/${event_type}/${type_batch}/1`
    );
  };

  //   const handleEditView = (type,id) => {
  //     navigate(`/createCourseBatch/${type}/${id}`);
  //   };
  // className="row tab-content mt-4"

  const [batch, setBatch] = useState([
    {
      batch_id: "",
      category: "",
      img_url: "",
      course_name: "",
      course_id: "",
      batch_details: "",
      start_time: "",
      end_time: "",
      batch_type: "",
      class_mode: "",
      start_on: "",
      medium: "",
      duration: "",
      act_c_fee: "",
    },
  ]);

  // console.log("currentData", currentData);

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className="">
      {updateChipData.length > 0 ? (
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {updateChipData.map((data, index) => {
                // console.log(data, " - data - ", index);
                let icon;
                let key = "" + data.name;
                return (
                  <ListItem key={data}>
                    <Chip
                      icon={icon}
                      label={key}
                      onDelete={handleDelete(data, index)}
                    />
                  </ListItem>
                );
              })}

              <ListItem>
                {/* <Chip
                      label=" Clear All Filters"
                      onClick={handleAllClear}
                    /> */}
                <a className="allclear" href="#" onClick={handleAllClear}>
                  Clear All Filters
                </a>
              </ListItem>
            </Paper>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="row mt-3 ms-1 me-1">
        <div className="col-md-12 table-responsive p-0">
          <table className="table  test-table " id="example">
            <thead>
              <tr>
                <th id="th" style={{ width: "1% !important" }}>
                  <span className="text_th color-table">S.No</span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "event_name")}
                  >
                    Event Name
                  </span>

                  <span
                    class="material-icons align-middle color-table"
                    onClick={(e) => handleSort(e, "event_name")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                  <span
                    onClick={(e) => handleFilter(e, "eventName")}
                    class={` align-middle material-icons color-table  ${
                      eventNames.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.eventName === true && (
                    <div className="filterBoxs p-1">
                      <div className="filterBoxText ">
                        <div className="row ms-1">
                          <div className="col-lg-12 col-md-12">
                            <div class="input-group searchInputsss">
                              <input
                                type="text"
                                class="Inputs width_75"
                                id="SearchFilterInput"
                                name="SearchFilterInput"
                                placeholder="Search Here"
                                onKeyDown={(e) =>
                                  handleFilterSearch(e, "eventName")
                                }
                              />
                              <span class="input-group-text" id="SearchInput">
                                <span className="material-icons">search</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {eeventName.map((el) => (
                          <div className="row mt-2 ">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el.event_name}
                                  value={el.event_pk}
                                  id={el.event_name}
                                  checked={eventNames.some((element) => {
                                    if (element.id == el.event_pk) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "eventName")
                                  }
                                />
                                <label
                                  class="form-check-label PinkText"
                                  for="defaultCheck1"
                                >
                                  {el.event_name}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("eventName")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("eventName")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "completed_date")}
                  >
                    Date
                  </span>{" "}
                  <span
                    class="material-icons align-middle  color-table"
                    onClick={(e) => handleSort(e, "completed_date")}
                  >
                    import_export
                  </span>{" "}
                  <span
                    onClick={(e) => handleFilter(e, "startDate")}
                    class={` align-middle material-icons color-table  ${
                      appOn.length > 0 ? "active_icons" : ""
                    }`}
                  >
                    filter_list
                  </span>
                  {isOpen.startDate === true && (
                    <div className="filterBoxOne_date container p-1">
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="FDate"
                              className="d-flex form-label"
                            >
                              From Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                              id="FDate"
                              type="date"
                              name="FDate"
                              value={appData.FDate}
                              className="FilterInputs"
                              onChange={handleAppliedOn}
                              placeholder="FDate"
                              autoComplete="FDate"
                              required
                            /> */}
                            <DatePickerSample
                              IconInput={"filterIocnBoxInput"}
                              class={"FilterInputs"}
                              name={"FDate"}
                              handleChange={handleChangeDate}
                              fieldInput={appData.FDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <div class="form-check p-1">
                            <label
                              htmlFor="TDate "
                              className="d-flex form-label"
                            >
                              To Date
                              <span
                                data-required="true"
                                aria-hidden="true"
                              ></span>
                            </label>
                            {/* <input
                              id="TDate"
                              type="date"
                              name="TDate"
                              value={appData.TDate}
                              className="FilterInputs"
                              placeholder="TDate"
                              onChange={handleAppliedOn}
                              autoComplete="TDate"
                              required
                            /> */}
                            <DatePickerSample
                              class={"FilterInputs"}
                              IconInput={"filterIocnBoxInput"}
                              name={"TDate"}
                              handleChange={handleChangeDate}
                              fieldInput={appData.TDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              id="Today"
                              value="Today"
                              checked={appData.Days === "Today"}
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Today">
                              Today
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Yesterday"
                              checked={appData.Days === "Yesterday"}
                              id="Yesterday"
                              onChange={handleAppliedOn}
                            />
                            <span
                              class="form-check-label d-flex"
                              for="Yesterday"
                            >
                              Yesterday
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tweek"
                              checked={appData.Days === "Tweek"}
                              id="Tweek"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Tweek">
                              This week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Lweek"
                              checked={appData.Days === "Lweek"}
                              id="Lweek"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Lweek">
                              Last week
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              value="Tmonth"
                              checked={appData.Days === "Tmonth"}
                              id="Tmonth"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Tmonth">
                              This month
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 col-sm-6 col-xs-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Days"
                              checked={appData.Days === "Lmonth"}
                              value="Lmonth"
                              id="Lmonth"
                              onChange={handleAppliedOn}
                            />
                            <span class="form-check-label d-flex" for="Lmonth">
                              Last month
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("appliedOn")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("appliedOn")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                {/* className="tablehhh" */}
                {/* <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "test_no")}
                  >
                    Test Number
                  </span>

                  <span
                    class="material-icons  color-table align-middle"
                    onClick={(e) => handleSort(e, "test_no")}
                  >
                    import_export
                  </span>
                </th> */}
                <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "subject")}
                  >
                    Subject
                  </span>{" "}
                  <span
                    class="material-icons align-middle  color-table"
                    onClick={(e) => handleSort(e, "subject")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons  color-table ${
                      mediums.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "medium")}
                  >
                    filter_list
                  </span>
                  {isOpen.medium === true && (
                    <div className="filterBoxs p-1">
                      <div className="filterBoxText ">
                        <div className="row ms-1">
                          <div className="col-lg-12 col-md-12">
                            <div class="input-group searchInputsss">
                              <input
                                type="text"
                                class="Inputs width_75"
                                id="SearchFilterInput"
                                name="SearchFilterInput"
                                placeholder="Search Here"
                                // onChange={handleSearch}
                                onKeyDown={(e) =>
                                  handleFilterSearch(e, "subject")
                                }
                              />
                              <span class="input-group-text" id="SearchInput">
                                <span className="material-icons">search</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {ssubjects.map((el) => (
                          <div className="row mt-2 ">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el.subject}
                                  value={el.subject_pk}
                                  id={el.subject}
                                  checked={mediums.some((element) => {
                                    if (element.id == el.subject_pk) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "medium")
                                  }
                                />
                                <label
                                  class="form-check-label PinkText"
                                  for="defaultCheck1"
                                >
                                  {el.subject}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("medium")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("medium")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "test_topic")}
                  >
                    Test Topic
                  </span>

                  <span
                    class="material-icons color-table align-middle"
                    onClick={(e) => handleSort(e, "test_topic")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons color-table ${
                      test_topics.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "test_topic")}
                  >
                    filter_list
                  </span>
                  {isOpen.test_topic === true && (
                    <div className="filterBoxs p-1">
                      <div className="filterBoxText ">
                        <div className="row ms-1">
                          <div className="col-lg-12 col-md-12">
                            <div class="input-group searchInputsss">
                              <input
                                type="text"
                                class="Inputs width_75"
                                id="SearchFilterInput"
                                name="SearchFilterInput"
                                placeholder="Search Here"
                                // onChange={handleSearch}
                                onKeyDown={(e) =>
                                  handleFilterSearch(e, "test_topic")
                                }
                              />
                              <span class="input-group-text" id="SearchInput">
                                <span className="material-icons">search</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {testTopics.map((el) => (
                          <div className="row mt-2 ">
                            <div className="col-md-12">
                              <div class="form-check ms-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el.test_topic}
                                  value={el.test_topic_pk}
                                  id={el.test_topic}
                                  checked={test_topics.some((element) => {
                                    if (element.id == el.test_topic_pk) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "test_topic")
                                  }
                                />
                                <label
                                  class="form-check-label PinkText"
                                  for="defaultCheck1"
                                >
                                  {el.test_topic}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("test_topic")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("test_topic")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                {/* className="tablenj" */}
                <th id="th">
                  <span
                    className="text_th colorTextTH"
                    onClick={(e) => handleSort(e, "total_marks")}
                  >
                    Total Marks
                  </span>{" "}
                  <span
                    class={` align-middle material-icons  color-table ${
                      tQmarks.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "no_of_questions")}
                  >
                    filter_list
                  </span>
                </th>
                {/* className="tablenj" */}
                {/* --- New change Start--- */}
                <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "no_of_questions")}
                  >
                    Number of Questions
                  </span>{" "}
                  <span
                    class="material-icons align-middle  color-table"
                    onClick={(e) => handleSort(e, "no_of_questions")}
                  >
                    import_export
                  </span>
                  {/* <span
                    class={` align-middle material-icons  color-table ${
                      tQmarks.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "no_of_questions")}
                  >
                    filter_list
                  </span> */}
                  {isOpen.no_of_questions === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <span
                            class="form-check-label d-flex justify-content-center mb-3"
                            for="Lmonth"
                          >
                            {" "}
                            Range filter :
                          </span>
                          <div class="form-check">
                            {/* filter option */}
                            <MultiRangeSlider
                              handleChangeFilter={handleChangeQFilters}
                              ranges={ranges}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("TPercentage")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("TPercentage")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                {/* --- New change end--- */}

                <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "cut-off")}
                  >
                    Cut-off
                  </span>

                  <span
                    class="material-icons color-table align-middle"
                    onClick={(e) => handleSort(e, "cut-off")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th" className="">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "Obtained Marks")}
                  >
                    Obtained Marks
                  </span>{" "}
                  <span
                    class="material-icons  color-table align-middle"
                    onClick={(e) => handleSort(e, "Obtained Marks")}
                  >
                    import_export
                  </span>
                  {/* <span
                    class={` align-middle material-icons  color-table ${
                      percen.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "Percentage")}
                  >
                    filter_list
                  </span> */}
                  {isOpen.Percentage === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <span
                            class="form-check-label d-flex justify-content-center mb-3"
                            for="Lmonth"
                          >
                            {" "}
                            Range filter :
                          </span>
                          <div class="form-check">
                            {/* filter option */}
                            <MultiRangeSlider
                              handleChangeFilter={handleChangeFilter}
                              ranges={ranges}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("Percentage")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("Percentage")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "rank")}
                  >
                    Class Rank
                  </span>{" "}
                  <span
                    class="material-icons color-table align-middle"
                    onClick={(e) => handleSort(e, "rank")}
                  >
                    import_export
                  </span>
                  {/* <span
                    class={` align-middle material-icons  color-table ${
                      rank.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "rank")}
                  >
                    filter_list
                  </span> */}
                  {isOpen.rank === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <span
                            class="form-check-label d-flex justify-content-center mb-3"
                            for="Lmonth"
                          >
                            {" "}
                            Range filter :
                          </span>
                          <div class="form-check">
                            {/* filter option */}
                            <MultiRangeSlider
                              handleChangeFilter={handleChangeFilterss}
                              ranges={ranges}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("rank")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("rank")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                {/* ------New Added----------- */}
                <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleSort(e, "rank")}
                  >
                    Overall Rank
                  </span>{" "}
                  <span
                    class="material-icons color-table align-middle"
                    onClick={(e) => handleSort(e, "rank")}
                  >
                    import_export
                  </span>
                  {/* <span
                    class={` align-middle material-icons  color-table ${
                      rank.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "rank")}
                  >
                    filter_list
                  </span> */}
                  {isOpen.rank === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <span
                            class="form-check-label d-flex justify-content-center mb-3"
                            for="Lmonth"
                          >
                            {" "}
                            Range filter :
                          </span>
                          <div class="form-check">
                            {/* filter option */}
                            <MultiRangeSlider
                              handleChangeFilter={handleChangeFilterss}
                              ranges={ranges}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("rank")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("rank")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th color-table"
                    onClick={(e) => handleFilter(e, "status")}
                  >
                    Status
                  </span>{" "}
                  <span
                    class="material-icons color-table align-middle"
                    onClick={(e) => handleSort(e, "rank")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons  color-table ${
                      status.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "status")}
                  >
                    filter_list
                  </span>
                  {/* absent -- 0 | completed --- 1 */}
                  {isOpen.status === true && (
                    <div className="filterBoxs_fg p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="absent"
                              value="0"
                              id="absent"
                              checked={status.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "absent")
                              }
                            />
                            <label
                              class="form-check-label PinkText"
                              for="defaultCheck1"
                            >
                              Absent
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3 test-table">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="completed"
                              value="1"
                              id="completed"
                              checked={status.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "completed")
                              }
                            />
                            <label
                              class="form-check-label GreenText"
                              for="defaultCheck1"
                            >
                              Completed
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3 ">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("status")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("status")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th" className="ColorTH">
                  <span className="text_th color-table">Action</span>{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td colspan="15" style={{ textAlign: "center" }}>
                    No record to display
                  </td>
                </tr>
              ) : (
                currentData.map((course, index) => (
                  <tr key={index}>
                    <td id="td">{course.serialNumber}</td>
                    <td id="td">{course.event_name}</td>
                    <td id="td">
                      {moment(course.start_date).format("Do MMM YYYY")}
                    </td>

                    <td id="td">
                      {course.subject && course.subject.length > 0
                        ? course.subject
                            .map((subject) => subject.subject)
                            .join(", ")
                        : "_"}
                    </td>
                    <td id="td" style={{ wordWrap: "break-word" }}>
                      {course.test_topic && course.test_topic.length > 0
                        ? course.test_topic
                            .map((test_topic) => test_topic.test_topic)
                            .join(", ")
                        : "_"}
                    </td>
                    <td id="td">{course.tot_marks}</td>
                    <td id="td">
                      {course.no_of_questions !== null
                        ? course.no_of_questions === 0
                          ? "_"
                          : course.no_of_questions
                        : "_"}
                    </td>
                    <td id="td">{course.cut_off ? course.cut_off : "_"}</td>
                    <td id="td">
                      {course.marks_obtained != null
                        ? course.marks_obtained === 0 && course.status === 0
                          ? "A"
                          : course.marks_obtained
                        : "-"}
                    </td>
                    <td id="td">
                      {course.event_rank != null
                        ? course.event_rank === 0
                          ? "_"
                          : course.event_rank
                        : "_"}
                    </td>
                    <td id="td">
                      {course.over_all_event_rank != null
                        ? course.over_all_event_rank === 0
                          ? " _ "
                          : course.over_all_event_rank
                        : "_"}
                    </td>
                    {course.status === 0 && (
                      <td id="td">
                        {" "}
                        <span className="PinkText">Absent</span>
                      </td>
                    )}
                    {course.status === 1 && (
                      <td id="td">
                        {" "}
                        <span className="GreyText1">Completed</span>
                      </td>
                    )}

                    {(course.status === 1 || course.status === 0) && (
                      <td id="td">
                        <button
                          className="button"
                          onClick={() =>
                            handleReadView(
                              course.event_pk,
                              course.batch_id,
                              0,
                              course.event_type,
                              course.type
                            )
                          }
                        >
                          {" "}
                          <span class="material-icons">visibility</span>
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-3 ms-1 me-1">
        <div className="col-md-12">
          <div className="pag w-100 dflexcenter">
            <span className="ipage">Items Per Page</span>
            <select
              name="pageCount"
              id="pageCount"
              defaultValue={rowsPerPage}
              onChange={handlePageCount}
              className="ms-2"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pagenumber ms-2">
              {" "}
              {updateListData.length > 0
                ? currentPage * rowsPerPage + 1 - rowsPerPage
                : 0}{" "}
              -
              {updateListData.length < currentPage * rowsPerPage
                ? updateListData.length
                : currentPage * rowsPerPage}{" "}
              of {updateListData.length}
            </span>
            <button
              className="prev_button ms-2 me-2"
              onClick={(e) => handlePrev(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span class="material-icons">chevron_left</span>
            </button>
            <button
              className="prev_button"
              onClick={() => handleNext(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <span class="material-icons">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestPerformance;
