export const validateFaculty = (
  data,
  step,
  category,
  subject,
  facId,
  useref_validation
) => {
  const errors = {};
  const phoneNumberRegex = /^\d{10}$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (data.fname === "" && step === 0) {
    errors.fname = "Please enter first name.";
  }
  if (data.lname === "" && step === 0) {
    errors.lname = "Please enter Last name.";
  }
  if (data.phoneNumber === "" && step === 0) {
    errors.phoneNumber = "Please enter phone number.";
  }
  if (
    !phoneNumberRegex.test(data.phoneNumber) &&
    data.phoneNumber !== "" &&
    step === 0
  ) {
    errors.phoneNumber = "Please enter a valid 10-digit phone number.";
  }
  if (data.email === "" && step === 0) {
    errors.email = "Please enter email.";
  }
  if (!emailRegex.test(data.email) && data.email !== "" && step === 0) {
    errors.email = "Please enter a valid email address.";
  }
  // else if (!data.TeachType && step === 0) {
  //   errors.TeachType = "Please choose job type.";
  // }
  if (data.designation === "" && step === 0) {
    errors.designation = "Please choose designation.";
  }
  if (data.DJoin === "" && step === 0) {
    errors.DJoin = "Please enter Date of Join.";
  }
  if (data.location === "" && step === 0) {
    errors.location = "Please enter location.";
  }
  if (category.length === 0 && (facId === "0" || facId === 0)) {
    errors.category = "Please choose at least one category.";
  }
  if (subject.optionSelected === null && (facId === "0" || facId === 0)) {
    errors.optionSelected = "Please select an option.";
  }

  for (let i = 0; i < subject.length; i++) {
    const cert = subject[i].optionSelected;
    if (cert === null) {
      errors.optionSelected = "Please select subject for respective category .";
    }
    // Add additional validation rules for SSLC/HSC_Path if necessary
  }
  validateFFFaculty(data, step, category, subject, facId, useref_validation);
  return errors;
};

export const validateFFFaculty = (
  data,
  step,
  category,
  subject,
  facId,
  useref_validation
) => {
  const errors = {};
  const phoneNumberRegex = /^\d{10}$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (data.fname === "" && step === 0) {
    useref_validation.firstName.focus();
  } else if (data.lname === "" && step === 0) {
    useref_validation.lastName.focus();
  } else if (data.phoneNumber === "" && step === 0) {
    useref_validation.phoneNumber.focus();
  } else if (
    !phoneNumberRegex.test(data.phoneNumber) &&
    data.phoneNumber !== "" &&
    step === 0
  ) {
    useref_validation.phoneNumber.focus();
  } else if (data.email === "" && step === 0) {
    useref_validation.email.focus();
  } else if (!emailRegex.test(data.email) && data.email !== "" && step === 0) {
    useref_validation.email.focus();
  }
  // else if (!data.TeachType && step === 0) {
  // }
  else if (data.designation === "" && step === 0) {
    useref_validation.designation.focus();
  } else if (data.DJoin === "" && step === 0) {
    useref_validation.dateOfJoining.setFocus();
  } else if (data.location === "" && step === 0) {
    useref_validation.location.focus();
  } else if (category.length === 0 && (facId === "0" || facId === 0)) {
    useref_validation.category.focus();
  } else if (
    subject.optionSelected === null &&
    (facId === "0" || facId === 0)
  ) {
    useref_validation.category.focus();
  }

  for (let i = 0; i < subject.length; i++) {
    const cert = subject[i].optionSelected;
    if (cert === null) {
      useref_validation.category.focus();
    }
    // Add additional validation rules for SSLC/HSC_Path if necessary
  }

  return errors;
};

export const validateForms = (data) => {
  // console.log(data);
  const errors = {};
  if (!data.Adminstatus) {
    errors.Adminstatus = "Please Choose status.";
  }
  if (!data.comments) {
    errors.comments = "Please enter comment.";
  }

  return errors;
};

export const validatePanelMember = (data, useref_panelmembers, addJob) => {
  const errors = {};
  const phoneNumberRegex = /^\d{10}$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  if (data.fname === "") {
    errors.fname = "Please enter first name.";
  }
  if (data.lname === "") {
    errors.lname = "Please enter Last name.";
  }
  if (data.phoneNumber === "") {
    errors.phoneNumber = "Please enter phone number.";
  }
  if (!phoneNumberRegex.test(data.phoneNumber) && data.phoneNumber !== "") {
    errors.phoneNumber = "Please enter a valid 10-digit phone number.";
  }
  if (data.email === "") {
    errors.email = "Please enter email.";
  }
  if (!emailRegex.test(data.email) && data.email !== "") {
    errors.email = "Please enter a valid email address.";
  }
  if (data.status === "") {
    errors.status = "Please choose status.";
  }
  validateFPanelMember(data, useref_panelmembers, addJob);
  return errors;
};

export const validateFPanelMember = (data, useref_panelmembers, addJob) => {
  const errors = {};
  const phoneNumberRegex = /^\d{10}$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  if (data.fname === "") {
    errors.fname = "Please enter first name.";
    useref_panelmembers.firstName.focus();
  } else if (data.lname === "") {
    errors.lname = "Please enter Last name.";
    useref_panelmembers.lastName.focus();
  } else if (data.phoneNumber === "") {
    errors.phoneNumber = "Please enter phone number.";
    useref_panelmembers.phoneNumber.focus();
  } else if (
    !phoneNumberRegex.test(data.phoneNumber) &&
    data.phoneNumber !== ""
  ) {
    errors.phoneNumber = "Please enter a valid 10-digit phone number.";
    useref_panelmembers.phoneNumber.focus();
  } else if (data.email === "") {
    errors.email = "Please enter email.";
    useref_panelmembers.email.focus();
  } else if (!emailRegex.test(data.email) && data.email !== "") {
    errors.email = "Please enter a valid email address.";
    useref_panelmembers.email.focus();
  } else if (data.status === "") {
    errors.status = "Please choose status.";
    useref_panelmembers.status.focus();
  }
  // else if(addJob.length > 1 && addJob[0].department === ""){

  //   // useref_panelmembers.department.focus();
  //   errors.department = "Please choose department.";
  // }
  // else if (data.department === "") {
  //   errors.department = "Please choose department.";
  //   useref_panelmembers.department.focus();
  // }
  // else if (data.designation === "") {
  //   errors.designation = "Please choose designation.";
  //   useref_panelmembers.designation.focus();
  // }

  return errors;
};
export const validateSalary = (data, staff_type) => {
  const errors = {};

  if (data.Date === "") {
    errors.Date = "Please enter Date.";
  }
  if (data.sDate === "" && staff_type != 1) {
    errors.sDate = "Please enter  start date.";
  }
  if (data.MonthOf === "" && staff_type === "1") {
    errors.MonthOf = "Please enter Month Of.";
  }
  if (data.eDate === "" && staff_type != 1) {
    errors.eDate = "Please enter end date";
  }
  if (data.no_class === "" && staff_type != 1) {
    errors.no_class = "Please enter no of class.";
  }
  // else if (data.TSalary === ""  ) {
  //   errors.TSalary = "Please enter Total Salary.";
  // }
  if (data.deduction === "") {
    errors.deduction = "Please enter deduction.";
  }
  // else if (data.THSalary === "") {
  //   errors.THSalary = "Please enter Take Home Salary.";
  // }

  return errors;
};

// export const validateRaiseQuery =(e)=>{
//   const errors={};
//   if (queryText.queryTextarea === "") {
//     errors.queryTextarea = "Please enter text area.";
//   }

//   if (!queryText.queryFile) {
//     errors.queryFile = "Please select a file.";
//   }
// }
