import React, { useState, useRef, useEffect } from "react";

import $, { data } from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chip, Typography } from "@material-ui/core";
import { CloseOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { styled } from "@mui/material/styles";

import DatePickerSample from "../../DatePicker";
import ExamAdvancedFilterAdmin from "./ExamAdvancedFilterAdmin";
import Loader from "../../Loader";

function AdmissionPayments(props) {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const txtBSearch = useRef(null);
  const txtFilterAPayments = useRef(null);
  const txtFilterSDAPayments = useRef(null);
  // List data load table
  // AIASA-000185 issues solved by lalitha
  const currentDate = moment();
  const [isLoading, setIsLoading] = useState(false);
  const startOfDay = moment(currentDate).startOf("day");
  const tenAM = startOfDay.clone().hour(10);

  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);

  const [csvListData, setCsvListData] = useState([]);

  const [isOpen, setIsOpen] = useState({
    status: false,
    time: false,
    startDate: false,
    schedule: false,
    category: false,
    medium: false,
    classMode: false,
    location: false,
    created_on: false,
  });

  const [formData, setFormData] = useState({
    male_stud_enrolled: 0,
    female_stud_enrolled: 0,
    transGender_stud_enrolled: 0,
    stud_pay_online: 0,
    stud_pay_cash: 0,
    online_pay_collect: 0,
    cash_payment_collect: 0,
    total_no_student_enrolled: 0,
    total_amount_collected: 0,
  });

  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const [appData_on, setAppData_on] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });

  const [timedata, setTimedate] = useState({
    STime: "",
    TTime: "",
    name: "",
  });

  const [updateChipData, setUpdateChipData] = useState([]);

  const [district, setDistrict] = useState([]);
  const [districtAll, setDistrictAll] = useState([]);
  const [examCenterOption, setExamCenterOption] = useState({});
  const [modelExam, setModelExam] = useState([]);
  const [genderAll, setGenderAll] = useState([]);
  const [districtf, setDistrictf] = useState([]);
  const [examCenterf, setExamCenterfm] = useState([]);
  const [noExamEnrolled, setNoExamEnrolled] = useState([]);
  const [examDateFilter, setExamDateFilter] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [modelExamOption, setModelExamOption] = useState([]);
  const [optionNEEnrolled, setOptionNEEnrolled] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState({});
  const [selectedExamCenter, setSelectedExamCenter] = useState({});
  const [ModelExamValue, setModelExamValue] = useState([]);
  const [ClearAllFilterBut, setClearAllFilterBut] = useState(false);
  const [centerDistrict, setCenterDistrict] = useState({
    centerD: null,
    modelExamNo: null,
    no_exam_enrolled: "",
    payment_mode: null,
    startDateFilter: null,
    endDateFilter: null,
  });
  // const [filterClose, setFilterClose] = useState({
  //   maleGender: true,
  //   FemaleGender: true,
  //   TransGender: true,
  //   Online: true,
  //   Cash: true,
  //   endDateFilter: true,
  // });
  const [clearFilterDisabled, setClearFilterDisabled] = useState({
    Modelexamsec: false,
    districtsec: false,
    gendersec: false,
    paymentsec: false,
    datefiltersec: false,
  });
  const [error, setError] = useState({});
  const [genderOption, setGenderOption] = useState([]);

  let filterGender = [];
  let filterModelExam = [];
  let filterDistrictCenter = [];
  let filterCenterDistrict = [];
  let filterNoExamEnrolled = [];
  let filterDate = [];
  // let filterSearch = [];
  let filterPaymentType = [];

  let filterSearch = [];

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.activeTab === 1) {
      ModelexamDropdown(props.batch_id);
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    if (props.activeTab === 1) {
      loadListdata();
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (props.activeTab === 1) {
      loadListdata();
    }
  }, [updateChipData]);

  function updateStateData() {
    filterModelExam = modelExam;
    filterGender = genderAll;
    filterDistrictCenter = districtf;
    filterCenterDistrict = examCenterf;

    if (centerDistrict.no_exam_enrolled !== "") {
      const no_of_enrolled = [
        {
          id: centerDistrict.no_exam_enrolled,
          name: centerDistrict.no_exam_enrolled,
          type: "no_exam_enrolled",
        },
      ];
      filterNoExamEnrolled = no_of_enrolled;
      setNoExamEnrolled(no_of_enrolled);
    }
    if (
      centerDistrict.startDateFilter !== null &&
      centerDistrict.endDateFilter === null
    ) {
      const dateFil = [
        {
          id: `${centerDistrict.startDateFilter} `,
          name: `${
            centerDistrict.startDateFilter
              ? moment(centerDistrict.startDateFilter).format("DD/MM/YYYY")
              : ""
          } `,
          type: "DateFilter",
          startDate: centerDistrict.startDateFilter,
          endDate: centerDistrict.endDateFilter,
        },
      ];
      filterDate = dateFil;
    } else if (
      centerDistrict.startDateFilter !== null &&
      centerDistrict.endDateFilter !== null
    ) {
      const dateFil = [
        {
          id: `${centerDistrict.startDateFilter} - ${centerDistrict.endDateFilter}`,
          name: `${
            centerDistrict.startDateFilter
              ? moment(centerDistrict.startDateFilter).format("DD/MM/YYYY")
              : ""
          } - ${
            centerDistrict.endDateFilter
              ? moment(centerDistrict.endDateFilter).format("DD/MM/YYYY")
              : ""
          }`,
          type: "DateFilter",
          startDate: centerDistrict.startDateFilter,
          endDate: centerDistrict.endDateFilter,
        },
      ];
      filterDate = dateFil;
    }

    if (
      centerDistrict.payment_mode !== "" &&
      centerDistrict.payment_mode !== null
    ) {
      const payment_typess = [
        {
          id: centerDistrict.payment_mode,
          name: centerDistrict.payment_mode,
          type: "payment_mode",
        },
      ];
      filterPaymentType = payment_typess;
    }
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };

  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      // Create a new KeyboardEvent
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        keyCode: 13,
        which: 13,
        code: "Enter",
        bubbles: true,
      });

      // Dispatch the event
      txtBSearch.current.dispatchEvent(event);
    }
  };

  const OpenFilterModel = () => {
    setOpenFilterModal(true);
  };
  const handleFilterClose = () => {
    setOpenFilterModal(false);
    // console.log(updateChipData);
    if (updateChipData.length > 0) {
      const newModelExamValues = [];
      const newGenderOption = [];
      clearState();
      updateChipData.forEach((el) => {
        if (el.type === "model_exam_name") {
          newModelExamValues.push({
            value: el.id,
            label: el.name,
            id: el.id,
            name: el.name,
          });
          setCenterDistrict((prevState) => ({
            ...prevState,
            modelExamNo: "0",
          }));
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            Modelexamsec: true,
          }));
        } else if (el.type === "gender") {
          newGenderOption.push(el.name);
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            gendersec: true,
          }));
        } else if (el.type === "payment_mode") {
          setCenterDistrict((prevState) => ({
            ...prevState,
            payment_mode: el.id,
          }));
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            paymentsec: true,
          }));
        } else if (el.type === "no_exam_enrolled") {
          setCenterDistrict((prevState) => ({
            ...prevState,
            no_exam_enrolled: el.id,
            modelExamNo: "1",
          }));
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            Modelexamsec: true,
          }));
        } else if (el.type === "DateFilter") {
          // console.log(el, "el.endDate");
          setCenterDistrict((prevState) => ({
            ...prevState,
            startDateFilter: el.startDate ? el.startDate : null,
            endDateFilter:
              el.endDate !== "null" || el.endDate !== null ? el.endDate : null,
          }));
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            datefiltersec: true,
          }));
        } else if (el.type === "district") {
          setSelectedDistrict({
            value: el.name,
            district_id: el.id,
            label: el.name,
            district_code: el.district_code,
          });
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            districtsec: true,
          }));
          setCenterDistrict((prevState) => ({
            ...prevState,
            centerD: "0",
          }));
        } else if (el.type === "exam_center") {
          setSelectedExamCenter({
            value: el.id,
            district_id: el.district_id,
            label: el.name,
          });
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            districtsec: true,
          }));
          examCenterDistrict(el.district_id);
          setCenterDistrict((prevState) => ({
            ...prevState,
            centerD: "1",
          }));
        }
      });
      setModelExamValue(newModelExamValues);
      setGenderOption(newGenderOption);
    } else {
      clearState();
    }
  };

  const clearState = () => {
    setCenterDistrict({
      centerD: null,
      modelExamNo: null,
      no_exam_enrolled: "",
      payment_mode: null,
      startDateFilter: null,
      endDateFilter: null,
    });
    setModelExamValue([]);
    setGenderOption([]);
    setDistrictf([]);
    setExamCenterfm([]);
    setSelectedDistrict({});
    setSelectedExamCenter({});
  };

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  // const BoldTypography = styled(Typography)({
  //   textAlign: "left",
  //   width: "max-content",
  //   display: "contents",
  //   fontSize: "12px",
  //   fontWeight: 600,
  //   lineHeight: "16px",
  //   fontFamily: "Roboto",
  //   letterSpacing: "0px",
  //   color: "#041134",
  //   opacity: 1,
  // });

  // const BoldTypographyText = styled(Typography)({
  //   textAlign: "right",
  //   width: "max-content",
  //   display: "contents",
  //   fontSize: "12px",
  //   fontWeight: 400,
  //   lineHeight: "16px",
  //   fontFamily: "Roboto",
  //   letterSpacing: "0px",
  //   color: "#041134",
  //   opacity: 0.5,
  // });

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setGenderAll([]);
    setModelExamValue([]);
    setModelExam([]);
    setSelectedDistrict({});
    setSelectedExamCenter({});
    setCenterDistrict({
      centerD: null,
      modelExamNo: null,
      no_exam_enrolled: "",
      payment_mode: null,
      startDateFilter: null,
      endDateFilter: null,
    });

    // setFilterClose({
    //   maleGender: true,
    //   FemaleGender: true,
    //   TransGender: true,
    //   Online: true,
    //   Cash: true,
    //   startDateFilter: true,
    //   endDateFilter: true,
    // });
    setClearFilterDisabled({
      Modelexamsec: false,
      districtsec: false,
      gendersec: false,
      paymentsec: false,
      datefiltersec: false,
    });
    setDistrictf([]);
    setGenderOption([]);
    setExamCenterfm([]);
    setOpenFilterModal(false);
    setUpdateListData(listData);
    // setUpdateChipData(
    //   updateChipData.filter((item) => item.type === "model_exam_name")
    // );
    setUpdateChipData([]);
    setExamCenterOption([]);
  };

  // load table data

  function loadListdata() {
    let tempModelExam = [];
    let tempModelExamNum = [];
    let tempGender = [];
    let tempDistrict = [];
    let tempExamCenter = [];

    modelExam.map((obj) => {
      tempModelExam.push(obj.id);
    });
    genderAll.map((obj) => {
      tempGender.push(obj.name);
    });
    // console.log(districtf, "districtf");
    districtf.map((obj) => {
      tempDistrict.push(obj.id);
    });
    examCenterf.map((obj) => {
      tempExamCenter.push(obj.id);
    });
    tempModelExamNum = tempModelExam.map(Number);

    let statusFilter = {
      batch_id: props.batch_id,
      type: 4, // static as model exam
      page_size: "",
      page_no: "",
      district_id: districtf.length > 0 ? tempDistrict[0] : "",
      exam_detail_id:
        centerDistrict.modelExamNo === "0" && modelExam.length > 0
          ? tempModelExamNum
          : "",
      no_of_exams:
        centerDistrict.modelExamNo === "1" && centerDistrict.no_exam_enrolled
          ? parseInt(centerDistrict.no_exam_enrolled)
          : "",
      exam_center_cstm_pk:
        Object.keys(selectedExamCenter).length > 0 ? tempExamCenter[0] : "",
      gender: genderAll.length > 0 ? tempGender : [],
      start_date: centerDistrict.startDateFilter
        ? centerDistrict.startDateFilter
        : "",
      end_date: centerDistrict.endDateFilter
        ? centerDistrict.endDateFilter
        : "",
      payment_type: centerDistrict.payment_mode
        ? centerDistrict.payment_mode === "cash"
          ? 0
          : 1
        : "", // 1 → online ,0 → offline
      search: searchTerm.length > 0 ? searchTerm : "",
    };
    setIsLoading(true);
    AxiosInstance.post("api/batch/paymentList/byAdm", statusFilter)
      .then((res) => {
        if (res.data.status === true) {
          setIsLoading(false);
          const overviewData = res.data.data.overview;
          setFormData({
            total_no_student_enrolled: overviewData.no_of_stud,
            total_amount_collected: overviewData.amount_collected,
            male_stud_enrolled: overviewData.male_count,
            female_stud_enrolled: overviewData.female_count,
            transGender_stud_enrolled: overviewData.transgender_count,
            stud_pay_online: overviewData.online_payment_count,
            stud_pay_cash: overviewData.cash_payment_count,
            online_pay_collect: overviewData.online_payment,
            cash_payment_collect: overviewData.cash_payment,
          });
          const fiata = res.data.data.stud_data;
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          setUpdateListData(serialNumberAddedData);
          const uniqueDistricts = new Set();
          const districtData = fiata
            .filter((esssl) => {
              // Check if the district name is already in the Set
              if (uniqueDistricts.has(esssl.exam_district)) {
                return false; // Skip this element
              } else {
                // Add the district name to the Set
                uniqueDistricts.add(esssl.exam_district);
                return true; // Include this element
              }
            })
            .map((esssl) => ({
              value: esssl.exam_district,
              district_id: esssl.district_id,
              label: esssl.exam_district,
              district_code: esssl.district_code,
            }));
          setDistrict((prevState) => {
            if (prevState.length === districtData.length) {
              return districtData;
            } else if (prevState.length < districtData.length) {
              return districtData;
            } else if (prevState.length === 0) {
              return districtData;
            } else {
              return prevState;
            }
          });
          const csvData = fiata.map((el) => {
            return {
              "Full Name": el.fullname,
              "Admission ID": el.admission_id,
              "Phone Number": el.phone_no,
              Email: el.email,
              Gender: el.gender,
              "Exam District": el.exam_district,
              "No. of Exams Enrolled": el.no_of_exams,
              Amount: el.amount,
              "Applied on Date": el.applied_on,
              "Payment Type": el.payment_type === 1 ? "Online" : "Cash",
              "Model Exam Name": el.model_exam_names,
            };
          });
          setCsvListData(csvData);
        } else if (res.data.status === false) {
          setUpdateListData([]);
          setIsLoading(false);
          setFormData({
            male_stud_enrolled: 0,
            female_stud_enrolled: 0,
            transGender_stud_enrolled: 0,
            stud_pay_online: 0,
            stud_pay_cash: 0,
            online_pay_collect: 0,
            cash_payment_collect: 0,
            total_no_student_enrolled: 0,
            total_amount_collected: 0,
          });
        }
      })
      .catch((error) => {
        setUpdateListData([]);
        setIsLoading(false);
        return error;
      });
  }

  const ModelexamDropdown = (batchId) => {
    if (batchId) {
      AxiosInstance.post(`api/modelexam/list/byBatch`, {
        batch_id: batchId,
        type: 4, // default model exam type
        list_type: 0, // default as 1 for exam records tab
      })
        .then((res) => {
          if (res.data.status === true) {
            const bh = res.data.data
              .sort((a, b) => a.exam_count - b.exam_count)
              .map((item, index) => ({
                value: item.exam_detail_cstm_pk,
                label: item.exam_title,
                exam_title: item.exam_title,
                exam_count: item.exam_count,
                exam_detail_cstm_pk: item.exam_detail_cstm_pk,
                paper_type: item.paper_type,
              }));
            setModelExamOption(bh);

            const options = Array.from(
              { length: res.data.data.length },
              (_, index) => ({
                value: index + 1,
                label: (index + 1).toString(),
              })
            );

            setOptionNEEnrolled(options);

            loadListdata();
          } else {
            setModelExamOption([]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
    AxiosInstance.post("api/all/district/", {
      id: 31,
    })
      .then((res) => {
        const district_alll_data = res.data.map((item, index) => ({
          value: item.name,
          district_id: item.id,
          label: item.name,
          district_code: item.district_code,
        }));
        setDistrictAll(district_alll_data);
      })
      .catch((error) => {
        return error;
      });
  };

  // chip data load

  function loadChipdata() {
    let tempArr = [];
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    if (centerDistrict.modelExamNo === "0") {
      filterModelExam.map((obj) => {
        tempArr.push(obj);
      });
    }

    filterGender.map((obj) => {
      tempArr.push(obj);
    });
    filterDistrictCenter.map((obj) => {
      tempArr.push(obj);
    });
    filterCenterDistrict.map((obj) => {
      tempArr.push(obj);
    });

    if (centerDistrict.modelExamNo === "1") {
      filterNoExamEnrolled.map((obj) => {
        tempArr.push(obj);
      });
    }
    filterPaymentType.map((obj) => {
      tempArr.push(obj);
    });
    filterDate.map((obj) => {
      tempArr.push(obj);
    });
    setUpdateChipData(tempArr);
  }

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "model_exam_name") {
      setModelExamValue(
        ModelExamValue.filter((item) => item.id !== chipToDelete.id)
      );
      filterModelExam = modelExam.filter((item) => item.id !== chipToDelete.id);
      if (Object.keys(selectedDistrict).length > 0) {
        setCenterDistrict((prevState) => ({
          ...prevState,
          modelExamNo: null,
          centerD: prevState.centerD === "0" ? prevState.centerD : "0",
        }));
      } else {
        setCenterDistrict((prevState) => ({
          ...prevState,
          modelExamNo: null,
        }));
      }
      setModelExam(modelExam.filter((item) => item.id !== chipToDelete.id));
      setExamCenterfm([]);
      filterCenterDistrict = [];
      setSelectedExamCenter({});
      setClearFilterDisabled((prevState) => ({
        ...prevState,
        Modelexamsec: false,
      }));
    } else if (chipToDelete.type === "gender") {
      // if (chipToDelete.id === "Male") {
      //   setFilterClose((prevState) => ({
      //     maleGender:
      //       prevState.TransGender === true || prevState.FemaleGender === true
      //         ? false
      //         : true,
      //     TransGender:
      //       prevState.maleGender === false ||
      //       prevState.FemaleGender === false ||
      //       prevState.TransGender === true
      //         ? true
      //         : false,
      //     FemaleGender:
      //       prevState.maleGender === false ||
      //       prevState.TransGender === false ||
      //       prevState.FemaleGender === true
      //         ? true
      //         : false,
      //     Online: true,
      //     Cash: true,
      //     endDateFilter: true,
      //   }));
      // }
      // if (chipToDelete.id === "Female") {
      //   setFilterClose((prevState) => ({
      //     FemaleGender:
      //       prevState.TransGender === true || prevState.maleGender === true
      //         ? false
      //         : true,
      //     TransGender:
      //       prevState.FemaleGender === false ||
      //       prevState.maleGender === false ||
      //       prevState.TransGender === true
      //         ? true
      //         : false,
      //     maleGender:
      //       prevState.maleGender === true ||
      //       prevState.TransGender === false ||
      //       prevState.FemaleGender === false
      //         ? true
      //         : false,
      //     Online: true,
      //     Cash: true,
      //     endDateFilter: true,
      //   }));
      // }
      // if (chipToDelete.id === "Transgender") {
      //   setFilterClose((prevState) => ({
      //     TransGender:
      //       prevState.FemaleGender === true || prevState.maleGender === true
      //         ? false
      //         : true,
      //     FemaleGender:
      //       prevState.TransGender === false ||
      //       prevState.maleGender === false ||
      //       prevState.FemaleGender === true
      //         ? true
      //         : false,
      //     maleGender:
      //       prevState.TransGender === false ||
      //       prevState.FemaleGender === false ||
      //       prevState.maleGender === true
      //         ? true
      //         : false,
      //     Online: true,
      //     Cash: true,
      //     endDateFilter: true,
      //   }));
      // }
      setGenderAll(genderAll.filter((item) => item.id !== chipToDelete.id));
      filterGender = genderAll.filter((item) => item.id !== chipToDelete.id);
      setGenderOption(genderOption.filter((item) => item !== chipToDelete.id));
      setClearFilterDisabled((prevState) => ({
        ...prevState,
        gendersec: genderOption.length > 0 ? true : false,
      }));
    } else if (chipToDelete.type === "district") {
      setDistrictf(districtf.filter((item) => item.id !== chipToDelete.id));
      filterDistrictCenter = districtf.filter(
        (item) => item.id !== chipToDelete.id
      );
      setCenterDistrict((prevState) => ({
        ...prevState,

        centerD: null,
      }));
      // setCenterDistrict((prevState) => ({
      //   ...prevState,
      //   centerD: null,
      // }));
      setExamCenterfm([]);
      setSelectedDistrict({});
      setSelectedExamCenter({});
      // setFilterClose((prevState) => ({
      //   maleGender: true,
      //   TransGender: true,
      //   FemaleGender: true,
      //   Online: true,
      //   Cash: true,
      //   endDateFilter: true,
      // }));
      filterCenterDistrict = [];
      setSelectedExamCenter({});
      setClearFilterDisabled((prevState) => ({
        ...prevState,
        districtsec: false,
      }));
    } else if (chipToDelete.type === "exam_center") {
      setExamCenterfm(
        examCenterf.filter((item) => item.id !== chipToDelete.id)
      );
      filterCenterDistrict = examCenterf.filter(
        (item) => item.id !== chipToDelete.id
      );
      setCenterDistrict((prevState) => ({
        ...prevState,
        centerD: "0",
      }));
      setSelectedExamCenter({});
      if (Object.keys(selectedDistrict).length === 0) {
        setClearFilterDisabled((prevState) => ({
          ...prevState,
          districtsec: false,
        }));
        setCenterDistrict((prevState) => ({
          ...prevState,
          centerD: null,
        }));
      }
    } else if (chipToDelete.type === "DateFilter") {
      setExamDateFilter(
        examDateFilter.filter((item) => item.id !== chipToDelete.id)
      );
      filterDate = examDateFilter.filter((item) => item.id !== chipToDelete.id);
      setCenterDistrict((prevState) => ({
        ...prevState,
        startDateFilter: null,
        endDateFilter: null,
      }));
      setClearFilterDisabled((prevState) => ({
        ...prevState,
        datefiltersec: false,
      }));
    } else if (chipToDelete.type === "no_exam_enrolled") {
      setNoExamEnrolled(
        noExamEnrolled.filter((item) => item.id !== chipToDelete.id)
      );
      filterNoExamEnrolled = noExamEnrolled.filter(
        (item) => item.id !== chipToDelete.id
      );
      setCenterDistrict((prevState) => ({
        ...prevState,
        no_exam_enrolled: "",
        modelExamNo: null,
      }));
      // setFilterClose((prevState) => ({
      //   maleGender: true,
      //   TransGender: true,
      //   FemaleGender: true,
      //   Online: true,
      //   Cash: true,
      //   endDateFilter: true,
      // }));
      setClearFilterDisabled((prevState) => ({
        ...prevState,
        Modelexamsec: false,
      }));
    } else if (chipToDelete.type === "payment_mode") {
      setPaymentType(paymentType.filter((item) => item.id !== chipToDelete.id));
      filterPaymentType = paymentType.filter(
        (item) => item.id !== chipToDelete.id
      );
      setClearFilterDisabled((prevState) => ({
        ...prevState,
        paymentsec: false,
      }));
      setCenterDistrict((prevState) => ({
        ...prevState,
        payment_mode: null,
      }));
      // if (chipToDelete.id === "online") {
      //   setFilterClose((prevState) => ({
      //     ...prevState,
      //     Online: true,
      //     // Cash: prevState.Cash === false ? true : true,
      //     // maleGender: prevState.maleGender === false ? true : true,
      //     // TransGender: prevState.TransGender === false ? true : true,
      //     // FemaleGender: prevState.FemaleGender === false ? true : true,
      //   }));
      // } else if (chipToDelete.id === "cash") {
      //   setFilterClose((prevState) => ({
      //     ...prevState,
      //     Cash: true,
      //     // Online: prevState.Online === false ? true : true,
      //     // maleGender: prevState.maleGender === false ? true : true,
      //     // TransGender: prevState.TransGender === false ? true : true,
      //     // FemaleGender: prevState.FemaleGender === false ? true : true,
      //   }));
      // }
      // console.log(filterModelExam, "filterModelExam");
    }
    // console.log(filterDate, "delete function");
    loadChipdata();
  };

  const examCenterDistrict = (DistrictID) => {
    if (DistrictID) {
      AxiosInstance.post("api/modelexam/examCenter/byDistrict", {
        batch_id: props.batch_id,
        type: 4,
        district_id: DistrictID,
      })
        .then((res) => {
          if (res.data.status === true) {
            const exOption = res.data.data.map((item, index) => ({
              value: item.exam_center_cstm_pk,
              label: item.exam_center_name,
              district_id: item.district_id,
              exam_detail_id: item.exam_detail_id,
            }));
            setExamCenterOption(exOption);
          } else {
            setExamCenterOption({});
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  const handleExamChange = (selected, names) => {
    if (names === "model_exam_name") {
      if (selected) {
        const OptionSelected = selected.map((v) => ({
          value: v.value,
          label: v.label,
          id: v.value,
          name: v.label,
        }));
        setModelExamValue(OptionSelected);
        if (selected.length > 1) {
          setSelectedExamCenter({});
          if (centerDistrict.centerD === "1") {
            setSelectedDistrict({});
            filterDistrictCenter = [];
            filterCenterDistrict = [];
            setCenterDistrict((prevState) => ({
              ...prevState,
              centerD: null,
            }));
          }
          setClearFilterDisabled((prevState) => ({
            ...prevState,
            districtsec: false,
          }));
        }
        setClearFilterDisabled((ert) => ({
          ...ert,
          Modelexamsec: true,
        }));
        setDistrict([]);
        DistrictData(
          props.batch_id,
          selected.map((v) => v.value)
        );
      } else {
        setModelExamValue([]);
        setClearFilterDisabled((ert) => ({
          ...ert,
          Modelexamsec: false,
        }));
        setDistrict([]);
      }
    } else if (names === "no_exam_enrolled") {
      if (selected) {
        setCenterDistrict((prevState) => ({
          ...prevState,
          no_exam_enrolled: selected.value,
        }));
        setClearFilterDisabled((ert) => ({
          ...ert,
          Modelexamsec: true,
        }));
      } else {
        setCenterDistrict((prevState) => ({
          ...prevState,
          no_exam_enrolled: "",
        }));
        setClearFilterDisabled((ert) => ({
          ...ert,
          Modelexamsec: false,
        }));
      }
    }
  };

  function DistrictData(BID, EDID) {
    AxiosInstance.post("api/modelexam/district", {
      batch_id: BID,
      exam_details_id: EDID,
    })
      .then((res) => {
        const districtsss = res.data.data.map((item, index) => ({
          value: item.exam_center_district,
          district_id: item.district_id,
          label: item.exam_center_district,
          district_code: item.exam_district_code,
        }));
        setDistrict(districtsss);
      })
      .catch((error) => {
        return error;
      });
  }
  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const handleReadView = (id, type, useridd) => {
    navigate(
      `/TransactionHistory/ViewAdmission/${useridd}/${props.batch_id}/${id}/${type}/1`
    );
  };
  const handleAllClearFilter = () => {
    setSearchTerm("");
    setGenderAll([]);
    setModelExamValue([]);
    setClearAllFilterBut(true);
    setSelectedDistrict({});
    setSelectedExamCenter({});
    setCenterDistrict({
      centerD: null,
      modelExamNo: null,
      no_exam_enrolled: "",
      payment_mode: null,
      startDateFilter: null,
      endDateFilter: null,
    });
    // setFilterClose({
    //   maleGender: true,
    //   FemaleGender: true,
    //   TransGender: true,
    //   Online: true,
    //   Cash: true,
    //   startDateFilter: true,
    //   endDateFilter: true,
    // });
    setClearFilterDisabled({
      Modelexamsec: false,
      districtsec: false,
      gendersec: false,
      paymentsec: false,
      datefiltersec: false,
    });
    setDistrictf([]);
    setGenderOption([]);
    setExamCenterfm([]);
    // setOpenFilterModal(false);
    // setUpdateListData(listData);
    // setUpdateChipData([]);
    setExamCenterOption([]);
  };

  const handleExamRecordsFilter = (event, dname) => {
    const { name, value, checked } = event.target;

    if (dname === "centerD") {
      if (checked) {
        setCenterDistrict((prevState) => ({
          ...prevState,
          [dname]: value,
        }));
        if (value === "1") {
          clearDistrictFilter();
        }
        setError((prevState) => ({
          ...prevState,
          ["exam_center_name"]: "",
        }));
      } else {
        setCenterDistrict((prevState) => ({
          ...prevState,
          [dname]: "",
        }));
      }
    } else if (dname === "modelExamNo") {
      if (checked) {
        if (ModelExamValue.length === 0 && noExamEnrolled.length === 0) {
          setModelExamValue([]);
          setModelExam([]);
          setCenterDistrict((prevState) => ({
            ...prevState,
            [dname]: value,
          }));
        } else {
          setCenterDistrict((prevState) => ({
            ...prevState,
            [dname]: value,
          }));
        }
        setNoExamEnrolled([]);
      } else {
        setModelExamValue([]);
        setNoExamEnrolled([]);
        setModelExam([]);
        setCenterDistrict((prevState) => ({
          ...prevState,
          [dname]: "",
          ["no_exam_enrolled"]: "",
        }));
      }
    } else if (dname === "payment_mode") {
      // console.log(dname, "dnameeee", checked, value);
      if (checked) {
        setCenterDistrict((prevState) => ({
          ...prevState,
          [dname]: value,
        }));
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          paymentsec: true,
        }));
      } else {
        setCenterDistrict((prevState) => ({
          ...prevState,
          ["payment_mode"]: "",
        }));
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          paymentsec: false,
        }));
      }
    } else if (dname === "no_exam_enrolled") {
      setCenterDistrict((prevState) => ({
        ...prevState,
        [dname]: value,
      }));
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        Modelexamsec: true,
      }));
    } else if (dname === "gender") {
      if (checked) {
        setGenderOption([...genderOption, value]);

        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          gendersec: true,
        }));
      } else {
        setGenderOption(genderOption.filter((item) => item !== value));
        if (genderOption.length === 1) {
          setClearFilterDisabled((prevFields) => ({
            ...prevFields,
            gendersec: false,
          }));
        }
      }
    }
  };

  const clearDistrictFilter = () => {
    setDistrictf([]);
    setExamCenterfm([]);
    setSelectedDistrict({});
    setSelectedExamCenter({});
    // loadChipdata();
  };

  const handleExamDistrict = (option, dnames) => {
    if (dnames === "district_name") {
      if (option) {
        const OptionSelectedDis = {
          value: option.value,
          district_id: option.district_id,
          label: option.label,
          district_code: option.district_code,
        };
        setError((prevState) => ({
          ...prevState,
          ["district_name"]: "",
        }));
        setSelectedDistrict(OptionSelectedDis);
        setSelectedExamCenter({});
        examCenterDistrict(option.district_id);
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          districtsec: true,
        }));
      } else {
        setSelectedExamCenter({});
        setExamCenterfm([]);
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          districtsec: false,
        }));
        setDistrictf([]);
        setSelectedDistrict({});
      }
    } else if (dnames === "exam_center_name") {
      if (option) {
        if (Object.keys(selectedDistrict).length > 0) {
          const OptionSelectedCenter = {
            value: option.value,
            label: option.label,
            district_id: option.district_id,
          };
          setSelectedExamCenter(OptionSelectedCenter);
          setError((prevState) => ({
            ...prevState,
            ["exam_center_name"]: "",
            ["district_name"]: "",
          }));

          setClearFilterDisabled((prevFields) => ({
            ...prevFields,
            districtsec: true,
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            ["exam_center_name"]: "Please select district",
          }));
          setSelectedExamCenter({});
          setClearFilterDisabled((prevFields) => ({
            ...prevFields,
            districtsec: false,
          }));
        }
      } else {
        setSelectedExamCenter({});
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          districtsec: false,
        }));
      }
    }
  };

  const handleClearSection = (cname) => {
    if (cname === "gender") {
      setGenderOption([]);
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        gendersec: false,
      }));
      setClearAllFilterBut(true);
    } else if (cname === "centerD") {
      setCenterDistrict((prevFields) => ({
        ...prevFields,
        centerD: "",
      }));
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        districtsec: false,
      }));
      setSelectedDistrict({});
      setSelectedExamCenter({});
      setExamCenterOption([]);
      setExamCenterfm([]);
      setDistrictf([]);
      setClearAllFilterBut(true);
    } else if (cname === "model_exam") {
      setCenterDistrict((prevFields) => ({
        ...prevFields,
        no_exam_enrolled: "",
        modelExamNo: "",
      }));
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        Modelexamsec: false,
      }));
      setModelExamValue([]);
      setModelExam([]);
      setClearAllFilterBut(true);
    } else if (cname === "payment_mode") {
      setCenterDistrict((prevFields) => ({
        ...prevFields,
        payment_mode: "",
      }));
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        paymentsec: false,
      }));
      // setFilterClose((prevFields) => ({
      //   ...prevFields,
      //   Online: true,
      //   Cash: true,
      // }));
      setClearAllFilterBut(true);
    } else if (cname === "dateFilter") {
      setCenterDistrict((prevFields) => ({
        ...prevFields,
        startDateFilter: null,
        endDateFilter: null,
      }));
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        datefiltersec: false,
      }));
      setClearAllFilterBut(true);
    }
  };
  // filter apply button
  const handleApplyFilterAll = () => {
    if (
      centerDistrict.startDateFilter !== null &&
      centerDistrict.endDateFilter === null
    ) {
      const errorss = validatEDateFields(
        centerDistrict.startDateFilter,
        centerDistrict.endDateFilter
      );
      if (Object.keys(errorss).length === 0) {
        applyFilter();
      } else {
        setError(errorss);
      }
    } else if (
      (centerDistrict.centerD === "1" &&
        Object.keys(selectedDistrict).length > 0 &&
        Object.keys(selectedExamCenter).length === 0) ||
      (centerDistrict.centerD === "1" &&
        Object.keys(selectedDistrict).length === 0 &&
        Object.keys(selectedExamCenter).length === 0)
    ) {
      const errorx = validateExamCenter(selectedDistrict, selectedExamCenter);
      if (Object.keys(errorx).length === 0) {
        applyFilter();
      } else {
        setError(errorx);
      }
    } else {
      applyFilter();
    }
  };

  const applyFilter = () => {
    updateStateData();
    if (centerDistrict.modelExamNo === "0") {
      const modelExamOne = ModelExamValue.map((el) => ({
        id: el.id,
        event_pk: el.event_pk,
        name: el.name,
        type: "model_exam_name",
      }));
      filterModelExam = modelExamOne;
      setModelExam(modelExamOne);
      setCenterDistrict((prevState) => ({
        ...prevState,
        no_exam_enrolled: "",
      }));
    }
    const genderfil = genderOption.map((el) => {
      return {
        id: el,
        name: el,
        type: "gender",
      };
    });
    filterGender = genderfil;
    setGenderAll(genderfil);

    if (Object.keys(selectedDistrict).length > 0) {
      const districtFil = [
        {
          id: selectedDistrict.district_id,
          name: selectedDistrict.value,
          type: "district",
          district_code: selectedDistrict.district_code,
        },
      ];
      filterDistrictCenter = districtFil;
      setDistrictf(districtFil);
    } else {
      filterDistrictCenter = [];
    }
    if (Object.keys(selectedExamCenter).length > 0) {
      const examcenterDF = [
        {
          id: selectedExamCenter.value,
          name: selectedExamCenter.label,
          type: "exam_center",
          district_id: selectedExamCenter.district_id,
        },
      ];
      filterCenterDistrict = examcenterDF;
      setExamCenterfm(examcenterDF);
    } else {
      filterCenterDistrict = [];
    }
    if (
      centerDistrict.no_exam_enrolled !== "" &&
      centerDistrict.modelExamNo === "1"
    ) {
      const no_of_enrolled = [
        {
          id: centerDistrict.no_exam_enrolled,
          name: centerDistrict.no_exam_enrolled,
          type: "no_exam_enrolled",
        },
      ];
      filterNoExamEnrolled = no_of_enrolled;
      setNoExamEnrolled(no_of_enrolled);
      setModelExamValue([]);
      filterModelExam = [];
    }
    if (
      centerDistrict.startDateFilter !== null &&
      centerDistrict.endDateFilter === null
    ) {
      const dateFil = [
        {
          id: `${centerDistrict.startDateFilter} `,
          name: `${
            centerDistrict.startDateFilter
              ? moment(centerDistrict.startDateFilter).format("DD/MM/YYYY")
              : ""
          } `,
          type: "DateFilter",
          startDate: centerDistrict.startDateFilter,
          endDate: centerDistrict.endDateFilter,
        },
      ];
      filterDate = dateFil;
      setExamDateFilter(dateFil);
    } else if (
      centerDistrict.startDateFilter !== null &&
      centerDistrict.endDateFilter !== null
    ) {
      const dateFil = [
        {
          id: `${centerDistrict.startDateFilter} - ${centerDistrict.endDateFilter}`,
          name: `${
            centerDistrict.startDateFilter
              ? moment(centerDistrict.startDateFilter).format("DD/MM/YYYY")
              : ""
          } - ${
            centerDistrict.endDateFilter
              ? moment(centerDistrict.endDateFilter).format("DD/MM/YYYY")
              : ""
          }`,
          type: "DateFilter",
          startDate: centerDistrict.startDateFilter,
          endDate: centerDistrict.endDateFilter,
        },
      ];
      filterDate = dateFil;
      setExamDateFilter(dateFil);
    }
    if (
      centerDistrict.payment_mode !== "" &&
      centerDistrict.payment_mode !== null
    ) {
      // if (centerDistrict.payment_mode === "online") {
      //   setFilterClose((prevState) => ({
      //     ...prevState,
      //     Online: true,
      //   }));
      // } else if (centerDistrict.payment_mode === "cash") {
      //   setFilterClose((prevState) => ({
      //     ...prevState,
      //     Cash: true,
      //   }));
      // }
      const payment_typess = [
        {
          id: centerDistrict.payment_mode,
          name: centerDistrict.payment_mode,
          type: "payment_mode",
        },
      ];
      filterPaymentType = payment_typess;
      setPaymentType(payment_typess);
    }
    loadChipdata();
    setOpenFilterModal(false);
    setClearAllFilterBut(false);
  };

  const validatEDateFields = (sdate, edate) => {
    const errors = {};
    const dateDOB = moment(sdate).format("YYYY-MM-DD");
    const currentToDate = moment().format("YYYY-MM-DD");
    if (dateDOB !== currentToDate) {
      errors.endDateFilter = "Please choose end date";
    }
    return errors;
  };

  const validateExamCenter = (district, examcenter) => {
    const errorx = {};

    if (
      Object.keys(district).length > 0 &&
      Object.keys(selectedExamCenter).length === 0
    ) {
      errorx.exam_center_name = "Please choose exam center";
    } else if (
      Object.keys(district).length === 0 &&
      Object.keys(selectedExamCenter).length === 0
    ) {
      errorx.district_name = "Please choose district";
      errorx.exam_center_name = "Please choose exam center";
    }
    return errorx;
  };

  const handleChangeDateFilter = (date, namess) => {
    const dateDOB = moment(date).format("YYYY-MM-DD");
    const currentToDate = moment().format("YYYY-MM-DD");
    if (namess === "startDateFilter") {
      setCenterDistrict((prevState) => ({
        ...prevState,
        [namess]: dateDOB,
        endDateFilter:
          dateDOB === currentToDate
            ? null
            : prevState.endDateFilter !== null
            ? prevState.endDateFilter
            : null,
      }));
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        datefiltersec: true,
      }));
    } else {
      setClearFilterDisabled((prevFields) => ({
        ...prevFields,
        datefiltersec: false,
      }));
    }
    if (namess === "endDateFilter") {
      if (
        centerDistrict.startDateFilter !== "" &&
        centerDistrict.startDateFilter !== null
      ) {
        setCenterDistrict((prevState) => ({
          ...prevState,
          [namess]: dateDOB,
        }));
        setError((prevState) => ({
          ...prevState,
          ["endDateFilter"]: "",
        }));
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          datefiltersec: true,
        }));
      } else {
        setCenterDistrict((prevState) => ({
          ...prevState,
          [namess]: "",
        }));
        setError((prevState) => ({
          ...prevState,
          ["endDateFilter"]: "Please Choose Start Date First",
        }));
        setClearFilterDisabled((prevFields) => ({
          ...prevFields,
          datefiltersec: false,
        }));
      }
    }
  };

  // console.log(districtAll, "districtAll");

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div
      className={
        props.activeTab === 1 ? "tab-content mt-4" : "tab-content mt-4 hide "
      }
      id="ex2-content"
    >
      <div className="row ">
        {openFilterModal && (
          <ExamAdvancedFilterAdmin
            examCenterOption={examCenterOption}
            handleClearSection={handleClearSection}
            selectedExamCenter={selectedExamCenter}
            optionNEEnrolled={optionNEEnrolled}
            selectedDistrict={selectedDistrict}
            district_alll_data={districtAll}
            txtFilterAPayments={txtFilterAPayments}
            clearFilterDisabled={clearFilterDisabled}
            handleExamDistrict={handleExamDistrict}
            txtFilterSDAPayments={txtFilterSDAPayments}
            district={district}
            handleApplyFilterAll={handleApplyFilterAll}
            handleChangeDateFilter={handleChangeDateFilter}
            genderOption={genderOption}
            centerDistrict={centerDistrict}
            handleExamRecordsFilter={handleExamRecordsFilter}
            modelExamOption={modelExamOption}
            handleExamChange={handleExamChange}
            ModelExamValue={ModelExamValue}
            show={openFilterModal}
            handleFilterClose={handleFilterClose}
            handleAllClearFilter={handleAllClearFilter}
            ClearAllFilterBut={ClearAllFilterBut}
            error={error}
          />
        )}
        <div className="col-lg-4 col-md-12">
          <div class="input-group">
            <input
              ref={txtBSearch}
              type="text"
              class="Inputs_searchList"
              id="SearchInput"
              name="SearchInput"
              placeholder="Search by Name, ID, Phone Number or Email"
              onKeyDown={handleSearch}
            />
            <span
              class="input-group-text"
              id="SearchInput"
              onClick={handleSearchFocus}
            >
              <span onClick={handleSearchFocus} className="material-icons">
                search
              </span>
            </span>
          </div>
        </div>
        <div className="col-lg-2 col-md-2"></div>
        <div className="col-lg-6  dflex col-md-12 justify-content-md-between justify-content-lg-end">
          <button
            type="button"
            className="ListViewBtn me-3"
            onClick={() => OpenFilterModel()}
          >
            <span className="material-icons me-2 fs-4 align-middle text-white">
              filter_list
            </span>
            Advanced Filters
          </button>
          <button type="button" className="ListViewBtn">
            <span className="material-icons me-2 fs-4 align-middle text-white">
              file_download
            </span>
            <CSVLink data={csvListData} filename={"AdmissionPayment.csv"}>
              Download as CSV
            </CSVLink>
          </button>
        </div>
      </div>

      {updateChipData.length > 0 ? (
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {updateChipData.map((data, index) => {
                let icon;
                let keyss = "";
                let keyWords = "";
                if (data.type === "model_exam_name") {
                  keyss = "Model Exam Name ";
                  keyWords = "" + data.name;
                } else if (data.type === "gender") {
                  keyss = " " + "Gender ";
                  keyWords = "" + data.name;
                } else if (data.type === "district") {
                  keyss = " " + "District ";
                  keyWords = "" + data.name;
                } else if (data.type === "exam_center") {
                  keyss = " " + "Exam Center ";
                  if (data.name.length > 10) {
                    keyWords = data.name.substring(0, 10) + "...";
                  } else {
                    keyWords = data.name;
                  }
                } else if (data.type === "payment_mode") {
                  keyss = " " + "Payment Mode ";
                  keyWords = "" + data.name;
                } else if (data.type === "DateFilter") {
                  keyss = "" + "Admission Date ";
                  keyWords = " " + data.name;
                } else if (data.type === "no_exam_enrolled") {
                  keyss = "" + "No. of Exams Enrolled ";
                  keyWords = " " + data.name;
                } else if (data.type === "search") {
                  keyss = "" + "Searched Keyword";
                  keyWords = " " + data.name;
                }
                return (
                  <ListItem key={data}>
                    <Chip
                      icon={icon}
                      label={
                        <>
                          <span className="BoldTy">{keyss}</span>
                          <span className="BoldTyText">{keyWords}</span>
                        </>
                      }
                      onDelete={handleDelete(data, index)}
                    />
                  </ListItem>
                );
              })}

              <ListItem>
                <a className="allclear" href="#" onClick={handleAllClear}>
                  Clear All Filters
                </a>
              </ListItem>
            </Paper>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <section className="StepOne">
        <div className="row box">
          <div className="col-md-12">
            <p className="StepTitle">Admission Payment Overview</p>

            <div className="row mb-3">
              {/* {filterClose.maleGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Male Students Enrolled
                  </label>
                  <span className="ReviewLabel">
                    {formData.male_stud_enrolled || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              {/* {filterClose.FemaleGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Female Students Enrolled
                  </label>
                  <span className="ReviewLabel">
                    {formData.female_stud_enrolled || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              {/* {filterClose.TransGender === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Transgender Students Enrolled
                  </label>
                  <span className="ReviewLabel">
                    {formData.transGender_stud_enrolled || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Total Number of Students Enrolled
                  </label>
                  <span className="ReviewLabel">
                    {formData.total_no_student_enrolled || "_"}
                  </span>
                </div>
              </div>
              {/* {filterClose.Online === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Students Paid Online
                  </label>
                  <span className="ReviewLabel">
                    {formData.stud_pay_online || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}

              {/* {filterClose.Online === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Online Payments Collected
                  </label>
                  <span className="ReviewLabel">
                    {formData.online_pay_collect || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}

              {/* {filterClose.Cash === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Students Paid in Cash
                  </label>
                  <span className="ReviewLabel">
                    {formData.stud_pay_cash || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}

              {/* {filterClose.Cash === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Cash Payments Collected
                  </label>
                  <span className="ReviewLabel">
                    {formData.cash_payment_collect
                      ? formData.cash_payment_collect.toFixed(2)
                      : "_" || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
              {/* {filterClose.Cash === true && filterClose.Online === true && ( */}
              <div className="col-md-3">
                <div className="forms ">
                  <label htmlFor="inputName" className="form-label text-muted ">
                    Total Amount Collected (In Rupees)
                  </label>
                  <span className="ReviewLabel">
                    {formData.total_amount_collected
                      ? formData.total_amount_collected.toFixed(2)
                      : "_" || "_"}
                  </span>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </section>
      <div className="row mt-4">
        <div className="col-md-12 table-responsive">
          <table className="table" id="example">
            <thead>
              <tr>
                <th id="th" style={{ width: "5% !important" }}>
                  {" "}
                  <span
                    onClick={(e) => handleSort(e, "serialNumber")}
                    className="text_th"
                  >
                    S.No
                  </span>
                  <span
                    onClick={(e) => handleSort(e, "serialNumber")}
                    class="material-icons align-middle"
                  >
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "fullname")}
                  >
                    Name
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "fullname")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "admission_id")}
                  >
                    Admission ID
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "admission_id")}
                  >
                    {" "}
                    import_export{" "}
                  </span>{" "}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    Phone Number
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "email")}
                  >
                    Email ID
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "email")}
                  >
                    import_export
                  </span>{" "}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "gender")}
                  >
                    Gender
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "gender")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "exam_district")}
                  >
                    Preferred Exam District
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "exam_district")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "no_of_exams")}
                  >
                    {" "}
                    No .of Exams Enrolled
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "no_of_exams")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "amount")}
                  >
                    Paid Amount
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "amount")}
                  >
                    import_export
                  </span>{" "}
                </th>

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "applied_on")}
                  >
                    Date of Enrollment
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "applied_on")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "payment_type")}
                  >
                    Mode of Payment
                  </span>{" "}
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "payment_type")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span className="text_th">Action</span>{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td
                    colspan="15"
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid #dee2e6",
                    }}
                  >
                    No record to display
                  </td>
                </tr>
              ) : (
                currentData.map((course, index) => (
                  <tr key={index} title={course.pk}>
                    <td id="td">{course.serialNumber}</td>
                    <td id="td"> {course.fullname}</td>
                    <td id="td">{course.admission_id}</td>
                    <td id="td"> {course.phone_no}</td>
                    <td id="td">{course.email}</td>
                    <td id="td">{course.gender}</td>
                    <td id="td">{course.exam_district}</td>
                    <td id="td">{course.no_of_exams}</td>
                    <td id="td">{course.amount}</td>
                    <td id="td">
                      {course.applied_on
                        ? moment(course.applied_on, "DD-MM-YYYY").format(
                            "DD/MM/YYYY"
                          )
                        : "_"}
                    </td>
                    <td id="td">
                      {course.payment_type === 1 ? "Online" : "Cash"}
                    </td>
                    <td id="td">
                      <button
                        className="button"
                        onClick={() =>
                          handleReadView(course.pk, 4, course.user_id)
                        }
                      >
                        <span class="material-icons">visibility</span>
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="pag w-100 dflexcenter">
            <span className="ipage">Items Per Page</span>
            <select
              name="pageCount"
              id="pageCount"
              defaultValue={rowsPerPage}
              onChange={handlePageCount}
              className="ms-2"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pagenumber ms-2">
              {" "}
              {updateListData.length > 0
                ? currentPage * rowsPerPage + 1 - rowsPerPage
                : 0}{" "}
              -{" "}
              {updateListData.length < currentPage * rowsPerPage
                ? updateListData.length
                : currentPage * rowsPerPage}{" "}
              of {updateListData.length}
            </span>
            <button
              className="prev_button ms-2 me-2"
              onClick={(e) => handlePrev(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span class="material-icons">chevron_left</span>
            </button>
            <button
              className="prev_button"
              onClick={() => handleNext(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <span class="material-icons">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdmissionPayments;
