
import React, { useEffect, useState } from "react";
import TeachingListview from "./TeachingListview";
import NteachingListView from "./NteachingListView";
import PanelMembersLiistview from "./PanelMembersLiistview";
import { Link, useNavigate, useParams } from "react-router-dom";
import FaclityDetailProfile from "./FaclityDetailProfile";
import Faculityschedule from "./Faculityschedule";
import SalaryListView from "./SalaryListView";
import TeachingProgress from "./normalFaculity/TeachingProgress";



function FacultyDetailview() {
  const {userId , staff_id , active_tab} = useParams();

  const navigate = useNavigate();

 const [activeTab, setActiveTab] = useState(1);

 useEffect(() => {
   // Update the activeTab state when the component mounts and whenever active_tab changes in the URL
   setActiveTab(Number(active_tab) || 1);
   // setActiveTab(1);
 }, [active_tab]);


 const handleTabClick = (index) => {
   setActiveTab(index);
   navigate(`/Faculties/FacultyDetailView/${userId}/${staff_id}/${index}`);

 };
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };


  return (
    <div className=" BathDet ">
      <div className="row">
        <div className="col-lg-3 col-md-5 col-6 col-sm-6">
          <h6 className="Title">View Faculty Profile</h6>
        </div>
        <div className=" col-lg-9 col-md-7  col-6 col-sm-6 d-flex justify-content-end  align-items-center ">
        <Link to="/Faculties/1" className="CancelButtons mobileCanclebutton">
              Back to Faculties
         </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
        {/* nav-tabs */}
          <ul  
            className="nav  nav-fill mb-3"
            id="listViewTabs"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 1 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-2"
                aria-selected={activeTab === 1}
                onClick={() => handleTabClick(1)}
              >
              Schedule
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 2 ? "nav-link active " : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex3-tabs-4"
                aria-selected={activeTab === 2}
                onClick={() => handleTabClick(2)}
              >
               Teaching Progress
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 3 ? "nav-link active " : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex3-tabs-3"
                aria-selected={activeTab === 3}
                onClick={() => handleTabClick(3)}
              >
               Salary
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 4 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-1"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-1"
                aria-selected={activeTab === 4}
                onClick={() => handleTabClick(4)}
              >
                Profile
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="borderActive"></div> */}
      </div>
{/* // tab content   */}
<Faculityschedule  staff_id={staff_id}  activeTab={activeTab} />

  <FaclityDetailProfile  staff_id={staff_id} userId={userId} activeTab={activeTab} />

<SalaryListView staff_type={0}  staff_id={staff_id} userId={userId} activeTab={activeTab} />

<TeachingProgress  staff_id={staff_id} userId={userId} activeTab={activeTab} />

    </div>
  );
}

export default FacultyDetailview;


