// DEPENDENCIES
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ICON_BACK from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import toast from "react-hot-toast";
import moment from "moment";
// CUSTOM COMPONENTS
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";
import HorizontalBarChart from "../components/HorizontalBarChart";
import DonutChart from "../components/DonutChart";
import PieChart from "../components/PieChart";
import TestDetailsNumbers from "../components/TestDetailsNumbers";
import IMAGE_outstanding from "../../../../../img/testmodule/outstanding.png";
import IMAGE_Excellent from "../../../../../img/testmodule/Excellent_img.png";
import IMAGE_VGood from "../../../../../img/testmodule/VeryGood_Img.png";
import IMAGE_Good from "../../../../../img/testmodule/Good_Img.png";
import IMAGE_Average from "../../../../../img/testmodule/Average_img.png";
import IMAGE_AAverage from "../../../../../img/testmodule/AboveAverage_Img.png";
import IMAGE_BAverage from "../../../../../img/testmodule/BelowAverage_Img.png";

// UI COMPONENTS
import FloatedTitleContainer from "../../../../../ui/FloatedTitleContainer";
import PrimaryButton from "../../../../../ui/PrimaryButton";
import NavigationButton from "../../../../../ui/NavigationButton";
import SecondaryButton from "../../../../../ui/SecondaryButton";
import CustomContainer from "../../../../../ui/CustomContainer";
import SkeletonLoaderPage from "../../../../../ui/SkeletonLoaderPage";
import ButtonContainer from "../../../../../ui/ButtonContainer";
import StyledTitle from "../../../../../ui/StyledTitle";
// CONFIG
import { AxiosInstance } from "../../../../../axios";
import { ImageUrlPrefix } from "../../../../../labels";
import {
  apiSubjectListforTimeTakenChart,
  getTestTypes,
  perfomanceBasicInfo,
  studentPerfomanceChart,
} from "../../../../../services/services";
import {
  CHART_OVERALL,
  CHART_PERFOMANCE,
  CHART_PROFICIENCY_SUBJECTS,
  CHART_QUESTION_TYPE,
  CHART_SUBJECT_TIME_TAKEN,
  DONUTBACKGROUNDCOLOR,
  MEDIA_SIZE_LG,
  MEDIA_SIZE_MD,
  QUESTION_TYPE_EASY,
  QUESTION_TYPE_HARD,
  QUESTION_TYPE_MEDIUM,
  QUESTION_TYPE_VERY_EASY,
  QUESTION_TYPE_VERY_HARD,
  TESTTYPE_DAILY,
  TESTTYPE_MODEL,
  TESTTYPE_MONTH,
  TESTTYPE_REVISION,
  TESTTYPE_SPOT,
  TESTTYPE_TOPIC_WISE,
  TESTTYPE_UNIT_WISE,
  TESTTYPE_WEEK,
  capitalizeFirstLetter,
} from "../../../../../utils/helper";
import {
  createEventDifficultyTypeData,
  createOverallAnalysisScore,
  createOverallDifficultyTypeData,
  createPerfomanceLabels,
  createPerfomanceStudentScore,
  createPerfomanceTitle,
  createPerfomanceTopperScore,
  createProficiencySubjectAnalysisScore,
  createSubjectWiseTimeTaken,
} from "../../../../../redux/ChartSlice";
import styles from "./ViewTestPerfomance.module.css";

// const ContainerCustom = styled.div`
//   padding: 0;
//   margin: 0;
//   margin: 15px;
//   @media (min-width: ${MEDIA_SIZE_FLIP}) {
//     margin: 43px;
//   }
// `;

const MagicNumberSection = styled.section`
  border: 2px solid var(--color-brand-800);
  border-radius: var(--border-radius-md);
  padding: 15px 30px;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 30px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    padding: 30px 60px;
  }
`;

const StyleduserName = styled.h6`
  color: var(--color-brand-800);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 36px;
  }
`;

const StyledGreeting = styled.p`
  color: var(--color-text-primary);
  font-size: 18px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 28px;
  }
`;

const ProgressBox = styled.div`
  max-width: 300px;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--border-radius-md);
  border-color: ${(props) =>
    props.variation === "error" ? "var(--color-brand-800)" : "#28A745"};
  background-color: ${(props) =>
    props.variation === "error" ? "#C1393D0D" : "#28A7450D"};
  margin: auto;
`;

const ProgressScore = styled.p`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
  color: ${(props) =>
    props.variation === "error" ? "var(--color-brand-800)" : "#28A745"};
`;
const ProgressLabel = styled.p`
  font-weight: 600;
  text-align: center;
  color: var(--color-text-primary);
  margin-bottom: 8px;
`;
const ProgressBar = styled.div`
  max-width: 260px;
  background-color: #ccc;
  height: 15px;
  border-radius: var(--border-radius-md);
`;
const ProgressBarFloat = styled.div`
  width: 10%;
  height: 14px;
  background-color: ${(props) =>
    props.variation === "error" ? "var(--color-brand-800)" : "#28A745"};
  color: transparent;
  padding: 0;
  margin: 0;
  border-radius: var(--border-radius-md);
`;

const StyledChartSection = styled.section`
  padding: 40px 6px 6px 6px;
  position: relative;
  margin: 0 auto;
  width: 80vw;
`;

const StyledDonutchartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 200px;
  padding: 10px;
  /* @media (min-width: ${MEDIA_SIZE_LG}) {
    padding: 3px;
  } */
`;
// squad styles
const SquadContainer = styled.div`
  padding: 10px;
`;
const SquadContentBox = styled.div`
  border: 1px solid var(--color-text-primary);
  border-radius: var(--border-radius-sm);
`;
const SquadRank = styled.p`
  font-size: 32px;
  color: var(--color-brand-800);
  font-style: italic;
  margin: 0;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    font-size: 64px;
  }
`;
const SquadImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  /* box-shadow: var(--shadow-lg); */
`;

const SquadName = styled.p`
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  color: var(--color-brand-800);
  text-transform: capitalize;
  margin: 8px 0;
`;
const SquadHeader = styled.p`
  font-size: 14px;
  color: var(--color-text-secondary);
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 8px;
`;
const SquadText = styled.p`
  font-size: 16px;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  margin: 0;
  margin-bottom: 8px;
`;
//title

const StyledSelectContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-top: 35px;
  @media (min-width: ${MEDIA_SIZE_MD}) {
    margin-top: 20px;
  }
`;
const StyledSelect = styled.select`
  && {
    border: 1px solid var(--color-secondary-border);
    color: var(--color-text-primary);
    padding: 5px 10px 5px 5px;
    font-size: 14px;
    text-transform: capitalize;
    background: none !important;
    appearance: auto;
    border-radius: 6px;
    &:focus,
    :focus-visible {
      outline: none;
      border-color: var(--color-secondary-border);
    }
    @media (min-width: ${MEDIA_SIZE_MD}) {
      padding-right: 300px;
    }
  }
`;
const StyledOption = styled.option`
  /* cursor: pointer;
  line-height: 30px;
  padding: 10px; */
`;

// default props
ProgressBox.defaultProps = {
  variation: "error",
};
ProgressScore.defaultProps = {
  variation: "error",
};
ProgressBarFloat.defaultProps = {
  variation: "error",
};

const testDetailsColors = [
  "linear-gradient(180deg, #FF6384 0%, #803242 100%)", //1
  "linear-gradient(180deg, #FFCD56 0%, #80672B 100%)", //2
  "linear-gradient(180deg, #4BC0C0 0%, #266060 100%)", //3
  "linear-gradient(180deg, #FF9F40 0%, #805020 100%)", //4
  "linear-gradient(180deg, #36A2EB 0%, #1B5176 100%)", //5
  "linear-gradient(180deg, #D9D9D9 0%, #7F665B 100%)", //6
  "linear-gradient(180deg, #F274BC 0%, #913169 100%)", //7
  "linear-gradient(180deg, #9966FF 0%, #4D3380 100%)", //8
  // "linear-gradient(180deg, #9966FF 0%, #4D3380 100%)", //8
  "linear-gradient(180deg, #96C0AF 0%, #42725F 100%)", //9
];

const TIME_ALLOCATION_LABELS = [
  "Optimum Time",
  "Time Taken by You",
  "Time Taken by Topper",
];
const TIME_FIXING_BY_MINUTE = 1; //0.0166
const ADDED_VALUE = 1; //1
const PAGE_TEST_PERFOMANCE_LIST = "TestPerformanceListView";

export default function ViewTestPerfomance() {
  const numberOfStudents_class = 150;
  const numberOfStudents_batch = 754;
  const perfomancelabels = Array.from(
    { length: 11 },
    (_, i) => `Weekly Test ${String(i + 1).padStart(2, 0)}`
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { Type, id } = useParams();

  const {
    batchID = 0,
    eventID = 0,
    userID = 0,
    type_batch = 1,
  } = location.state || {};
  const [perfomanceData, setPerfomanceData] = useState({
    isLoading: true,
    methodOfTest: 0,
    batchID: batchID,
    eventID: eventID,
    title: "",
    date: new Date(),
    firstName: "",
    lastName: "",
    ans_sheet_download_req: "",
    studentScore: null,
    cutOffScore: 0,
    studentCorePercentage: 0,
    cutoffPercentage: 0,
    totalQuestions: 0,
    totalMarks: 0,
    answeredQuestion: 0,
    unAnsweredQuestions: 0,
    correctQuestion: 0,
    inCorrectQuestions: 0,
    marksObtained: 0,
    classRank: 0,
    overallRank: 0,
    classStudents: 0,
    overallStudents: 0,

    squadDetails: [],
    testTypes: [],
    subjectsList: [],
    timeTakenSubjectList: [],
    // CHART
    perfomanceSelectType: -1,
    subjectWiseSelectType: -1,
    timeTakenSelectType: -1,
    selectLoading: false,
  });

  const [scoreImage, setScoreImage] = useState({
    OutStandingScore: 100,
    ExcellentStartScore: 90,
    ExcellentEndScore: 99,
    VeryGoodStartScore: 80,
    VeryGoodEndScore: 89,
    GoodStartScore: 70,
    GoodEndScore: 79,
    AAverageStartScore: 60,
    AAverageEndScore: 69,
    AverageStartScore: 50,
    AverageEndScore: 59,
    BAverageScore: 50,
  });

  const [isOverallForOvarallAnalysis, setIsOverallForOvarallAnalysis] =
    useState(true);
  const [overallAnalysisLabel, setOverallAnalysisLabel] = useState("Test Type");
  // chart data
  const perfomanceChartData = useSelector(
    (state) => state.chart.perfomanceAnalysis
  );
  const overallChartData = useSelector((state) => state.chart.overallAnalysis);
  const proficiencySubjectsChartData = useSelector(
    (state) => state.chart.proficiencySubjectAnalysis
  );
  const difficultyTypeChart = useSelector(
    (state) => state.chart.queDifficultyAnalysis
  );
  const timeTakenChart = useSelector((state) => state.chart.timeTakenAnalysis);

  // ###DERIVED STATE
  const testDetails = [
    { label: "Total No. of Questions", value: perfomanceData.totalQuestions },
    { label: "Total Marks", value: perfomanceData.totalMarks },
    {
      label: "No. of Questions Answered",
      value: perfomanceData.answeredQuestion,
    },
    {
      label: "No. of Questions Unanswered",
      value: perfomanceData.unAnsweredQuestions,
    },
    {
      label: "No. of Questions Correct",
      value: perfomanceData.correctQuestion,
    },
    {
      label: "No. of Questions Incorrect",
      value: perfomanceData.inCorrectQuestions,
    },
    { label: "Marks Obtained", value: perfomanceData.marksObtained },
    {
      label: "Class Rank",
      value: `${perfomanceData.classRank} / ${perfomanceData.classStudents}`,
    },
    {
      label: "Overall Rank",
      value: `${perfomanceData.overallRank} / ${perfomanceData.overallStudents}`,
    },
  ];
  //for Model exam
  const testDetails_modelexam = [
    { label: "Total Marks", value: perfomanceData.totalMarks },
    { label: "Marks Obtained", value: perfomanceData.marksObtained },
    {
      label: "Class Rank",
      value: `${perfomanceData.classRank} / ${perfomanceData.classStudents}`,
    },
  ];
  const PERFOMANCE_CHART_TITLE = perfomanceData.testTypes.some((test) => {
    return test.id === perfomanceData.perfomanceSelectType;
  })
    ? perfomanceData.testTypes.find((test) => {
        return test.id === perfomanceData.perfomanceSelectType;
      }).name
    : "Overall"; //New Changes

  // ###FUNCTIONS
  const getPerfomanceChartTitle = (value) => {
    const title = perfomanceData.testTypes.some((test) => {
      return test.id === value;
    })
      ? perfomanceData.testTypes.find((test) => {
          return test.id === value;
        }).name
      : "Overall"; //New Changes

    return title;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "perfomanceSelect") {
      setPerfomanceData((prevData) => ({ ...prevData, selectLoading: true }));
      const formdata = {
        batch_id: perfomanceData.batchID,
        event_id: perfomanceData.eventID,
        user_id: userID,
        type: CHART_PERFOMANCE,
        overall: +value === -1 ? 1 : 0,
      };
      if (+value !== -1) formdata.test_type_pk = +value;
      // return alert('lakshmaan')
      studentPerfomanceChart(formdata)
        .then((res) => {
          if (res.status) {
            let topperScore = [];
            let labels = [];
            const { score_with_top: scores } = res.data;
            if (!scores?.curr_user) {
              // send empty array if data is empty
              toast.error(`${getPerfomanceChartTitle(+value)} Not Yet Taken`);
              return null;
            }
            const topperScores = scores.topper;
            const sortedUserScores = scores.curr_user.sort(
              (a, b) => parseFloat(a.event_pk) - parseFloat(b.event_pk)
            );
            const studentScore = sortedUserScores.map((score) => {
              const eventValue = topperScores.some(
                (topScore) => topScore.event_pk === score.event_pk
              )
                ? topperScores.find(
                    (topscore) => topscore.event_pk === score.event_pk
                  ).marks_in_perc
                : 0;
              topperScore.push(eventValue);
              labels.push(score.event_type_title);
              // console.log(score.marks_in_perc, "score.marks_in_perc");
              return score.marks_in_perc;
            });
            // console.log(studentScore, "studentScore ----");
            dispatch(createPerfomanceLabels(labels));
            dispatch(createPerfomanceTopperScore(topperScore));
            dispatch(createPerfomanceStudentScore(studentScore));

            setPerfomanceData((prevData) => ({
              ...prevData,
              perfomanceSelectType: +value,
            }));
          } else {
            console.error("studentPerfomanceChart api error");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() =>
          setPerfomanceData((prevData) => ({
            ...prevData,
            selectLoading: false,
          }))
        );
    } else if (name === "subjectWiseSelect") {
      setPerfomanceData((prevData) => ({ ...prevData, selectLoading: true }));
      const formData = {
        batch_id: perfomanceData.batchID,
        event_id: perfomanceData.eventID,
        user_id: userID,
        type: CHART_PROFICIENCY_SUBJECTS,
        overall: +value === -1 ? 1 : 0,
      };
      studentPerfomanceChart(formData)
        .then((res) => {
          if (res.status) {
            const { sub_cnt } = res.data;
            // new
            const arrayOfOverallAndSubjects = sub_cnt.flatMap((ele) => {
              if (ele.single) {
                //--> if subject contains each subjects it will return overall+subjects
                // overwrite subject_name for overall
                ele.subject_name = `${ele.subject_overall}-overall`;
                // if (ele.single.length > 1) {
                // }
                const singleArray = ele.single.map((eleS, indexS, arrS) => {
                  // overwrite subject_name with overallSubjectName+subjectName
                  eleS.subject_name = `${ele.subject_overall}-${eleS.subject_name}`;
                  // if (arrS.length > 1) {
                  // }
                  return eleS;
                });
                // removing single key from object for perfomance
                delete ele.single;
                return [ele, ...singleArray];
              }
              // else return overall only
              return ele;
            });
            // new
            dispatch(
              createProficiencySubjectAnalysisScore(arrayOfOverallAndSubjects)
            );
            setPerfomanceData((prevData) => ({
              ...prevData,
              subjectWiseSelectType: value,
            }));
          } else {
            console.error("studentPerfomanceChart subject0-wise api error");
          }
        })
        .catch((err) => console.error(err))
        .finally(() =>
          setPerfomanceData((prevData) => ({
            ...prevData,
            selectLoading: false,
          }))
        );
    } else if (name === "timeTakenSelect") {
      setPerfomanceData((prevData) => ({ ...prevData, selectLoading: true }));
      const formData = {
        batch_id: perfomanceData.batchID,
        event_id: perfomanceData.eventID,
        user_id: userID,
        type: CHART_SUBJECT_TIME_TAKEN,
        overall: +value === -1 ? 1 : 0,
      };
      if (+value !== -1) {
        formData.subject = value;
      }
      studentPerfomanceChart(formData)
        .then((res) => {
          if (res.status) {
            const { sub_time } = res.data;
            if (!sub_time.time_taken_by_stud) {
              toast.error(`${value} is not available`);
            } else {
              // fixed when showing 30 mins testas 31 mis test
              // const timeTakenData = [
              //   Math.ceil(sub_time.optimum_time * TIME_FIXING_BY_MINUTE),
              //   Math.ceil(sub_time.time_taken_by_stud * TIME_FIXING_BY_MINUTE),
              //   Math.ceil(
              //     sub_time.time_taken_by_topper * TIME_FIXING_BY_MINUTE
              //   ),
              // ];
              const timeTakenData = [
                parseInt(sub_time.optimum_time * TIME_FIXING_BY_MINUTE),
                sub_time.time_taken_by_stud * TIME_FIXING_BY_MINUTE,
                sub_time.time_taken_by_topper * TIME_FIXING_BY_MINUTE,
              ];

              dispatch(createSubjectWiseTimeTaken(timeTakenData));
              setPerfomanceData((prevData) => ({
                ...prevData,
                timeTakenSelectType: value,
              }));
            }
          } else {
            console.error("studentPerfomanceChart timetaken api error");
          }
        })
        .catch((err) => console.error(err))
        .finally(() =>
          setPerfomanceData((prevData) => ({
            ...prevData,
            selectLoading: false,
          }))
        );
    } else {
      console.error(`un handled handlechange type from ${name}`);
    }
  };
  const handleBackToTestType = () => {
    const formData = {
      batch_id: perfomanceData.batchID,
      event_id: perfomanceData.eventID,
      user_id: userID,
      type: CHART_OVERALL,
      overall: 1,
    };
    handleSelectLoading(true);
    studentPerfomanceChart(formData)
      .then((res) => {
        if (res.status) {
          const { scores } = res.data;
          const modifiedTestTypes = perfomanceData.testTypes.map((test) => ({
            name: test.name,
            mark: 0,
            testType: test.id,
          }));
          // 1.add marks based on testypepk
          const wantedScore = modifiedTestTypes.map((modifyTest) => {
            if (
              scores.some((score) => score.test_type_pk === modifyTest.testType)
            ) {
              const changedMark = scores.find(
                (findScore) => findScore.test_type_pk === modifyTest.testType
              )?.marks_in_perc;
              return {
                name: modifyTest.name,
                mark: changedMark,
                testType: modifyTest.testType,
              };
            } else {
              return modifyTest;
            }
          });
          // 2.choose fixedtypes+usertestTypes
          const fixedTestTypes = [
            TESTTYPE_DAILY,
            TESTTYPE_WEEK,
            TESTTYPE_MONTH,
            TESTTYPE_REVISION,
            TESTTYPE_TOPIC_WISE,
            TESTTYPE_UNIT_WISE,
            TESTTYPE_SPOT,
            TESTTYPE_MODEL,
          ];
          const userTestTypes = scores.flatMap((score) => {
            if (!fixedTestTypes.includes(score.test_type_pk)) {
              return score.test_type_pk;
            } else {
              return [];
            }
          });
          const finalisedTestTypes = [...fixedTestTypes, ...userTestTypes];
          // 3.eliminate other testtypes
          const finalisedScore = wantedScore.flatMap((wscore) => {
            const tempTestType = wscore.testType;
            if (finalisedTestTypes.includes(tempTestType)) {
              return wscore;
            } else {
              return [];
            }
          });
          dispatch(createOverallAnalysisScore(finalisedScore));
          setIsOverallForOvarallAnalysis(true);
          setOverallAnalysisLabel("Test Type");
        } else {
          throw new Error(" overall score chart api error");
        }
      })
      .catch((err) => console.error(err))
      .finally(() => handleSelectLoading(false));
  };
  const handleSelectLoading = (flag) => {
    setPerfomanceData((prevData) => ({ ...prevData, selectLoading: flag }));
  };

  const handleRetakeTest = () => {
    let dt = {
      batch_id: parseInt(batchID),
      event_id: parseInt(eventID),
      user_id: userID,
    };

    AxiosInstance.post("api/test/start", dt)
      .then((res) => {
        if (res.data.status === true) {
          let startTime =
            res.data.data[0].start_time != null
              ? res.data.data[0].start_time
              : "";
          let endTime =
            res.data.data[0].end_time != null ? res.data.data[0].end_time : "";
          let batch_id = res.data.data[0].batch_id;
          let event_id = res.data.data[0].event_id;
          let no_ques = res.data.data[0].no_of_questions;
          let tol_mark = res.data.data[0].tot_marks;
          // let attempt_no =  (fdata.obtained_marks !==null && fdata.obtained_marks !=="") ? res.data.data[0].attempt : 2 ;
          let attempt_no = res.data.data[0].attempt;
          let method_of_test = res.data.data[0].method_of_test;
          let event_name = res.data.data[0].event_name;
          let type_type_name = res.data.data[0].name;
          let start_date_test = res.data.data[0].start_date;
          let description = res.data.data[0].description;
          const navState = res.data.data[0];
          navigate(res.data.data[0].teams_url, {
            state: {
              eventName: event_name,
              methodOfTest: method_of_test,
              testType: type_type_name,
              date: start_date_test,
              startTime: startTime,
              endTime: endTime,
              totalNoQues: no_ques,
              totalMark: tol_mark,
              descForStudent: description,
              batchID: batch_id,
              eventID: event_id,
              attempt_no: attempt_no,
            },
          });
          // Remove buttonClickCount from sessionStorage
          sessionStorage.removeItem("buttonClickCount");
          // window.location.reload();
        } else {
          toast.error("You already reach 3 attempt");
          navigate("/");
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const handleDownloadAnswerSheet = () => {
    const formData = {
      user_id: userID,

      batch_id: perfomanceData.batchID,

      event_id: perfomanceData.eventID,
    };
    AxiosInstance.post("api/test/answer/download", formData)
      .then((res) => {
        handleSelectLoading(true);
        if (res.data.status) {
          const { path } = res.data.data;
          if (path) window.open(`${ImageUrlPrefix}/${path}`);
        } else {
          throw new Error("download answer sheet pdf error");
        }
      })
      .catch((err) => console.error(err))
      .finally(() => handleSelectLoading(false));
  };
  const handleReviewSubmission = () => {
    navigate(
      `/ReviewSubmission/${perfomanceData.batchID}/${perfomanceData.eventID}`
    );
  };
  useEffect(() => {
    const testTypesFormData = {
      method_of_test: perfomanceData.methodOfTest,
    };
    const BasicApiFormData = {
      batch_id: perfomanceData.batchID,
      event_id: perfomanceData.eventID,
      user_id: userID,
    };
    const perfomanceChartFormData = {
      batch_id: perfomanceData.batchID,
      event_id: perfomanceData.eventID,
      user_id: userID,
      type: CHART_PERFOMANCE,
      overall: 1,
    };
    const overallChartFormData = {
      batch_id: perfomanceData.batchID,
      event_id: perfomanceData.eventID,
      user_id: userID,
      type: CHART_OVERALL,
      overall: 1,
    };
    const proficiencySubjectsFormData = {
      batch_id: perfomanceData.batchID,
      event_id: perfomanceData.eventID,
      user_id: userID,
      type: CHART_PROFICIENCY_SUBJECTS,
      overall: 1,
    };
    const difficultyTypeFormData = {
      batch_id: perfomanceData.batchID,
      event_id: perfomanceData.eventID,
      user_id: userID,
      type: CHART_QUESTION_TYPE,
      overall: 1,
    };
    const difficultyTypeEventFormData = {
      batch_id: perfomanceData.batchID,
      event_id: perfomanceData.eventID,
      user_id: userID,
      type: CHART_QUESTION_TYPE,
      overall: 0,
    };

    const timeTakenFormData = {
      batch_id: perfomanceData.batchID,
      event_id: perfomanceData.eventID,
      user_id: userID,
      type: CHART_SUBJECT_TIME_TAKEN,
      overall: 1,
    };

    const timeTakenSubjects = {
      batch_id: perfomanceData.batchID,
      event_id: perfomanceData.eventID,
      user_id: userID,
    };

    const promises = [
      getTestTypes(testTypesFormData), //0
      perfomanceBasicInfo(BasicApiFormData), //1
      studentPerfomanceChart(perfomanceChartFormData), //2
      studentPerfomanceChart(overallChartFormData), //3
      studentPerfomanceChart(proficiencySubjectsFormData), //4
      studentPerfomanceChart(difficultyTypeFormData), //5
      studentPerfomanceChart(difficultyTypeEventFormData), //6
      studentPerfomanceChart(timeTakenFormData), //7
      apiSubjectListforTimeTakenChart(timeTakenSubjects), //8
    ];

    // setPerfomanceData((prevData) => ({ ...prevData, isLoading: true }));
    Promise.allSettled(promises)
      .then((results) => {
        // ### API DATA
        let apiTestTypes = [];
        let apiSubjectsList = [];
        let apiInfo = {};
        let apiTest_details = {};
        let apiTop_studs = {};

        let apiPerfomanceLabels = [];
        let apiPerfomanceTopperScore = [];
        let apiperfomanceStudentScore = [];

        let apiOverallChartScore = [];

        let apiProficiencySubjectsChartScore = [];

        let api_difficultyChart_OverallStcore = [];
        let api_difficultyChart_Overalltotalcore = [];
        let api_difficultyChart_eventStcore = [];
        let api_difficultyChart_eventTotalcore = [];

        let apiTimeTakenChart = [];
        let apiTimeTakenSubjects = [];

        // ### ADDITIONAL DATA
        // CALCULATE STUDENT SCORE CUTOFF PERCENTAGE
        let stScorePercenttage = "";
        let cutoffScorePercenttage = "";
        let splittedDate = "";

        // ###LOOP
        results.forEach((result, index) => {
          const { status, value } = result;
          if (index === 0) {
            if (value.status) {
              apiTestTypes = value.data;
            } else {
              console.error("getTestTypes api error");
            }
          } else if (index === 1) {
            // LOGIC FOR BASIC INFO
            if (value.status) {
              const { info, test_details, top_studs } = value.data;
              // console.log(top_studs, "top_studs----lalitha", value);
              apiInfo = info;
              apiTest_details = test_details;
              apiTop_studs = top_studs;
              stScorePercenttage =
                (test_details.marks_obtained / info.tot_marks) * 100;
              // (test_details.marks_obtained / info.cut_off) * 100;
              cutoffScorePercenttage = (info.cut_off / info.tot_marks) * 100;

              splittedDate = info.start_date.split("-");
            } else {
              console.error("perfomanceBasicInfo api error");
              // throw new Error("perfomance chart api error");
            }
          } else if (index === 2) {
            // LOGIC FOR PERFOMANCE CHART
            if (value.status) {
              const { score_with_top: scores } = value.data;
              const topperScores = scores.topper;
              const sortedUserScores = scores.curr_user.sort(
                (a, b) => parseFloat(a.event_pk) - parseFloat(b.event_pk)
              );
              let topperScore = [];
              let labels = [];
              // console.log(sortedUserScores, "sortedUserScores", topperScores);
              const studentScore = sortedUserScores.map((score) => {
                const eventValue = topperScores.some(
                  (topScore) => topScore.event_pk === score.event_pk
                )
                  ? topperScores.find(
                      (topscore) => topscore.event_pk === score.event_pk
                    ).marks_in_perc
                  : 0;
                topperScore.push(eventValue);
                // labels.push(score.event_type_title); //change request
                labels.push(score.event_name);
                // console.log(score.marks_in_perc, "score.marks_in_percsdsd");
                return score.marks_in_perc;
              });
              // console.log(studentScore, "studentScore ---sss-");
              apiPerfomanceLabels = labels;
              apiPerfomanceTopperScore = topperScore;
              apiperfomanceStudentScore = studentScore;
            } else {
              console.error("studentPerfomanceChart api error");
            }
          } else if (index === 3) {
            // LOGIC FOR OVERALL CHART
            if (value.status) {
              const { scores } = value.data;
              // let myScore = [
              //   ...scores,
              //   {
              //     marks_in_perc: 100,
              //     test_type_name: "AIASA Genius Test",
              //     test_type_pk: 21,
              //     user_id: "e0c7c41f2b7dabcf1b1e6312f5e74df6",
              //   },
              // ];
              const modifiedTestTypes = apiTestTypes.map((test) => ({
                name: test.name,
                mark: 0,
                testType: test.id,
              }));
              // 1.add marks based on testypepk

              const wantedScore = modifiedTestTypes.map((modifyTest) => {
                if (
                  scores.some(
                    (score) => score.test_type_pk === modifyTest.testType
                  )
                ) {
                  const changedMark = scores.find(
                    (findScore) =>
                      findScore.test_type_pk === modifyTest.testType
                  )?.marks_in_perc;
                  return {
                    name: modifyTest.name,
                    mark: changedMark,
                    testType: modifyTest.testType,
                  };
                } else {
                  return modifyTest;
                }
              });
              // 2.choose fixedtypes+usertestTypes
              const fixedTestTypes = [
                TESTTYPE_DAILY,
                TESTTYPE_WEEK,
                TESTTYPE_MONTH,
                TESTTYPE_REVISION,
                TESTTYPE_TOPIC_WISE,
                TESTTYPE_UNIT_WISE,
                TESTTYPE_SPOT,
                TESTTYPE_MODEL,
              ];
              const userTestTypes = scores.flatMap((score) => {
                if (!fixedTestTypes.includes(score.test_type_pk)) {
                  return score.test_type_pk;
                } else {
                  return [];
                }
              });
              const finalisedTestTypes = [...fixedTestTypes, ...userTestTypes];
              // 3.eliminate other testtypes
              const finalisedScore = wantedScore.flatMap((wscore) => {
                const tempTestType = wscore.testType;
                if (finalisedTestTypes.includes(tempTestType)) {
                  return wscore;
                } else {
                  return [];
                }
              });
              apiOverallChartScore = finalisedScore;
            } else {
              console.error(" overall score chart api error");
              // throw new Error("");
            }
          } else if (index === 4) {
            // LOGIC FOR SUBJECTWISE CHART
            if (value.status) {
              const { sub_cnt } = value.data;
              const subList = sub_cnt.map((sub) => sub.subject_name);
              // old
              // apiProficiencySubjectsChartScore = sub_cnt;
              apiSubjectsList = subList;
              // old
              // new
              const arrayOfOverallAndSubjects = sub_cnt.flatMap((ele) => {
                if (ele.single) {
                  //--> if subject contains each subjects it will return overall+subjects
                  // overwrite subject_name for overall
                  ele.subject_name = `${ele.subject_overall}-overall`;
                  // if (ele.single.length > 1) {
                  // }
                  const singleArray = ele.single.map((eleS, indexS, arrS) => {
                    // overwrite subject_name with overallSubjectName+subjectName
                    eleS.subject_name = `${ele.subject_overall}-${eleS.subject_name}`;
                    // if (arrS.length > 1) {
                    // }
                    return eleS;
                  });
                  // removing single key from object for perfomance
                  delete ele.single;
                  return [ele, ...singleArray];
                }
                // else return overall only
                return ele;
              });
              // new
              apiProficiencySubjectsChartScore = arrayOfOverallAndSubjects;
            } else {
              console.error(" proficiency subject chart api error");
              // throw new Error(" proficiency subject chart api error");
            }
          } else if (index === 5) {
            // LOGIC FOR QUESTION_TYPE CHART
            if (value.status) {
              const { que_diff_type } = value.data;
              const stCount = [0, 0, 0, 0, 0];
              const totalCount = [0, 0, 0, 0, 0];
              que_diff_type.forEach((que) => {
                if (que.difficulty_type === QUESTION_TYPE_VERY_HARD) {
                  stCount[0] = que.usr_crct_cnt;
                  totalCount[0] = que.diff_type_count;
                } else if (que.difficulty_type === QUESTION_TYPE_HARD) {
                  stCount[1] = que.usr_crct_cnt;
                  totalCount[1] = que.diff_type_count;
                } else if (que.difficulty_type === QUESTION_TYPE_MEDIUM) {
                  stCount[2] = que.usr_crct_cnt;
                  totalCount[2] = que.diff_type_count;
                } else if (que.difficulty_type === QUESTION_TYPE_VERY_EASY) {
                  stCount[3] = que.usr_crct_cnt;
                  totalCount[3] = que.diff_type_count;
                } else if (que.difficulty_type === QUESTION_TYPE_EASY) {
                  stCount[4] = que.usr_crct_cnt;
                  totalCount[4] = que.diff_type_count;
                }
              });
              //overwrite by adding 1 to show when question paper itself doesnt contain difficult question
              const finalTotalCount = totalCount.map(
                (count) => Number(count) + ADDED_VALUE
              );
              // new
              const finalStCount = stCount.map(
                (count) => Number(count) + ADDED_VALUE
              );
              // new
              api_difficultyChart_OverallStcore = finalStCount;
              api_difficultyChart_Overalltotalcore = finalTotalCount;
            } else {
              console.error("question difficulty chart api error");
              // throw new Error(" question difficulty chart api error");
            }
          } else if (index === 6) {
            // LOGIC FOR QUESTION_TYPE CHART
            if (value.status) {
              const { que_diff_type } = value.data;
              const stCount = [0, 0, 0, 0, 0];
              const totalCount = [0, 0, 0, 0, 0];
              que_diff_type.forEach((que) => {
                if (que.difficulty_type === QUESTION_TYPE_VERY_HARD) {
                  stCount[0] = que.usr_crct_cnt;
                  totalCount[0] = que.diff_type_count;
                } else if (que.difficulty_type === QUESTION_TYPE_HARD) {
                  stCount[1] = que.usr_crct_cnt;
                  totalCount[1] = que.diff_type_count;
                } else if (que.difficulty_type === QUESTION_TYPE_MEDIUM) {
                  stCount[2] = que.usr_crct_cnt;
                  totalCount[2] = que.diff_type_count;
                } else if (que.difficulty_type === QUESTION_TYPE_VERY_EASY) {
                  stCount[3] = que.usr_crct_cnt;
                  totalCount[3] = que.diff_type_count;
                } else if (que.difficulty_type === QUESTION_TYPE_EASY) {
                  stCount[4] = que.usr_crct_cnt;
                  totalCount[4] = que.diff_type_count;
                }
              });
              //overwrite by adding 1 to show when question paper itself doesnt contain difficult question
              const finalTotalCount = totalCount.map(
                (count) => Number(count) + ADDED_VALUE
              );
              // new
              const finalStCount = stCount.map(
                (count) => Number(count) + ADDED_VALUE
              );
              // new
              // console.log("laksh-before-add", totalCount, stCount);
              // console.log("laksh-after-add", finalTotalCount, finalStCount);
              api_difficultyChart_eventStcore = finalStCount;
              api_difficultyChart_eventTotalcore = finalTotalCount;
            } else {
              console.error("question difficulty chart api error");
              // throw new Error(" question difficulty chart api error");
            }
          } else if (index === 7) {
            // LOGIC FOR SUBJECT TIME TAKEN CHART
            if (value.status) {
              const { sub_time } = value.data;
              // const data = [
              //   Math.ceil(sub_time.optimum_time * TIME_FIXING_BY_MINUTE),
              //   Math.ceil(sub_time.time_taken_by_stud * TIME_FIXING_BY_MINUTE),
              //   Math.ceil(
              //     sub_time.time_taken_by_topper * TIME_FIXING_BY_MINUTE
              //   ),
              // ];
              //changed when optimum time is above the exam time,fixed by converting optimum time alone
              const data = [
                parseInt(sub_time.optimum_time * TIME_FIXING_BY_MINUTE),
                sub_time.time_taken_by_stud * TIME_FIXING_BY_MINUTE,
                sub_time.time_taken_by_topper * TIME_FIXING_BY_MINUTE,
              ];
              apiTimeTakenChart = data;
            } else {
              console.error("time taken chart api error");
              // throw new Error(" time taken chart api error");
            }
          } else if (index === 8) {
            // console.log(value, "value");
            if (value.status) {
              const data = value.data?.map((ele) => ele.subject_name);
              apiTimeTakenSubjects = data;
            } else {
              if (type_batch !== "4") {
                console.log("time taken chart sub-list api error");
                // throw new Error("time taken chart sub-list api error");
              } else {
                console.log("time taken chart sub-list api error");
              }
            }
          } else {
            // throw new Error("un-handled api response from apilist");
            console.log("un-handled api response from apilist");
          }
        });
        // ###STORING VALUES
        dispatch(createPerfomanceLabels(apiPerfomanceLabels));
        dispatch(createPerfomanceTopperScore(apiPerfomanceTopperScore));
        dispatch(createPerfomanceStudentScore(apiperfomanceStudentScore));
        dispatch(createOverallAnalysisScore(apiOverallChartScore));
        dispatch(
          createProficiencySubjectAnalysisScore(
            apiProficiencySubjectsChartScore
          )
        );
        dispatch(
          createOverallDifficultyTypeData(
            api_difficultyChart_OverallStcore,
            api_difficultyChart_Overalltotalcore
          )
        );
        dispatch(
          createEventDifficultyTypeData(
            api_difficultyChart_eventStcore,
            api_difficultyChart_eventTotalcore
          )
        );
        dispatch(createSubjectWiseTimeTaken(apiTimeTakenChart));

        setPerfomanceData((prevData) => ({
          ...prevData,
          isLoading: false,
          // title: apiInfo.event_type_title,
          title: apiInfo.event_name, // new change for floated title container heading
          ans_sheet_download_req: apiInfo.ans_sheet_download_req,
          // date: apiInfo.start_date,
          date: new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0]),

          firstName: apiInfo.first_name,
          lastName: apiInfo.last_name,
          cutOffScore: apiInfo.cut_off,
          studentScore: apiTest_details.marks_obtained,
          studentCorePercentage: stScorePercenttage,
          cutoffPercentage: cutoffScorePercenttage,
          // test-details
          totalQuestions: apiInfo.no_of_questions,
          totalMarks: apiInfo.tot_marks,
          answeredQuestion: apiTest_details.ans_que,
          unAnsweredQuestions: apiTest_details.unans_que,
          correctQuestion: apiTest_details.no_of_que_crct,
          inCorrectQuestions: apiTest_details.no_of_que_incrct,
          marksObtained: apiTest_details.marks_obtained,
          classRank: apiInfo.classrank,
          overallRank: apiInfo.overallrank,
          classStudents: apiInfo.classrank_outof,
          overallStudents: apiInfo.overallrank_outof,

          squadDetails: apiTop_studs,
          testTypes: apiTestTypes,
          subjectsList: apiSubjectsList,
          timeTakenSubjectList: apiTimeTakenSubjects,
        }));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() =>
        setPerfomanceData((prevData) => ({ ...prevData, isLoading: false }))
      );
  }, []);

  return (
    <CustomContainer>
      <section
        id="title"
        className="row justify-content-center align-items-center p-0"
        style={{ margin: "10px 0" }}
      >
        <div className="col-12 col-lg-4">
          <StyledTitle>{`${
            perfomanceData.title !== null ? perfomanceData.title : ""
          } - ${moment(new Date(perfomanceData.date)).format(
            "DD/MM/YYYY"
          )}`}</StyledTitle>
        </div>
        <div className="col-12 col-lg-8">
          <ButtonContainer>
            <SecondaryButton onClick={() => navigateToPreviousScreen()}>
              Back to View Test
            </SecondaryButton>
            {type_batch !== "4" && (
              <NavigationButton onClick={handleRetakeTest}>
                Retake Test
              </NavigationButton>
            )}
            {/* {type_batch !== "4" && (
              <PrimaryButton
                // disabled={
                //   perfomanceData.ans_sheet_download_req === 1 ? false : true
                // }
                className="DisabledListviS"
                disabled
                onClick={handleDownloadAnswerSheet}
              >
                Download My Answer Sheet
              </PrimaryButton>
            )} */}

            {type_batch !== "4" && (
              <PrimaryButton onClick={handleReviewSubmission}>
                Review Submission
              </PrimaryButton>
            )}
          </ButtonContainer>
        </div>
      </section>

      <MagicNumberSection>
        <div className="row">
          <div className="col-12 col-md-4 m-0 p-0 mb-4 mb-md-0">
            <div className="d-flex justify-content-center">
              {perfomanceData.studentCorePercentage ===
                scoreImage.OutStandingScore && (
                <img
                  src={IMAGE_outstanding}
                  alt="winner-cup"
                  className="img-fluid"
                />
              )}
              {perfomanceData.studentCorePercentage >=
                scoreImage.ExcellentStartScore &&
                perfomanceData.studentCorePercentage <=
                  scoreImage.ExcellentEndScore && (
                  <img
                    src={IMAGE_Excellent}
                    alt="winner-cup"
                    className="img-fluid"
                  />
                )}
              {/* {console.log(
                perfomanceData.studentCorePercentage >=
                  scoreImage.VeryGoodStartScore &&
                  perfomanceData.studentCorePercentage <=
                    scoreImage.VeryGoodEndScore,
                "---- lalitha"
              )} */}
              {perfomanceData.studentCorePercentage >=
                scoreImage.VeryGoodStartScore &&
                perfomanceData.studentCorePercentage <=
                  scoreImage.VeryGoodEndScore && (
                  <img
                    src={IMAGE_VGood}
                    alt="winner-cup"
                    className="img-fluid"
                  />
                )}
              {perfomanceData.studentCorePercentage >=
                scoreImage.GoodStartScore &&
                perfomanceData.studentCorePercentage <=
                  scoreImage.GoodEndScore && (
                  <img
                    src={IMAGE_Good}
                    alt="winner-cup"
                    className="img-fluid"
                  />
                )}
              {perfomanceData.studentCorePercentage >=
                scoreImage.AverageStartScore &&
                perfomanceData.studentCorePercentage <=
                  scoreImage.AverageEndScore && (
                  <img
                    src={IMAGE_Average}
                    alt="winner-cup"
                    className="img-fluid"
                  />
                )}
              {perfomanceData.studentCorePercentage >=
                scoreImage.AAverageStartScore &&
                perfomanceData.studentCorePercentage <=
                  scoreImage.AAverageEndScore && (
                  <img
                    src={IMAGE_AAverage}
                    alt="winner-cup"
                    className="img-fluid"
                  />
                )}
              {perfomanceData.studentCorePercentage !== null &&
                perfomanceData.studentCorePercentage <
                  scoreImage.BAverageScore && (
                  <img
                    src={IMAGE_BAverage}
                    alt="winner-cup"
                    className="img-fluid"
                  />
                )}
            </div>
          </div>
          <div
            className="col-12 col-md-5
           m-0 p-0 mb-3 mx-md-0 my-md-auto"
          >
            <StyleduserName className="text-center text-md-start">{`Hi ${perfomanceData.firstName} ${perfomanceData.lastName}`}</StyleduserName>
            {perfomanceData.studentCorePercentage ===
              scoreImage.OutStandingScore && (
              <StyledGreeting className="text-center text-md-start">
                You are an Outstanding Student!
                <br />
                AIASA is very proud of you!
              </StyledGreeting>
            )}
            {perfomanceData.studentCorePercentage >=
              scoreImage.ExcellentStartScore &&
              perfomanceData.studentCorePercentage <=
                scoreImage.ExcellentEndScore && (
                <StyledGreeting className="text-center text-md-start">
                  Excellent! You have such a great future ahead of you!
                </StyledGreeting>
              )}

            {perfomanceData.studentCorePercentage >=
              scoreImage.VeryGoodStartScore &&
              perfomanceData.studentCorePercentage <=
                scoreImage.VeryGoodEndScore && (
                <StyledGreeting className="text-center text-md-start">
                  You are on the right path!
                  <br />
                  Keep up the good work !
                </StyledGreeting>
              )}

            {perfomanceData.studentCorePercentage >=
              scoreImage.GoodStartScore &&
              perfomanceData.studentCorePercentage <=
                scoreImage.GoodEndScore && (
                <StyledGreeting className="text-center text-md-start">
                  You have the potential to achieve!
                  <br />
                  Needs slight improvement!
                </StyledGreeting>
              )}

            {perfomanceData.studentCorePercentage >=
              scoreImage.AAverageStartScore &&
              perfomanceData.studentCorePercentage <=
                scoreImage.AAverageEndScore && (
                <StyledGreeting className="text-center text-md-start">
                  Believe in your ability to succeed.
                  <br />
                  Work hard to make your dreams a reality.
                </StyledGreeting>
              )}

            {perfomanceData.studentCorePercentage >=
              scoreImage.AverageStartScore &&
              perfomanceData.studentCorePercentage <=
                scoreImage.AverageEndScore && (
                <StyledGreeting className="text-center text-md-start">
                  Your future depends on what you do today!
                  <br />
                  Focus more on your studies! With continuous efforts you can
                  achieve !
                </StyledGreeting>
              )}
            {perfomanceData.studentCorePercentage !== null &&
              perfomanceData.studentCorePercentage <
                scoreImage.BAverageScore && (
                <StyledGreeting className="text-center text-md-start">
                  Believe in yourself!
                  <br />
                  Nothing is impossible!
                  <br />
                  If you work hard, you will soon get good Results!
                </StyledGreeting>
              )}
          </div>
          <div className="col-12 col-md-3 m-0 p-0 mb-3 mx-md-0 my-md-auto">
            <div>
              <ProgressBox
                variation="success"
                style={{
                  marginBottom: "20px",
                }}
              >
                <ProgressScore variation="success">
                  {perfomanceData.studentScore}
                </ProgressScore>
                <ProgressLabel>Your Score</ProgressLabel>
                <ProgressBar>
                  <ProgressBarFloat
                    variation="success"
                    style={{
                      width: `${perfomanceData.studentCorePercentage}%`,
                      minWidth: "1%",
                      maxWidth: "100%",
                      // maxWidth: "100%",
                    }}
                  ></ProgressBarFloat>
                </ProgressBar>
              </ProgressBox>
              {type_batch !== "4" && (
                <ProgressBox>
                  <ProgressScore>{perfomanceData.cutOffScore}</ProgressScore>
                  <ProgressLabel>cut-off</ProgressLabel>
                  <ProgressBar>
                    <ProgressBarFloat
                      style={{
                        width: `${perfomanceData.cutoffPercentage}%`,
                        minWidth: "1%",
                        maxWidth: "100%",
                      }}
                    ></ProgressBarFloat>
                  </ProgressBar>{" "}
                </ProgressBox>
              )}
            </div>
          </div>
        </div>
      </MagicNumberSection>
      {/* test details */}
      <FloatedTitleContainer title="Test Details" className="mb-4">
        <div className={`row ${styles.testDetails_container}`}>
          {type_batch !== "4"
            ? testDetails.map((detail, index) => (
                <div className="col-12 col-md-4 m-0 p-0">
                  <TestDetailsNumbers
                    label={detail.label}
                    value={detail.value}
                    bgcolor={testDetailsColors[index]}
                  />
                </div>
              ))
            : testDetails_modelexam.map((detail, index) => (
                <div className="col-12 col-md-4 m-0 p-0">
                  <TestDetailsNumbers
                    label={detail.label}
                    value={detail.value}
                    bgcolor={testDetailsColors[index]}
                  />
                </div>
              ))}
        </div>
      </FloatedTitleContainer>
      {/* top 10 squd */}
      <FloatedTitleContainer title="Top 10 Squad">
        <div
          className="row align-items-center  p-0"
          style={{ margin: "20px 10px" }}
        >
          {perfomanceData.squadDetails.map((squad, index) => {
            const splittedTime =
              squad.duration_completed !== null
                ? squad.duration_completed.split(":")
                : [];
            return (
              <SquadContainer className="col-12 col-md-6 m-0 ">
                <SquadContentBox>
                  <div className="row p-1 m-0 justify-content-center align-items-center">
                    <div className="col-6 col-lg-2 m-0 p-0">
                      <div className="d-flex justify-content-center align-items-center">
                        {/* <SquadRank>{Number(index) + 1}</SquadRank>classrank */}
                        <SquadRank>{squad.classrank}</SquadRank>
                      </div>
                      {/* <span style={{ fontSize: "24px" }}>1</span> */}
                    </div>
                    <div className="col-6 col-lg-3">
                      <SquadImg
                        src={`${ImageUrlPrefix}/${squad.profile_path}`}
                        className="img-fluid"
                        // style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                        alt="student profile"
                      />
                    </div>
                    <div className="col-12 col-lg-7">
                      <SquadName>{`${squad.first_name} ${squad.last_name}`}</SquadName>

                      <div className="row p-0 m-0">
                        <div className="col-6 p-0 m-0">
                          <SquadHeader>marks obtained</SquadHeader>
                          <SquadText>{`${squad.marks_obtained} / ${perfomanceData.totalMarks}`}</SquadText>
                        </div>
                        {type_batch !== "4" && (
                          <div className="col-6 p-0 m-0">
                            <SquadHeader>time taken</SquadHeader>
                            <SquadText>
                              {splittedTime.length > 0
                                ? `${splittedTime[0]
                                    .toString()
                                    .padStart(2, 0)}h : ${splittedTime[1]
                                    .toString()
                                    .padStart(2, 0)}m : ${splittedTime[2]
                                    .toString()
                                    .padStart(2, 0)}s`
                                : ""}
                            </SquadText>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </SquadContentBox>
              </SquadContainer>
            );
          })}
        </div>
      </FloatedTitleContainer>

      {/* time allocation subject */}
      {type_batch !== "4" && (
        <FloatedTitleContainer
          title={`Subject-wise Time Allocation - ${
            +perfomanceData.timeTakenSelectType === -1
              ? perfomanceData.title
              : perfomanceData.timeTakenSelectType
          }`}
          className="mt-4"
          headerClassName={styles.floatedcontainer_header}
        >
          <StyledSelectContainer>
            <StyledSelect
              onChange={handleChange}
              name="timeTakenSelect"
              value={perfomanceData.timeTakenSelectType}
            >
              <option value={-1}>{perfomanceData.title}</option>
              {perfomanceData.timeTakenSubjectList.map((sub) => (
                <option value={sub.subject_name}>
                  {capitalizeFirstLetter(sub)}
                  {/* {sub} */}
                </option>
              ))}

              {/* {perfomanceData.testTypes.map((test) => (
                     <option value={test.id}>{test.name}</option>
                   ))} */}
            </StyledSelect>
          </StyledSelectContainer>
          <StyledChartSection id="overall Analysis">
            <HorizontalBarChart
              labels={TIME_ALLOCATION_LABELS}
              // data={data.timeAllocationData} //format-op-time,st-time.tp-time
              data={timeTakenChart.timeTakenArray}
            />
          </StyledChartSection>
        </FloatedTitleContainer>
      )}

      {/* proficiency question standard */}

      {/* ---------------------Proficiency Analysis by Question Standard-------------- (Chart 3) */}

      {type_batch !== "4" && (
        <section>
          <div className="row">
            <div className="col-12 col-md-6">
              <FloatedTitleContainer
                title={`Proficiency Analysis by Question Standard - ${perfomanceData.title}`}
                className="mt-4"
              >
                <PieChart
                  yourscore={difficultyTypeChart.eventDetails.studentCount}
                  totalscore={difficultyTypeChart.eventDetails.totalCount}
                />
              </FloatedTitleContainer>
            </div>
            <div className="col-12 col-md-6 ">
              <FloatedTitleContainer
                title="Proficiency Analysis by Question Standard - Overall"
                className="mt-4"
              >
                <PieChart
                  yourscore={difficultyTypeChart.overall.studentCount}
                  totalscore={difficultyTypeChart.overall.totalCount}
                />
              </FloatedTitleContainer>
            </div>
          </div>
        </section>
      )}

      {/* proficiency subject */}
      {/* ---------------------Proficiency Analysis by Subjects-------------- (Chart 3) */}
      {type_batch !== "4" && (
        <FloatedTitleContainer
          title={`Proficiency Analysis by Subjects - ${
            +perfomanceData.subjectWiseSelectType === -1
              ? "Overall"
              : perfomanceData.title
          }`}
          className="mt-4"
          headerClassName={styles.floatedcontainer_header}
        >
          <StyledSelectContainer>
            <StyledSelect
              onChange={handleChange}
              name="subjectWiseSelect"
              value={perfomanceData.subjectWiseSelectType}
            >
              <option value={-1}>Overall</option>
              <option value={1}>{perfomanceData.title}</option>
            </StyledSelect>
          </StyledSelectContainer>
          <div className="row m-0" style={{ padding: "40px 6px 6px 6px" }}>
            {proficiencySubjectsChartData.studentScore.map((score, index) => {
              let tempIndex = index % 6;
              return (
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 p-0 m-0">
                  <StyledDonutchartContainer>
                    <DonutChart
                      scoreData={score.crct_count}
                      totalScore={score.subject_count}
                      label={score.subject_name}
                      bgColor={DONUTBACKGROUNDCOLOR[tempIndex]}
                    />
                  </StyledDonutchartContainer>
                </div>
              );
            })}

            {/* <div className="col-12 col-md-4 col-lg-3 col-xl-2 p-0 m-0 ">
                  <StyledDonutchartContainer>
                    <DonutChart
                      scoreData={6}
                      totalScore={20}
                      label="Tamil IlakKanam"
                      bgColor="rgba(0,178,178,1)"
                    />
                  </StyledDonutchartContainer>
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 p-0 m-0 ">
                  <StyledDonutchartContainer>
                    <DonutChart
                      scoreData={7}
                      totalScore={20}
                      label="Tamil Ilakkiyam"
                      bgColor="rgba(255,159,64,1)"
                    />
                  </StyledDonutchartContainer>
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 p-0 m-0 ">
                  <StyledDonutchartContainer>
                    <DonutChart
                      scoreData={8}
                      totalScore={20}
                      label="General Knowledge - overall"
                      bgColor="rgba(59,178,0,1)"
                    />
                  </StyledDonutchartContainer>
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 p-0 m-0 ">
                  <StyledDonutchartContainer>
                    <DonutChart
                      scoreData={9}
                      totalScore={20}
                      label="Mathematics"
                      bgColor="rgba(255,205,86,1)"
                    />
                  </StyledDonutchartContainer>
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 p-0 m-0 ">
                  <StyledDonutchartContainer>
                    <DonutChart
                      scoreData={10}
                      totalScore={20}
                      label="Physics"
                      bgColor="rgba(253,98,131,1)"
                    />
                  </StyledDonutchartContainer>
                </div> */}
          </div>
          {/* <div id="proficiency Analysis" style={{ width: "20vw" }}>
                <DonutChart
                // labels={data.overallAnalysisLabels}
                // data={data.overallAnalysisData}
                />
              </div> */}
        </FloatedTitleContainer>
      )}

      {/* overall */}
      {/* ---------------------Overall Score Analysis-------------- (Chart 4) */}
      {type_batch !== "4" && (
        <FloatedTitleContainer
          title={`Overall Score Analysis - ${overallAnalysisLabel}`}
          className="mt-4"
          headerClassName={styles.floatedcontainer_header}
        >
          <StyledChartSection id="overall Analysis">
            {isOverallForOvarallAnalysis ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 mt-2 mb-2">
                    <div className="forms ">
                      <p className="alert_info_ReviewLabel">
                        <strong> NOTE: </strong> Clicking on any legends below
                        to the chart will give you access to a detailed
                        breakdown of the specific test type.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  margin: "5px 0px",
                }}
              >
                <SecondaryButton
                  style={{ minWidth: "200px" }}
                  onClick={handleBackToTestType}
                >
                  <ICON_BACK /> Back to Test Type{" "}
                </SecondaryButton>
              </div>
            )}

            <BarChart
              chartData={overallChartData.studentScore}
              batchID={perfomanceData.batchID}
              eventID={perfomanceData.eventID}
              userID={userID}
              isOverall={isOverallForOvarallAnalysis}
              onSetIsoverall={setIsOverallForOvarallAnalysis}
              onSetLabel={setOverallAnalysisLabel}
              onSetSelectLoading={handleSelectLoading}
            />
          </StyledChartSection>
        </FloatedTitleContainer>
      )}

      {/* perfomance */}
      {/* ---------------------Perfomance Analysis by Test Type-------------- (Chart 5) */}
      {type_batch !== "4" && (
        <FloatedTitleContainer
          title={`Perfomance Analysis by Test Type - ${PERFOMANCE_CHART_TITLE}`}
          headerClassName={styles.floatedcontainer_header}
          className="mt-4"
        >
          <StyledSelectContainer>
            <StyledSelect
              onChange={handleChange}
              name="perfomanceSelect"
              value={perfomanceData.perfomanceSelectType}
            >
              <option value={-1}>Overall</option>
              {perfomanceData.testTypes.map((test) => (
                <option value={test.id}>{test.name}</option>
              ))}
            </StyledSelect>
          </StyledSelectContainer>
          <StyledChartSection id={`Perfomance Analysis`}>
            <LineChart
              labels={perfomanceChartData.perfomanceLabels}
              studentScore={perfomanceChartData.studentScore}
              toppperScore={perfomanceChartData.topperScore}
            />
          </StyledChartSection>
        </FloatedTitleContainer>
      )}

      {/* loader */}
      {perfomanceData.selectLoading && (
        <Dialog open={perfomanceData.selectLoading}>
          <DialogContent>
            <div style={{ margin: "15px" }}>
              <div className="spinner-border text-danger"></div>{" "}
              {/* <p>Loading ...</p> */}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </CustomContainer>
  );
}
