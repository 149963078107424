import React, { useState } from "react";

import ViewCourseBath from "./CourseBatch";
import TestBatchView from "./TestBatchView";
import SpecialClassView from "./SpecialClassView";
import CounsellingViiew from "./CounsellingViiew";
import ModelExamView from "./ModelExamView";
import MockInterview from "./MockInterview";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

function ListviewBatch() {
  const navigate = useNavigate();
  const { active_tab } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  // AIASA-000142  - v1 issues solved by lalitha
  useEffect(() => {
    // Update the activeTab state when the component mounts and whenever active_tab changes in the URL
    setActiveTab(Number(active_tab) || 1);
    // setActiveTab(1);
  }, [active_tab]);

  const handleTabClick = (index) => {
    setActiveTab(index);
    navigate(`/ViewCourseBatch/${index}`);
  };

  return (
    <div className="BathDet ">
      <h6 className="Title ms-0 me-0">Batches</h6>
      <div className="row">
        <div className="col-md-12">
          {/* nav-tabs */}
          <ul className="nav  nav-fill mb-3" id="listViewTabs" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 1 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-1"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-1"
                aria-selected={activeTab === 1}
                onClick={() => handleTabClick(1)}
              >
                Course
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 2 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-2"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-2"
                aria-selected={activeTab === 2}
                onClick={() => handleTabClick(2)}
              >
                Test
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 3 ? "nav-link active" : "nav-link"}
                // id="ex2-tab-3"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-3"
                aria-selected={activeTab === 3}
                onClick={() => handleTabClick(3)}
              >
                Special Class
              </a>
            </li>

            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 5 ? "nav-link active" : "nav-link"}
                // id="javascript:void(0)"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-5"
                aria-selected={activeTab === 5}
                onClick={() => handleTabClick(5)}
              >
                {" "}
                Model Exam
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 6 ? "nav-link active" : "nav-link"}
                // id="#"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-6"
                aria-selected={activeTab === 6}
                onClick={() => handleTabClick(6)}
              >
                Mock Interview
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={activeTab === 4 ? "nav-link active" : "nav-link"}
                // id="javascript:void(0)"
                data-mdb-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="ex2-tabs-4"
                aria-selected={activeTab === 4}
                onClick={() => handleTabClick(4)}
              >
                Counselling
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="borderActive"></div> */}
      </div>
      {/* // tab content coures  */}
      <ViewCourseBath activeTab={activeTab === 1 ? activeTab : null} />
      <TestBatchView activeTab={activeTab === 2 ? activeTab : null} />
      <SpecialClassView activeTab={activeTab === 3 ? activeTab : null} />
      <CounsellingViiew activeTab={activeTab === 4 ? activeTab : null} />
      <ModelExamView activeTab={activeTab === 5 ? activeTab : null} />
      <MockInterview activeTab={activeTab === 6 ? activeTab : null} />
    </div>
  );
}

export default ListviewBatch;
